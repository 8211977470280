import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, when } from "mobx";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { Fact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";
import { ProducingObjectsParams } from "models/project/fact/producingObjectsParams/producingObjectsParams";
import { LicenseRegion } from "models/project/licenseRegion/licenseRegion";
import { ProducingObject } from "models/project/producingObject/producingObject";

import { ProdObjectsGeneric } from "./generics";

type DRow = {
  name: string;
  measure: string | null;
  [year: number]: number | null;

  isEditable?: boolean;
};

type ParamKeys = "reserves";

type ParamsStore<Keys extends ParamKeys, V = number | null> = {
  [producingObjectId: number]: {
    [licenseRegionId: number]: Record<Keys, V[]>;
  };
};
type ParamsStoreValidated<K extends ParamKeys> = ParamsStore<K, number>;

class ReservesDepletion extends TableNode<DRow, ProdObjectsGeneric<ParamKeys>> {
  public licenseRegions?: LicenseRegion[];
  public producingObjects?: ProducingObject[];
  public data?: ParamsStore<ParamKeys>;

  constructor(private fact: Fact, private forecast: Forecast | null) {
    super(null, { isExpandedChildren: true });

    makeObservable<ReservesDepletion, "init" | "initStore">(this, {
      data: observable,
      isValid: computed,
      init: action,
      initStore: action,
    });

    when(
      () => this.fact.producingObjects.isLoading === false && this.params.isLoading === false,
      () => this.init()
    );
  }

  public get params(): ProducingObjectsParams {
    return (this.forecast ?? this.fact).producingObjectsParams;
  }

  public get isValid(): boolean {
    if (!this.data) {
      return false;
    }
    for (const prodObjParams of Object.values(this.data)) {
      for (const lrParams of Object.values(prodObjParams)) {
        for (const paramValues of Object.values(lrParams)) {
          if (paramValues.includes(null)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private init() {
    this.licenseRegions = this.fact.licenseRegions.data;
    this.producingObjects = [...this.fact.producingObjects.values!];

    this.initStore();

    this.childrenStore = new ChildrenStoreArray(this, [
      new ProdObjectsGeneric(this, { key: "reserves", title: "НИЗ", measure: new UOM(5, 5, global.uomResolver) }),
    ]);
  }

  private initStore() {
    this.data = {};
    if (!this.producingObjects || !this.licenseRegions || this.params.isLoading) {
      return;
    }
    const paramsRange = this.params.paramsRange;

    for (const prodObj of this.producingObjects) {
      const prodObjData: ParamsStore<ParamKeys>[number] = {};
      for (const lr of this.licenseRegions) {
        prodObjData[lr.id] = {
          reserves:
            this.params.licenseRegionsParams?.[prodObj.id]?.[lr.id]?.reserves.slice() ?? paramsRange.array.fill(null),
        };
      }
      this.data[prodObj.id] = prodObjData;
    }
  }

  public submit = async () => {
    if (!this.data || !this.isValid) {
      return;
    }
    await this.params.updateLRParams(this.data as ParamsStoreValidated<ParamKeys>);
    this.mutationsManager?.dropMutations();
    this.init();
  };
}

export type { DRow, ParamKeys, ParamsStore };
export { ReservesDepletion };
