import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { Pipes as InfastructurePipes, PipeType } from "models/project/fact/infrastructure/pipes";
import { SegmentType as SegmentCalculateType, SimulationOutput } from "services/back/infrastructure/calculate";
import { SegmentType } from "services/back/infrastructure/catalog";
import { NodeType, PipeBoundaryCondition, Segment } from "services/back/infrastructure/types";

import { compareValues, findMatch } from "../../../utils";

type FormatPipesType<T> = { uuid: string; title: string; params: ({ date: Dayjs } & T)[] };

type FormatSegmentCalculateType = { date: Dayjs; firstPressure: number; secondPressure: number } & SegmentCalculateType;

type Calculations = ({ date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationOutput)[];

type DRow = {
  date: Dayjs | null;
  title: string;
  type: string;
  fluidRateM3: number;
  pressureDelta: number;
  firstPressure: number;
  secondPressure: number;
  limitingPressureGradient: number;
  limitingVelocity: number;
  pressureGradient: number;
  velocity: number;
  isProblem?: boolean;
};

class Pipe extends TableNode<DRow> {
  asDRow = (): any => ({
    ...this.data,
    type: this.type,
    isProblem: this.isProblem,
    limitingPressureGradient: this.limitingPressureGradient,
    limitingVelocity: this.limitingVelocity,
    date: dayjs(this.data.date).format("MM.YYYY"),
  });
  public data: FormatSegmentCalculateType;
  constructor(private parent: Pipes, data: FormatSegmentCalculateType, private infrastructure: Infrastructure) {
    super(parent);

    makeObservable(this, {
      data: observable,
      type: computed,
      isProblem: computed,
      limitingPressureGradient: computed,
      limitingVelocity: computed,
    });

    this.data = data;
  }

  get type() {
    const pipeAttributes = InfastructurePipes.getPipesAttributes(this.data as Partial<SegmentType>);
    const pipes = this.infrastructure.catalog.pipes(this.data.segmentType) || [];
    const matchingStation = findMatch<SegmentType>(pipeAttributes, pipes);
    return matchingStation?.title || "";
  }

  get isProblem(): boolean {
    return Pipe.exceedsBoundaryLimits(this.data, this.infrastructure.calculateStore.userFilterParams ?? undefined);
  }

  get limitingPressureGradient() {
    const maxLimit = this.infrastructure.calculateStore.userFilterParams?.pressureGradient;
    return maxLimit ?? this.data.limitingPressureGradient;
  }
  get limitingVelocity() {
    const maxLimit = this.infrastructure.calculateStore.userFilterParams?.velocity;
    return maxLimit ?? this.data.limitingVelocity;
  }

  public static exceedsBoundaryLimits = (
    pipe: FormatSegmentCalculateType,
    limits?: { pressureGradient?: number | null; velocity?: number | null }
  ): boolean => {
    return (
      compareValues(limits?.pressureGradient ?? pipe.limitingPressureGradient ?? 0, pipe.pressureGradient) ||
      compareValues(limits?.velocity ?? pipe.limitingVelocity ?? 0, pipe.velocity)
    );
  };
}

class Pipes extends TableNode<DRow, Pipe> {
  asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(private parent: PipesModel, private name: string, private params: any[]) {
    super(parent, { isExpandedChildren: true });

    this.initChildren();
  }

  private initChildren() {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.params.map((pipe) => new Pipe(this, pipe, this.parent.infrastructure))
    );
  }
}

class PipesModel extends TableNode<DRow, Pipes> {
  public allPipes: FormatPipesType<Segment>[] = [];
  public filteredProblemPipes: FormatPipesType<Segment>[] | null = null;
  public hasProblemDataFilter: boolean = false;
  constructor(public infrastructure: Infrastructure, private mode: "prod" | "inj") {
    super();

    makeObservable<PipesModel, "initChildren">(this, {
      allPipes: observable,
      filteredProblemPipes: observable,
      hasProblemDataFilter: observable,
      initChildren: action,
      toggleProblemPipesFilter: action,
      pipes: computed,
      userFilters: computed,
      isEditedUserFilters: computed,
    });

    this.initChildren();
  }

  private initChildren() {
    const calculations = this.infrastructure.calculateStore.hydraulicDataCollection || [];
    this.allPipes = PipesModel.objectSegmentsValues(calculations, this.mode, this.infrastructure.pipes.data ?? []);

    const formatPipes = (p: FormatPipesType<Segment>[]) => p.map(({ title, params }) => new Pipes(this, title, params));
    this.childrenStore = new ChildrenStoreArray(this, formatPipes(this.allPipes));

    reaction(
      () => `${this.hasProblemDataFilter}-${this.isEditedUserFilters}`,
      () => {
        const pipes = PipesModel.filterByDate(this.filteredProblemPipes ?? this.allPipes, this.userFilters?.date);
        this.childrenStore = new ChildrenStoreArray(this, formatPipes(pipes));
      }
    );

    reaction(
      () => this.isEditedUserFilters,
      () => this.hasProblemDataFilter && this.toggleProblemPipesFilter()
    );
  }

  public get pipes() {
    return this.filteredProblemPipes ?? this.allPipes;
  }

  public get userFilters() {
    return this.infrastructure.calculateStore.userFilterParams;
  }

  public get isEditedUserFilters() {
    const velocity = this.userFilters?.velocity;
    const pressureGradient = this.userFilters?.pressureGradient;
    const date = this.userFilters?.date;
    return `${velocity}-${pressureGradient}-${date}`;
  }

  public static filterByDate = (pipes: FormatPipesType<Segment>[], date?: [Dayjs, Dayjs] | null) => {
    const [start, end] = date ?? [null, null];
    return pipes.map((el) => ({
      ...el,
      params: el.params.filter((pipe) => {
        const date = pipe.date;
        if (start && end) {
          return (
            (date.isAfter(start, "month") || date.isSame(start, "month")) &&
            (date.isBefore(end, "month") || date.isSame(end, "month"))
          );
        } else if (start) {
          return date.isAfter(start, "month") || date.isSame(start, "month");
        } else if (end) {
          return date.isBefore(end, "month") || date.isSame(end, "month");
        } else {
          return true;
        }
      }),
    }));
  };

  public toggleProblemPipesFilter = () => {
    if (!this.allPipes) return;
    this.hasProblemDataFilter = !this.hasProblemDataFilter;
    this.filteredProblemPipes = this.hasProblemDataFilter ? this.filterProblematicPipes(this.allPipes) : null;
  };

  private filterProblematicPipes = (pipes: FormatPipesType<Segment>[]): FormatPipesType<Segment>[] => {
    const limits = this.infrastructure.calculateStore.userFilterParams;
    return pipes.map((el) => ({
      ...el,
      params: (el.params as FormatSegmentCalculateType[]).filter((item) =>
        Pipe.exceedsBoundaryLimits(item, limits ?? undefined)
      ),
    }));
  };

  public static objectSegmentsValues = (
    calculations: Calculations,
    mode: "prod" | "inj",
    pipes: PipeType[]
  ): { uuid: string; title: string; params: PipeInfo[] }[] => {
    const segments: Map<string, { uuid: string; title: string; params: PipeInfo[] }> = new Map();
    const pipesLength = new Map<string, number>(pipes.map((pipe) => [pipe.uuid, pipe.length]));

    for (const calculation of calculations) {
      if (calculation[mode]) {
        PipesModel.formatPipeSegments(
          calculation[mode]!.segments,
          segments,
          calculation[mode]!.nodes,
          calculation.date,
          pipesLength
        );
      }
    }
    return [...segments.values()];
  };

  private static formatPipeSegments = (
    data: SegmentCalculateType[],
    result: Map<string, { uuid: string; title: string; params: PipeInfo[] }>,
    nodes: ({ pressure?: number } & NodeType)[],
    date: Dayjs,
    pipesLength: Map<string, number>
  ) => {
    const nodesPressure = new Map<string, number>(nodes.map((node) => [node.uuid, node.pressure ?? 0]));

    for (const item of data) {
      const newItem: PipeInfo = {
        ...item,
        firstPressure: nodesPressure.get(item.firstNodeUuid),
        secondPressure: nodesPressure.get(item.secondNodeUuid),
        pressureGradient: item.pressureDelta / (pipesLength.get(item.uuid) ?? 1),
        date,
      };
      if (result.has(item.uuid)) {
        result.get(item.uuid)?.params.push(newItem);
      } else {
        result.set(item.uuid, {
          uuid: item.uuid,
          title: item.title,
          params: [newItem],
        });
      }
    }
  };
}

type PipeInfo = SegmentCalculateType & {
  firstPressure: number | undefined;
  secondPressure: number | undefined;
  pressureGradient: number;
  date: Dayjs;
};

export { type DRow as DRowPipes, PipesModel };
export type { FormatPipesType, PipeInfo };
