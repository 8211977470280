import { Color } from "antd/es/color-picker";
import { action, computed, makeObservable, observable, ObservableMap, ObservableSet, runInAction } from "mobx";

import { randomColor } from "utils/random";

type Channel = {
  key: string;
  title: string;
  axis: string;
  color: string;
  isVisible: boolean;
  toggleVisible: () => void;
  setColor: (color: Color) => void;
};

type GroupDump = {
  title: string;
  key: string;
  toggleVisible: () => void;
  isVisible: boolean;
  isPartiallyVisible: boolean;
  channels: Channel[];
};

type ChannelInfo = {
  title: string;
  axis: string;
  color: string | null;
  visibleByDefault?: true;
};

type ChannelsMetaInfo = Record<string, ChannelInfo>;

abstract class ChannelsManager {
  public colors: ObservableMap<string, string> = observable.map();
  public hidden: ObservableSet<string> = observable.set();
  public hideUngrouped = false;

  constructor() {
    makeObservable(this, {
      colors: observable,
      hidden: observable,
      hideUngrouped: observable,
      toggleUngrouped: action,
      colorHolder: action,
      isGroupVisible: action,
      isGroupPartiallyVisible: action,
      channelGroups: computed,
    });
  }

  toggleUngrouped = () => {
    this.hideUngrouped = !this.hideUngrouped;
  };

  abstract get channelsMetaInfo(): ChannelsMetaInfo;

  abstract updateLineColor(key: string): void;

  colorHolder(key: string) {
    return (color: Color) =>
      runInAction(() => {
        this.colors.set(key, color.toRgbString());
        this.updateLineColor(key);
      });
  }

  channelColor(channel: string): string {
    return this.colors.get(channel) ?? this.channelsMetaInfo[channel]?.color ?? randomColor(channel);
  }

  groupChannelsKey(groupKey: string): string[] {
    if (!(groupKey in this.knownGroups)) {
      console.error(`Ключа ${groupKey} не существует`);
    }
    return [...this.knownGroups[groupKey].items.values()];
  }

  // если видны все каналы
  isGroupVisible(groupKey: string): boolean {
    return this.groupChannelsKey(groupKey).find((key) => this.hidden.has(key)) === undefined;
  }

  onGroupVisibilityChange(groupKey: string) {
    const action = this.isGroupVisible(groupKey) ? ("add" as const) : ("delete" as const);
    for (const channelKey of this.groupChannelsKey(groupKey)) {
      this.hidden[action](channelKey);
    }
  }

  // если виден хотя-бы один канал
  isGroupPartiallyVisible(groupKey: string): boolean {
    return this.groupChannelsKey(groupKey).find((key) => !this.hidden.has(key)) !== undefined;
  }

  fromChannelKey = (key: string): Channel => ({
    key,
    title: this.channelsMetaInfo[key]?.title ?? key,
    axis: this.channelsMetaInfo[key]?.axis, // ?? axisInference(key),
    isVisible: !this.hidden.has(key),
    toggleVisible: () => runInAction(() => this.hidden[this.hidden.has(key) ? "delete" : "add"](key)),
    setColor: this.colorHolder(key),
    color: this.channelColor(key),
  });

  fromGroupItem = ([key, { title, items }]: [string, { title: string; items: Set<string> }]): GroupDump => ({
    toggleVisible: () => this.onGroupVisibilityChange(key),
    key,
    title,
    isVisible: this.isGroupVisible(key),
    isPartiallyVisible: this.isGroupPartiallyVisible(key),
    channels: Array.from(items.values()).map(this.fromChannelKey),
  });

  abstract get knownGroups(): Record<
    string,
    {
      title: string;
      items: Set<string>;
    }
  >;

  get channelGroups(): GroupDump[] {
    return [...Object.entries(this.knownGroups).map(this.fromGroupItem)];
  }
}
export { ChannelsManager };
export type { ChannelInfo, ChannelsMetaInfo };
