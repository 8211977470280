import React, { useState } from "react";
import { Tabs } from "antd";
import { observer } from "mobx-react";

import { Card } from "elements/card/card";
import { Format } from "elements/format/format";
import { useProject } from "models/project/project";

import { LicenseRegion } from "./licenseRegion";

import cn from "./licenseRegions.module.less";

const LicenseRegions: React.FC = observer(() => {
  const { licenseRegions } = useProject()!;
  const [activeKey, setActiveKey] = useState(`${licenseRegions.ids[0]}`);
  const items = licenseRegions.data.map((field) => ({
    label: <Format>{field.title}</Format>,
    key: `${field.id}`,
    children: <LicenseRegion fieldKey={field.id} />,
  }));

  return (
    <Card>
      <Tabs className={cn.lr} hideAdd={true} type="card" onChange={setActiveKey} activeKey={activeKey} items={items} />
    </Card>
  );
});

export { LicenseRegions };
