import { makeAutoObservable } from "mobx";

import { ProjectParticipantInfo } from "services/back/projectParticipants";
import { ProjectRole } from "services/back/roles";

type ExtendedParticipantInfo = ProjectParticipantInfo & {
  roles: ProjectRole[];
};

class Participant {
  public title: string;
  public id: number;
  public email: string;
  public roles: ProjectRole[];
  public projectId: number;
  public username: string;
  public fullName: string;
  public disabled: boolean;

  constructor(participant: ExtendedParticipantInfo, projectId: number) {
    this.title = participant.fullName;
    this.fullName = participant.fullName;
    this.id = participant.id;
    this.email = participant.email;
    this.roles = participant.roles;
    this.projectId = projectId;
    this.username = participant.username;
    this.disabled = participant.disabled;

    makeAutoObservable(this);
  }
}

export { Participant };
export type { ExtendedParticipantInfo };
