import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { EcyParameters as EcyParametersFeature } from "features/ecyParameters/ecyParameters";
import { Preloader } from "features/preloader/preloader";
import { useECYStore } from "features/useMetrics/useECYStore";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

const EcyParameters: FC = observer(() => {
  return (
    <Preloader hooks={[useFact, useForecast, useECYStore]}>
      <PageFrame title="Единые сценарные условия" permissionSection="economic">
        <EcyParametersFeature />
      </PageFrame>
    </Preloader>
  );
});

export { EcyParameters };
