import { FC } from "react";
import classNames from "classnames";

import cn from "./tagViewer.module.less";

type Props = {
  title: string;
  children: string[];
  className?: string;
};

const TagViewer: FC<Props> = ({ title, children, className }) => {
  if (!Array.isArray(children)) {
    return <></>;
  }
  if (Array.isArray(children) && children.length === 0) {
    return <></>;
  }

  return (
    <div className={classNames(cn.wrapper, className)}>
      <div className={cn.title}>{title}</div>
      <div className={cn.list}>
        {children.map((tag) => (
          <span className={cn.tag} key={tag}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export { TagViewer };
