import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

const useInfrastructure = () => {
  const fact = useFact()!;
  const forecast = useForecast();
  return (forecast ?? fact).infrastructure;
};

export { useInfrastructure };
