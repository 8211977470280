import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class OperatingParams extends Params {
  constructor(years: Range, services: ParamsServices) {
    super("Параметры операционных расходов", years, services);
  }
}

export { OperatingParams };
