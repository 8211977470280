import { FC } from "react";

import { useTechForecastModel } from "../useTechForecastModel";

import { ValidationInput } from "./legacyInputs/validationInput";
import { Param, Params } from "./param";

const StopCriterion: FC = () => {
  const model = useTechForecastModel();
  const fc = model.currentForecast;
  const { settings } = fc;
  return (
    <Params>
      <Param title="Обводненность объемная, %">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.waterCut}
          onSave={settings.stopCriterion.holder("waterCut")}
          min={0}
          max={100}
        />
      </Param>
      <Param title="Дебит нефти, т/сут">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.oilRate}
          onSave={settings.stopCriterion.holder("oilRate")}
          min={0}
          max={10000}
        />
      </Param>
      <Param title="Период отработки на нефть, мес">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.oilProdMonthDuration}
          onSave={settings.stopCriterion.holder("oilProdMonthDuration")}
          min={1}
          max={2400}
        />
      </Param>
      <Param title="Мин. темп изменения дебита жидкости, %/мес">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.liquidDropRate}
          onSave={settings.stopCriterion.holder("liquidDropRate")}
          min={0}
          max={100}
        />
      </Param>
      <Param title="Мин. темп изменения дебита нефти, %/мес">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.oilDropRate}
          onSave={settings.stopCriterion.holder("oilDropRate")}
          min={0}
          max={100}
        />
      </Param>
      <Param title="Макс. отбор от НИЗ, д.ед.">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.recoverableResources}
          onSave={settings.stopCriterion.holder("recoverableResources")}
          min={0}
          max={1}
        />
      </Param>
      <Param title="Макс. накопленная добыча нефти, тыс. т">
        <ValidationInput
          key={model.currentKey}
          value={settings.stopCriterion.accumOilProd}
          onSave={settings.stopCriterion.holder("accumOilProd")}
          min={0}
          max={100000}
        />
      </Param>
      <Param title={<b>Коэффициент эксплуатации</b>}>
        <ValidationInput
          key={model.currentKey}
          required
          value={settings.prodTimeRatio.prodTimeRatio}
          onSave={settings.prodTimeRatio.holder}
          min={0}
          max={5}
        />
      </Param>
    </Params>
  );
};

export { StopCriterion };
