import { type FC } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { ForecastMode } from "services/back/techForecast/request";

import { LineDataModel } from "../elements/lineDataModel";
import { Point } from "../elements/point";

import { Bound } from "./bound";

import cn from "./lineBounds.module.less";

type LineBoundsProps = {
  lineInfo: LineDataModel;
  metric: ForecastMode;
  accessor: (v: number) => number;
  yScale: d3.ScaleContinuousNumeric<number, number>;
  xScale: d3.ScaleContinuousNumeric<number, number>;
};

const LineBounds: FC<LineBoundsProps> = observer(({ lineInfo, metric, accessor, yScale, xScale }) => {
  const model = useTechForecastModel();
  const { settings } = model.currentForecast;

  const value = settings[metric].factDomain!;
  const onChange = settings.boundsHolder(metric);
  const onDrag = (side: "min" | "max", changed: number) => {
    onChange({
      ...value,
      [side]: changed,
    });
  };
  const onOutDot = settings.dotsToggleHolder;
  if (yScale === undefined) {
    return null;
  }
  return (
    <>
      <Bound
        side="left"
        x={xScale(accessor(value.min))}
        y={yScale(lineInfo.y[value.min]!)}
        onDrag={(x) => {
          onDrag("min", (accessor as any).invert(xScale.invert(x)));
        }}
        color={lineInfo.color}
        patternKey={lineInfo.key}
      />
      <Bound
        side="right"
        x={xScale(accessor(value.max))}
        y={yScale(lineInfo.y[value.max]!)}
        onDrag={(x) => {
          onDrag("max", (accessor as any).invert(xScale.invert(x)));
        }}
        color={lineInfo.color}
        patternKey={lineInfo.key}
      />
      {lineInfo.y.map(
        (y, index) =>
          y !== null &&
          index >= value.min &&
          index <= value.max && (
            <Point
              className={classNames(settings[metric].emptyDots.has(index) && cn.emptyDot)}
              key={index}
              y={yScale(y)}
              x={xScale(accessor(index))}
              curveKey={lineInfo.key}
              index={index}
              color={lineInfo.color}
              onClick={(e) => {
                e.stopPropagation();
                onOutDot(index, metric)();
              }}
            />
          )
      )}
    </>
  );
});

export { LineBounds };
