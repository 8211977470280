import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { useLogsColumns } from "features/project/logs/logs";
import { SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { LoggerStore } from "models/logger";
import { useProjects } from "models/project/projects";

import { PreloaderLogs } from "./preloaderLogs";

const DashboardTable: FC = observer(() => {
  const { logger } = global;
  const projects = useProjects()!;
  const columns = useLogsColumns(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new LoggerStore(logger, projects), [logger.isLoading, projects]);

  return (
    <PreloaderLogs>
      <SimpleTableContext exportFileName="Логирование" columns={columns} data={store} hideExpandColumn showIndexColumn={false}>
        <Widget />
      </SimpleTableContext>
    </PreloaderLogs>
  );
});

export { DashboardTable };
