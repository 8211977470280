import { FC, useMemo, useState } from "react";
import { Button, Empty, Typography } from "antd";
import { observer } from "mobx-react";

import { Map } from "elements/map/map";
import { useMapContext } from "elements/map/useMapContext";
import { MapManager } from "elements/map/useMapManager";
import { TooltipContext } from "elements/tooltips/abstractTooltip";
import { WrapTooltipModel } from "elements/tooltips/wrapTooltip/wrapTooltipModel";
import { useForecast } from "models/project/fact/forecast/forecast";
import { save } from "services/back/calculate/utils";
import { VoronoiGrid as VoronoiGridData } from "services/back/injectionPrediction/voronoi";
import { viewport } from "utils/pointsViewport";

import { Cells } from "./Cells/Cells";
import { WellPad } from "./Cells/WellPad";
import { PadIcon } from "./icons/padIcon";
import { LegendMap } from "./legendMap/legendMap";
import { VoronoiModeSelector } from "./voronoiModeSelector/voronoiModeSelector";
import { VoronoiModeSelectorModel } from "./voronoiModeSelector/voronoiModeSelectorModel";
import { GridMapDataManager } from "./GridMapDataManager";
import { HoverManager, HoverManagerContext } from "./hoverManager";

import cn from "./GridMap.module.less";

type LayoutProps = {
  manager: GridMapDataManager;
  type: "injection" | "mining";
};

const valueRange = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

const LabelsContainer: FC<LayoutProps> = ({ manager, type }) => {
  const { scale, zoom } = useMapContext();

  return (
    <>
      {manager.wells!.map((point) => (
        <g style={{ transform: `translate(${scale.x(point.x)}px, ${scale.y(point.y)}px)` }}>
          <WellPad zoom={1 / valueRange(zoom, 2, 5)} showLabel={zoom >= 1} title={point.well?.title}>
            <PadIcon mode={type} fond={point.well?.fond} />
          </WellPad>{" "}
        </g>
      ))}
    </>
  );
};

const VoronoiGrid: FC<LayoutProps> = ({ manager, type }) => {
  const { scale, zoom } = useMapContext();

  return (
    <>
      <Cells type={type} active dataManager={manager} scale={scale} zoom={zoom} />
    </>
  );
};

type GridMapProps = {
  data: VoronoiGridData;
};

const GridMap: FC<GridMapProps> = observer(({ data }) => {
  const [manager, setManager] = useState<MapManager | null>(null);
  const modeModel = useMemo(() => new VoronoiModeSelectorModel(), []);
  const hoverManager = useMemo(() => new HoverManager(data, modeModel), [data, modeModel]);
  const fc = useForecast()!;

  const onDownload = () => {
    save(data);
  };
  const tooltipModel = useMemo(() => new WrapTooltipModel(), []);
  const injDataManager = useMemo(() => new GridMapDataManager(data, "injection", fc), [data, fc]);
  const minDataManager = useMemo(() => new GridMapDataManager(data, "mining", fc), [data, fc]);

  if (data.injWells.length === 0 && data.prodWells.length === 0) {
    return (
      <div className={cn.empty}>
        <Empty description={<Typography.Text>Нет данных для отображения</Typography.Text>} />
      </div>
    );
  }
  return (
    <div className={cn.layout}>
      <LegendMap />
      <div className={cn.toolbar}>
        <div className={cn.buttons}>
          <Button onClick={manager?.setInitialScale}>1:1</Button>
          <Button onClick={onDownload}>Скачать расчет</Button>
          <VoronoiModeSelector modeModel={modeModel} />
        </div>
      </div>
      <div className={cn.mapNDate}>
        <TooltipContext.Provider value={tooltipModel}>
          <Map manager={setManager} viewPort={viewport([...data.injWells, ...data.prodWells]).join(" ")} className={cn.map}>
            <HoverManagerContext.Provider value={hoverManager}>
              <VoronoiGrid
                manager={modeModel.mode === "oilRate" ? injDataManager : minDataManager}
                type={modeModel.mode === "oilRate" ? "injection" : "mining"}
              />
              <VoronoiGrid
                manager={modeModel.mode === "oilRate" ? minDataManager : injDataManager}
                type={modeModel.mode === "oilRate" ? "mining" : "injection"}
              />
              <LabelsContainer
                manager={modeModel.mode === "oilRate" ? injDataManager : minDataManager}
                type={modeModel.mode === "oilRate" ? "injection" : "mining"}
              />
              <LabelsContainer
                manager={modeModel.mode === "oilRate" ? minDataManager : injDataManager}
                type={modeModel.mode === "oilRate" ? "mining" : "injection"}
              />
            </HoverManagerContext.Provider>
          </Map>
        </TooltipContext.Provider>
      </div>
    </div>
  );
});

export { GridMap };
