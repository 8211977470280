import { Column } from "features/tableDebug/simpleTable";
import { BalanceResult } from "models/project/stratum/materialBalance";
import { reqCamel } from "utils/request";

import { backendStorage } from "../backendStorage";

type ProducingObjectSettings = Record<Exclude<SettingKey, null>, number>;
type SettingKey =
  | "cWater"
  | "permeability"
  | "porosity"
  | "saturatedThickness"
  | "inflowAngle"
  | "waterViscosity"
  | "pAqInit"
  | "cOil"
  | "cPore"
  | "bOilInit"
  | "bWaterInit"
  | "oilSaturation"
  | "pReservoirInit"
  | "oilBearingAreaM2"
  | "oilReservesInitKg"
  | "sRhoOil"
  | "pSaturation"
  | null;
type SettingsBody = {
  scenarioId: number;
  producingObjectId: number;
  monthCount?: number;
  deposit: ProducingObjectSettings;
  convergence: {
    iterCount: number;
    relTol: number;
  };
};
type BalanceResults = {
  months: number[];
  years: number[];
  oilProdM3: number[];
  waterProdM3: number[];
  waterInM3: number[];
  liquidProd: number[];
  pressure: number[];
  coeffProd: number[];
  coeffInj: number[];
  apgProdM3: number[] | null;
};

type BalanceData = {
  date: string;
  liquidProdM3?: number;
  oilProdM3: 0;
  waterProdM3: 0;
  waterInjM3?: 0;
  apgProdM3?: 0;
  pressure?: 300;
  children: BalanceData[];
};

type BalanceResultsTable = {
  data: BalanceData[];
  columns: Column[];
};

const calculateMaterialBalance = (settings: SettingsBody) =>
  reqCamel.post<BalanceResults, SettingsBody>("calculation/mat-balance", settings);

const materialBalanceTableView = (settings: SettingsBody) =>
  reqCamel.post<BalanceResultsTable, SettingsBody>("table-views/mat-balance-pressure", settings);

const loadSettings = async (
  projectId: number,
  scenarioId: number,
  producingObjectId: number
): Promise<ProducingObjectSettings | null> => {
  const data = await backendStorage.getItem<ProducingObjectSettings | null>(
    `balance-settings/${producingObjectId}`,
    projectId,
    scenarioId
  );
  if (data === null) {
    return null;
  }
  return data;
};

const saveSettings = async (
  settings: ProducingObjectSettings,
  projectId: number,
  scenarioId: number,
  producingObjectId: number
): Promise<ProducingObjectSettings | null> => {
  const data = await backendStorage.setItem<ProducingObjectSettings | null>(
    settings,
    `balance-settings/${producingObjectId}`,
    projectId,
    scenarioId
  );
  if (data === null) {
    return null;
  }
  return data;
};

const loadResults = async (
  projectId: number,
  scenarioId: number,
  producingObjectId: number
): Promise<BalanceResult[] | null> => {
  const data = await backendStorage.getItem<BalanceResult[] | null>(
    `balance-results/${producingObjectId}`,
    projectId,
    scenarioId
  );
  if (data === null) {
    return null;
  }
  return data;
};

const saveResults = async (
  results: BalanceResult[],
  projectId: number,
  scenarioId: number,
  producingObjectId: number
): Promise<BalanceResult[] | null> => {
  const data = await backendStorage.setItem<BalanceResult[] | null>(
    results,
    `balance-results/${producingObjectId}`,
    projectId,
    scenarioId
  );
  if (data === null) {
    return null;
  }
  return data;
};

export { calculateMaterialBalance, loadResults, loadSettings, materialBalanceTableView, saveResults, saveSettings };
export type { BalanceData, BalanceResults, BalanceResultsTable, ProducingObjectSettings, SettingKey, SettingsBody };
