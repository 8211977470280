import { Delaunay } from "d3";
import dayjs, { Dayjs } from "dayjs";
import { makeAutoObservable } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import type { VoronoiGrid } from "services/back/injectionPrediction/voronoi";
import { viewport } from "utils/pointsViewport";

import { DRow, InjectionSlice } from "../resultsTableView/injectionSliceModel";
import { ProductionSlice } from "../resultsTableView/productionSliceModel";

import { WellPadType } from "./Cells/WellPad";

type WellPadPoint = {
  id: number;
  x: number;
  y: number;
  balance: number;
  polygon: Delaunay.Polygon;
  well: Well | null;
  date: Dayjs;
  oilRate: number | null;
  liquidRate: number | null;
  injCapacity: number | null;
  type: WellPadType;
  injectionWells?: DRow[];
  miningWells?: DRow[];
};

type TooltipInfoProps = {
  header: string;
  value: number;
};

type TooltipInfoType = TooltipInfoProps & { left: number; top: number };

class GridMapDataManager {
  currentDate: Dayjs;
  wells: WellPadPoint[] | undefined;
  injectionSlice: InjectionSlice;
  productionSlice: ProductionSlice;

  constructor(voronoi: VoronoiGrid, public type: WellPadType, fc: Forecast) {
    makeAutoObservable(this);
    this.currentDate = dayjs(new Date(voronoi.year, voronoi.month - 1));
    this.injectionSlice = new InjectionSlice(fc, voronoi);
    this.productionSlice = new ProductionSlice(fc, voronoi);

    this.wells = (type === "injection" ? voronoi.injWells : voronoi.prodWells).map(({ x, y, points, id }) => {
      const miningWells = this.injectionSlice.injectionNodes
        .find((node) => node.wellId === id)
        ?.prodNodes.map((node) => node.asDRow());

      const injectionWells = this.productionSlice.prodNodes
        .find((node) => node.wellId === id)
        ?.injNodes.map((node) => node.asDRow());
      const prodDatum = fc.production.wellData(id)?.getForMonth(...this.injectionSlice.currentDate);
      return {
        x,
        y,
        id,
        polygon: points.map(({ x, y }) => [x, y]),
        balance: 0,
        well: fc.wells.at(id) ?? null,
        type: type,
        date: this.currentDate,
        oilRate: prodDatum?.prod_days ? ((prodDatum.oil_prod ?? 0) / prodDatum.prod_days) * 1000 : null,
        liquidRate: prodDatum?.prod_days ? ((prodDatum.liquid_prod ?? 0) / prodDatum.prod_days) * 1000 : null,
        injCapacity: (prodDatum?.inj_days && ((prodDatum?.water_inj ?? 0) / prodDatum.inj_days) * 1000) ?? null,
        miningWells: miningWells,
        injectionWells: injectionWells,
      };
    });
  }

  get viewPort(): string {
    return viewport(this.wells?.filter(({ x, y }) => x + y !== 0) ?? []).join(" ");
  }
}

export { GridMapDataManager };
export type { TooltipInfoProps, TooltipInfoType, WellPadPoint };
