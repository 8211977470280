import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, override, transaction } from "mobx";

import { ProducingObject } from "models/project/producingObject/producingObject";
import { Project } from "models/project/project";
import { ProducingObjectRaw } from "services/back/producingObjects";

import { StratumRow } from "./stratum";

type DRow = NullableFields<Params> & {
  title: string;
  isDuplicatedTitle: boolean;

  mainStratumId: number | null;
  relatedStratums: Map<number, StratumRow>;
  remove: () => void;
};

type NullableNumber<T> = T extends number ? number | null : T;

type NullableFields<T> = {
  [K in keyof T]: T[K] extends object ? NullableFields<T[K]> : NullableNumber<T[K]>;
};

type Params = Pick<
  ProducingObjectRaw,
  "densityOil" | "densityWater" | "viscosityOil" | "viscosityWater" | "gasOilRatio"
>;

type ProducingObjectUnsaved = NullableFields<Params> & {
  title: string;
  mainStratumId: number | null;
};

class ProducingObjectRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    ...this.data,
    isDuplicatedTitle: this.isDuplicatedTitle,
    relatedStratums: this.relatedStratums,
    remove: this.remove,
  });

  public isDuplicatedTitle: boolean = false;
  public readonly initalStratumsEncoded: string;
  public readonly relatedStratums = observable.map<number, StratumRow>();

  public get id(): number | undefined {
    return this.producingObject?.id;
  }
  public readonly data: ProducingObjectUnsaved;

  constructor(
    private parent: ProducingObjectTable,
    private producingObject?: ProducingObject,
    stratumsMap?: Map<number, StratumRow>
  ) {
    super(parent);
    const { title, mainStratumId, densityOil, densityWater, viscosityWater, viscosityOil, gasOilRatio } =
      producingObject?.data ?? {};
    this.data = {
      title: title ?? "",
      mainStratumId: mainStratumId ?? null,
      densityOil: densityOil ?? null,
      densityWater: densityWater ?? null,
      viscosityOil: viscosityOil ?? null,
      viscosityWater: viscosityWater ?? null,
      gasOilRatio: gasOilRatio ?? null,
    };
    this.childrenStore = null;

    if (producingObject && stratumsMap) {
      const stratums = producingObject.stratums!;
      this.initalStratumsEncoded = stratums
        .map((s) => s.id)
        .sort((a, b) => a - b)
        .join(",");
      stratums.forEach(({ id }) => {
        const sRow = stratumsMap.get(id)!;
        this.relatedStratums.set(id, sRow);
        sRow.relatedProducingObject = this;
      });
    } else {
      this.initalStratumsEncoded = "";
    }

    makeObservable<ProducingObjectRow>(this, {
      id: computed,
      data: observable,
      isDuplicatedTitle: observable,
      isUpdated: override,
      isValid: computed,
      toRaw: computed,
      addStratum: action,
      removeStratum: action,
      updateValue: action,
    });
  }

  public get isUpdated() {
    if (super.isUpdated) {
      return true;
    }
    return (
      [...this.relatedStratums.values()]
        .map((s) => s.stratum.id)
        .sort((a, b) => a - b)
        .join(",") !== this.initalStratumsEncoded
    );
  }

  public get isValid(): boolean {
    const { title, mainStratumId, ...params } = this.data;
    if (title.length === 0 || mainStratumId === null) {
      return false;
    }
    if (this.isDuplicatedTitle) {
      return false;
    }
    if ([...Object.values(params)].includes(null)) {
      return false;
    }
    return true;
  }

  public get toRaw(): (Omit<ProducingObjectRaw, "id"> & { id: number | null }) | undefined {
    if (!this.isValid) {
      return undefined;
    }
    return {
      ...(this.data as any),
      id: this.id ?? null,
      projectId: this.parent.projectId,
      stratumIds: Array.from(this.relatedStratums.values(), ({ stratum }) => stratum.id),
    };
  }

  public addStratum(stratum: StratumRow) {
    this.relatedStratums.set(stratum.stratum.id, stratum);
    this.mutationsManager?.propagateMutation();
  }

  public removeStratum(stratum: StratumRow) {
    this.relatedStratums.delete(stratum.stratum.id);
    this.mutationsManager?.propagateMutation();
  }

  private remove = () => {
    if (this.index === undefined) {
      console.error("Попытка удалить объект разработки до актуализации индекса");
      return;
    }
    this.relatedStratums.forEach((st) => {
      st.updateValue?.("producingObject", null);
    });
    this.parent.removeProducingObject(this.index);
  };

  updateValue(key: string, newValue: any): [prevValue: any, currValue: any] {
    if (key === "title") {
      const prev = this.data.title;
      const curr = (this.data.title = newValue);
      this.parent.validateUniqueTitles();
      return [prev, curr];
    }
    if (
      ["mainStratumId", "densityOil", "densityWater", "viscosityWater", "viscosityOil", "gasOilRatio"].includes(key)
    ) {
      // I am really sorry but this typing is a crap
      const k = key as keyof ProducingObjectUnsaved;
      return [this.data[k], (this.data[k] = newValue as never)];
    }
    console.error("attempt to mutate immutable field");

    return [undefined, undefined];
  }
}

class ProducingObjectTable extends TableNode<DRow, ProducingObjectRow> {
  public readonly projectId: number;

  constructor(project: Project, stratumsMap: Map<number, StratumRow>) {
    super();
    this.projectId = project.id;
    this.childrenStore = new ChildrenStoreArray(
      this,
      [...project.producingObjects.values!].map((po) => new ProducingObjectRow(this, po, stratumsMap))
    );
  }

  public get isValid(): boolean {
    return this.isCompleted;
    // return this.length === 0 || [...this.children!].some(({ isValid }) => isValid);
  }

  public get isCompleted(): boolean {
    return this.length === 0 || [...this.children!].every(({ isValid }) => isValid);
  }

  public validateUniqueTitles() {
    const titles = new Set<string>();
    transaction(() => {
      for (const child of this.childrenStore?.children ?? []) {
        child.isDuplicatedTitle = titles.has(child.data.title);
        titles.add(child.data.title);
      }
    });
  }

  public findByTitle(title: string): ProducingObjectRow | undefined {
    for (const po of this.children!) {
      if (po.data.title === title) {
        return po;
      }
    }
    return undefined;
  }

  public createProducingObject = () => {
    const newProducingObject = new ProducingObjectRow(this);
    const childrenTitles = [...this.children!].map((c) => c.data.title);
    let i = 1;
    while (childrenTitles.includes(`Объект разработки ${i}`)) ++i;
    newProducingObject.data.title = `Объект разработки ${i}`;
    this.childrenStore?.push(newProducingObject);
  };

  public removeProducingObject = (id: number) => {
    this.childrenStore?.splice(id, 1);
  };
}

export type { DRow, Params };
export { ProducingObjectRow, ProducingObjectTable };
