import { FC } from "react";
import { Scale } from "@okopok/axes_context";

import { GrabPoints } from "../InfrastructureMapManager/selection";

import cn from "./selection.module.less";

type Props = {
  grabPoints: GrabPoints;
  cursorPosition: { x: number; y: number };
  strokeWidth: number;
  scale: Scale;
};

const SelectionGrab: FC<Props> = ({ grabPoints, cursorPosition, scale, strokeWidth }) => {
  const { prevPoint, nextPoint } = grabPoints;
  return (
    <>
      <line
        x1={scale.x(nextPoint.x)}
        y1={scale.y(nextPoint.y)}
        x2={scale.x(cursorPosition.x)}
        y2={scale.y(cursorPosition.y)}
        strokeWidth={strokeWidth}
        className={cn.grabLine}
      />
      <line
        x1={scale.x(prevPoint.x)}
        y1={scale.y(prevPoint.y)}
        x2={scale.x(cursorPosition.x)}
        y2={scale.y(cursorPosition.y)}
        strokeWidth={strokeWidth}
        className={cn.grabLine}
      />
    </>
  );
};

export default SelectionGrab;
