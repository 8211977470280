/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

import { axisWidth } from "theme/global";

import { FragmentInfo, useD3Context } from "../D3SVG/D3SVG";

const Line = ({ i, j, fragment }: { i: number; j: number; fragment: FragmentInfo }) => {
  const model = useD3Context();
  const refLine = useRef(null);
  const refArea = useRef(null);
  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
    const yScale = d3
      .scaleLinear()
      .domain([model.YDomains[model.curves[i].yId].min, model.YDomains[model.curves[i].yId].max])
      .range([model.height - model.padding.bottom, model.padding.top])
      .nice();
    const generateScaledLine = d3
      .line<any>()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .curve(model.curves[i].curveMethod ?? d3.curveLinear);

    d3.select(refLine.current)
      .datum(model.curves[i].points)
      .attr("d", generateScaledLine)
      .attr("fill", "none")
      .attr("stroke", fragment.color)
      .style(
        "stroke-dasharray",
        fragment.lineStyles.lineStyle === "dashed" ? `${fragment.lineStyles.dashLength ?? 5},${fragment.lineStyles.dashLength ?? 5}` : "1,0"
      )
      .style("stroke-width", fragment.lineStyles.lineWidth ?? 2);
    if (fragment.shadow) {
      const area = d3
        .area()
        .x((d) => {
          return xScale(d[0]);
        })
        .y0((d) => model.height + model.padding.top)
        .y1((d) => {
          return yScale(d[1]);
        })
        .curve(model.curves[i].curveMethod ?? d3.curveLinear);
      d3.select(refArea.current)
        .attr("d", area(model.curves[i].points.map((e: any) => [e.x, e.y])))
        .style(
          "fill",
          typeof model.curves[i].fragments[j].color === "string"
            ? model.curves[i].fragments[j].color
            : `url(#hatch-${model.curves[i].fragments[j].color})`
        )
        .style("opacity", model.curves[i].fragments[j].opacity ?? 1)
        .style("z-index", model.curves[i].zIndex ?? 1);
    }
  }, [
    model.YDomains[model.curves[i].yId].min,
    model.YDomains[model.curves[i].yId].max,
    model.currentZoomState,
    model.height,
    model.width,
    model.curves[i].points.map((point) => point.y),
  ]);
  return (
    <g>
      <path ref={refLine} clipPath={`url(#${`clipPath-${i}-${j}` + model.svgClassName})`}></path>
      {fragment.shadow ? <path ref={refArea} clipPath={`url(#${`clipPath-${i}-${j}` + model.svgClassName})`} key={300 + j}></path> : <></>}
    </g>
  );
};

export default Line;
