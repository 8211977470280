/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { formatNumber } from "elements/format/format";

import { useD3Context } from "../D3SVG/D3SVG";

import styles from "./Tooltip.module.less";
type PointInfo = {
  header: string;
  color: string | number;
  value: number;
  uom: string;
  year: number;
};
type TooltipProps = {
  left: number;
  top: number;
  pointsInfo: PointInfo[];
};

const Tooltip = observer(() => {
  const tooltipRef = useRef(null);

  const model = useD3Context();

  useEffect(() => {
    d3.select(tooltipRef.current).transition().duration(0.2);
  }, [model.tooltipState, tooltipRef.current]);
  if (model.tooltipState === null) {
    return <div></div>;
  }
  return (
    <div
      ref={tooltipRef}
      className={styles["tooltip"]}
      style={{ left: (model.tooltipState?.left ?? 150) + 0 + "px", top: (model.tooltipState?.top ?? 150) + 0 + "px" }}
    >
      {model.tooltipState.pointsInfo.map((point, index) => (
        <div key={index}>
          <div className={styles["header"]}>{point.header} </div>
          <div className={styles["valueRow"]}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
              {typeof point.color === "string" ? (
                <circle cx="4" cy="4" r="4" fill={point.color} />
              ) : (
                <circle cx="4" cy="4" r="4" fill={`url(#hatch-${point.color})`} />
              )}
            </svg>
            <div className={styles["value"]}>
              <>{formatNumber(point.value)}</>
              {point.uom}
            </div>
          </div>
        </div>
      ))}
      <div className={styles["value"]}>{model.tooltipState.pointsInfo[0].year} год</div>
    </div>
  );
});

export default Tooltip;
export type { PointInfo, TooltipProps };
