import dayjs from "dayjs";

import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Summary } from "models/project/fact/forecast/ranking/results/summary";
import { colorCarousel } from "services/colorCarousel";

import { PlanningChartModel } from "../chart/planningChartModel";

class Crews extends PlanningChartModel {
  tooltipManager: TooltipDataManager | null;
  bars: BarsDataModel;

  constructor(store: Summary) {
    super("Бригады", new Map<string, string>([["bars", "Кол-во бригад"]]));

    const rawBars: BarDataModelProps[] = [
      {
        y: store.CrewsCount.zbs.yearValues.map((value, index) => ({
          value: value,
          limit: store.CrewsLimit.zbs.yearValues[index],
        })),
        x: new Array(store.CrewsCount.zbs.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.CrewsCount.zbs.yearsRange.from + index}`)),
        key: "zbs",
        title: "ЗБС",
        color: colorCarousel(0),
      },
      {
        y: store.CrewsCount.krs.yearValues.map((value, index) => ({
          value: value,
          limit: store.CrewsLimit.krs.yearValues[index],
        })),
        x: new Array(store.CrewsCount.krs.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.CrewsCount.krs.yearsRange.from + index}`)),
        key: "krs",
        title: "КРС",
        color: colorCarousel(1),
      },
      {
        y: store.CrewsCount.eb.yearValues.map((value, index) => ({
          value: value,
          limit: store.CrewsLimit.eb.yearValues[index],
        })),
        x: new Array(store.CrewsCount.eb.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.CrewsCount.eb.yearsRange.from + index}`)),
        key: "eb",
        title: "ЭБ",
        color: colorCarousel(2),
      },
    ];
    this.bars = new BarsDataModel(rawBars, "bars", true);
    this.tooltipManager = new TooltipDataManager(undefined, this.bars);
  }
}
export { Crews };
