import { FC, useCallback } from "react";

import { setupAccuracy } from "utils/round";

import { ValidationInput, ValidationInputProps } from "./validationInput";

const toPercents = (x: number): number => x * 100;
const fromPercents = (p: number): number => p / 100;

const ValidationInputPercent: FC<ValidationInputProps> = ({ value, onSave, placeholder, min, max, ...props }) => {
  const onSavePercent: ValidationInputProps["onSave"] = useCallback(
    (value: number | null) => {
      if (value === null) {
        onSave(null);
      } else {
        onSave(fromPercents(value));
      }
    },
    [onSave]
  );

  return (
    <ValidationInput
      {...props}
      min={toPercents(min)}
      max={toPercents(max)}
      value={value === null ? value : setupAccuracy(toPercents(value), "round", 2)}
      onSave={onSavePercent}
      placeholder={placeholder === undefined ? undefined : `${placeholder} %`}
    />
  );
};

export { ValidationInputPercent };
