import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { runInAction } from "mobx";

import { DRow as InjectionDRow } from "features/injPrediction/resultsTableView/injectionSliceModel";

type DRow = {
  injMineTitle?: string;
  injCapacity?: number;
  oilCoeff?: number;
};

class InjectionTableModel extends TableNode<DRow, InjectionNode> {
  constructor(public wellPads: InjectionDRow[] | undefined) {
    super();
    runInAction(() => {
      this.childrenStore =
        this.wellPads !== undefined && this.wellPads.length > 0
          ? new ChildrenStoreArray(
              this,
              this.wellPads?.map((wellPad) => new InjectionNode(this, wellPad))
            )
          : null;
    });
  }
}

class InjectionNode extends TableNode<DRow> {
  asDRow(): DRow {
    return {
      injCapacity: this.well.injCapacity,
      injMineTitle: this.well.injWellTitle,
      oilCoeff: this.well.oilCoeff,
    };
  }
  constructor(public readonly parent: InjectionTableModel, public well: InjectionDRow) {
    super(parent);
    this.childrenStore = null;
  }
}

export { InjectionTableModel };
export type { DRow };
