import { type FC, type PropsWithChildren, type ReactNode, useCallback } from "react";
import { Button, Tooltip } from "antd";
import classNames from "classnames";
import { saveAs } from "file-saver";

import { Icon } from "elements/icon/icon";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

import { ReactComponent as ExcelIcon } from "../icons/excel.svg";

import cn from "./csvSaver.module.less";

const saveCsv = (filename: string, exportedTable: (string | null)[][]) => {
  const csvContent = exportedTable.map((row) => row.join(";")).join("\n");
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `${filename}.csv`);
};

const useOnSaveCSV = (filename: string | undefined, exportArray: () => (string | null)[][]) => {
  const projectTitle = useProject()?.title;
  const forecastTitle = useForecast()?.title;
  const name = [filename, projectTitle, forecastTitle].filter((v) => typeof v === "string").join("_");
  return useCallback(() => {
    saveCsv(name, exportArray());
  }, [name, exportArray]);
};

type CsvSaverProps = PropsWithChildren<{
  exportArray: () => (string | null)[][];
  filename?: string;
  tooltip?: ReactNode;
  className?: string;
  top?: number;
}>;

const CsvSaver: FC<CsvSaverProps> = ({ className, tooltip, filename, exportArray, children, top }) => (
  <div className={classNames(cn.container, className)}>
    <Tooltip title={tooltip}>
      <Button
        className={cn["export-button"]}
        style={{ top }}
        onClick={useOnSaveCSV(filename, exportArray)}
        icon={<Icon width="14" height="16" viewBox="0 0 14 16" content={<ExcelIcon />} />}
      />
    </Tooltip>
    {children}
  </div>
);

export { CsvSaver };
