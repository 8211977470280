import { PageFrame } from "routing/pageFrame/pageFrame";

const SystemSetup = () => {
  return (
    <PageFrame title="Настройки системы">
      <div style={{ backgroundColor: "white", padding: 10 }}>
        Раздел предназначен для централизованного администрирования ИПРМ, управления интеграциями
      </div>
    </PageFrame>
  );
};

export { SystemSetup };
