import { reqCamel } from "utils/request";

type WellItem = {
  id: number;
  x: number;
  y: number;
  points: { x: number; y: number }[];
};

type VoronoiGrid = {
  month: number;
  year: number;
  producingObject: number;
  prodWells: WellItem[];
  injWells: WellItem[];
  coefMap: {
    [injWellId: number]: {
      [prodWellId: number]: {
        inj: number;
        prod: number;
      };
    };
  };
};

async function calculateVoronoi(scenarioId: number, producingObjectId: number, date: [month: number, year: number]) {
  const [month, year] = date;
  const monthPadded = month.toString().padStart(2, "0");
  const dateString = `${year}-${monthPadded}-01`;
  const args = reqCamel.args({
    producing_object_id: producingObjectId,
    minDateIn: dateString,
    maxDateIn: dateString,
  });
  const response = await reqCamel.post<VoronoiGrid[]>(`calculation/voronoi/${scenarioId}?${args}`, null);
  console.assert(response.length === 1);
  return response[0];
}

export type { VoronoiGrid, WellItem };
export { calculateVoronoi };
