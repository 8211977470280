import { getUniversalDataSource } from "../utils";

import { getNDDFact, getNDDForecast } from "./ndd";
import { getSeveranceFact, getSeveranceForecast } from "./severance";
import { getWealthFact, getWealthForecast } from "./wealth";

const taxValues = ["Льгот нет", "Льготная", "0%", "10%"].map((title, id) => ({ title, id }));

const [
  wealthFactSource,
  wealthForecastSource,
  nddFactSource,
  nddForecastSource,
  severanceFactSource,
  severanceForecastSource,
] = getUniversalDataSource([
  { key: "WealthFact2", fn: getWealthFact }, //Налог на имущество
  { key: "Wealth3", fn: getWealthForecast },
  { key: "NDDFact1", fn: getNDDFact },
  { key: "NDD1", fn: getNDDForecast },
  { key: "SeveranceFact2", fn: getSeveranceFact }, //НДПИ
  { key: "Severance1", fn: getSeveranceForecast },
]);

const getTaxDiscounts = (): Promise<typeof taxValues> => Promise.resolve(taxValues);

export {
  getTaxDiscounts,
  nddFactSource,
  nddForecastSource,
  severanceFactSource,
  severanceForecastSource,
  wealthFactSource,
  wealthForecastSource,
};
