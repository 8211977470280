/* eslint-disable no-mixed-operators */

function hash(str: string): number {
  if (str.length === 0) return 0;
  let hash = 0,
    i,
    chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Math.random возвращает всегда новое случайное число. Когда нужно получить одну и ту же последовательность
 * случайных чисел в опоре на какое-то начальное значение (получить случайный но воспроизводимый результат)
 * можно использовать эту функцию.
 * @param seed - источник энтропии
 * @returns возвращает функцию, возвращающую одну и ту же последовательность случайных чисел в диапазоне (0, 1)
 */
function mulberry32(seed: string | number | undefined): () => number {
  if (seed === undefined) {
    return Math.random;
  }
  let a = typeof seed === "string" ? hash(seed) : seed;
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

/**
 * @param seed - источник энтропии
 * @returns цвет, соответствующий переданному зерну энтропии
 */
function randomColor(seed?: string | number): string {
  const rand = seed === undefined ? Math.random : mulberry32(seed);
  return `#${[rand(), rand(), rand()].map((v) => `0${Math.floor(0x100 * v).toString(16)}`.slice(-2)).join("")}`;
}

function getRandomUid() {
  return `${Date.now().toString(16)}-${Math.floor(Math.random() * 0x1000000).toString(16)}`;
}

function getRandomNumberId(): number {
  return (Date.now() % 1000000) + Math.floor(Math.random() * 1000000) * 1000000;
}

export { getRandomNumberId, getRandomUid, randomColor, mulberry32 as randomEngine };
