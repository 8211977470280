import { BackendStorageMock } from "services/back/backendStorage";
import { getEcyResolver } from "services/back/ecy";
import { Range } from "utils/range";

import { pathECYWithBase, pathECYWithCurrent } from "./ecyPatcher";
import type { MetricTree } from "./utils";
import { riseMetrics } from "./utils";

export type ECYAsSourceFormat = {
  id: number;
  title: string;
  usc: MetricTree[];
  discount: number;
};

const getEcy = async (range: Range) => {
  const { schema_usc } = await getEcyResolver(range);
  const metricSchema = riseMetrics(schema_usc);

  const originEcy = { id: -1000, title: "Неизвестное ЕСУ", usc: metricSchema.slice(1), discount: 10 };

  const baseEcy = pathECYWithBase(range, structuredClone(originEcy));
  const currentEcy = pathECYWithCurrent(range, structuredClone(originEcy));

  return [baseEcy, currentEcy]; // TODO: Поидее эти элементы должны подмешиваться, в случае когда с бека пришли данные, но в них эти элементы отсутсвуют
};

const ecySource = (key: { fact: number }) => {
  const [get, set] = new BackendStorageMock<Range, ECYAsSourceFormat[], ECYAsSourceFormat[]>("EcyParams2-", key.fact)
    .decorators;
  return [
    (r: Range) => get(getEcy)(r),
    (
      f: {
        id: number;
        title: string;
        usc: MetricTree[];
        discount: number;
      }[]
    ) => set(async (r) => r)(f),
  ] as const;
};

export { ecySource };
