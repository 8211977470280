import { useFacilitiesMinesTableModel } from "../useFacilitiesMinesTableModel";
import { useFacilitiesPipesTableModel } from "../useFacilitiesPipesTableModel";
import { useFacilitiesStationsTableModel } from "../useFacilitiesStationsTableModel";

const useFacilitiesTableModel = (mode?: "prod" | "inj" | "mines" | "stations") => {
  const { columns: minesColumns, store: minesStore } = useFacilitiesMinesTableModel();
  const { columns: prodColumns, store: oilStore } = useFacilitiesPipesTableModel("prod");
  const { columns: injColumns, store: waterStore } = useFacilitiesPipesTableModel("inj");
  const { columns: stationsColumns, store: stationsStore } = useFacilitiesStationsTableModel();

  if (mode === "prod") {
    return { columns: prodColumns, store: oilStore };
  }
  if (mode === "inj") {
    return { columns: injColumns, store: waterStore };
  }
  if (mode === "stations") {
    return { columns: stationsColumns, store: stationsStore };
  }
  return { columns: minesColumns, store: minesStore };
};

export { useFacilitiesTableModel };
