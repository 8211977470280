const convertPressureFn = (): {
  barToMPa: (bar?: number | null) => number | undefined;
  mpaToBar: (mpa?: number | null) => number | undefined;
} => {
  const barToMPa = (bar?: number | null) => {
    if (!bar) return;
    if (bar === 0) return 0;
    return Number((bar / 10).toFixed(4));
  };
  const mpaToBar = (mpa?: number | null) => {
    if (!mpa) return;
    if (mpa === 0) return 0;
    return Number((mpa * 10).toFixed(4));
  };

  return { barToMPa, mpaToBar };
};

const convertPressure = convertPressureFn();

export { convertPressure };
