import { useMemo } from "react";
import { observer } from "mobx-react";

import { useForecast } from "models/project/fact/forecast/forecast";

import { useOperatingGTMContext } from "../operatingGTM";

import { DecreaseEffectChart } from "./decreaseEffectChart";
import { DecreaseEffectChartModel } from "./decreaseEffectChartModel";

const DecreaseEffectChartFeature = observer(() => {
  const forecast = useForecast()!;
  const calcModel = useOperatingGTMContext()!.CalcEffectTableModel.calcModel;

  const chartModel = useMemo(() => {
    return new DecreaseEffectChartModel(forecast, calcModel);
  }, [calcModel, forecast]);
  return (
    <div style={{ display: "flex", flex: 1, height: "100%" }}>
      <DecreaseEffectChart chartModel={chartModel} />
    </div>
  );
});

export { DecreaseEffectChartFeature };
