import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { Summary as SummaryModel } from "models/project/fact/forecast/ranking/results/summary";
import { useRankingResults } from "pages/ranking/rankingResultsContext";

import cn from "./results.module.less";

const useColumns = (forecast: Forecast) =>
  useMemo<Column[]>(
    () => [
      {
        dataKey: "param",
        title: "Параметр",
        type: "string",
        isSticky: true,
        width: { min: 300, max: 600, competitiveness: 2 },
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        type: "string",
        isSticky: true,
        width: 130,
      },
      ...[...forecast.range].map(
        (year): Column => ({
          dataKey: `${year}`,
          title: year,
          type: "number",
          width: 150,
          onCell: () => ({ className: cn.tableCellAlignRight }),
          onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        })
      ),
    ],
    [forecast]
  );

const Summary: FC = observer(() => {
  const forecast = useForecast()!;
  const results = useRankingResults();
  const store = useMemo(() => new SummaryModel(forecast), [forecast]);
  const columns = useColumns(forecast);

  return (
    <SimpleTableContext
      exportFileName="Сводные показатели"
      columns={columns}
      data={store}
      hideExpandColumn
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
      tableOptions={{
        onRow: ({ expand, indexPath }) => ({ className: expand && (indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary) }),
      }}
    >
      <PageFrameTitlePortal model={results} submitting={results.isSaving} onSave={results.save} permissionSection="ranking" />
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { Summary };
