/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react-lite";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";

import styles from "./YearLabels.module.less";
type YearLabelType = {
  year: number;
  label: { text: string; level: 1 | 2; align: "top" | "bottom" };
};

const YearLabel = observer(({ year, label }: YearLabelType) => {
  const model = useD3Context();
  const refTop1 = useRef(null);
  const refTop2 = useRef(null);
  const refBottom1 = useRef(null);
  const refBottom2 = useRef(null);

  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
    if (label.level === 1 && label.align === "top") {
      d3.select(refTop1.current)
        .style("text-anchor", "middle")
        .text(label.text)
        .attr("y", model.padding.top + 13)
        .attr("x", xScale(new Date(year, 6, 1)));
    }
    if (label.level === 2 && label.align === "top") {
      d3.select(refTop2.current)
        .style("text-anchor", "middle")
        .text(label.text)
        .attr("y", model.padding.top + 25)
        .attr("x", xScale(new Date(year, 6, 1)));
    }
    if (label.level === 1 && label.align === "bottom") {
      d3.select(refBottom1.current)
        .style("text-anchor", "middle")
        .text(label.text)
        .attr("y", model.height - model.padding.top - 13)
        .attr("x", xScale(new Date(year, 6, 1)));
    }
    if (label.level === 2 && label.align === "bottom") {
      d3.select(refBottom2.current)
        .style("text-anchor", "middle")
        .text(label.text)
        .attr("y", model.height - model.padding.top - 27)
        .attr("x", xScale(new Date(year, 6, 1)));
    }
  }, [
    model.currentZoomState,
    model.leftAxisCount,
    model.padding.left,
    model.padding.right,
    model.rightAxisCount,
    model.width,
    model.xAxis.domain.max,
    model.xAxis.domain.min,
  ]);
  return (
    <g clipPath={`url(#clipPath-${model.svgClassName})`}>
      <text ref={refTop1} className={styles.level1}></text>
      <text ref={refTop2} className={styles.level2}></text>
      <text ref={refBottom1} className={styles.level1}></text>
      <text ref={refBottom2} className={styles.level2}></text>
    </g>
  );
});

const YearLabels = ({ yearLabels }: { yearLabels: YearLabelType[] }) => {
  return (
    <g>
      {yearLabels.map((label, index) => (
        <YearLabel key={index} year={label.year} label={label.label}></YearLabel>
      ))}
    </g>
  );
};

export default YearLabels;
export type { YearLabelType };
