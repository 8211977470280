import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { AnalizReport as AnalizReportFeature } from "features/report/analizReport/analizReport";
import { useFact } from "models/project/fact/fact";

const AnalizReport: FC = observer(() => {
  return (
    <Preloader hooks={[useFact]}>
      <PageFrame title="Анализ">
        <AnalizReportFeature />
      </PageFrame>
    </Preloader>
  );
});

export { AnalizReport };
