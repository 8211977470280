import { makeAutoObservable, reaction } from "mobx";

import { CalcEffectTableNode, TableRow } from "../calcEffectTableModel";
import { CalcModel } from "../calcModel";

class CalcModelOtherGTM {
  public summary: TableRow[];
  public other: TableRow[][];
  public rawTransferringMining: (number | null)[][] = [];

  constructor(public node: CalcEffectTableNode, public calcModel: CalcModel) {
    makeAutoObservable(this);

    this.summary = [];
    Array.from(
      Array.from(node.children!).find((child) => child.rowInfo.title === "Интегральные показатели")!.children!
    ).forEach((child) => this.summary.push(child));
    this.other = [];
    Array.from(node.children!)
      .filter((child) => child.rowInfo.title !== "Интегральные показатели")!
      .forEach((block) => {
        this.other.push(Array.from(block.children!));
      });

    this.calcRawTransferring();

    // Количество ГТМ

    reaction(
      () => [
        ...this.other.map((block) => Object.values(block.find((row) => row.rowInfo.title === "Количество ГТМ")!.data)),
        ...Object.values(this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data),
      ],
      () => {
        Object.keys(this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data).forEach((year, index) => {
          if (index < 3) {
            const newValue = this.other
              .map((block) => block.find((row) => row.rowInfo.title === "Количество ГТМ")!)
              .reduce((prev, curr) => prev + (curr.data[+year] ?? 0), 0);
            this.summary
              .find((row) => row.rowInfo.title === "Количество ГТМ")!
              .mutationsManager?.updateWrapper(+year, newValue);
          } else {
            if (
              this.calcModel.miningWellsModel.wellsAmount.data[+year] !== null &&
              this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data[+year] !== null
            ) {
              const newValue =
                this.calcModel.miningWellsModel.wellsAmount.data[+year]! *
                this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data[+year]!;
              this.summary
                .find((row) => row.rowInfo.title === "Количество ГТМ")!
                .mutationsManager?.updateWrapper(+year, newValue);
            }
          }
        });
      }
    );

    reaction(
      () =>
        Object.values(
          this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!.data
        ),
      () => {
        const lastUpdatedKey = this.summary.find(
          (row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени"
        )!.lastUpdatedKey;

        Object.keys(
          this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!.data
        ).forEach((year, index) => {
          if (
            lastUpdatedKey !== null &&
            isFinite(lastUpdatedKey) &&
            this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!.data[
              lastUpdatedKey
            ] !== null
          ) {
            if (+year > lastUpdatedKey) {
              this.summary
                .find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!
                .mutationsManager?.updateWrapper(
                  +year,
                  this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!.data[
                    lastUpdatedKey
                  ]
                );
            }
          }
        });
      }
    );

    reaction(
      () => [
        this.decreaseCoefficientsForTransfering,
        ...Object.values(
          this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data
        ),
        ...Object.values(
          this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data
        ),
        ...Object.values(this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data),
        this.calcModel.getFirstNElements(this.calcModel.miningWellsModel.serviceCoefficient, 1),
      ],
      () => {
        this.calcRawTransferring();

        for (let i = 0; i < this.calcModel.range.length; ++i) {
          let result = null;

          for (let j = 0; j < this.calcModel.range.length; ++j) {
            if (this.rawTransferringMining[j][i]) {
              if (result === null) {
                result = this.rawTransferringMining[j][i];
              } else {
                result += this.rawTransferringMining[j][i]!;
              }
            }
          }
          if (this.rawTransferringMining[i][i] !== null && result !== null) {
            result -= this.rawTransferringMining[i][i]!;
          }
          this.summary
            .find((row) => row.rowInfo.title === "Переходящая доп. добыча от ГТМ")!
            .mutationsManager?.updateWrapper(this.calcModel.range.from + i, result);
        }
      }
    );

    reaction(
      () => [
        ...Object.values(this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data),
        ...Object.values(this.calcModel.miningWellsModel.wellsAmount.data),
      ],
      () => {
        Object.keys(this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data).forEach(
          (year, index) => {
            if (index < 3) {
              if (
                this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+year] !== null &&
                this.calcModel.miningWellsModel.wellsAmount.data[+year] !== null
              ) {
                this.summary
                  .find((row) => row.rowInfo.title === "Удельное количество ГТМ")!
                  .mutationsManager?.updateWrapper(
                    +year,
                    this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+year]! /
                      this.calcModel.miningWellsModel.wellsAmount.data[+year]!
                  );
              }
            } else {
              const meanItems = [
                this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data[
                  this.calcModel.range.from
                ],
                this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data[
                  this.calcModel.range.from + 1
                ],
                this.summary.find((row) => row.rowInfo.title === "Удельное количество ГТМ")!.data[
                  this.calcModel.range.from + 2
                ],
              ].filter((item) => item !== null);
              const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;
              this.summary
                .find((row) => row.rowInfo.title === "Удельное количество ГТМ")!
                .mutationsManager?.updateWrapper(+year, mean);
            }
          }
        );
      }
    );

    reaction(
      () => [
        ...this.other.map((block) =>
          Object.values(block.find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!.data)
        ),
        ...Object.values(this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data),
        ...Object.values(
          this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data
        ),
        ...Object.values(
          this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data
        ),
      ],
      () => {
        Object.keys(
          this.summary.find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!.data
        ).forEach((year, index) => {
          if (index < 3) {
            const newValue = this.other
              .map((block) => block.find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!)
              .reduce((prev, curr) => prev + (curr.data[+year] ?? 0), 0);
            this.summary
              .find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!
              .mutationsManager?.updateWrapper(+year, newValue);
          } else {
            if (
              this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+year] !== null &&
              this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data[
                +year
              ] !== null &&
              this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                +year
              ] !== null
            ) {
              const newValue =
                (this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+year]! *
                  this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data[
                    +year
                  ]! *
                  this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                    +year
                  ]!) /
                1000;
              this.summary
                .find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!
                .mutationsManager?.updateWrapper(+year, newValue);
            }
          }
        });
      }
    );
    reaction(
      () => [
        ...Object.values(this.summary.find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!.data),
        ...Object.values(
          this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!.data
        ),
        ...Object.values(this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data),
        ...Object.values(
          this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data
        ),
      ],
      () => {
        Object.keys(
          this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data
        ).forEach((year, index) => {
          if (index < 3) {
            if (
              this.summary.find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!.data[+year] !==
                null &&
              this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+year] != null &&
              this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                +year
              ] !== null
            ) {
              this.summary
                .find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")
                ?.mutationsManager?.updateWrapper(
                  +year,
                  (this.summary.find((row) => row.rowInfo.title === "Доп. добыча за год проведения ГТМ")!.data[+year]! *
                    1000) /
                    this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+year]! /
                    this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                      +year
                    ]!
                );
            }
          } else {
            const meanItems = [
              this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data[
                this.calcModel.range.from
              ],
              this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data[
                this.calcModel.range.from + 1
              ],
              this.summary.find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")!.data[
                this.calcModel.range.from + 2
              ],
            ].filter((item) => item !== null);
            const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;
            if (
              this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!.data[
                +year
              ] !== null
            ) {
              this.summary
                .find((row) => row.rowInfo.title === "Средний прирост дебита на одну скважину")
                ?.mutationsManager?.updateWrapper(
                  +year,
                  mean *
                    this.summary.find((row) => row.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени")!
                      .data[+year]!
                );
            }
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.getFirstNElements(
          this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!
        ),
      ],
      () => {
        Object.keys(
          this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data
        ).forEach((year, index) => {
          if (index > 2) {
            if (
              this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                this.calcModel.range.from
              ] !== null ||
              this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                this.calcModel.range.from + 1
              ] !== null ||
              this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                this.calcModel.range.from + 2
              ] !== null
            ) {
              const meanItems = [
                this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                  this.calcModel.range.from
                ],
                this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                  this.calcModel.range.from + 1
                ],
                this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
                  this.calcModel.range.from + 2
                ],
              ].filter((item) => item !== null);
              const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

              this.summary
                .find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!
                .mutationsManager?.updateWrapper(+year, mean);
            }
          }
        });
      }
    );
  }

  get decreaseCoefficientsForTransfering() {
    return Array.from(
      this.calcModel.forecast.decreaseCoefficients.coefficients
        .find((coeff) => coeff.key === "otherGTM")
        ?.data?.values() ?? []
    );
  }

  calcRawTransferring = () => {
    const coefficients = Array.from(
      this.calcModel.forecast.decreaseCoefficients.coefficients
        .find((coeff) => coeff.key === "otherGTM")
        ?.data?.values() ?? []
    );

    const debetIncrease: (number | null)[][] = new Array(this.calcModel.range.length)
      .fill(null)
      .map((value, index) => new Array(coefficients.length).fill(null));

    for (let i = 0; i < debetIncrease.length; ++i) {
      debetIncrease[i][0] = this.summary.find(
        (row) => row.rowInfo.title === "Средний прирост дебита на одну скважину"
      )!.data[this.calcModel.range.from + i];
      for (let j = 1; j < debetIncrease[i].length; ++j) {
        if (debetIncrease[i][j - 1] !== null && coefficients[i] !== null) {
          debetIncrease[i][j] = debetIncrease[i][j - 1]! * coefficients[i]!;
        }
      }
    }

    this.rawTransferringMining = new Array(this.calcModel.range.length)
      .fill(null)
      .map((value, index) => new Array(this.calcModel.range.length + coefficients.length + 1).fill(null));

    for (let i = 0; i < this.calcModel.range.length; ++i) {
      if (
        debetIncrease[i][0] !== null &&
        this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+this.calcModel.range.from + i] !==
          null &&
        this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
          +this.calcModel.range.from + i
        ] !== null
      ) {
        this.rawTransferringMining[i][i] =
          (debetIncrease[i][0]! *
            this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+this.calcModel.range.from + i]! *
            this.summary.find((row) => row.rowInfo.title === "Среднее количество дней работы скважины")!.data[
              +this.calcModel.range.from + i
            ]!) /
          1000;

        for (let j = 1; j < debetIncrease[i].length; ++j) {
          if (
            debetIncrease[i][j] !== null &&
            this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[+this.calcModel.range.from + i] !==
              null &&
            this.calcModel.miningWellsModel.serviceCoefficient.data[+this.calcModel.range.from + i] !== null
          )
            this.rawTransferringMining[i][i + j] =
              (debetIncrease[i][j]! *
                this.summary.find((row) => row.rowInfo.title === "Количество ГТМ")!.data[
                  +this.calcModel.range.from + i
                ]! *
                365 *
                this.calcModel.miningWellsModel.serviceCoefficient.data[+this.calcModel.range.from + i]!) /
              1000;
        }
      }
    }
  };
}
export { CalcModelOtherGTM };
