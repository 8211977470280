import type { FC } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";

import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import { useTechForecastModel } from "../useTechForecastModel";

import cn from "./wellsGroup.module.less";

type WellsGroupProps = {
  index: number;
};

const WellsGroup: FC<WellsGroupProps> = observer(({ index: rawIndex }) => {
  const model = useTechForecastModel();
  const index = model.wells.groupAt(rawIndex);
  const wells = model.wells.at(index);
  if (wells.length === 0) {
    return (
      <div>
        {index} {rawIndex}
      </div>
    );
  }
  return (
    <div className={cn.group} onClick={model.collapseGroupHolder(index)}>
      <Icon content={<ArrowIcon />} className={classNames(cn.icon, model.collapsed.has(index) && cn.collapsed)} viewBox="0 0 8 4" />
      {model.groupTitle(index)}
    </div>
  );
});

export { WellsGroup };
