class Range {
  constructor(public readonly from: number, public readonly to: number) {
    console.assert(typeof from === "number" && typeof to === "number", `wrong range value ${typeof from} ${typeof to}`);
    console.assert(from < to, `wrong range value ${from} >= ${to}`);
  }

  [Symbol.iterator] = function* (this: Range) {
    for (let i = this.from; i < this.to; ++i) {
      yield i;
    }
  };

  get length(): number {
    return this.to - this.from;
  }

  includes(val: number): boolean {
    return val >= this.from && val < this.to;
  }

  static join(a: Range, b: Range): Range {
    console.assert(a.to === b.from, `attempt to concatenate torn range ${a.to} !== ${b.from}`);
    return new Range(a.from, b.to);
  }

  get array() {
    return new Array(this.to - this.from);
  }

  id(val: number): number {
    return val - this.from;
  }

  toString(): string {
    return `[${this.from}, ${this.to})`;
  }
}

export { Range };
