import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

import { ForecastResult } from "models/project/fact/forecast/forecastResult";
import { OptimizerModal } from "models/project/fact/forecast/optimizerModal";
import { OptimizerModal as OptimizerModalModel } from "models/project/fact/forecast/optimizerModal";
import { useProject } from "models/project/project";

import { useColumns } from "./wells";

const ProjectsContext = createContext<OptimizerModal | null>(null);

type OptimizerModalContextProviderProps = {
  result?: ForecastResult;
} & PropsWithChildren;

const OptimizerModalContextProvider = ({ result, children }: OptimizerModalContextProviderProps) => {
  const startYear = useProject()!.fact.factRange.to;
  const columns = useColumns(true, startYear);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const model = useMemo(() => new OptimizerModalModel(result, columns, startYear), []);
  return <ProjectsContext.Provider value={model}>{children}</ProjectsContext.Provider>;
};

const useOptimizerModalContext = (): OptimizerModal => {
  console.assert(useContext<OptimizerModal | null>(ProjectsContext) !== null, "useOptimizerModalContext out of context");
  return useContext<OptimizerModal | null>(ProjectsContext)!;
};

export { OptimizerModalContextProvider, useOptimizerModalContext };
