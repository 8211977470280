import { FC, HTMLAttributes, PropsWithChildren, ReactNode, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableItem, TableModel, TableNode } from "@okopok/components/Table";
import { Theme } from "@okopok/components/Table/models/theme";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Checkbox, DatePicker, Popconfirm, SelectProps, Switch, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import locale from "antd/es/date-picker/locale/ru_RU";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { DeleteButton } from "elements/deleteButton/deleteButton";
import { EditRowButton } from "elements/editRowButton/editRowButton";
import { DATEPICKER_FORMAT_OPTIONS, Format } from "elements/format/format";
import { formatData, FormatDataAdditionalProps } from "elements/format/formatData";
import { LazyInput } from "elements/inputs/lazyInput/lazyInput";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { Select } from "elements/inputs/select";
import { SelectStorable, SelectStoreType } from "elements/inputs/selectStorable/selectStorable";
import { ValidationInput } from "elements/inputs/validationInput/validationInput";
import { useTableSettings } from "models/tableSettings";
import { type GenericTableRow } from "services/back/genericTable/genegicTableService";
import { conditionallyArr } from "utils/conditionally";

import { ColumnFilter, SimpleTableFilter } from "./simpleTableFilters";

import cn from "./simpleTable.module.less";

const disabledDate = (date: Dayjs, picker: keyof typeof DATEPICKER_FORMAT_OPTIONS, start?: Dayjs, end?: Dayjs) => {
  if (!start && !end) return false;
  if (picker === "month") {
    return !date || (end !== undefined && date.isAfter(end.add(1, "month"))) || (start !== undefined && date.isBefore(start));
  }
  if (picker === "year") {
    return !date || (end !== undefined && date.isAfter(end)) || (start !== undefined && date.isBefore(start));
  }

  return false;
};

const SelectAll = observer(({ store }: { store: TableNode<any, any> }) => {
  const { selectManager } = store;
  const onChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      selectManager?.propagateSelected();
    } else {
      selectManager?.propagateDeselected();
    }
  };
  return <Checkbox indeterminate={selectManager?.isPartiallySelected} checked={selectManager?.isSelected} onChange={onChange} />;
});

type TableOptions<DRow> = {
  // TODO: Сейчас из общих компонентов это не экспортируется, надо будет импортировать существующий тип
  onRow?: (tableItem: TableItem<DRow>, rowIdx: number) => HTMLAttributes<HTMLDivElement>;
  expandRow?: (tableItem: TableItem<DRow>, index: number) => ReactNode;
  isRowEditable?: (tableItem: TableItem<DRow>, rowIdx: number) => boolean;
};

const withFirstColumnPadding = (
  columnIndex: number,
  onCell?: (tableItem: TableItem<any>, rowIndex: number) => HTMLAttributes<HTMLDivElement>,
  hasEditableCells?: boolean
) => {
  return columnIndex === 0
    ? (tableItem: TableItem<any>, rowIndex: number) => {
        const derived = onCell?.(tableItem, rowIndex);
        return {
          ...derived,
          style: { paddingLeft: 12 * tableItem.indexPath.length, ...derived?.style },
          className: classNames(hasEditableCells && cn.afterButton, derived?.className),
        };
      }
    : onCell;
};

const getRenderer = (
  column: Column,
  hasEditableCells: boolean,
  tableOptions?: TableOptions<unknown> | undefined,
  customRenders?: Record<string, (value: number | boolean | null | undefined, row: GenericTableRow) => ReactNode>
) => {
  if (customRenders && customRenders[column.key!]) {
    return (value: any, row: GenericTableRow) => customRenders[column.key!](value, row);
  }
  const editable = {
    number: (v: number | null | undefined, row: TableItem<GenericTableRow>) => {
      if (column.type !== "number") return;
      const isRowEditable = tableOptions?.isRowEditable?.(row, row.indexPath[0]) ?? true;
      const isCellEditable = typeof column.editable === "function" ? column.editable(row) : true;
      const tooltip = typeof column.tooltip === "function" ? column.tooltip(row) : column.tooltip;
      if (row.update && isRowEditable && isCellEditable) {
        if (column.min && column.max) {
          return (
            <Tooltip title={tooltip}>
              <div className={cn.tooltipWrapper}>
                <ValidationInput
                  value={v ?? null}
                  min={column.min}
                  max={column.max}
                  onSave={(n) => row.update!(column.dataKey, n)}
                  variant="borderless"
                />
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip title={tooltip}>
            <div className={cn.tooltipWrapper}>
              <LazyInputNumber onUpdate={(n: number | null) => row.update!(column.dataKey, n)} value={v ?? null} />
            </div>
          </Tooltip>
        );
      }
      return (
        <Tooltip title={tooltip}>
          <div className={cn.tooltipWrapper}>
            <Format>{v ?? null}</Format>
          </div>
        </Tooltip>
      );
    },
    string: (v: string, row: TableItem<GenericTableRow>) => {
      const isRowEditable = tableOptions?.isRowEditable?.(row, row.indexPath[0]) ?? true;
      return row.update && isRowEditable ? (
        <LazyInput
          variant="borderless"
          onChange={(n: string | null) => {
            row.update!(column.dataKey, n);
          }}
          value={v ?? null}
        />
      ) : (
        <Format>{v}</Format>
      );
    },
    set: (v: number | boolean | null | undefined, row: TableItem<GenericTableRow>) => {
      if (row.expand?.status !== undefined) return <Format>{v ?? null}</Format>;
      return row.update ? (
        typeof v === "boolean" ? (
          <Switch
            checked={v ?? false}
            checkedChildren="Да"
            unCheckedChildren="Нет"
            onChange={(checked: boolean) => {
              row.update!(column.dataKey, checked);
            }}
          />
        ) : (
          <LazyInputNumber
            onUpdate={(n: number | null) => {
              row.update!(column.dataKey, n);
            }}
            value={v ?? null}
          />
        )
      ) : (
        <Format>{v}</Format>
      );
    },
    select: (v: [id: number | null, title: string | undefined] | string | number, row: TableItem<GenericTableRow>) => {
      const tooltip = typeof column.tooltip === "function" ? column.tooltip(row) : column.tooltip;
      if (column.type === "select") {
        if (!row.update) {
          return (
            <Tooltip title={tooltip}>
              <Format>{Array.isArray(v) ? v[0] ?? v[1] ?? null : v || null}</Format>
            </Tooltip>
          );
        }
        if (Array.isArray(v) && column.options && typeof column.options !== "function" && !Array.isArray(column.options)) {
          return (
            <Tooltip title={tooltip}>
              <SelectStorable
                values={v}
                store={column.options}
                setValues={(n: number | null, sV?: string | undefined) => row.update?.(column.dataKey, [n, sV])}
              />
            </Tooltip>
          );
        }

        const options = typeof column.options === "function" ? column.options(row) ?? [] : Array.isArray(column.options) ? column.options : [];
        return (
          <Tooltip title={tooltip}>
            <Select value={v} options={options} variant="borderless" onChange={(n) => row.update?.(column.dataKey, n)} />
          </Tooltip>
        );
      }
    },
    boolean: (v: boolean) => <Format>{v}</Format>,
    date: (v: number | string | Date | Dayjs, tableItem: TableItem<GenericTableRow>) => {
      if (tableItem.expand?.status !== undefined) return <Format>{v ?? null}</Format>;
      if (column.type === "date") {
        const renderFormat = column.renderFormat || "date";
        const start = typeof column.start === "function" ? column.start(tableItem) : column.start;
        const end = typeof column.end === "function" ? column.end(tableItem) : column.end;

        if (tableItem.value?.notEditable) {
          return (
            <Format renderFormat={renderFormat} disabled>
              {v ? dayjs(v) : null}
            </Format>
          );
        }
        return (
          <DatePicker
            value={dayjs(v)}
            picker={column.renderFormat !== "moment" ? column.renderFormat : undefined}
            format={DATEPICKER_FORMAT_OPTIONS[renderFormat]}
            onChange={(date) => tableItem.update?.(column.dataKey, date)}
            variant="borderless"
            locale={locale}
            disabledDate={(date) => disabledDate(date, renderFormat, start, end)}
          />
        );
      }
    },
  } as const;
  const notEditable = {
    number: (v: number, row: TableItem<GenericTableRow>) => (
      <Tooltip title={typeof column.tooltip === "function" ? column.tooltip(row) : column.tooltip}>
        <div className={cn.tooltipWrapper}>
          <Format>{v ?? null}</Format>
        </div>
      </Tooltip>
    ),
    set: (v: number) => <Format>{v ?? null}</Format>,
    string: (v: string, row: TableItem<GenericTableRow>, index: number, widths?: number[]) => {
      const isRowEditable = tableOptions?.isRowEditable?.(row, row.indexPath[0]) ?? true;
      const notEditableString = row?.expand?.status;
      const showEditRowBtn = hasEditableCells && column.showEditRowBtn && !notEditableString && isRowEditable;

      return (
        <>
          <Format ellipsisLimit={widths![index] / (column.factor ?? 8)}>{v ?? null}</Format>
          {showEditRowBtn && <EditRowButton row={row} />}
        </>
      );
    },
    select: (v: [id: number | null, title: string | undefined] | string | number, row: TableItem<GenericTableRow>) => {
      const tooltip = typeof column.tooltip === "function" ? column.tooltip(row) : column.tooltip;
      if (column.type === "select") {
        const options = typeof column.options === "function" ? column.options(row) : column.options;
        if (!row.update) {
          return (
            <Tooltip title={tooltip}>
              <Format>{Array.isArray(v) ? v[0] ?? v[1] ?? null : v || null}</Format>
            </Tooltip>
          );
        }
        return (
          <Tooltip title={tooltip}>
            <Select
              open={false}
              variant="borderless"
              value={Array.isArray(v) ? v[0] : v}
              options={options && "selector" in options ? options.selector : options}
              onChange={(n) => row.update?.(column.dataKey, n)}
            />
          </Tooltip>
        );
      }
    },
    boolean: (v: boolean) => <Format>{v}</Format>,
    date: (v: number | string | Date | Dayjs) => {
      if (column.type === "date") {
        const renderFormat = column.renderFormat || "date";
        return (
          <Format renderFormat={renderFormat} disabled>
            {v ? dayjs(v) : null}
          </Format>
        );
      }
    },
  } as const;

  if (column.editable || typeof column.editable === "function") {
    return editable[column.type];
  } else {
    return notEditable[column.type];
  }
};

class GenericTableData extends TableNode<GenericTableRow, GenericTableData> {}

type Column = Omit<ColumnRaw<GenericTableRow>, "render" | "title" | "width"> &
  (
    | {
        type: "number";
        min?: number;
        max?: number;
      }
    | {
        type: "string";
      }
    | {
        type: "set";
      }
    | {
        type: "boolean";
        yes?: string;
        no?: string;
      }
    | {
        type: "select";
        options: SelectStoreType | SelectProps["options"] | ((tableItem: TableItem<GenericTableRow>) => SelectProps["options"]);
      }
    | {
        type: "date";
        renderFormat?: "date" | "moment" | "month" | "year";
        start?: ((tableItem: TableItem<GenericTableRow>) => Dayjs) | Dayjs;
        end?: ((tableItem: TableItem<GenericTableRow>) => Dayjs) | Dayjs;
      }
  ) & {
    title?: string | ReactNode;
    width?: ColumnRaw<GenericTableRow>["width"];
    editable?: boolean | ((tableItem: TableItem<GenericTableRow>) => boolean);
    factor?: number;
    showEditRowBtn?: boolean;
    tooltip?: string | ((tableItem: TableItem<GenericTableRow>) => string);
    hideFilter?: boolean;
  };

type SimpleTableProps = PropsWithChildren<{
  data: TableNode<GenericTableRow, GenericTableData> | undefined;
  columns: Column[];
  exportFileName: string;
  hideExpandColumn?: boolean;
  tableOptions?: TableOptions<any> | undefined;
  showIndexColumn?: boolean;
  showSelectColumn?: boolean;
  theme?: Partial<Theme>;
  customRenders?: Record<string, (value: any, row: GenericTableRow) => ReactNode>;
  topExportIcon?: number;
  tableSettingsId?: string;
  selectable?: boolean;
}>;

const formatDataOptions: FormatDataAdditionalProps = { unit: "locale_C" };

const SimpleTableContext: FC<SimpleTableProps> = observer(
  ({
    exportFileName,
    columns,
    data,
    children,
    hideExpandColumn: alwaysExpanded = false,
    tableOptions,
    showIndexColumn = true,
    theme,
    showSelectColumn,
    customRenders,
    topExportIcon,
    tableSettingsId,
    selectable = false,
  }) => {
    const hasEditableCells = columns.some((column) => column.editable);
    const hasRemoveFunc = ((data?.children ?? []) as { remove: () => void }[]).some((el) => "remove" in el && typeof el.remove === "function");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableFilter = useMemo(() => new SimpleTableFilter(formatDataOptions), [columns]);

    const model = useMemo(() => {
      if (!data) {
        return undefined;
      }

      return new TableModel<GenericTableRow>(
        [
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(selectable, {
            key: "selectInfo",
            title: <SelectAll store={data} />,
            width: 34,
            isSticky: true,
            isExported: false,
            render: (_, { select }) => {
              if (select === undefined) return undefined;
              const onChange = (event: CheckboxChangeEvent) => {
                if (event.target.checked) {
                  select.onSelect();
                } else {
                  select.onDeselect();
                }
              };
              return <Checkbox indeterminate={select.status === "partiallySelected"} checked={select.status === "selected"} onChange={onChange} />;
            },
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(showIndexColumn, {
            key: "index",
            title: "No.пп",
            isSticky: true,
            width: { min: 54, max: 54, competitiveness: 1 },
            render: (_, { absoluteIndex }) => absoluteIndex ?? <div />,
            onCell: () => ({ style: { justifyContent: "right" } }),
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(!alwaysExpanded, {
            key: "expand",
            title: null,
            isSticky: true,
            width: { min: 32, max: 32, competitiveness: 1 },
            render: (_: any, tableItem: any) =>
              tableItem.expand !== undefined && tableItem.expand.status !== undefined && <ExpandButton expand={tableItem.expand} />,
            isExported: false,
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(!!showSelectColumn, {
            key: "select",
            title: null,
            isSticky: true,
            width: { min: 32, max: 32, competitiveness: 1 },
            render: (_: any, tableItem: any) => {
              return (
                tableItem.value.select && (
                  <Tooltip title={tableItem.value.select.tooltip}>
                    <Checkbox
                      className={cn["exclude-checkbox"]}
                      checked={tableItem.value.select.checked}
                      onChange={tableItem.value.select.onSelect}
                    />
                  </Tooltip>
                )
              );
            },
            isExported: false,
          }),
          ...columns.map((column, columnIndex) => {
            const renderFunc = getRenderer(column, hasEditableCells, tableOptions, customRenders);

            return {
              ...column,
              title: column.hideFilter ? (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  {column.title ?? column.key}
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  {column.title ?? column.key}
                  <ColumnFilter tableData={data} tableFilter={tableFilter} column={column} />
                </div>
              ),
              onCell: withFirstColumnPadding(columnIndex, column.onCell, hasEditableCells),
              render: renderFunc,
              renderToString: (v: string | number): string => formatData(v, formatDataOptions),
              width: column.width ?? { competitiveness: 1, min: 100, max: 200 },
            };
          }),
          ...conditionallyArr<ColumnRaw<GenericTableRow>>(hasRemoveFunc, {
            dataKey: "remove",
            title: null,
            isSticky: true,
            width: { min: 48, max: 48, competitiveness: 1 },
            render: (_: any, tableItem: any) => {
              if (!tableItem.value.remove) {
                return null;
              }
              if (tableItem.value.removeConfirm) {
                return (
                  <Popconfirm {...tableItem.value.removeConfirm} onConfirm={tableItem.value.remove}>
                    <DeleteButton />
                  </Popconfirm>
                );
              }
              return <DeleteButton onClick={tableItem.value.remove} />;
            },
            isExported: false,
          }),
        ],
        data,
        tableOptions,
        theme
      );
    }, [
      data,
      selectable,
      showIndexColumn,
      alwaysExpanded,
      showSelectColumn,
      columns,
      hasRemoveFunc,
      tableOptions,
      theme,
      hasEditableCells,
      customRenders,
      tableFilter,
    ]);

    if (model === undefined) {
      return <>{children}</>;
    }

    useTableSettings(model, tableSettingsId);

    return (
      <TableContextProvider value={model}>
        <CsvSaver filename={exportFileName} exportArray={() => model.export()} top={topExportIcon}>
          {children}
        </CsvSaver>
      </TableContextProvider>
    );
  }
);

export type { Column };
export { type GenericTableData, SimpleTableContext };
