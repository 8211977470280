import { LineDataModel } from "@okopok/axes_context";
import dayjs from "dayjs";
import { computed, makeObservable, observable, reaction, when } from "mobx";

import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Forecast } from "models/project/fact/forecast/forecast";
import { colorCarousel } from "services/colorCarousel";

class WellInterventionsAmountChartModel {
  bars: BarsDataModel | undefined;
  lines: LineDataModel[] = [];
  isValid: boolean = false;
  tooltipManager: TooltipDataManager | null = null;

  constructor(private forecast: Forecast) {
    makeObservable(this, {
      bars: observable,
      lines: observable,
      isValid: observable,
      dataForLine: computed,
      dataForBars: computed,
    });

    when(
      () => forecast.operatingGTM.isLoading === false,
      () => {
        this.init();
      }
    );
  }

  private init() {
    this.lines = this.dataForLine;
    this.bars = new BarsDataModel(this.dataForBars, "bars");
    this.tooltipManager = new TooltipDataManager(this.lines, this.bars);
    if (this.lines[0].y.length < 1 || this.bars.bars.find((bar) => bar.y.length > 0) === undefined) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }

    reaction(
      () => [this.forecast.operatingGTM.params],
      () => {
        if (
          this.lines &&
          this.bars &&
          (this.lines[0].y.length < 1 || this.bars.bars.find((bar) => bar.y.length > 0) === undefined)
        ) {
          this.isValid = false;
        } else {
          this.isValid = true;
        }
      }
    );
  }

  get dataForBars(): BarDataModelProps[] {
    const result: BarDataModelProps[] = [];
    for (let [dataKey, parentParam] of this.forecast.operatingGTM.params) {
      if (dataKey !== "miningWells" && dataKey !== "pumpingWells") {
        if (parentParam.children) {
          if (dataKey === "otherGTM") {
            const data = Array.from(
              Array.from(parentParam.children.values())
                .find((param) => param.title === "Интегральные показатели")
                ?.children!.values()!
            ).find((param) => param.title === "Количество ГТМ")!.data;
            if (data) {
              result.push({
                y: Array.from(data.values()).map((value) => ({ value: value })),
                x: Array.from(data.keys()).map((year) => dayjs(`${year}`)),
                key: dataKey,
                title: parentParam.title,
                color: colorCarousel(result.length),
              });
            }
          } else {
            const data = Array.from(parentParam.children.values()).find(
              (param) => param.title === "Количество ГТМ" || param.title === "Количество ФХ МУН"
            )?.data;
            if (data) {
              result.push({
                y: Array.from(data.values()).map((value) => ({ value: value })),
                x: Array.from(data.keys()).map((year) => dayjs(`${year}`)),
                key: dataKey,
                title: parentParam.title,
                color: colorCarousel(result.length),
              });
            }
          }
        }
      }
    }
    return result;
  }

  get dataForLine(): LineDataModel[] {
    let dataSet: Map<number, number | null> = new Map();
    for (let [year, value] of Array.from(this.forecast.operatingGTM.params.get("miningWells")!.children!.values()).find(
      (param) => param.title === "Доп. добыча за год проведения ГТМ"
    )!.data!) {
      if (value !== null) {
        dataSet.set(year, value);
      }
    }

    return [
      new LineDataModel({
        y: Array.from(dataSet.values()),
        x: Array.from(dataSet.keys()).map((year) => dayjs(`${year}`)),
        axisKey: "line",
        key: "line",
        color: colorCarousel(18),
      }),
    ];
  }
}
export { WellInterventionsAmountChartModel };
