import type { LicenseRegionBackend } from "services/back/licenseRegions";

import { Project } from "../project";

import { LicenseRegion } from "./licenseRegion";

class LicenseRegions {
  data: LicenseRegion[];

  constructor(data: LicenseRegionBackend[], private readonly project: Project) {
    this.data = data.map(LicenseRegion.fromRaw(this));
  }

  get isHasNDD(): boolean | undefined {
    return this.data.find(({ isNdd }) => isNdd) !== undefined;
  }

  public get selector(): { value: number; label: string }[] {
    return this.data.map(({ id, title }) => ({ value: id, label: title }));
  }

  get ids(): number[] {
    return this.data.map(({ id }) => id);
  }

  get titles(): string[] {
    return this.data.map(({ title }) => title);
  }

  get nddRegion(): LicenseRegion | null {
    return this.data!.find(({ isNdd }) => isNdd) ?? null;
  }

  at(regionId: number): null | LicenseRegion {
    return this.data.find(({ id }) => regionId === id) ?? null;
  }

  async update(region: LicenseRegion, data: LicenseRegionBackend): Promise<LicenseRegionBackend> {
    const index = this.data.findIndex((v) => v === region);
    console.assert(index >= 0, "Попытка обновить ЛУ в другом контейнере");
    const result = await this.project.applyFromFormData({
      lz: [data],
    });
    return result.lz![index];
  }
}

export { LicenseRegions };
