import { global } from "models/global";
import { Args, reqCamel } from "utils/request";

const reqLogged = {
  get: async <T extends object>(path: string, arg?: Args) => {
    try {
      await global.logger.addNote(`get:${path}`);
    } finally {
      return reqCamel.get<T>(path, arg);
    }
  },
  post: async <T extends object, B extends object = any>(path: string, body: B, arg?: Args) => {
    try {
      await global.logger.addNote(`post:${path}`);
    } finally {
      return reqCamel.post<T>(path, body, arg);
    }
  },
  delete: async <T extends object>(path: string, body?: any, arg?: Args) => {
    try {
      await global.logger.addNote(`delete:${path}`);
    } finally {
      return reqCamel.delete<T>(path, body, arg);
    }
  },
  put: async <T extends object>(path: string, body: any, arg?: Args) => {
    try {
      await global.logger.addNote(`put:${path}`);
    } finally {
      return reqCamel.put<T>(path, body, arg);
    }
  },
  patch: async <T extends object>(path: string, body: any, arg?: Args) => {
    try {
      await global.logger.addNote(`patch:${path}`);
    } finally {
      return reqCamel.patch<T>(path, body, arg);
    }
  },
  args: reqCamel.args,
};

export { reqLogged };
