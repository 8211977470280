import { LineDataModel } from "@okopok/axes_context";
import dayjs from "dayjs";
import { computed, makeObservable } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";
import { colorCarousel } from "services/colorCarousel";

class MiningOilGasModel {
  constructor(public summaryModel: SummaryModel) {
    makeObservable(this, {
      tooltipManager: computed,
      lines: computed,
    });
  }

  get tooltipManager() {
    return new TooltipDataManager(this.lines);
  }

  get stickerValue() {
    return this.summaryModel.oilGas.data
      .filter(
        (value) =>
          value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
          value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
      )
      .reduce((prev, curr) => prev + curr.y, 0);
  }

  get lines() {
    return [
      new LineDataModel({
        y: this.summaryModel.oilGas.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.y),
        x: this.summaryModel.oil.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.x),
        axisKey: "oil",
        key: "oil",
        color: colorCarousel(1),
        title: `Товарный нефтяной газ, млн м³`,
        shadow: true,
      }),
    ];
  }
}
export { MiningOilGasModel };
