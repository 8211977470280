import { FC } from "react";
import { Select } from "antd";

import { Card } from "elements/card/card";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Project } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import cn from "./dataProject.module.less";

const VersionFem: FC<{ project: Project }> = ({ project }) => {
  const forecast = useForecast();
  const edit = getUserPermission(project, forecast)["economic"];
  return (
    <Card className={cn.versionFem} title="Версия ФЭМ">
      <Select disabled={!edit.value} value={1} options={[{ value: 1, label: "13.0  12.12.2023" }]} />
    </Card>
  );
};

export { VersionFem };
