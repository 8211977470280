import { type FC } from "react";
import { useMatches } from "react-router";
import { observer } from "mobx-react-lite";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { WellsList as WellsListFeature } from "features/wellsList/wellsList";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

const useProducingObjects = () => useProject()?.producingObjects;
const useForecastWells = () => {
  const fc = useForecast();
  if (fc === null || fc === undefined) {
    return fc;
  }
  return fc.wells;
};
const useFactWells = () => useFact()?.wells;
const useWellPads = () => useFact()?.wellPads;

const TABS: TabItem[] = [
  {
    key: "base",
    label: "Базовый фонд",
    default: true,
    children: <WellsListFeature />,
  },
  {
    key: "forecast",
    label: "Новый фонд",
    children: <WellsListFeature />,
  },
];

const WellsList: FC = observer(() => {
  const tab = useMatches().at(-1)!.pathname.split("/")[4];
  return (
    <Preloader hooks={[useProducingObjects, useForecastWells, useFactWells, useWellPads]}>
      {useForecast() !== null ? (
        <PageFrame title={`Список скважин: ${tab === TABS[1].key ? "новый фонд" : "базовый фонд"}`} tabs={TABS} />
      ) : (
        <PageFrame title="Список скважин: базовый фонд">
          <WellsListFeature />
        </PageFrame>
      )}
    </Preloader>
  );
});

export { WellsList };
