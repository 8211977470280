import { LineDataModel } from "@okopok/axes_context";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, when } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Forecast } from "models/project/fact/forecast/forecast";
import { colorCarousel } from "services/colorCarousel";
import { Range } from "utils/range";
import { setupAccuracy } from "utils/round";

import { CalcModel } from "../calcEffectTable/calcModel";

class DecreaseEffectChartModel {
  lines: LineDataModel[] = [];
  tooltipManager: TooltipDataManager | null = null;
  yearLabelsTop: Map<Dayjs, string> | undefined;
  yearLabelsBottom: Map<Dayjs, string> | undefined;
  range: Range;
  constructor(private forecast: Forecast, private calcModel: CalcModel | null) {
    makeObservable<DecreaseEffectChartModel, "init">(this, {
      lines: observable,
      init: action,
      isValid: computed,
    });
    when(
      () => forecast.operatingGTM.isLoading === false,
      () => {
        this.init();
      }
    );
    this.range = new Range(this.forecast.range.from + 1, this.forecast.range.to);

    this.yearLabelsTop = new Map(
      Array(this.range.length)
        .fill(0)
        .map((_, indx) => dayjs(`${this.range.from + indx}`))
        .map((year, indx) => [
          year,
          this.forecast.operatingGTM.basedMining.get(this.range.from + indx) !== null &&
          this.forecast.operatingGTM.basedMining.get(this.range.from + indx) !== undefined &&
          this.forecast.operatingGTM.basedMining.get(this.range.from + indx + 1) !== null &&
          this.forecast.operatingGTM.basedMining.get(this.range.from + indx + 1) !== undefined &&
          this.forecast.operatingGTM.basedMining.get(this.range.from + indx) !== 0
            ? `${setupAccuracy(
                (this.forecast.operatingGTM.basedMining.get(this.range.from + indx + 1)! -
                  this.forecast.operatingGTM.basedMining.get(this.range.from + indx)!) /
                  this.forecast.operatingGTM.basedMining.get(this.range.from + indx)!,
                "round"
              )}%`
            : "",
        ])
    );
    this.yearLabelsBottom = new Map(
      Array(this.range.length)
        .fill(0)
        .map((_, indx) => dayjs(`${this.range.from + indx}`))
        .map((year, indx) => [
          year,
          this.forecast.operatingGTM.basicMining.get(this.range.from + indx) !== null &&
          this.forecast.operatingGTM.basicMining.get(this.range.from + indx) !== undefined &&
          this.forecast.operatingGTM.basicMining.get(this.range.from + indx + 1) !== null &&
          this.forecast.operatingGTM.basicMining.get(this.range.from + indx + 1) !== undefined &&
          this.forecast.operatingGTM.basicMining.get(this.range.from + indx) !== 0
            ? `${setupAccuracy(
                (this.forecast.operatingGTM.basicMining.get(this.range.from + indx + 1)! -
                  this.forecast.operatingGTM.basicMining.get(this.range.from + indx)!) /
                  this.forecast.operatingGTM.basicMining.get(this.range.from + indx)!,
                "round"
              )}%`
            : "",
        ])
    );
  }

  get isValid() {
    return this.lines.length > 0;
  }

  private daysInYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 366 : 365;
  };

  private init() {
    const resultTransferring: (number | null)[][] = new Array(this.range.length)
      .fill(null)
      .map((value, index) => new Array(this.range.length + 9).fill(null));
    if (this.calcModel) {
      [...this.calcModel.similarParamsModel, this.calcModel.otherGTMModel, this.calcModel.physicModel].forEach(
        (model) => {
          model.rawTransferringMining.forEach((row, rowIndex) => {
            row.forEach((value, valueIndex) => {
              if (value !== null) {
                if (resultTransferring[rowIndex][valueIndex] !== null) {
                  resultTransferring[rowIndex][valueIndex]! += value;
                } else {
                  resultTransferring[rowIndex][valueIndex] = value;
                }
              }
            });
          });
        }
      );
    }

    const dataSets: {
      data: Map<number, number | null>;
      title: string;
      color: string;
    }[] = [];

    const dataForTooltip: {
      data: Map<number, number | null>;
      title: string;
      color: string;
    }[] = [];

    dataForTooltip.push({
      data: this.forecast.operatingGTM.basicMining,
      title: "Базисная добыча",
      color: colorCarousel(19),
    });

    dataSets.push({
      data: this.forecast.operatingGTM.basicMining,
      title: "Базисная добыча",
      color: dataForTooltip.find((data) => data.title === "Базисная добыча")!.color,
    });

    resultTransferring.forEach((row, rowIndex) => {
      dataForTooltip.push({
        data: new Map(row.map((value, index) => [this.range.from + index, value])),
        title: `${this.range.from + rowIndex}`,
        color: colorCarousel(rowIndex),
      });

      const newLine = {
        data: new Map(dataSets.at(-1)!.data),
        title: `${this.range.from + rowIndex}`,
        color: colorCarousel(rowIndex),
      };
      row.forEach((value, valueIndex) => {
        if (value !== null) {
          if (
            newLine.data.get(valueIndex + this.range.from) === undefined ||
            newLine.data.get(valueIndex + this.range.from) === null
          ) {
            newLine.data.set(valueIndex + this.range.from, value);
          } else {
            newLine.data.set(valueIndex + this.range.from, value + newLine.data.get(valueIndex + this.range.from)!);
          }
        }
      });
      dataSets.push(newLine);
    });

    dataSets.reverse().forEach((data, index) => {
      this.lines.push(
        new LineDataModel({
          y: Array.from(data.data.values())
            .map((value, indx) =>
              value !== null ? (value * 1000) / this.daysInYear(Array.from(data.data.keys())[indx]) : null
            )
            .slice(0, this.range.length),
          x: Array.from(data.data.keys())
            .map((year) => dayjs(`${year}`))
            .slice(0, this.range.length),
          axisKey: "y",
          title: data.title,
          key: `line-${index}`,
          color: data.color,
          shadow: true,
        })
      );
    });
    this.tooltipManager = new TooltipDataManager(
      dataForTooltip.map(
        (data, index) =>
          new LineDataModel({
            y: Array.from(data.data.values())
              .map((value, indx) =>
                value !== null ? (value * 1000) / this.daysInYear(Array.from(data.data.keys())[indx]) : null
              )
              .slice(0, this.range.length),
            x: Array.from(data.data.keys())
              .map((year) => dayjs(`${year}`))
              .slice(0, this.range.length),
            axisKey: "y",
            title: data.title,
            key: `line-${index}`,
            color: data.color,
            shadow: true,
          })
      )
    );
  }
}
export { DecreaseEffectChartModel };
