import { Dispatch, type FC, MouseEvent, type ReactNode, SetStateAction, useEffect, useMemo, useRef } from "react";
import { useAxis } from "@okopok/axes_context";
import * as d3 from "d3";

import { useTooltipContext } from "elements/tooltips/abstractTooltip";
import { useContainerShape } from "utils/useContainerShape";
import { useInteractiveRef } from "utils/useInteractiveRef";

import { TooltipConsumer } from "./tooltipConsumer";
import type { Rect } from "./types";
import { MapContext, MapRefsContext, useContextMemo, useRefsContextMemo } from "./useMapContext";
import { MapManager, useMapManager } from "./useMapManager";
import { useZoom } from "./useZoom";

import cn from "./map.module.less";

type MapProps = {
  className?: string;
  children?: ReactNode;
  /* поскольку задача как и у вьюпорта то интерфейс взят из svg но
  у карты есть особенность: пропорции по вертикали и горизонтали всегда 1:1
  так что окончательный вьюпорт вычисляется с учетом размера окна и дополняется
  пустым пространством*/
  viewPort: string;
  /* идея в том, чтобы класс поставлял менеджер, в котором можно выполнять все
  необходимые операции. Они прописываются тут с доступом ко всем замыканиями
  без проброса всей внутренней механики на верх */
  manager?: Dispatch<SetStateAction<MapManager | null>>;
  onMouseClick?: (position: { x: number; y: number }) => void;
  onMouseMove?: (position: { x: number; y: number; zoomedX: number; zoomedY: number }) => void;
  onMouseLeave?: () => void;
};

const Map: FC<MapProps> = ({ className, children, viewPort, manager, onMouseClick, onMouseMove, onMouseLeave }) => {
  console.assert(viewPort.split(" ").map(parseFloat).filter(isFinite).length === 4, "Ошибка формата вьюпорта");
  const shapeRef = useRef<HTMLDivElement>(null);
  const shape = useContainerShape(shapeRef);
  const domain = useMemo<Rect>(() => {
    const [x1, y2, w, h] = viewPort.split(" ").map(parseFloat).filter(isFinite);
    return { x1, y1: y2 + h, x2: x1 + w, y2 };
  }, [viewPort]);
  const [scale, zoomedScale, sceneRef, svgRef, zoom, transform] = useZoom(shape, domain);
  const gridXRef = useRef<SVGGElement>(null);
  const gridYRef = useRef<SVGGElement>(null);
  const [xRef] = useAxis({
    orientation: "top",
    scale: zoomedScale.x,
    grid: { ref: gridXRef, ...shape },
  });
  const [yRef] = useAxis({
    orientation: "right",
    scale: zoomedScale.y,
    grid: { ref: gridYRef, ...shape },
  });

  const isReady = isFinite(shape.width) && isFinite(shape.height);
  const mapManager = useMapManager(zoom, svgRef);
  const context = useContextMemo(scale, zoomedScale, domain, transform);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const noTransformPortalRef = useInteractiveRef<SVGGElement>();
  const refsContext = useRefsContextMemo(zoomedScale, tooltipRef, noTransformPortalRef, shape);
  const tooltipModel = useTooltipContext();

  useEffect(() => {
    manager?.(mapManager);
  }, [manager, mapManager]);

  const handleClick = (event: MouseEvent) => {
    const [x, y] = d3.pointer(event);
    onMouseClick?.({ x: zoomedScale.x.invert(x), y: zoomedScale.y.invert(y) });
  };

  const handleMouseMove = (event: MouseEvent) => {
    const [x, y] = d3.pointer(event);
    onMouseMove?.({ x, y, zoomedX: zoomedScale.x.invert(x), zoomedY: zoomedScale.y.invert(y) });
  };

  return (
    <div ref={shapeRef} className={className}>
      {tooltipModel !== null && <TooltipConsumer tooltipModel={tooltipModel} />}
      <svg ref={svgRef} onClick={handleClick} onMouseMove={handleMouseMove} onMouseLeave={onMouseLeave}>
        <g className={cn.grid}>
          <g ref={gridXRef} className={cn.x} />
          <g ref={gridYRef} className={cn.y} />
        </g>
        {isReady && (
          <g ref={sceneRef}>
            <MapContext.Provider value={context}>
              <MapRefsContext.Provider value={refsContext}>{children}</MapRefsContext.Provider>
            </MapContext.Provider>
          </g>
        )}
        <g ref={noTransformPortalRef} />
        <g ref={xRef} className={cn.axis} transform={`translate(0, ${isFinite(shape.height) ? shape.height - 1 : 0})`} />
        <g ref={yRef} className={cn.axis} />
      </svg>
    </div>
  );
};

export { Map };
