import dayjs from "dayjs";

import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Summary } from "models/project/fact/forecast/ranking/results/summary";
import { colorCarousel } from "services/colorCarousel";

import { PlanningChartModel } from "../chart/planningChartModel";

class Expenses extends PlanningChartModel {
  tooltipManager: TooltipDataManager | null;
  bars: BarsDataModel;

  constructor(store: Summary) {
    super("Затраты", new Map<string, string>([["bars", "млн руб"]]));

    const rawBars: BarDataModelProps[] = [
      {
        y: store.Invests.eb.yearValues.map((value, index) => ({
          value: value,
          limit: store.InvestsLimits.eb.yearValues[index],
        })),
        x: new Array(store.Invests.eb.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.Invests.eb.yearsRange.from + index}`)),
        key: "eb",
        title: "CAPEX - ЭБ",
        color: colorCarousel(3),
      },
      {
        y: store.Invests.reconstruction.yearValues.map((value, index) => ({
          value: value,
          limit: store.InvestsLimits.reconstruction.yearValues[index],
        })),
        x: new Array(store.Invests.reconstruction.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.Invests.reconstruction.yearsRange.from + index}`)),
        key: "reconstruction",
        title: "CAPEX - Инвестиционные ГТМ",
        color: colorCarousel(4),
      },
    ];
    this.bars = new BarsDataModel(rawBars, "bars", true);
    this.tooltipManager = new TooltipDataManager(undefined, this.bars);
  }
}
export { Expenses };
