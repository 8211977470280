import { FC, useEffect, useMemo } from "react";
import { Button, Form, Input } from "antd";
import { observer } from "mobx-react";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Icon } from "elements/icon/icon";
import { PipeType } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { findMatch } from "features/infrastructure/utils";
import { Pipes } from "models/project/fact/infrastructure/pipes";
import { SegmentType } from "services/back/infrastructure/catalog";

import { ParamsTabs } from "../../ui/paramsTabs/paramsTabs";

import { ReactComponent as SelectPipeIcon } from "./selectPipe.svg";
import { TechnologyParams } from "./technologyParams";

import cn from "./selectedItem.module.less";

const { useForm } = Form;

const findItemInCatalog = (selectedItem: PipeType, pipes: SegmentType[]) => {
  if (!selectedItem.diameter) {
    return "Не выбран тип трубопровода";
  }
  const tmp = Pipes.getPipesAttributes(selectedItem);
  return findMatch<SegmentType>(tmp, pipes)?.title || "Нет соответствующего трубопровода";
};

type Props = {
  selectedItem: PipeType | null;
  goCatalog: () => void;
};

const SelectedItem: FC<Props> = observer(({ selectedItem, goCatalog }) => {
  const { catalog, pipes, calculateStore } = useInfrastructure();

  const [form] = useForm();

  const economicParams = useMemo(() => {
    const segment = calculateStore.economicData?.segments.find((el) => el.uuid === selectedItem?.uuid);
    return { construction: segment?.constrNominal, reconstruction: segment?.reconstrNominal };
  }, [calculateStore.economicData?.segments, selectedItem]);

  useEffect(() => {
    form.setFieldsValue({ ...selectedItem });
  }, [selectedItem, form]);

  if (!selectedItem) {
    return <></>;
  }

  return (
    <Form form={form} colon={false} onFinish={(values) => pipes.update({ ...(values as Partial<PipeType>), uuid: selectedItem.uuid })}>
      <div className={cn.field}>
        <Form.Item name="title">
          <Input onBlur={form.submit} />
        </Form.Item>
        <DeleteButton title={`Вы точно хотите удалить ${selectedItem.title}`} onClick={() => pipes.remove([selectedItem.uuid])}>
          <Button danger>Удалить</Button>
        </DeleteButton>
      </div>
      <div className={cn.catalogBtnWrapper}>
        <div className={cn.selectedName}>
          <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<SelectPipeIcon />} />
          {findItemInCatalog(selectedItem, catalog.pipes(selectedItem.segmentType)?.map((el) => el)!)}
        </div>
        <Button onClick={goCatalog}>В каталог</Button>
      </div>
      <ParamsTabs className={cn.pipeTabs} economicParams={economicParams}>
        <TechnologyParams selectedItem={selectedItem} />
      </ParamsTabs>
    </Form>
  );
});

export { SelectedItem };
