import { FC } from "react";

import { Card } from "elements/card/card";
import { Icon } from "elements/icon/icon";

import { DetailCard } from "./detailCard";
import { ReactComponent as LicenseRegionIcon } from "./licenseRegion.svg";

import dp from "./dataProject.module.less";

const LicenseRegion: FC<{ licenseRegions?: string[] }> = ({ licenseRegions }) => {
  return (
    <Card className={dp.margin} bodyClassName={dp.detailCardParent} title="Лицензионные участки">
      {licenseRegions?.map((title) => (
        <DetailCard
          key={title}
          title="Лицензионный участок"
          icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<LicenseRegionIcon />} />}
        >
          {title}
        </DetailCard>
      ))}
    </Card>
  );
};

export { LicenseRegion };
