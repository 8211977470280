import { ColumnRaw } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import classNames from "classnames";

import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { Forecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as StarIcon } from "../../star.svg";

import { DRow } from "./tableStore";

import cn from "../compareScenario.module.less";
const COMPARE_TABLE_BASE_COLUMNS: ColumnRaw<DRow>[] = [
  {
    title: "№",
    width: 54,
    key: "index",
    isSticky: true,
    render: (_, mine) => mine.absoluteIndex,
  },
  {
    key: "expand",
    title: <div />,
    isSticky: true,
    width: { min: 32, max: 32, competitiveness: 1 },
    render: (_, tableItem) => (tableItem.expand !== undefined ? <ExpandButton expand={tableItem.expand} /> : <div />),
  },
  {
    title: "Параметр",
    width: { min: 400, max: 400, competitiveness: 1 },
    dataKey: "param",
    isSticky: true,
    onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
    render: (value) => (
      <Ellipsis limit={45} position="after">
        {value}
      </Ellipsis>
    ),
  },
  {
    key: "measure",
    title: "Ед. измерения",
    isSticky: true,
    width: 120,
  },
];

function generateColumns(forecasts: Forecast[], favoriteScenario: number | null, onFavoriteSelect: (id: number) => void): ColumnRaw<DRow>[] {
  return [
    ...COMPARE_TABLE_BASE_COLUMNS,
    ...forecasts
      .sort(({ title: a }, { title: b }) => a.localeCompare(b))
      .map(
        ({ id, title }): ColumnRaw<DRow> => ({
          key: `fc_${id}`,
          dataKey: id,
          title: (
            <div className={cn.favHeader}>
              {title}
              {favoriteScenario === id && <Icon content={<StarIcon />} width={"25px"} height={"25px"} viewBox="0 0 13 13" />}
            </div>
          ),
          hidden: true,
          width: { min: 250, max: 300, competitiveness: 1 },
          onHeaderCell: () => ({
            className: classNames(cn.tableCellAlignRight, favoriteScenario === id && cn.favorite, cn.headerCell),
            onClick: () => {
              onFavoriteSelect(id);
            },
          }),
          onCell: () => ({
            className: cn.cellWrap,
          }),
          render: (_, { value }) => (
            <div className={cn.cell}>
              <div>
                <Format>{value?.[id].value}</Format>
              </div>
              {value?.[id].delta !== null && value?.[id].delta !== undefined && (
                <div className={value?.[id].delta! > 0 ? cn.profit : cn.nonprofit}>
                  <Format sign>{value?.[id].delta}</Format>
                </div>
              )}
            </div>
          ),
        })
      ),
  ];
}

export { generateColumns };
