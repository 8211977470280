import { Result } from "services/back/calculate/calculate";
import { Metric, MetricTree } from "services/finance/utils";
import { zip } from "utils/itertools";
import { Range } from "utils/range";

import type { MetricsSources } from "../models/tableLayout";
import { INVEST_METRICS_SOURCES, OPERATING_METRICS_SOURCES } from "../models/tableLayout";

function* ofMetricsTreeLimited<T extends { children?: T[] }>(
  metrics: T[],
  maxLevel: number | undefined = undefined,
  level: number = 0
): Generator<T> {
  if (maxLevel !== undefined && level >= maxLevel) {
    return;
  }
  for (const metric of metrics) {
    yield metric;
    if (metric.children === undefined) {
      continue;
    }
    yield* ofMetricsTreeLimited(metric.children, maxLevel, level + 1);
  }
}

function extractFromMetricsTree(
  ranges: [fullRange: Range, forecastRange: Range],
  tree: MetricTree[],
  metricsSources: MetricsSources
): ComparisonValues {
  const res: ComparisonValues = {
    forecast: {},
    full: {},
  };
  const [fullRange, forecastRange] = ranges;
  const flatten = [...ofMetricsTreeLimited(tree)];
  for (const [metricTitle, maxLevel] of Object.entries(metricsSources)) {
    const metricRoot = flatten.find((metric) => metric.title === metricTitle);
    if (!metricRoot) {
      continue;
    }
    for (const { title, values } of ofMetricsTreeLimited([metricRoot], maxLevel)) {
      res.forecast[title] = null;
      res.full[title] = null;
      for (const [year, cur] of zip([...fullRange], values ?? [])) {
        res.full[title] = (res.full[title] || cur) && (res.full[title] ?? 0) + (cur ?? 0);
        if (forecastRange.includes(year)) {
          res.forecast[title] = (res.forecast[title] || cur) && (res.forecast[title] ?? 0) + (cur ?? 0);
        }
      }
    }
  }
  return res;
}

function extractMainResults(schema: Metric[]): ComparisonValues {
  const res: ComparisonValues = {
    forecast: {},
    full: {},
  };
  for (const metric of schema) {
    let title = metric.title.trim();
    const section = title.endsWith(" 1") ? "forecast" : "full";
    title = title.slice(0, -2);
    res[section][title] = metric.values?.[metric.values.length - 1] ?? null;
  }
  return res;
}

type ComparisonValues = Record<"full" | "forecast", Record<string, number | null>>;

function extractComparisonValues(result: Result, ranges: [fullRange: Range, forecastRange: Range]): ComparisonValues {
  const res: ComparisonValues = {
    forecast: {},
    full: {},
  };

  try {
    const main = extractMainResults(result.result.schema);
    const operating = extractFromMetricsTree(ranges, result.schemaOperation, OPERATING_METRICS_SOURCES);
    const invest = extractFromMetricsTree(ranges, result.schemaInvest, INVEST_METRICS_SOURCES);
    for (const section of ["full", "forecast"] as const) {
      res[section] = {
        ...main[section],
        ...operating[section],
        ...invest[section],
      };
    }
  } catch (error) {
    console.error(`Can't extract comparison values from result:\n ${error}`);
  }
  return res;
}

export type { ComparisonValues };
export { extractComparisonValues };
