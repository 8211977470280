import { FC } from "react";
import { Button, ButtonProps, Popconfirm, PopconfirmProps, Tooltip, TooltipProps } from "antd";

type ToolbarButtonProps = {
  tooltip: TooltipProps;
  popconfirm?: PopconfirmProps;
} & ButtonProps;

const ToolbarButton: FC<ToolbarButtonProps> = ({ tooltip, popconfirm, ...props }) =>
  popconfirm ? (
    <Popconfirm {...popconfirm}>
      <Tooltip {...tooltip}>
        <Button {...props} />
      </Tooltip>
    </Popconfirm>
  ) : (
    <Tooltip {...tooltip}>
      <Button {...props} />
    </Tooltip>
  );

export { ToolbarButton };
