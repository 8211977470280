import { FC, ReactNode } from "react";
import { Button, ButtonProps, Popconfirm, PopconfirmProps, Tooltip } from "antd";
import classNames from "classnames";

import { Icon } from "elements/icon/icon";

import { ReactComponent as Close } from "./close.svg";

import cn from "./deleteButton.module.less";

type DeleteButtonProps = {
  className?: string;
  onClick?: (e?: React.MouseEvent) => void;
  title?: PopconfirmProps["title"];
  disabled?: boolean;
  children?: ReactNode;
  tooltip?: string;
} & Pick<PopconfirmProps, "description" | "okText" | "cancelText" | "onCancel">;

const SimpleButton: FC<Pick<ButtonProps, "onClick" | "className" | "disabled"> & { tooltip?: string }> = ({
  className,
  onClick,
  disabled,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip ? tooltip : ""}>
      <Button
        onClick={onClick}
        danger
        disabled={disabled}
        className={classNames(cn.deleteBtn, className)}
        icon={<Icon content={<Close />} viewBox="0 0 16 16" />}
      />
    </Tooltip>
  );
};

const DeleteButton: FC<DeleteButtonProps> = ({
  className,
  onClick,
  title,
  description,
  okText,
  cancelText,
  onCancel,
  disabled,
  children,
  tooltip,
}) => {
  if (title === undefined) {
    return <SimpleButton className={className} onClick={onClick} disabled={disabled} tooltip={tooltip} />;
  }
  return (
    <Popconfirm title={title} description={description} onConfirm={onClick} onCancel={onCancel} okText={okText} cancelText={cancelText}>
      {children ? children : <SimpleButton disabled={disabled} className={className} tooltip={tooltip} />}
    </Popconfirm>
  );
};

export { DeleteButton };
