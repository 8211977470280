import { makeAutoObservable } from "mobx";

import { global } from "models/global";
import { ExtendedParticipantInfo } from "models/projectParticipants";
import { ADMIN_ROLES_IDS, assignRole, deleteRole, deleteUser, getProjectRoles } from "services/back/roles";

import { Participant } from "./participant";

class Participants {
  public isLoaded = false;
  private participants: Record<number, Participant> = {};
  public isLoading = false;
  public canUserEdit = false;

  constructor(private projectId: number) {
    makeAutoObservable(this);
    this.loadParticipants();
  }

  private loadParticipants = async (force = false) => {
    if (!this.isLoaded || force) {
      this.isLoading = true;
      const projectRoles = await getProjectRoles(this.projectId);
      const participants: Record<number, Participant> = {};
      const { users, user } = global;
      const adminRole = global.roles.find(({ id }) => id === 1)!;
      for (const role of projectRoles) {
        for (const uId of role.userIds) {
          if (participants[uId]) {
            participants[uId].roles.push(role);
          } else {
            participants[uId] = {
              ...users.at(uId)!,
              roles: [role],
              projectId: this.projectId,
            };
          }
        }
      }
      for (const id of adminRole.userIds) {
        if (participants[id]) {
          participants[id].roles.push(adminRole);
        }
      }
      if (user?.id && participants[user?.id].roles.find(({ id }) => ADMIN_ROLES_IDS.includes(id))) {
        this.canUserEdit = true;
      }
      this.participants = participants;
      this.isLoaded = true;
      this.isLoading = false;
      return Object.values(participants).map((participant) => new Participant(participant, this.projectId));
    }
  };

  getById = (uId: number): Participant => {
    return this.participants[uId];
  };

  deleteParticipant = async (uId: number) => {
    const roles = this.getById(uId)!.roles.map(({ id }) => id);
    await deleteUser(roles, this.projectId, uId);
    await this.loadParticipants(true);
  };

  addParticipant = async (uId: number) => {
    //9 роль куратора проектов, выдаваемая по умолчанию
    await assignRole(9, this.projectId, uId);
    await this.loadParticipants(true);
  };

  addRole = async (rId: number, uId: number) => {
    await assignRole(rId, this.projectId, uId);
    await this.loadParticipants(true);
  };

  deleteRole = async (rId: number, uId: number) => {
    await deleteRole(rId, this.projectId, uId);
    await this.loadParticipants(true);
  };

  get data(): Participant[] {
    return Object.values(this.participants);
  }

  get tableData(): ExtendedParticipantInfo[] {
    return this.data.map((info) => ({
      ...info,
      addRole: (rId) => this.addRole(rId, info.id),
      deleteRole: (rId) => this.deleteRole(rId, info.id),
      addParticipant: this.addParticipant,
      deleteParticipant: () => this.deleteParticipant(info.id),
    }));
  }
}

export { Participants };
