import { type FC, useCallback } from "react";
import { Domain, useChartContext } from "@okopok/axes_context";
import { createDomainAccessor } from "@okopok/axes_context/utils/boundDomain";
import clsx from "clsx";
import type { NumberValue } from "d3";
import * as d3 from "d3";
import dayJS from "dayjs";
import { observer } from "mobx-react";

import { LineDataModel, NullableNumberValue } from "./lineDataModel";
import { Point } from "./point";

import cn from "./line.module.less";

type LineData = {
  y: Array<NumberValue>;
  x: Array<NumberValue> | Domain;
  axisKey: string;
  key: string;

  color?: string;
  className?: string;
  domain?: Domain;
  title?: string;
  showPoints?: boolean;
  onChange?: (newArr: NumberValue[]) => void;
};

type LineProps = {
  lineInfo: LineDataModel;
  className?: string;
  ref?: React.Ref<SVGGElement>;
};

const BREAK_WIDTH = 135;

const interpolate = (a: number, b: number, r: number): Array<number> => {
  const d = (a - b) / 2;
  return new Array(Math.max(0, r - 1)).fill(0).map((_, id) => b + d * (Math.cos(((id + 1) / r) * Math.PI) + 1));
};

const R = 12;

const extrapolation = (vector: Array<NullableNumberValue>, index: number, value: number): Array<number> => [
  ...interpolate(+(vector[Math.max(index - R, 0)] ?? value), value, Math.min(R, index + 1)),
  value,
  ...interpolate(value, +(vector[index + R] ?? value), Math.min(R, vector.length - index)),
];

const prepareLine = (rawLine: NullableNumberValue[]) => {
  const line: { y: NumberValue; indx: number }[][] = [[]];
  rawLine.forEach((y, indx) => {
    if (y !== null && Number.isFinite(y)) {
      line.at(-1)?.push({ y: y, indx: indx });
    } else {
      line.push([]);
    }
  });
  return line;
};

const Line: FC<LineProps> = observer(({ lineInfo, className, ref }) => {
  const axes = useChartContext();

  const yScale = axes.at(lineInfo.axisKey)?.scale;

  const isExtrapolation = !axes.isNotReady ? axes.yearWidth < BREAK_WIDTH : true;
  const preparedLine = prepareLine(lineInfo.y);
  const onDrag = useCallback(
    (changed: number, index: number) => {
      const value = Math.max(yScale?.invert(changed) ?? 0, 0);
      if (isExtrapolation) {
        lineInfo.setPoints([
          ...lineInfo.y.slice(0, Math.max(0, index - R + 1)),
          ...extrapolation(lineInfo.y, index, value),
          ...lineInfo.y.slice(index + R),
        ]);
      } else {
        lineInfo.setPoints([...lineInfo.y.slice(0, index), value, ...lineInfo.y.slice(index + 1)]);
      }
    },
    [yScale, isExtrapolation, lineInfo]
  );

  if (axes.isNotReady) {
    return null;
  }
  if (yScale === undefined) {
    console.error(`Не определён масштаб для отображаемой кривой ${lineInfo.axisKey}`);
    return null;
  }
  const xScale = axes.axisX.scale;
  let accessor: (index: number) => number;
  if (Array.isArray(lineInfo.x)) {
    accessor = (number: number): number => (lineInfo.x as number[])[number] as number;
  } else {
    accessor = createDomainAccessor<LineData["x"]>(lineInfo.x, lineInfo.y.length);
  }
  return (
    <g ref={ref}>
      {preparedLine.map(
        (line, index) =>
          line.length > 0 && (
            <g key={index} className={clsx(lineInfo.className, cn.line, className)} style={{ color: lineInfo.color }}>
              <path
                strokeDasharray={lineInfo.strokeDasharray}
                d={
                  d3
                    .line<number>()
                    .curve(lineInfo.curve)
                    .x((_, id) => xScale(accessor(line[id].indx)))
                    .y((v) => yScale(v))(line.map((v) => +v.y)) ?? undefined
                }
                className={cn.path}
              />
              {lineInfo.showPoints &&
                line.map((value, id) => {
                  if (isExtrapolation && dayJS(accessor(line[id].indx)).month() !== 0) {
                    return null;
                  }
                  return (
                    <Point
                      key={id}
                      y={yScale(value.y)}
                      x={xScale(accessor(value.indx))}
                      onChange={onDrag}
                      curveKey={lineInfo.key}
                      index={value.indx}
                    />
                  );
                })}
            </g>
          )
      )}
    </g>
  );
});

export { Line, type LineData };
