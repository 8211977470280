import { req } from "utils/request";

type StratumBackend = {
  id: number;
  title: string;
  code: string | null;
  depletion: number | null;
  htr_type_id: number | null;
  htr_type: { id: number; title: string } | null;
};

type StratumRaw = {
  id: number;
  title: string;
  htrType: string | null;
  depletion: number;
};

async function getStratums(): Promise<StratumRaw[]> {
  const response = await req.get<StratumBackend[]>("stratums/");
  return response.map(({ id, title, htr_type, depletion }) => ({
    id,
    title,
    htrType: title === "ЮВ1/1" ? htr_type?.title ?? null : null, // FIXME hardcode for ananastya
    depletion: depletion ?? 0,
  }));
}

export type { StratumRaw };
export { getStratums };
