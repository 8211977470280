import { computed, makeObservable, observable, ObservableSet, runInAction, transaction } from "mobx";
import objectHash from "object-hash";

import {
  type Analogs as AnalogsType,
  type AnalogsRequest,
  AnalogsResult,
  Fitting,
  getAnalogs,
  itemKey,
  RATE_FITTED,
} from "services/back/techForecast/request";
import { getRandomUid } from "utils/random";

import { ScalarDump } from "./methods";
import { type WellTechForecast } from "./wellTechForecast";

class Analogs {
  isApplyConstruction: boolean = true;
  prodMonthDuration: number | null = 60;
  oilRatio: number | null = null;
  oilRatioDiv: number | null = null;
  liquidRatio: number | null = null;
  liquidRatioDiv: number | null = null;
  hidden: ObservableSet<string> = observable.set();

  readonly uid = getRandomUid();

  analogs = observable.map<string, AnalogsResult | undefined>();
  readonly #fittingAfterFilter = observable.box<Omit<AnalogsResult, "items"> | undefined | null>(undefined);

  constructor(readonly mode: "oil" | "liquid", private readonly fc: WellTechForecast) {
    makeObservable(this, {
      isApplyConstruction: observable,
      prodMonthDuration: observable,
      oilRatio: observable,
      oilRatioDiv: observable,
      liquidRatio: observable,
      liquidRatioDiv: observable,

      isValid: computed,
      isLoading: computed,
      save: computed,
      dump: computed,
      currentAnalogs: computed,
      a: computed,
      fitting: computed,
      selectedAmount: computed,
      amount: computed,
      analogsRequest: computed,
    });
  }

  requestImmediately = async () => {
    const { analogsRequest } = this;
    const hash = objectHash(analogsRequest);
    if (this.analogs.has(hash)) {
      return;
    }
    runInAction(() => {
      this.analogs.set(hash, undefined);
    });
    const result = await getAnalogs(analogsRequest);
    runInAction(() => {
      this.analogs.set(hash, result);
    });
    const { wellIds } = this.fittingAfterFilterRequest;
    if (wellIds === null) {
      this.#fittingAfterFilter.set(null);
    }
    this.#fittingAfterFilter.set(await getAnalogs(this.fittingAfterFilterRequest, true));
  };

  get isChanged(): boolean {
    const { analogsRequest } = this;
    const hash = objectHash(analogsRequest);
    return !this.analogs.has(hash);
  }

  readonly applyModal = (
    hidden: ObservableSet<string>,
    fittings: Pick<AnalogsResult, "liquidRateM3Fitted" | "oilRateTFitted">
  ) =>
    runInAction(() =>
      transaction(() => {
        this.hidden.replace(hidden);
        this.#fittingAfterFilter.set(fittings);
      })
    );

  holder(field: "prodMonthDuration" | "oilRatio" | "oilRatioDiv" | "liquidRatio" | "liquidRatioDiv") {
    return (v: number | null) =>
      runInAction(() => {
        this[field] = v;
      });
  }

  readonly applyConstructionHolder = (v: boolean) =>
    runInAction(() => {
      this.isApplyConstruction = v;
    });

  readonly selectAllHolder = () =>
    runInAction(() => {
      this.hidden.clear();
      this.#fittingAfterFilter.set(null);
    });

  get analogsRequest(): AnalogsRequest {
    return {
      scenarioId: this.fc.key.scenarioId,
      stratumId: this.fc.key.stratumId,
      prodMonthDuration: this.prodMonthDuration!,
      producingObjectId: this.fc.producingObjectId,
      wellTypeId: this.isApplyConstruction ? this.fc.wellTypeId : null,
      wellIds: null,
      oilRatio: this.oilRatio,
      oilRatioDiv: this.oilRatioDiv,
      liquidRatio: this.liquidRatio,
      liquidRatioDiv: this.liquidRatioDiv,
    };
  }

  get fittingAfterFilterRequest(): AnalogsRequest {
    const allRequest = this.analogsRequest;
    if (this.hidden.size === 0) {
      return allRequest;
    }
    const currentItems = this.currentAnalogs?.items;
    if (currentItems === undefined) {
      return allRequest;
    }
    const wellIds = currentItems.filter((v) => !this.hidden.has(itemKey(v))).map((v) => v.wellId) ?? null;
    if (wellIds.length === currentItems.length) {
      return allRequest;
    }
    return { ...allRequest, wellIds };
  }

  get dump(): ScalarDump {
    return {
      fnType: "geometric_progression",
      a: this.a ?? null,
    };
  }

  get a(): number | undefined {
    return this.fitting?.a;
  }

  get fitting(): Fitting | undefined | null {
    const fitting = this.#fittingAfterFilter.get();
    const hash = objectHash(this.analogsRequest);
    if (!this.analogs.has(hash)) {
      // фитинг посчитан по устаревшим входным параметрам
      return null;
    }
    if (fitting === null) {
      return this.currentAnalogs?.[RATE_FITTED[this.mode]];
    }
    return fitting?.[RATE_FITTED[this.mode]];
  }

  get selectedAmount(): number | null {
    let counter = 0;
    const { currentAnalogs } = this;
    if (currentAnalogs === undefined) {
      return null;
    }
    const { hidden } = this;
    if (hidden.size === 0) {
      return currentAnalogs.items.length;
    }
    for (const item of currentAnalogs.items)
      if (hidden.has(itemKey(item))) {
        counter += 1;
      }
    return currentAnalogs.items.length - counter;
  }

  get amount(): number | null {
    return this.currentAnalogs?.items.length ?? null;
  }

  get currentAnalogs() {
    return this.analogs.get(objectHash(this.analogsRequest));
  }

  get isValid(): boolean {
    if ((this.oilRatio === null) !== (this.oilRatioDiv === null)) {
      return false;
    }
    if ((this.liquidRatio === null) !== (this.liquidRatioDiv === null)) {
      return false;
    }
    return this.prodMonthDuration !== null;
  }

  get isLoading(): boolean {
    const hash = objectHash(this.analogsRequest);
    return this.analogs.has(hash) && this.analogs.get(hash) === undefined;
  }

  get save(): AnalogsType {
    return {
      isApplyConstruction: this.isApplyConstruction,
      prodMonthDuration: this.prodMonthDuration,
      oilRatio: this.oilRatio,
      oilRatioDiv: this.oilRatioDiv,
      liquidRatio: this.liquidRatio,
      liquidRatioDiv: this.liquidRatioDiv,
      hidden: [...this.hidden.values()],
    };
  }

  fromSave(data: AnalogsType) {
    runInAction(() =>
      transaction(() => {
        this.isApplyConstruction = data.isApplyConstruction;
        this.prodMonthDuration = data.prodMonthDuration;
        this.oilRatio = data.oilRatio;
        this.oilRatioDiv = data.oilRatioDiv;
        this.liquidRatio = data.liquidRatio;
        this.liquidRatioDiv = data.liquidRatioDiv;
        this.hidden.replace(data.hidden);
      })
    );
  }
}

export { Analogs };
