import { FC } from "react";

import { Icon } from "elements/icon/icon";

import type { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g fill="none" stroke="currentColor">
    <path d="M3.5 8L3.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 8L12.5 15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.5 15L3.5 13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 1L12.5 3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 13C4.88174 13 6 11.8807 6 10.5C6 9.11928 4.88174 8 3.5 8C2.11928 8 1 9.11928 1 10.5C1 11.8807 2.11928 13 3.5 13Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3C11.1183 3 10 4.11928 10 5.5C10 6.88072 11.1183 8 12.5 8C13.8807 8 15 6.88072 15 5.5C15 4.11928 13.8807 3 12.5 3Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

const ColumnsSettings: FC<SharedIconProps> = (props) => <Icon viewBox="-4 -4 24 24" {...props} content={ASSET} />;

export { ColumnsSettings };
