import { FC, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { NodeType, useInfrastructureMapContext } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";

import { ReactComponent as GoBackIcon } from "../icons/goBack.svg";
import { Container } from "../ui/container";

import { NodeCatalog } from "./nodeCatalog/nodeCatalog";
import { SelectedItem } from "./selectedItem/selectedItem";

const NodeInspector: FC<{ node: NodeType }> = observer(({ node }) => {
  const [showCatalog, setShowCatalog] = useState(false);

  const { inspector } = useInfrastructureMapContext();
  const { setCurrentItem, clearCurrentItem, selectedNodes, isMultipleItems } = inspector;

  useEffect(() => {
    if (isMultipleItems) {
      setCurrentItem(selectedNodes?.find(({ uuid }) => node.uuid === uuid)!);
    }
  }, [setCurrentItem, selectedNodes, node, isMultipleItems]);

  const goCatalog = useCallback(() => setShowCatalog(true), []);
  const onCloseCatalog = useCallback(() => setShowCatalog(false), []);

  return (
    <>
      {showCatalog ? (
        <Container title="Каталог">
          <NodeCatalog selectedItem={node} onClose={onCloseCatalog} />
        </Container>
      ) : (
        <Container
          title={
            <>
              Инспектор
              {isMultipleItems && <Icon onClick={clearCurrentItem} content={<GoBackIcon width="24px" height="25px" viewBox="0 0 24 25" />} />}
            </>
          }
        >
          <SelectedItem selectedItem={node} goCatalog={goCatalog} />
        </Container>
      )}
    </>
  );
});

export { NodeInspector };
