import { FC } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { observer } from "mobx-react";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Icon } from "elements/icon/icon";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { global } from "models/global";

import { ReactComponent as AddIcon } from "./icons/add.svg";
import { useCreateProjectsContext } from "./createProjectProvider";

import cn from "./roles.module.less";

type UserProps = {
  id: number;
  name: string;
  email: string;
  roleId: number;
  onSetRole?: (id: number, roleId: number) => void;
  onRemoveParticipant?: (id: number) => void;
};

const User: FC<UserProps> = ({ id, name, email, roleId, onSetRole, onRemoveParticipant }) => {
  const { roles } = global;
  const role = roles.at(roleId)?.title ?? "";

  return (
    <div className={cn["user-container"]}>
      <div className={cn.info}>
        <div className={cn.avatar}>
          <UserOutlined />
        </div>
        <div className={cn.name}>{name}</div>
        <div className={cn.email}>{email}</div>
      </div>
      <div className={cn.right}>
        <div className={cn.role}>
          {onSetRole ? (
            <SelectStorable className={cn["role-select"]} store={roles} onChange={(role) => onSetRole(id, Number(role))} defaultValue={role} />
          ) : (
            role
          )}
        </div>
        <div className={cn.delete}>
          {onRemoveParticipant && <DeleteButton className={cn["delete-button"]} onClick={() => onRemoveParticipant(id)} />}
        </div>
      </div>
    </div>
  );
};

const RolesSettings: FC = observer(() => {
  const { participants, setRole, addParticipant, removeParticipant, creator } = useCreateProjectsContext();
  const users = Array.from(global.users.values!);

  const options: DefaultOptionType[] = users
    .filter(({ id: uId }) => !participants.find(({ user: { id } }) => id === uId) && uId !== creator.id)
    .map(({ fullName, id }) => ({ label: fullName, value: id }));

  const creatorInfo = users.find(({ id }) => id === creator.id);

  return (
    <div className={cn.container}>
      <Select
        placeholder="Добавить участника..."
        suffixIcon={<Icon viewBox="0 0 15 15" content={<AddIcon />} />}
        options={options}
        className={cn.select}
        onSelect={(id) => addParticipant(users.find(({ id: uId }) => id === uId)!)}
      />
      {creatorInfo && <User id={creator.id} name={creatorInfo.fullName} email={creatorInfo.email} roleId={2} />}
      {participants.map(({ user: { fullName, email, id }, roleId }) => (
        <User name={fullName} email={email} roleId={roleId} id={id} onSetRole={setRole} onRemoveParticipant={removeParticipant} />
      ))}
    </div>
  );
});

export { RolesSettings };
