import { makeAutoObservable } from "mobx";

class ProdTimeRatio {
  prodTimeRatio: number | null = 1;

  constructor() {
    makeAutoObservable(this);
  }

  get isValid(): boolean {
    return this.prodTimeRatio !== null;
  }

  get dump(): number | null {
    return Math.max(this.prodTimeRatio ?? 1, 0);
  }

  holder = (value: number | null) => {
    this.prodTimeRatio = value;
  };
}

export { ProdTimeRatio };
