import { StorageKey } from "services/finance/utils";

import { backendStorage } from "../backendStorage";

type CompensationCoefficientsRaw = {
  [wellId: number]: {
    [producingObjectId: number]: number;
  };
};

const STORAGE_TITLE = "compensation_coefficient" as const;

async function getCompensationCoefficients(storageKey: StorageKey): Promise<CompensationCoefficientsRaw | null> {
  const { fact, forecast } = storageKey;
  const response = await backendStorage.getItem<CompensationCoefficientsRaw>(STORAGE_TITLE, fact, forecast);
  return response;
}

async function setCompensationCoefficients(storageKey: StorageKey, coeffs: CompensationCoefficientsRaw): Promise<void> {
  const { fact, forecast } = storageKey;
  await backendStorage.setItem<CompensationCoefficientsRaw>(coeffs, STORAGE_TITLE, fact, forecast);
}

export type { CompensationCoefficientsRaw };
export { getCompensationCoefficients, setCompensationCoefficients };
