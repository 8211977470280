import { FC } from "react";

import { Nodes } from "./nodes/nodes";
import Line from "./pipes/line";
import Pipes from "./pipes/pipes";

const CreateTopologies: FC = () => (
  <>
    <Pipes />
    <Nodes />
    <Line />
  </>
);

export default CreateTopologies;
