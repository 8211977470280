import { FC, ReactNode, useCallback } from "react";
import { Radio, RadioChangeEvent, Tooltip } from "antd";
import { RadioGroupButtonStyle } from "antd/es/radio";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";

import { ReactComponent as LiquidIcon } from "./liquidIcon.svg";
import { TechMode } from "./modeSelectorModel";
import { ReactComponent as OilIcon } from "./oilIcon.svg";
import { ReactComponent as WateringIcon } from "./wateringIcon.svg";

import cn from "./modeSelector.module.less";

type ModeSelectorProps = {
  onUpdate: (value: TechMode) => void;
  value: TechMode;
  buttonStyle?: RadioGroupButtonStyle;
  className?: string;
};

const tooltipTitle = (tooltip: string): ReactNode => (
  <>
    Выбрать <b>{tooltip}</b> вычисляемым параметром
  </>
);

const MODE_OPTIONS: { label: ReactNode; value: TechMode; tooltip: string }[] = [
  { value: "oilRate", label: <Icon viewBox="0 0 16 16" content={<OilIcon />} />, tooltip: "добычу нефти" },
  { value: "liquidRate", label: <Icon viewBox="0 0 16 16" content={<LiquidIcon />} />, tooltip: "добычу жидкости" },
  { value: "waterCut", label: <Icon viewBox="0 0 16 16" content={<WateringIcon />} />, tooltip: "обводненность" },
];

const MODE_ICONS = Object.fromEntries(MODE_OPTIONS.map(({ value, label }) => [value, label])) as Record<TechMode, ReactNode>;

const ModeSelector: FC<ModeSelectorProps> = observer(({ onUpdate, value, ...props }) => {
  const onChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      onUpdate(value);
    },
    [onUpdate]
  );
  return (
    <Radio.Group onChange={onChange} value={value} buttonStyle={props.buttonStyle} className={props.className}>
      {MODE_OPTIONS.map((option, index) => (
        <Tooltip overlayClassName={cn.tooltip} key={index} title={tooltipTitle(option.tooltip)}>
          <Radio.Button value={option.value}>{option.label}</Radio.Button>
        </Tooltip>
      ))}
    </Radio.Group>
  );
});

export { MODE_ICONS, MODE_OPTIONS, ModeSelector };
