import { useRef } from "react";
import { observer } from "mobx-react";

import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { ForecastResult } from "models/project/fact/forecast/forecastResult";
import { OptimizerModal as OptimizerModalModel } from "models/project/fact/forecast/optimizerModal";

import { OptimizerModal } from "./optimizerModal";
import { OptimizerModalContextProvider } from "./optimizerModalProvider";

const ModalContent: ModelContentComponentType<OptimizerModalModel, ForecastResult> = observer(({ accept, reject, dataRef }) => {
  return (
    <OptimizerModalContextProvider result={dataRef.current}>
      <OptimizerModal accept={accept} reject={reject} />
    </OptimizerModalContextProvider>
  );
});

const useOptimizerModal = (forecastResult: ForecastResult): (() => Promise<OptimizerModalModel | null>) => {
  const showModal = useModal<OptimizerModalModel, ForecastResult>();

  const dataRef = useRef<ForecastResult>(forecastResult);
  dataRef.current = forecastResult;

  return () =>
    showModal(ModalContent, dataRef, "Экономическая оптимизация", "Применить", undefined, "1165px", null, {
      padding: 0,
    }).catch(() => {
      return null;
    });
};
export { useOptimizerModal };
