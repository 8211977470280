import { ReactNode } from "react";
import { action, makeObservable, observable } from "mobx";

import { TooltipModel } from "../abstractTooltip";

class WrapTooltipModel extends TooltipModel {
  content: ReactNode | null = null;
  lastId: number | null = null;
  coords: [["left" | "right", number], ["top" | "bottom", number]] | undefined = undefined;
  lock: boolean = false;
  constructor() {
    super();
    makeObservable(this, {
      content: observable,
      coords: observable,
      lock: observable,

      onContentUpdate: action,
      lockUpdates: action,
      unlockUpdates: action,
      onClickUpdate: action,
      reset: action,
    });
  }

  onContentUpdate = (content: ReactNode | null, coords?: [["left" | "right", number], ["top" | "bottom", number]]) => {
    if (this.lock === false) {
      this.content = content;
      this.coords = coords;
    }
  };

  lockUpdates = () => {
    this.lock = true;
  };

  unlockUpdates = () => {
    this.lock = false;
    this.content = null;
  };

  onClickUpdate = (
    id: number,
    content: ReactNode | null,
    coords: [["left" | "right", number], ["top" | "bottom", number]]
  ) => {
    if (id !== this.lastId) {
      this.content = content;
      this.coords = coords;
      this.lastId = id;
    } else {
      this.lastId = null;
      this.content = null;
    }
  };

  reset = () => {
    this.content = null;
    this.lastId = null;
  };
}

export { WrapTooltipModel };
