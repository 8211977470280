import { Statuses, StatusSections } from "features/scenariosList/statusCard";

import { backendStorage } from "./backendStorage";

type ScenarioStatusInfo = {
  status: Statuses;
  userId?: number;
};

const setScenarioStatus = async (
  projectId: number,
  scenarioId: number,
  section: StatusSections,
  status: Statuses,
  userId: number
): Promise<ScenarioStatusInfo | null> =>
  backendStorage.setItem<ScenarioStatusInfo>({ status, userId }, `status/${section}`, projectId, scenarioId);

const getScenarioStatus = async (
  projectId: number,
  scenarioId: number,
  section: StatusSections
): Promise<ScenarioStatusInfo | null> =>
  backendStorage.getItem<ScenarioStatusInfo>(`status/${section}`, projectId, scenarioId);

export { getScenarioStatus, setScenarioStatus };
