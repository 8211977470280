import { useCallback } from "react";

import { useModal } from "elements/modal/modal";

import { CompensationCoeffs, ProducingObjectForm } from "./producingObjectForm";

const useProducingObjectModal = () => {
  const modal = useModal<CompensationCoeffs>();

  return useCallback(() => modal(ProducingObjectForm, undefined, "Задание коэффициента компенсации", "Применить", "Отменить", "540px"), [modal]);
};

export { useProducingObjectModal };
