import { memo, useState } from "react";
import { Checkbox, InputNumber, Typography } from "antd";

import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ModelContentComponentType } from "elements/modal/modal";
import { useFact } from "models/project/fact/fact";
import { FondType } from "models/project/fact/well/well";

import cn from "./multiPeriod.module.less";

type PeriodSettings = {
  fonds: FondType[];
  producingObjectsIds: number[];
  monthDuration: { from: number | undefined; to: number | undefined };
  idleWells: boolean;
};

const FOND_OPTIONS: { label: string; value: FondType }[] = [
  {
    label: "Базовый",
    value: "Base",
  },
  {
    label: "Новый",
    value: "New",
  },
];

const MultiPeriodForm: ModelContentComponentType<PeriodSettings> = memo(({ setOnAccept }) => {
  const fact = useFact()!;

  const [result, setResult] = useState<PeriodSettings>({
    fonds: ["Base"],
    producingObjectsIds: [],
    monthDuration: { from: undefined, to: undefined },
    idleWells: true,
  });

  setOnAccept(async () => ({ result: true, data: result }));

  return (
    <div className={cn.modal}>
      <div className={cn.item}>
        <Typography.Text>Фонд скважин</Typography.Text>
        <Checkbox.Group options={FOND_OPTIONS} value={result.fonds} onChange={(checked) => setResult({ ...result, fonds: checked })} />
      </div>
      <div className={cn.item}>
        <Typography.Text>Объекты разработки</Typography.Text>
        <SelectStorable
          mode="multiple"
          store={fact.producingObjects}
          variant="outlined"
          onChange={(values) => setResult({ ...result, producingObjectsIds: values })}
        />
      </div>
      <div className={cn.item}>
        <Typography.Text>Длительность периода (кол-во месяцев в бездействии)</Typography.Text>
        <span className={cn.durationControls}>
          <span>
            от{" "}
            <InputNumber
              value={result.monthDuration.from}
              onChange={(value) => setResult({ ...result, monthDuration: { ...result.monthDuration, from: value ?? undefined } })}
            />
          </span>
          <span>
            до{" "}
            <InputNumber
              value={result.monthDuration.to}
              onChange={(value) => setResult({ ...result, monthDuration: { ...result.monthDuration, to: value ?? undefined } })}
            />
          </span>
        </span>
      </div>
      <div className={cn.item}>
        <Checkbox checked={result.idleWells} onChange={(e) => setResult({ ...result, idleWells: e.target.checked })}>
          Учитывать простаивающие скважины
        </Checkbox>
        <Typography.Text disabled>Скважины по которым не построен прогноз добычи и не относящиеся к нагнетательному фонду</Typography.Text>
      </div>
    </div>
  );
});

export { MultiPeriodForm, type PeriodSettings };
