import React from "react";

import { MiningValueChartFeature } from "./miningValueChartFeature";

const MiningValueChartExample = () => {
  return (
    <div style={{ display: "flex", flex: 1, height: "100%" }}>
      <MiningValueChartFeature />
    </div>
  );
};

export { MiningValueChartExample };
