import type { ChangeEventHandler, DependencyList, FC, MutableRefObject, ReactNode } from "react";
import { useCallback, useEffect, useRef } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Button, Input } from "antd";
import { runInAction } from "mobx";

import { Search } from "elements/icons/search";
import { global } from "models/global";

import cn from "./submenuTitle.module.less";

type TitleProps = {
  buttons?: React.ReactNode;
  onSearchChange?: (v: string | undefined) => void;
  search?: string | undefined;
  title: ReactNode;
  isWrongSearch?: boolean;
} & React.PropsWithChildren;

const useEffectOnce = <T extends { input: HTMLInputElement | null }>(callback: (elem: T) => void, deps?: DependencyList) => {
  const prvInput: MutableRefObject<T | null> = useRef(null);
  const curInput = useRef<T>(null);

  useEffect(() => {
    if (curInput.current && prvInput.current?.input !== curInput.current?.input) {
      callback(curInput.current);
      prvInput.current = curInput.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return curInput;
};

const SubmenuTitle: FC<TitleProps> = ({ onSearchChange, search, title, buttons, isWrongSearch, children }: TitleProps) => {
  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((r) => onSearchChange!(r.target.value), [onSearchChange]);

  const onEdit = useCallback(() => {
    onSearchChange!("");
    runInAction(() => {
      global.isNarrowMenu = false;
    });
  }, [onSearchChange]);
  const onFinished = useCallback(() => onSearchChange!(undefined), [onSearchChange]);

  const input = useEffectOnce<InputRef>((curInput) => curInput.focus({ cursor: "start" }), [search]);

  return (
    <div className={cn.title}>
      {search === undefined ? (
        <p>{title}</p>
      ) : (
        <Input status={isWrongSearch ? "warning" : undefined} ref={input} placeholder="Поиск по имени" onChange={onChange} value={search} />
      )}
      {buttons}
      { onSearchChange && <Button icon={search === undefined ? <Search /> : <CloseCircleOutlined />} onClick={search === undefined ? onEdit : onFinished} />}
      {children}
    </div>
  );
};

export { SubmenuTitle };
