import { FC, useEffect, useRef } from "react";
import { Scale } from "@okopok/axes_context";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { colorPrimary } from "theme/global";

import { SelectionLines } from "../InfrastructureMapManager/selection";
import { getZoomParameters } from "../utils";

import cn from "./selection.module.less";

type Props = {
  line: SelectionLines;
  scale: Scale;
  zoom: number;
  onHover?: (hover: boolean) => void;
  onDrag?: (event: any) => void;
  onEnd?: (event: any) => void;
  addIntermediatePoint?: (event: any) => void;
};

const SelectionLine: FC<Props> = observer(({ line, scale, zoom, onHover, onDrag, onEnd, addIntermediatePoint }) => {
  const pointRef = useRef(null);

  const { zoomScale, zoomScaleFactor } = getZoomParameters(zoom);

  useEffect(() => {
    const dragHandler = d3
      .drag()
      .on("drag", (drag) => {
        onDrag?.(drag);
        onHover?.(true);
      })
      .on("end", (end) => {
        addIntermediatePoint?.(end);
        onEnd?.(end);
      });
    d3.select(pointRef.current).call(dragHandler as any);
  }, [onDrag, onEnd, onHover, addIntermediatePoint]);

  return (
    <>
      <line
        stroke={colorPrimary}
        strokeWidth={zoomScaleFactor}
        x1={scale.x(line.from.x)}
        y1={scale.y(line.from.y)}
        x2={scale.x(line.to.x)}
        y2={scale.y(line.to.y)}
      />
      <g transform={`translate(${scale.x(line.intermediatePoint.x)}, ${scale.y(line.intermediatePoint.y)}), scale(${zoomScale})`}>
        <circle
          r={8}
          ref={pointRef}
          className={cn.selectionIntermediatePoint}
          onClick={(event) => event.stopPropagation()}
          onMouseMove={() => onHover?.(false)}
          onMouseLeave={() => onHover?.(true)}
        />
      </g>
    </>
  );
});

export default SelectionLine;
