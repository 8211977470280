import { computed, makeObservable, observable } from "mobx";

import { Project } from "models/project/project";
import { createProducingObjects, deleteProducingObjects, updateProducingObjects } from "services/back/producingObjects";

import { ProducingObjectTable } from "./producingObject";
import { StratumTable } from "./stratum";

class Tables {
  public stratums?: StratumTable;
  public producingObjects?: ProducingObjectTable;

  public isUpdating: boolean = false;

  constructor(private project: Project) {
    this.rebuild();
    makeObservable(this, {
      isUpdating: observable,
      stratums: observable.ref,
      producingObjects: observable.ref,
      isUpdated: computed,
      isCompleted: computed,
      isValid: computed,
    });
  }

  public get isUpdated() {
    return this.producingObjects?.isUpdated;
  }

  public get isCompleted() {
    return this.producingObjects?.isCompleted;
  }

  public get isValid() {
    return this.producingObjects?.isValid;
  }

  public rebuild() {
    this.stratums = new StratumTable(this.project);
    this.producingObjects = new ProducingObjectTable(this.project, this.stratums.byId);
    this.isUpdating = false;
  }

  public save = async () => {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;

    if (this.producingObjects === undefined) {
      return;
    }
    const deletedPO = this.producingObjects.deletedChildren;
    const createdPO = this.producingObjects.addedChildren;
    const updatedPO = this.producingObjects.mutatedChildren;

    if (deletedPO) {
      const ids = [...deletedPO].map(({ id }) => id ?? null).filter((v): v is number => v !== null);
      await deleteProducingObjects(ids);
    }

    if (updatedPO) {
      const raw = [...updatedPO]
        .filter((po) => !createdPO?.has(po))
        .map(({ toRaw }) => toRaw)
        .filter((v) => v !== undefined && v.id !== null) as any;
      await updateProducingObjects(raw);
    }

    if (createdPO) {
      const raw = [...createdPO].map(({ toRaw }) => toRaw).filter((v) => v !== undefined) as any;
      await createProducingObjects(raw);
    }

    await this.project.producingObjects.reset();
    this.rebuild();
  };
}

export { Tables };
