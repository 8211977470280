import { makeAutoObservable } from "mobx";

import { global } from "models/global";
import { LoadableStoreResolver } from "models/loadableStore/loadableStoreResolver";
import { Tpp } from "services/back/fields";

class Field {
  public readonly id: number;
  public readonly title: string;
  public readonly tpp: Tpp;

  constructor(fieldRaw: { id: number; title: string; tpp: Tpp }) {
    makeAutoObservable(this);

    this.id = fieldRaw.id;
    this.title = fieldRaw.title;
    this.tpp = fieldRaw.tpp;
  }
}

class Fields extends LoadableStoreResolver<Field> {
  constructor(ids: number[]) {
    super(global.fields, ids);
  }
}

export { Fields };
