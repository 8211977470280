import { createContext, useContext } from "react";
import { action, makeObservable, observable } from "mobx";

import { VoronoiGrid } from "services/back/injectionPrediction/voronoi";

import { VoronoiModeSelectorModel } from "./voronoiModeSelector/voronoiModeSelectorModel";

const HoverManagerContext = createContext<HoverManager | null>(null);

const useHoverContext = (): HoverManager | null => {
  const context = useContext(HoverManagerContext);
  return context;
};

class Cell {
  constructor(public id: number, public hoverIds: number[]) {
    makeObservable(this, {
      hoverIds: observable,
    });
  }
}

class HoverManager {
  public injLayer: Cell[] = [];
  public prodLayer: Cell[] = [];

  public hoverArray: number[] = [];
  constructor(data: VoronoiGrid, public modeModel: VoronoiModeSelectorModel) {
    makeObservable(this, {
      injLayer: observable,
      prodLayer: observable,
      modeModel: observable,
      hoverArray: observable,
      reset: action,
      findIntersectId: action,
    });

    this.injLayer = [...Object.entries(data.coefMap)].map(
      ([injId, prods]) =>
        new Cell(
          parseInt(injId),
          [...Object.entries(prods)].map(([prodId]) => parseInt(prodId))
        )
    );

    this.prodLayer = data.prodWells.map(
      (well) =>
        new Cell(
          well.id,
          this.injLayer.filter((cell) => cell.hoverIds.includes(well.id)).map((cell) => cell.id)
        )
    );
  }

  public reset = () => {
    this.hoverArray = [];
  };

  public findIntersectId = (wellId: number) => {
    this.hoverArray = (this.modeModel.mode === "liquidRate" ? this.injLayer : this.prodLayer).find((cell) => cell.id === wellId)?.hoverIds ?? [];
  };
}

export { HoverManager, HoverManagerContext, useHoverContext };
