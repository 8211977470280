/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useRef } from "react";
import { Button, Form, FormProps, Input } from "antd";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { Fact, useFact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";
import { postForecast, type ScenarioNewRaw, ScenarioRaw } from "services/back/project";

import cn from "./createForecastModal.module.less";

type FormState = ScenarioNewRaw;

const toForecast = (fact: Fact, backendResponse: ScenarioRaw): Forecast => new Forecast(fact, backendResponse.title, backendResponse.id);

const ModalContent: ModelContentComponentType<Forecast, undefined> = observer(({ setValid, accept, setLoading, setOnAccept }) => {
  const fact = useFact();
  const { project } = useMainRouterParams();
  const dataRef = useRef<FormState>({
    title: "",
    projectId: project!,
  });

  const checkValid = useCallback(
    (title: string) => {
      setValid(title.length > 0 && fact?.forecasts.find((fc) => fc.title === title) === null);
    },
    [setValid, fact]
  );

  const onChange: FormProps<FormState>["onValuesChange"] = async (changed: Record<string, any>, values) => {
    dataRef.current = {
      ...dataRef.current,
      ...values,
    };
    checkValid(values.title);
  };

  useEffect(() => {
    checkValid(dataRef.current.title);
  }, [checkValid]);

  useEffect(() => setValid(false), [setValid]);

  const onFinish = async () => {
    setLoading(true);
    return { result: true, data: toForecast(fact!, await postForecast(dataRef.current)) };
  };

  setOnAccept(onFinish);

  return (
    <Form className={cn.form} layout="vertical" initialValues={dataRef.current} onValuesChange={onChange} onFinish={accept}>
      <Form.Item required name="title" label="Название сценария">
        <Input />
      </Form.Item>
      <Button style={{ display: "none" }} htmlType="submit" />
    </Form>
  );
});

const useCreateForecastModal = (): (() => Promise<Forecast | null>) => {
  const showModal = useModal<Forecast, undefined>();
  return () => showModal(ModalContent, undefined, "Основные параметры сценария", "Создать").catch(() => null);
};

export { useCreateForecastModal };
