import React from "react";
import { observer } from "mobx-react";

import { TooltipModel } from "elements/tooltips/abstractTooltip";

import cn from "./map.module.less";
const TooltipConsumer = observer(({ tooltipModel }: { tooltipModel: TooltipModel }) => {
  return (
    <div
      className={cn.tooltip}
      style={
        tooltipModel !== null && tooltipModel.coords
          ? {
              display: tooltipModel.content !== null ? "block" : "none",
              [tooltipModel.coords[0][0]]: tooltipModel.coords[0][1],
              [tooltipModel.coords[1][0]]: tooltipModel.coords[1][1],
              pointerEvents: tooltipModel.lock ? "auto" : "none",
            }
          : {}
      }
    >
      <div className={cn.content}>{tooltipModel?.content}</div>
    </div>
  );
});

export { TooltipConsumer };
