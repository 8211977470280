import { useRef } from "react";
import { UploadFile } from "antd";
import { ColumnsType } from "antd/es/table";

import { ImportModalContent, ImportModalResolve, TableState } from "elements/importModalContent/ImportModalContent";
import { useModal } from "elements/modal/modal";
import { useForecast } from "models/project/fact/forecast/forecast";
import { WellsPreview } from "models/project/fact/well/list/wellsPreview";
import { parseExcelFile } from "services/back/wells";

import { WELLS_TEMPLATE_INFO_CARDS } from "./infocards";
import { useColumns, useCustomRenders } from "./wellsList";

type MockDataType = {
  id: number;
  well: string;
  wellPad: number;
  licenseRegion: string;
  oilRate: string;
  liquidRate: string;
  recoverableResources: string;
  waterCut: string;
  type: string;
  length: number;
  date: number | string;
  prodObj: string;
  topX: number;
  topY: number;
  botX: number;
  botY: number;
};

export const MOCK_DATA_TABLE: MockDataType[] = [
  {
    id: 1,
    well: "101",
    wellPad: 10,
    licenseRegion: "ХМН-00498-НЭ",
    type: "МС/МЗС",
    liquidRate: "439,10",
    oilRate: "739,96",
    recoverableResources: "147,26",
    waterCut: "26,78",
    length: 2000,
    date: "06.2024",
    prodObj: "Объект разработки 1",
    topX: 100,
    topY: 150,
    botX: 400,
    botY: 420,
  },
  {
    id: 2,
    well: "102",
    wellPad: 10,
    licenseRegion: "ХМН-00498-НЭ",
    type: "ННС",
    liquidRate: "428,47",
    oilRate: "614,39",
    recoverableResources: "401,91",
    waterCut: "15,22",
    length: 2000,
    date: "03.2027",
    prodObj: "Объект разработки 2",
    topX: 170,
    topY: 190,
    botX: 200,
    botY: 220,
  },
  {
    id: 3,
    well: "103",
    wellPad: 10,
    licenseRegion: "ХМН-00497-НЭ",
    type: "ГС",
    liquidRate: "591,80",
    oilRate: "850,07",
    recoverableResources: "311,30",
    waterCut: "55,92",
    length: 2000,
    date: "08.2028",
    prodObj: "Объект разработки 3",
    topX: 110,
    topY: 140,
    botX: 390,
    botY: 400,
  },
];

export const columnsMock: ColumnsType<MockDataType> = [
  {
    dataIndex: "title",
    title: "Скважина",
    width: 80,
  },
  {
    dataIndex: "wellPad",
    title: "Куст",
    width: 76,
  },
  {
    dataIndex: "licenseRegion",
    title: "ЛУ",
    width: 176,
  },
  {
    dataIndex: "type",
    title: "Тип заканчивания",
    width: 100,
  },
  {
    dataIndex: "topX",
    title: "Устье X, м",
    width: 105,
  },
  {
    dataIndex: "topY",
    title: "Устье Y, м",
    width: 105,
  },
  {
    dataIndex: "botX",
    title: "Забой X, м",
    width: 105,
  },
  {
    dataIndex: "botY",
    title: "Забой Y, м",
    width: 105,
  },
  {
    dataIndex: "liquidRate",
    title: "Стартовый дебит жид-ти, м3/сут",
    width: 124,
  },
  {
    dataIndex: "oilRate",
    title: "Стартовый дебит нефти, т/сут",
    width: 124,
  },
  {
    dataIndex: "recoverableResources",
    title: "Извлекаемые запасы, тыс т",
    width: 100,
  },
  {
    dataIndex: "length",
    title: "Проходка, м",
    width: 116,
  },
  {
    dataIndex: "date",
    title: "Дата проведения",
    width: 100,
  },
  {
    dataIndex: "prodObj",
    title: "Объект разработки",
    width: 250,
  },
];

const useImportWellsModal = (): ((file: UploadFile | null) => Promise<ImportModalResolve | null>) => {
  const showModal = useModal<ImportModalResolve, TableState<WellsPreview>>();

  const columns = useColumns(true, true);
  const forecast = useForecast()!;

  const parseExcel = async (file: UploadFile): Promise<WellsPreview> => {
    try {
      const resolve = await parseExcelFile(file, forecast.id);
      return new WellsPreview(forecast, resolve);
    } catch (err) {
      throw new Error("invalid file format");
    }
  };

  const val: { current: TableState<WellsPreview> } = useRef({
    columns: columns.filter(({ key }) => key !== "expand" && key !== "sticky"),
    customRenders: useCustomRenders(true, true),
    parseExcel,
    template: {
      infoCards: WELLS_TEMPLATE_INFO_CARDS,
      templateLink: (
        <a href="/static/Список скважин.xlsx" download="wells_template.xlsx">
          Скачать шаблон списка скважин
        </a>
      ),
      data: MOCK_DATA_TABLE,
      columns: columnsMock,
    },
  });

  return async (file: UploadFile | null) => {
    val.current.file = file;
    return showModal(ImportModalContent, val, "Импорт скважин", null, null, "1536px", null)
      .then((data) => data)
      .catch(() => null);
  };
};

export { useImportWellsModal };
