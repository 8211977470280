import { ECY } from "models/project/fact/ecy";
import { Range } from "utils/range";
import { findInTree, ofTree } from "utils/tree";

import { ECYAsSourceFormat } from "./ecy";
import rawECYPatchData from "./ecyPathcerData.json";

type RawPatchDataForTarget = {
  "Цены и курсы валют": any[];
  "Индексы цен": any[];
  Налоги: any[];
  "Цены ЕСУ по Провинции": any[];
};

export const ECY_BASE_ID = -1;
export const ECY_CURRENT_ID = -2;

export const checkSystemECY = (value: number | ECY | null | undefined) => {
  if (!value) {
    return undefined;
  }

  const id = typeof value === "number" ? value : value.id;
  return [ECY_BASE_ID, ECY_CURRENT_ID].includes(id);
};

const parseValue = (v: number | string | null) => {
  if (typeof v === "string") {
    const value = parseFloat(v);
    return isFinite(value) ? value : null;
  }
  return v;
};

const parseRawPatchData = (rawPatchData: RawPatchDataForTarget) => {
  const patchData = [];

  for (const [rootCodeTitle, rootValues] of Object.entries(rawPatchData)) {
    for (const [codeTitle, unit, ...values] of rootValues) {
      patchData.push({
        rootCodeTitle,
        codeTitle,
        unit,
        values: values.map(parseValue),
      });
    }
  }

  return patchData;
};

const currentECYData = parseRawPatchData(rawECYPatchData.current);
const baseECYData = parseRawPatchData(rawECYPatchData.base);

const patchECY = (
  range: Range,
  id: number,
  ecyData: ECYAsSourceFormat,
  patchData: { rootCodeTitle: string; codeTitle: string; unit: string; values: (number | null)[] }[],
  title: string,
  discount: number
) => {
  const patchedCodeTitles = new Set();
  const existCodeTitles = new Set();

  for (const { code_title: rootCodeTitle, children } of ecyData.usc) {
    for (const { code_title: codeTitle } of children) {
      existCodeTitles.add(`${rootCodeTitle}|${codeTitle}`);
    }
  }

  const patched = new Set<number>();

  for (const { rootCodeTitle, codeTitle, values } of patchData) {
    const childNode = findInTree((e) => e.code_title === codeTitle, ecyData.usc);
    if (!childNode) {
      continue;
    }

    patched.add(childNode.key);

    const injectValues = range.array
      .fill(null)
      .map((_, i) => (values.length > i ? values[i] : values[values.length - 1]));
    childNode.values = injectValues;

    patchedCodeTitles.add(`${rootCodeTitle}|${codeTitle}`);
  }

  for (const node of ofTree(ecyData.usc)) {
    console.assert(node.children || patched.has(node.key), `Не обнаружены данные ЕСУ по метрике ${node.title}`);
  }

  for (const key of existCodeTitles) {
    if (!patchedCodeTitles.has(key)) {
      console.warn(`Отсутсвует ${key} в patchData`);
    }
  }

  ecyData.title = title;
  ecyData.id = id;
  ecyData.discount = discount;

  return ecyData;
};

export const pathECYWithCurrent = (range: Range, data: ECYAsSourceFormat) => {
  return patchECY(range, ECY_CURRENT_ID, data, currentECYData, "Текущие цены", 12.2);
};

export const pathECYWithBase = (range: Range, data: ECYAsSourceFormat) => {
  return patchECY(range, ECY_BASE_ID, data, baseECYData, "Базовые ЕСУ", 15);
};
