import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { ApgStatePlan as ApgStatePlanFeature } from "features/report/apgStatePlan/apgStatePlan";
import { useFact } from "models/project/fact/fact";

const ApgStatePlan: FC = observer(() => {
  return (
    <Preloader hooks={[useFact]}>
      <PageFrame title="ГОСПЛАН-газ">
        <ApgStatePlanFeature />
      </PageFrame>
    </Preloader>
  );
});

export { ApgStatePlan };
