import { useMemo } from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";

import { DrainSources } from "../models/drainSources";

import cn from "../infrastructureParamsTable.module.less";

const useColumns = () => {
  const { range } = useInfrastructure()!;
  return useMemo(
    (): Column[] => [
      {
        title: "Название",
        width: { min: 300, max: 400, competitiveness: 1 },
        dataKey: "title",
        type: "string",
        editable: true,
        onCell: () => ({ className: classNames(cn.tableFirstCell, cn.tableCell) }),
      },
      {
        title: "Дата ввода",
        width: { min: 250, max: 400, competitiveness: 1 },
        dataKey: "startedAt",
        type: "date",
        renderFormat: "month",
        start: dayjs().year(range.from),
        end: dayjs().year(range.to),
        editable: true,
        onCell: () => ({ className: classNames(cn.tableCellAlignRight, cn.tableCell) }),
      },
      {
        title: "Координаты, м",
        width: { min: 300, max: 400, competitiveness: 1 },
        dataKey: "coords",
        onCell: () => ({ className: cn.tableCell }),
        type: "string",
      },
      {
        title: "Альтитуда, м",
        width: { min: 250, max: 400, competitiveness: 1 },
        dataKey: "altitude",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        type: "number",
        editable: true,
      },
    ],
    [range]
  );
};

const useParamDrainSourcesTableStore = (mode: "drain" | "source") => {
  const infrastructure = useInfrastructure();
  const columns = useColumns();
  const store = useMemo(() => new DrainSources(infrastructure, mode), [infrastructure, mode]);
  return { columns, store };
};

export { useParamDrainSourcesTableStore };
