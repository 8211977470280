import { FC, ReactNode } from "react";
import { Button, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { ApproveIcon } from "elements/icons/approve";
import { ClockIcon } from "elements/icons/clock";
import { EconomicIcon } from "elements/icons/economic";
import { InfrastructureIcon } from "elements/icons/infrastructure";
import { InProgress } from "elements/icons/inProgress";
import { ReloadIcon } from "elements/icons/reload";
import { RkpIcon } from "elements/icons/rkp";
import { TechnologyIcon } from "elements/icons/technology";
import { Loader } from "elements/loader";
import { ProjectInfoCard } from "features/projects/projectInfoCard";
import { UserCard } from "features/projects/userCard";
import { global } from "models/global";
import { Forecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

import cn from "./statusCard.module.less";

type StatusSections = "tech" | "economic" | "ranking" | "infrastructure";

type Statuses = "inProgress" | "onApproval" | "approved";

type StatusCardProps = {
  section: StatusSections;
  scenario: Forecast;
};

type StatusProps = {
  status: Statuses;
};

type Permission = {
  approve: number[];
  send: number[];
};

type ButtonInfo = {
  title: string;
  toStatus: Statuses;
  icon: ReactNode;
};

const SECTION_PERMISSIONS: Record<StatusSections, Permission> = {
  tech: {
    approve: [6, 2],
    send: [2, 3],
  },
  economic: {
    approve: [8, 2],
    send: [2, 5],
  },
  ranking: {
    approve: [6, 8, 2],
    send: [2, 3, 5],
  },
  infrastructure: {
    approve: [7, 2],
    send: [2, 4],
  },
};

const SECTION_TITLES: Record<StatusSections, string> = {
  tech: "Технологические показатели",
  economic: "Экономические показатели",
  ranking: "Ресурсно-календарное планирование",
  infrastructure: "Наземная инфраструктура",
};

const SECTION_ICONS: Record<StatusSections, ReactNode> = {
  tech: <TechnologyIcon width="16" height="16" />,
  economic: <EconomicIcon width="16" height="16" />,
  ranking: <RkpIcon width="16" height="16" />,
  infrastructure: <InfrastructureIcon width="16" height="16" />,
};

const STATUS_TITLES: Record<Statuses, string> = {
  inProgress: "В работе",
  onApproval: "На согласовании",
  approved: "Согласовано",
};

const STATUS_ICONS: Record<Statuses, ReactNode> = {
  inProgress: <InProgress width="16" height="16" className={cn["in-progress"]} />,
  onApproval: <ClockIcon width="16" height="16" className={cn["on-approval"]} />,
  approved: <ApproveIcon width="16" height="16" className={cn["approved"]} />,
};

const ActionButton: FC<StatusCardProps & { className?: string }> = observer(({ scenario, section, className }) => {
  const project = useProject()!;
  const userRoles = project?.participants.getById(global.user?.id!).roles;
  const sectionStatus = scenario.getSectionStatus(section);
  const status = sectionStatus?.status;

  const buttonsInfo: ButtonInfo[] = [];

  if (userRoles.some((role) => SECTION_PERMISSIONS[section].approve.includes(role.id))) {
    if (status === "onApproval") {
      buttonsInfo.push({
        title: "Согласовать",
        toStatus: "approved",
        icon: <ApproveIcon width="16" height="16" />,
      });
    }
    if (status === "approved") {
      buttonsInfo.push({
        title: "Отменить согласование",
        toStatus: "inProgress",
        icon: <ReloadIcon width="12" height="12" />,
      });
    }
  }

  if (userRoles.some((role) => SECTION_PERMISSIONS[section].send.includes(role.id))) {
    if (status === "inProgress") {
      buttonsInfo.push({
        title: "Отправить на согласование",
        toStatus: "onApproval",
        icon: <ApproveIcon width="12" height="12" />,
      });
    }
    if (status === "onApproval") {
      buttonsInfo.push({
        title: "Отозвать с согласования",
        toStatus: "inProgress",
        icon: <ReloadIcon width="12" height="12" />,
      });
    }
  }

  return buttonsInfo.length !== 0 ? (
    <>
      {buttonsInfo.map(({ title, icon, toStatus }, index) => (
        <Button
          loading={sectionStatus?.isLoading}
          onClick={() => scenario?.setSectionStatus(section, toStatus)}
          className={classNames(cn["action-button"], className, index !== 0 ? cn["button-container"] : "")}
          icon={icon}
        >
          {title}
        </Button>
      ))}
    </>
  ) : (
    <></>
  );
});

const StatusComponent: FC<StatusProps & { className?: string }> = ({ status, className }) => {
  return (
    <div className={classNames(cn.status, className)}>
      <Typography.Text className={cn["status-text"]}>{STATUS_TITLES[status]}</Typography.Text>
      {STATUS_ICONS[status]}
    </div>
  );
};

const StatusCard: FC<StatusCardProps> = observer(({ section, scenario }) => {
  const status = scenario.getSectionStatus(section);

  if (!status) {
    return <Loader />;
  }

  return (
    <ProjectInfoCard title={SECTION_TITLES[section]} icon={SECTION_ICONS[section]} className={cn["status-card"]} iconFirst>
      <div className={cn["info-container"]}>
        <StatusComponent status={status.status ?? "inProgress"} className={cn["status-position"]} />
        {status.userId ? <UserCard id={status.userId} large /> : null}
        <ActionButton scenario={scenario} section={section} className={status.userId ? cn["button-container"] : ""} />
      </div>
    </ProjectInfoCard>
  );
});

export { STATUS_TITLES, StatusCard, StatusComponent };
export type { Statuses, StatusSections };
