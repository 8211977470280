import { makeAutoObservable } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { DecreaseCoefficients } from "models/project/fact/forecast/operatingGTM/decreaseCoefficients";
import { Range } from "utils/range";

import { CalcModelInjectionWells } from "./models/injectionWells";
import { CalcModelMiningWells } from "./models/miningWells";
import { CalcModelOtherGTM } from "./models/otherGTM";
import { CalcModelPhysic } from "./models/physic";
import { CalcModelRepair } from "./models/repair";
import { CalcModelSimilarParam } from "./models/similarParams";
import { CalcEffectTableNode, TableRow } from "./calcEffectTableModel";

class CalcModel {
  miningWellsModel: CalcModelMiningWells;
  injectionWellsModel: CalcModelInjectionWells;
  similarParamsModel: CalcModelSimilarParam[] = [];
  otherGTMModel: CalcModelOtherGTM;
  physicModel: CalcModelPhysic;
  repairModel: CalcModelRepair;

  decreaseCoefficientsModel: DecreaseCoefficients;

  constructor(public childrenStore: CalcEffectTableNode[], public range: Range, public forecast: Forecast) {
    makeAutoObservable(this);
    this.decreaseCoefficientsModel = this.forecast.decreaseCoefficients;

    this.miningWellsModel = new CalcModelMiningWells(
      childrenStore.find((child) => child.dataKey === "miningWells")!,
      this
    );

    this.injectionWellsModel = new CalcModelInjectionWells(
      childrenStore.find((child) => child.dataKey === "pumpingWells")!,
      this
    );

    ["optimization", "rir", "grp", "perforation", "otherOPZ"].forEach((dataKey) => {
      this.similarParamsModel.push(
        new CalcModelSimilarParam(childrenStore.find((child) => child.dataKey === dataKey)!, this)
      );
    });

    this.otherGTMModel = new CalcModelOtherGTM(childrenStore.find((child) => child.dataKey === "otherGTM")!, this);

    this.physicModel = new CalcModelPhysic(childrenStore.find((child) => child.dataKey === "physic")!, this);

    this.repairModel = new CalcModelRepair(childrenStore.find((child) => child.dataKey === "repair")!, this);
  }

  getFirstNElements(param: TableRow, n: number = 3) {
    return Array(n)
      .fill(null)
      .map((value, index) => this.range.from + index)
      .map((year) => param.data[year]);
  }
}

export { CalcModel };
