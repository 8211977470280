import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { reqCamel } from "utils/request";

type ProducingObjectBackend = {
  id: number;
  title: string;
  project_id: number;

  density_oil: number;
  density_water: number;
  viscosity_oil: number;
  viscosity_water: number;
  gas_oil_ratio: number;

  main_stratum_id: number;
  stratum_ids: number[];
};

type ProducingObjectRaw = ObjectToCamel<ProducingObjectBackend>;

const PREFIX = "producing_object" as const;

function getProducingObjects(projectId: number): Promise<ProducingObjectRaw[]> {
  return reqCamel.get<ProducingObjectBackend[]>(`${PREFIX}/?project_id=${projectId}`);
}

function deleteProducingObjects(producingObjectsIds: number[]) {
  return Promise.all(producingObjectsIds.map((id) => reqCamel.delete(`${PREFIX}/${id}`)));
}

function createProducingObjects(producingObjects: Omit<ProducingObjectRaw, "id">[]) {
  return Promise.all(producingObjects.map((po) => reqCamel.post<ProducingObjectBackend>(`${PREFIX}/`, po)));
}

function updateProducingObjects(producingObjects: ProducingObjectRaw[]) {
  return Promise.all(
    producingObjects.map(({ id, ...rest }) => reqCamel.put<ProducingObjectBackend>(`${PREFIX}/${id}`, rest))
  );
}

export type { ProducingObjectRaw };
export { createProducingObjects, deleteProducingObjects, getProducingObjects, updateProducingObjects };
