import { FC } from "react";
import { Table } from "@okopok/components/Table/Table";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Loader } from "elements/loader";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { hasStoreNoData } from "utils/hasStoreNoData";

import { DrainSourcesModel } from "./models/drainSources";
import { WellPadsModel } from "./models/mines";
import { PipesModel } from "./models/pipes";
import { SubFrameTitle } from "./subFrameTitle";

import cn from "./infrastructureResultsTable.module.less";

type Props = {
  mode: string;
  store: PipesModel | WellPadsModel | DrainSourcesModel;
  columns: Column[];
};

const InfrastructureResultsTable: FC<Props> = observer(({ mode, store, columns }) => {
  const infrastructure = useInfrastructure();

  if (store.isLoading) {
    return <Loader />;
  }

  return (
    <SimpleTableContext
      data={store}
      columns={columns}
      exportFileName="Результаты гидравлического расчета"
      hideExpandColumn={mode === "drain" || mode === "drain"}
      tableSettingsId="infrastructure_table_results"
      tableOptions={{
        onRow: ({ indexPath, expand, value }) => ({
          className: classNames({
            [cn.tableRowPlain]: expand === undefined,
            [cn.tableRowPrimary]: indexPath.length === 1,
            [cn.columnWarning]: value?.isProblem,
          }),
        }),
      }}
    >
      <SubFrameTitle store={infrastructure.calculateStore} />
      {hasStoreNoData(store) ? (
        <FullScreenEmpty>Нет данных для отображения</FullScreenEmpty>
      ) : (
        <div style={{ height: "100%" }}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      )}
    </SimpleTableContext>
  );
});

export { InfrastructureResultsTable };
