import { FC, useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import dayjs from "dayjs";
import { objectToCamel } from "ts-case-convert";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { Format } from "elements/format/format";
import { useModal } from "elements/modal/modal";
import { CommitInfo, getVersionInfo, Version } from "services/versionInfo";

import frontendVersion from "./frontendVersion.json";

import cn from "./aboutModal.module.less";

const FRONTEND_VERSION = objectToCamel(frontendVersion);

type FrontVersion = ObjectToCamel<typeof frontendVersion>;

const handmadeCheck = (v: FrontVersion): FrontVersion => {
  // если это заглушка
  if (v.commitSha === "92d4817a0a36826a5813c0f0f08ed01dc74fc11a") {
    return {
      commitDate: new Date().toISOString(),
      commitSha: "develop",
      revision: "handmade",
    };
  }
  return v;
};

const ThreadInfo: FC<{ info: CommitInfo }> = ({ info }) => (
  <ul>
    <li>
      <Format>{dayjs(info.commitDate).format("YYYY-MM-DD HH:mm:ss")}</Format>
    </li>
    <li>
      <Format>{info.commitSha}</Format>
    </li>
    <li>
      <Format>{info.revision}</Format>
    </li>
  </ul>
);

const ModalContent = () => {
  const [versionInfo, setVersionInfo] = useState<Version>();
  useEffect(() => {
    getVersionInfo().then(
      (result) => {
        result.software.front = handmadeCheck(FRONTEND_VERSION);
        setVersionInfo(result);
      },
      () => console.error("Не получилось получить версию")
    );
  }, []);
  return (
    <div className={cn.wrap}>
      {versionInfo && (
        <div className={cn.blockInfo}>
          <div>
            <span>Дата сборки: </span>
            <Format>{dayjs(versionInfo?.buildDate).format("YYYY-MM-DD HH:mm:ss")}</Format>
          </div>
          {(
            Object.entries({
              back: "Бэкенд",
              deploy: "Система сборки",
              front: "Фронтенд",
            }) as [["back" | "front" | "deploy", string]]
          ).map(([key, title]) => (
            <div key={key}>
              <span>{title}: </span>
              <ThreadInfo info={versionInfo.software[key]} />
            </div>
          ))}
        </div>
      )}
      <Button href="/static/guide.pdf" type="primary" icon={<DownloadOutlined />} className={cn.btn}>
        {" "}
        Инструкция пользователя
      </Button>
      <br />
      <Button href="/static/ReleaseNotes.pdf" type="primary" icon={<DownloadOutlined />} className={cn.btn}>
        {" "}
        История изменений
      </Button>
      <br />
      <Button href="/static/economy.pdf" type="primary" icon={<DownloadOutlined />} className={cn.btn}>
        {" "}
        Спецификация по экономическим расчетам
      </Button>
    </div>
  );
};

const useAboutModal = () => {
  const showModal = useModal();

  return () => showModal(ModalContent, undefined, "О программе", null, null, "600px");
};
export { useAboutModal };
