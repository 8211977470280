import { FC, ReactNode } from "react";
import { ChartContext, LineDataModel, Lines } from "@okopok/axes_context";
import dayJS, { Dayjs } from "dayjs";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext, useTooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { useLinesBarsComposition } from "features/plot/useLinesBarsComposition";
import { BalanceResult } from "models/project/stratum/materialBalance";
import { colorCarousel } from "services/colorCarousel";

import cn from "./balanceChart.module.less";

type BalanceChartProps = {
  data: BalanceResult[];
};

const BalanceChart: FC<BalanceChartProps> = ({ data }) => {
  const preparedLines: LineDataModel[] = [];
  const titles: [string, ReactNode][] = [];
  let currentColor = 0;

  for (const parameter of data) {
    const x: Dayjs[] = [];
    const y: number[] = [];
    titles.push([`axis${parameter.measure}`, parameter.measure]);

    Object.entries(parameter)
      .filter(([key]) => Number(key))
      .forEach(([year, value]) => {
        x.push(dayJS(year));
        y.push(Number(value));
      });

    preparedLines.push(
      new LineDataModel({
        x,
        y,
        axisKey: `axis${parameter.measure}`,
        key: `axis${parameter.key}`,
        color: colorCarousel(currentColor),
        title: parameter.title,
      })
    );
    currentColor++;
  }
  const TITLES = new Map<string, ReactNode>(titles);

  const tooltipManager = useTooltipDataManager(preparedLines);

  return (
    <div className={cn.wrapper}>
      <TooltipDiv manager={tooltipManager} />
      <ChartContext axes={useLinesBarsComposition(preparedLines, undefined, undefined, TITLES)} className={cn.chart}>
        <TooltipContext.Provider value={tooltipManager}>
          <Lines data={preparedLines} />
          <PointerLine />
        </TooltipContext.Provider>
      </ChartContext>
    </div>
  );
};

export { BalanceChart };
