import { getReferenceResolver } from "services/back/reference";
import { Range } from "utils/range";

import { getUniversalDataSource, riseMetrics } from "./utils";

const params = {
  propertyTaxAdjustment: "Корректировка налога на имущество",
  calculationIncomeTax: "Расчет. Налог на прибыль",
  writeOffOfCostsForSidetracking: "Списание затрат на зарезку боковых стволов",
  writeOffOfOtherExpenses: "Списание прочих затрат",
} as const;

const excludeParams = [params.calculationIncomeTax, params.propertyTaxAdjustment];

const getReference = (isForecast?: boolean) => async (range: Range) => {
  const { schema_operation } = await getReferenceResolver(range);
  const metricSchema = riseMetrics(schema_operation).filter((el) => excludeParams.includes(el.code_title! as any));

  let childrens = [...metricSchema[0].children, ...metricSchema[1].children];
  if (isForecast) {
    childrens = childrens.filter(
      (el) => ![params.writeOffOfCostsForSidetracking, params.writeOffOfOtherExpenses].includes(el.title! as any)
    );
  }
  const result = childrens.sort((a, b) => b.title.localeCompare(a.title));
  return { table: result };
};

const [referenceFactSource, referenceForecastSource] = getUniversalDataSource([
  { key: "ReferenceFact", fn: getReference() }, //Налог на прибыль
  { key: "Reference", fn: getReference(true) },
]);

export { referenceFactSource, referenceForecastSource };
