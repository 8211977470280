import { action, computed, makeObservable, observable } from "mobx";

import { ProducingObject } from "models/project/producingObject/producingObject";
import { ProducingObjects } from "models/project/producingObject/producingObjects";

const DEFAULT_VALUE = 115;

class FormItem {
  public value: number | null;

  constructor(private root: ProducingObjectFormModel, public readonly producingObject: ProducingObject) {
    makeObservable<FormItem>(this, {
      value: observable,
      update: action,
    });

    this.value = root.commonValue;
  }

  public update = (newValue: number | null) => {
    this.value = newValue;
    if (this.root.isAllSame) {
      this.root.commonValue = newValue;
    } else {
      this.root.commonValue = null;
    }
  };

  public reset = () => {
    this.update(DEFAULT_VALUE);
  };
}

class ProducingObjectFormModel {
  public commonValue: number | null = DEFAULT_VALUE;
  private items: FormItem[];

  constructor(producingObjects: ProducingObjects) {
    makeObservable<ProducingObjectFormModel, "commonValue">(this, {
      commonValue: observable,
      individuals: computed,
      isAllSame: computed,
      setCommonValue: action,
    });

    console.assert(producingObjects.isLoading === false);
    this.items = producingObjects.map((po) => new FormItem(this, po))!;
  }

  public get individuals(): FormItem[] {
    return this.items;
  }

  public get isAllSame(): boolean {
    return this.items.every((item, _, items) => item.value === items[0].value);
  }

  public setCommonValue = (newValue: number | null) => {
    this.commonValue = newValue;
    for (const item of this.items) {
      item.value = newValue;
    }
  };

  public resetCommonValue = () => {
    this.setCommonValue(DEFAULT_VALUE);
  };
}

export { DEFAULT_VALUE, ProducingObjectFormModel };
