import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { Virtuoso } from "react-virtuoso";
import { Checkbox, Skeleton, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { ExpandButton } from "elements/expandButton/expandButton";
import { Preloader } from "features/preloader/preloader";
import { useTreeContext } from "models/tree/context";
import { TreeNode } from "models/tree/tree";

import { FilterPicker } from "./filterPicker";
import { NestingSelector } from "./nestingSelector";

import styles from "./tree.module.less";

type RowProps = {
  node?: TreeNode<any>;
  customLeaf?: CustomLeaf;
  checkboxes?: boolean;
  onRow?: (node?: TreeNode<any>) => Omit<HTMLAttributes<HTMLDivElement>, "style">;
};

const Row: FC<RowProps> = observer(({ node, checkboxes = true, customLeaf, onRow }) => {
  if (!node) {
    return null;
  }

  const { expand, select, value, indexPath } = node.asTableItem;
  const level = indexPath.length;
  const isRoot = level === 0;
  const isLeaf = !isRoot && expand === undefined;
  const onChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      select?.onSelect();
    } else {
      select?.onDeselect();
    }
  };
  const { className, ...rest } = onRow?.(node) ?? {};
  const padding = 8 + level * 10 + +isRoot * 16 + +isLeaf * 34;
  return (
    <div {...rest} className={classNames(styles.treeRow, className)} style={{ paddingLeft: padding, width: `calc(100% - ${padding + 8}px)` }}>
      <ExpandButton expand={expand} />
      {checkboxes && select && (
        <Checkbox indeterminate={select.status === "partiallySelected"} checked={select.status === "selected"} onChange={onChange} />
      )}
      {customLeaf ? customLeaf(node) : <div className={classNames({ [styles.node]: !isLeaf }, styles.leaf)}>{value?.title}</div>}
    </div>
  );
});

type CustomLeaf = (node?: TreeNode<any>) => ReactNode;

type TreeProps = {
  title: string;
  className?: string;
  customLeaf?: CustomLeaf;
  onRow?: (node?: TreeNode<any>) => Omit<HTMLAttributes<HTMLDivElement>, "style">;
  preloadHooks: (() => unknown)[];
};

const TreeSkeleton = ({ className, title }: { className?: string; title: string }) => {
  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.head}>
        <Typography.Text>
          {title} <Skeleton.Input active size="small" />
        </Typography.Text>
      </div>
      <Skeleton.Input style={{ width: "100%" }} />
      <div className={styles["list-skeleton"]}>
        <Skeleton active />
      </div>
    </div>
  );
};

const Tree: FC<TreeProps & PropsWithChildren> = observer(({ title, className, preloadHooks, customLeaf, onRow, children }) => {
  const tree = useTreeContext();
  const filterManager = tree.filterManager;

  return (
    <Preloader hooks={preloadHooks} whileLoading={<TreeSkeleton title={title} className={className} />}>
      <div className={classNames(className, styles.container)}>
        <div className={styles.head}>
          <Typography.Text>
            {title} ({tree.selectedCount}/{tree.totalCount})
          </Typography.Text>
          {filterManager && filterManager.filters.length > 0 && (
            <FilterPicker value={filterManager.value} onChange={filterManager.setSelected} groups={filterManager.groups} />
          )}
        </div>
        {children}
        {tree.nestingFields.length > 0 && <NestingSelector />}
        <div className={styles.list}>
          <Virtuoso
            totalCount={tree.length}
            fixedItemHeight={30}
            itemContent={(index) => {
              try {
                const node = tree.at(index) as TreeNode<any> | undefined;
                return <Row node={node} customLeaf={customLeaf} onRow={onRow} checkboxes={tree.selectable} />;
              } catch (error) {
                console.error(error); // TODO: Без перехвата этой ошибки приложение падает в случае переключения на уже загруженный forecast. Покрайней мере с обычным перехватом этой ошибки работает как надо.
                return null;
              }
            }}
          />
        </div>
      </div>
    </Preloader>
  );
});

export { type CustomLeaf, Tree };
