import { BaseReport } from "../baseReport";

export const AnalizReport = () => {
  return (
    <BaseReport
      options={{
        chooseSingleLicenceZone: true,
        useEcyLists: true,
        chooseSingleUsc: true,
        chooseSingleUscIsImportant: true,
      }}
      apiPath="reports/analiz-report"
      title="Анализ"
    />
  );
};
