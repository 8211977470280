import { createContext, FC, RefObject, useContext } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";

const CONTEXT = createContext<{
  toolboxRef: RefObject<HTMLElement>;
} | null>(null);

const CardToolboxPortalContextProvider = CONTEXT.Provider;

type CardTitlePortalProps = {
  children?: React.ReactNode;
};

const CardTitlePortal: FC<CardTitlePortalProps> = observer(({ children }) => {
  const { toolboxRef } = useContext(CONTEXT)!;
  if (toolboxRef.current === null) {
    return null;
  }

  return createPortal(children, toolboxRef.current);
});

export { CardTitlePortal, CardToolboxPortalContextProvider };
