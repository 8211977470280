import { Dayjs } from "dayjs";

const formatElementsDisabled = <T extends { startedAt: Dayjs; finishedAt?: Dayjs | null }>(
  array: T[],
  date?: Dayjs
): ({ isDisabledDate?: boolean } & T)[] => {
  const isDisabled = (currentDate: Dayjs, startedAt: Dayjs, finishedAt?: Dayjs | null) => {
    if (!!(currentDate.isSame(startedAt, "month") || (finishedAt && currentDate.isSame(finishedAt, "month")))) {
      return false; //если выбранная дата совпала с датой ввода или вывода - not disabled
    }
    return (currentDate.isAfter(finishedAt) && !!finishedAt) || currentDate.isBefore(startedAt, "month");
  };
  if (!date) return array;
  return array.map((item) => ({
    ...item,
    isDisabledDate: isDisabled(date, item.startedAt, item.finishedAt),
  }));
};

export { formatElementsDisabled };
