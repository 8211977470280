import React, { type FC, type ReactNode, useRef } from "react";
import { Tooltip, Typography } from "antd";
import { TitleProps } from "antd/es/typography/Title";

import lessVariables from "theme/global";
import { useContainerShape } from "utils/useContainerShape";

type EllipsisProps = {
  children: ReactNode;
  limit: number;
  position: "mid" | "after";
};

const Ellipsis: FC<EllipsisProps> = ({ children, limit, position }) => {
  if (typeof children !== "string" || children.length < limit) {
    return <>{children}</>;
  }
  const text = position === "mid" ? `${children.slice(0, limit / 2 - 1)}…${children.slice(-limit / 2)}` : `${children.slice(0, limit - 1)}…`;
  return <Tooltip title={children}>{text}</Tooltip>;
};

type EllipsisTableProps = {
  value: any;
  row: { level: number };
};

const EllipsisTable = ({ value, row }: EllipsisTableProps) => {
  const limit = (lessVariables["table-second-column-width"] - ((row.level ?? 0) + 1) * lessVariables["table-child-padding-addition"]) / 9;
  return (
    <Ellipsis limit={limit} position="mid">
      {value}
    </Ellipsis>
  );
};

type EllipsisTitleProps = {
  children: ReactNode;
  className?: string;
  factor?: number;
  position?: EllipsisProps["position"];
  level?: TitleProps["level"];
};

const EllipsisTitle: FC<EllipsisTitleProps> = ({ children, className, factor = 13, position = "after", level }) => {
  const ref = useRef(null);
  const shape = useContainerShape(ref);
  if (typeof children !== "string") {
    return <Typography.Title className={className}>{children}</Typography.Title>;
  }
  let limit = children.length;
  if (isFinite(shape.width)) {
    limit = shape.width / factor;
  }
  return (
    <Typography.Title className={className} level={level} ref={ref}>
      <Ellipsis position={position} limit={limit}>
        {children}
      </Ellipsis>
    </Typography.Title>
  );
};

export { Ellipsis, type EllipsisProps, EllipsisTable, EllipsisTitle };
