import React, { FC } from "react";
import { useChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import cn from "./cornerLabels.module.less";

type CornerLabelsType = {
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
};

const CornerLabels: FC<CornerLabelsType> = observer(({ topLeft, topRight, bottomLeft, bottomRight }) => {
  const model = useChartContext();
  if (model.isNotReady || !model.isShapeReady) {
    return null;
  }
  return (
    <>
      <text className={cn.label} x={model.marginLeft + 20} y={model.marginTop + 30}>
        {topLeft}
      </text>
      <text className={cn.label} x={model.width + model.marginLeft - 20} y={model.marginTop + 30} style={{ textAnchor: "end" }}>
        {topRight}
      </text>
      <text className={cn.label} x={model.marginLeft + 20} y={model.height - model.marginTop - 30}>
        {bottomLeft}
      </text>
      <text className={cn.label} x={model.width + model.marginLeft - 20} y={model.height - model.marginTop - 30} style={{ textAnchor: "end" }}>
        {bottomRight}
      </text>
    </>
  );
});

export { CornerLabels };
