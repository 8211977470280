import { FC, ReactNode } from "react";
import { ChartContext } from "@okopok/axes_context";
import { Lines } from "@okopok/axes_context";
import { Empty, Typography } from "antd";
import { observer } from "mobx-react";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import StackedBars from "elements/charts/stackedBars/stackedBars";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { useInteractiveRef } from "utils/useInteractiveRef";

import { useLinesBarsComposition } from "../../../plot/useLinesBarsComposition";

import { WellInterventionsAmountChartModel } from "./wellInterventionsAmountChartModel";

import cn from "./wellInterventionsAmountChart.module.less";

type Padding = {
  axisKey: string;
  top?: number;
  bottom?: number;
};

type WellInterventionsAmountChartProps = {
  chartModel: WellInterventionsAmountChartModel;
};

const PADDINGS: Padding[] = [
  { axisKey: "bars", top: 0.5 },
  { axisKey: "line", bottom: 0.6, top: 0.1 },
];

const WellInterventionsAmountChart: FC<WellInterventionsAmountChartProps> = observer(({ chartModel }) => {
  const legendRef = useInteractiveRef<HTMLDivElement>();

  const TITLES = new Map<string, ReactNode>(
    Object.entries({
      bars: "Количество ГТМ, шт",
      line: "Дополнительная добыча от ГТМ, тыс т",
    })
  );

  const YEARS_ONLY = new Set(["x"]);
  const RIGHT = new Set(["line"]);
  const axes = useLinesBarsComposition(chartModel.lines, chartModel.bars, PADDINGS, TITLES, RIGHT, YEARS_ONLY);
  return (
    <div className={cn.layout}>
      {chartModel.isValid && (
        <>
          <div className={cn.title}>
            {chartModel.bars!.bars.map((bar, index) => (
              <div key={index} className={cn.legendItem}>
                {bar.color !== null && <div className={cn.circle} style={{ backgroundColor: bar.color }} />}
                <div className={cn.value}>{bar.title}</div>
              </div>
            ))}
          </div>
          <ChartContext axes={axes} className={cn.chart} legendRef={legendRef.current}>
            <TooltipContext.Provider value={chartModel.tooltipManager}>
              <Lines data={chartModel.lines} />
              <StackedBars bars={chartModel.bars!} />
              <PointerLine />
            </TooltipContext.Provider>
          </ChartContext>
          <TooltipDiv manager={chartModel.tooltipManager!} />
        </>
      )}
      {!chartModel.isValid && (
        <div className={cn.empty}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Typography.Text>Таблица заполнена не полностью</Typography.Text>} />
        </div>
      )}
    </div>
  );
});

export { WellInterventionsAmountChart };
