import { observer } from "mobx-react-lite";

import { useLegendContext } from "elements/charts/lineChart/LegendModel";

import LegendItem from "./LegendItem";

import cn from "../card.module.less";

const Legend = observer(() => {
  const legend = useLegendContext();
  return (
    <div className={cn.legend}>
      {legend?.legend?.map((item, index) => (
        <LegendItem key={index} item={item} legend={legend}></LegendItem>
      ))}
    </div>
  );
});

export default Legend;
