import React, { useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { DRow as InjectionDRow } from "features/injPrediction/resultsTableView/injectionSliceModel";

import { DRow, InjectionTableModel } from "./injectionTableModel";

import cn from "./injectionTable.module.less";

const COLUMNS: ColumnRaw<DRow>[] = [
  {
    dataKey: "injMineTitle",
    title: "№ скв.",
    width: { min: 10, max: 80, competitiveness: 1 },
    onHeaderCell: () => ({ className: cn.headerCell }),
  },
  {
    dataKey: "oilCoeff",
    title: "Коэф. закачки",
    width: { min: 10, max: 70, competitiveness: 1 },
    onHeaderCell: () => ({ className: cn.headerCell }),
    render: (value) => <Format>{value}</Format>,
  },
  {
    dataKey: "injCapacity",
    title: "Приемистость (м³/сут)",
    width: { min: 10, max: 300, competitiveness: 1 },
    onHeaderCell: () => ({ className: cn.headerCell }),
    render: (value) => value && <Format>{value}</Format>,
  },
];

const InjectionTable = observer(({ wellPads }: { wellPads?: InjectionDRow[] }) => {
  const store = useMemo(() => new InjectionTableModel(wellPads), [wellPads]);
  const model = useMemo(
    () =>
      new TableModel(
        COLUMNS,
        store,
        {},
        {
          headerHeight: 39,
          rowHeight: 25,
          borderColor: "#f0f0f0",
        }
      ),
    [store]
  );
  return (
    <TableContextProvider value={model}>
      <Widget headerClassName={cn.tableHeader} className={cn.table} />
    </TableContextProvider>
  );
});

export { InjectionTable };
