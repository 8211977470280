import { useMemo, useState } from "react";
import { Button, Radio, RadioChangeEvent, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Icon } from "elements/icon/icon";
import { Select } from "elements/inputs/select";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import { CalculateButton } from "../calculateButton/calculateButton";
import { useInfrastructure } from "../useInfrastructure";

import { InfrastructureParamsTable } from "./infrastructureParamsTable/infrastructureParamsTable";
import { useParamsTableByMode } from "./infrastructureParamsTable/store/useParamsTableByMode";
import { InfrastructureResultsTable } from "./infrastructureResultsTable/infrastructureResultsTable";
import { PipesModel } from "./infrastructureResultsTable/models/pipes";
import { useResultsTableByMode } from "./infrastructureResultsTable/store/useResultsTableByMode";
import { ReactComponent as WarningIcon } from "./warning.svg";

import cn from "./infrastructureTable.module.less";

const usePipeTypeOptions = (regime: "params" | "results") =>
  useMemo(
    () => [
      { label: "Нефтепровод", value: "prod" },
      { label: "Водовод", value: "inj" },
      { label: "Кустовые площадки", value: "mines" },
      { label: "НС/УПН/УПСВ/УКПГ", value: "stations", disabled: regime === "results" },
      { label: "Стоки", value: "drain" },
      { label: "Источники", value: "source" },
    ],
    [regime]
  );

const regimeOptions = [
  { label: "Параметры", value: "params" },
  { label: "Результаты", value: "results" },
];

const InfrastructureTable = observer(() => {
  const [mode, setMode] = useState("prod");
  const [regime, setRegime] = useState<"params" | "results">("params");
  const pipeTypeOptions = usePipeTypeOptions(regime);

  const { store: paramsStore, columns: paramColumns } = useParamsTableByMode(mode);
  const { store: resultsStore, columns: resultColumns } = useResultsTableByMode(mode);

  const forecast = useForecast();
  const infrastructure = useInfrastructure();

  const project = useProject()!;
  const edit = getUserPermission(project, forecast)["infrastructure"];

  const handleSelect = (value: string) => setMode(value);
  const handleRegime = (event: RadioChangeEvent) => setRegime(event?.target.value);

  return (
    <>
      <PageFrameTitlePortal model={undefined} onSave={edit.value && regime === "params" ? infrastructure.save : undefined}>
        <div className={cn.actionButtons}>
          {resultsStore instanceof PipesModel && resultsStore.allPipes.length > 0 && regime === "results" && (
            <Tooltip title="Показывать только проблемные результаты расчета">
              <Button
                className={classNames(cn.warning, resultsStore.hasProblemDataFilter && cn.warningProblem)}
                onClick={resultsStore.toggleProblemPipesFilter}
                icon={<Icon width="20px" height="20px" viewBox="0 0 20 20" content={<WarningIcon />} />}
              />
            </Tooltip>
          )}
          <Select value={mode} options={pipeTypeOptions} onChange={handleSelect} />
          <Radio.Group value={regime} options={regimeOptions} buttonStyle="solid" optionType="button" onChange={handleRegime} className={cn.regime} />
          {forecast && regime === "results" && <CalculateButton fullWidth />}
        </div>
      </PageFrameTitlePortal>
      {regime === "params" ? (
        <InfrastructureParamsTable mode={mode} store={paramsStore} columns={paramColumns} />
      ) : (
        <InfrastructureResultsTable mode={mode} store={resultsStore} columns={resultColumns} />
      )}
    </>
  );
});

export { InfrastructureTable };
