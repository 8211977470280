import { action, computed, makeAutoObservable, makeObservable, observable, runInAction, when } from "mobx";

import { BackendStorageMock } from "services/back/backendStorage";
import { getDecreaseCoefficients } from "services/back/decreaseCoefficients";
import { Range } from "utils/range";

import { Forecast } from "../forecast";

type DecreaseCoefficientDump = {
  data: {
    title: string;
    key: string;
    data: [number, number | null][] | null;
  }[];
};

const decreaseCoefficientsSource = (key: { fact: number; forecast?: number }) => {
  return new BackendStorageMock<void, DecreaseCoefficientDump>(
    `DecreaseCoefficientSource_${key.fact}_${key.forecast}`,
    key.fact,
    key.forecast
  );
};

class DecreaseCoefficient {
  title: string;
  key: string;
  data: Map<number, number | null> | null;
  constructor(key: string, title: string, data: Map<number, number | null> | null) {
    makeObservable(this, {
      title: observable,
      key: observable,
      data: observable,
      setData: action,
      dump: computed,
    });
    this.key = key;
    this.title = title;
    this.data = data;
  }

  public setData = (year: number, value: number | null) => {
    this.data?.set(year, value);
  };

  get dump() {
    return {
      title: this.title,
      key: this.key,
      data: this.data ? Array.from(this.data.entries()) : null,
    };
  }
}
class DecreaseCoefficients {
  coefficients: DecreaseCoefficient[] = [];
  source: BackendStorageMock<void, DecreaseCoefficientDump, DecreaseCoefficientDump> | undefined;
  isLoading: boolean = true;
  range: Range | undefined;

  constructor(private forecast: Forecast) {
    makeAutoObservable<DecreaseCoefficients, "init">(this, {
      range: observable,
      coefficients: observable,
      init: action,
      createTemplate: action,
      save: action,
    });
    this.range = new Range(this.forecast.range.from + 1, this.forecast.range.to);

    when(
      () => forecast.production.isLoading === false && forecast.fact.production.isLoading === false,
      () => this.init()
    );
  }

  private init = async () => {
    this.source = decreaseCoefficientsSource(this.forecast.storageKey);
    await this.createTemplate(this.range ?? new Range(this.forecast.range.from + 1, this.forecast.range.to));

    await this.source.getItem().then((results) => {
      runInAction(() => {
        if (results && results.data.length > 0) {
          try {
            this.coefficients.forEach((coeff) => {
              const resultData = new Map(results.data.find((param) => param.key === coeff.key)?.data);
              if (resultData) {
                Array.from(coeff.data?.keys() ?? []).forEach((year) => {
                  coeff.data?.set(year, resultData.get(year) ?? null);
                });
              }
            });
          } catch {
            return;
          }
        } else {
          return;
        }
      });
    });
    this.isLoading = false;
  };

  public setData = (parentKey: string, paramKey: string, year: number, value: number | null) => {
    this.coefficients.find((coefficient) => coefficient.key === paramKey)?.setData(year, value);
  };

  public clear = async () => {
    await this.createTemplate(this.range ?? new Range(this.forecast.range.from + 1, this.forecast.range.to));
    await this.save();
  };

  public async save() {
    if (this.source) {
      this.source.setItem({
        data: this.coefficients.map((coefficient) => coefficient.dump),
      });
    }
  }

  public async createTemplate(range: Range) {
    await getDecreaseCoefficients().then((results) => {
      this.coefficients = [];
      this.coefficients = results.map(
        (coefficient) =>
          new DecreaseCoefficient(
            coefficient.key,
            coefficient.title,
            new Map(new Array(this.forecast.range!.length).fill(null).map((_, index) => [range.from + index, null]))
          )
      );
    });
  }
}
export { DecreaseCoefficient, DecreaseCoefficients };
