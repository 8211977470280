import { useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { observer } from "mobx-react-lite";
import { ResultValidator, useResultValidatorManager } from "routing/outlines/result/resultInformer";

import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import type { ResultsTable } from "models/project/fact/forecast/forecastResult";

import { FinancialResultStore } from "./financialResultStore";
import { getEconomicParamsValidator, getGtmTechValidator, getWellsBaseTechValidator, getWellsForecastTechValidator } from "./resultValidators";

import cn from "./results.module.less";

const Results = observer(({ model, exportFileName }: { exportFileName: string; model: ResultsTable }) => {
  const store = useMemo(() => new FinancialResultStore(model), [model]);
  const forecast = useForecast();

  useResultValidatorManager(
    new ResultValidator([
      getEconomicParamsValidator(forecast),
      getWellsBaseTechValidator(forecast),
      getWellsForecastTechValidator(forecast),
      getGtmTechValidator(forecast),
    ])
  );

  const columns = useMemo<Column[]>(
    () =>
      model
        ? [
            {
              title: "Параметр",
              dataKey: "title",
              type: "string",
              isSticky: true,
              width: 380,
            },
            {
              title: "Ед. измерения",
              dataKey: "measure",
              type: "string",
              isSticky: true,
              width: 130,
            },
            ...[...(model?.years ?? [])].map(
              (year, id): Column => ({
                title: `${year}`,
                dataKey: `${year}`,
                type: "number",
              })
            ),
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model, model.years]
  );

  return (
    <div className={cn.generic}>
      <SimpleTableContext
        data={store}
        columns={columns}
        exportFileName={exportFileName}
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
        theme={{
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }}
      >
        <div className={cn.tableWrap}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      </SimpleTableContext>
    </div>
  );
});

export { Results };
