import { createContext, ReactNode, useContext, useLayoutEffect, useMemo, useState } from "react";
import { CheckSquareOutlined, HighlightOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { makeAutoObservable, reaction } from "mobx";
import { observer } from "mobx-react-lite";

import { DebugZone } from "elements/debugZone/debugZone";
import { global } from "models/global";

import cn from "./resiltInformer.module.less";

type ValidationMessageType = "error" | "warning" | "debug";

type ValidatorResultInfo =
  | {
      hasMessages: boolean;
      level: number | undefined;
      levelType: ValidationMessageType;
    }
  | undefined;

const validationMessageTypeLevel: Record<ValidationMessageType, number> = {
  error: 10,
  warning: 5,
  debug: 0,
};

const validationMessageTypeLevelOrdered = Object.entries(validationMessageTypeLevel)
  .map(([type, level]) => ({ type: type as ValidationMessageType, level }))
  .sort((e) => e.level);

const getValidationMessageTypeByLevel: (value: number | undefined) => ValidationMessageType = (value) => {
  if (!value) {
    return "debug";
  }

  let currentType: ValidationMessageType = validationMessageTypeLevelOrdered[0].type;
  for (const { type, level } of validationMessageTypeLevelOrdered) {
    if (value <= level) {
      currentType = type;
    } else {
      return currentType;
    }
  }
  return currentType;
};

const typeIcons: Record<ValidationMessageType, ReactNode> = {
  error: <WarningOutlined style={{ color: "red" }} />,
  warning: <WarningOutlined style={{ color: "orange" }} />,
  debug: <HighlightOutlined />,
};

const typeTitles: Record<ValidationMessageType, string> = {
  error: "Ошибка",
  warning: "Предупреждение",
  debug: "Debug-сообщение",
};

class ResultValidationMessage {
  public level: number;
  constructor(public type: ValidationMessageType, public text: string, public keys: string[] = []) {
    this.level = validationMessageTypeLevel[type];
  }
}

class ResultValidationChecker {
  constructor(public check: () => ResultValidationMessage[]) {}
}

class ResultValidator {
  private messages: ResultValidationMessage[] | undefined = undefined;
  constructor(private readonly toCheck: ResultValidationChecker[]) {
    makeAutoObservable(this);
  }

  public validate() {
    const result: ResultValidationMessage[] = [];
    for (const v of this.toCheck) {
      const vCheckResult = v.check();
      for (const r of vCheckResult) {
        result.push(r);
      }
    }
    this.messages = result;
  }

  public get displayedMessages() {
    if (global.IS_DEBUG_ZONE) {
      return this.messages;
    } else {
      return this.messages?.filter((m) => m.type !== "debug");
    }
  }

  private getMessagesMaxLevel(messages: ResultValidationMessage[]) {
    return messages.map((m) => m.level).reduce((a, b) => Math.max(a, b), -Infinity);
  }

  private generateValidationResultInfo(messages: ResultValidationMessage[]) {
    const level = messages.length > 0 ? this.getMessagesMaxLevel(messages) : undefined;
    return {
      level,
      levelType: getValidationMessageTypeByLevel(level),
      hasMessages: messages.length > 0,
    };
  }

  public get result(): ValidatorResultInfo {
    if (this.displayedMessages === undefined) {
      return undefined;
    }
    return this.generateValidationResultInfo(this.displayedMessages);
  }

  public resultByKeys(keys: string[]): ValidatorResultInfo {
    if (this.displayedMessages === undefined) {
      return undefined;
    }
    const filteredMessages = this.displayedMessages.filter((m) => m.keys.filter((value) => keys.includes(value)).length > 0);
    return this.generateValidationResultInfo(filteredMessages);
  }
}

class ResultValidatorManager {
  public validator: ResultValidator | null = null;
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.validator,
      () => {
        if (this.validator) {
          this.validator.validate();
        }
      }
    );
  }

  public useValidator(validator: ResultValidator) {
    this.validator = validator;
  }

  public resetValidator() {
    this.validator = null;
  }

  public get validationResult(): ValidatorResultInfo {
    if (!this.validator) {
      return undefined;
    }
    return this.validator.result;
  }

  public validationResultByKeys(keys: string[]): ValidatorResultInfo {
    if (!this.validator) {
      return undefined;
    }
    return this.validator.resultByKeys(keys);
  }
}

const ValidatorManagerContext = createContext<ResultValidatorManager | null>(null);

const useResultValidatorManager = (validator?: ResultValidator) => {
  const validatorManager = useContext(ValidatorManagerContext);
  console.assert(validatorManager != null, "Запрошен validatorManager вне контекста ValidatorManagerContext");
  useLayoutEffect(() => {
    if (validator) {
      validatorManager?.useValidator(validator);
      return () => {
        validatorManager?.resetValidator();
      };
    }
  });
  return validatorManager;
};

const ValidatorManagerContextProvider = observer(({ children }: { children: ReactNode }) => {
  const validatorManager = useMemo(() => {
    return new ResultValidatorManager();
  }, []);

  return <ValidatorManagerContext.Provider value={validatorManager}>{children}</ValidatorManagerContext.Provider>;
});

const Message = ({ message }: { message: ResultValidationMessage }) => {
  const text = message.text.split("\n").map((par, j) => <div key={j}>{par}</div>);

  return (
    <div className={cn["message"]}>
      <div className={cn["message-header"]}>
        <div>{typeIcons[message.type]}</div>
        <div>{typeTitles[message.type]}</div>
      </div>
      <div className={cn["message-content"]}>{message.type === "debug" ? <DebugZone>{text}</DebugZone> : text}</div>
    </div>
  );
};

const MessageDisplay = observer(() => {
  const validatorManager = useResultValidatorManager();

  if (!validatorManager || !validatorManager.validator || !validatorManager.validator.displayedMessages) {
    return null;
  }

  return (
    <div className={cn["popover-content"]}>
      <div className={cn["message-wrapper"]}>
        {validatorManager.validator.displayedMessages.map((message, messageIndex) => {
          return <Message key={messageIndex} message={message} />;
        })}
      </div>
    </div>
  );
});

const ResultInformer = observer(() => {
  const [isOpenedDetails, setOpenedDetails] = useState<boolean>(false);
  const validatorManager = useResultValidatorManager();

  const switchOpenDetails = () => {
    setOpenedDetails((v) => !v);
  };

  if (!validatorManager) {
    return null;
  }

  const hasWarning = validatorManager.validationResult?.hasMessages;

  if (hasWarning === undefined) {
    return null;
  }

  return hasWarning ? (
    <Popover
      trigger="click"
      content={<MessageDisplay />}
      placement="bottom"
      title={"Список ошибок и предупрежений"}
      open={isOpenedDetails}
      onOpenChange={switchOpenDetails}
    >
      <Button icon={typeIcons[getValidationMessageTypeByLevel(validatorManager.validationResult.level)]} />
    </Popover>
  ) : (
    <Button disabled icon={<CheckSquareOutlined style={{ color: "green" }} />}></Button>
  );
});

export {
  MessageDisplay,
  ResultInformer,
  ResultValidationChecker,
  ResultValidationMessage,
  ResultValidator,
  useResultValidatorManager,
  type ValidationMessageType,
  validationMessageTypeLevel,
  ValidatorManagerContextProvider,
};
