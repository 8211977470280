import { ElementType, useEffect, useState } from "react";
import classNames from "classnames";

import { global } from "models/global";

import cn from "./debugZone.module.less";

const DebugZone: React.FC<React.PropsWithChildren<{ tag?: ElementType }>> = ({ children, tag: Tag = "div" }) => {
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      setIsCtrlPressed(event.ctrlKey || event.metaKey);
    };

    window.addEventListener("keydown", handleKey);
    window.addEventListener("keyup", handleKey);

    return () => {
      window.removeEventListener("keydown", handleKey);
      window.removeEventListener("keyup", handleKey);
    };
  }, []);

  if (!global.IS_DEBUG_ZONE) {
    return <></>;
  }

  return <Tag className={classNames(cn.wrap, Tag === "div" && cn.inline, isCtrlPressed && cn.bgHidden)}>{children}</Tag>;
};

export { DebugZone };
