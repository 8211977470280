import { FC, useState } from "react";
import classNames from "classnames";

import { Attention } from "elements/icons/attention";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";

import cn from "./toggleIntersectedButton.module.less";

type ToggleIntersectedButtonProps = {
  hasIntersected: boolean;
  onlyIntersected: () => void;
  reset: () => void;
};

const ToggleIntersectedButton: FC<ToggleIntersectedButtonProps> = ({ hasIntersected, onlyIntersected, reset }) => {
  const [active, setActive] = useState(false);

  const onClick = () => {
    !active ? onlyIntersected() : reset();
    setActive(!active);
  };

  if (!hasIntersected) {
    return null;
  }

  return (
    <ToolbarButton
      tooltip={{ title: active ? "Показать все периоды" : "Показывать только пересекающиея периоды нагнетания и добычи" }}
      icon={<Attention />}
      type="text"
      className={classNames(active && cn.active)}
      danger
      onClick={onClick}
    />
  );
};

export { ToggleIntersectedButton };
