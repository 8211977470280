import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { reqCamel } from "utils/request";

type RCPInputBack = {
  years: number[];
  gtms: RCPInputGTM[];
  mines: RCPInputMine[];
  wells: RCPInputWell[];
  crews: RCPInputCrew[];
  investment_limits: RCPInputLimit<"ЭБ" | "ГТМ">[];
  crew_limits: RCPInputLimit<"ЭБ" | "КРС" | "ЗБС">[];
};

type RCPInputWell = {
  id: number;
  well_type: string;
  length: number;
  prod_drilling_price?: number;
  is_factual?: boolean;
};

type RCPInputGTM = {
  id: number;
  well_id: number;
  priority: number;
  gtm_group: "КРС" | "ЗБС";
  gtm_type: string;
  gtm_price: number;
};

type RCPInputMine = {
  id: number;
  priority: number;
  gtm_group: "ЭБ";
  well_cluster: {
    id_list_of_wells: number[];
  };
};

type RCPInputCrew = CrewEB | CrewZBS | CrewKRS;

type CrewEB = {
  id: number;
  gtm_group: "ЭБ";

  nns_production: number;
  gs_production: number;
  ms_production: number;
};

type CrewZBS = {
  id: number;
  gtm_group: "ЗБС";

  zbs_production: number;
};

type CrewKRS = {
  id: number;
  gtm_group: "КРС";

  transfer_production: number;
  rad_drilling_production: number;
  deepening_production: number;
};

type RCPInputLimit<GTMGroup> = {
  year: number;
  val: number;
  gtm_group: GTMGroup;
};

type RCPInput = Omit<ObjectToCamel<RCPInputBack>, "crews"> & {
  crews: Array<ObjectToCamel<CrewEB> | ObjectToCamel<CrewZBS> | ObjectToCamel<CrewKRS>>;
};

type GTMWork = {
  gtm: null | {
    id: number;
    priority: number;
    gtmGroup: "КРС" | "ЗБС";
    gtmType: string;
    wellId: number;
    gtmPrice: number;
  };
  mine: null | {
    id: number;
    priority: number;
    gtmGroup: "ЭБ";
    wellCluster: {
      idListOfWells: number[];
    };
  };
  well: {
    id: number;
    wellType: string;
    length: number;
    prodDrillingPrice: number;
    isFactual: boolean;
  };
  crew: {
    id: number;
    gtmGroup: "ЭБ" | "КРС" | "ЗБС";
  };
  period: {
    start: string; // date
    end: string; // date
  };
};

type RCPOutput = Array<{ year: number; gtmWorks: GTMWork[] }>;

async function calculate(scenarioId: number, input: RCPInput): Promise<RCPOutput> {
  const response = await reqCamel.post<{ rcp: RCPOutput }>(`calculation/rcp/${scenarioId}`, input);

  return response.rcp;
}

export { calculate };
export type { GTMWork, RCPInput, RCPOutput };
