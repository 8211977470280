import { FC, useState } from "react";
import { MenuByItSelf } from "routing/outlines/secondaryMenu/menuByItSelf";

import { Icon } from "elements/icon/icon";

import { ReactComponent as StarIcon } from "./star.svg";

import cn from "./versionFem.module.less";

const items = [
  {
    key: "1",
    label: "Корпоративные версии",
    children: [
      {
        key: "2",
        label: (
          <div className={cn.menuItem}>
            <div>13.0</div>
            <div className={cn.menuItemDate}>12.12.2023</div>
            <Icon width="12" height="12" viewBox="0 0 12 12" content={<StarIcon />} />
          </div>
        ),
      },
    ],
  },
];

const VersionFemMenu: FC = () => {
  const [search, setSearch] = useState<string | undefined>();

  const handleNavigate = () => {};
  const onAdd = () => {};
  return (
    <div className={cn.menuWrapper}>
      <MenuByItSelf
        title="Версии алгоритма расчета ФЭМ"
        flex={1}
        alwaysOpen
        items={items}
        onNavigate={handleNavigate}
        onAdd={onAdd}
        selectedKey="fem"
        search={search}
        onSearchChange={setSearch}
      />
    </div>
  );
};

export { VersionFemMenu };
