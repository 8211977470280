import { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { action, computed, makeObservable, observable, transaction } from "mobx";

import { FilterDetails } from "features/techPrediction/filters/types";
import { Fact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";

import { getFilterDetails } from "./columnFilters";
import { columnRenderers } from "./columnRenderers";
import { getData, getMeta, saveData, saveMeta } from "./service";

type CustomColumnType = "string" | "number" | "date";

type CustomColumnValue<T extends CustomColumnType> = {
  string: string;
  number: number;
  date: string;
}[T];

type ColumnKey = string;
type RowKey = string;

type CustomData = Record<ColumnKey, Record<RowKey, CustomColumnValue<any>>>;

class CustomColumns {
  #meta = observable.map<ColumnKey, CustomColumnType>();
  #data = observable.map<ColumnKey, Record<RowKey, CustomColumnValue<any>>>();

  public isLoadingMeta: boolean = true;
  public isLoading: boolean = true;

  public isUpdated: boolean = false;

  constructor(
    private fact: Fact,
    private forecast: Forecast | null,
    private tableKey: string,
    private filterMap: Record<string, FilterDetails<any, any, any>>
  ) {
    makeObservable<CustomColumns, "init" | "update">(this, {
      isUpdated: observable,
      isLoadingMeta: observable,
      isLoading: observable,
      init: action,
      save: action,
      update: action,
      meta: computed,
    });

    this.init();
  }

  public get(title: string, rowKey: string): any | null | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return this.#data.get(title)?.[rowKey] ?? null;
  }

  public getAll(rowKey: RowKey): Record<string, any> | null | undefined {
    if (this.isLoading) {
      return undefined;
    }
    const result: Record<string, any> = {};
    for (const title of this.#meta.keys()) {
      result[CustomColumns.dataKey(title)] = this.#data.get(title)?.[rowKey] ?? null;
    }
    return result;
  }

  public get meta(): Array<[ColumnKey, CustomColumnType]> {
    return Array.from(this.#meta);
  }

  public get initialColumns(): Array<ColumnRaw<any>> {
    return this.meta.map(([title, type]) => this.getColumnRaw(title, type));
  }

  private get storageKey() {
    return (this.forecast ?? this.fact).storageKey;
  }

  private update = (rowKey: RowKey, columnTitle: string, newValue: any) => {
    const columnValues = this.#data.get(columnTitle);
    if (!columnValues) {
      return;
    }
    columnValues[rowKey] = newValue;
    this.isUpdated = true;
  };

  private getColumnRaw(title: string, type: CustomColumnType): ColumnRaw<any> {
    return {
      ...columnRenderers(title, type, this.update),
      dataKey: CustomColumns.dataKey(title),
      title,
      width: { min: 150, max: 300, competitiveness: 1 },
    };
  }

  public hasColumn(title: string): boolean {
    return this.#meta.has(title);
  }

  public addColumn(title: string, type: CustomColumnType): ColumnRaw<any> {
    this.#meta.set(title, type);
    this.#data.set(title, {});
    this.filterMap[CustomColumns.dataKey(title)] = getFilterDetails(title, type);
    this.isUpdated = true;
    return this.getColumnRaw(title, type);
  }

  public changeColumnType(): Pick<ColumnRaw<any>, "render" | "renderToString"> {
    // local register + filter
    return {};
  }

  public removeColumn(title: string): string {
    this.#meta.delete(title);
    this.#data.delete(title);
    delete this.filterMap[CustomColumns.dataKey(title)];
    this.isUpdated = true;
    return CustomColumns.dataKey(title);
  }

  private init() {
    this.initMeta();
    this.initData();
  }

  public async save() {
    await Promise.all([
      saveMeta(
        this.storageKey,
        this.tableKey,
        Array.from(this.#meta.entries(), ([columnKey, columnType]) => ({ columnKey, columnType }))
      ),
      saveData(this.storageKey, this.tableKey, Object.fromEntries(this.#data)),
    ]);
    this.isUpdated = false;
  }

  private async initMeta() {
    this.isLoadingMeta = true;
    const meta = await getMeta(this.storageKey, this.tableKey);
    transaction(() => {
      if (meta) {
        this.#meta.replace(meta.map((c) => [c.columnKey, c.columnType]));
        for (const { columnKey, columnType } of meta) {
          this.filterMap[CustomColumns.dataKey(columnKey)] = getFilterDetails(columnKey, columnType);
        }
      }
    });
    this.isLoadingMeta = false;
  }

  private async initData() {
    this.isLoading = true;
    const data = await getData<CustomData>(this.storageKey, this.tableKey);
    data && this.#data.replace(data);
    this.isLoading = false;
  }

  static dataKey(title: string): string {
    return `custom-${title}`;
  }
}

export type { ColumnKey, CustomColumnType, CustomColumnValue, RowKey };
export { CustomColumns };
