import { TableColumnOrder } from "@okopok/components/Table/widgets/TableColumnOrder/TableColumnOrder";

import { Header } from "./header";
import { Row } from "./row";

const ColumnsSettingsWidget = () => {
  return (
    <TableColumnOrder
      components={{
        Header,
        Row,
      }}
    />
  );
};

export { ColumnsSettingsWidget };
