import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";

import { zip } from "utils/itertools";
import { Range } from "utils/range";

import { Forecast } from "../../forecast";

import { KRS_GTM_TYPES, WELL_TYPES, ZBS_GTM_TYPE } from "./constants";

type DRow = {
  param: string;
  measure: string;
  [year: number]: number;
};

class SummarizeChildren extends TableNode<DRow, SummarizeChildren | DummyRow> {
  asDRow = (): DRow => ({
    param: this.title,
    measure: this.measure,
    ...Object.fromEntries(zip([...this.yearsRange], this.yearValues)),
  });

  public get yearValues(): number[] {
    const result = this.yearsRange.array.fill(0);
    for (const child of this.children ?? []) {
      child.yearValues.forEach((val, i) => {
        result[i] += val;
      });
    }
    return result;
  }

  constructor(
    parent: TableNode<DRow, SummarizeChildren>,
    public readonly title: string,
    public readonly measure: string,
    public readonly yearsRange: Range
  ) {
    super(parent, { isExpandedChildren: true });
  }
}

class DummyRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    param: this.title,
    measure: this.measure,
    ...Object.fromEntries(zip([...this.yearsRange], this.yearValues)),
  });

  constructor(
    parent: TableNode<DRow, DummyRow | SummarizeChildren>,
    public readonly title: string,
    public readonly measure: string,
    public readonly yearsRange: Range,
    public readonly yearValues: number[]
  ) {
    super(parent);
    this.childrenStore = null;
  }
}

class GTMCount extends SummarizeChildren {
  ZBS: DummyRow;
  GTMKRSCount: GTMKRSCount;
  constructor(public parent: Summary) {
    super(parent, "Количество ГТМ", "шт", parent.yearsRange);

    this.ZBS = new DummyRow(this, "ЗБС", "шт", this.yearsRange, this.data.count.gtm[ZBS_GTM_TYPE]);
    this.GTMKRSCount = new GTMKRSCount(this);

    this.childrenStore = new ChildrenStoreArray(this, [this.ZBS, this.GTMKRSCount]);
  }

  public get data() {
    return this.parent.aggregatedData;
  }
}

class GTMKRSCount extends SummarizeChildren {
  constructor(parent: GTMCount) {
    super(parent, "КРС", "шт", parent.yearsRange);
    this.childrenStore = new ChildrenStoreArray(
      this,
      KRS_GTM_TYPES.map((gtmType) => new DummyRow(this, gtmType, "шт", this.yearsRange, parent.data.count.gtm[gtmType]))
    );
  }
}

class EBCount extends SummarizeChildren {
  constructor(parent: Summary) {
    super(parent, "Эксплуатационное бурение", "скв", parent.yearsRange);
    this.childrenStore = new ChildrenStoreArray(
      this,
      WELL_TYPES.map(
        (wellType) => new DummyRow(this, wellType, "скв", this.yearsRange, parent.aggregatedData.count.eb[wellType])
      )
    );
  }
}

class Length extends SummarizeChildren {
  constructor(parent: Summary) {
    super(parent, "Проходка", "тыс м", parent.yearsRange);
    this.childrenStore = new ChildrenStoreArray(
      this,
      WELL_TYPES.map(
        (wellType) =>
          new DummyRow(
            this,
            wellType,
            "тыс м",
            this.yearsRange,
            parent.aggregatedData.length[wellType].map((v) => v / 1000)
          )
      )
    );
  }
}

class CrewsCount extends SummarizeChildren {
  zbs: DummyRow;
  krs: DummyRow;
  eb: DummyRow;

  constructor(parent: Summary) {
    super(parent, "Количество задействованных бригад", "шт", parent.yearsRange);

    this.zbs = new DummyRow(this, "ЗБС", "шт", this.yearsRange, parent.aggregatedData.count.crews["ЗБС"]);
    this.krs = new DummyRow(this, "КРС", "шт", this.yearsRange, parent.aggregatedData.count.crews["КРС"]);
    this.eb = new DummyRow(this, "ЭБ", "шт", this.yearsRange, parent.aggregatedData.count.crews["ЭБ"]);

    this.childrenStore = new ChildrenStoreArray(this, [this.zbs, this.krs, this.eb]);
  }
}

class CrewsLimit extends SummarizeChildren {
  zbs: DummyRow;
  krs: DummyRow;
  eb: DummyRow;
  constructor(parent: Summary) {
    super(parent, "Лимит по количеству бригад", "шт", parent.yearsRange);
    this.zbs = new DummyRow(this, "ЗБС", "шт", this.yearsRange, parent.limits.crews["ЗБС"]);
    this.krs = new DummyRow(this, "КРС", "шт", this.yearsRange, parent.limits.crews["КРС"]);
    this.eb = new DummyRow(this, "ЭБ", "шт", this.yearsRange, parent.limits.crews["ЭБ"]);
    this.childrenStore = new ChildrenStoreArray(this, [this.zbs, this.krs, this.eb]);
  }
}

class CrewsDummyCount extends SummarizeChildren {
  constructor(parent: Summary) {
    super(parent, "Количество незадействованных бригад", "шт", parent.yearsRange);
    this.childrenStore = new ChildrenStoreArray(
      this,
      (["ЗБС", "КРС", "ЭБ"] as const).map(
        (crewType) =>
          new DummyRow(
            this,
            crewType,
            "шт",
            this.yearsRange,
            [...zip(parent.limits.crews[crewType], parent.aggregatedData.count.crews[crewType])].map(
              ([limit, working]) => limit - working
            )
          )
      )
    );
  }
}

class Invests extends SummarizeChildren {
  eb: DummyRow;
  reconstruction: DummyRow;
  constructor(parent: Summary) {
    super(parent, "Инвестиционные затраты", "млн руб", parent.yearsRange);

    this.eb = new DummyRow(this, "На ЭБ", "млн руб", this.yearsRange, parent.aggregatedData.invests["eb"]);

    this.reconstruction = new DummyRow(
      this,
      "На Реконструкцию",
      "млн руб",
      this.yearsRange,
      parent.aggregatedData.invests["reconstruction"]
    );

    this.childrenStore = new ChildrenStoreArray(this, [this.eb, this.reconstruction]);
  }
}

class InvestsFree extends SummarizeChildren {
  constructor(parent: Summary) {
    super(parent, "Нераспределенные инвестиции", "млн руб", parent.yearsRange);
    this.childrenStore = new ChildrenStoreArray(
      this,
      (["eb", "reconstruction"] as const).map(
        (type) =>
          new DummyRow(
            this,
            type === "eb" ? "На ЭБ" : "На Реконструкцию",
            "млн руб",
            this.yearsRange,
            [...zip(parent.limits.invest[type], parent.aggregatedData.invests[type])].map(
              ([limit, working]) => limit - working
            )
          )
      )
    );
  }
}

class InvestsLimits extends SummarizeChildren {
  eb: DummyRow;
  reconstruction: DummyRow;
  constructor(parent: Summary) {
    super(parent, "Лимит по инвестициям", "млн руб", parent.yearsRange);
    this.eb = new DummyRow(this, "На ЭБ", "млн руб", this.yearsRange, parent.limits.invest["eb"]);
    this.reconstruction = new DummyRow(
      this,
      "На Реконструкцию",
      "млн руб",
      this.yearsRange,
      parent.limits.invest["reconstruction"]
    );
    this.childrenStore = new ChildrenStoreArray(this, [this.eb, this.reconstruction]);
  }
}

class Summary extends TableNode<DRow, SummarizeChildren> {
  public get aggregatedData() {
    return this.forecast.rankingSettings.result!.aggregatedData;
  }

  public get limits() {
    return this.forecast.rankingSettings.result!.limits;
  }

  GTMCount: GTMCount;
  EBCount: EBCount;
  Invests: Invests;
  InvestsLimits: InvestsLimits;
  CrewsCount: CrewsCount;
  CrewsLimit: CrewsLimit;

  constructor(private forecast: Forecast) {
    super();
    this.GTMCount = new GTMCount(this);
    this.EBCount = new EBCount(this);
    this.Invests = new Invests(this);
    this.InvestsLimits = new InvestsLimits(this);
    this.CrewsCount = new CrewsCount(this);
    this.CrewsLimit = new CrewsLimit(this);
    this.childrenStore = new ChildrenStoreArray(this, [
      this.GTMCount,
      this.EBCount,
      new Length(this),
      this.CrewsCount,
      new CrewsDummyCount(this),
      this.CrewsLimit,
      this.Invests,
      new InvestsFree(this),
      this.InvestsLimits,
    ]);
  }

  public get yearsRange(): Range {
    return this.forecast.range;
  }
}

export type { DRow };
export { Summary };
