import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { runInAction } from "mobx";

import { DRow as MiningDRow } from "features/injPrediction/resultsTableView/injectionSliceModel";

type DRow = {
  prodWellTitle?: string;
  oilCoeff?: number;
  liquidRate?: number;
  oilRate?: number;
};

class MiningTableModel extends TableNode<DRow, MiningNode> {
  constructor(public wellPads: MiningDRow[] | undefined) {
    super();
    runInAction(() => {
      this.childrenStore =
        this.wellPads !== undefined
          ? new ChildrenStoreArray(
              this,
              this.wellPads?.map((wellPad) => new MiningNode(this, wellPad))
            )
          : null;
    });
  }
}

class MiningNode extends TableNode<DRow> {
  asDRow(): DRow {
    return {
      prodWellTitle: this.well.prodWellTitle,
      oilCoeff: this.well.oilCoeff,
      liquidRate: this.well.liquidRate,
      oilRate: this.well.oilRate,
    };
  }
  constructor(public readonly parent: MiningTableModel, public well: MiningDRow) {
    super(parent);
    this.childrenStore = null;
  }
}

export { MiningTableModel };
export type { DRow };
