import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2422 3.61169L18.3038 14.2205C18.3215 14.2516 18.3373 14.2839 18.3509 14.317C18.4614 14.5858 18.5223 14.8685 18.5381 15.1498L18.5383 15.1536C18.6111 16.5696 17.5223 17.7747 16.1079 17.8486C16.0934 17.8494 16.0789 17.8498 16.0644 17.8498H3.99603C3.98606 17.8498 3.97613 17.8496 3.96624 17.8493C3.9406 17.8502 3.9147 17.8499 3.88858 17.8485C3.6063 17.8327 3.32672 17.7717 3.06052 17.6644L3.0575 17.6632C1.74614 17.129 1.1141 15.6336 1.64725 14.3205C1.6612 14.2861 1.67742 14.2527 1.69581 14.2205L7.75732 3.61185C7.99039 3.19263 8.3371 2.84624 8.75833 2.61437C9.99685 1.92884 11.5547 2.37616 12.2422 3.61169ZM3.96831 16.1836C3.97752 16.1833 3.98676 16.1831 3.99603 16.1831H16.0391C16.5268 16.1479 16.898 15.73 16.8739 15.2411C16.8688 15.1528 16.852 15.069 16.8253 14.9924L10.7868 4.42406C10.5462 3.98919 9.99943 3.83204 9.565 4.07282L9.56247 4.07422C9.41618 4.15463 9.29458 4.27588 9.2129 4.42369L9.20709 4.43403L3.175 14.9912C3.01903 15.4399 3.23997 15.937 3.68473 16.1191C3.77404 16.1549 3.86933 16.1767 3.96831 16.1836Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8337 8.31699C10.8337 7.86578 10.4606 7.5 10.0003 7.5C9.54009 7.5 9.16699 7.86578 9.16699 8.31699V10.8497C9.16699 11.3009 9.54009 11.6667 10.0003 11.6667C10.4606 11.6667 10.8337 11.3009 10.8337 10.8497V8.31699ZM10.0003 12.5C9.54033 12.5 9.16699 12.8785 9.16699 13.3367C9.16699 13.7948 9.54033 14.1667 10.0003 14.1667C10.4603 14.1667 10.8337 13.7948 10.8337 13.3367V13.3245C10.8337 12.8663 10.4603 12.5 10.0003 12.5Z"
      fill="currentColor"
    />
  </g>
);

const Attention: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0.5 2 18.5 16" content={ASSET} />;

export { Attention };
