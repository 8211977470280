import { useMemo } from "react";
import { TableModel } from "@okopok/components/Table";
import { makeAutoObservable, reaction } from "mobx";
import { isPersisting, makePersistable } from "mobx-persist-store";

class TableSettings {
  public columnOrder?: number[];
  public hiddenColumns?: string[];

  constructor(private tableModel: TableModel<any>, id: string) {
    this.columnOrder = undefined;
    this.hiddenColumns = undefined;

    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: `table_settings_for_${id}`,
        properties: ["columnOrder", "hiddenColumns"],
        storage: window.localStorage,
      },
      {}
    );

    reaction(
      () => this.tableModel.columnsModel.store.columnOrder.join("-"),
      () => {
        this.columnOrder = tableModel.columnsModel.store.columnOrder;
      }
    );

    reaction(
      () => this.tableModel.columnsModel.store.hiddenColumns,
      () => {
        this.hiddenColumns = tableModel.columnsModel.store.hiddenColumns;
      }
    );
    reaction(
      () => this.isPersisting,
      () => {
        if (this.columnOrder) {
          this.tableModel.columnsModel.store.setColumnsOrder(this.columnOrder);
        }
        if (this.hiddenColumns) {
          this.tableModel.columnsModel.store.setColumnsHidden(new Set(this.hiddenColumns));
        }
      }
    );
  }

  public get isPersisting() {
    return isPersisting(this);
  }
}

const useTableSettings = (tableModel: TableModel<any>, id?: string) => {
  useMemo(() => {
    if (id != null) {
      return new TableSettings(tableModel, id);
    }
    return null;
  }, [tableModel, id]);
};

export { type TableSettings, useTableSettings };
