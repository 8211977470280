const yearWordChoose = (n: number) => {
  if (n === 10 || n === 11 || n === 12 || n === 13 || n === 14) {
    return "лет";
  }
  if (n % 10 === 1) {
    return "год";
  }
  if (2 <= n % 10 && n % 10 <= 4) {
    return "года";
  }
  return "лет";
};

export { yearWordChoose };
