import { FC } from "react";
import { Select, Typography } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react";

import { MonthPicker } from "elements/inputs/monthPicker";
import { YearPicker } from "elements/inputs/yearPicker";
import { Loader } from "elements/loader";
import { Tree } from "elements/tree/tree";
import { global } from "models/global";
import { TreeContextProvider } from "models/tree/context";

import { useCreateProjectsContext } from "./createProjectProvider";

import cn from "./wellsTree.module.less";

const WellsTree: FC = observer(() => {
  const createdProject = useCreateProjectsContext();
  const { lastFactDate } = global;

  return (
    <TreeContextProvider value={createdProject.tree}>
      <div className={cn.wells}>
        <Tree title="Скважины" preloadHooks={[]}>
          <div className={cn["month-picker"]}>
            <Typography.Text>Последний фактический месяц</Typography.Text>
            {lastFactDate ? (
              <MonthPicker
                className={cn.input}
                value={createdProject.lastFactMonth > lastFactDate ? lastFactDate : createdProject.lastFactMonth}
                onChange={(date) => createdProject.setLastFactMonth(date)}
                end={lastFactDate.subtract(1, "months")}
              />
            ) : (
              <Loader />
            )}
          </div>
          <div className={cn["year-picker"]}>
            <Typography.Text>Срок планирования (включительно)</Typography.Text>
            <YearPicker
              className={cn.input}
              min={new Date().getFullYear() + 1}
              max={new Date().getFullYear() + 100}
              onChange={(date) => createdProject.setLastForecastYear(date as number)}
            />
          </div>
          <div className={cn.select}>
            <Typography.Text>ТПП</Typography.Text>
            <Select
              disabled
              showSearch
              className={cn.input}
              defaultValue={createdProject.currentTppId}
              options={createdProject.tppAsSelectOptions}
              filterOption={(input, option) => (option?.label?.toString().toLowerCase() ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label?.toString() ?? "").toLowerCase().localeCompare((optionB?.label?.toString() ?? "").toLowerCase())
              }
              onChange={(value) => {
                runInAction(() => (createdProject.currentTppId = value));
              }}
            />
          </div>
        </Tree>
      </div>
    </TreeContextProvider>
  );
});

export { WellsTree };
