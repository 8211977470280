import { createContext, useContext, useMemo } from "react";
import { LineData } from "@okopok/axes_context";
import { LineDataModel } from "@okopok/axes_context";
import dayjs from "dayjs";
import { action, makeObservable, observable } from "mobx";

import { BarInfo, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { DATE_FORMAT, formatDate } from "elements/format/format";
import { LineDataModel as LineDataModelLocal } from "features/techForecast/wellTechForecast/results/chart/elements/lineDataModel";
import { boundDomain, createDomainAccessor, Domain, joinDomains } from "utils/boundDomain";

type TooltipManagerProps = {
  currentTimestamp: number;
  left: number;
  top: number;
  sceneTop: number;
  sceneLeft: number;
};

type TooltipDataType = {
  header: string;
  value: number;
  color: string | null;
};

class TooltipDataManager {
  isShowing: boolean = false;
  tooltipData: TooltipDataType[] | null;
  tooltipCoords: { top: number; left: number } | null;
  lines: (LineDataModel | LineDataModelLocal)[];
  bars: Map<number, BarInfo[]> | undefined;
  dataBounds: Domain | null;
  curveDragging: string | null = null;
  closestX: number;
  constructor(lines?: (LineDataModel | LineDataModelLocal | null)[], bars?: BarsDataModel, public footerFormat?: (value: number) => TooltipDataType) {
    makeObservable(this, {
      tooltipData: observable,
      isShowing: observable,
      curveDragging: observable,
      lines: observable,
      footerFormat: observable,
      setIsDragging: action,
      setTooltipInfo: action,
      getPointerLinePosition: action,
      updateFooterFormat: action,
      updateLines: action,
    });
    this.lines = (lines?.filter((line) => line !== null) as (LineDataModel | LineDataModelLocal)[]) ?? [];
    this.bars = bars?.preparedBars;
    this.dataBounds = this.findDataBounds();
    this.tooltipData = null;
    this.tooltipCoords = null;
    this.closestX = NaN;
  }

  updateFooterFormat = (f?: (value: number) => TooltipDataType) => {
    this.footerFormat = f;
  };

  updateLines = (lines?: (LineDataModel | LineDataModelLocal | null)[]) => {
    this.lines = (lines?.filter((line) => line !== null) as (LineDataModel | LineDataModelLocal)[]) ?? [];
  };

  getPointerLinePosition = (currentTimestamp: number | null) => {
    if (currentTimestamp) {
      if (this.lines) {
        this.lines.forEach((line) => {
          if (line !== undefined && this.curveDragging === null) {
            const accessor = createDomainAccessor<LineData["x"]>(line.x, line.y.length);
            if (!this.closestX) {
              this.closestX = accessor(0);
            }
            line.y.forEach((point, index) => {
              if (Math.abs(currentTimestamp - accessor(index)) < Math.abs(currentTimestamp - this.closestX)) {
                this.closestX = accessor(index);
              }
            });
          }
        });
      }
      if (this.bars) {
        if (!this.closestX) {
          this.closestX = Array.from(this.bars.entries())[0][0];
        }
        Array.from(this.bars.entries()).forEach(([year, barInfo]) => {
          if (Math.abs(currentTimestamp - year) < Math.abs(currentTimestamp - this.closestX)) {
            this.closestX = year;
          }
        });
      }
    }
    return this.closestX;
  };

  setTooltipInfo = (info: TooltipManagerProps | null) => {
    this.isShowing = info !== null;
    if (info) {
      const result = new Set<string>();

      if (this.lines) {
        this.lines.forEach((line) => {
          if (line !== undefined && this.curveDragging === null) {
            const accessor = createDomainAccessor<LineData["x"]>(line.x, line.y.length);
            if (!this.closestX) {
              this.closestX = accessor(0);
            }
            line.y.forEach((point, index) => {
              if (Math.abs(info.currentTimestamp - accessor(index)) < Math.abs(info.currentTimestamp - this.closestX)) {
                this.closestX = accessor(index);
              }
            });
          }
        });
        this.lines.forEach((line) => {
          if (line !== undefined) {
            const accessor = createDomainAccessor<LineData["x"]>(line.x, line.y.length);

            line.y.forEach((point, index) => {
              if (accessor(index) === this.closestX) {
                if (point !== null && !isNaN(+point) && isFinite(+point))
                  result.add(JSON.stringify({ header: line.title ?? "", value: +point, color: line.color ?? "currentColor" }));
              }
            });
          }
        });
      }
      if (this.bars) {
        const bar = this.bars.get(this.closestX);
        if (bar) {
          bar.map((bar) => result.add(JSON.stringify({ header: bar.title ?? "", value: bar.value, color: bar.color })));
        }
      }
      this.tooltipData = [];

      result.forEach((value) => this.tooltipData?.push(JSON.parse(value)));
      this.tooltipData?.push(
        this.footerFormat
          ? this.footerFormat(this.closestX)
          : JSON.parse(JSON.stringify({ header: "Период", value: formatDate(dayjs(this.closestX), DATE_FORMAT.monthYear), color: null }))
      );
      if (window.innerHeight < info.top + Math.min(this.tooltipData.length * 40, 280) + info.sceneTop) {
        this.tooltipCoords = {
          left: info.left + (Math.abs(info.sceneLeft + info.left - window.innerWidth) < 200 ? -188 : 20),
          top: info.top - Math.abs(window.innerHeight - info.top - Math.min(this.tooltipData.length * 46, 320) - info.sceneTop),
        };
      } else {
        this.tooltipCoords = { left: info.left - 90 + (Math.abs(info.sceneLeft + info.left - window.innerWidth) < 200 ? -90 : 3), top: info.top };
      }
    }
    return this.closestX;
  };

  findDataBounds = (): Domain | null => {
    let xDomain: Domain | undefined;
    if (this.bars) {
      xDomain = {
        min: Math.min(...Object.keys(this.bars).map((key) => +key)),
        max: Math.max(...Object.keys(this.bars).map((key) => +key)),
      };
    }
    if (this.lines.length > 0) {
      if (xDomain === undefined) {
        xDomain = boundDomain(this.lines[0].x);
      }
      this.lines.forEach((line) => {
        xDomain = joinDomains(xDomain!, boundDomain(line.x));
      });
    }
    return xDomain ?? null;
  };

  setIsDragging = (curveKey: string | null) => {
    this.curveDragging = curveKey;
  };
}
const TooltipContext = createContext<TooltipDataManager | null>(null);

const useTooltipContext = (): TooltipDataManager | null => {
  const model = useContext(TooltipContext);
  console.assert(model !== null, "Использование модели графика вне контекста");
  return model;
};

const useTooltipDataManager = (lines: (LineDataModel | LineDataModelLocal)[], bars?: BarsDataModel): TooltipDataManager => {
  const manager = useMemo(() => {
    return new TooltipDataManager(lines, bars);
  }, [bars, lines]);

  return manager;
};

export { TooltipContext, TooltipDataManager, useTooltipContext, useTooltipDataManager };
export type { TooltipManagerProps };
