import { useEffect, useMemo } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { makeAutoObservable } from "mobx";

type BufferNote = {
  key: string;
  value: string | undefined;
};

class SearchParamsStorage {
  private buffer: Array<BufferNote> = [];

  private parse(s: string): any {
    try {
      return JSON.parse(s);
    } catch {
      const i = parseInt(s, 10);
      if (isFinite(i)) {
        return i;
      }
      const f = parseFloat(s);
      if (isFinite(f)) {
        return f;
      }
      return s;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }

  getItem<T = unknown>(key: string): T | null {
    const fromStorage = localStorage.getItem(key);
    const fromURL = new URLSearchParams(window.location.search).get(key);
    const fromBuffer = (this.buffer as any).findLast((record: BufferNote) => key === record.key);
    if (fromBuffer?.value !== undefined) {
      return this.parse(fromBuffer.value);
    }
    if (fromURL !== null) {
      return this.parse(fromURL);
    }

    if (fromStorage) {
      this.buffer.push({ key, value: fromStorage });
      return this.parse(fromStorage);
    }
    return null;
  }

  setItem<T = unknown>(key: string, data: T) {
    const value = JSON.stringify(data);
    this.buffer.push({ key, value });
    localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    this.buffer.push({ key, value: undefined });
    localStorage.removeItem(key);
  }

  setDefaultItem<T = unknown>(key: string, data: T) {
    if (!this.getItem(key)) {
      // TODO: Поидее тут должен быть метод hasItem
      this.setItem(key, data);
    }
  }

  get bufferLength(): number {
    return this.buffer.length;
  }

  consume(setter: SetURLSearchParams) {
    setter(
      (v) => {
        const result = Object.fromEntries(v.entries());
        for (const { key, value } of this.buffer.splice(0, this.buffer.length)) {
          if (value === undefined) {
            delete result[key];
          } else {
            result[key] = value;
          }
        }
        return result;
      },
      { replace: true }
    );
  }
}

const useSearchParamsStorage = (): SearchParamsStorage => {
  const setter = useSearchParams()[1];
  const storage = useMemo(() => new SearchParamsStorage(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => storage.consume(setter), [storage.bufferLength]);
  return storage;
};

export { useSearchParamsStorage };
export type { SearchParamsStorage };
