import { ResultPage, useResultModel } from "routing/outlines/result/resultPage";

import { SideBlock as SideBlockComponent } from "elements/charts/sideBlock/SideBlock";
import { InvestResults } from "features/results/InvestResults";
import { SummaryModel } from "models/summary";

import InvestReportChart from "./resultCharts/investReportChart";

import styles from "./investReport.module.less";

const SideBlock = () => {
  const result = useResultModel();
  const { investStream } = new SummaryModel(result);
  return <SideBlockComponent data={investStream} />;
};
const InvestReport = () => {
  return (
    <ResultPage scroll={false} title="Результаты расчета: инвестиционная деятельность" needLicense>
      <div key="any" className={styles.wrap}>
        <InvestResults />
        <InvestReportChart />
        <SideBlock />
      </div>
    </ResultPage>
  );
};

export { InvestReport };
