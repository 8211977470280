import { RefObject, useEffect, useState } from "react";

export type ContainerShape = {
  width: number;
  height: number;
};

export function useContainerShape<T extends HTMLElement>(containerRef: RefObject<T>): ContainerShape {
  const [shape, setShape] = useState<ContainerShape>({ width: NaN, height: NaN });
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      setShape({ width: entries[0].contentRect.width, height: entries[0].contentRect.height });
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    const updateShape = () => {
      setShape({
        width: containerRef.current!.offsetWidth,
        height: containerRef.current!.offsetHeight,
      });
    };
    window.addEventListener("resize", updateShape);
    updateShape();
    return () => {
      window.removeEventListener("resize", updateShape);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current && observer.unobserve(containerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  return shape;
}
