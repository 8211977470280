import { FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { Settings as SettingsTable } from "features/ranking/settings";
import { useForecast } from "models/project/fact/forecast/forecast";

import { SettingsPreloader } from "./settingPreloader";

import cn from "./settings.module.less";

const Settings: FC = () => {
  return (
    <Preloader hooks={[useForecast]}>
      <ForecastSelector>
        <PageFrame title="Настройки планирования" bodyClassName={cn.container}>
          <SettingsPreloader>
            <SettingsTable />
          </SettingsPreloader>
        </PageFrame>
      </ForecastSelector>
    </Preloader>
  );
};

export { Settings };
