import { action, computed, makeObservable, observable } from "mobx";

import type {
  ProducingObjectsHTRTypesIds,
  ProducingObjectsLicenseRegionsParams,
} from "services/back/producingObjectsParams";
import { PROD_OBJ_LR_PARAMS_KEYS } from "services/back/producingObjectsParams";
import {
  getProducingObjectsLicenseRegionsParams,
  setProducingObjectsLicenseRegionsParams,
} from "services/back/producingObjectsParams";
import { getProducingObjectsHTRTypesIds, setProducingObjectsHTRTypesIds } from "services/back/producingObjectsParams";

import { Fact } from "../fact";
import { Forecast } from "../forecast/forecast";

type PrefillLicenseRegion = {
  default: number;
  [lrTitle: string]: number;
};
type Prefill = {
  default: PrefillLicenseRegion;
  [prodObjTitle: string]: PrefillLicenseRegion;
};
const PREFILL_LR_VALUES: Prefill = {
  БВ7: {
    "ХМН-00497-НЭ": 5.197,
    "ХМН-00498-НЭ": 18.844,
    default: 11.83,
  },
  "ЮВ1/1": {
    "ХМН-00497-НЭ": 0.023,
    "ХМН-00498-НЭ": 2.242,
    default: 1.21,
  },
  default: {
    "ХМН-00497-НЭ": 3.04,
    "ХМН-00498-НЭ": 14.503,
    default: 12.92,
  },
} as const;

const PREFILL_LR_VALUES_FACTUAL: Prefill = {
  БВ7: {
    "ХМН-00497-НЭ": 4.817,
    "ХМН-00498-НЭ": 17.291,
    default: 12.92,
  },
  "ЮВ1/1": {
    "ХМН-00497-НЭ": 0.023,
    "ХМН-00498-НЭ": 4.052,
    default: 2.31,
  },
  default: {
    "ХМН-00497-НЭ": 3.04,
    "ХМН-00498-НЭ": 14.503,
    default: 12.92,
  },
} as const;

function getPrefillValue(prodObjTitle: string, lrTitle: string, isFactual = false): number {
  const prefill = isFactual ? PREFILL_LR_VALUES_FACTUAL : PREFILL_LR_VALUES;
  const po = prefill[prodObjTitle] ?? prefill.default;
  return po[lrTitle] ?? po.default;
}

class ProducingObjectsParams {
  private htrTypesIdsValue?: ProducingObjectsHTRTypesIds | null;
  private licenseRegionsParamsValue?: ProducingObjectsLicenseRegionsParams | null;

  constructor(private fact: Fact, private forecast: Forecast | null = null) {
    makeObservable<ProducingObjectsParams, "htrTypesIdsValue" | "licenseRegionsParamsValue" | "init">(this, {
      htrTypesIdsValue: observable.ref,
      licenseRegionsParamsValue: observable.ref,
      htrTypesIds: computed,
      licenseRegionsParams: computed,
      isLoading: computed,
      isValid: computed,
      init: action,
      updateHTR: action,
      updateLRParams: action,
    });

    this.init();
  }

  public get htrTypesIds() {
    if (this.isLoading) {
      return undefined;
    }
    const result: ProducingObjectsHTRTypesIds = {};
    for (const prodObj of this.fact.producingObjects.values!) {
      result[prodObj.id] = this.htrTypesIdsValue?.[prodObj.id] ?? this.paramsRange.array.fill(null);
    }
    return result;
  }

  public get licenseRegionsParams() {
    if (this.isLoading) {
      return undefined;
    }
    const result: ProducingObjectsLicenseRegionsParams = {};
    const actualProducingObjects = [...this.fact.producingObjects.values!];
    const actualLicenseRegions = this.fact.licenseRegions.data;

    for (const po of actualProducingObjects) {
      const poParams: ProducingObjectsLicenseRegionsParams[number] =
        JSON.parse(JSON.stringify(this.licenseRegionsParamsValue?.[po.id] ?? {})) ?? {};
      for (const lr of actualLicenseRegions) {
        const fillingValue = getPrefillValue(po.title, lr.title, this.forecast === null);
        if (poParams[lr.id]) {
          const actualLength = poParams[lr.id].reserves.length;
          if (actualLength !== this.paramsRange.length) {
            console.error(`Неверный размер запасов: ${poParams[lr.id].reserves.length}`);
            poParams[lr.id].reserves = this.paramsRange.array.fill(fillingValue);
          } else {
            poParams[lr.id].reserves = poParams[lr.id].reserves.map((v) => v ?? fillingValue);
          }
        } else {
          poParams[lr.id] = {
            reserves: this.paramsRange.array.fill(fillingValue),
          };
        }
      }
      result[po.id] = poParams;
    }

    return result;
  }

  public get paramsRange() {
    if (this.forecast === null) {
      return this.fact.factRange;
    }
    return this.forecast.range;
  }

  public get isLoading(): boolean {
    return (
      this.fact.producingObjects.isLoading ||
      this.htrTypesIdsValue === undefined ||
      this.licenseRegionsParamsValue === undefined
    );
  }

  public get isValid(): boolean {
    if (!this.htrTypesIdsValue || !this.licenseRegionsParamsValue || this.fact.producingObjects.isLoading) {
      return false;
    }
    const expectedLength = this.paramsRange.length;
    const actualProducingObjectsIds = [...this.fact.producingObjects.values!].map((po) => po.id);
    const actualLicenseRegionsIds = this.fact.licenseRegions.ids;
    for (const prodObjId of actualProducingObjectsIds) {
      const htrTypes = this.htrTypesIdsValue[prodObjId];
      const prodObjParams = this.licenseRegionsParamsValue[prodObjId];
      if (!prodObjParams || !htrTypes) {
        return false;
      }
      if (htrTypes.length !== expectedLength) {
        console.error(
          `wrong params length (PO_id=${prodObjId}, param='htrTypesIds'). Expected: ${expectedLength}. Got: ${htrTypes.length}`
        );
        return false;
      }
      for (const licenseRegionId of actualLicenseRegionsIds) {
        const licenseRegionParams = prodObjParams[licenseRegionId];
        if (!licenseRegionParams) {
          return false;
        }
        for (const paramKey of PROD_OBJ_LR_PARAMS_KEYS) {
          if (!licenseRegionParams[paramKey]) {
            console.error(
              `wrong params length (PO_id=${prodObjId}, LR_id=${licenseRegionId}, param=${paramKey}). Expected: ${expectedLength}. Got: ${licenseRegionParams[paramKey].length}`
            );
            return false;
          }
        }
      }
    }
    return true;
  }

  private async init() {
    this.htrTypesIdsValue = await getProducingObjectsHTRTypesIds(this.fact.projectId, this.forecast?.id);
    this.licenseRegionsParamsValue = await getProducingObjectsLicenseRegionsParams(
      this.fact.projectId,
      this.forecast?.id
    );
  }

  public async updateHTR(updatedTypesIds: ProducingObjectsHTRTypesIds) {
    this.htrTypesIdsValue = await setProducingObjectsHTRTypesIds(
      this.fact.projectId,
      this.forecast?.id,
      updatedTypesIds
    );
  }

  public async updateLRParams(updatedParams: ProducingObjectsLicenseRegionsParams) {
    this.licenseRegionsParamsValue = await setProducingObjectsLicenseRegionsParams(
      this.fact.projectId,
      this.forecast?.id,
      updatedParams
    );
  }
}

export { ProducingObjectsParams };
