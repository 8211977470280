import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { ParamsPage } from "routing/outlines/params/paramsPage";

import type { Params as ParamsModel } from "models/params/params";
import { InvestParams } from "models/project/fact/investParams/investParams";

type InvestModelsFact = {
  investParams: {
    get: (key: number) => InvestParams;
  };
};

type InvestModelsForecast = InvestModelsFact & {
  investCosts: ParamsModel;
};

const forecast = [
  {
    key: "costs",
    title: "Удельные затраты",
    model: (data: InvestModelsForecast) => data.investCosts,
  },
  {
    key: "years",
    title: "Параметры по годам",
    model: (data: InvestModelsForecast, licenseKey?: number) => data.investParams.get(licenseKey!),
    isNeedLicense: true,
    hideSums: false,
  },
];

const fact = [
  {
    key: "years",
    title: "Параметры по годам",
    model: (data: InvestModelsFact, licenseKey?: number) => data.investParams.get(licenseKey!),
    isNeedLicense: true,
    hideSums: false,
  },
];

const InvestParameters: FC = observer(() => {
  return <ParamsPage factTabs={fact} forecastTabs={forecast} />;
});

export { InvestParameters };
