import { useMemo } from "react";
import { UndoOutlined } from "@ant-design/icons";
import { Button, InputNumber, Typography } from "antd";
import { observer } from "mobx-react";

import { Loader } from "elements/loader";
import { ModelContentComponentType } from "elements/modal/modal";
import { useFact } from "models/project/fact/fact";

import { DEFAULT_VALUE, ProducingObjectFormModel } from "./producingObjectFormModel";

import cn from "./producingObjectForm.module.less";

type CompensationCoeffs = {
  [producingObjectId: number]: number;
};

const ProducingObjectForm: ModelContentComponentType<CompensationCoeffs> = observer(({ setOnAccept }) => {
  const fact = useFact()!;
  const formModel = useMemo(() => new ProducingObjectFormModel(fact.producingObjects), [fact]);

  setOnAccept(async () => {
    const data: CompensationCoeffs = {};
    for (const item of formModel.individuals) {
      data[item.producingObject.id] = item.value ?? formModel.commonValue ?? DEFAULT_VALUE;
    }
    return {
      result: true,
      data,
    };
  });

  return (
    <div>
      <div className={cn.body}>
        <div className={cn.pumping}>
          <Typography.Text>Задать общий коэф-т компенсации</Typography.Text>
          <InputNumber
            placeholder="Разные значения"
            value={formModel.commonValue}
            onChange={formModel.setCommonValue}
            suffix="%"
            min={0}
            className={cn.inputNumber}
          />
          <Button disabled={formModel.commonValue === DEFAULT_VALUE} icon={<UndoOutlined />} onClick={formModel.resetCommonValue} />
        </div>
        <Typography.Title level={4} className={cn.title}>
          Значения для объектов разработки в отдельности
        </Typography.Title>
        <div className={cn.inputs}>
          {fact === undefined || fact!.producingObjects.isLoading || formModel.individuals === undefined ? (
            <Loader />
          ) : (
            formModel.individuals.map((po) => (
              <div key={po.producingObject.id}>
                <Typography.Text>{po.producingObject.title}</Typography.Text>
                <div className={cn.row}>
                  <InputNumber min={0} value={po.value} onChange={po.update} suffix="%" className={cn.inputNumber} />
                  <Button disabled={po.value === DEFAULT_VALUE} icon={<UndoOutlined />} onClick={po.reset} />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
});

export { type CompensationCoeffs, ProducingObjectForm };
