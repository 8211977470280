import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Dropdown, MenuProps, Select, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { useForecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";

import cn from "./calculateButton.module.less";

const calculateItems = [
  { key: "hydraulic", label: "Гидравлический расчет" },
  { key: "economic", label: "Экономический расчет" },
];

const CalculateButton: FC<{ fullWidth?: boolean }> = observer(({ fullWidth }) => {
  const [ecyId, setEcyId] = useState(-1);
  const forecast = useForecast()!;
  const { infrastructureValidator } = forecast.infrastructure;
  const { isCalculation, calculateHydraulic, calculateEconomic } = forecast.infrastructure.calculateStore;
  const loading = forecast.production.isLoading || isCalculation;

  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const onCalculate: MenuProps["onClick"] = (e) => {
    if (e.key === "hydraulic") {
      calculateHydraulic();
    } else {
      calculateEconomic(ecyId);
    }
  };

  const handleSelect = (id: number) => {
    setEcyId(id);
  };

  if (path === "table") {
    return (
      <Tooltip title={infrastructureValidator.validationTooltip}>
        <Button
          onClick={() => calculateHydraulic(true)}
          loading={loading}
          disabled={!!infrastructureValidator.validationTooltip || loading}
          type="primary"
          className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}
        >
          Рассчитать
        </Button>
      </Tooltip>
    );
  }

  if (path === "aggregation") {
    return (
      <div className={cn.wrapper}>
        <Select value={ecyId} onChange={handleSelect} options={forecast.ecyStore.selectItems} />
        <Tooltip title={infrastructureValidator.validationTooltip}>
          <Button
            onClick={() => calculateEconomic(ecyId)}
            loading={loading}
            disabled={!!infrastructureValidator.validationTooltip || loading}
            type="primary"
            className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}
          >
            Рассчитать
          </Button>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={cn.wrapper}>
      <Select value={ecyId} onChange={handleSelect} options={forecast.ecyStore.selectItems} />
      <Tooltip title={infrastructureValidator.validationTooltip}>
        <div>
          <Dropdown
            disabled={infrastructureValidator.cannotCalculate || loading}
            menu={{ items: infrastructureValidator.cannotCalculate || loading ? [] : calculateItems, onClick: onCalculate }}
          >
            <Button loading={loading} type="primary" className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}>
              Рассчитать <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ArrowDownIcon />} />
            </Button>
          </Dropdown>
        </div>
      </Tooltip>
    </div>
  );
});

export { CalculateButton };
