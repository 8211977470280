import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7929 14.7929C15.1834 14.4024 15.8166 14.4024 16.2071 14.7929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L14.7929 16.2071C14.4024 15.8166 14.4024 15.1834 14.7929 14.7929Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16ZM11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z"
    />
  </g>
);

const Search: FC<SharedIconProps> = (props) => <Icon {...props} content={ASSET} />;

export { Search };
