import { ReactNode } from "react";
import { Typography } from "antd";

import dp from "./dataProject.module.less";

type Props = {
  icon: ReactNode;
  title: string;
  children: ReactNode;
};
const DetailCard = ({ icon, title, children }: Props) => {
  return (
    <div className={dp.detailCardWrapper}>
      <Typography.Title level={5} className={dp.detailTitle}>
        {icon}
        <span>{title}</span>
      </Typography.Title>
      <Typography.Text className={dp.detailContent}>{children}</Typography.Text>
    </div>
  );
};

export { DetailCard };
