import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { RankingSettings } from "models/project/fact/forecast/ranking/settings";

const SettingsContext = createContext<RankingSettings | null>(null);

const SettingsContextProvider = ({ value, children }: PropsWithChildren<{ value: RankingSettings }>) => {
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

const useSettingsContext = (): RankingSettings => {
  console.assert(useContext<RankingSettings | null>(SettingsContext) !== null, "useSettingsContext out of context");
  return useContext<RankingSettings | null>(SettingsContext)!;
};

export { SettingsContextProvider, useSettingsContext };
