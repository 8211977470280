import { FC, ReactNode, useMemo, useState } from "react";
import { Divider, Switch, Tabs, TabsProps } from "antd";
import classNames from "classnames";

import { Format } from "elements/format/format";

import { CalculationEmpty } from "../calculationEmpty";
import { FieldParams } from "../fieldParams";
import { Label } from "../label";

import cn from "./paramsTabs.module.less";

type Construction = {
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;
  total: number;
};

type Reconstruction = { period?: number; isActive?: boolean } & Construction;

type ParamsProps = { title: string; period?: number; isActive?: boolean; onClick?: (e: boolean) => void } & Construction;

const Field: FC<{ children: number }> = ({ children }) => {
  if (!children) return <>0</>;
  return (
    <>
      <Format>{children}</Format> млн. руб
    </>
  );
};

const Params: FC<ParamsProps> = ({ title, onClick, ...props }) => (
  <>
    <div className={cn.paramsTitleBlock}>
      <Label>{title}</Label>
      {props.isActive !== undefined && <Switch onClick={onClick} value={props.isActive} />}
    </div>
    <FieldParams title="Суммарно">
      <Field>{props.total}</Field>
    </FieldParams>
    <Divider className={cn.divider} />
    <FieldParams title="ПИР">
      <Field>{props.dsw}</Field>
    </FieldParams>
    <FieldParams title="Оборудование">
      <Field>{props.equipment}</Field>
    </FieldParams>
    <FieldParams title="СМР">
      <Field>{props.ciw}</Field>
    </FieldParams>
    <FieldParams title="Прочее">
      <Field>{props.other}</Field>
    </FieldParams>
    {props.period && <FieldParams title="Период реконструкции">{props.period} лет</FieldParams>}
  </>
);

type EconomicParamsProps = {
  construction?: Construction;
  reconstruction?: Reconstruction;
  calculate?: () => void;
};

const EconomicParams: FC<EconomicParamsProps> = ({ construction, reconstruction, calculate }) => {
  const [isReconstruction, setIsReconstuction] = useState(false);

  const [constructionTotalCost, reconstructionTotalCost] = [construction?.total ?? 0, reconstruction?.total ?? 0];
  const total = useMemo(
    () => (isReconstruction ? constructionTotalCost + reconstructionTotalCost : constructionTotalCost),
    [isReconstruction, constructionTotalCost, reconstructionTotalCost]
  );

  if (!(construction && Object.keys(construction).length)) {
    return <CalculationEmpty type="economic" calculate={calculate} />;
  }

  return (
    <div className={cn.wrapper}>
      <div className={cn.economicTotalSum}>
        <div>
          <Format>{total}</Format> млн. руб
        </div>
        <div>Суммарные затраты</div>
      </div>
      <div className={cn.paramsWrapper}>
        <Params title="Строительство" {...construction} />
        {reconstruction && Object.keys(reconstruction).length && (
          <>
            <Divider className={cn.divider} />
            <Params title="Реконструкция" {...reconstruction} isActive={isReconstruction} onClick={setIsReconstuction} />
          </>
        )}
      </div>
    </div>
  );
};

type Props = { children: ReactNode; className?: string; economicParams?: EconomicParamsProps };

const ParamsTabs: FC<Props> = ({ children, className, economicParams }) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Технология",
      children: children,
    },
    {
      key: "2",
      label: "Экономика",
      children: <EconomicParams {...economicParams} />,
    },
  ];
  return <Tabs className={classNames(cn.tabs, className)} items={items} />;
};

export { ParamsTabs };
