import { FC } from "react";
import { useMapContext } from "@okopok/axes_context";

import { useInfrastructureMapContext } from "./InfrastructureMapManager/InfrastructureMapManager";
import { getZoomParameters } from "./utils";

const color = "rgba(219, 68, 50, 1)";

const Ruler: FC = () => {
  const manager = useInfrastructureMapContext();
  const { scale, zoom } = useMapContext();

  const { zoomScale } = getZoomParameters(zoom);

  if (!(manager.zoomedCursorPosition && manager.ruler)) {
    return <></>;
  }

  return (
    <g>
      <g transform={`translate(${scale.x(manager.ruler.x)}, ${scale.y(manager.ruler.y)}), scale(${zoomScale})`}>
        <circle fill={color} r={10} />
      </g>
      <line
        x1={scale.x(manager.ruler.x)}
        y1={scale.y(manager.ruler.y)}
        x2={scale.x(manager.zoomedCursorPosition.x)}
        y2={scale.y(manager.zoomedCursorPosition.y)}
        strokeWidth={2 / Math.min(zoom, 1)}
        strokeDasharray={"5,5"}
        stroke={color}
      />
      <g transform={`translate(${scale.x(manager.zoomedCursorPosition.x)}, ${scale.y(manager.zoomedCursorPosition.y)}), scale(${zoomScale})`}>
        <circle fill={color} r={10} />
      </g>
    </g>
  );
};

export default Ruler;
