import { when } from "mobx";

import { Params, ParamsServices } from "models/params/params";
import { ParamsTable } from "models/params/paramsTable";
import type { Project } from "models/project/project";
import { Range } from "utils/range";

class NDDTable extends ParamsTable {
  public project?: Project;
  get tableItems() {
    if (this.project?.isAssetAlgoNdd) {
      return super.tableItems[1].children!.map((row, id) => {
        row.level = 0;
        row.row = id + 1;
        return row;
      });
    }
    return super.tableItems;
  }

  get clone() {
    const params: NDDTable = new NDDTable(this.title, this.years, this.getParams, this.setParams, this);
    params.metrics = this.metrics?.map((metric) => metric.cloneAsRoot());
    params.project = this.project;
    return params;
  }
}

class NDDTax extends Params {
  constructor(range: Range | Promise<Range>, services: ParamsServices, project: Project) {
    super("Налоговое окружение", range, services, NDDTable);

    when(() => this.table?.isLoading === false).then(() => {
      (this.table! as NDDTable).project = project;
    });
  }
}

export { NDDTax };
