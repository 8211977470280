import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, makeObservable, observable } from "mobx";

import { Project } from "models/project/project";
import { Stratum } from "models/project/stratum/stratum";

import { ProducingObjectRow } from "./producingObject";

type DRow = {
  title: string;
  stratumType: string | null;
  collectorType: string | null;
  producingObject: ProducingObjectRow | null;
};

class StratumRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    title: this.stratum.title,
    stratumType: null,
    collectorType: null,
    producingObject: this.relatedProducingObject,
  });

  public relatedProducingObject: ProducingObjectRow | null = null;

  constructor(parent: StratumTable, public readonly stratum: Stratum) {
    super(parent);
    makeObservable(this, {
      relatedProducingObject: observable.ref,
      updateValue: action,
    });
    this.childrenStore = null;
  }

  updateValue(key: string, newValue: any): [prevValue: any, currValue: any] {
    if (key === "producingObject") {
      if (newValue === null || newValue instanceof ProducingObjectRow) {
        newValue && (newValue as ProducingObjectRow).addStratum(this);
        this.relatedProducingObject?.removeStratum(this);
        if (this.relatedProducingObject?.data.mainStratumId === this.stratum.id) {
          // need to register mutation through mutation manager
          this.relatedProducingObject.mutationsManager?.updateWrapper("mainStratumId", null);
        }
        return [this.relatedProducingObject, (this.relatedProducingObject = newValue)];
      }
      console.error("wrong producingObject newValue type");
      return [undefined, undefined];
    }
    console.error("mutatate non mutable field");
    return [undefined, undefined];
  }
}

class StratumTable extends TableNode<DRow, StratumRow> {
  public readonly byId = new Map<number, StratumRow>();
  constructor(project: Project) {
    super();
    this.childrenStore = new ChildrenStoreArray(
      this,
      project.stratums.items!.map((s) => {
        const row = new StratumRow(this, s);
        this.byId.set(s.id, row);
        return row;
      })
    );
  }
}

export type { DRow };
export { StratumRow, StratumTable };
