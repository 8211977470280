import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react";

import { ModelContentComponentType, useModal } from "elements/modal/modal";
import { global } from "models/global";
import { Project as ProjectModel } from "models/project/project";
import { useProjects } from "models/project/projects";
import { postProject, type ProjectNewRaw } from "services/back/project";
import { assignRole } from "services/back/roles";

import { CreateProject } from "./createProjectModel";
import { CreateProjectsContextProvider } from "./createProjectProvider";
import { Settings } from "./settings";
import { WellsTree } from "./wellsTree";

import cn from "./createProjectModal.module.less";

const CreateProjectForm: ModelContentComponentType<ProjectNewRaw> = observer(({ setOnAccept, setLoading, accept, reject }) => {
  const projects = useProjects();
  const navigate = useNavigate();
  const creator = global.user!;
  const createProjectModel = useMemo(() => new CreateProject(creator), [creator]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onFinish = async () => {
    setIsLoading(true);
    const created = await postProject(createProjectModel.project);
    const project = ProjectModel.fromRawData(created);
    const { participants } = createProjectModel;
    for (const {
      user: { id: uId },
      roleId,
    } of participants) {
      await assignRole(roleId, project.id, uId);
    }
    projects.add(project);
    navigate(project.id.toString());
    return Promise.resolve({
      result: true,
      data: createProjectModel.project,
    });
  };

  const onSubmit = () => {
    setOnAccept(() => onFinish());
    accept();
  };

  return (
    <CreateProjectsContextProvider value={createProjectModel}>
      <div className={cn.container}>
        <WellsTree />
        <Settings />
      </div>
      <div className={cn.footer}>
        <Button onClick={reject}>Отмена</Button>
        <Tooltip title={!createProjectModel.isParticipantsOk ? "Заполнены не все роли" : ""}>
          <Button onClick={() => onSubmit()} disabled={!createProjectModel.isParticipantsOk} loading={isLoading} type="primary">
            Создать проект
          </Button>
        </Tooltip>
      </div>
    </CreateProjectsContextProvider>
  );
});

const useCreateProject = () => {
  const showModal = useModal<ProjectNewRaw>();
  const data = useRef(undefined);

  return useCallback(() => showModal(CreateProjectForm, data, "Создание проекта", null, null, "1165px", null), [showModal]);
};

export { useCreateProject };
