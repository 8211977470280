import { FC, ReactNode } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import { Plus } from "elements/icons/plus";
import { SubmenuTitle } from "elements/submenuTitle/submenuTitle";

import cn from "./title.module.less";

type TitleProps = {
  onSearchChange: (v: string | undefined) => void;
  search: string | undefined;
  title: ReactNode;
  onAdd?: (() => void) | null;
  addTooltip?: string;
  isWrongSearch: boolean;
  onCopy?: (() => void) | null;
} & React.PropsWithChildren;

const Title: FC<TitleProps> = ({ onSearchChange, search, title, onAdd, onCopy, addTooltip, isWrongSearch, children }) => {
  return (
    <SubmenuTitle
      title={title}
      isWrongSearch={isWrongSearch}
      onSearchChange={onSearchChange}
      search={search}
      buttons={
        <>
          {onCopy !== undefined && (
            <Tooltip title="Копировать прогноз">
              <Button
                className={cn.copyButton}
                data-testid="copyButton"
                icon={<CopyOutlined />}
                disabled={onCopy === null}
                onClick={onCopy ?? undefined}
              />
            </Tooltip>
          )}
          {onAdd !== undefined && (
            <Tooltip title={addTooltip}>
              <Button icon={<Plus />} data-testid="addScenario" disabled={onAdd === null} onClick={onAdd ?? undefined} />
            </Tooltip>
          )}
        </>
      }
    >
      {children}
    </SubmenuTitle>
  );
};

export { Title };
