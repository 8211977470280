import { reqCamel } from "utils/request";

type NDDGroupWithoutYear = {
  group: 3 | 5;
  productionYear?: undefined;
};

type NDDGroupWithYear = {
  group: 1 | 2 | 4;
  productionYear: number;
};

type NDDGroups = NDDGroupWithoutYear["group"] | NDDGroupWithYear["group"];

type NDDSystem = {
  ndd: NDDGroupWithYear | NDDGroupWithoutYear;
  transitionYear: number;
};

type TaxSystem =
  | {
      isNdd: true;
      nddSystem: NDDSystem;
    }
  | {
      isNdd: false;
      nddSystem: null;
    };

type LicenseRegionBackend = {
  id: number;
  title: string;
  taxSystem: TaxSystem;
};

function getLicenseRegions(): Promise<LicenseRegionBackend[]> {
  return reqCamel.get(`license_zones/`) as Promise<LicenseRegionBackend[]>;
}

export type { LicenseRegionBackend, NDDGroups, NDDSystem, TaxSystem };
export { getLicenseRegions };
