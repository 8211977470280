import { useEffect, useState } from "react";
import { reaction } from "mobx";

const getForEdit = <T extends { isLoading: boolean; clone: T }>(model: T) =>
  model && !model.isLoading ? model.clone : null;

const useForEdit = <T extends { isLoading: boolean; clone: T }>(model: T): [T | null, (v: T) => void] => {
  const [forEdit, setForEdit] = useState<T | null>(null);

  useEffect(() => {
    return reaction(
      () => model.isLoading,
      () => setForEdit(getForEdit(model)),
      { fireImmediately: true }
    );
  }, [model]);

  return [forEdit, setForEdit];
};

export { useForEdit };
