import { FC, ReactNode } from "react";
import { Typography } from "antd";

import cn from "./projectsInfoSection.module.less";

type ProjectInfoSectionProps = {
  title: string;
  children: ReactNode;
};

const ProjectInfoSection: FC<ProjectInfoSectionProps> = ({ title, children }) => {
  return (
    <div className={cn.section}>
      <Typography.Text type="secondary" className={cn.title}>
        {title.toUpperCase()}
      </Typography.Text>
      {children}
    </div>
  );
};

export { ProjectInfoSection };
