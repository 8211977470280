import { FC, useEffect } from "react";
import { observer } from "mobx-react";

import { useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";

import { GeneralInspector } from "./generalInspector/generalInspector";
import { MultiInspector } from "./multiInspector/multiInspector";
import { NodeInspector } from "./nodeInspector/nodeInspector";
import { PipeInspector } from "./pipeInspector/pipeInspector";

const InspectorMenu: FC<{ visible: boolean }> = observer(({ visible }) => {
  const { inspector } = useInfrastructureMapContext();
  const { selectedPipe, selectedNode, isMultipleItems, isNodeItem, isPipeItem, clearCurrentItem } = inspector;

  useEffect(() => {
    if (!isMultipleItems) {
      clearCurrentItem();
    }
  }, [isMultipleItems, clearCurrentItem]);

  if (!visible) {
    return <></>;
  }

  if (isPipeItem) {
    return <PipeInspector pipe={selectedPipe!} />;
  }

  if (isNodeItem) {
    return <NodeInspector node={selectedNode!} />;
  }

  if (isMultipleItems) {
    return <MultiInspector />;
  }

  return <GeneralInspector />;
});

export { InspectorMenu };
