import { RCPInput, RCPOutput } from "services/back/rcp/calculate";

import { CrewType, Ranks } from "../types";

type GTMInfo = {
  wellId: number;
  gtmId: number | null;
  gtmType: string | null; // null when "ЭБ"
  crewType: CrewType;
  rank?: number; // FIXME: should be always number but types are weak

  price?: number; // млн руб;
  period?: {
    start: string; // date;
    end: string; // date;
  };
};

type RCPInfo = {
  gtms: GTMInfo[];
  gtmsNotIncluded: GTMInfo[];
};

function extractRCPInfo(ranks: Ranks, input: RCPInput, output: RCPOutput): RCPInfo {
  const wellIds = new Set<number>();
  const gtmIds = new Set<number>();

  const gtms = output.flatMap(({ gtmWorks }) =>
    gtmWorks.map(({ well, gtm, crew, period }): GTMInfo => {
      if (gtm !== null) {
        gtmIds.add(gtm.id);
      } else {
        wellIds.add(well.id);
      }
      return {
        wellId: well.id,
        gtmId: gtm?.id ?? null,
        gtmType: gtm?.gtmType ?? null,
        crewType: crew.gtmGroup,
        rank: gtm === null ? ranks.wells.get(well.id) : ranks.gtms.get(gtm.id),

        price: (gtm?.gtmPrice ?? well.prodDrillingPrice * well.length) / 1000,
        period,
      };
    })
  );
  const gtmsNotIncluded: GTMInfo[] = [];
  input.mines.forEach((mine) => {
    mine.wellCluster.idListOfWells.forEach((wellId) => {
      if (!wellIds.has(wellId)) {
        gtmsNotIncluded.push({
          wellId,
          gtmId: null,
          gtmType: null,
          crewType: "ЭБ",
          rank: ranks.wells.get(wellId),
        });
      }
    });
  });
  input.gtms.forEach(({ id, wellId, gtmType, gtmGroup }) => {
    if (!gtmIds.has(id)) {
      gtmsNotIncluded.push({
        wellId,
        gtmId: id,
        gtmType,
        crewType: gtmGroup,
        rank: ranks.gtms.get(id),
      });
    }
  });

  return { gtms, gtmsNotIncluded };
}

export { extractRCPInfo, type GTMInfo };
