import { FC, ReactNode, useCallback, useEffect } from "react";
import { Button, Space } from "antd";
import { NotificationPlacements } from "antd/es/notification/interface";
import useNotification from "antd/es/notification/useNotification";

type Props = {
  notifyKey?: string;
  title?: ReactNode;
  placement?: (typeof NotificationPlacements)[number];
  children?: ReactNode;
  buttons?: ReactNode;
  duration?: number | null;
  onClose?: VoidFunction;
};

const Notification: FC<Props> = ({ notifyKey = "alert", title, placement = "bottomRight", children, buttons, duration = null, onClose }) => {
  const [api, contextHolder] = useNotification();

  const handleClose = useCallback(() => {
    api.destroy(notifyKey);
    onClose?.();
  }, [onClose, api, notifyKey]);

  const openNotification = useCallback(() => {
    api.open({
      message: title ?? <></>,
      placement: placement,
      description: <>{children ?? ""}</>,
      btn: (
        <Space>
          {buttons}
          <Button type="primary" size="small" onClick={handleClose}>
            Закрыть
          </Button>
        </Space>
      ),
      key: notifyKey,
      duration: duration,
      onClose: handleClose,
    });
  }, [api, notifyKey, title, placement, children, buttons, duration, handleClose]);

  useEffect(() => {
    children && openNotification();
  }, [openNotification, children]);

  return <>{contextHolder}</>;
};

export { Notification };
