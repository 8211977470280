import type { FC, ReactNode } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Empty, Spin } from "antd";
import classNames from "classnames";

import cn from "./style.module.less";

const FullScreen: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => {
  return <div className={classNames(cn.center, className)}>{children}</div>;
};

const FullScreenLoader: FC<{ children?: ReactNode; onClose?: () => void }> = ({ children, onClose }) => (
  <FullScreen className={cn.loader}>
    {onClose ? <Button type="text" className={cn.close} icon={<CloseCircleOutlined />} onClick={onClose} /> : null}
    <Spin size="large" />
    <div className="loading" style={{ display: "flex", justifyContent: "space-around" }}>
      {children ?? "Загрузка"}
    </div>
  </FullScreen>
);

const FullScreenEmpty: FC<{ children: ReactNode }> = ({ children }) => (
  <FullScreen>
    <Empty style={{ maxWidth: 700 }} description={children} />
  </FullScreen>
);

const FullScreenNotFound: FC<{ children: ReactNode }> = ({ children }) => (
  <FullScreen>
    <Empty image="тут будет 404" style={{ maxWidth: 700 }} description={children} />
  </FullScreen>
);

export { FullScreen, FullScreenEmpty, FullScreenLoader, FullScreenNotFound };
