import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g fill="none" stroke="currentColor">
    <path d="M19 12H5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19V5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </g>
);

const Plus: FC<SharedIconProps> = (props) => <Icon {...props} content={ASSET} />;

export { Plus };
