import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, makeObservable, observable, override } from "mobx";

import type { Ranking, RankType } from "services/back/ranking";

import { RankingDRow } from "./rankingDrilling";
import { RowNode } from "./wellRow";

class RankingReconstruction extends TableNode<RankingDRow, RowNode> {
  order: RankType = "pi";
  isMoved: boolean = false;

  constructor() {
    super(null, { selectable: false, mutable: true });

    makeObservable(this, {
      order: observable,
      isMoved: observable,
      isUpdated: override,
      setOrder: action,
      initChildren: action,
      drop: action,
    });
  }

  initChildren(gtmList: Ranking["gtms"]) {
    this.childrenStore = new ChildrenStoreArray(
      this,
      gtmList.map((gtm) => new RowNode(this, gtm))
    );
  }

  setOrder(order: RankType) {
    this.order = order;
    this.isMoved = true;
    this.sort();
  }

  private sort() {
    this.childrenStore?.sort((a, b) => b.rank - a.rank);
  }

  drop() {
    this.childrenStore = undefined;
  }

  get isUpdated(): boolean {
    if (super.isUpdated) {
      return super.isUpdated;
    }
    return this.isMoved;
  }

  public getOrder = () => {
    const order = {
      result: {
        gtms: [] as Ranking["gtms"],
      },
      reconstruction: this.order,
    };
    for (const child of this.children ?? []) {
      const { gtmId, gtmTypeTitle, gtmTypeId } = child.row;
      order.result.gtms.push({
        gtmId: gtmId ?? 0,
        gtmTypeId: gtmTypeId ?? 0,
        gtmTypeTitle: gtmTypeTitle ?? "",
        ...child.row,
      });
    }
    return order;
  };
}

export { RankingReconstruction };
