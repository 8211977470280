import { FC } from "react";

import { Icon } from "elements/icon/icon";
import { Loader } from "elements/loader";

import { ReactComponent as YesCompleteIcon } from "./complete.svg";
import { ReactComponent as GrayCompleteIcon } from "./complete_gray.svg";
import { ReactComponent as NotCompleteIcon } from "./notComplete.svg";
import { ReactComponent as WarningIcon } from "./warning.svg";
import { ReactComponent as WrongIcon } from "./wrong.svg";

type CompleteIconProps = {
  completed: boolean;
  size?: number;
  isLoading?: boolean;
  wrong?: boolean;
  warning?: boolean;
  gray?: boolean;
};

const icon = (wrong: boolean, warning: boolean, completed: boolean, gray: boolean) => {
  if (completed) {
    return gray ? <GrayCompleteIcon /> : <YesCompleteIcon />;
  }
  if (wrong) {
    return <WrongIcon />;
  }
  if (warning) {
    return <WarningIcon />;
  }
  return <NotCompleteIcon />;
};

const CompleteIcon: FC<CompleteIconProps> = ({ completed, size: fontSize, isLoading, wrong = false, warning = false, gray = false }) => {
  if (isLoading) {
    return <Loader />;
  }
  return <Icon style={{ fontSize }} content={icon(wrong, warning, completed, gray)} />;
};

export { CompleteIcon };
