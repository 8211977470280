import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { reaction } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { TreeRoot } from "models/tree/tree";

import { CompareTableStore, DRow } from "./tableStore";

import cn from "../compareScenario.module.less";

class CompareTableModel extends TableModel<DRow> {
  constructor(public readonly tree: TreeRoot<Forecast>, store: CompareTableStore, columns: ColumnRaw<DRow>[]) {
    super(
      columns,
      store,
      {
        onRow: ({ indexPath, expand }) => ({
          className:
            expand === undefined
              ? cn.tableRowPlain
              : indexPath.length === 1
              ? cn.tableRowPrimary
              : cn.tableRowSecondary,
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );

    reaction(
      () => tree.selectedItems,
      (selectedForecasts) =>
        this.onTreeSelect(
          selectedForecasts.map((node) => node.item?.id).filter((id): id is number => id !== undefined)
        ),
      { fireImmediately: true }
    );
  }

  private onTreeSelect(selectedForecastsIds: number[]) {
    for (const column of this.columnsModel.store.rawColumns) {
      if (!column.key.startsWith("fc")) {
        continue;
      }
      const columnFcId = column.dataKey as number;
      if (selectedForecastsIds.includes(columnFcId)) {
        column.hidden = false;
      } else {
        column.hidden = true;
      }
    }
  }
}

export { CompareTableModel };
