import { Metric } from "services/finance/utils";
import { Range } from "utils/range";
import { req } from "utils/request";

import { formatSchema, ParamsResponse } from "./utils";

const getMetCoefsResolver = async (range: Range): Promise<{ met_coefs: Metric[] }> => {
  const { met_coefs } = await req.get<ParamsResponse<"met_coefs">>("demo/schemas/basic");
  return { met_coefs: formatSchema(met_coefs.schema, range) };
};

export { getMetCoefsResolver };
