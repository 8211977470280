import { action, computed, makeObservable, observable } from "mobx";

import { isPointInPolygon } from "../utils";

import { InfrastructureMapManager } from "./InfrastructureMapManager";

type SelectionPoint = {
  id: number;
  x: number;
  y: number;
};

type SelectionLines = {
  id: number;
  from: SelectionPoint;
  to: SelectionPoint;
  intermediatePoint: SelectionPoint;
};

type GrabPoints = {
  prevPoint: SelectionPoint;
  nextPoint: SelectionPoint;
};

class SelectionMethod {
  points: SelectionPoint[] = [];
  lines: SelectionLines[] = [];
  grabPoints?: GrabPoints;
  selectedNodeIds: string[] = [];
  selectedPipeIds: string[] = [];

  constructor(private readonly parent: InfrastructureMapManager) {
    makeObservable(this, {
      points: observable,
      lines: observable,
      grabPoints: observable,
      update: action,
      updateSelectionPointPosition: action,
      addIntermediatePoint: action,
      updateSelectionIntermediatePointPosition: action,
      deleteSelectionPoint: action,
      setGrabPoints: action,
      updateLines: action,
      reset: action,
      resetSelectedObects: action,
      isGrab: computed,
      selectedNodeIds: observable,
      selectedPipeIds: observable,
      setSelectedNodeIds: action,
      setSelectedPipeIds: action,
    });
  }

  private generateIntermediatePoint = (currentPoint: SelectionPoint, nextPoint: SelectionPoint, id: number) => ({
    id,
    x: (currentPoint.x + nextPoint.x) / 2,
    y: (currentPoint.y + nextPoint.y) / 2,
  });

  private findPointIndexById(id: number): number {
    return this.points.findIndex((point) => point.id === id);
  }

  private updatePointIds() {
    this.points = this.points.map((el, i) => ({ ...el, id: i + 1 }));
  }

  setGrabPoints = (grabPoints?: GrabPoints) => {
    if (grabPoints) {
      this.grabPoints = grabPoints;
    } else {
      this.grabPoints = { prevPoint: this.points.at(-1)!, nextPoint: this.points[0] };
    }
  };

  addIntermediatePoint = (point: { x: number; y: number; id: number }) => {
    this.points.splice(point.id - 1, 0, { id: point.id, x: point.x, y: point.y });
    this.updatePointIds();
  };

  update = (point: { x: number; y: number; id?: number }) => {
    const pointExists = this.points.some((el) => Math.abs(el.x - point.x) <= 0.05 && Math.abs(el.y - point.y) <= 0.05);
    if (pointExists) {
      return;
    }

    if (point.id) {
      this.addIntermediatePoint({ ...point, id: point.id });
    } else {
      this.points.push({
        id: this.points.length + 1,
        x: point.x,
        y: point.y,
      });
    }

    this.updateLines();
    this.setGrabPoints();
    this.setSelectionIds();
  };

  updateLines = () => {
    this.lines = this.points
      .reduce((acc, el, i, arr) => {
        if (arr[i + 1]) {
          acc.push({
            from: el,
            to: arr[i + 1],
            intermediatePoint: this.generateIntermediatePoint(el, arr[i + 1], el.id + 1),
          });
        }
        if (arr.length - 1 === i) {
          acc.push({ from: el, to: arr[0], intermediatePoint: this.generateIntermediatePoint(el, arr[0], el.id + 1) });
        }
        return acc;
      }, [] as { from: SelectionPoint; to: SelectionPoint; intermediatePoint: SelectionPoint }[])
      .map((el, i) => ({ ...el, id: i + 1 }));

    this.setGrabPoints();
  };

  get isGrab() {
    return !!this.points.length;
  }

  updateSelectionPointPosition = (point: { x: number; y: number; id: number }) => {
    const pointIndex = this.findPointIndexById(point.id);
    if (pointIndex === -1) {
      console.error("Не существующая точка");
      return;
    }
    this.points[pointIndex] = point;
    this.updateLines();
    this.setSelectionIds();
  };

  updateSelectionIntermediatePointPosition = (line: { x: number; y: number; id: number }) => {
    const lineIndex = this.lines.findIndex((el) => el.id === line.id);
    if (lineIndex === -1) {
      console.error("Не существующая линия");
      return;
    }
    this.lines[lineIndex] = {
      ...this.lines[lineIndex],
      intermediatePoint: { ...this.lines[lineIndex].intermediatePoint, x: line.x, y: line.y },
    };
    this.setSelectionIds();
  };

  setSelectedNodeIds = (nodeId: string | null, isMulti = true) => {
    if (nodeId === null) {
      this.selectedNodeIds = [];
      return;
    }
    const nodeIndx = this.selectedNodeIds.findIndex((id) => id === nodeId);
    if (nodeIndx !== -1) {
      this.selectedNodeIds.splice(nodeIndx, 1);
    } else {
      if (isMulti) {
        this.selectedNodeIds.push(nodeId);
      } else {
        this.resetSelectedObects();
        this.selectedNodeIds = [nodeId];
      }
    }
  };

  setSelectedPipeIds = (pipeId: string | null, isMulti = true) => {
    if (pipeId === null) {
      this.selectedPipeIds = [];
      return;
    }
    const pipeIndx = this.selectedPipeIds.findIndex((id) => id === pipeId);
    if (pipeIndx !== -1) {
      this.selectedPipeIds.splice(pipeIndx, 1);
    } else {
      if (isMulti) {
        this.selectedPipeIds.push(pipeId);
      } else {
        this.resetSelectedObects();
        this.selectedPipeIds = [pipeId];
      }
    }
  };

  setSelectionIds = () => {
    this.parent.nodes!.forEach((node) => {
      const alreadySelected = this.selectedNodeIds.includes(node.uuid);
      const isInPolygon = isPointInPolygon(node, this.points);
      if (((isInPolygon && !alreadySelected) || (!isInPolygon && alreadySelected)) && !node.isDisabled) {
        this.setSelectedNodeIds(node.uuid);
      }
    });

    this.parent.pipes!.forEach((pipe) => {
      const startNode = this.parent.nodes!.find((p) => p.uuid === pipe.from);
      const endNode = this.parent.nodes!.find((p) => p.uuid === pipe.to);
      const alreadySelected = this.selectedPipeIds.includes(pipe.uuid);
      const bothNodesInPolygon =
        startNode && endNode && isPointInPolygon(startNode, this.points) && isPointInPolygon(endNode, this.points);
      if ((bothNodesInPolygon && !alreadySelected) || (!bothNodesInPolygon && alreadySelected)) {
        this.setSelectedPipeIds(pipe.uuid);
      }
    });
  };

  reset = () => {
    this.points = [];
    this.lines = [];
  };

  resetSelectedObects = () => {
    this.setSelectedNodeIds(null);
    this.setSelectedPipeIds(null);
    this.reset();
  };

  deleteSelectionPoint = (id: number) => {
    const pointIndex = this.findPointIndexById(id);
    this.points.splice(pointIndex, 1);
    this.updateLines();
  };
}

export { type GrabPoints, SelectionMethod as Selection, type SelectionLines, type SelectionPoint };
