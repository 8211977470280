import { type FC, Fragment, useEffect, useRef } from "react";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";

import { Icon } from "elements/icon/icon";
import { MonthPicker } from "elements/inputs/monthPicker";

import { ReactComponent as ClockIcon } from "./clock.svg";

import cn from "./datePane.module.less";

type DatePaneProps = {
  start: Dayjs;
  end: Dayjs;
  onChange?: (date: Dayjs | null, dateString: string | string[]) => void;
  value?: Dayjs | null;
  className?: string;
};

const L_HEIGHT = 26;
const S_HEIGHT = 8;
const STEP = 8;
const TEXT_Y = L_HEIGHT - S_HEIGHT - 3;

const Grid: FC<Omit<DatePaneProps, "className">> = ({ start, end, onChange, value }) => {
  const length = end.diff(start, "month") + 1;
  const beginMonth = start.month();
  const startYear = start.year();
  const valueG = useRef<SVGGElement>(null);
  useEffect(() => {
    if (value && valueG.current) {
      const elem = valueG.current as any;
      if (elem.scrollIntoViewIfNeeded) {
        elem.scrollIntoViewIfNeeded();
      } else {
        const contRect = elem.parentElement.parentElement.getBoundingClientRect();
        const elemRect = elem.getBoundingClientRect();

        if (elemRect.right > contRect.right || elemRect.left < contRect.left) {
          elem.scrollIntoView({ inline: "center" });
        }
      }
    }
  }, [value]);
  return (
    <div className={cn.grid}>
      <svg className={cn.svg} style={{ width: 8 * length }}>
        {beginMonth && (
          <text y={TEXT_Y} textAnchor="middle" x={(6.5 - beginMonth) * STEP}>
            {startYear}
          </text>
        )}
        {new Array(length).fill(null).map((_, id) => {
          const currentMonth = (beginMonth + id) % 12;
          const isJan = currentMonth === 0;
          const currentYear = startYear + Math.floor((id + beginMonth) / 12);
          const isValue = value?.year() === currentYear && value?.month() === currentMonth;
          const onClick = () => {
            const result = dayjs().month(currentMonth).year(currentYear);
            onChange?.(result, result.format("MM.YYYY"));
          };
          return (
            <Fragment key={id}>
              {isJan && (
                <text y={TEXT_Y} textAnchor="middle" x={(id + 6.5) * STEP}>
                  {currentYear}
                </text>
              )}
              <g
                ref={isValue ? valueG : undefined}
                onClick={isValue ? undefined : onClick}
                className={classNames({ [cn.value]: isValue })}
                style={{ transform: `translateX(${id * STEP}px)` }}
              >
                <rect pointerEvents="visible" fill="none" width={STEP} height={L_HEIGHT}>
                  <title>{`${`0${currentMonth + 1}`.slice(-2)}.${currentYear}`}</title>
                </rect>
                <line x1={STEP / 2} y1={!isJan && !isValue ? L_HEIGHT - S_HEIGHT : 0} x2={STEP / 2} y2={L_HEIGHT} />
              </g>
            </Fragment>
          );
        })}
      </svg>
    </div>
  );
};

const DatePane: FC<DatePaneProps> = ({ className, ...props }) => {
  return (
    <div className={classNames(cn.layout, className)}>
      <MonthPicker {...props} />
      <Icon content={<ClockIcon />} />
      <Grid {...props} />
    </div>
  );
};

export { DatePane };
