import React, { useState } from "react";

import { LegendModel, LegendType } from "elements/charts/lineChart/LegendModel";

import cn from "../card.module.less";

const LegendItem = ({ item, legend }: { item: LegendType; legend: LegendModel }) => {
  const [fontWeight, setFontWeight] = useState(400);

  return (
    <div
      className={cn.legendItem}
      onMouseEnter={() => {
        legend.setActiveLabel(item.id);
        setFontWeight(700);
      }}
      onMouseLeave={() => {
        legend.setActiveLabel(null);
        setFontWeight(400);
      }}
      style={{ fontWeight: fontWeight, transition: "0.1s" }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        {typeof item.color === "number" ? (
          <circle cx="4" cy="4" r="4" fill={`url(#hatch-${item.color})`} />
        ) : (
          <circle cx="4" cy="4" r="4" fill={item.color} />
        )}
      </svg>
      <div style={{ pointerEvents: "none" }}>{item.title}</div>
    </div>
  );
};

export default LegendItem;
