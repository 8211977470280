import { global } from "models/global";
import type { Forecast } from "models/project/fact/forecast/forecast";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";
import { fillValues } from "./utils";

function investSchema(forecast: Forecast, regionKey: number) {
  const predef = CALCULATE_PREDEFINE.lz[0].schema_invest.schema;
  const valuesLength = forecast.wholeRange.length;
  const investState = forecast.fact.investParams.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());
  const investForecast = forecast.investParams.get(regionKey).table!.metrics!.flatMap((m) => m.flatten());
  const interventionTypes = Array.from(global.interventionsTypes.values!).map(({ title }) => title);
  const schema = [];
  for (const metric of predef) {
    if (metric.editable.not_editable === true || metric.unit.quantity === 0) {
      if (metric.title === "Год") {
        metric.values = [...forecast.wholeRange];
      }
      schema.push(metric);
      continue;
    }
    const stateMetric = investState.find((m) => m.title === metric.title);
    const forecastMetric = investForecast.find((m) => m.title === metric.title);
    let values = [];
    if (interventionTypes.includes(metric.title as any)) {
      if (metric.unit.quantity === 2) {
        values = fillValues(forecast, stateMetric, forecastMetric, metric);
      } else if (metric.unit.quantity === 12) {
        values = [
          ...forecast.fact.factRange.array.fill(null),
          ...(forecast.interventions.countByType(forecast.range, interventionTypes.indexOf(metric.title as any)) ??
            forecast.range.array.fill(null)),
        ].map((v) => v ?? 0); // TODO: fix (no map)
      } else {
        throw new Error(`Unknown reconstruction metric: ${metric}`);
      }
    } else if (stateMetric === undefined && forecastMetric === undefined) {
      throw new Error(`InvestMetric not found: ${metric.title}`);
    } else {
      values = fillValues(forecast, stateMetric, forecastMetric, metric);
    }
    if (values.length !== valuesLength) {
      throw new Error(`Metric incomplete (${values.length}/${valuesLength}): ${metric.title} ${values}`);
    }
    schema.push({ ...metric, values });
  }
  return { schema };
}

export { investSchema };
