import { useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";

import { Format } from "elements/format/format";
import { DRow as MiningDRow } from "features/injPrediction/resultsTableView/injectionSliceModel";

import { MiningTableModel } from "./miningTableModel";

import cn from "./miningTable.module.less";

const COLUMNS: ColumnRaw<any>[] = [
  {
    dataKey: "prodWellTitle",
    title: "№ скв.",
    width: 60,
    onHeaderCell: () => ({ className: cn.headerCell }),
  },
  {
    dataKey: "oilCoeff",
    title: "Коэф. нефти",
    width: 55,
    onHeaderCell: () => ({ className: cn.headerCell }),
    render: (value) => <Format>{value}</Format>,
  },
  {
    dataKey: "liquidRate",
    title: "Дебит жидкости, (м³/сут)",
    width: 121,
    onHeaderCell: () => ({ className: cn.headerCell }),
    render: (value) => value && <Format>{value}</Format>,
  },
  {
    dataKey: "oilRate",
    title: "Дебит нефти, (т/сут)",
    width: 98,
    onHeaderCell: () => ({ className: cn.headerCell }),
    render: (value) => value && <Format>{value}</Format>,
  },
];

const MiningTable = ({ wellPads }: { wellPads?: MiningDRow[] }) => {
  const store = useMemo(() => new MiningTableModel(wellPads), [wellPads]);
  const model = useMemo(
    () =>
      new TableModel(
        COLUMNS,
        store,
        {},
        {
          headerHeight: 39,
          rowHeight: 25,
          borderColor: "#f0f0f0",
        }
      ),
    [store]
  );
  return (
    <TableContextProvider value={model}>
      <Widget headerClassName={cn.tableHeader} className={cn.table} />
    </TableContextProvider>
  );
};

export { MiningTable };
