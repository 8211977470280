import { FC, useEffect } from "react";
import { Select, Typography } from "antd";
import { observer } from "mobx-react";

import { Loader } from "elements/loader";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { Param } from "../param";

import cn from "./typical.module.less";

const Typical: FC<{ mode: "oil" | "liquid" }> = observer(({ mode }) => {
  const model = useTechForecastModel();
  const selector = model.typeForecastSettings.selector(mode);
  const settings = model.currentForecast.settings[mode];
  useEffect(() => {
    if (Array.isArray(selector) && selector.length > 0 && settings.typicalUid === null) {
      settings.typicalHolder(selector[0].value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector]);
  return (
    <Loader className={cn.loader} spinning={selector === undefined}>
      <Param title="Типовая кривая">
        {selector !== undefined && selector.length === 0 ? (
          <Typography.Text>Для {{ oil: "нефти", liquid: "жидкости" }[mode]} типовые кривые не заданы</Typography.Text>
        ) : (
          <Select
            allowClear={false}
            loading={!Array.isArray(selector)}
            className={cn.select}
            size="small"
            value={settings.typicalUid}
            onChange={settings.typicalHolder}
            options={selector}
          />
        )}
      </Param>
    </Loader>
  );
});

export { Typical };
