import { FC } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import { useEditRow } from "features/useEditRow/useEditRow";

const EditRowButton: FC<{ row: any }> = ({ row }) => {
  const onEditRow = useEditRow(row);

  return (
    <Tooltip title="Ввод строки целиком">
      <Button onClick={onEditRow} type="text" icon={<EditOutlined />} />
    </Tooltip>
  );
};

export { EditRowButton };
