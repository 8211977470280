import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, override } from "mobx";

import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { InterventionParsedRaw, InterventionUnsaved } from "services/back/interventions";

import { Forecast } from "../forecast";

import { WellIntervention } from "./wellIntervention";

type DRow = {
  id?: number | null; // debug column
  wellId?: number | null; // debug column

  well: [id: number | null, title?: string];
  stratum: [id: number | null, title?: string];
  producingObject: [id: number | null, title?: string];
  gtmType: [id: number | null, title?: string];
  fond: string | null;
  oilRate: number | null;
  liquidRate: number | null;
  waterCut: number | null;
  recoverableResources: number | null;
  date: Dayjs | null;
  isRankingResultDate: boolean | null;
  isComplete: boolean;
  isDuplicatedIntervention: boolean;

  remove?: () => void;
};

class WellInterventions extends TableNode<DRow, WellIntervention> {
  constructor(
    public forecast: Forecast,
    public interventionsParsed?: InterventionParsedRaw[],
    public readonly modeModel?: ModeSelectorModel
  ) {
    super();

    makeObservable<WellInterventions, "reset">(this, {
      reset: action,
      propagateDuplicates: action,
      isUpdated: override,
      isComplete: computed,
    });
    this.reset(interventionsParsed);
  }

  public get isComplete(): boolean {
    return [...(this.children ?? [])]?.every(({ isComplete }) => isComplete) ?? false;
  }

  public get isValid(): boolean {
    if (!this.childrenStore) {
      return false;
    }
    if (this.childrenStore.size === 0) {
      return true;
    }
    return [...(this.children ?? [])].some(
      ({ isComplete, isDuplicatedIntervention }) => isComplete && !isDuplicatedIntervention
    );
  }

  private reset(interventionsParsed?: InterventionParsedRaw[]) {
    if (interventionsParsed !== undefined) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        interventionsParsed.map((data) => new WellIntervention(this, data))
      );
    } else {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.forecast.interventions.interventions.map(
          ({ data, producingObject }) =>
            new WellIntervention(this, {
              ...data,
              producingObjectId: producingObject?.id ?? undefined,
            })
        )
      );
    }

    this.propagateDuplicates();
  }

  public propagateDuplicates() {
    const existedInterventions = new Map<number, Set<number>>();

    const interventions = this.interventionsParsed ? this.forecast.interventions.interventions : [];
    for (const intervention of interventions) {
      const { wellId, gtmTypeId } = intervention.data;
      if (!wellId || !gtmTypeId) continue;

      let gtmTypeIdsSet = existedInterventions.get(wellId);
      if (gtmTypeIdsSet) {
        gtmTypeIdsSet.add(gtmTypeId);
      } else {
        gtmTypeIdsSet = new Set<number>();
        gtmTypeIdsSet.add(gtmTypeId);
        existedInterventions.set(wellId, gtmTypeIdsSet);
      }
    }

    for (const intervention of this.childrenStore?.children ?? []) {
      const { wellId, gtmTypeId } = intervention.data;

      if (!wellId || !gtmTypeId) {
        intervention.isDuplicatedIntervention = false;
        continue;
      }

      let gtmTypeIdsSet = existedInterventions.get(wellId);
      if (gtmTypeIdsSet && gtmTypeIdsSet.has(gtmTypeId)) {
        intervention.isDuplicatedIntervention = true;
      } else {
        intervention.isDuplicatedIntervention = false;
        if (!gtmTypeIdsSet) {
          gtmTypeIdsSet = new Set<number>();
          existedInterventions.set(wellId, gtmTypeIdsSet);
        }
        gtmTypeIdsSet.add(gtmTypeId);
      }
    }
  }

  public addEmpty = () => {
    const newIntervention = new WellIntervention(this, {});
    this.childrenStore?.push(newIntervention);
  };

  public importInterventions(interventions: InterventionUnsaved[], replace: boolean = false) {
    if (!this.childrenStore) {
      console.error("Импорт в неинициализированную таблицу");
      return;
    }
    const newInterventions = interventions.map((data) => new WellIntervention(this, data));
    if (replace) {
      this.childrenStore.splice(0, this.childrenStore.size, ...newInterventions);
    } else {
      this.childrenStore.push(...newInterventions);
    }
  }

  public get newRows(): InterventionUnsaved[] {
    return [...(this.children ?? [])]
      .filter((intervention) => intervention.isComplete && intervention.data.wellId !== null)
      .map((intervention) => intervention.data as InterventionUnsaved);
  }

  public submit = async () => {
    const updatedInterventions: InterventionUnsaved[] = [];
    const createdInterventions: InterventionUnsaved[] = [];
    const hasId = (interventionId: number | undefined): interventionId is number => {
      const hasId = interventionId !== undefined;
      console.assert(hasId, "гтм без id среди удалённых");
      return hasId;
    };
    const deletedIds: number[] = [...this.childrenStore!.deletedChildren].map((c) => c.data.id).filter(hasId);

    this.mutatedChildren!.forEach((interventionNode) => {
      const raw = interventionNode.toInterventionUnsaved;
      if (!!raw && !this.addedChildren!.has(interventionNode)) {
        updatedInterventions.push(raw);
      }
    });
    this.addedChildren!.forEach(({ toInterventionUnsaved }) => {
      toInterventionUnsaved && createdInterventions.push(toInterventionUnsaved);
    });

    return this.forecast.interventions
      .update({ updatedInterventions, createdInterventions, deletedIds })
      .then(() => this.reset());
  };
}

export type { DRow };
export { WellInterventions };
