import { FC } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";

import { Tree } from "elements/tree/tree";
import { TreeContextProvider } from "models/tree/context";

import { useProjectsContext } from "./projectsContextProvider";

import cn from "./tppList.module.less";

const TppList: FC = observer(() => {
  const fields = useProjectsContext();

  return (
    <TreeContextProvider value={fields.tree}>
      <div className={cn["tpp-list"]}>
        <Tree
          className={cn.tree}
          title="Список ТПП"
          preloadHooks={[]}
          onRow={(v) => ({
            onClick: () =>
              runInAction(() => {
                if (!v?.childrenStore) {
                  fields.currentFieldId = v!.item.id;
                }
              }),
            className: v?.item.id === fields.currentFieldId && !v?.childrenStore ? cn["selected-row"] : cn.row,
          })}
        />
      </div>
    </TreeContextProvider>
  );
});

export { TppList };
