import { type FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { Table } from "@okopok/components/Table/Table";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react";
import { useForecastCopyDialog } from "routing/outlines/secondaryMenu/useForecastCopyDialog";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { Loader } from "elements/loader";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useProject } from "models/project/project";

import { ReactComponent as CopyIcon } from "./icons/copy.svg";
import { ReactComponent as NoWells } from "./icons/noWells.svg";
import { ScenariosListModel } from "./scenariosListModel";
import { StatusComponent } from "./statusCard";

import cn from "./scenariosList.module.less";

interface ScenariosListProps {
  data: ScenariosListModel;
  onNavigate: (key: string, postfix: string) => void;
}

const ScenariosList: FC<ScenariosListProps> = observer(({ data, onNavigate }) => {
  const project = useProject();
  const onCopy = useForecastCopyDialog();

  const columns = useMemo<Column[]>(
    () => [
      {
        title: "",
        dataKey: "noNewWells",
        key: "noNewWells",
        type: "string",
        width: 32,
        hideFilter: true,
      },
      {
        title: "Название сценария",
        dataKey: "title",
        key: "scenarioTitle",
        type: "string",
        width: { min: 200, max: 2000, competitiveness: 2 },
      },
      {
        title: "Создан",
        dataKey: "date",
        type: "date",
        renderFormat: "moment",
        width: { min: 200, max: 2200, competitiveness: 1 },
      },
      {
        title: "Срок планирования",
        dataKey: "end",
        type: "date",
        renderFormat: "year",
        width: { min: 100, max: 1400, competitiveness: 1 },
      },
      {
        title: "Скважин НФ",
        dataKey: "wellsCount",
        key: "wellsCount",
        type: "number",
        width: { min: 120, max: 140, competitiveness: 1 },
      },
      {
        title: "Инвест.ГТМ",
        dataKey: "gtmsCount",
        key: "gtmsCount",
        type: "number",
        width: { min: 120, max: 140, competitiveness: 1 },
      },
      {
        title: "Технологические показатели",
        dataKey: "techStatus",
        key: "techStatus",
        type: "string",
        width: { min: 150, max: 150, competitiveness: 1 },
      },
      {
        title: "Экономические показатели",
        dataKey: "economicStatus",
        key: "economicStatus",
        type: "string",
        width: { min: 150, max: 150, competitiveness: 1 },
      },
      {
        title: "Ресурсно-календарное планирование",
        dataKey: "rankingStatus",
        key: "rankingStatus",
        type: "string",
        width: { min: 150, max: 150, competitiveness: 1 },
      },
      {
        title: "Инфраструктура",
        dataKey: "infrastructureStatus",
        key: "infrastructureStatus",
        type: "string",
        width: { min: 150, max: 150, competitiveness: 1 },
      },
      {
        title: "",
        dataKey: "customActions",
        key: "customActions",
        type: "string",
        width: { min: 52, max: 52, competitiveness: 1 },
        hideFilter: true,
      },
    ],
    []
  );

  return (
    <SimpleTableContext
      exportFileName="Перечень сценариев"
      columns={columns}
      data={data}
      hideExpandColumn
      customRenders={{
        customActions: (value, row) => {
          return (
            <div className={cn.actions}>
              <Tooltip title="Копировать">
                <Button className={cn["copy-button"]} onClick={onCopy(row.value.id)} icon={<CopyIcon />} size="small" type="text" />
              </Tooltip>
              <DeleteButton className={cn.delete} tooltip="Удалить" {...row.value.removeConfirm} onClick={row.value.removeRow} />
            </div>
          );
        },
        scenarioTitle: (value, row) => {
          return (
            <Link to={`/${project?.id}/${row.value.id}/wells`} className={cn.link}>
              <Format ellipsisLimit={28}>{row.value.title}</Format>
            </Link>
          );
        },
        noNewWells: (value, row) => {
          const hasNoWells = row.value.hasNoWells;

          if (row.value.wells.isLoading) {
            return (
              <div className={cn.loader}>
                <Loader />
              </div>
            );
          }

          if (!hasNoWells) {
            return <></>;
          }

          return (
            <Tooltip title="Отсутствуют скважины нового фонда">
              <>
                <Icon content={<NoWells />} viewBox="0 0 16 16" className={cn.noWells} />
              </>
            </Tooltip>
          );
        },
        wellsCount: (value, row) => {
          if (row.value.wells.isLoading) {
            return (
              <div className={cn.loader}>
                <Loader />
              </div>
            );
          }

          return <Format>{row.value.wellsCount}</Format>;
        },
        gtmsCount: (value, row) => {
          if (row.value.interventions.isLoading) {
            return (
              <div className={cn.loader}>
                <Loader />
              </div>
            );
          }

          return <Format>{row.value.gtmsCount}</Format>;
        },
        techStatus: (value, row) => <StatusComponent status={row.value.techStatus} />,
        economicStatus: (value, row) => <StatusComponent status={row.value.economicStatus} />,
        rankingStatus: (value, row) => <StatusComponent status={row.value.rankingStatus} />,
        infrastructureStatus: (value, row) => <StatusComponent status={row.value.infrastructureStatus} />,
      }}
      tableOptions={{
        onRow: (v, index) => ({
          className: index === data?.currentIndex ? cn["selected-row"] : cn["row"],
          onClick: () => {
            data.currentIndex = index;
            data.selectScenario(v.value.id);
          },
          onDoubleClick: () => {
            onNavigate(v.value.key, "wells");
          },
        }),
      }}
    >
      <div className={cn.tableWrap}>
        <Table headerClassName={cn.tableHeader} className={cn.table} />
      </div>
    </SimpleTableContext>
  );
});

export { ScenariosList };
