import styles from "./columnsWidget.module.less";

const Header = () => {
  return (
    <div className={styles.headerContainer}>
      <span>Настройка таблицы</span>
    </div>
  );
};

export { Header };
