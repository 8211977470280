import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { axisWidth, colorPrimary } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";

const YearLine = observer(({ year }: { year: number }) => {
  const model = useD3Context();

  const refLine = useRef<SVGLineElement | null>(null);
  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
    d3.select(refLine.current)
      .attr("x1", xScale(new Date(year, 0, 1)))
      .attr("x2", xScale(new Date(year, 0, 1)))
      .attr("y1", model.padding.top)
      .attr("y2", model.height - model.padding.top)
      .style("stroke", colorPrimary);
  }, [
    model.currentZoomState,
    model.height,
    model.leftAxisCount,
    model.padding.left,
    model.padding.right,
    model.padding.top,
    model.rightAxisCount,
    model.width,
    model.xAxis.domain.max,
    model.xAxis.domain.min,
    year,
  ]);
  return <line clipPath={`url(#clipPath-${model.svgClassName})`} ref={refLine}></line>;
});

const YearLines = observer(() => {
  const model = useD3Context();
  const years = [];
  for (let i = model.xAxis.domain.min.getFullYear(); i <= model.xAxis.domain.max.getFullYear(); ++i) {
    years.push(i);
  }

  return (
    <g>
      {years.map((year, index) => {
        return <YearLine key={index} year={year}></YearLine>;
      })}
    </g>
  );
});

export default YearLines;
