import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { TitulReport as TitulReportFeature } from "features/report/titul/titulReport";
import { useFact } from "models/project/fact/fact";

const TitulReport: FC = observer(() => {
  return (
    <Preloader hooks={[useFact]}>
      <PageFrame title="Титульный лист">
        <TitulReportFeature />
      </PageFrame>
    </Preloader>
  );
});

export { TitulReport };
