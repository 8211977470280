import { useMatches } from "react-router-dom";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Forecast } from "models/project/fact/forecast/forecast";

const useToForecast: () => (f: Forecast) => string = () => {
  const { project, scenario } = useMainRouterParams();
  const matches = useMatches();
  const match = matches.at(-1)!;
  const path = match.pathname.split("/");
  const page = path.slice(scenario === undefined ? 2 : 3).join("/");
  return (f: Forecast) => {
    return ["", project, f.id, page].join("/");
  };
};

export { useToForecast };
