import { FC } from "react";
import { Button, Checkbox, Tooltip, Typography } from "antd";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { plural } from "utils/plural";

import { ReactComponent as AllChecks } from "../../../icons/allChecks.svg";
import { ReactComponent as CheckList } from "../../../icons/checkList.svg";
import { ValidationInput } from "../../legacyInputs/validationInput";

import { useAnalogSelector } from "./useWellsAnalogSelector";

import cn from "./analog.module.less";

const WellsAnalog: FC<{ mode: "oil" | "liquid" }> = observer(({ mode }) => {
  console.assert(
    mode === "oil" || mode === "liquid",
    `Для скважин-аналогов система построения запроса реализована только для нефти и жидкости ${mode}`
  );
  const model = useTechForecastModel();
  const { analogs } = model.currentForecast.settings[mode];
  const { amount, selectedAmount } = analogs;
  const selectAnalogs = useAnalogSelector(analogs);
  return (
    <>
      <div className={cn.buttons}>
        <Button
          disabled={analogs.isChanged || analogs.isLoading}
          icon={<Icon viewBox="0 0 16 16" content={<CheckList />} />}
          size="small"
          onClick={selectAnalogs}
        >
          Выбрать аналоги
        </Button>
        <Button
          icon={<Icon viewBox="0 0 16 16" content={<AllChecks />} />}
          size="small"
          onClick={analogs.selectAllHolder}
          disabled={amount === selectedAmount}
        >
          Прогноз по всем аналогам
        </Button>
      </div>
      <Typography.Text className={cn.amount}>
        {analogs.isLoading ? (
          <>Выполняется расчет</>
        ) : analogs.isValid === false ? (
          <>
            {analogs.prodMonthDuration === null
              ? "Ограничение длительности работы скважин обязательно для заполнения"
              : "Темп падения заполнен с ошибками"}
          </>
        ) : analogs.isChanged ? (
          <>Требуется провести подбор аналогов</>
        ) : (
          <>
            Выбрано <Format>{analogs.selectedAmount}</Format> из <Format>{analogs.amount}</Format>{" "}
            {plural(analogs.amount ?? 0, ["скважины", "скважин", "скважин"])} аналогов
          </>
        )}
      </Typography.Text>
      <div className={cn.grid}>
        <Typography.Text className={cn.durationTitle}>Минимальная длительность работы скважины на объекте разработки, мес</Typography.Text>
        <ValidationInput value={analogs.prodMonthDuration} onSave={analogs.holder("prodMonthDuration")} min={1} max={999} />
        <Typography.Text>
          Учитывать темп падения q<sub>н</sub>
        </Typography.Text>
        <ValidationInput
          placeholder="от (%/мес)"
          value={analogs.oilRatio}
          onSave={analogs.holder("oilRatio")}
          min={-1000}
          max={analogs.oilRatioDiv ?? 999}
        />
        <ValidationInput
          placeholder="до (%/мес)"
          value={analogs.oilRatioDiv}
          onSave={analogs.holder("oilRatioDiv")}
          min={analogs.oilRatio ?? -1000}
          max={999}
        />
        <Typography.Text>
          Учитывать темп падения q<sub>ж</sub>
        </Typography.Text>
        <ValidationInput
          placeholder="от (%/мес)"
          value={analogs.liquidRatio}
          onSave={analogs.holder("liquidRatio")}
          min={-1000}
          max={analogs.liquidRatioDiv ?? 999}
        />
        <ValidationInput
          placeholder="до (%/мес)"
          value={analogs.liquidRatioDiv}
          onSave={analogs.holder("liquidRatioDiv")}
          min={analogs.liquidRatio ?? -1000}
          max={999}
        />
      </div>
      <Checkbox checked={analogs.isApplyConstruction} onChange={({ target }) => analogs.applyConstructionHolder(target.checked)}>
        Учитывать тип конструкции скважины ({model.currentSelection.well.type})
      </Checkbox>
      <Tooltip
        title={
          analogs.isChanged === false ? "Входные параметры не изменены" : analogs.isValid === false ? "Параметры заполнены не полностью" : undefined
        }
      >
        <Button loading={analogs.isLoading} disabled={!analogs.isChanged || !analogs.isValid} size="small" onClick={analogs.requestImmediately}>
          Провести подбор
        </Button>
      </Tooltip>
    </>
  );
});

export { WellsAnalog };
