import type { FC } from "react";
import { useChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { BarInfo } from "../stackedBars/barDataModel";

import { Bar } from "./bar";

type RowBarProps = {
  y: BarInfo[];
  x: number;
  axisKey: string;
};
const getBarWidth = (yearLength: number) => {
  return Math.min(40, (2 * yearLength) / 3);
};
const RowBar: FC<RowBarProps> = observer(({ y, x, axisKey }) => {
  const axes = useChartContext();
  if (axes.isNotReady || !axes.isShapeReady) {
    return null;
  }
  const barsToShow = y.filter((bar) => bar.value > 0);
  const yScale = axes.at(axisKey)?.scale;
  const xScale = axes.axisX.scale;
  const yearWidth = axes.yearWidth;
  if (yScale === undefined) {
    console.error("Не определён масштаб для отображаемой кривой");
    return null;
  }
  const gap = barsToShow.length > 1 ? 2 : 0;
  const barWidth = (getBarWidth(yearWidth) - gap * (y.length - 1)) / y.length;
  return (
    <>
      {barsToShow.map(
        (info, index) =>
          info.value > 0 && (
            <Bar
              key={index}
              x={xScale(x) - (barsToShow.length * (barWidth + gap) - gap) / 2 + (gap + barWidth) * index}
              y={yScale(info.value)}
              width={barWidth}
              zeroLevel={yScale(0)}
              color={info.color}
              limit={info.limit !== undefined ? yScale(info.limit) : undefined}
            />
          )
      )}
    </>
  );
});

export { RowBar };
