import { ReactNode } from "react";
import { InputNumber as InputAntd, type InputNumberProps as AntdInputNumberProps } from "antd";

import cn from "./inputNumber.module.less";

const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
  e.currentTarget.blur();
};
const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.code === "ArrowDown" || e.code === "ArrowUp") {
    e.preventDefault();
  }
};

type InputNumberProps = AntdInputNumberProps<number> & {
  value?: number | null;
  addonAfter?: ReactNode;
  onUpdate?: (value: number | null) => void;
  className?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  bordered?: boolean;
  status?: "" | "warning" | "error";
  placeholder?: string;
};

const InputNumber = ({ onUpdate, className, status, placeholder, bordered = false, ...props }: InputNumberProps) => {
  const classNames = `${cn.inputNumber} ${className}`;

  return (
    <InputAntd
      {...props}
      placeholder={placeholder}
      status={status}
      variant={bordered || !!status ? "outlined" : "borderless"}
      className={classNames}
      onChange={onUpdate}
      type="number"
      onWheel={onWheel}
      onKeyDown={onKeyDown}
    />
  );
};

export { InputNumber, type InputNumberProps };
