import { reqCamel } from "utils/request";

type WellReq = {
  wellId: number;
  stratumId: number;
  scenarioId: number;
};

type Production = {
  producingObject: {
    title: string;
    projectId: number;
    densityOil: number;
    densityWater: number;
    viscosityOil: number;
    viscosityWater: number;
    gasOilRatio: number;
    stratumIds: number[];
    mainStratumId: number;
  };
  defaultEndStep: number;
  defaultStartStep: number;
  recoverableResources: number;
  dates: string[];
  liquidProdM3: number[];
  oilProdT: number[];
  waterProdT: number[];
  waterProdM3: number[];
  apgProdM3: number[];
  prodTimeRatio: number[];
  oilProdM3: number[];
  months: number[];
  years: number[];
  steps: number[];
  waterCutVol: number[];
  waterCutMass: number[];
  lnVnfVol: number[];
  accumOilProdT: number[];
  recoveryRate: number[];
  accumLiquidProdM3: number[];
  densityOil: number[];
  daysInMonth: number[];
  oilRateT: number[];
  liquidRateM3: number[];
  normalizedOilRateT: number[];
};

type WellFactProduction = {
  wellId: number;
  stratumId: number | null;
  scenarioId: number;
  wellTypeId: number;
  gtmId: number | null;
  forecastProduction?: Production;
  factProduction: Production;
};

const getTechFact = async (req: WellReq[]): Promise<WellFactProduction[]> =>
  reqCamel.post("decline-new/fact-production", req) as any as Promise<WellFactProduction[]>;

export { getTechFact, type Production, type WellFactProduction, type WellReq };
