import { FC } from "react";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Expand } from "@okopok/components/Table";
import { Button } from "antd";

const ExpandButton: FC<{ expand: Expand | undefined }> = ({ expand }) => {
  if (expand === undefined) {
    return null;
  }
  return <Button icon={expand.status ? <CaretDownOutlined /> : <CaretRightOutlined />} size="small" type="link" onClick={expand.toggleExpand} />;
};

export { ExpandButton };
