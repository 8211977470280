type PatternType = {
  color: string;
  angel: number;
};

const style: PatternType[] = [
  { color: "rgba(171, 243, 248, 1)", angel: 45 },
  { color: "rgba(192, 213, 255, 1)", angel: -45 },
];

const Patterns = () => {
  return (
    <g>
      {style.map((pattern, id) => (
        <pattern id={`hatch-${id}`} patternUnits="userSpaceOnUse" width="4" height="4" key={id}>
          <rect width="4" height="4" x="0" y="0" fill={pattern.color}></rect>
          <path
            d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
            style={{ stroke: "black", strokeWidth: 1 }}
          />
        </pattern>
      ))}
    </g>
  );
};

export default Patterns;
