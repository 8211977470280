import classNames from "classnames";

import { Format } from "elements/format/format";
import { InputNumber, type InputNumberProps } from "elements/inputs/inputNumber/inputNumber";
import { useBooleanState } from "utils/useBooleanState";

import cn from "./lazyInputNumber.module.less";

type LazyInputNumberProps = Omit<InputNumberProps, "onBlur" | "onChange" | "autoFocus" | "onPressEnter">;

const LazyInputNumber = ({ value, className, ...props }: LazyInputNumberProps) => {
  const [editing, makeEditable, makePlain] = useBooleanState(false);

  if (editing) {
    return <InputNumber {...props} value={value} autoFocus className={classNames(cn.input, className)} onBlur={makePlain} onPressEnter={makePlain} />;
  }

  return (
    <div tabIndex={0} className={classNames(cn.placeholder)} onClick={makeEditable} onFocus={makeEditable}>
      <span className={cn.editable}>
        <Format>{value}</Format>
      </span>
    </div>
  );
};

export { LazyInputNumber, type LazyInputNumberProps };
