import type { Forecast } from "models/project/fact/forecast/forecast";

const reconstruction = (data: Forecast) => {
  console.assert(data.wells.isLoading === false);
  console.assert(data.interventions.isLoading === false);
  return data.interventions.safelyData!.map((intervention) => ({
    schema: [
      {
        id: 0,
        title: "Скважина/участок",
        code_title: "name",
        unit: {
          quantity: 0,
          measure: 0,
        },
        values: [intervention.well?.title],
      },
      {
        id: 1,
        title: "Тип ГТМ",
        code_title: "type",
        unit: {
          quantity: 0,
          measure: 0,
        },
        values: [intervention.typeName],
      },
      {
        id: 2,
        title: "Год проведения ГТМ",
        code_title: "Год проведения",
        unit: {
          quantity: 4,
          measure: 2,
        },
        values: [intervention.date!.year()],
      },
      {
        id: 3,
        title: "Залежь до ГТМ",
        code_title: "Залежь до ГТМ",
        unit: {
          quantity: 0,
          measure: 0,
        },
        values: [`Залежь ${intervention.stratumBeforeId}`],
      },
      {
        id: 4,
        title: "Залежь после ГТМ",
        code_title: "Залежь после ГТМ",
        unit: {
          quantity: 0,
          measure: 0,
        },
        values: [`Залежь ${intervention.data.stratumId}`],
      },
    ],
  }));
};

export { reconstruction };
