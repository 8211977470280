import type { Params, ParamsServices } from "models/params/params";
import { ParamsMap } from "models/params/paramsMap";
import { StorageKey } from "services/finance/utils";
import type { Range } from "utils/range";

import { Forecast } from "./forecast/forecast";
import { Fact } from "./fact";

class LicenseRegionsParamsMap<T extends Params> extends ParamsMap<T> {
  constructor(
    ParamsModel: new (range: Range, paramsServices: ParamsServices) => T,
    paramsServices: (storageKey: StorageKey) => ParamsServices,
    fact: Fact,
    forecast: Forecast | null = null
  ) {
    const storageKey = (forecast ?? fact).storageKey;
    const range = forecast?.range ?? fact.factRange;
    super(
      fact.licenseRegions.ids,
      (licenseRegionKey) => new ParamsModel(range, paramsServices({ ...storageKey, suffix: `${licenseRegionKey}` }))
    );
  }
}

export { LicenseRegionsParamsMap };
