import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { useForecast } from "models/project/fact/forecast/forecast";

import { WellInterventionsAmountChart } from "./wellInterventionsAmountChart";
import { WellInterventionsAmountChartModel } from "./wellInterventionsAmountChartModel";

const WellInterventionsAmountChartFeature = observer(() => {
  const forecast = useForecast()!;
  const chartModel = useMemo(() => {
    return new WellInterventionsAmountChartModel(forecast);
  }, [forecast]);
  return (
    <div style={{ display: "flex", flex: 1, height: "100%" }}>
      <WellInterventionsAmountChart chartModel={chartModel} />
    </div>
  );
});

export { WellInterventionsAmountChartFeature };
