import ReactJson from "react-json-view";
import { Typography } from "antd";
import { observer } from "mobx-react";
import { ResultPage, useResultModel } from "routing/outlines/result/resultPage";

import { Format } from "elements/format/format";
import { UOM } from "elements/uom";
import { global } from "models/global";

const TRANSLATE: Record<string, string> = {
  schema_operation: "Операционная деятельность",
  schema_invest: "Инвестиционная деятельность",
  schema_usc: "ЕСУ",
  schema_cashflow: "Денежный поток",
  schema_well_production: "Поскважинка",
};

const HIDDEN_FIELDS_SET = new Set([
  "schemaOperation",
  "schemaInvest",
  "schemaUSC",
  "schemaCashflow",
  "result",
  "schema_well_production",
  "schemaWells",
]);

const valToPair = ({ title, values, unit }: any) => [`${title} [${new UOM(unit.quantity, unit.measure, global.uomResolver).unit}]`, values];

const simplify = (data: any) => {
  let result: any = Object.fromEntries(
    data.result.schema.map(valToPair).map(([k, v]: any) => [
      k,
      (() => {
        if (Array.isArray(v)) {
          return v.length === 1 ? v[0] : v;
        }
        return v;
      })(),
    ])
  );

  for (const [key, val] of Object.entries(data) as any[]) {
    if (typeof val === "object" && "schema" in val) {
      result[TRANSLATE[key] ?? key] = Object.fromEntries(val.schema.map(valToPair));
    } else if (key === "schema_well_production") {
      result[key] = val;
    } else {
      console.assert(HIDDEN_FIELDS_SET.has(key), `Неизвестное поле результата ${key}`);
    }
  }
  delete result.result;
  return result;
};

const DebugResult = () => {
  const result = useResultModel();
  return (
    <>
      <Typography.Title>Результаты расчета</Typography.Title>
      <Typography.Paragraph>
        <Typography.Text>
          Время выполнения расчета: <Format>{result.delay / 1000}</Format> сек.
        </Typography.Text>
      </Typography.Paragraph>
      {Object.entries(simplify(result.result)).map(([key, val]) => {
        if (typeof val !== "object" && val !== undefined) {
          return (
            <Typography.Paragraph key={key}>
              <Typography.Text>
                {key}: <Format>{val as any}</Format>
              </Typography.Text>
            </Typography.Paragraph>
          );
        }
        return <ReactJson key={key} name={key} quotesOnKeys={false} collapsed displayDataTypes={false} src={val!} />;
      })}
      {process.env.NODE_ENV === "development" &&
        (result.unchanged.length > 0 ? (
          <>
            <Typography.Title level={2}>Суррогатные объекты расчета</Typography.Title>
            <ul>
              {result.unchanged.map((t) => (
                <li key={t}>{t}</li>
              ))}
            </ul>
            {result.changed.length > 0 && (
              <>
                <Typography.Title level={2}>Объекты расчета из интерфейса</Typography.Title>
                <ul>
                  {result.changed.map((t) => (
                    <li key={t}>{t}</li>
                  ))}
                </ul>
              </>
            )}
          </>
        ) : (
          <Typography.Title level={2}>Все данные, используемые в расчете получены из интерфейса</Typography.Title>
        ))}
    </>
  );
};

const DebugResultsPage = observer(() => {
  return (
    <ResultPage>
      <DebugResult key="any key" />
    </ResultPage>
  );
});

export { DebugResultsPage, simplify };
