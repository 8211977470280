import { getDepositsResolver } from "services/back/deposits";
import { getStratums } from "services/back/stratums";
import { Range } from "utils/range";

import { getUniversalDataSource, riseMetrics } from "./utils";

const depositPredicate = ({ title }: { title: string }) => title.startsWith("Начальные извлекаемые запасы нефти АВ1+");
const PREFIX = "Начальные извлекаемые запасы нефти АВ1+B2 ";
const POSTFIX = " на начало года";
const KEY_POSTFIX = " (на нач. г.)";
const depositKeyFromTitle = (title: string) => title.slice(PREFIX.length, title.length - POSTFIX.length);

const getDeposits = async (range: Range) => {
  try {
    const [{ schema_operation }, stratums] = await Promise.all([getDepositsResolver(range), getStratums()]);
    const metricSchema = riseMetrics(schema_operation);
    const { children } = metricSchema.find(({ code_title }) => code_title === "Начальные извлекаемые запасы")!;
    return {
      table: [
        ...stratums.map(({ title }, id) => ({
          code_title: `${PREFIX}${title}${KEY_POSTFIX}`,
          key: 17400 + id,
          title: `${PREFIX}${title}${POSTFIX}`,
          unit: { quantity: 5, measure: 5 },
          values: range.array.fill(0),
          children: null as any as [],
        })),
        ...children.filter((v) => !depositPredicate(v)),
      ],
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const [depositsFactSource, depositsForecastSource] = getUniversalDataSource([
  { key: "DepositsFact4", fn: getDeposits },
  { key: "Deposits4", fn: getDeposits },
]);

export { depositKeyFromTitle, depositPredicate, depositsFactSource, depositsForecastSource };
