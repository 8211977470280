import React, { useContext } from "react";
import { action, makeObservable, observable } from "mobx";

type LegendType = {
  color: string | number;
  title: string;
  id: number;
  opacity: number;
};

const LegendContext = React.createContext<LegendModel | null>(null);

const useLegendContext = () => {
  const context = useContext(LegendContext);

  return context;
};

class LegendModel {
  legend: LegendType[] | null;
  activeLabel: number | null;
  constructor() {
    makeObservable(this, {
      legend: observable,
      setLegend: action,
      setActiveLabel: action,
      activeLabel: observable,
    });
    this.legend = null;
    this.activeLabel = null;
  }

  public setLegend = (newLegend: LegendType[]) => {
    this.legend = newLegend;
  };

  public setActiveLabel = (id: number | null) => {
    this.activeLabel = id;
    if (this.legend !== null) {
      if (id !== null) {
        for (let i = 0; i < this.legend.length; ++i) {
          this.legend[i].opacity = 0.5;
          if (this.legend[i].id === id) {
            this.legend[i].opacity = 1;
          }
        }
      } else {
        for (let i = 0; i < this.legend.length; ++i) {
          this.legend[i].opacity = 1;
        }
      }
    }
  };
}
export { LegendModel };
export { LegendContext, useLegendContext };
export type { LegendType };
