import React, { FC } from "react";
import { useChartContext } from "@okopok/axes_context";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react";

import cn from "./yearLabels.module.less";
type YearLabelsProps = {
  topLabels: Map<Dayjs, string> | undefined;
  bottomLabels: Map<Dayjs, string> | undefined;
};
const YearLabels: FC<YearLabelsProps> = observer(({ topLabels, bottomLabels }) => {
  const axes = useChartContext();
  if (axes.isNotReady || !axes.isShapeReady) {
    return null;
  }
  const xScale = axes.axisX.scale;
  return (
    <>
      <g className={cn.labels}>
        {topLabels &&
          Array.from(topLabels.entries()).map(([year, label], index) => (
            <text className={cn.label} key={index} x={xScale(year) + axes.yearWidth / 2} y={axes.marginTop + 10}>
              {label}
            </text>
          ))}
        {bottomLabels &&
          Array.from(bottomLabels.entries()).map(([year, label], index) => (
            <text className={cn.label} key={index} x={xScale(year) + axes.yearWidth / 2} y={axes.height - axes.marginTop - 10}>
              {label}
            </text>
          ))}
      </g>

      <g>
        {topLabels &&
          Array.from(topLabels.entries()).map(([year, label], index) => (
            <line className={cn.line} key={index} x1={xScale(year)} x2={xScale(year)} y1={0} y2={axes.height} />
          ))}
      </g>
    </>
  );
});

export { YearLabels };
