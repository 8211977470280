import { global } from "models/global";
import { LoadableStoreResolver } from "models/loadableStore/loadableStoreResolver";
import type { StratumRaw } from "services/back/stratums";
import type { Metric } from "services/finance/utils";

import { ProducingObject } from "../producingObject/producingObject";
import { Project } from "../project";

import { Stratum } from "./stratum";

const toRawStratums = (data: Record<string, string>): StratumRaw[] => {
  const result = new Map<number, { id: number; title?: string; htrType?: string; depletion?: number }>();
  for (const [inputKey, val] of Object.entries(data)) {
    const pos = inputKey.match(/\d+$/)?.index;
    console.assert(pos !== undefined, "Попытка разбора в объекты депозита неправильного исходного объекта");
    const id = parseInt(inputKey.slice(pos!), 10);
    const field = inputKey.slice(0, pos!) as "title" | "htrType";
    console.assert(["title", "htrType", "depletion"].includes(field), `Неизвестное поле в объекте ${field}`);
    if (!result.has(id)) {
      result.set(id, { id });
    }
    result.get(id)![field] = val;
  }
  for (const item of result.values()) {
    console.assert(
      item.title !== undefined && item.htrType !== undefined && item.depletion !== undefined,
      "Не полное описание объекта",
      item
    );
  }
  return [...result.values()] as StratumRaw[];
};

class Stratums extends LoadableStoreResolver<Stratum, StratumRaw> {
  constructor(private readonly project: Project, ids: number[]) {
    super(global.stratums, ids);
  }

  public getProducingObject(stratum: Stratum): ProducingObject | null | undefined {
    return this.project.producingObjects.find((prodObj) => prodObj.hasStratumWithId(stratum.id));
  }

  get length(): number {
    return this.ids.length;
  }

  get formInitialValues() {
    console.assert(!this.store.isLoading, "Открытие формы до завершения загрузки формы");
    return Object.fromEntries(
      this.items
        ?.map(({ id, title, htrType, depletion }) => [
          [`title${id}`, title],
          [`htrType${id}`, htrType],
          [`depletion${id}`, depletion],
        ])
        .flat() ?? []
    );
  }

  async update(data: Record<string, string>): Promise<void> {
    this.store.reset(toRawStratums(data));
  }

  isValid(data: Record<string, string>): boolean {
    const usedNames = new Set<string>();

    for (const datum of toRawStratums(data)) {
      if (datum.title.length === 0) {
        return false;
      }
      if (usedNames.has(datum.title)) {
        return false;
      }
      usedNames.add(datum.title);
    }
    return true;
  }

  get depletion(): Metric[] {
    console.assert(!this.store.isLoading, "Запрошена истощенность депозитов до завершения загрузки");
    return this.items!.map(({ id, depletion }) => ({
      key: id,
      title: `Выработанность по Залежь ${id} на 2012 год`,
      parent: null,
      unit: {
        quantity: 3,
        measure: 2,
      },
      values: [depletion],
    }));
  }

  isUpdated(data: Record<string, string>): boolean {
    const stamp = this.formInitialValues;
    const [a, b] = [data, stamp]
      .map((v) => Object.entries(v).sort(([a], [b]) => (a > b ? -1 : +(a !== b))))
      .map((v) => JSON.stringify(v));
    return a !== b;
  }
}

export { Stratums };
