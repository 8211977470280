import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { axisWidth } from "theme/global";

import { selectTicksNumber } from "../Axes/Axes";
import { useD3Context } from "../D3SVG/D3SVG";

import styles from "./XAxis.module.less";

const XAxis = observer(() => {
  const model = useD3Context();
  const refAxisGrid = useRef(null);
  const rectRef = useRef(null);
  const refAxis = useRef(null);
  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    const xAxisFigure: any = d3
      .axisBottom(xScale)
      .ticks(Math.min(selectTicksNumber(model.width), xScale.domain()[1].getFullYear() - xScale.domain()[0].getFullYear() + 1))
      .tickFormat((d) => d3.format("d")((d as Date).getFullYear()));

    d3.select(refAxis.current).call(xAxisFigure).select(".domain").style("opacity", 0.1);
    d3.select(refAxis.current).selectAll("line").style("opacity", 0.1);
    d3.select(refAxis.current).selectAll("text").style("pointerEvents", "none");
    d3.select(rectRef.current).call(model.zoom);

    const xAxisGrid: any = d3
      .axisBottom(xScale)
      .ticks(Math.min(selectTicksNumber(model.width), model.xAxis.domain.max.getFullYear() - model.xAxis.domain.min.getFullYear() + 1))
      .tickFormat((d) => d3.format("d")((d as Date).getFullYear()));
    d3.select(refAxisGrid.current)
      .call(xAxisGrid.tickSize(model.height - model.padding.bottom - model.padding.top))
      .select(".domain")
      .style("opacity", 0);
    d3.select(refAxisGrid.current).selectAll("line").style("opacity", 0.1);
    d3.select(refAxisGrid.current).selectAll("text").style("opacity", 0);

    if (model.grid?.x !== undefined && model.grid?.x.show) {
      d3.select(refAxisGrid.current)
        .selectAll("line")
        .style("opacity", model.grid.x.opacity ?? 0.1)
        .style("stroke", model.grid.x.color ?? "grey");
    }
    const zoom: any = d3
      .zoom()
      .scaleExtent([1, 40])
      .on("zoom", (event: d3.D3ZoomEvent<SVGSVGElement, any>) => {
        const zoomState = event.transform;
        model.setCurrentZoomState(zoomState);
      });
    d3.select(rectRef.current).call(zoom);
  }, [model.currentZoomState, model.width, model.height, model.zoom, model]);
  return (
    <>
      <g className={styles[`x-axis`]} transform={`translate(0, ${model.height - model.padding.bottom})`} ref={refAxis}></g>
      {model.grid?.x !== undefined && model.grid?.x.show && <g ref={refAxisGrid} transform={`translate(0, ${model.padding.bottom - 5})`}></g>}
      <rect
        ref={rectRef}
        className={styles[`x-axis-zoom-rect`]}
        x={model.padding.left + model.yAxesLeft.filter((axis) => axis.showAxis === undefined || axis.showAxis).length * axisWidth}
        y={model.height - model.padding.bottom}
        height={20}
        width={
          model.width -
          model.yAxesRight.filter((axis) => axis.showAxis === undefined || axis.showAxis).length * axisWidth -
          model.padding.right -
          model.padding.left -
          model.yAxesLeft.filter((axis) => axis.showAxis === undefined || axis.showAxis).length * axisWidth
        }
      ></rect>
    </>
  );
});

export default XAxis;
