import { req } from "utils/request";

import type { InjectionDataBack } from "./calculateInjection";

type SavedInjection = {
  well_id: number;
  stratum_id: number;
  scenario_id: number;
  gtm_id: number | null;
};

function saveInjection(injections: InjectionDataBack[]) {
  return req.post<SavedInjection[]>(`productions/forecast/`, injections);
}

export { saveInjection };
