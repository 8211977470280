import { useMemo } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { Button, Empty, Select, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { SelectStorable, SelectStoreType } from "elements/inputs/selectStorable/selectStorable";
import { useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { useFact } from "models/project/fact/fact";
import { useProject } from "models/project/project";

import { RangeSelector } from "../common/rangeSelector";

import { FactorAnalysisChart } from "./factorAnalysisChart/factorAnalysisChart";
import { FactorAnalysisChartModel } from "./factorAnalysisChart/factorAnalysisChartModel";
import { DRow, FactorAnalysisTableStore } from "./factorAnalysisTable";
import { FACTORS } from "./factors";
import { ReactComponent as PlusIcon } from "./plusIcon.svg";

import cn from "./factorAnalysis.module.less";

const factorSelectStore: SelectStoreType = {
  isLoading: false,
  selector: FACTORS,
  at: (id: number) => {
    const item = FACTORS.find((item) => item.value === id);
    if (item === undefined) return undefined;
    return {
      id: item.value,
      title: item.label,
    };
  },
};

const useColumns = (): ColumnRaw<DRow>[] => {
  const fact = useFact()!;
  const ecyOptions = useMemo(() => fact.ecyStore?.selectItems, [fact]);

  return useMemo<ColumnRaw<DRow>[]>(
    () => [
      {
        title: <div />,
        key: "setorder",
        isSticky: true,
        width: 30,
        onCell: () => ({ style: { padding: 0 } }),
        render: (_, { value }) => {
          return (
            <div className={cn.arrowButtonsBlock}>
              <Button type="link" onClick={() => value?.move?.("up")} icon={<CaretUpOutlined style={{ fontSize: 12 }} />} />
              <Button type="link" onClick={() => value?.move?.("down")} icon={<CaretDownOutlined style={{ fontSize: 12 }} />} />
            </div>
          );
        },
      },
      {
        title: "№",
        width: 54,
        key: "index",
        isSticky: true,
        render: (_, tableItem) => tableItem.absoluteIndex,
      },
      {
        dataKey: "scenarioId",
        title: "Сценарий",
        width: { min: 200, max: 400, competitiveness: 1 },
        isSticky: true,
        onCell: () => ({ className: cn.tableCell }),
        render: (id: number | null, { update }) => {
          return <SelectStorable values={[id, undefined]} store={fact.forecasts} setValues={(id: number | null) => update?.("scenarioId", id)} />;
        },
      },
      {
        dataKey: "ecyId",
        title: "Модель ЕСУ",
        width: { min: 200, max: 500, competitiveness: 1 },
        onCell: () => ({ className: classNames(cn.ecy, cn.tableCell) }),
        render: (id: number, { update }) => {
          return <Select variant="borderless" className={cn.select} value={id} onSelect={(v) => update?.("ecyId", v)} options={ecyOptions} />;
        },
      },
      {
        dataKey: "factor",
        title: "Фактор",
        width: { min: 200, max: 500, competitiveness: 1 },
        onCell: () => ({ className: cn.factor }),
        render: (value: [number | null, string?] | undefined, tableItem) => {
          if (value === undefined || tableItem.value?.prev === null) {
            return null;
          }
          const [id, title] = value;
          return (
            <SelectStorable
              values={[id, title]}
              notFoundContent={<div>Ввести: '{title}'</div>}
              store={factorSelectStore}
              setValues={(id, searchValue) => {
                tableItem.update?.("factor", [id, id !== null ? factorSelectStore.at(id)?.title : searchValue]);
              }}
            />
          );
        },
      },
      {
        dataKey: "factorValueMeasure",
        title: "Ед. изм",
        width: 100,
        render: (value: number | null | undefined) => <Format>{value}</Format>,
      },
      {
        dataKey: "factorValue",
        title: "Значение параметра",
        width: 120,
        render: (value: number | null | undefined) => <Format>{value}</Format>,
      },

      {
        dataKey: "factorDeltaValue",
        title: "Δ Параметра",
        width: 120,
        onCell: ({ value }) => ({ className: value?.factorDeltaValue ? (value?.factorDeltaValue >= 0 ? cn.positive : cn.negative) : "" }),
        render: (value: number | null | undefined) => <Format sign>{value}</Format>,
      },
      {
        dataKey: "factorDeltaRelativeValue",
        title: "Δ, %",
        width: 120,
        onCell: ({ value }) => ({
          className: value?.factorDeltaRelativeValue ? (value?.factorDeltaRelativeValue >= 0 ? cn.positive : cn.negative) : "",
        }),

        render: (value: number | null | undefined) => <Format sign>{value}</Format>,
      },
      {
        dataKey: "npv",
        title: "NPV, млн $",
        width: 120,
        render: (value: number | null | undefined) => <Format>{value}</Format>,
      },
      {
        dataKey: "delta",
        title: "ΔNPV, млн $",
        width: 120,
        onCell: ({ value }) => ({ className: value?.delta ? (value?.delta >= 0 ? cn.positive : cn.negative) : "" }),
        render: (value: number | null | undefined) => {
          return <Format sign>{value}</Format>;
        },
      },
      {
        dataKey: "deltaRelative",
        title: "ΔNPV, %",
        width: 120,
        onCell: ({ value }) => ({ className: value?.delta ? (value?.delta >= 0 ? cn.positive : cn.negative) : "" }),
        render: (value: number | null | undefined) => {
          return <Format sign>{value}</Format>;
        },
      },
      {
        title: "",
        key: "sticky",
        width: 55,
        render: (_, { value }) => {
          return <>{value?.remove && <DeleteButton onClick={value?.remove} />}</>;
        },
        onHeaderCell: () => ({ className: cn.tableRightSticky }),
        onCell: () => ({ className: cn.tableRightSticky }),
      },
    ],
    [ecyOptions, fact.forecasts]
  );
};

const FactorAnalysis = observer(() => {
  const project = useProject()!;
  const searchParamsStorage = useSearchParamsStorage();
  const store = useMemo(() => new FactorAnalysisTableStore(project.fact, searchParamsStorage), [searchParamsStorage, project.fact]);
  const factorChartModel = useMemo(() => new FactorAnalysisChartModel(store), [store]);
  const columns = useColumns();
  const model = useMemo(() => {
    return new TableModel(
      columns,
      store,
      {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
  }, [columns, store]);

  return (
    <>
      <PageFrameTitlePortal model={store} onSave={store.save} />
      <div className={cn.container}>
        <div className={cn.header}>
          <Typography.Title level={2}>Настройки анализа</Typography.Title>
          <div className={cn.toolbar}>
            Период анализа
            <RangeSelector value={store.range} onChange={store.onChangeRange} />
            <Button onClick={() => store.addScenario()} icon={<Icon content={<PlusIcon />} viewBox="0 0 14 14" width="16px" height="16px" />}>
              Добавить сценарий
            </Button>
          </div>
        </div>
        {store.isLoading === true || (store.childrenStore?.size ?? 0) === 0 ? (
          <main className={cn.main}>
            <Empty description={<Typography.Text>Нет данных для отображения</Typography.Text>} />
          </main>
        ) : (
          <main className={cn.main}>
            <div className={cn.table}>
              <TableContextProvider value={model}>
                <Widget headerClassName={cn.tableHeader} className={cn.table} />
              </TableContextProvider>
            </div>
            <div className={cn.chart}>
              <FactorAnalysisChart chartModel={factorChartModel} />
            </div>
          </main>
        )}
      </div>
    </>
  );
});

export { FactorAnalysis };
