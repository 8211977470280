import { BackendStorageMock } from "services/back/backendStorage";
import type { NDDGroups } from "services/back/licenseRegions";
import { START_YEAR } from "services/field";
import { delayedResolve } from "utils/delayedResolve";
import { Range } from "utils/range";

type Visible = {
  row: null | {
    only_ndd: boolean | null;
    ndd_group: Array<NDDGroups> | null;
    only_direction: boolean | null;
    direction_group: Array<1 | 2 | 3> | null;
  };
  column: null | {
    only_fact: boolean | null;
    only_forecast: boolean | null;
  };
};

type Editable = {
  optional: boolean | null;
  not_editable: boolean | null;
  only_fact: boolean | null;
  only_forecast: boolean | null;
  only_since_ndd: boolean | null;
  only_before_ndd: boolean | null;
  only_since_entry: boolean | null;
  start_from: number | null;
  dispose: number | null;
};

type Metric = {
  key: number;
  title: string;
  parent: null | number;
  code_title?: string;
  unit: null | {
    quantity: null | number;
    measure: null | number;
  };
  values?: Array<number | null> | null;
  editable?: Editable;
  visible?: Visible;
};

type MetricTree = Omit<Metric & { wellId?: number }, "parent"> & { children: MetricTree[] };

type ParamsResponse = { table?: MetricTree[]; scalar?: MetricTree[] };
type ParamsFlat = { table?: Metric[]; scalar?: Metric[] };

// преобразовывает плоское представление в древовидное
const riseMetrics = (flatOrigin: Metric[]): MetricTree[] => {
  const flat = flatOrigin.map((v) => ({ ...v }));
  const metricsMap = new Map<number, Metric & { children?: Metric[] }>();
  for (const metric of flat) {
    metricsMap.set(metric.key, metric);
  }
  for (const metric of flat) {
    if (metric.parent !== null) {
      console.assert(
        metricsMap.has(metric.parent),
        `Ошибка указания родительского элемента для ${metric.title} (${metric.parent})`
      );
      const parent = metricsMap.get(metric.parent)!;
      parent.children = parent.children ?? [];
      parent.children.push(metric);
    }
  }
  const result: Array<MetricTree> = [];
  for (const metric of flat as Array<Omit<Metric, "parent"> & { parent?: null | number; children: MetricTree[] }>) {
    if (metric.parent === null) {
      result.push(metric);
    }
    delete metric.parent;
  }
  return result;
};

const riseParamsMetrics = ({ table, scalar }: ParamsFlat) => ({
  table: table ? riseMetrics(table) : table,
  scalar: scalar ? riseMetrics(scalar) : scalar,
});

const valuesMock =
  (range: Range, mockSet: Record<string, Array<number | null | boolean>>) =>
  (note: Metric): Metric => ({
    ...note,
    values:
      note.title in mockSet
        ? mockSet[note.title]
            .slice(range.from - START_YEAR, range.to - START_YEAR)
            .map((v) => (typeof v === "number" ? Math.round(v * 1e3) / 1e3 : v))
        : range.array.fill(null),
  });

const costsTableFromMock =
  (mockData: string, mock: Record<string, Array<null | number>> = {}) =>
  async (range: Range): Promise<ParamsResponse> => {
    const empty: Metric[] = parseRawMock(mockData).map((v) => {
      return {
        ...v,
        unit: v.title.includes("(реальная ставка)") ? { quantity: 3, measure: 2 } : { quantity: 2, measure: 3 }, // млн руб
        values: range.array.fill(null),
      };
    });
    return { table: riseMetrics(empty.map(valuesMock(range, mock))) };
  };

type StorageKey = { suffix?: string; fact: number; forecast?: number };

const getUniversalDataSource = (
  defaultGetters: Array<{ key: string; fn: (range: Range) => Promise<ParamsResponse> }>
) => {
  return defaultGetters.map(({ key, fn }) => {
    const storageNamePrefix = `${key}Params-`;
    return ({ suffix = "", fact, forecast }: StorageKey) => {
      const [get, set] = new BackendStorageMock<Range, ParamsFlat, ParamsResponse>(
        storageNamePrefix + suffix,
        fact,
        forecast
      ).decorators;
      return [
        get(fn),
        set((data: ParamsFlat) => {
          const dataRised: ParamsResponse = {
            scalar: data.scalar && riseMetrics(data.scalar),
            table:
              data.table &&
              riseMetrics(
                data.table.map(({ values, ...rest }) => ({
                  ...rest,
                  values: values?.map((v) => (typeof v === "boolean" ? Number(v) : v)),
                }))
              ),
          };
          return delayedResolve(dataRised, 200);
        }),
      ] as const;
    };
  });
};

function parseRawMock(raw: string): Metric[] {
  function parseLine(rawLine: string): [level: number, title: string, quantity: number, measure: number] {
    let level = (rawLine.length - rawLine.trimStart().length) / 2; // Suppose 2 spaces as offset symbol
    const [title, q, m] = rawLine.trim().split(";"); // {title};{quantity};{measure}
    const quantity = q !== undefined ? parseInt(q) : 0;
    const measure = m !== undefined ? parseInt(m) : 0;
    return [level, title, quantity, measure];
  }

  const parents: number[] = [];
  const result: Metric[] = [];
  const lines = raw.split("\n");
  let key = 0;
  let parent: number | null = null;

  for (const line of lines) {
    if (line.length === 0) continue;
    const [lvl, title, quantity, measure] = parseLine(line);
    console.assert(lvl <= parents.length && Number.isInteger(lvl), `Неверные отступы: ${title}`);

    parents.length = lvl;
    parent = parents[parents.length - 1] ?? null;

    parents.push(key);
    result.push({ title, key, parent, unit: { quantity, measure } });
    ++key;
  }
  return result;
}

function findObjectByCodeTitle(obj: MetricTree, codeTitle: string, isNot = false): MetricTree[] | null {
  if (obj.code_title === codeTitle) {
    return [obj];
  }
  if (obj.children && obj.children.length > 0) {
    for (let i = 0; i < obj.children.length; i++) {
      const result = findObjectByCodeTitle(obj.children[i], codeTitle);
      if (result && !isNot) {
        return result;
      }
      if (result && isNot) {
        return obj.children.filter((el) => el.code_title !== result[0].code_title);
      }
    }
  }
  return null;
}

export type { Metric, MetricTree, ParamsFlat, ParamsResponse, StorageKey };
export {
  costsTableFromMock,
  findObjectByCodeTitle,
  getUniversalDataSource,
  parseRawMock,
  riseMetrics,
  riseParamsMetrics,
  valuesMock,
};
