import { Metric } from "services/finance/utils";
import { Range } from "utils/range";
import { req } from "utils/request";

import { formatSchema, ParamsResponse } from "./utils";

const getInvestResolver = async (range: Range): Promise<{ schema_invest: Metric[] }> => {
  const { schema_invest } = await req.get<ParamsResponse<"schema_invest">>("demo/schemas/basic");
  return {
    schema_invest: formatSchema(schema_invest.schema, range),
  };
};

export { getInvestResolver };
