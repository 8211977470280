import { computed, makeObservable, observable } from "mobx";

import type { Params } from "models/params/params";

class ParamsMap<T extends Params> {
  #items = observable.map<number, T>();

  constructor(public readonly keys: number[], private readonly paramsFactory: (key: number) => T) {
    makeObservable<ParamsMap<T>>(this, {
      isLoading: computed,
      items: computed,
      values: computed,
    });
  }

  public get isLoading(): boolean {
    return this.keys.map((key) => this.get(key).isLoading).includes(true);
  }

  public get(key: number): T {
    if (!this.#items.has(key)) {
      this.#items.set(key, this.paramsFactory(key));
    }
    return this.#items.get(key)!;
  }

  public get items(): [key: number, params: T][] | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return [...this.#items.entries()];
  }

  public get values(): T[] | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return [...this.#items.values()];
  }
}

export { ParamsMap };
