import { RadioChangeEvent } from "antd";
import { action, makeObservable, observable } from "mobx";

type VoronoiModeSelectorOptions = "oilRate" | "liquidRate";

class VoronoiModeSelectorModel {
  public mode: VoronoiModeSelectorOptions;
  constructor() {
    makeObservable(this, {
      mode: observable,
      onChangeMode: action,
    });
    this.mode = "oilRate";
  }
  public onChangeMode = ({ target: { value } }: RadioChangeEvent) => {
    this.mode = value;
  };
}
export { VoronoiModeSelectorModel, type VoronoiModeSelectorOptions };
