import { useLayoutEffect, useMemo, useRef } from "react";
import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { Fact, useFact } from "models/project/fact/fact";
import { type Forecast } from "models/project/fact/forecast/forecast";

const checkForecastName = (name: string, fact: Fact | undefined | null) => {
  return name.length > 0 && fact?.forecasts.find((fc) => fc.title === name) === null;
};

const ModalContent: ModelContentComponentType<Forecast, number> = ({ dataRef, setValid, accept, setOnAccept, setLoading }) => {
  const fact = useFact();
  const origin = useFact()?.forecasts.at(dataRef.current)!;
  const form = Form.useForm()[0];
  const onChange = async (changed: Record<string, any>, values: { name: string }) => {
    if ("name" in changed) {
      setValid(checkForecastName(changed.name, fact));
    }
  };

  const initialName = useMemo(() => {
    return origin.nameInference((title: string, id: number | null) => `${title} (копия${id === null ? "" : ` ${id}`})`);
  }, [origin]);

  useLayoutEffect(() => {
    setValid(checkForecastName(initialName, fact));
  }, [fact, initialName, setValid]);

  setOnAccept(async () => {
    try {
      setLoading(true);
      const data = await origin.copy(form.getFieldValue("name"), form.getFieldValue("description"));
      setLoading(false);
      return { result: true, data };
    } catch (err) {
      console.error("Не удалось скопировать", err);
      return { result: false };
    }
  });
  if (!origin) {
    return <FullScreenLoader />;
  }

  return (
    <Form
      form={form}
      onFinish={accept}
      onValuesChange={onChange}
      initialValues={{
        name: initialName,
      }}
    >
      <FormItem name="name" required label="Название копии сценария">
        <Input />
      </FormItem>
      <FormItem name="description" label="Описание">
        <TextArea autoSize={{ minRows: 3, maxRows: 15 }} />
      </FormItem>
    </Form>
  );
};

const useForecastCopyDialog = (): ((id: number) => () => Promise<void>) => {
  const showModal = useModal<Forecast, number>();
  const fact = useFact()!;
  const data = useRef<number>(-1);

  return (originId: number) => () => {
    data.current = originId;
    return showModal(ModalContent, data, "Создание копии сценария", "Применить")
      .catch(() => {
        return null;
      })
      .then((created: Forecast | null) => {
        if (created === null) {
          return;
        }
        fact!.forecasts.insertCopy(created, originId);
      });
  };
};
export { useForecastCopyDialog };
