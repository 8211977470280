import { getConstantsResolver } from "services/back/constants";
import { getInvestResolver } from "services/back/invest";
import type { Range } from "utils/range";
import { ofTree } from "utils/tree";

import { findObjectByCodeTitle, getUniversalDataSource, MetricTree, ParamsResponse, riseMetrics } from "./utils";

const getUnitCosts = async (): Promise<ParamsResponse> => {
  try {
    const { constants } = await getConstantsResolver();
    const unitCosts = riseMetrics(constants).find(
      (el) => el.code_title === "Удельные расходы. Инвестиционная деятельность"
    )!;

    if (unitCosts) {
      const { children } = unitCosts;
      for (const costs of ofTree(children)) {
        if (
          costs.children &&
          costs.children.length > 0 &&
          costs.values &&
          costs.values.length === 1 &&
          costs.values[0] === null
        ) {
          costs.values = null;
        }
      }
      const lastElement = children[1];
      children[0].children = [...children[0].children, lastElement];
      children[0].title = "";
    }

    return { scalar: [unitCosts.children[0]] };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const getParamsByYear =
  (key: "InvestFact" | "Invest") =>
  async (range: Range): Promise<ParamsResponse> => {
    try {
      const { schema_invest } = await getInvestResolver(range);
      let paramsByYear = riseMetrics(schema_invest).reduce((acc, el) => {
        switch (el.code_title) {
          case "Денежный поток от инвестиционной деятельности":
            acc.push(el);
            break;
          case "Денежный поток от инвестиций в оборотный капитал":
            acc.push(el);
        }
        return acc;
      }, [] as MetricTree[]);

      //фактические данные
      if (key === "InvestFact") {
        paramsByYear[1].children = findObjectByCodeTitle(
          paramsByYear[1],
          "Прочие изменения оборотного капитала",
          true
        )!;
      }

      //прогноз
      if (key === "Invest") {
        const { children } = paramsByYear[0];
        paramsByYear[1].children = findObjectByCodeTitle(
          paramsByYear[1],
          "Денежный поток от измен.оборотного капитала по инвест.деят.",
          true
        )!;
        children[0].children[0].children[0].children = findObjectByCodeTitle(
          paramsByYear[0],
          "Поисково-разведочное бурение"
        )!;
        children[0].children[0].children[1].children = findObjectByCodeTitle(paramsByYear[0], "прочее (ОНСС)")!;
      }

      paramsByYear = [...paramsByYear[0].children, paramsByYear[1]];

      return { table: paramsByYear };
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

const [investFactSource, investForecastSource, investUnitCostsSource] = getUniversalDataSource([
  { key: "InvestFact", fn: getParamsByYear("InvestFact") },
  { key: "Invest", fn: getParamsByYear("Invest") },
  { key: "InvestUnitCosts", fn: getUnitCosts },
]);

export { investFactSource, investForecastSource, investUnitCostsSource };
