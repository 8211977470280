import { FC, PropsWithChildren, useEffect, useRef } from "react";
import { observer } from "mobx-react";

import cn from "./InfrastructureMap.module.less";

const [DEFAULT_X, DEFAULT_Y] = [-25, -10];
const [dx, dy] = [5, 15]; //Для смещения текста в подложке

const Label: FC<{ x?: number; y?: number; className?: string } & PropsWithChildren> = observer(({ children, x, y, className }) => {
  const textRef = useRef<SVGTextElement>(null);
  const backgroundRef = useRef<SVGRectElement>(null);

  useEffect(() => {
    if (textRef.current && backgroundRef.current) {
      const { width, height } = textRef.current.getBBox();
      const padding = 5;
      backgroundRef.current.setAttribute("width", (width + 2 * padding).toString());
      backgroundRef.current.setAttribute("height", (height + 2 * padding).toString());
    }
  }, [backgroundRef, textRef, children]);

  return (
    <g className={className}>
      <rect x={x ? x : DEFAULT_X} y={y ? y : DEFAULT_Y} className={cn.bgText} ref={backgroundRef} rx={5} />
      <text x={x ? x : DEFAULT_X} y={y ? y : DEFAULT_Y} dx={dx} dy={dy} className={cn.text} ref={textRef}>
        {children}
      </text>
    </g>
  );
});

export { Label };
