import { type FC, useMemo, useState } from "react";
import { Button, Checkbox, Dropdown, Popover, Tooltip } from "antd";
import { observer } from "mobx-react";
import { ItemType } from "rc-menu/lib/interface";

import { useForecast } from "models/project/fact/forecast/forecast";
import type { CalculationVariant } from "services/back/calculate/calculate";
import { PermissionInfo } from "services/back/roles";

import { MessageDisplay, useResultValidatorManager } from "./resultInformer";

type CalculateModeOption = ItemType & {
  key: CalculationVariant;
};

type ButtonMenuItem = { type: "group"; children: CalculateModeOption[] } | CalculateModeOption;

type CalculateButtonProps = {
  edit?: PermissionInfo;
};

const CalculateButton: FC<CalculateButtonProps> = observer(({ edit }) => {
  const forecast = useForecast()!;
  const [isOpenedDetails, setOpenedDetails] = useState<boolean>(false);
  const validatorManager = useResultValidatorManager();

  const switchOpenDetails = () => {
    setOpenedDetails((v) => !v);
  };
  const disabled = forecast.infrastructure.infrastructureValidator.cannotCalculate;

  const items = useMemo(
    (): ButtonMenuItem[] => [
      { key: "classic", label: "без учета инфраструктуры" },
      {
        type: "group",
        label: <Tooltip title={disabled ? "Инфраструктура еще не построена" : undefined}>с учетом инфраструктуры</Tooltip>,
        children: [
          { key: "infrastructure-total-only", label: "без учета в поскважинной экономике", disabled },
          { key: "infrastructure-by-well", label: "с учетом в поскважинной экономике", disabled },
        ],
      },
    ],
    [disabled]
  );

  const calculate = (e: { key: string }) => {
    forecast.calculate(e.key as CalculationVariant, forecast.isWithoutGtm);
  };

  if (validatorManager && validatorManager.validationResult?.levelType === "error") {
    return (
      <Popover
        trigger="click"
        content={<MessageDisplay />}
        placement="bottom"
        title={"Список ошибок и предупрежений"}
        open={isOpenedDetails}
        onOpenChange={switchOpenDetails}
      >
        <Button danger>{forecast.savedResultTS !== null ? "Пересчитать" : "Рассчитать"}</Button>
      </Popover>
    );
  }

  return (
    <Tooltip title={!edit?.value && (edit?.customTooltip || "Для выполнения действия недостаточно полномочий вашей роли в проекте")}>
      <Dropdown disabled={!edit?.value} menu={{ items, onClick: calculate }}>
        <Button>{forecast.savedResultTS !== null ? "Пересчитать" : "Рассчитать"}</Button>
      </Dropdown>
      &nbsp;
      <Checkbox checked={forecast.isWithoutGtm} onChange={(e) => (forecast.isWithoutGtm = e.target.checked)}>
        Расчёт для оптимизации БФ и НФ
      </Checkbox>
    </Tooltip>
  );
});

export { CalculateButton };
