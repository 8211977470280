import { FC, useEffect, useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { Alert, Empty, Select, Switch } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreen, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { LinkButton } from "elements/linkButton/linkButton";
import { PermissionButton } from "elements/permissionButton/permissionButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { RankingSettings } from "models/project/fact/forecast/ranking/settings";
import { StaticSettings } from "models/project/fact/forecast/ranking/settingsStatic";
import { YearsSettings } from "models/project/fact/forecast/ranking/settingsYears";
import { useProject } from "models/project/project";
import { getSettings, saveSettings } from "services/back/ranking";

import { SettingsContextProvider } from "./settingsContextProvider";
import { SettingsModule, SettingsSection } from "./settingsModule";

import cn from "./settings.module.less";

const useYearColumns = (settings: RankingSettings) =>
  useMemo<Column[]>(
    () => [
      {
        dataKey: "title",
        title: "Мероприятие",
        type: "string",
        isSticky: true,
        width: { min: 250, max: 350, competitiveness: 1 },
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        type: "string",
        isSticky: true,
        width: { min: 150, max: 350, competitiveness: 1 },
      },
      ...[...settings.yearsRange].map(
        (year): Column => ({
          dataKey: `${year}`,
          title: `${year}`,
          type: "number",
          width: { min: 100, max: 400, competitiveness: 1 },
          editable: true,
          renderToString: (v) => v,
        })
      ),
    ],
    [settings]
  );

const useStaticSettingsColumns = (settings: RankingSettings) =>
  useMemo<Column[]>(
    () => [
      {
        dataKey: "title",
        title: "Мероприятие",
        type: "string",
        isSticky: true,
        width: { min: 250, max: 650, competitiveness: 1 },
      },
      {
        dataKey: "workCost",
        title: "Стоимость работ",
        type: "number",
        width: { min: 250, max: 650, competitiveness: 1 },
      },
      {
        dataKey: "workCostMeasure",
        title: "Ед. измерения",
        type: "string",
        width: { min: 250, max: 650, competitiveness: 1 },
      },
      {
        dataKey: "teamsWork",
        title: "Выработка бригад",
        type: "number",
        width: { min: 250, max: 650, competitiveness: 1 },
        editable: true,
      },
      {
        dataKey: "teamsWorkMeasure",
        title: "Ед. измерения",
        type: "string",
        width: { min: 250, max: 350, competitiveness: 1 },
      },
    ],
    []
  );

const Settings: FC = observer(() => {
  const forecast = useForecast()!;
  const { id: scenarioId } = forecast;
  const project = useProject();

  const settings = forecast.rankingSettings;

  const [yearsStore, staticStore] = useMemo(
    () => [new YearsSettings(settings.yearsSettings, settings.yearsRange), new StaticSettings(settings.staticSettings)],
    [settings]
  );

  const yearsSettingsColumns = useYearColumns(settings);
  const staticSettingsColumns = useStaticSettingsColumns(settings);

  const getTotalRows = (items: any) => {
    return items.reduce((count: number, item: any) => {
      let total = 1;
      if (item.children && item.children.length > 0) {
        total += getTotalRows(item.children);
      }

      return count + total;
    }, 0);
  };

  const yearsLineCount = yearsStore?.children ? getTotalRows(yearsStore?.children) : 0;
  const staticLineCount = staticStore?.children ? getTotalRows(staticStore?.children) : 0;

  const {
    rankingSequential: { isLoading },
  } = forecast;

  useEffect(() => {
    settings.setLoading(true);
    getSettings(project?.id ?? 0, scenarioId)
      .then((newSettings) => {
        if (newSettings) {
          settings.setYearsSettings(newSettings.years);
          settings.setStaticSettings(newSettings.static);
        }
      })
      .finally(() => {
        settings.setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (settings.isLoading) {
    return <FullScreenLoader />;
  }

  if (isLoading) {
    return (
      <FullScreen>
        <Empty description={<>Для начала настройки планирования нужно настроить Очередность бурения </>}>
          <LinkButton to={`/${project?.id}/${scenarioId}/queue`}>Очередность бурения</LinkButton>
        </Empty>
      </FullScreen>
    );
  }

  return (
    <SettingsContextProvider value={settings}>
      <PageFrameTitlePortal
        onSave={async () => {
          saveSettings({ years: settings.yearsSettings, static: settings.staticSettings }, project?.id!, scenarioId);
        }}
        permissionSection="ranking"
      >
        <PermissionButton
          onClick={async () => {
            await saveSettings({ years: settings.yearsSettings, static: settings.staticSettings }, project?.id!, scenarioId);
            await settings.calculate();
          }}
          loading={settings.isCalculating}
          section="ranking"
        >
          Провести планирование
        </PermissionButton>
      </PageFrameTitlePortal>
      <SettingsModule title="Параметры по годам">
        <SimpleTableContext
          exportFileName="Параметры по годам"
          columns={yearsSettingsColumns}
          data={yearsStore}
          theme={{
            headerHeight: 39,
            rowHeight: 33,
            borderColor: "#f0f0f0",
          }}
          tableOptions={{
            onRow: ({ expand }) => ({
              className: expand === undefined ? cn.tableRowPlain : cn.tableRowPrimary,
            }),
          }}
        >
          <div className={cn.simpleTable} style={{ "--line-count": `${yearsLineCount}` } as React.CSSProperties}>
            <Table headerClassName={cn.tableHeader} className={cn.table} />
          </div>
        </SimpleTableContext>
      </SettingsModule>
      <SettingsModule title="Статичные параметры" className={cn.static}>
        <SettingsSection title="Стоимость работ и выработка">
          <SimpleTableContext
            exportFileName="Стоимость работ и выработка"
            columns={staticSettingsColumns}
            data={staticStore}
            theme={{
              headerHeight: 39,
              rowHeight: 33,
              borderColor: "#f0f0f0",
            }}
            tableOptions={{
              onRow: ({ expand }) => ({
                className: expand === undefined ? cn.tableRowPlain : cn.tableRowPrimary,
              }),
            }}
          >
            <div className={cn.simpleTable} style={{ "--line-count": `${staticLineCount}` } as React.CSSProperties}>
              <Table headerClassName={cn.tableHeader} className={cn.table} />
            </div>
          </SimpleTableContext>
          <Alert
            message={
              <>
                <span>Указанные значения удельной стоимости работ задаются в разделе </span>
                <LinkButton to={`/${project?.id}/${scenarioId}/invest/costs/`} type="link" className={cn.link}>
                  Параметры инвестиционной деятельности: удельные затраты.
                </LinkButton>
              </>
            }
            type="warning"
            showIcon
            className={cn.alert}
          />
        </SettingsSection>
        <SettingsSection title="Дополнительные настройки">
          <div className={cn["additional"]}>
            <div className={cn.ecy}>Модель ECY</div>
            <div className={cn["additional-inputs"]}>
              <Select className={cn["cost-selector"]} disabled />
              <Switch className={cn.switch} disabled />
              <span className={cn.onns}>Учет затрат на ОНСС</span>
            </div>
          </div>
        </SettingsSection>
      </SettingsModule>
    </SettingsContextProvider>
  );
});

export { Settings };
