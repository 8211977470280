import { useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";

import { ContainerShape } from "utils/useContainerShape";

import { Rect, Scale } from "./types";
/*
Скэил синтезируется в два этапа:
Сначала создаётся скэйл данных, он зависит только от входных данных (размера виджета и пространства данных)
Потом провайдится зум, управляющий осями и решеткой
*/
const useZoom = (shape: ContainerShape, domain: Rect, scaleExtentMin: number = 0.5, scaleExtentMax: number = 20) => {
  const listenerRef = useRef<SVGSVGElement>(null);
  const zoom: d3.ZoomBehavior<SVGSVGElement, unknown> = useMemo(
    () => d3.zoom<SVGSVGElement, unknown>().scaleExtent([scaleExtentMin, scaleExtentMax]),
    [scaleExtentMin, scaleExtentMax]
  );

  const scale: Scale = useMemo(() => {
    const [x, y, w, h] = [domain.x1, domain.y2, domain.x2 - domain.x1, domain.y1 - domain.y2];
    const k = isFinite(shape.width) && isFinite(shape.height) ? shape.width / shape.height : 1;
    const width = Math.max(h * k, w);
    const dx = (width - w) / 2;
    const height = Math.max(w / k, h);
    const dy = (height - h) / 2;
    const x1 = x - dx;
    const y2 = y - dy;
    const y1 = y2 + height;
    const x2 = x1 + width;
    return {
      x: d3.scaleLinear().domain([x1, x2]).range([0, shape.width]),
      y: d3.scaleLinear().domain([y1, y2]).range([0, shape.height]),
    };
  }, [domain, shape.width, shape.height]);

  useEffect(() => {
    zoom.extent([
      [0, 0],
      [shape.width, shape.height],
    ]);
  }, [shape.width, shape.height, zoom]);

  useEffect(() => {
    zoom(d3.select(listenerRef.current!));
  }, [zoom]);

  const [zoomedScale, setZoomedScale] = useState<Scale | null>(null);

  const sceneRef = useRef<SVGGElement>(null);

  const [transform, setTransform] = useState<d3.ZoomTransform | null>(null);

  useEffect(() => {
    zoom.on("zoom", (e: d3.D3ZoomEvent<SVGSVGElement, unknown>) => {
      setTransform(e.transform);
    });
  }, [zoom]);

  useEffect(() => {
    if (transform === null) {
      return;
    }
    setZoomedScale({
      x: transform.rescaleX(scale.x).interpolate(d3.interpolateRound),
      y: transform.rescaleY(scale.y).interpolate(d3.interpolateRound),
    });
    d3.select(sceneRef.current).attr("transform", transform.toString());
  }, [transform, scale]);

  return [scale, zoomedScale ?? scale, sceneRef, listenerRef, zoom, transform] as const;
};

export { useZoom };
