import { type FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { ParamsPage } from "routing/outlines/params/paramsPage";

import type { Params as ParamsModel } from "models/params/params";
import { useFact } from "models/project/fact/fact";
import { conditionallyArr } from "utils/conditionally";

type Taxable = {
  wealthTax: {
    get: (key: number) => ParamsModel;
  };
  severanceTax: ParamsModel;
  nddTax: ParamsModel;
};

const TaxParams: FC = observer(() => {
  const fact = useFact();

  const tabs = useMemo(
    () => [
      {
        key: "wealth",
        title: "Налог на имущество",
        model: (data: Taxable, licenseKey?: number) => data.wealthTax.get(licenseKey!),
        isNeedLicense: true,
      },
      ...conditionallyArr(fact?.licenseRegions.isHasNDD === true, {
        key: "ndd",
        title: "НДД",
        model: (data: Taxable) => data.nddTax,
      }),
      {
        key: "severance",
        title: "НДПИ",
        model: (data: Taxable) => data.severanceTax,
      },
    ],
    [fact?.licenseRegions.isHasNDD]
  );

  return <ParamsPage factTabs={tabs} forecastTabs={tabs} />;
});

export { TaxParams };
