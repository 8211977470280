import { TableNode } from "@okopok/components/Table";

function hasStoreNoData(store: TableNode<any, any>): boolean {
  if (store.childrenStore === undefined) {
    return false;
  }
  if (!store.childrenStore?.children || store.childrenStore.size === 0) {
    return true;
  }
  for (const child of store.childrenStore.children) {
    if (!hasStoreNoData(child)) {
      return false;
    }
  }
  return true;
}

export { hasStoreNoData };
