import { FC, useEffect } from "react";
import { useMapContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { NodeIcon } from "../nodeIcon";
import { calculateSnapPoint, getZoomParameters } from "../utils";

import cn from "./pipes.module.less";

const Line: FC = observer(() => {
  const { scale, zoom } = useMapContext();
  const manager = useInfrastructureMapContext();

  const [from, to] = [manager.newPipe?.start!, manager.lineEnd];
  const { zoomScaleFactor, zoomScale } = getZoomParameters(zoom);

  useEffect(() => {
    const position = manager.zoomedCursorPosition ?? { x: 0, y: 0 };
    const snapNode = calculateSnapPoint(manager.nodes, position, scale, zoom);
    manager.setLineEnd(snapNode ?? position);
  }, [manager, manager.zoomedCursorPosition, scale, zoom]);

  if (!(from && to)) {
    return <></>;
  }
  return (
    <>
      <g transform={`translate(${scale.x(to.x)}, ${scale.y(to.y)}) scale(${zoomScale})`}>
        <NodeIcon displacement={25 / 2}>
          <circle cx="10.5" cy="12.5" r="11" className={cn.circleNewLine} />
        </NodeIcon>
      </g>
      <line
        x1={scale.x(from.x) - zoomScaleFactor / 2}
        y1={scale.y(from.y)}
        x2={scale.x(to.x) - zoomScaleFactor / 4}
        y2={scale.y(to.y)}
        className={cn.newLine}
        strokeWidth={zoomScaleFactor}
      />
    </>
  );
});

export default Line;
