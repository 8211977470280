/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useContext, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";

import AdditionalLabels from "../AdditionalLabels/AdditionalLabels";
import { CornerLabelsType } from "../AdditionalLabels/CornerLabel";
import { YearLabelType } from "../AdditionalLabels/YearLabels";
import Axes from "../Axes/Axes";
import Bars from "../Bars/Bars";
import Curves from "../Curves/Curves";
import { D3SVGModel } from "../D3SVGModel";
import { useLegendContext } from "../LegendModel";
import Patterns from "../Patterns/Patterns";
import { PointType } from "../Points/Point/Point";
import Points from "../Points/Points";
import Tooltip from "../Tooltip/Tooltip";
import TooltipLine from "../TooltipLine/TooltipLine";

import styles from "./D3SVG.module.less";

type XAxisType = {
  uom?: string;
  id?: number;
  color?: string;
  domain: XDomain;
  tickNumber?: number;
  showAxis?: boolean;
};

type YAxisType = {
  uom?: string;
  id: number;
  color?: string;
  tickNumber?: number;
  showAxis?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  startValue?: number;
};
type LineStyle = {
  lineStyle: string;
  dashLength?: number;
  lineWidth?: number;
};

type FragmentInfo = {
  from: Date;
  to: Date;
  lineStyles: LineStyle;
  color: string | number;
  shadow: boolean;
  opacity?: number;
  fragmentBorderLeft?: boolean;
  fragmentBorderRight?: boolean;
  fragmentBorderMoving?: boolean;
  editable?: boolean;
};

type CurveType = {
  points: Array<PointType>;
  setPoints?: React.Dispatch<React.SetStateAction<PointType[] | null>>;
  fragments: Array<FragmentInfo>;
  curveMethod?: d3.CurveFactory;
  yId: number;
  title: string;
  zIndex?: number;
  replaceNull?: number;
};

type clipPathInfo = {
  x1: number;
  y: number;
  x2: number;
  height: number;
};

type Domain = {
  min: number;
  max: number;
};

type XDomain = {
  min: Date;
  max: Date;
};

type BarType = {
  title: string;
  points: Array<PointType>;
  color: string | number;
  yId: number;
};

type AdditionalLabelsType = {
  yearLines?: boolean;
  yearLabels?: YearLabelType[];
  cornerLabels?: CornerLabelsType;
};

type D3SVGProps = {
  curves: Array<CurveType>;
  bars?: Array<BarType>;
  width: number;
  height: number;
  xAxis: XAxisType;
  yAxesLeft: Array<YAxisType>;
  yAxesRight: Array<YAxisType>;
  grid?: { x?: { show: boolean; color?: string; opacity?: number }; y?: { show: boolean; yId: number; color?: string; opacity?: number } };
  children?: ReactNode;
  additionalLabels?: AdditionalLabelsType;
};

const D3Context = React.createContext<D3SVGModel | null>(null);

const D3SVG = observer((svgProps: D3SVGProps) => {
  const svgRef = useRef(null);
  const uClassName = uuidv4();
  const legend = useLegendContext();

  const model = new D3SVGModel(svgProps, uClassName, legend);
  return (
    <div className={styles["container"]}>
      {svgProps.children}
      <D3Context.Provider value={model}>
        <svg className={styles.chartContainer} ref={svgRef} width={svgProps.width} height={svgProps.height}>
          <Patterns></Patterns>
          <Axes></Axes>
          <Curves></Curves>
          <TooltipLine svgRef={svgRef}></TooltipLine>
          <Bars></Bars>
          <Points></Points>
          {svgProps.additionalLabels && (
            <AdditionalLabels
              yearLines={svgProps.additionalLabels.yearLines}
              yearLabels={svgProps.additionalLabels.yearLabels}
              cornerLabels={svgProps.additionalLabels.cornerLabels}
            ></AdditionalLabels>
          )}
        </svg>
        <Tooltip></Tooltip>
      </D3Context.Provider>
    </div>
  );
});

const useD3Context = () => {
  const context = useContext(D3Context);
  if (context === null) {
    console.assert("Контекст не указан");
  }
  return context!;
};

export default D3SVG;
export { useD3Context };

export type { AdditionalLabelsType, BarType, clipPathInfo, CurveType, D3SVGProps, Domain, FragmentInfo, XAxisType, XDomain, YAxisType };
