import { Forecast } from "models/project/fact/forecast/forecast";
import { Metric } from "services/finance/utils";
import { expandArr } from "utils/expandArray";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";
import { fillValues } from "./utils";

function metCoefs(forecast: Forecast) {
  const predef = CALCULATE_PREDEFINE.met_coefs.schema;
  const schema: any[] = [];

  function fillUnion(metric: any, stateMetrics: Metric[], forecastMetrics: Metric[]): Array<number | null> | undefined {
    const stateMetric = stateMetrics.find((m) => m.title === metric.title);
    const forecastMetric = forecastMetrics.find((m) => m.title === metric.title);
    if (stateMetric !== undefined || forecastMetric !== undefined) {
      return fillValues(forecast, stateMetric, forecastMetric, metric);
    }
    return undefined;
  }

  const taxSeveranceState = forecast.fact.severanceTax.table!.metrics!.flatMap((m) => m.flatten());
  const taxSeveranceForecast = forecast.severanceTax.table!.metrics!.flatMap((m) => m.flatten());

  for (const metric of predef) {
    if (metric.editable.not_editable === true || metric.unit.quantity === 0) {
      if (metric.title === "Год") {
        metric.values = [...forecast.wholeRange];
      }
      schema.push(metric);
      continue;
    }

    let values;
    if (metric.title === "Коэффициент выработанности (Кв)") {
      const coef = taxSeveranceState.find((m) => m.title === metric.title)!;
      values = expandArr(coef.values!, forecast.wholeRange.from, forecast.wholeRange);
    } else {
      values = fillUnion(metric, taxSeveranceState, taxSeveranceForecast);
    }
    if (!values) {
      console.error(`coef ( ${metric.title} ) not found`);
      continue;
    }
    if (values.length !== forecast.wholeRange.length) {
      console.warn(`coef ( ${metric.title} ) incomplete (${values.length}/${forecast.wholeRange.length}): ${values}`);
    }
    schema.push({ ...metric, values });
  }

  return { schema };
}

export { metCoefs };
