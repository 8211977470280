import { Forecast } from "models/project/fact/forecast/forecast";
import type { Range } from "utils/range";

const typeMap = (type: string) => {
  return type;
};

/* расчетнику нужна гарантия,
что нулы могут быть только в начале и конце массива, при этом
замазать всё нулями нельзя так как null используется как признак
того, что скважина не работает */
// const nullJoiner = (data: Array<null | number>, defaultNumber: number = 0): Array<null | number> => {
//   const result: Array<number | null> = data.map((v) => (v === null ? defaultNumber : v));
//   let i = 0;
//   while (result[i] === defaultNumber) {
//     result[i] = null;
//     ++i;
//   }
//   i = result.length - 1;
//   while (result[i] === defaultNumber) {
//     result[i] = null;
//     --i;
//   }
//   return result;
// };

const drilling = (forecast: Forecast) =>
  forecast.wells.allWells
    .filter(({ length, date }) => length !== null && date.year() >= forecast.investYear)
    .map(({ title, type, length, date }) => ({
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: [title],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: [typeMap(type!) as any],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [(length ?? 0) / 1000],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [date.year()],
        },
      ],
    }));

function safelyMerge(
  factRange: Range,
  forecastRange: Range,
  factualValues: (number | null)[] | undefined,
  forecastValues: (number | null)[] | undefined
): (number | null)[] {
  const result = [];
  if (!factualValues) {
    console.error("Фактические параметры не найдены");
    result.push(...factRange.array.fill(null));
  } else {
    result.push(...factualValues);
  }
  if (!forecastValues) {
    console.error("Прогнозные параметры не найдены");
    result.push(...forecastRange.array.fill(null));
  } else {
    result.push(...forecastValues);
  }

  return result;
}

const deposit = (forecast: Forecast) => {
  const factRange = forecast.factRange;
  const forecastRange = forecast.range;

  console.assert(!forecast.fact.producingObjects.isLoading, "Запрос объектов разработки до завершения загрузки");
  const producingObjects = [...forecast.fact.producingObjects.values!];
  const licenseRegions = forecast.fact.licenseRegions.data;

  const paramsFact = forecast.fact.producingObjectsParams;
  const paramsForecast = forecast.producingObjectsParams;
  console.assert(!paramsFact.isLoading, "Запрос параметров объектов разработки (факт) до завершения загрузки");
  console.assert(!paramsForecast.isLoading, "Запрос параметров объектов разработки (прогноз) до завершения загрузки");

  console.assert(paramsFact.isValid, "Фактические данные параметров объектов разработки не валидны");
  console.assert(paramsForecast.isValid, "Прогнозные данные параметров объектов разработки не валидны");

  return producingObjects.map((po) => {
    const years = [...paramsFact.paramsRange, ...paramsForecast.paramsRange];
    const htr_types = safelyMerge(
      factRange,
      forecastRange,
      paramsFact.htrTypesIds?.[po.id],
      paramsForecast.htrTypesIds?.[po.id]
    );
    return {
      producing_object_id: po.id,
      producing_object_title: po.title,

      years,
      htr_types,
      lzs: licenseRegions.map((lr) => {
        return {
          license_zone_id: lr.id,
          license_zone_title: lr.title,

          init_reserves: safelyMerge(
            factRange,
            forecastRange,
            paramsFact.licenseRegionsParams?.[po.id]?.[lr.id]?.reserves,
            paramsForecast.licenseRegionsParams?.[po.id]?.[lr.id]?.reserves
          ),
        };
      }),
    };
  });
};

export { deposit, drilling };
