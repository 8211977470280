import { computed, makeObservable } from "mobx";

import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";

import { FactorAnalysisTableStore } from "../factorAnalysisTable";

class FactorAnalysisChartModel {
  axisTitle = new Map([["bars", "млн $"]]);
  tooltipManager: TooltipDataManager | null = null;

  constructor(public tableModel: FactorAnalysisTableStore) {
    makeObservable(this, {
      bars: computed,
    });
  }

  get bars(): BarsDataModel | null {
    if (this.tableModel.isLoading) {
      return null;
    }
    const childrenStore = this.tableModel.childrenStore;
    if (!childrenStore || childrenStore.size === 0) {
      return null;
    }
    let counter = 0;
    const rawBars: BarDataModelProps[] = [];
    const [root, ...rows] = [...childrenStore.children];

    if (root.forecast === null) {
      return null;
    }

    rawBars.push({
      y: [{ value: root.npv ?? 0 }],
      x: [++counter],
      key: "bars",
      color: "rgba(255, 126, 14, 1)",
    });

    for (const row of rows) {
      if (row.forecast === null) {
        continue;
      }
      if (typeof row.npv === "number" && typeof row.delta === "number") {
        rawBars.push({
          y: [{ value: row.delta, limit: row.npv }],
          x: [++counter],
          key: "bars",
          color: row.delta > 0 ? "rgba(43, 160, 43, 1)" : "rgba(242, 101, 92, 1)",
          xTicks: row.factor[1] ? [[row.factor[1]]] : [[]],
        });
      }
    }

    if (rows.length > 0) {
      rawBars.push({
        y: [{ value: rows.at(-1)!.npv ?? 0 }],
        x: [++counter],
        key: "bars",
        color: "rgba(121, 140, 255, 1)",
      });
    }

    const model = new BarsDataModel(rawBars, "bars");
    return model;
  }
}
export { FactorAnalysisChartModel };
