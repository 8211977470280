import dayjs from "dayjs";

import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Summary } from "models/project/fact/forecast/ranking/results/summary";
import { colorCarousel } from "services/colorCarousel";

import { PlanningChartModel } from "../chart/planningChartModel";

class Events extends PlanningChartModel {
  tooltipManager: TooltipDataManager | null;
  bars: BarsDataModel;

  constructor(store: Summary) {
    super("Мероприятия", new Map<string, string>([["bars", "Кол-во мероприятий"]]));

    const rawBars: BarDataModelProps[] = [
      {
        y: store.GTMCount.ZBS.yearValues.map((value, index) => ({ value: value })),
        x: new Array(store.GTMCount.ZBS.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.GTMCount.ZBS.yearsRange.from + index}`)),
        key: "zbs",
        title: "ЗБС",
        color: colorCarousel(0),
      },
      {
        y: store.GTMCount.GTMKRSCount.yearValues.map((value, index) => ({ value: value })),
        x: new Array(store.GTMCount.GTMKRSCount.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.GTMCount.GTMKRSCount.yearsRange.from + index}`)),
        key: "gtm",
        title: "КРС",
        color: colorCarousel(1),
      },
      {
        y: store.EBCount.yearValues.map((value, index) => ({ value: value })),
        x: new Array(store.EBCount.yearsRange.length)
          .fill(null)
          .map((_, index) => dayjs(`${store.EBCount.yearsRange.from + index}`)),
        key: "eb",
        title: "ЭБ",
        color: colorCarousel(2),
      },
    ];
    this.bars = new BarsDataModel(rawBars, "bars", true);
    this.tooltipManager = new TooltipDataManager(undefined, this.bars);
  }
}
export { Events };
