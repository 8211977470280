import { observer } from "mobx-react";

import { useProjectContext } from "models/project/context/projectContext";

import { View } from "./view";

import cn from "./summary.module.less";

const Summary = observer(() => {
  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;

  return (
    <div className={cn.root}>
      <View className={cn.view} tree={tree} />
    </div>
  );
});

export { Summary };
