import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { constructionKeys, reconstructionKeys } from "features/infrastructure/utils";
import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { InfrastructureCatalog } from "models/project/fact/infrastructure/infrastructureCatalog";
import { StationType } from "services/back/infrastructure/catalog";
import { getRandomUid } from "utils/random";

const stations: Record<StationType["stationType"], "PSStations" | "GPUStations" | "PUStations" | "WKUStations"> = {
  НС: "PSStations",
  УКПГ: "GPUStations",
  УПН: "PUStations",
  УПСВ: "WKUStations",
} as const;

type CustomKeysType = {
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;
  reconstructionDsw: number;
  reconstructionCiw: number;
  reconstructionEquipment: number;
  reconstructionOther: number;
  period: number;
};

type DRow = CustomKeysType & {
  title: string;
  power?: number;
  deltaPressure?: number | null;
  stationType: string;
  total?: number;
  totalReconstruction?: number;
  construction?: StationType["construction"];
  reconstruction?: StationType["reconstruction"];
  remove?: () => void;
};

class Station extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    total: this.total,
    totalReconstruction: this.totalReconstruction,
    remove: this.remove,
  });

  public parent: Stations;
  public data: CustomKeysType & StationType;
  constructor(parent: Stations, data: StationType, private catalog: InfrastructureCatalog) {
    super(parent);

    makeObservable(this, {
      data: observable,
      parent: observable,
      total: computed,
      totalReconstruction: computed,
      updateValue: action,
      setParent: action,
    });

    this.data = Station.formatStationData(data);
    this.parent = parent;
  }

  static formatStationData = (data: StationType) => {
    const { dsw, ciw, equipment, other } = data.construction!;
    const {
      dsw: reconstructionDsw,
      ciw: reconstructionCiw,
      equipment: reconstructionEquipment,
      other: reconstructionOther,
      period,
    } = data.reconstruction!;

    return {
      ...data,
      dsw,
      ciw,
      equipment,
      other,
      reconstructionDsw,
      reconstructionCiw,
      reconstructionEquipment,
      reconstructionOther,
      period,
    };
  };

  get total() {
    const { dsw, ciw, equipment, other } = this.data!;
    return dsw + ciw + equipment + other;
  }
  get totalReconstruction() {
    const { reconstructionDsw, reconstructionCiw, reconstructionEquipment, reconstructionOther } = this.data!;
    return reconstructionDsw + reconstructionCiw + reconstructionEquipment + reconstructionOther;
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const { construction, reconstruction } = this.data;
    const [k, value] = [key as keyof StationType, newValue as never];
    if (k === "stationType" && this.data.stationType !== value) {
      this.parent.move(this, value);
    }

    const prev = this.data[k];
    this.data[k] = value;

    if (constructionKeys[key as keyof StationType["construction"]]) {
      const newConstruction = { ...construction!, [key]: value, totalCostPerUnit: this.total };
      this.catalog.update({ uuid: this.data.uuid, construction: newConstruction }, "station");
      this.data.construction = newConstruction;
    } else if ((reconstructionKeys as any)[key]) {
      const k = key as keyof typeof reconstructionKeys;
      const newReconstruction = {
        ...reconstruction!,
        [reconstructionKeys[k]]: value,
        totalCostPerUnit: this.totalReconstruction,
      };
      this.data.reconstruction = newReconstruction;
      this.catalog.update({ uuid: this.data.uuid, reconstruction: newReconstruction }, "station");
    } else {
      this.catalog.update({ uuid: this.data.uuid, [k]: value }, "station");
    }

    return [prev, value];
  }

  public setParent = (parent: Stations) => {
    this.parent = parent;
  };

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.parent.remove(this.data.uuid, this.index);
  };
}

class Stations extends TableNode<DRow, Station> {
  public asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(private parent: StationsModel, private name: string, private stations: StationType[]) {
    super(parent, { isExpandedChildren: true });

    makeObservable<Stations, "init">(this, {
      init: action,
      move: action,
      push: action,
    });

    this.init();
  }

  public init = () => {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.stations.map((mine) => new Station(this, mine, this.parent.infrastructure.catalog)) ?? []
    );
  };

  public push = (type?: string) => {
    const stationType = type as StationType["stationType"];
    const newStation: StationType = {
      id: Math.floor(Math.random() * 1000),
      uuid: getRandomUid(),
      title: "Новая станция",
      stationType,
      deltaPressure: 0,
      power: 0,
      construction: {
        dsw: 0,
        ciw: 0,
        other: 0,
        equipment: 0,
        totalCostPerUnit: 0,
      },
      reconstruction: {
        dsw: 0,
        ciw: 0,
        other: 0,
        equipment: 0,
        totalCostPerUnit: 0,
        isActive: false,
        period: 0,
      },
    };
    this.parent.infrastructure.catalog.push(newStation, "station");
    this.childrenStore?.push(new Station(this, newStation, this.parent.infrastructure.catalog));
  };

  public move(station: Station, type: StationType["stationType"]) {
    if (station.index === undefined) {
      return;
    }
    this.childrenStore?.splice(station.index, 1);
    this.parent[stations[type]]?.childrenStore?.push(station);
    station.setParent(this.parent[stations[type]]!);
  }

  public remove = (uuid: string, index: number) => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.parent.infrastructure.catalog.remove(uuid, "station");
    this.childrenStore?.splice(index, 1);
  };
}

class StationsModel extends TableNode<DRow, Stations> {
  PSStations?: Stations;
  GPUStations?: Stations;
  PUStations?: Stations;
  WKUStations?: Stations;

  constructor(public infrastructure: Infrastructure) {
    super();

    makeObservable(this, {
      PSStations: observable,
      GPUStations: observable,
      PUStations: observable,
      WKUStations: observable,
    });
    this.init();
  }

  public init = () => {
    const { stationsAt } = this.infrastructure.catalog;
    runInAction(() => {
      this.PSStations = new Stations(this, "НС", stationsAt("НС"));
      this.GPUStations = new Stations(this, "УКПГ", stationsAt("УКПГ"));
      this.PUStations = new Stations(this, "УПН", stationsAt("УПН"));
      this.WKUStations = new Stations(this, "УПСВ", stationsAt("УПСВ"));

      this.childrenStore = new ChildrenStoreArray(this, [
        this.PSStations,
        this.GPUStations,
        this.PUStations,
        this.WKUStations,
      ]);
    });
  };

  public push = (type?: string) => {
    const stationType = type as StationType["stationType"];
    this[stations[stationType]]?.push(type);
  };
}

export { type DRow as DRowStations, StationsModel };
