import { FC } from "react";
import { Button, ButtonProps, Tooltip } from "antd";

import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { getUserPermission, Sections } from "services/back/roles";

type PermissionButtonProps = ButtonProps & {
  section: Sections;
};

const PermissionButton: FC<PermissionButtonProps> = ({ section, disabled, ...props }) => {
  const project = useProject()!;
  const forecast = useForecast();
  const edit = getUserPermission(project, forecast)[section];
  return (
    <Tooltip title={edit.value ? "" : edit.customTooltip || "Для выполнения действия недостаточно полномочий вашей роли в проекте"}>
      <Button disabled={disabled || !edit.value} {...props} />
    </Tooltip>
  );
};

export { PermissionButton };
