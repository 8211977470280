import { useCallback } from "react";

import { useModal } from "elements/modal/modal";

import { MultiPeriodForm, type PeriodSettings } from "./multiPeriodForm";

const useMultiPeriodModal = () => {
  const modal = useModal<PeriodSettings>();

  return useCallback(() => modal(MultiPeriodForm, undefined, "Периоды нагнетания", "Применить", "Отменить", "540px"), [modal]);
};

export { useMultiPeriodModal };
