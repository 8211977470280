import React, { FC, memo } from "react";

import cn from "./bar.module.less";
type BarType = {
  x: number;
  y: number;
  width: number;
  zeroLevel: number;
  limit?: number;
  color?: string;
};

const ErrorBar: FC<BarType> = ({ x, y, width, zeroLevel }) => {
  return (
    <>
      <rect className={cn.errorBarBorder} x={x} y={y} width={width} height={1} />
      <rect className={cn.errorBar} x={x} y={y} width={width} height={zeroLevel - y} />
    </>
  );
};

const Bar: FC<BarType> = memo(({ x, y, width, zeroLevel, limit, color }) => {
  if (limit !== undefined && limit <= y) {
    return (
      <>
        <rect x={x} y={y} width={width} height={1} style={{ fill: color }} />
        <rect x={x} y={y} width={width} height={zeroLevel - y} style={{ fill: color, opacity: 0.65 }} />
        <rect x={x} y={limit} width={width} height={2} style={{ fill: color }} />
      </>
    );
  }
  if (limit !== undefined && limit > y) {
    return (
      <>
        <rect x={x} y={limit} width={width} height={1} style={{ fill: color }} />
        <rect x={x} y={limit} width={width} height={zeroLevel - limit} style={{ fill: color, opacity: 0.65 }} />
        <ErrorBar x={x} y={y} width={width} zeroLevel={limit} />
      </>
    );
  }
  return (
    <>
      <rect x={x} y={y} width={width} height={1} style={{ fill: color }} />
      <rect x={x} y={y} width={width} height={zeroLevel - y} style={{ fill: color, opacity: 0.65 }} />
    </>
  );
});

export { Bar };
