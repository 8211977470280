import { DecreaseEffectChartFeature } from "./decreaseEffectChartFeature";

const DecreaseEffectChartExample = () => {
  return (
    <div style={{ display: "flex", flex: 1, height: "100%" }}>
      <DecreaseEffectChartFeature />
    </div>
  );
};

export { DecreaseEffectChartExample };
