import { computed, makeObservable } from "mobx";

import type { LoadableStore, Storable } from "./loadableStore";

class LoadableStoreResolver<T extends Storable, Raw = T> {
  constructor(protected readonly store: LoadableStore<T, Raw>, public readonly ids: number[]) {
    makeObservable<LoadableStoreResolver<T, Raw>>(this, {
      isLoading: computed,
      items: computed,
      selector: computed,
    });
  }

  public get isLoading(): boolean {
    return this.store.isLoading;
  }

  public get items(): T[] | undefined {
    if (this.store.isLoading) {
      return undefined;
    }
    return this.ids
      .filter((id) => {
        const presented = this.store.has(id)!;
        console.assert(presented, `LoadableStore (${this.store.constructor.name}) doesnt contain item with id = ${id}`);
        return presented;
      })
      .map((id) => this.store.at(id)!);
  }

  public get selector(): { value: number; label: string | undefined }[] {
    return this.ids
      .map((id) => ({
        value: id,
        label: ((item) => (item ? item.title : item))(this.store.at(id)),
      }))
      .filter(({ label }) => label !== null) as { value: number; label: string | undefined }[];
  }

  public map<R>(callback: (entry: T) => R): R[] | undefined {
    return this.items?.map(callback);
  }

  public find(predicate: (item: T) => boolean): T | null | undefined {
    return this.items?.find(predicate);
  }

  public at(id: number): T | null | undefined {
    return this.store.at(id);
  }

  public get first(): T | undefined {
    return this.items?.[0];
  }

  public get length(): number {
    return this.ids.length;
  }
}

export { LoadableStoreResolver };
