import { FC } from "react";
import { GradientContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { PipeType, useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { fromUniformArray, LEGEND_MAP_COLOR } from "../utils";

import { Pipe } from "./pipe";

const Pipes: FC = observer(() => {
  const { nodes, waterPipes, oilPipes, pipeMap, range } = useInfrastructureMapContext();

  const [minPressure, maxPressure] = range;
  const colorScale = fromUniformArray(LEGEND_MAP_COLOR, minPressure, maxPressure);

  const getPreparedPipes = (pipes: PipeType[]) => {
    const getPoint = (id: string) => nodes.find((el) => el.uuid === id)!;
    return pipes.map((pipe) => ({
      ...pipe,
      isEmpty: pipe.category === null,
      velocity: pipeMap?.get(pipe.uuid)?.velocity || 0,
      from: { ...getPoint(pipe.from), pressure: pipeMap?.get(pipe.uuid)?.pressure[0] || 0 },
      to: { ...getPoint(pipe.to), pressure: pipeMap?.get(pipe.uuid)?.pressure[1] || 0 },
    }));
  };

  const [preparedWaterPipes, preparedOilPipes] = [getPreparedPipes(waterPipes), getPreparedPipes(oilPipes)];
  return (
    <g>
      <GradientContext colorScale={colorScale}>
        {preparedWaterPipes.map((pipe) => (
          <Pipe key={pipe.uuid} pipe={pipe} type="water" />
        ))}
        {preparedOilPipes.map((pipe) => (
          <Pipe key={pipe.uuid} pipe={pipe} type="oil" />
        ))}
      </GradientContext>
    </g>
  );
});

export default Pipes;
