import { FC, ReactNode } from "react";
import { Typography } from "antd";

import cn from "../inspectorMenu.module.less";

const Container: FC<{ title: ReactNode; children: ReactNode }> = ({ title, children }) => (
  <div className={cn.menu}>
    <Typography className={cn.title}>{title}</Typography>
    <div className={cn.container}>{children}</div>
  </div>
);

export { Container };
