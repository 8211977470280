import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.83333 8.00033C3.83333 5.42261 5.92261 3.33333 8.50033 3.33333C11.078 3.33333 13.1673 5.42261 13.1673 8.00033C13.1673 10.578 11.078 12.6673 8.50033 12.6673C5.92261 12.6673 3.83333 10.578 3.83333 8.00033ZM8.50033 2C5.18623 2 2.5 4.68623 2.5 8.00033C2.5 11.3144 5.18623 14.0007 8.50033 14.0007C11.8144 14.0007 14.5007 11.3144 14.5007 8.00033C14.5007 4.68623 11.8144 2 8.50033 2ZM8.97103 5.60514C8.97103 5.23695 8.67255 4.93848 8.30436 4.93848C7.93617 4.93848 7.6377 5.23695 7.6377 5.60514V8.39998C7.6377 8.76817 7.93617 9.06665 8.30436 9.06665H10.4997C10.8679 9.06665 11.1663 8.76817 11.1663 8.39998C11.1663 8.03179 10.8679 7.73331 10.4997 7.73331H8.97103V5.60514Z"
      fill="currentColor"
    />
  </g>
);

const ClockIcon: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 16 16" content={ASSET} />;

export { ClockIcon };
