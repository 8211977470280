import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable, runInAction } from "mobx";

import { WellParsedRaw } from "services/back/wells";

import { Forecast } from "../../forecast/forecast";

import { DRow } from "./listFlat";
import { WellNode, WellRawOptional } from "./wellNode";

class WellsPreview extends TableNode<DRow, WellNode> {
  constructor(public readonly forecast: Forecast, data: WellParsedRaw[]) {
    super();

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        data.map((d) => new WellNode(this, d))
      );
    });

    makeObservable<WellsPreview>(this, {
      isValid: computed,
      isComplete: computed,
    });
  }

  public get isComplete(): boolean {
    return [...(this.children ?? [])].every(
      ({ isComplete, isDuplicatedWell, data: { well_id } }) => isComplete && !isDuplicatedWell && well_id === null
    );
  }

  public get isValid(): boolean {
    if (!this.children) {
      return false;
    }
    return [...(this.children ?? [])].some(
      ({ isComplete, isDuplicatedWell, data: { well_id } }) => isComplete && !isDuplicatedWell && well_id === null
    );
  }

  public get newRows(): WellRawOptional[] {
    return [...(this.children ?? [])]
      .filter(
        (well) =>
          well.isComplete && well.data.title !== null && well.data.well_id === null && well.isDuplicatedWell === false
      )
      .map((well) => well.data);
  }

  public propagateDuplicates() {
    const existedTitles = new Set<string>();
    for (const well of this.childrenStore?.children ?? []) {
      if (!well.data.title) {
        continue;
      }
      if (existedTitles.has(well.data.title)) {
        well.isDuplicatedWell = true;
      } else {
        well.isDuplicatedWell = false;
      }
      existedTitles.add(well.data.title);
    }
  }
}

export { WellsPreview };
