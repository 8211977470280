import { FC } from "react";
import { Table } from "@okopok/components/Table/Table";
import { observer } from "mobx-react-lite";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Loader } from "elements/loader";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { hasStoreNoData } from "utils/hasStoreNoData";

import { DrainSources } from "./models/drainSources";
import { WellPadsModel } from "./models/mines";
import { PipesModel } from "./models/pipes";
import { StationsModel } from "./models/stations";

import cn from "./infrastructureParamsTable.module.less";

type Props = {
  mode: string;
  columns: Column[];
  store: WellPadsModel | StationsModel | DrainSources | PipesModel;
};

const InfrastructureParamsTable: FC<Props> = observer(({ mode, store, columns }) => {
  if (store.isLoading) {
    return <Loader />;
  }

  return (
    <SimpleTableContext
      data={store}
      columns={columns}
      exportFileName="Таблица параметров"
      hideExpandColumn={mode === "drain" || mode === "drain"}
      tableSettingsId="infrastructure_table_params"
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className:
            expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? `${cn.tableRowPrimary} ${cn.rowBorderNone}` : cn.tableRowPlain,
        }),
      }}
    >
      {hasStoreNoData(store) ? (
        <FullScreenEmpty>Нет данных для отображения</FullScreenEmpty>
      ) : (
        <div style={{ height: "100%" }}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      )}
    </SimpleTableContext>
  );
});

export { InfrastructureParamsTable };
