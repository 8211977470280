import { createContext, PropsWithChildren, useContext } from "react";

import { RankingResults } from "models/project/fact/forecast/ranking/results/results";

const RankingResultsContext = createContext<RankingResults | null>(null);

const RankingResultsContextProvider = ({ value, children }: PropsWithChildren<{ value: RankingResults }>) => {
  return <RankingResultsContext.Provider value={value}>{children}</RankingResultsContext.Provider>;
};

const useRankingResults = (): RankingResults => {
  console.assert(useContext<RankingResults | null>(RankingResultsContext) !== null, "useRankingResults out of context");
  return useContext<RankingResults | null>(RankingResultsContext)!;
};

export { RankingResultsContextProvider, useRankingResults };
