import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { axisWidth, colorPrimary } from "theme/global";

import { AxisPosition } from "../Axes/Axes";
import { Domain, YAxisType } from "../D3SVG/D3SVG";
import { useD3Context } from "../D3SVG/D3SVG";

import cn from "./YAxis.module.less";
type YAxisProps = {
  translateX: number;
  axis: YAxisType & { domain: Domain };
  position: AxisPosition;
};

const YAxis = observer(({ translateX, axis, position }: YAxisProps) => {
  const model = useD3Context();
  const refAxis = useRef(null);
  const refAxisGrid = useRef(null);

  const refTitle = useRef(null);

  useEffect(() => {
    const yScale = d3
      .scaleLinear()
      .domain([model.YDomains[axis.id].min, model.YDomains[axis.id].max])
      .range([model.height - model.padding.bottom, model.padding.top])
      .nice();
    const yAxis: any = (position === AxisPosition.Right ? d3.axisRight : d3.axisLeft)(yScale)
      .ticks(Math.min(axis!.tickNumber ?? 6, axis.domain.max - axis!.domain.min + 1))
      .tickFormat((d) => d3.format("d")(d));
    const yAxisGrid: any = (position === AxisPosition.Right ? d3.axisRight : d3.axisLeft)(yScale)
      .ticks(Math.min(axis!.tickNumber ?? 6, axis.domain.max - axis!.domain.min + 1))
      .tickFormat((d) => d3.format("d")(d));
    d3.select(refAxis.current)
      .call(yAxis.tickSize(5))
      .select(".domain")
      .style("opacity", model.activeAxis === axis.id ? 1 : 0.1);
    if (model.grid?.y?.yId === axis.id) {
      d3.select(refAxisGrid.current)
        .call(yAxisGrid.tickSize(model.width - axisWidth * (model.leftAxisCount + model.rightAxisCount) - model.padding.left - model.padding.right))
        .select(".domain")
        .style("opacity", 0);
      d3.select(refAxisGrid.current).selectAll("line").style("opacity", 0.1);
      d3.select(refAxisGrid.current).selectAll("text").style("opacity", 0);
    }
    d3.select(refAxis.current).selectAll("line").style("opacity", 0.1);

    if (model.activeAxis === axis.id) {
      d3.select(refAxis.current).select(".domain").style("opacity", 1);
      d3.select(refAxis.current).selectAll("line").style("opacity", 1);
    }

    d3.select(refTitle.current)
      .style("text-anchor", "middle")
      .attr("y", model.padding.top + (model.height - model.padding.bottom - model.padding.top) / 2 - (position === AxisPosition.Right ? 45 : 25))
      .attr("x", translateX - (position === AxisPosition.Right ? 0 : model.padding.left))
      .text(axis!.uom ?? "")
      .attr(
        "transform",
        `rotate(${position === AxisPosition.Right ? 90 : -90}, ${translateX - (position === AxisPosition.Right ? 0 : model.padding.left)}, ${
          model.padding.top + (model.height - model.padding.bottom - model.padding.top) / 2
        })`
      );
  }, [
    axis,
    model.YDomains,
    model.activeAxis,
    model.grid?.y?.yId,
    model.height,
    model.leftAxisCount,
    model.padding.bottom,
    model.padding.left,
    model.padding.right,
    model.padding.top,
    model.rightAxisCount,
    model.width,
    position,
    translateX,
  ]);

  return (
    <>
      <g className={model.activeAxis === axis.id ? cn.active : "a"}>
        <g ref={refAxis} transform={`translate(${translateX}, 0)`}></g>

        <text ref={refTitle} fill={model.activeAxis === axis.id ? colorPrimary : axis!.color ?? "black"}></text>
      </g>
      {model.grid?.y !== undefined && model.grid?.y.show && model.grid.y.yId === axis.id && (
        <g
          ref={refAxisGrid}
          transform={`translate(${
            position === AxisPosition.Left
              ? model.width - model.padding.right - model.rightAxisCount * axisWidth
              : model.padding.left + model.leftAxisCount * axisWidth
          }, 0)`}
        ></g>
      )}
    </>
  );
});

export default YAxis;
