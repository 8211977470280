import type { UserData } from "services/auth";

class User {
  constructor(public name: string, public email: string, public id: number, public roles: string[]) {}

  static fromService({ email, username, id, roles }: UserData): User {
    return new User(username, email, id, roles);
  }
}

export { User };
