import type { FC } from "react";
import { Select } from "antd";

import { useProject } from "models/project/project";

type RangeSelectorProps = {
  value: "full" | "forecast";
  onChange: (newValue: "full" | "forecast") => void;
};

const RangeSelector: FC<RangeSelectorProps> = ({ value, onChange }) => {
  const project = useProject()!;
  return (
    <Select
      value={value}
      onChange={onChange}
      options={[
        { value: "full", label: `С начала проекта (${project.startYear} - ${project.lastForecastYear} )` },
        { value: "forecast", label: `С первого прогнозного года (${project.fact.lastDescribedYear + 1} - ${project.lastForecastYear} )` },
      ]}
    />
  );
};

export { RangeSelector };
