import { useMemo } from "react";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";

import { DrainSourcesModel } from "../models/drainSources";

import cn from "../infrastructureResultsTable.module.less";

const useColumns = (type: string): Column[] => {
  return useMemo(
    (): Column[] => [
      {
        title: type === "drain" ? "Название стока" : "Название источника",
        width: { min: 410, max: 500, competitiveness: 1 },
        isSticky: true,
        dataKey: "title",
        type: "string",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Дата",
        width: { min: 430, max: 500, competitiveness: 1 },
        isSticky: true,
        dataKey: "date",
        onCell: () => ({ className: cn.tableCell }),
        type: "string",
      },
      {
        title: type === "drain" ? "Давление приема, бар" : "Давление нагнетания, бар",
        width: { min: 430, max: 500, competitiveness: 1 },
        dataKey: "pressure",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        type: "number",
      },
    ],
    [type]
  );
};

const useResultDrainSourcesTableStore = (type: string) => {
  const infrastructure = useInfrastructure()!;
  const { isCalculation } = infrastructure.calculateStore;

  const columns = useColumns(type); // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new DrainSourcesModel(infrastructure, type as "drain"), [infrastructure, type, isCalculation]);
  return { columns, store };
};

export { useResultDrainSourcesTableStore };
