import { ResultPage } from "routing/outlines/result/resultPage";

import { WellsResult } from "features/results/wells";

const WellsReport = () => (
  <ResultPage scroll={false} title="Результаты расчета: поскважинный расчет">
    <WellsResult />
  </ResultPage>
);

export { WellsReport };
