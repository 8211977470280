import { useMemo } from "react";
import { observer } from "mobx-react";

import { useForecast } from "models/project/fact/forecast/forecast";

import { MiningValueChart } from "./miningValueChart";
import { MiningValueChartModel } from "./miningValueChartModel";

const MiningValueChartFeature = observer(() => {
  const forecast = useForecast()!;
  const chartModel = useMemo(() => {
    return new MiningValueChartModel(forecast);
  }, [forecast]);
  return (
    <div style={{ display: "flex", flex: 1, height: "100%" }}>
      <MiningValueChart chartModel={chartModel} />
    </div>
  );
});
export { MiningValueChartFeature };
