import { FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { TableDebug } from "features/tableDebug/tableDebug";

const TableDebugPage: FC = () => (
  <PageFrame title="Универсальная таблица">
    <TableDebug />
  </PageFrame>
);

export { TableDebugPage as TableDebug };
