import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { PopconfirmProps } from "antd";
import { runInAction } from "mobx";

import { ProjectParticipantInfo } from "services/back/projectParticipants";
import { addUser, deleteUser } from "services/back/users";

import { global } from "./global";

type UserInfo = ProjectParticipantInfo & {
  remove?: (email: string) => Promise<void>;
  removeConfirm?: PopconfirmProps;
};

class UsersStore extends TableNode<UserInfo, ParticipantNode> {
  constructor(private canDelete?: boolean) {
    super();

    this.init();
  }

  init = () => {
    const participants = Array.from(global.users.values!);

    this.childrenStore = new ChildrenStoreArray(
      this,
      participants.map(
        (info) =>
          new ParticipantNode(this, info, this.canDelete ? () => this.deleteUser(info.email) : undefined, {
            title: "Удалить пользователя?",
            cancelText: "Отмена",
            okText: "Удалить",
          })
      )
    );
  };

  deleteUser = async (email: string) => {
    const { id } = await deleteUser(email);
    global.users.delete(id);
    this.init();
  };

  addUser = async (email: string) => {
    const newUser = await addUser(email);
    if (newUser.id && !global.users.at(newUser.id)) {
      global.users.add(newUser);
      this.init();
    }
  };
}

class ParticipantNode extends TableNode<UserInfo> {
  public asDRow(): UserInfo {
    return {
      ...this.personInfo,
      remove: this.remove,
      removeConfirm: this.removeConfirm,
    };
  }
  constructor(
    parentNode: UsersStore,
    public readonly personInfo: UserInfo,
    public readonly remove?: (email: string) => Promise<void>,
    public readonly removeConfirm?: PopconfirmProps
  ) {
    super(parentNode, { isExpandedChildren: true });
    runInAction(() => (this.childrenStore = null));
  }
}

export { UsersStore };
export type { UserInfo };
