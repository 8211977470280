import { UOMResolver } from "elements/uom";
import { req } from "utils/request";

// units of measure

type UOMResponse = {
  uom: Array<{
    measures: [number, string][];
    name: string;
    id: number;
  }>;
};

const getUOMResolver = async (): Promise<UOMResolver[]> => {
  const response = await req.get<UOMResponse>("demo/schemas/uom");
  return response.uom.map(({ measures, ...spread }) => ({
    ...spread,
    measures: measures.map(([_, v]) => v),
  }));
};

export { getUOMResolver, type UOMResponse };

/* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
[
  {
    name: "",
    id: 0,
    measures: [""],
  },
  {
    name: "Длина",
    id: 1,
    measures: ["", "м", "тыс м", "мм", "фут", "дюйм"],
  },
  {
    name: "Валюта",
    id: 2,
    measures: ["", "₽", "тыс ₽", "млн ₽", "$", "тыс $", "млн $"],
  },
  {
    name: "Доля",
    id: 3,
    measures: ["", "д.ед", "%"],
  },
  {
    name: "Время",
    id: 4,
    measures: ["", "сут", "год"],
  },
  {
    name: "Масса",
    id: 5,
    measures: ["", "г", "кг", "т", "тыс т", "млн т", "тыс ТУТ"],
  },
  {
    name: "Расход массы",
    id: 6,
    measures: ["", "т/сут"],
  },
  {
    name: "Объем",
    id: 7,
    measures: ["", "м^3", "н.м^3", "тыс н.м^3", "млн н.м^3", "млн м^3", "тыс. м^3"],
  },
  {
    name: "Расход объема",
    id: 8,
    measures: ["", "м^3/сут", "н.м^3/сут", "тыс н.м^3/сут", "млн н.м^3/сут"],
  },
  {
    name: "Плотность",
    id: 9,
    measures: ["", "г/н.м^3"],
  },
  {
    name: "Удельный объем",
    id: 10,
    measures: ["", "м^3/т"],
  },
  {
    name: "Цены",
    id: 11,
    measures: [
      "",
      "₽/т",
      "тыс. ₽/т",
      "₽/скв",
      "тыс ₽/скв",
      "₽/скв-оп",
      "тыс ₽/скв-оп",
      "₽/м",
      "тыс ₽/м",
      "$/брр",
      "₽/$",
      "% (₽/т с 2012 года)",
      "₽/тыс м^3",
      "$/т",
      "$/€",
      "$/тыс м^3",
    ],
  },
  {
    name: "Операции",
    id: 12,
    measures: ["", "скв", "скв-оп"],
  },
  {
    name: "Опция",
    id: 13,
    measures: ["", "да/нет"],
  },
];
