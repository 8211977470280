import { LoadableStore } from "models/loadableStore/loadableStore";
import { deleteForecast, ScenarioRaw } from "services/back/project";

import { type Fact, useFact } from "../fact";

import { Forecast } from "./forecast";

class Forecasts extends LoadableStore<Forecast> {
  constructor(forecasts: ScenarioRaw[], private fact: Fact) {
    super(() => Promise.resolve(forecasts).then((rawData) => rawData.map(Forecast.fromRaw(fact))));
  }

  deleteForecast = async (id: number) => {
    await deleteForecast(id);
    this.delete(id);
  };

  insertCopy(copy: Forecast, originId: number) {
    this.insert(copy, this.findIndex(({ id }) => id === originId)! + 1);
  }
}

const useForecasts = (): Forecasts | undefined | null => {
  const fact = useFact();
  return fact ? fact.forecasts : fact;
};

export { Forecasts, useForecasts };
