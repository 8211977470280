import { action, computed, makeObservable, observable, reaction } from "mobx";

import { NUMBER_FORMAT } from "elements/format/format";
import type { ParamsRow as FinancialParamsRow } from "features/useMetrics/paramsRow";
import { Table as TableModel } from "models/table";
import { type Metric, type MetricTree as RawMetric } from "services/finance/finance";
import { Range } from "utils/range";
import { numerateTree, ofTree } from "utils/tree";

import { ParamsNode } from "./paramsNode";

class ParamsTable extends TableModel {
  public metrics?: ParamsNode[];
  private isUpdatedValue: boolean = false;

  constructor(
    tableTitle: string,
    public readonly years: Range,
    public getParams: (range: Range) => Promise<RawMetric[]>,
    public setParams: (fin: { finance: Metric[] }) => Promise<RawMetric[]>,
    private readonly origin: ParamsTable | null = null
  ) {
    super(tableTitle);
    makeObservable<ParamsTable, "isUpdatedValue" | "personalValues">(this, {
      ...TableModel.inherited,

      isUpdatedValue: observable,
      setIsUpdated: action,
      metrics: observable,
      personalValues: computed,
      clone: computed,
      fromRaw: action,
    });

    if (origin === null) {
      getParams(this.years).then((raw) => this.fromRaw(raw));
    } else {
      reaction(
        () => this.personalValues,
        () => this.resetUpdated()
      );
    }
  }

  export(): (string | null)[][] {
    const toString = (v: null | undefined | boolean | string | number): string | null => {
      if (v === null || v === undefined) {
        return null;
      }
      if (typeof v === "boolean") {
        return v ? "да" : "нет";
      }
      if (typeof v === "number") {
        return NUMBER_FORMAT.real_4.format(v);
      }
      return v;
    };

    return [
      ["Параметр", "Единицы измерения", ...[...this.years].map((v) => `${v}`)],
      ...this.tableItems.map(({ title, measure, values }) => [title, measure, ...(values?.map(toString) ?? [])]),
    ];
  }

  private get personalValues() {
    return this.metrics?.flatMap((m) => m.flatten()).map((m) => m.values);
  }

  public fromRaw(raw: RawMetric[]): void {
    this.metrics = raw.map(ParamsNode.fromRaw);
    // после того как дерево создано выставляем всем потомкам ссылку на родителя
    this.metrics.forEach((v) => v.propagateParent());
  }

  public get clone(): ParamsTable {
    const params = new ParamsTable(this.title, this.years, this.getParams, this.setParams, this);
    params.metrics = this.metrics?.map((metric) => metric.cloneAsRoot());
    return params;
  }

  public get tableItems(): FinancialParamsRow[] {
    console.assert(this.metrics !== undefined, "Попытка отобразить таблицу до завершения её загрузки");
    const result = this.metrics!.map((v) => v.tableItems);
    numerateTree(result);
    return result;
  }

  public get isLoading() {
    return this.metrics === undefined;
  }

  public get isUpdated(): boolean {
    return this.isUpdatedValue;
  }

  public setIsUpdated(newState: boolean) {
    this.isUpdatedValue = newState;
  }

  public resetUpdated() {
    if (this.origin === null) {
      this.setIsUpdated(false);
    } else {
      this.setIsUpdated(!this.isEqual(this.origin));
    }
  }

  public isEqual(rhs: ParamsTable): boolean {
    return ParamsTable.isEqual(this, rhs);
  }

  public static isEqual(model1: ParamsTable, model2: ParamsTable): boolean {
    const metrics1 = ofTree(model1.metrics ?? [])[Symbol.iterator]();
    const metrics2 = ofTree(model2.metrics ?? [])[Symbol.iterator]();
    while (true) {
      const node1 = metrics1.next();
      const node2 = metrics2.next();
      if (node1.done && node2.done) return true;
      if (node1.done || node2.done) return false;
      if (!ParamsNode.isEqual(node1.value, node2.value)) {
        return false;
      }
    }
  }

  public get isCompleted(): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.metrics!.every((m) => m.isCompleted);
  }

  public async update(updatedModel: ParamsTable | null): Promise<void> {
    if (updatedModel === null) return;

    const request: Metric[] = updatedModel.metrics!.flatMap((m) => m.flatten());
    return this.setParams({ finance: request }).then(
      (rawMetrics) => this.fromRaw(rawMetrics),
      (error) => console.error(error)
    );
  }

  public findByTitle(title: string): ParamsNode | null | undefined {
    if (!this.metrics) {
      return this.metrics;
    }
    for (const metric of ofTree(this.metrics)) {
      if (metric.title === title) {
        return metric;
      }
    }
    return null;
  }
}

export { ParamsTable };
