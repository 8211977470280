import { FC } from "react";
import { Progress, Typography } from "antd";
import { observer } from "mobx-react";

import { setupAccuracy } from "utils/round";

import { useTechForecastModel } from "./useTechForecastModel";

import cn from "./loadScreen.module.less";

const LoadScreen: FC = observer(() => {
  const model = useTechForecastModel();

  const { amount, forecastReadyCount, savedCount } = model;

  return (
    <div className={cn.layout}>
      <div>
        <Progress type="circle" percent={setupAccuracy((forecastReadyCount / amount) * 100)} />
        <Typography.Title level={3}>
          Выполнение прогноза
          <br />
          {forecastReadyCount} / {amount}
        </Typography.Title>
      </div>
      <div>
        <Progress type="circle" percent={setupAccuracy((savedCount / amount) * 100)} />
        <Typography.Title level={3}>
          Сохранение результатов
          <br />
          {savedCount} / {amount}
        </Typography.Title>
      </div>
    </div>
  );
});

export { LoadScreen };
