import type { ColumnRaw } from "@okopok/components/Table";
import classNames from "classnames";

import { DebugZone } from "elements/debugZone/debugZone";
import { Column } from "features/tableDebug/simpleTable";
import { global } from "models/global";

import cn from "./debugColumn.module.less";

function debugColumns<T>(columns: Omit<ColumnRaw<T>, "onCell" | "onHeaderCell">[]): ColumnRaw<T>[] {
  if (!global.IS_DEBUG_ZONE) {
    return [];
  }

  return columns.map(({ render, title, isExported, ...rest }) => ({
    ...rest,
    title: <DebugZone tag="span">{title}</DebugZone>,
    render: (...args) => ((render || args[0]) !== undefined ? <DebugZone tag="span">{render?.(...args) ?? args[0]}</DebugZone> : <div />),
    onCell: () => ({ className: cn.debugColumnCell }),
    onHeaderCell: () => ({ className: cn.debugColumnCell }),
    isExported: false,
  }));
}

function debugColumnsForSimpleTable(columns: Omit<Column, "type">[]): Column[] {
  if (!global.IS_DEBUG_ZONE) {
    return [];
  }

  return columns.map(({ title, isExported, ...rest }) => ({
    ...rest,
    title: <DebugZone tag="span">{title}</DebugZone>,
    type: "string",
    onCell: () => ({ className: classNames(cn.debugColumnCell, cn.cell) }),
    onHeaderCell: () => ({ className: classNames(cn.debugColumnCell, cn.cell) }),
    isExported: false,
  }));
}

export { debugColumns, debugColumnsForSimpleTable };
