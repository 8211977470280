import { Domain } from "@okopok/axes_context";
import * as d3 from "d3";

const valuesDomain = (m: Array<d3.NumberValue>): Domain => {
  let min = m.find((value) => Number.isFinite(value)) ?? Number.MIN_SAFE_INTEGER;
  let max = min;
  for (const v of m) {
    if (Number.isFinite(v)) {
      min = +v < +min ? v : min;
      max = +v > +max ? v : max;
    }
  }
  return { min, max };
};
const joinDomains = (a: Domain, b: Domain | undefined) =>
  b === undefined
    ? a
    : {
        min: +a.min < +b.min ? a.min : b.min,
        max: +a.max > +b.max ? a.max : b.max,
      };

const boundDomain = (m: Array<d3.NumberValue> | Domain): Domain =>
  Array.isArray(m) ? { min: m[0], max: m[m.length - 1] } : m;

const createDomainAccessor = <T extends d3.NumberValue[] | Domain>(x: T, length: number) => {
  if (Array.isArray(x)) {
    return (v: number): number => +x[v];
  }
  const scale = d3.scaleLinear().domain([0, length]).range([+x.min, +x.max]);
  return (v: number): number => scale(v);
};

const isCorrectDomain = (domain: d3.NumberValue[] | Domain) => {
  if (Array.isArray(domain)) {
    return domain.length > 0;
  } else {
    return +domain.max >= +domain.min;
  }
};

export { boundDomain, createDomainAccessor, isCorrectDomain, joinDomains, valuesDomain };
export type { Domain };
