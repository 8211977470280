import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { CompleteIcon } from "elements/icons/complete/complete";
import { Loader } from "elements/loader";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { conditionallyArr } from "utils/conditionally";

import { MenuByItSelf } from "./menuByItSelf";
import { usePagePostfix } from "./scenarioMenu";

type OnlyScenarioMenuProps = {};

const OnlyScenarioMenu: FC<OnlyScenarioMenuProps> = observer(() => {
  const [searchScenario, onSearchScenarioChange] = useState<string | undefined>(undefined);
  const pagePostfix = usePagePostfix();

  const navigate = useNavigate();
  const params = useMainRouterParams();
  const project = useProject();
  const forecast = useForecast();

  const onForecastClick: (a: { key: string }) => void = useCallback(
    ({ key }) => {
      const path = ["", params.project, ...conditionallyArr(key !== "fact", key), ...pagePostfix].join("/");
      navigate(path);
    },
    [navigate, pagePostfix, params.project]
  );

  const forecasts: MenuProps["items"] = project?.fact?.forecasts.map(({ title, isComplete, id }) => ({
    label: title,
    icon: <CompleteIcon completed={isComplete} />,
    key: id,
  })) ?? [{ key: "loading", label: <Loader /> }];

  return (
    <MenuByItSelf
      title="Сценарии"
      flex={1}
      alwaysOpen
      items={forecasts}
      onNavigate={onForecastClick}
      selectedKey={`${forecast?.id ?? "fact"}`}
      search={searchScenario}
      onSearchChange={onSearchScenarioChange}
    />
  );
});

export { OnlyScenarioMenu };
