import { action, computed, makeObservable, observable, reaction } from "mobx";

import { Infrastructure } from "./infrastructure";
import { PipeType } from "./pipes";

type GraphType = { [k: string]: string[] };

class PipesGraph {
  errors = observable.array<string>();

  constructor(private parent: Infrastructure) {
    makeObservable(this, {
      errors: observable,
      nodes: computed,
      drainNodeIds: computed,
      sourceNodeIds: computed,
      adjacencyList: computed,
      errorsString: computed,
      validatePipesConnectivity: action,
      validateNodesConnectivity: action,
      validateConnectivity: action,
    });

    this.validateConnectivity();
  }

  get nodes() {
    const nodes = (this.parent.nodes.data ?? []).filter((node) => node.type !== "node");
    return Infrastructure.filterDisabled(nodes, true, this.parent.currentDate);
  }

  get drainNodeIds() {
    return new Set(this.parent.nodes.drains.map((node) => node.uuid));
  }
  get sourceNodeIds() {
    return new Set(this.parent.nodes.sources.map((node) => node.uuid));
  }

  get adjacencyList() {
    return PipesGraph.buildAdjacencyList(this.parent.pipes.data ?? []);
  }

  public get errorsString() {
    const moreErrors = this.errors.length > 2 ? this.errors.length - 2 : 0;
    const moreText = !!moreErrors ? ` еще (${moreErrors})` : "";
    return `${this.errors.slice(0, !!moreErrors ? 2 : this.errors.length).join(", ")}` + moreText;
  }

  public validateConnectivity = () => {
    this.errors.clear();
    this.validatePipesConnectivity();
    this.validateNodesConnectivity();
  };

  validatePipesConnectivity = () => {
    (this.parent.pipes.data ?? []).forEach((pipe) => {
      const startNode = pipe.from;
      let errorPipe = `Трубопровод ${pipe?.title ?? ""} не прикреплен к`;

      if (pipe.segmentType === "prod") {
        if (!PipesGraph.pathExists(startNode, this.drainNodeIds, this.adjacencyList)) {
          this.errors.push(`${errorPipe} стоку`);
        }
      } else if (pipe.segmentType === "inj") {
        if (!PipesGraph.pathExists(startNode, this.sourceNodeIds, this.adjacencyList)) {
          this.errors.push(`${errorPipe} источнику`);
        }
      }
    });
  };

  validateNodesConnectivity = () => {
    const visited = new Set<string>();
    const queue = [...this.drainNodeIds, ...this.sourceNodeIds];
    const graph = this.adjacencyList;

    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) continue;
      if (visited.has(current)) continue;
      visited.add(current);

      if (graph[current]) {
        graph[current].forEach((neighbor) => {
          if (!visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      }
    }

    const unconnectedNodes = this.nodes.filter(({ uuid }) => !visited.has(uuid));
    unconnectedNodes.forEach(({ title }) => {
      this.errors.push(`${title} не соединен со стоком`);
    });
  };

  private static buildAdjacencyList = (pipes: PipeType[]) => {
    const graph: GraphType = {};
    pipes.forEach((pipe) => {
      if (!graph[pipe.from]) graph[pipe.from] = [];
      if (!graph[pipe.to]) graph[pipe.to] = [];
      graph[pipe.from].push(pipe.to);
      graph[pipe.to].push(pipe.from);
    });
    return graph;
  };

  private static pathExists = (startNode: string, targetNodes: Set<string>, graph: GraphType) => {
    const visited = new Set();
    const queue = [startNode];

    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) continue;
      if (visited.has(current)) continue;
      visited.add(current);

      if (targetNodes.has(current)) return true;

      if (graph[current]) {
        graph[current].forEach((neighbor) => {
          if (!visited.has(neighbor)) {
            queue.push(neighbor);
          }
        });
      }
    }
    return false;
  };
}

class InfrastructureValidator {
  pipesGraph = new PipesGraph(this.parent);
  validationErrors = observable.array<string>();

  constructor(public parent: Infrastructure) {
    makeObservable(this, {
      validationErrors: observable,
      pipesGraph: observable,
      validate: action,
      resetValidationErrors: action,
      hasNoPipes: computed,
      validationTooltip: computed,
      hasUncategorizedPipes: computed,
      hasNoDrains: computed,
      hasNoSources: computed,
      isCalculating: computed,
      isProductionLoading: computed,
      hasDisconnected: computed,
      missingBoundaryConditions: computed,
      cannotCalculate: computed,
      isNoValid: computed,
    });

    this.validate();

    reaction(() => {
      const { parent } = this;
      if (!parent.forecast) return;

      return (
        parent.forecast.production.isLoading ||
        parent.isUpdated ||
        parent.pipes.data?.length ||
        parent.nodes.data?.length ||
        parent.calculateStore.isCalculation
      );
    }, this.validate);
  }

  get isProductionLoading() {
    if (!this.parent.forecast) return false;
    return this.parent.forecast.production.isLoading;
  }

  get hasDisconnected() {
    return !!this.pipesGraph.errors.length;
  }

  get hasNoPipes() {
    return (this.parent.pipes.data ?? []).length === 0;
  }

  get hasUncategorizedPipes() {
    return (this.parent.pipes.data ?? []).some((el) => el.category === null);
  }

  get hasNoDrains() {
    return !!this.parent.pipes.oilPipes.length && !this.parent.nodes.drains.length;
  }

  get hasNoSources() {
    return !!this.parent.pipes.waterPipes.length && !this.parent.nodes.sources.length;
  }

  get isNoValid() {
    return this.parent.pipes.data?.some((el) => el.startedAt === null);
  }

  get missingBoundaryConditions() {
    if (this.parent.nodes.drains.length || this.parent.nodes.sources.length) {
      const notPressureDrainSources = [...this.parent.nodes.drains, ...this.parent.nodes.sources].filter(
        (el) => !this.parent.drainSourceBoundaryConditions.some((item) => el.uuid === item.nodeUuid)
      );
      return !!notPressureDrainSources.length;
    }
    return false;
  }

  get isCalculating() {
    return this.parent.calculateStore.isCalculation;
  }

  public get validationTooltip() {
    if (this.isCalculating) return "Идет расчет";
    if (!this.cannotCalculate) return "";
    return "Невозможно выполнить расчет: " + this.validationErrors.join(", ");
  }

  public get cannotCalculate() {
    return !!this.validationErrors.length;
  }

  public validate = () => {
    this.resetValidationErrors();
    this.pipesGraph.validateConnectivity();

    if (this.isProductionLoading) {
      this.validationErrors.push("Подготовка добычи");
      return;
    }

    if (this.hasNoPipes) this.validationErrors.push("Не задан ни один трубопровод");

    if (this.hasNoDrains) {
      this.validationErrors.push("Не задан сток");
      return;
    }

    if (this.hasNoSources) {
      this.validationErrors.push("Не задан источник");
      return;
    }

    if (this.hasUncategorizedPipes) {
      this.validationErrors.push("Не все трубы в обвязке");
      return;
    }

    if (this.hasDisconnected) {
      this.validationErrors.push(this.pipesGraph.errorsString);
      return;
    }

    if (this.missingBoundaryConditions) {
      this.validationErrors.push("Отсутствуют граничные условия давления для Стока или Источника");
      return;
    }

    if (this.isNoValid) {
      this.validationErrors.push("Заполните все данные о трубах для выполнения расчета.");
    }
  };

  public resetValidationErrors = () => {
    this.validationErrors.clear();
  };
}

export { InfrastructureValidator };
