import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { PopconfirmProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { Forecasts } from "models/project/fact/forecast/forecasts";
import { Interventions } from "models/project/fact/forecast/interventions/interventions";
import { Wells } from "models/project/fact/well/wells";
import { Range } from "utils/range";

import { Statuses } from "./statusCard";

type DRow = {
  title: string;
  id: number;
  date?: null;
  key?: string;
  removeRow?: () => void;
  removeConfirm?: PopconfirmProps;
  submitTitle: (newTitle: string) => void;
  wells?: Wells;
  hasNoWells?: boolean;
  end?: Dayjs | null;
  wholeRange?: Range;
  interventions?: Interventions;
  wellsCount?: number;
  gtmsCount?: number;
  isLoading?: boolean;
  techStatus: Statuses | null;
  economicStatus: Statuses | null;
  rankingStatus: Statuses | null;
  infrastructureStatus: Statuses | null;
};

class ScenariosListModel extends TableNode<DRow, Datum> {
  public readonly root = this;
  public rows: Forecasts;
  public selectedScenario: Forecast | null = null;
  public readonly onNavigate: (key: string, postfix: string) => void;
  public currentIndex: number = 0;

  constructor(public forecasts: Forecasts, onNavigate: (key: string, postfix: string) => void) {
    super();

    makeObservable<ScenariosListModel, "rows">(this, {
      rows: observable,
      selectedScenario: observable,
      forecasts: observable,
      currentIndex: observable,
      selectScenario: action,
    });

    this.rows = forecasts;
    this.onNavigate = onNavigate;

    this.childrenStore = new ChildrenStoreArray(
      this,
      this.rows?.map(
        (row) =>
          new Datum(this, row, {
            title: "Удалить сценарий?",
            cancelText: "Отмена",
            okText: "Удалить",
          })
      ) || []
    );
  }

  get isUpdated(): boolean {
    return super.isUpdated || this.root.isUpdated;
  }

  selectScenario(scenarioId: number) {
    this.selectedScenario = this.rows.at(scenarioId)!;
  }
}

class Datum extends TableNode<DRow> {
  public get root(): ScenariosListModel {
    return this.parent.root;
  }

  constructor(
    private readonly parent: Datum | ScenariosListModel,
    public readonly row: DRow,
    public readonly removeConfirm?: PopconfirmProps
  ) {
    super(parent);
    this.childrenStore = null;

    makeObservable(this, {
      hasNoWells: computed,
      wellsCount: computed,
      gtmsCount: computed,
      removeRow: action,
      submitTitle: action,
    });
  }

  public asDRow = (): DRow => ({
    title: this.row.title,
    id: this.row.id,
    date: this.row.date ?? null,
    key: this.row.id.toString(),
    removeConfirm: this.removeConfirm,
    removeRow: this.removeRow,
    submitTitle: this.submitTitle,
    wells: this.row.wells,
    hasNoWells: this.hasNoWells,
    end: Datum.endToDayJS(this.row.wholeRange?.to!),
    wellsCount: this.wellsCount,
    gtmsCount: this.gtmsCount,
    interventions: this.row.interventions,
    isLoading: this.isLoading,
    techStatus: this.row.techStatus,
    economicStatus: this.row.economicStatus,
    rankingStatus: this.row.rankingStatus,
    infrastructureStatus: this.row.infrastructureStatus,
  });

  public static endToDayJS(end: number): Dayjs {
    return dayjs().year(end).startOf("year");
  }

  public get hasNoWells() {
    return !this.row.wells?.wells.length;
  }

  public get wellsCount() {
    return this.row.wells?.wells.length ?? 0;
  }

  public get gtmsCount() {
    return this.row.interventions?.interventions.length ?? 0;
  }

  public removeRow = async () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }

    await this.root.forecasts.deleteForecast(this.row.id);

    this.parent.childrenStore?.splice(this.index, 1);
    this.root.onNavigate("fact", "scenario");
  };

  public submitTitle = (newTitle: string) => {
    this.row.submitTitle(newTitle);
    if (this.root.selectedScenario) {
      this.root.selectedScenario.title = newTitle;
    }
  };
}

export { type DRow, ScenariosListModel };
