import { TemplateCard } from "elements/importModalContent/templateInfoCards";

export const WELLS_TEMPLATE_INFO_CARDS: TemplateCard[] = [
  {
    widthBasis: 610,
    sections: [
      {
        title: "Скважина и куст",
        content: "Эти колонки должны быть заполнены.",
      },
      {
        title: "Лицензионный участок",
        content: "Должен соответствовать списку доступных ЛУ для проекта.\nОбязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 290,
    sections: [
      {
        title: "Тип заканчивания",
        content: "Может принимать только значения перечисленные выше.\nОбязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 1678,
    sections: [
      {
        title: "Координаты устья/забоя, cтартовый дебит нефти, жидкости, извлекаемые запасы, проходка",
        content: "Обязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 290,
    sections: [
      {
        title: "Дата ввода",
        content: "Должна входить в интервал планирования проекта.\nОбязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 430,
    sections: [
      {
        title: "Объект разработки",
        content: "Должен соответствовать списку объектов разработки проекта.\nОбязательно для заполнения.",
      },
    ],
  },
];
