import type { StratumRaw } from "services/back/stratums";

class Stratum {
  public readonly id: number;
  public readonly title: string;
  public readonly htrType: string | null;
  public readonly depletion: number;

  constructor(stratumRaw: StratumRaw) {
    this.id = stratumRaw.id;
    this.title = stratumRaw.title;
    this.htrType = stratumRaw.htrType;
    this.depletion = stratumRaw.depletion;
  }
}

export { Stratum };
