import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class WealthTax extends Params {
  constructor(range: Range, services: ParamsServices) {
    super("Налоговое окружение", range, services);
  }
}

export { WealthTax };
