import { useCallback, useLayoutEffect, useRef } from "react";
import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";

import { ModelContentComponentType, useModal } from "elements/modal/modal";
import { ECY } from "models/project/fact/ecy";
import { ECYStore } from "models/project/fact/ecyStore/ecyStore";

import { useEcyLists } from "./useEcyLists";

type AddECYModalInput = {
  currentECYId: number;
  setCurrentECYId: CallableFunction;
  ecyStore: ECYStore | null | undefined;
};

const ModalContent: ModelContentComponentType<ECY, AddECYModalInput> = ({ setValid, accept, setOnAccept, setLoading, dataRef }) => {
  const { currentECYId, setCurrentECYId, ecyStore } = dataRef.current;
  const { listECY } = useEcyLists(ecyStore);

  const createEcy = useCallback(
    ({ title }: { title: string }) => {
      if (ecyStore) {
        const newEcyId = ecyStore.createNewECY({ title, baseId: currentECYId });
        if (newEcyId !== null) {
          setCurrentECYId(newEcyId);
        }
      } else {
        console.warn("Попытка создать ЕСУ, до завершения загурзки");
      }
    },
    [currentECYId, ecyStore, setCurrentECYId]
  );

  const form = Form.useForm()[0];
  // const titleRef = useRef<InputRef>(null); // Что то типа загатовки для автофокуса, но не работает потому что пока нет понимания о жизненном цикле модалки

  // useEffect(() => {
  //   if (titleRef.current) {
  //     titleRef.current.focus();
  //   }
  // }, []);

  useLayoutEffect(() => {
    setValid(true); // Не очень понял как сделать валидно по умолчанию другим способом...
  }, [setValid]);

  const onChange = async (changed: Record<string, any>, values: { name: string }) => {
    if ("title" in changed) {
      setValid(Boolean(changed.title.trim()));
    }
  };

  setOnAccept(async () => {
    try {
      setLoading(true);
      createEcy({ title: form.getFieldValue("title") });
      setLoading(false);
      return { result: true };
    } catch (err) {
      console.error("Не удалось создать ЕСУ", err);
      return { result: false };
    }
  });

  return (
    <Form
      form={form}
      onFinish={accept}
      onValuesChange={onChange}
      initialValues={{
        title: "Новая версия ЕСУ" + (listECY.length >= 1 ? ` ${listECY.length + 1}` : ""),
      }}
    >
      <FormItem name="title" label="Название">
        <Input
          //  ref={titleRef}
          autoFocus
        />
      </FormItem>
    </Form>
  );
};

export const useAddECYModal = (input: AddECYModalInput) => {
  const showModal = useModal<ECY, AddECYModalInput>();

  const Input = useRef<AddECYModalInput>(input);
  Input.current = input;

  return {
    showModal: () => showModal(ModalContent, Input, "Добавление новой версии ЕСУ", "Создать"),
  };
};
