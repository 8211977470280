import { getConstantsResolver } from "services/back/constants";
import { getTaxResolver } from "services/back/tax";
import { Range } from "utils/range";

import { MetricTree, riseMetrics } from "../utils";

const includeScalarParams = ["Индекс для переносов 1", "Индекс для переносов 2"];

const rawNDDForecast = "Прогноз расходов на инфр. объекты для учета в НБ НДД";

const incluseTableFirstParams = [
  "Отрицательный дополнительный доход до списания убытков",
  "Списание убытков",
  "Применение налогового вычета НДПИ (НДД) III гр. по ВЗ",
  "НДД по минимальной налоговой базе",
];

const incluseTableLastParams = [
  "Коэффициент удельных расходов НДД, руб./т",
  "Индикативный тариф на трансп. нефти (НДД), долл./т",
  "Инвестиции (внутригрупповой оборот)",
  "Закачка попутного газа в пласт, млн.н.м3",
];

const reorganizedDataScalar = (scalar: MetricTree[]) => {
  const parent = scalar.find((el) => el.code_title === "Для расчета НДД");
  const child = parent?.children
    .filter((el) => includeScalarParams.includes(el.code_title as any))
    .map((el, index) => ({
      ...el,
      title: `Индекс ${index + 1}`,
    }));

  const resultScalar = {
    title: "Постоянные значения",
    values: null,
    key: 0,
    unit: { quantity: 0, measure: 0 },
    children: [
      {
        title: "Индексы для переносов",
        values: null,
        key: 1,
        unit: { quantity: 0, measure: 0 },
        children: child,
      },
    ],
  };

  return [resultScalar] as MetricTree[];
};

const reorganizedDataTable = (table: MetricTree[], isForecast = false) => {
  const parent = table.find((el) => el.code_title === "Налоговое окружение НДД Актива");
  const children = parent?.children || [];
  const includeLastParams = [...incluseTableLastParams];
  isForecast && includeLastParams.push(rawNDDForecast);

  return [
    {
      children: children.filter((el) => incluseTableFirstParams.includes(el.code_title as any)),
      key: 0,
      title: "Настройки моделирования",
      unit: null,
      values: [],
    },
    {
      children: children.filter((el) => includeLastParams.includes(el.code_title as any)),
      key: 5,
      title: "Остальные параметры",
      unit: null,
      values: [],
    },
  ] as MetricTree[];
};

const getNDDFact = async (range: Range) => {
  const { constants } = await getConstantsResolver();
  const { schema_operation } = await getTaxResolver(range);
  let [scalarData, tableData] = [riseMetrics(constants), riseMetrics(schema_operation)];
  const scalar = reorganizedDataScalar(scalarData);
  const table = reorganizedDataTable(tableData);

  return { scalar, table };
};

const getNDDForecast = async (range: Range) => {
  const { schema_operation } = await getTaxResolver(range);
  let tableData = riseMetrics(schema_operation);
  const table = reorganizedDataTable(tableData, true);

  return { table };
};

export { getNDDFact, getNDDForecast };
