import dayjs, { Dayjs } from "dayjs";
import { computed, makeObservable } from "mobx";

import { global } from "models/global";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { Stratum } from "models/project/stratum/stratum";
import type { InterventionRaw } from "services/back/interventions";

import { Well } from "../../well/well";
import { Forecast } from "../forecast";

class Intervention {
  constructor(private forecast: Forecast, public readonly data: InterventionRaw) {
    makeObservable<Intervention>(this, {
      stratum: computed,
      producingObject: computed,
      typeName: computed,
      date: computed,
      well: computed,
    });
  }

  public get well(): Well | null | undefined {
    return this.forecast.wells.at(this.wellId);
  }

  public get id(): number {
    return this.data.id;
  }

  public get wellId(): number {
    return this.data.wellId;
  }

  public get date(): Dayjs | null {
    if (this.data.date === null) {
      return null;
    }
    return dayjs(this.data.date);
  }

  public get stratum(): Stratum | null | undefined {
    if (this.data.stratumId === null) {
      return null;
    }
    return this.forecast.stratums.at(this.data.stratumId);
  }

  public get producingObject(): ProducingObject | null | undefined {
    const stratum = this.stratum;
    if (!stratum) {
      return stratum;
    }
    return this.forecast.stratums.getProducingObject(stratum);
  }

  public get typeName(): string | null | undefined {
    if (this.data.gtmTypeId === null) {
      return null;
    }
    const type = global.interventionsTypes.at(this.data.gtmTypeId);
    return type && type.title;
  }

  get stratumBeforeId(): number | null | undefined {
    const interventions = this.forecast.interventions
      .getInterventionsByWellId(this.wellId)
      .sort((a, b) => a.date!.year() - b.date!.year());
    const myIndex = interventions.findIndex(({ id }) => this.id === id);
    console.assert(
      myIndex >= 0,
      "Поиск ГТМ не хранимого в списке ГТМ",
      myIndex,
      interventions.map(({ wellId }) => wellId),
      this.wellId
    );
    if (myIndex < 0) {
      return undefined;
    }
    if (myIndex === 0) {
      const { well } = this;
      if (!well) {
        return well;
      }
      return well.stratum?.id;
    }
    return interventions[myIndex - 1].stratum?.id;
  }
}

export { Intervention };
