import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { ModelReport as ModelReportFeature } from "features/report/modelReport/modelReport";
import { useFact } from "models/project/fact/fact";

const ModelReport: FC = observer(() => {
  return (
    <Preloader hooks={[useFact]}>
      <PageFrame title="Модель">
        <ModelReportFeature />
      </PageFrame>
    </Preloader>
  );
});

export { ModelReport };
