/* eslint-disable @typescript-eslint/no-unused-vars */
import { setupAccuracy } from "utils/round";

type DecreaseCoefficientType = {
  key: string;
  title: string;
};

const mock: DecreaseCoefficientType[] = [
  {
    key: "optimization",
    title: "Оптимизация",
  },
  {
    key: "rir",
    title: "РИР",
  },
  {
    key: "grp",
    title: "ГРП",
  },
  {
    key: "perforation",
    title: "Дострел/реперфорация",
  },
  {
    key: "otherOPZ",
    title: "Прочие ОПЗ",
  },
  {
    key: "otherGTM",
    title: "Прочие ГТМ",
  },
  {
    key: "physic",
    title: "Физико-химические методы ПНП (справочно)",
  },
];

const getDecreaseCoefficients = async (): Promise<DecreaseCoefficientType[]> => {
  return mock;
};

export { getDecreaseCoefficients };
