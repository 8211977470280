import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { CalculationResult } from "features/materialBalance/calculationResult";
import { InputData } from "features/materialBalance/inputData";
import { MaterialBalanceContextProvider } from "features/materialBalance/materialBalanceProvider";
import { Preloader } from "features/preloader/preloader";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

const useTabs = (edit: boolean): TabItem[] => [
  {
    key: "inputs",
    label: "Входные данные",
    default: edit,
    children: <InputData />,
    disabled: !edit,
  },
  {
    key: "results",
    label: "Результаты расчета",
    default: edit,
    children: <CalculationResult />,
  },
];

const MaterialBalance: FC = observer(() => {
  const project = useProject()!;
  const forecast = useForecast();
  const edit = getUserPermission(project, forecast)["tech"];

  return (
    <ForecastSelector>
      <Preloader hooks={[() => useProject()?.producingObjects]}>
        <MaterialBalanceContextProvider>
          <PageFrame title="Материальный баланс" tabs={useTabs(edit.value)} permissionSection="tech" />
        </MaterialBalanceContextProvider>
      </Preloader>
    </ForecastSelector>
  );
});

export { MaterialBalance };
