import type { Column } from "@okopok/components/Table/models/columns/store";
import { Row as DefaultRow, RowProps } from "@okopok/components/Table/widgets/TableColumnOrder/components/Row";
import { ToggleHiddenBtn } from "@okopok/components/Table/widgets/TableColumnOrder/components/ToggleHiddenButton";

import { Icon } from "elements/icon/icon";

import { ReactComponent as DndIcon } from "./dndIcon.svg";

const Row = ({ column, ...rest }: RowProps) => {
  if (column.dataKey === undefined) {
    return null; // WARNING: probably buggable
  }
  return (
    <DefaultRow
      column={column}
      {...rest}
      components={{
        dndIcon: <Icon style={{ fontSize: 24 }} content={<DndIcon />} />,
        title: ({ title, hidden }: Column<any>) => <span style={{ color: hidden ? "gray" : "black" }}>{title}</span>,
        ToggleHiddenBtn: (props) => (column.isSticky ? null : <ToggleHiddenBtn {...props} />),
      }}
    />
  );
};

export { Row };
