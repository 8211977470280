import { LineData } from "@okopok/axes_context/lines/line";
import { NumberValue } from "d3";
import { action, computed, makeObservable, observable } from "mobx";

import { createDomainAccessor, Domain } from "utils/boundDomain";

type BarDataModelProps = {
  y: { value: NumberValue | null; limit?: NumberValue | null }[];
  x: NumberValue[] | Domain;
  key: string;
  color?: string;
  xTicks?: string[][];
  title?: string;
};

type BarInfo = {
  value: number;
  color?: string;
  key: string;
  showBar: boolean;
  limit?: number;
  title?: string;
  xTick?: string[];
};
type BarsPrepared = {
  [index: number]: BarInfo[];
};

class BarDataModel {
  public y: { value: NumberValue | null; limit?: NumberValue | null }[];
  public x: NumberValue[] | Domain;
  public key: string;
  public color?: string;
  public title?: string;
  public xTicks?: string[][];
  public showBar: boolean = true;
  constructor(barInfo: BarDataModelProps) {
    makeObservable(this, {
      showBar: observable,
      onChangeShowBar: action,
    });
    this.y = barInfo.y;
    this.x = barInfo.x;
    this.key = barInfo.key;
    this.color = barInfo.color;
    this.title = barInfo.title;
    this.xTicks = barInfo.xTicks;
  }

  onChangeShowBar = (state: boolean) => {
    this.showBar = state;
  };
}

class BarsDataModel {
  bars: BarDataModel[] = [];
  constructor(rawBars: BarDataModelProps[], public axisKey: string, public inRow: boolean = false) {
    makeObservable(this, {
      bars: observable,
      preparedBars: computed,
    });
    rawBars.forEach((bar) => this.bars.push(new BarDataModel(bar)));
  }

  get preparedBars(): Map<number, BarInfo[]> {
    const preparedData: Map<number, BarInfo[]> = new Map();
    this.bars.forEach(({ x, y, color, title, key, showBar, xTicks }) => {
      if (Array.isArray(x)) {
        x.forEach((year, id) => {
          if (y[id] !== null) {
            if (preparedData.get(+year) === undefined) {
              preparedData.set(+year, [
                {
                  value: +(y[id].value ?? 0),
                  limit: y[id].limit !== undefined && y[id].limit !== null ? +y[id].limit! : undefined,
                  color: color,
                  title: title,
                  key: key,
                  showBar: showBar,
                  xTick: xTicks?.[id],
                },
              ]);
            } else {
              preparedData.set(+year, [
                ...preparedData.get(+year)!,
                {
                  value: +(y[id].value ?? 0),
                  limit: y[id].limit !== undefined && y[id].limit !== null ? +y[id].limit! : undefined,
                  color: color,
                  title: title,
                  key: key,
                  showBar: showBar,
                  xTick: xTicks?.[id],
                },
              ]);
            }
          }
        });
      } else {
        const accessor = createDomainAccessor<LineData["x"]>(x, y.length);
        y.forEach((_, index) => {
          if (y[index] !== null) {
            if (preparedData.get(+accessor(index)) === undefined) {
              preparedData.set(+accessor(index), [
                {
                  value: +(y[index].value ?? 0),
                  limit: y[index].limit !== undefined && y[index].limit !== null ? +y[index].limit! : undefined,
                  color: color,
                  title: title,
                  key: key,
                  showBar: showBar,
                  xTick: xTicks?.[index],
                },
              ]);
            } else {
              preparedData.set(+accessor(index), [
                ...preparedData.get(+accessor(index))!,
                {
                  value: +(y[index].value ?? 0),
                  limit: y[index].limit !== undefined && y[index].limit !== null ? +y[index].limit! : undefined,
                  color: color,
                  title: title,
                  key: key,
                  showBar: showBar,
                  xTick: xTicks?.[index],
                },
              ]);
            }
          }
        });
      }
    });
    return preparedData;
  }
}

export { BarDataModel, BarsDataModel };
export type { BarDataModelProps, BarInfo, BarsPrepared };
