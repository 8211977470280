import { FC } from "react";

import { Card } from "elements/card/card";
import { Icon } from "elements/icon/icon";

import { DetailCard } from "../detailCard";

import { ReactComponent as DepositIcon } from "./deposit.svg";
import { StratumParameters } from "./stratumParameters";

import cn from "./deposits.module.less";

const Deposits: FC<{ stratums?: string[] }> = ({ stratums }) => {
  return (
    <div className={cn.layout}>
      <Card bodyClassName={cn.detailCardParent} title="Залежи">
        {stratums?.map((title) => (
          <DetailCard key={title} title="Залежь" icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<DepositIcon />} />}>
            {title}
          </DetailCard>
        ))}
      </Card>
      <StratumParameters />
    </div>
  );
};

export { Deposits };
