import { reqCamel } from "utils/request";

type TechForecastSettings<T> = {
  wellId: number;
  gtmId: number | null;
  scenarioId: number;
  stratumId: number | null;
  data: T;
};

type TechForecastSettingsIndex = Omit<TechForecastSettings<never>, "data">;

const updateTechParameters = <T>(data: TechForecastSettings<T>[]): Promise<TechForecastSettings<T>[]> =>
  reqCamel.post("decline/forecast/setting/", data) as any;

const getTechParameters = <T>(scenarioId: number): Promise<TechForecastSettings<T>[]> =>
  reqCamel.get(`decline/forecast/setting/${scenarioId}`) as any;

const deleteTechParameters = (toDelete: TechForecastSettingsIndex[]): Promise<TechForecastSettings<never>[]> =>
  reqCamel.delete("decline/forecast/setting/", toDelete) as any;

export type { TechForecastSettings, TechForecastSettingsIndex };
export { deleteTechParameters, getTechParameters, updateTechParameters };
