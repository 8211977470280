import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { HTRTypes } from "features/producingObjectsParams/htrTypes/htrTypes";
import { Reserves } from "features/producingObjectsParams/reservesDepletion/reservesDepletion";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

const TABS: TabItem[] = [
  {
    key: "reserves",
    label: "запасы",
    default: true,
    children: <Reserves />,
  },
  {
    key: "htr",
    label: "Категории ТРИЗ",
    children: <HTRTypes />,
  },
];

const ProducingObjectsParams: FC = observer(() => {
  const fc = useForecast() ?? null;

  return (
    <Preloader hooks={[() => useFact()?.producingObjects]}>
      <PageFrame title={`Параметры объектов разработки: ${!fc ? "фактические данные" : "данные для расчета"}`} tabs={TABS} />
    </Preloader>
  );
});

export { ProducingObjectsParams };
