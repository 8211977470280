import { action, computed, makeObservable, observable } from "mobx";

import { InfrastructureMapManager } from "./InfrastructureMapManager";
import { NodeType, PipeType } from "./InfrastructureMapManager";

class Inspector {
  currentNode: NodeType | null = null;
  currentPipe: PipeType | null = null;
  constructor(private parent: InfrastructureMapManager) {
    makeObservable(this, {
      currentNode: observable,
      currentPipe: observable,
      setCurrentItem: action,
      clearCurrentItem: action,
      selectedNodes: computed,
      selectedPipes: computed,
      selectedNode: computed,
      selectedPipe: computed,
      minesCount: computed,
      isPipeItem: computed,
      isNodeItem: computed,
      isMultipleItems: computed,
    });
  }

  get selectedNode() {
    return this.selectedNodes?.length === 1 ? this.selectedNodes[0] : null;
  }
  get selectedPipe() {
    return this.selectedPipes?.length === 1 ? this.selectedPipes[0] : null;
  }

  get selectedNodes() {
    if (this.parent.selection.selectedNodeIds.length === 0) {
      return null;
    }
    return this.parent.nodes!.filter((el) => this.parent.selection.selectedNodeIds.includes(el.uuid));
  }

  get selectedPipes() {
    if (this.parent.selection.selectedPipeIds.length === 0) {
      return null;
    }
    return this.parent.pipes!.filter((el) => this.parent.selection.selectedPipeIds.includes(el.uuid));
  }

  get minesCount() {
    return this.parent.nodes!.filter(({ type }) => type === "mine")?.length || 0;
  }

  get wellsCount() {
    const { wells } = this.parent.infrastructure;
    const mineIds = this.parent.nodes!.filter(({ type }) => type === "mine").map((el) => el.mineId);
    return wells.filter((el) => mineIds.includes(el.mineId)).length ?? 0;
  }

  get isPipeItem() {
    return !!(this.selectedPipes && this.selectedPipes.length === 1) && !this.selectedNodes;
  }
  get isNodeItem() {
    return !!(this.selectedNodes && this.selectedNodes.length === 1) && !this.selectedPipes;
  }
  get isMultipleItems() {
    return [...(this.selectedNodes || []), ...(this.selectedPipes || [])].length > 1;
  }

  get segmentsLength(): [oilLength: number, waterLength: number] {
    const oilLength = this.parent.oilPipes.reduce((acc, el) => (acc += el.length), 0);
    const waterLength = this.parent.waterPipes.reduce((acc, el) => (acc += el.length), 0);
    return [oilLength, waterLength];
  }

  setCurrentItem = (item: NodeType | PipeType | null) => {
    if (item === null) {
      this.currentNode = null;
      this.currentPipe = null;
    } else if (item.type === "pipe") {
      this.currentPipe = item;
    } else {
      this.currentNode = item as NodeType;
    }
  };

  clearCurrentItem = () => {
    this.setCurrentItem(null);
  };
}

export { Inspector };
