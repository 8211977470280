import { action, computed, makeObservable, observable } from "mobx";

import { zip } from "utils/itertools";

type TreeFilter<FilterableItem, T extends { title: string } | string> = {
  title: string;
  options: T[];
  predicateFactory: (selected: string[]) => (item: FilterableItem) => boolean;
};

class FilterOption<FilterableItem, T extends { title: string } | string> {
  public selected: string[] = [];

  public readonly title: string;
  public readonly options: T[];
  private predicateFactory: (selected: string[]) => (item: FilterableItem) => boolean;

  constructor(treeFilter: TreeFilter<FilterableItem, T>, selectAll: boolean = true) {
    this.title = treeFilter.title;
    this.options = treeFilter.options;
    this.predicateFactory = treeFilter.predicateFactory;
    if (selectAll) {
      this.selected = this.selector;
    }

    makeObservable(this, {
      selected: observable.ref,
      predicate: computed,
    });
  }

  public get selector(): string[] {
    return this.options.map((option) => (typeof option === "string" ? option : option.title));
  }

  public get predicate(): (item: FilterableItem) => boolean {
    return this.predicateFactory(this.selected);
  }
}

class ExternalFilterOption<FilterableItem> {
  public predicate: (item: FilterableItem) => boolean;

  constructor(predicate: (item: FilterableItem) => boolean) {
    this.predicate = predicate;

    makeObservable(this, {
      predicate: observable,
    });
  }
}

class Filters<FilterableItem> {
  public readonly filters: FilterOption<FilterableItem, any>[];
  public readonly externalFilters?: Record<string, ExternalFilterOption<FilterableItem>>;

  constructor(
    filters: TreeFilter<FilterableItem, any>[],
    externalFilters?: Record<string, ExternalFilterOption<FilterableItem>>
  ) {
    this.filters = filters.map((f) => new FilterOption(f));
    this.externalFilters = externalFilters;

    makeObservable(this, {
      filters: observable,
      externalFilters: observable,
      value: computed,
      groups: computed,
      predicate: computed,
      setSelected: action,
    });
  }

  public get value(): string[][] {
    return this.filters.map((f) => f.selected);
  }

  public get groups() {
    return this.filters.map(({ title, selector }) => ({
      title,
      items: selector.map((label) => ({ key: label, label })),
    }));
  }

  public setSelected = (selected: string[][]) => {
    console.assert(
      selected.length === this.filters.length,
      "Размерность векторов значений и групп не должны отличаться"
    );
    for (const [values, filter] of zip(selected, this.filters)) {
      filter.selected = values;
    }
  };

  public get predicate(): (item: FilterableItem) => boolean {
    const predicates = [
      ...this.filters.map((f) => f.predicate),
      ...Object.values(this.externalFilters ?? {}).map(({ predicate }) => predicate),
    ];
    return (item: FilterableItem) => {
      for (const predicate of predicates) {
        if (!predicate(item)) {
          return false;
        }
      }
      return true;
    };
  }
}

export type { TreeFilter };
export { ExternalFilterOption, Filters };
