import { FC, useEffect, useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { ReactComponent as ToIcon } from "features/projects/icons/to.svg";
import { ReactComponent as WellIcon } from "features/projects/icons/well.svg";
import { ProjectInfoCard } from "features/projects/projectInfoCard";
import { Forecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as Gtms } from "./icons/gtms.svg";
import { StatusCard } from "./statusCard";

import cn from "./scenarioInfo.module.less";

const { Text, Title } = Typography;

type ScenarioInfoProps = {
  scenario: Forecast | null;
  onNavigate: (key: string, postfix: string) => void;
};

type FormState = {
  title: string;
  variant: string;
};

const ScenarioInfo: FC<ScenarioInfoProps> = observer(({ scenario, onNavigate }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const wellsForecastCount = scenario?.wells?.wells.length;
  const gtmsForecastCount = scenario?.interventions?.interventions.length;

  useEffect(() => {
    if (scenario) {
      setValue(scenario.title);
      form.setFieldsValue({
        title: scenario.title,
        variant: "",
      });
    }
  }, [scenario, scenario?.title, form]);

  const onFinish = (values: FormState) => {
    if (scenario) {
      scenario.submitTitle(values.title);
    }
  };

  const onChange = (values: FormState) => {
    if (values.title) {
      return setValue(isFocused ? values.title : scenario?.title!);
    }
  };

  const getBody = () => {
    if (!scenario) {
      return <Title level={2}>Выберите сценарий</Title>;
    } else {
      const { title } = scenario;
      const key = scenario.id.toString();
      const wellsCount = scenario.wells?.wells.length ?? 0;
      const gtmsCount = scenario.interventions?.interventions.length ?? 0;

      return (
        <>
          <Title level={2} className={cn.title}>
            {title}
          </Title>
          <Button
            icon={<Icon content={<ToIcon />} width="14" height="14" viewBox="0 0 14 12" />}
            className={cn["link-button"]}
            onClick={() => onNavigate(key!, "wells")}
          >
            К сценарию
          </Button>

          <Form form={form} layout="vertical" className={cn.form} onFinish={onFinish} onValuesChange={onChange}>
            <Form.Item name="variant" label="Вариант разработки">
              <Input disabled />
            </Form.Item>
            <Form.Item name="title" label="Переименовать сценарий">
              <Input
                placeholder={value}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={classNames(!isFocused && cn.blur)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>

          <div className={cn["card-container"]}>
            <ProjectInfoCard
              className={cn.margin}
              title="Скважин НФ"
              icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<WellIcon />} />}
            >
              <Typography.Text className={cn["card-text"]}>{wellsCount ?? wellsForecastCount}</Typography.Text>
            </ProjectInfoCard>
            <ProjectInfoCard title="Инвест. ГТМ" icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<Gtms />} />}>
              <Typography.Text className={cn["card-text"]}>{gtmsCount ?? gtmsForecastCount}</Typography.Text>
            </ProjectInfoCard>
          </div>
          <StatusCard section="tech" scenario={scenario} />
          <StatusCard section="economic" scenario={scenario} />
          <StatusCard section="ranking" scenario={scenario} />
          <StatusCard section="infrastructure" scenario={scenario} />
        </>
      );
    }
  };

  return (
    <div className={cn.container}>
      <div className={cn.header}>
        <Text className={cn.title} strong>
          Информация о сценарии
        </Text>
      </div>
      <div className={cn.body}>{getBody()}</div>
    </div>
  );
});

export { ScenarioInfo };
