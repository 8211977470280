import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

import { global } from "models/global";
import { useSession } from "models/session";

import { FieldInfo, Fields } from "./fieldsModel";

const ProjectsContext = createContext<Fields | null>(null);

const ProjectsContextProvider = ({ children }: PropsWithChildren) => {
  const { fields } = global;

  const {
    projects,
    user: { id: userId },
  } = useSession();

  const fieldsList: FieldInfo[] = useMemo(
    () =>
      Array.from(fields.values!).map(
        ({
          title,
          id,
          tpp: {
            title: tppTitle,
            ngdo: { title: ngdoTitle },
          },
        }) => {
          return { title, id, tpp: tppTitle, ngdo: ngdoTitle };
        }
      ),
    [fields]
  );
  const projectWindow = useMemo(() => new Fields(fieldsList, projects, userId), [fieldsList, projects, userId]);

  return <ProjectsContext.Provider value={projectWindow}>{children}</ProjectsContext.Provider>;
};

const useProjectsContext = (): Fields => {
  console.assert(useContext<Fields | null>(ProjectsContext) !== null, "useTreeContext out of context");
  return useContext<Fields | null>(ProjectsContext)!;
};

export { ProjectsContextProvider, useProjectsContext };
