import { useRef, useState } from "react";
import { Button, Tooltip, UploadFile } from "antd";
import { Alert, Collapse, CollapseProps, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { observer } from "mobx-react";

import {} from "elements/importModalContent/ImportModalContent";
import { TemplateTable } from "elements/importModalContent/templateTable";
import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { WellsList } from "models/project/fact/well/list/listFlat";
import { RecoverableResourcesExcelType } from "services/back/wells";
import { conditionallyArr } from "utils/conditionally";

import cn from "./importWellResourcesModal.module.less";

type MockDataType = {
  id: number;
  well: string;
  recoverableResources: string;
  stratumId: string;
};

export const MOCK_DATA_TABLE: MockDataType[] = [
  {
    id: 1,
    well: "101",
    recoverableResources: "147,26",
    stratumId: "БВ7/2-4",
  },
  {
    id: 2,
    well: "102",
    recoverableResources: "401,91",
    stratumId: "БВ7/2-4",
  },
  {
    id: 3,
    well: "103",
    recoverableResources: "311,30",
    stratumId: "БВ7/2-4",
  },
];

export const columnsMock: ColumnsType<MockDataType> = [
  {
    dataIndex: "id",
    title: "№",
    width: 40,
  },
  {
    dataIndex: "well",
    title: "Скважина",
    width: 80,
  },
  {
    dataIndex: "stratumId",
    title: "Залежь",
    width: 80,
  },
  {
    dataIndex: "recoverableResources",
    title: "ОИЗ, тыс т",
    width: 100,
  },
];

type ErrorsType = {
  data: RecoverableResourcesExcelType[];
  errors: { error: string; type: string; uuid: string }[];
  error?: string;
};

type ModalType = {
  store: WellsList;
};

const getItems = (errors: ErrorsType["errors"]): CollapseProps["items"] => {
  const notInBase = errors.filter((el) => el.type === "notInBase");
  const notValidFormatValue = errors.filter((el) => el.type === "notValidFormatValue");
  const notInFile = errors.filter((el) => el.type === "notInFile");

  return [
    ...conditionallyArr(!!notInFile.length, {
      key: "1",
      label: "В импортируемом файле отсутствуют некоторые скважины из базового фонда",
      children: (
        <div className={cn.alerts}>
          {notInFile.map((el) => (
            <Alert key={el.uuid} showIcon message={el.error} type="warning" />
          ))}
        </div>
      ),
    }),
    ...conditionallyArr(!!notValidFormatValue.length, {
      key: "2",
      label: "Значения некоторых скважин представлены в неверном формате",
      children: (
        <div className={cn.alerts}>
          {notValidFormatValue.map((el) => (
            <Alert key={el.uuid} showIcon message={el.error} type="warning" />
          ))}
        </div>
      ),
    }),
    ...conditionallyArr(!!notInBase.length, {
      key: "3",
      label: "В базовом фонде отсутствуют некоторые скважины",
      children: (
        <div className={cn.alerts}>
          {notInBase.map((el) => (
            <Alert key={el.uuid} showIcon message={el.error} type="warning" />
          ))}
        </div>
      ),
    }),
  ];
};

const ModalContent: ModelContentComponentType<RecoverableResourcesExcelType[], ModalType> = observer(
  ({ dataRef, setLoading, setOnAccept, accept, reject }) => {
    const [data, setData] = useState<ErrorsType | null>(null);
    const [file, setFile] = useState<UploadFile | null>(null);
    const [isError, setIsError] = useState(false);

    const isDisabled = file === null;

    const items = getItems(data?.errors || []);

    const onFinish = async () => {
      setLoading(true);
      dataRef.current.store.importRecoverableResources(data?.data);
      return { result: true, data: data?.data };
    };

    const parseExcel = async (file: UploadFile): Promise<ErrorsType | undefined> => {
      try {
        const resolve = await dataRef.current.store?.parseExcelRecoverableResources(file);
        resolve && setData(resolve);
        setFile(file);
        setIsError(false);
        return resolve;
      } catch (err) {
        setIsError(true);
        throw new Error("invalid file format");
      }
    };

    setOnAccept(onFinish);

    const footer = (
      <div className={cn.footer}>
        <Button onClick={reject}>Отмена</Button>
        <Tooltip title={isDisabled ? "Загрузите файл" : undefined}>
          <Button onClick={accept} disabled={isDisabled} type="primary">
            Импортировать
          </Button>
        </Tooltip>
      </div>
    );

    if (data === null || isError) {
      return (
        <div>
          <TemplateTable
            fileName={file?.name || ""}
            templateLink={
              <a className={cn.importWellReservesLink} href="/static/well_reserves_template.xlsx" download="well_reserves_template.xlsx">
                Скачать шаблон для импорта ОИЗ
              </a>
            }
            data={MOCK_DATA_TABLE}
            columns={columnsMock}
            isError={isError}
            onFile={parseExcel}
          />
          {footer}
        </div>
      );
    }

    if (!data.errors.length) {
      return (
        <div className={cn.importResourceModalWrap}>
          <Typography.Title className={cn.importResourceModalTitle} level={2}>
            При импорте данных ошибок не выявлено, продолжить?
          </Typography.Title>
          {footer}
        </div>
      );
    }

    return (
      <div className={cn.importResourceModalWrap}>
        <Typography.Title className={cn.importResourceModalTitle} level={2}>
          При импорте данных были обнаружены признаки того, что в данных может быть ошибка, продолжить?
        </Typography.Title>
        <Collapse items={items} />
        {footer}
      </div>
    );
  }
);

const useImportWellResourcesModal = (store: WellsList): (() => Promise<RecoverableResourcesExcelType[] | null>) => {
  const showModal = useModal<RecoverableResourcesExcelType[], ModalType>();

  const val: { current: ModalType } = useRef({ store });

  return async () => {
    return showModal(ModalContent, val, "Импорт ОИЗ", null, null, "600px", null)
      .then((data) => data)
      .catch(() => null);
  };
};

export { useImportWellResourcesModal };
