import { FC, useMemo } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { Empty, Input, Select, Tooltip, Typography } from "antd";
import { observer } from "mobx-react-lite";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { PermissionButton } from "elements/permissionButton/permissionButton";

import type { DRow, Params as ProducingObjectParams } from "./model/producingObject";
import { Tables } from "./model/tables";

import "@okopok/components/style.css";
import cn from "./producingObjects.module.less";

const PARAMS: Record<keyof ProducingObjectParams, string> = {
  densityOil: "Плотность нефти, кг/м³",
  densityWater: "Плотность воды, кг/м³",
  viscosityOil: "Вязкость нефти, мПа*сек",
  viscosityWater: "Вязкость воды, мПа*сек",
  gasOilRatio: "Газовый фактор, м³/т",
};

const ProducingObjectTitleWarningSuffix: FC<{ rowData: DRow | undefined }> = ({ rowData }) => {
  return rowData?.isDuplicatedTitle ? (
    <Tooltip title={"Объект разработки с таким названием уже упоминается ранее. Данные не будут сохранены"}>
      <ExclamationCircleOutlined style={{ color: "red" }} />
    </Tooltip>
  ) : null;
};

const COLUMNS: ColumnRaw<DRow>[] = [
  {
    key: "index",
    title: "No.пп",
    width: 60,
    render: (_, { absoluteIndex, indexPath }) => absoluteIndex ?? (indexPath.at(-1) ?? 0) + 1,
  },
  {
    dataKey: "title",
    title: "Название",
    width: 350,
    onCell: ({ value }) => ({
      style: { padding: 0 },
      className: value && value.title.length > 0 && !value.isDuplicatedTitle ? undefined : cn.invalidCell,
    }),
    render: (title: string, { value, update }) => (
      <Input
        variant="borderless"
        value={title}
        onChange={(event) => update?.("title", event.currentTarget.value)}
        suffix={<ProducingObjectTitleWarningSuffix rowData={value} />}
      />
    ),
  },
  {
    dataKey: "mainStratumId",
    title: "Основная залежь",
    width: 300,
    onCell: ({ value }) => ({ className: value && value.mainStratumId !== null ? undefined : cn.invalidCell }),
    render: (id, { value, update }) => {
      if (!value) {
        return null;
      }
      const relatedStratums = value.relatedStratums;
      return (
        <Select
          value={id}
          options={Array.from(relatedStratums.entries(), ([id, stratum]) => ({ value: id, label: stratum.stratum.title }))}
          onSelect={(id) => update?.("mainStratumId", id)}
          notFoundContent={"Выберите в таблице залежей"}
          variant="borderless"
          style={{ width: "100%" }}
        />
      );
    },
  },
  ...[...Object.entries(PARAMS)].map(
    ([dataKey, title]): ColumnRaw<DRow> => ({
      dataKey: dataKey as keyof ProducingObjectParams,
      title,
      width: { min: 200, max: 300, competitiveness: 1 },
      onCell: ({ value }) => ({ className: value && value[dataKey as keyof DRow] !== null ? undefined : cn.invalidCell }),
      render: (value, { update }) => <LazyInputNumber value={value} onUpdate={(v) => update?.(dataKey, v)} />,
    })
  ),
  {
    title: "",
    key: "sticky",
    width: 32,
    render: (_, { expand, value }) => {
      if (expand !== undefined) {
        return null;
      }
      return <>{value?.remove && <DeleteButton onClick={value?.remove} />}</>;
    },
    onHeaderCell: () => ({ style: { position: "sticky", right: 0 } }),
    onCell: () => ({ style: { position: "sticky", right: 0 } }),
  },
];

const ProducingObjectsTable: FC<{ tables: Tables }> = observer(({ tables }) => {
  const store = tables.producingObjects!;
  const model = useMemo(() => new TableModel(COLUMNS, store), [store]);

  return (
    <div className={cn.tableContainer}>
      <div className={cn.tableSupHeader}>
        <Typography.Title>Объекты разработки</Typography.Title>
        {store.length > 0 ? (
          <PermissionButton onClick={store.createProducingObject} section="license">
            Добавить объект
          </PermissionButton>
        ) : null}
      </div>
      {store.length ? (
        <TableContextProvider value={model}>
          <Widget headerClassName={cn.tableHeader} />
        </TableContextProvider>
      ) : (
        <Empty className={cn.empty} description="Объекты разработки позволяют группировать залежи и задавать информацию о запасах">
          <PermissionButton type="primary" onClick={store.createProducingObject} section="license">
            Создать объект разработки
          </PermissionButton>
        </Empty>
      )}
    </div>
  );
});

export { ProducingObjectsTable };
