import { type FC, Fragment } from "react";
import { Progress } from "antd";
import { Typography } from "antd";

import { Card } from "elements/card/card";
import { Format } from "elements/format/format";
import { ParamsNode } from "models/params/paramsNode";

import { DonutChart, type DonutChartProps } from "../donutChart/DonutChart";

import styles from "./SideBlock.module.less";

const STROKE_COLORS = { "0%": "#FF9C66", "100%": "#EB5105" };

const SideBlock: FC<{ data: ParamsNode }> = ({ data }) => {
  if (!Array.isArray(data.children) || data.children.length <= 1) {
    return null;
  }
  const donutValues: DonutChartProps["data"] = data.children.map(({ title, value }) => ({
    title,
    value,
  }));
  return (
    <Card title="Структура инвестиционных расходов">
      <div className={styles["donut-chart"]}>
        <DonutChart UOM={data.unit} data={donutValues} innerRadius={110} outerRadius={150}>
          <div className={styles.pieCenter}>
            <span>
              {" "}
              <Typography.Title level={3}>{data.title}</Typography.Title>
              <br></br>
              <span className={styles.big}>
                <Format>{data.value}</Format> {data.unit.unit}
              </span>
            </span>
          </div>
        </DonutChart>
      </div>
      {data.children
        .filter(({ value, children }) => value !== null && value !== 0 && Array.isArray(children))
        .map(({ unit, title, value, children }) => {
          const max = children!.reduce((prw, { value }) => Math.max(prw, value!), 0);
          return (
            <Card.Section
              key={title}
              title={title}
              subtitle={
                <>
                  <Format>{value}</Format> {unit.unit}
                </>
              }
            >
              {children!
                .filter((child) => child.value !== null && child.value !== 0)
                .map((child) => (
                  <Fragment key={child.title}>
                    <div className={styles["card-section-body-item"]}>
                      <div className={styles["card-section-body-item-title"]}>
                        {child.title}:{" "}
                        <span>
                          <Format>{child.value}</Format> {child.unit.unit}
                        </span>
                      </div>
                      <div className={styles["card-section-body-item-progress"]}>
                        <Progress percent={(child.value! / max) * 100} strokeColor={STROKE_COLORS} showInfo={false} />
                      </div>
                    </div>
                  </Fragment>
                ))}
            </Card.Section>
          );
        })}
    </Card>
  );
};

export { SideBlock };
