import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { ExclamationCircleOutlined, LaptopOutlined, UserOutlined } from "@ant-design/icons";
import { Table } from "@okopok/components/Table/Table";
import { Button, Empty, Tooltip, Typography, UploadFile } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { debugColumnsForSimpleTable } from "elements/debugColumn/debugColumn";
import { EmptyScreen } from "elements/emptyScreen";
import { FullScreen } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";
import { Plus } from "elements/icons/plus";
import { FallbackType, useFallBack } from "elements/importModalContent/useFallBack";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ModeSelector } from "elements/modeSelector/modeSelector";
import { ModeSelectorModel, TechMode } from "elements/modeSelector/modeSelectorModel";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { DRow as DRowInterventions } from "models/project/fact/forecast/wellInterventions/wellIntervention";
import { WellInterventions as WellInterventionsModel } from "models/project/fact/forecast/wellInterventions/wellInterventions";
import { useProject } from "models/project/project";
import { GenericTableRow } from "services/back/genericTable/genegicTableService";
import { getUserPermission } from "services/back/roles";
import { conditionallyArr } from "utils/conditionally";

import { ReactComponent as GTMIcon } from "./icons/gtmIcon.svg";
import { ReactComponent as UploadIcon } from "./icons/uploadIcon.svg";
import { useImportInterventionsModal } from "./importInterventionsModal";

import cn from "./wellInterventions.module.less";

const GTMWarning: FC<{ isPreview: boolean; rowData: DRowInterventions }> = ({ isPreview, rowData }) => {
  let tooltip: string | undefined;
  if (rowData.isDuplicatedIntervention) {
    tooltip = `ГТМ данного типа уже назначен на скважину. Данные не будут ${isPreview ? "импортированы" : "сохранены"}`;
  }
  return tooltip ? (
    <Tooltip title={tooltip}>
      <ExclamationCircleOutlined style={{ color: "#faad14", position: "absolute", right: "30px", top: "8px" }} />
    </Tooltip>
  ) : null;
};

const useCustomRendersImportModal = (isPreview: boolean = false): Record<string, (value: any, row: GenericTableRow) => ReactNode> => {
  const forecast = useForecast()!;
  const getFallback = useFallBack();

  return useMemo(
    () => ({
      well: (value: [id: number | null, searchValue: string | undefined] | undefined, row: GenericTableRow) => {
        return (
          <div className={cn.stratum}>
            <Tooltip title={getFallback(value?.[0] || value?.[1], "wellId").tooltip}>
              <SelectStorable
                values={value}
                store={forecast.wells}
                autoFocus={!value?.[0]}
                setValues={(id, title) => row.update?.("well", [id, title])}
              />
            </Tooltip>
            {row.value && <GTMWarning isPreview={isPreview} rowData={row.value} />}
          </div>
        );
      },
    }),
    [isPreview, forecast.wells, getFallback]
  );
};

const customRenders = {
  isRankingResultDate: (value: boolean | null | undefined) => {
    if (value === null || value === undefined) {
      return value;
    }
    const icon = value ? <LaptopOutlined /> : <UserOutlined />;
    const tooltip = value ? "Вычислено в результате РКП" : "Определено пользователем";
    return <Tooltip title={tooltip}>{icon}</Tooltip>;
  },
};

const useColumns = (isPreviewColumns: boolean = false, modeModel: ModeSelectorModel | null = null) => {
  const forecast = useForecast()!;
  const fact = useFact()!;
  const getFallback = useFallBack();

  const getTooltip = useCallback(
    (v: any, type?: FallbackType) => (isPreviewColumns ? getFallback(v, type).tooltip : ""),
    [isPreviewColumns, getFallback]
  );

  const isFallback = useCallback(
    (v: any, type?: FallbackType) => {
      if (!isPreviewColumns) return false;
      return getFallback(v, type).isFallback;
    },
    [isPreviewColumns, getFallback]
  );

  const disabledColumn = useCallback(
    (modeModel: ModeSelectorModel | null, mode: TechMode, value: any) => {
      if (isPreviewColumns) return false;
      return !!(mode === modeModel?.mode || typeof value?.producingObject?.[0] !== "number");
    },
    [isPreviewColumns]
  );
  return useMemo(
    (): Column[] => [
      ...debugColumnsForSimpleTable([
        {
          dataKey: "id",
          title: "ID ГТМ",
          width: 90,
          isSticky: true,
          hideFilter: true,
        },
        {
          dataKey: "wellId",
          title: "ID скважины",
          width: 120,
          isSticky: true,
          hideFilter: true,
        },
      ]),
      {
        title: "Скважина",
        type: "select",
        dataKey: "well",
        key: "well",
        options: forecast.wells,
        editable: true,
        hideFilter: true,
        width: { min: 120, max: 140, competitiveness: 1 },
        onHeaderCell: () => ({ className: cn.tableHeader }),
        onCell: ({ value }: any) => ({ className: classNames(cn.selector, isFallback(value.well) && cn.fallBackBg) }),
      },
      {
        title: "Фонд",
        type: "string",
        dataKey: "fond",
        width: { min: 80, max: 148, competitiveness: 1 },
        hideFilter: true,
        onHeaderCell: () => ({ className: cn.tableHeader }),
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Стартовый дебит жид-ти, м³/сут",
        type: "number",
        dataKey: "liquidRate",
        width: { min: 160, max: 180, competitiveness: 1 },
        editable: ({ value }) => !disabledColumn(modeModel, "liquidRate", value),
        hideFilter: true,
        onCell: ({ value }: any) => ({
          className: classNames({
            [cn.tableCell]: true,
            [cn.disabled]: disabledColumn(modeModel, "liquidRate", value),
            [cn.fallBackBg]: isFallback(value.liquidRate),
          }),
        }),
        onHeaderCell: () => ({ className: cn.tableHeader }),
        tooltip: ({ value }: any) => getTooltip(value.liquidRate),
      },
      {
        title: "Стартовый дебит нефти, т/сут",
        dataKey: "oilRate",
        type: "number",
        width: { min: 160, max: 180, competitiveness: 1 },
        editable: ({ value }) => !disabledColumn(modeModel, "oilRate", value),
        hideFilter: true,
        onCell: ({ value }: any) => ({
          className: classNames({
            [cn.tableCell]: true,
            [cn.disabled]: disabledColumn(modeModel, "oilRate", value),
            [cn.fallBackBg]: isFallback(value.oilRate),
          }),
        }),
        onHeaderCell: () => ({ className: classNames(cn.tableHeader) }),
        tooltip: ({ value }: any) => getTooltip(value.oilRate),
      },
      {
        title: "Извлекаемые запасы, тыс т",
        dataKey: "recoverableResources",
        type: "number",
        editable: true,
        hideFilter: true,
        width: { min: 140, max: 160, competitiveness: 1 },
        onHeaderCell: () => ({ className: classNames(cn.tableHeader) }),
        onCell: ({ value }: any) => ({ className: classNames(cn.tableCell, isFallback(value.recoverableResources) && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.recoverableResources),
      },
      ...conditionallyArr<Column>(!isPreviewColumns, {
        title: "Обводненность, %",
        dataKey: "waterCut",
        type: "number",
        width: { min: 180, max: 200, competitiveness: 1 },
        editable: ({ value }) => !disabledColumn(modeModel, "waterCut", value),
        hideFilter: true,
        onCell: ({ value }: any) => ({
          className: classNames({
            [cn.disabled]: disabledColumn(modeModel, "waterCut", value),
            [cn.fallBackBg]: isFallback(value.waterCut, "waterCut"),
          }),
        }),
        onHeaderCell: () => ({ className: classNames(cn.tableHeader) }),
        tooltip: ({ value }: any) => getTooltip(value.waterCut, "waterCut"),
      }),
      {
        title: "Тип ГТМ",
        dataKey: "gtmType",
        type: "select",
        editable: true,
        hideFilter: true,
        options: global.interventionsTypes,
        width: { min: 140, max: 500, competitiveness: 1 },
        onHeaderCell: () => ({ className: cn.tableHeader }),
        onCell: ({ value }: any) => ({ className: classNames(cn.selector, isFallback(value.gtmType, "gtmType") && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.waterCut, "gtmType"),
      },
      ...conditionallyArr<Column>(!isPreviewColumns, {
        title: <div />,
        dataKey: "isRankingResultDate",
        key: "isRankingResultDate",
        width: 30,
        type: "boolean",
        exportTitle: "Дата выставлена в результате РКП",
        isExported: false,
        onHeaderCell: () => ({ className: cn.tableHeader }),
      }),
      {
        title: "Дата проведения ГТМ",
        dataKey: "date",
        type: "date",
        renderFormat: "month",
        editable: true,
        hideFilter: true,
        width: { min: 160, max: 160, competitiveness: 1 },
        onHeaderCell: () => ({ className: cn.tableHeader }),
        onCell: ({ value }: any) => ({ className: classNames(cn.date, isFallback(value.date, "date") && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.date, "date"),
      },
      {
        title: "Объект разработки",
        dataKey: "producingObject",
        type: "select",
        options: fact.producingObjects,
        editable: true,
        hideFilter: true,
        width: { min: 160, max: 440, competitiveness: 1 },
        onHeaderCell: () => ({ className: cn.tableHeader }),
        onCell: ({ value }: any) => ({ className: classNames(cn.selector, isFallback(value.producingObject, "producingObject") && cn.fallBackBg) }),
        tooltip: ({ value }: any) => getTooltip(value.producingObject, "producingObject"),
      },
      {
        title: "Залежь",
        dataKey: "stratum",
        type: "select",
        options: fact.stratums,
        width: { min: 200, max: 300, competitiveness: 1 },
        onHeaderCell: () => ({ className: cn.tableHeader }),
        editable: isPreviewColumns,
        hideFilter: true,
        onCell: ({ value }: any) => ({
          className: classNames(!isPreviewColumns && cn.disabled, cn.selector, isFallback(value.producingObject, "producingObject") && cn.fallBackBg),
        }),
        tooltip: ({ value }: any) => getTooltip(value.stratum, "producingObject"),
      },
    ],
    [fact, forecast.wells, isPreviewColumns, modeModel, isFallback, disabledColumn, getTooltip]
  );
};

const WellInterventions = observer(() => {
  const [fileName, setFileName] = useState("");

  const forecast = useForecast()!;

  const project = useProject()!;
  const edit = getUserPermission(project, forecast)["tech"];

  const modeModel = useMemo(() => new ModeSelectorModel(), []);
  const store = useMemo(() => new WellInterventionsModel(forecast, undefined, modeModel), [forecast, modeModel]);
  const columns = useColumns(false, store.modeModel);

  const importInterventionsModal = useImportInterventionsModal();

  const handleUpload = (file: UploadFile) => {
    importInterventionsModal(file).then((data) => {
      data?.file?.name && setFileName(data.file.name);
      if (data?.newRows && data.replace !== undefined) {
        store.importInterventions(data.newRows, data.replace);
      }
    });
  };

  const addExcelData = () => {
    importInterventionsModal(null).then((data) => {
      data?.file?.name && setFileName(data.file.name);
      if (data?.newRows && data.replace !== undefined) {
        store.importInterventions(data.newRows, data.replace);
      }
    });
  };

  return (
    <SimpleTableContext hideExpandColumn exportFileName="Программа ГТМ" data={store} columns={columns} customRenders={customRenders}>
      <PageFrameTitlePortal permissionSection="tech" onSave={store.submit} model={store}>
        {fileName && <Typography.Text>Программа ГТМ: {fileName}</Typography.Text>}
        {store.modeModel && <ModeSelector onUpdate={store.modeModel.onChangeMode} value={store.modeModel.mode} />}

        <Tooltip title="Добавить ГТМ">
          <Button type="text" icon={<Plus />} onClick={store.addEmpty} />
        </Tooltip>
        <Tooltip title="Импортировать из excel-файла">
          <Button
            type="text"
            loading={store.isLoading}
            onClick={addExcelData}
            icon={<Icon width="24" height="24" content={<UploadIcon />} viewBox="0 0 16 16" />}
          />
        </Tooltip>
      </PageFrameTitlePortal>
      <div style={{ height: "100%" }}>
        {!store.childrenStore?.length ? (
          edit.value ? (
            <EmptyScreen
              text="Загрузите программу ГТМ"
              icon={<Icon className={cn.gtmIcon} content={<GTMIcon />} viewBox="0 0 36 42" />}
              onUpload={handleUpload}
            />
          ) : (
            <FullScreen>
              <Empty
                image={<Icon className={cn.gtmIcon} content={<GTMIcon />} viewBox="0 0 36 42" />}
                imageStyle={{ height: "auto" }}
                description="Для текущего проекта нет ГТМ"
              />
            </FullScreen>
          )
        ) : (
          <Table className={cn.table} />
        )}
      </div>
    </SimpleTableContext>
  );
});

export { useColumns, useCustomRendersImportModal, WellInterventions };
