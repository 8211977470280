import { FC } from "react";
import { FormOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import { ForecastMode } from "services/back/techForecast/request";

import { useTechForecastModel } from "../useTechForecastModel";

type ApplyForAllGroupButtonProps = {
  group: "stopCriterion" | ForecastMode;
  className?: string;
};

const ApplyForGroupButton: FC<ApplyForAllGroupButtonProps> = ({ className, group }) => {
  const model = useTechForecastModel();

  if (model.selection.selectedEvents.length === 1) {
    return null;
  }

  const { isValid } = model.currentForecast.settings[group];

  return (
    <Tooltip
      className={className}
      title={isValid ? "Применить ко всей группе" : "Для применения настроек ко всей группе нужно закончить заполнение формы"}
    >
      <Button disabled={!isValid} onClick={model.onApplyForGroup(group)} type="text" icon={<FormOutlined />} />
    </Tooltip>
  );
};

export { ApplyForGroupButton };
