import { useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useResultModel } from "routing/outlines/result/resultPage";
import { ResultPageTitlePortal } from "routing/outlines/result/resultPageTitlePortal";

import { NUMBER_FORMAT } from "elements/format/format";
import { PermissionButton } from "elements/permissionButton/permissionButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { global } from "models/global";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { OptimizerModal } from "models/project/fact/forecast/optimizerModal";

import { useOptimizerModal } from "./useOptimizerModal";
import { WellsResultStore } from "./wellsResultStore";

import cn from "./wells.module.less";

const SHARED_COLUMNS: Column[] = [
  {
    dataKey: "title",
    title: "Параметр",
    isSticky: true,
    type: "string",
    width: { min: 380, max: 380, competitiveness: 1 },
    onCell: (tableItem) => ({ style: { paddingLeft: tableItem.indexPath.length * 15 } }),
  },
  {
    dataKey: "measure",
    title: "Ед. измерения",
    isSticky: true,
    type: "string",
    width: { min: 130, max: 130, competitiveness: 1 },
  },
];

const getSummaryColumn = (year: number): Column[] => [
  {
    dataKey: "summary",
    title: "Итого",
    isSticky: true,
    width: { min: 130, max: 130, competitiveness: 1 },
    type: "string",
    onCell: (tableItem) => {
      if (tableItem.value?.optimizerModel) {
        const model: OptimizerModal = tableItem.value.optimizerModel;
        return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem, year, tableItem.value.title !== "NPV")) };
      }
      return { className: classNames(cn.tableCellAlignRight) };
    },
    renderToString: (value) => (typeof value === "number" ? NUMBER_FORMAT.real_4.format(value) : value),
  },
];

const useColumns = (forModal = false, startYear?: number): Column[] => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;
  const range = !!forecast ? (global.isPickingFactYears ? forecast.wholeRange : forecast.range) : fact.factRange;
  return useMemo(
    (): Column[] =>
      [
        ...SHARED_COLUMNS,
        ...(forModal ? getSummaryColumn(range.to - 1) : []),
        ...[...range]
          .filter((year) => !startYear || year >= startYear)
          .map(
            (year) =>
              ({
                dataKey: year.toString(),
                type: "number",
                title: `${year}`,
                width: { min: 130, max: 130, competitiveness: 1 },
                onCell: (tableItem) => {
                  if (tableItem.value?.optimizerModel) {
                    const model: OptimizerModal = tableItem.value.optimizerModel;
                    return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem, year)) };
                  }
                  return { className: classNames(cn.tableCellAlignRight) };
                },
                renderToString: (value) => (typeof value === "number" ? NUMBER_FORMAT.real_4.format(value) : value),
              } as Column)
          ),
      ] as Column[],
    [forModal, range, startYear]
  );
};

const WellsResult = observer(() => {
  const result = useResultModel();
  const openOptimizerModal = useOptimizerModal(result);
  const columns = useColumns();
  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;
  const store = useMemo(() => new WellsResultStore(result, tree), [result, tree]);

  return (
    <>
      <ResultPageTitlePortal>
        <PermissionButton onClick={openOptimizerModal} section={"wellsReport"}>
          Настройки оптимизации
        </PermissionButton>
      </ResultPageTitlePortal>
      <SimpleTableContext
        data={store}
        columns={columns}
        exportFileName="Таблица оптимизации"
        tableOptions={{
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        }}
        theme={{
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }}
      >
        <Widget headerClassName={cn.tableHeader} />
      </SimpleTableContext>
    </>
  );
});

export { useColumns, WellsResult };
