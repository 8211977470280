import { BaseReport } from "../baseReport";

export const ModelReport = () => {
  return (
    <BaseReport
      options={{ chooseSingleLicenceZone: true, chooseSingleUsc: true, chooseSingleUscIsImportant: true }}
      apiPath="reports/model-form"
      title="Модель"
    />
  );
};
