import { message } from "antd";

import { req } from "utils/request";

type LoginInfo = {
  username: string;
  password: string;
};

async function authLoginService(credentials: LoginInfo): Promise<string> {
  const form = new FormData();
  for (const [key, val] of Object.entries(credentials)) {
    form.append(key, val);
  }
  try {
    const response = await req.post("auth/token", form);
    if (
      typeof response !== "object" ||
      response === null ||
      !("access_token" in response && typeof response.access_token === "string")
    ) {
      if (
        typeof response === "object" &&
        response !== null &&
        "detail" in response &&
        response.detail === "Incorrect username or password"
      ) {
        message.warning("Неизвестное сочетание логина и пароля");
      } else {
        message.info(
          "Для предоставленных логина и пароля авторизация прошла успешно, тем не менее доступ не предоставлен из-за внутренней ошибки системы авторизации"
        );
        console.error("Получен ответ авторизации неизвестного формата", response, (response as any).status);
      }
      throw new Error("unauthorized");
    }
    console.assert(
      "token_type" in response && response.token_type === "bearer",
      "получена неправильная метка типа авторизации"
    );
    return response.access_token;
  } catch (errorStream: any) {
    const error: unknown = await errorStream?.json?.();
    if (typeof error === "object" && error !== null && "detail" in error) {
      if (error.detail === "Incorrect username or password") {
        message.warning("Неизвестное сочетание логина и пароля");
      }
    }
    throw new Error("unauthorized");
  }
}

type UserData = {
  email: string;
  full_name: string;
  username: string;
  id: number;
  roles: string[];
};

const getUserInfo = () => {
  const executor: (resolve: (value: UserData) => void, reject: (reason?: any) => void) => void = (resolve, reject) => {
    req
      .get<UserData>("users/me/")
      .then(resolve)
      .catch((error) => {
        if (error.status === 504) {
          setTimeout(() => {
            executor(resolve, reject);
          }, 1000);
        } else {
          reject(error);
        }
      });
  };
  return new Promise(executor);
};

export type { LoginInfo, UserData };
export { authLoginService, getUserInfo };
