import { getOperatingResolver } from "services/back/operating";
import type { Range } from "utils/range";

import { riseMetrics } from "../utils";

const params = {
  tax: "Налог на прибыль",
  ndd: "НДПИ (НДД)",
  cashFlowOperating: "Денежный поток от операционной деятельности",
  totalRevenue: "Выручка всего (без налогов и транспорта), млн руб.",
  realTaxRates: "Реальные ставки налога",
} as const;

const excludeParams: string[] = [params.tax, params.ndd];

const getParamsByYearFact = async (range: Range) => {
  const { schema_operation } = await getOperatingResolver(range);
  const metricsSchema = riseMetrics(schema_operation);
  const result = metricsSchema.filter((el) => el.code_title === params.cashFlowOperating);
  const realTaxRatesSchemaOperation = metricsSchema.filter((el) => el.code_title === params.realTaxRates);

  if (result[0].children) {
    const { children } = result[0];
    result[0].children = children.filter((el) => el.code_title !== params.totalRevenue);
    result[0].children[1].children = children[1].children.filter((el) => !excludeParams.includes(el.code_title!));

    //@ts-ignore
    result[0].children[1].children = children[1].children.map((el) => ({
      code_title: el.code_title,
      key: el.key,
      title: el.title,
      unit: el.unit,
      values: el.values,
    }));
  }
  return { table: [...result[0].children, ...realTaxRatesSchemaOperation[0].children] };
};

export { getParamsByYearFact };
