import { objectToCamel } from "ts-case-convert";

type CommitInfo = {
  commitDate: string;
  commitSha: string;
  revision: string;
};
type Version = {
  buildDate: string;
  software: {
    back: CommitInfo;
    deploy: CommitInfo;
    front: CommitInfo;
  };
};

const getVersionInfo = async (): Promise<Version> => {
  let versionInfo: Version = await fetch("/VERSION.json")
    .then((r) => r.json())
    .then(objectToCamel<Version>);
  return versionInfo;
};
export { type CommitInfo, getVersionInfo, type Version };
