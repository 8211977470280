import { FC } from "react";

import { Icon } from "elements/icon/icon";

const CLOSED = (
  <g fill="currentColor" stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.48145 6.66602C1.48145 6.78573 1.51922 6.90545 1.59478 7.01102C2.3674 8.09059 4.94218 11.3327 7.99944 11.3327C11.0567 11.3327 13.6315 8.09059 14.4041 7.01102C14.4797 6.90545 14.5174 6.78573 14.5174 6.66602H12.8602C12.451 7.19088 11.8298 7.90993 11.0718 8.54846C10.0466 9.41197 8.98334 9.94997 7.99944 9.94997C7.01554 9.94997 5.95226 9.41197 4.92712 8.54846C4.16908 7.90993 3.5479 7.19088 3.13864 6.66602H1.48145Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.612533 6.66871L3.01667 6.67127L3.01667 8.00065L0.612533 8.00065L0.612533 6.66871Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9748 6.66871L15.379 6.67127L15.379 8.00065L12.9748 8.00065L12.9748 6.66871Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.53047 10.7465L4.07586 8.90547L5.0971 9.7627L3.55171 11.6038L2.53047 10.7465Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0975 10.7465L11.5521 8.90547L10.5308 9.7627L12.0762 11.6038L13.0975 10.7465Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.66692 12.666L8.66702 10.6646L7.33369 10.6646L7.33359 12.6659L8.66692 12.666Z" />
  </g>
);

const OPEN = (
  <g fill="currentColor" stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8602 8.00065C12.451 7.47579 11.8298 6.75674 11.0718 6.11821C10.0466 5.25469 8.98334 4.7167 7.99944 4.7167C7.01554 4.7167 5.95225 5.25469 4.92712 6.11821C4.16908 6.75674 3.5479 7.47579 3.13864 8.00065C3.5479 8.52552 4.16908 9.24457 4.92712 9.8831C5.95226 10.7466 7.01554 11.2846 7.99944 11.2846C8.98334 11.2846 10.0466 10.7466 11.0718 9.88309C11.8298 9.24457 12.451 8.52552 12.8602 8.00065ZM14.4041 8.34565C14.5552 8.13451 14.5552 7.86679 14.4041 7.65565C13.6315 6.57608 11.0567 3.33398 7.99944 3.33398C4.94218 3.33398 2.3674 6.57608 1.59478 7.65565C1.44367 7.86679 1.44367 8.13451 1.59478 8.34565C2.3674 9.42523 4.94218 12.6673 7.99944 12.6673C11.0567 12.6673 13.6315 9.42522 14.4041 8.34565Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
    />
  </g>
);

const PARTIALLY = (
  <g fill="currentColor" stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8602 8.00065C12.451 7.47579 11.8298 6.75674 11.0718 6.11821C10.0466 5.25469 8.98334 4.7167 7.99944 4.7167C7.01554 4.7167 5.95225 5.25469 4.92712 6.11821C4.16908 6.75674 3.5479 7.47579 3.13864 8.00065C3.5479 8.52552 4.16908 9.24457 4.92712 9.8831C5.95226 10.7466 7.01554 11.2846 7.99944 11.2846C8.98334 11.2846 10.0466 10.7466 11.0718 9.88309C11.8298 9.24457 12.451 8.52552 12.8602 8.00065ZM14.4041 8.34565C14.5552 8.13451 14.5552 7.86679 14.4041 7.65565C13.6315 6.57608 11.0567 3.33398 7.99944 3.33398C4.94218 3.33398 2.3674 6.57608 1.59478 7.65565C1.44367 7.86679 1.44367 8.13451 1.59478 8.34565C2.3674 9.42523 4.94218 12.6673 7.99944 12.6673C11.0567 12.6673 13.6315 9.42522 14.4041 8.34565Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.42016 6.34204C6.92506 6.1589 7.47898 6 7.99957 6C8.52017 6 9.07408 6.1589 9.57898 6.34204C9.96134 6.48073 10.3634 6.65216 10.7417 6.8135C10.8667 6.86677 10.989 6.91893 11.1072 6.96846C11.6051 7.17706 12.037 7.34235 12.3988 7.42175C12.7787 7.50515 12.9367 7.46026 12.9899 7.42834L13.6759 8.57166C13.1735 8.87307 12.5871 8.82818 12.1129 8.72408C11.6204 8.61598 11.0885 8.40628 10.5919 8.19821C10.4556 8.14108 10.3218 8.08403 10.1901 8.02789C9.82125 7.87065 9.46905 7.7205 9.12433 7.59546C8.65284 7.42444 8.27898 7.33333 7.99957 7.33333C7.72017 7.33333 7.34631 7.42444 6.87482 7.59546C6.5301 7.7205 6.17789 7.87065 5.80907 8.02789C5.67737 8.08403 5.54355 8.14108 5.4072 8.19821C4.91061 8.40628 4.3787 8.61598 3.88626 8.72408C3.41202 8.82818 2.8256 8.87307 2.32324 8.57166L3.00924 7.42834C3.06244 7.46026 3.22046 7.50515 3.60038 7.42175C3.96211 7.34235 4.39409 7.17706 4.89194 6.96846C5.01015 6.91893 5.13249 6.86677 5.2574 6.8135C5.63578 6.65216 6.03781 6.48073 6.42016 6.34204Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
    />
  </g>
);

const Eye: FC<{ isVisible: boolean; isPartiallyVisible?: boolean; className?: string }> = ({ isVisible, className, isPartiallyVisible }) => (
  <Icon viewBox="0 0 16 16" className={className} content={isVisible ? OPEN : isPartiallyVisible ? PARTIALLY : CLOSED} />
);

export { Eye };
