import { makeAutoObservable } from "mobx";

type UOMResolver = {
  name: string;
  measures: string[];
};

type UOMObject = {
  quantity: number;
  measure: number;
};

class UOM {
  constructor(public quantityId: number, public unitId: number, private resolver: UOMResolver[]) {
    makeAutoObservable(this);
  }

  static fromObject(object: UOMObject, resolver: UOMResolver[]): UOM {
    return new UOM(object.quantity, object.measure, resolver);
  }

  get isNull(): boolean {
    return (
      this.quantityId <= 0 ||
      this.unitId <= 0 ||
      this.quantityId >= this.resolver.length ||
      this.unitId >= this.resolver[this.quantityId].measures.length
    );
  }

  get isBoolean(): boolean {
    return this.quantityId === 13;
  }

  get name(): string | null {
    return this.resolver[this.quantityId]?.name ?? null;
  }

  get unit(): string | null {
    return this.resolver[this.quantityId]?.measures[this.unitId]?.replaceAll("^3", "³")?.replaceAll("^2", "²") ?? null;
  }

  get dump(): UOMObject {
    return {
      quantity: this.quantityId,
      measure: this.unitId,
    };
  }

  static equal(a: UOM, b: UOM): boolean {
    return a.quantityId === b.quantityId && a.unitId === b.unitId;
  }

  equal(b: UOM): boolean {
    return UOM.equal(this, b);
  }
}

export { UOM, type UOMObject, type UOMResolver };
