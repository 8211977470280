import { FC, ReactNode } from "react";
import { Scale } from "@okopok/axes_context";

type Props = {
  scale: Scale;
  from: { x: number; y: number };
  to: { x: number; y: number };
  strokeWidth: number;
  className?: string;
  children: ReactNode;
};

const EmptyLine: FC<Props> = ({ scale, from, to, strokeWidth, className, children }) => {
  const [x1, y1, x2, y2] = [scale.x(from.x) - strokeWidth / 2, scale.y(from.y), scale.x(to.x) - strokeWidth / 4, scale.y(to.y)];
  const [centerX, centerY] = [(x1 + x2) / 2, (y1 + y2) / 2];
  return (
    <>
      <line x1={x1} x2={x2} y1={y1} y2={y2} strokeWidth={strokeWidth} className={className} />
      <line x1={x1} x2={x2} y1={y1} y2={y2} strokeWidth={strokeWidth / 2} stroke="white" />
      {children && <g transform={`translate(${centerX - 5}, ${centerY})`}>{children}</g>}
    </>
  );
};

export { EmptyLine };
