import { Button, Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { CompleteIcon } from "elements/icons/complete/complete";
import { global } from "models/global";

const FactYearsToggler = observer(() => (
  <Tooltip title="Отображать фактические данные в режиме прогноза" placement="right">
    <Button onClick={global.togglePickingFactYears}>
      <CompleteIcon gray completed={global.isPickingFactYears} />
    </Button>
  </Tooltip>
));

export { FactYearsToggler };
