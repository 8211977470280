import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class InvestParams extends Params {
  constructor(years: Range, services: ParamsServices) {
    super("Параметры инвестиционных расходов", years, services);
  }
}

export { InvestParams };
