import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { CreateProject } from "./createProjectModel";

const ProjectsContext = createContext<CreateProject | null>(null);

type CreateProjectsContextProviderProps = { value: CreateProject } & PropsWithChildren;

const CreateProjectsContextProvider = ({ children, value }: CreateProjectsContextProviderProps) => {
  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};

const useCreateProjectsContext = (): CreateProject => {
  console.assert(useContext<CreateProject | null>(ProjectsContext) !== null, "useCreateProjectsContext out of context");
  return useContext<CreateProject | null>(ProjectsContext)!;
};

export { CreateProjectsContextProvider, useCreateProjectsContext };
