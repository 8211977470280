import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeObservable, runInAction } from "mobx";

import { zip } from "utils/itertools";
import { Range } from "utils/range";

import { YearsSettingsData, YearsSettingsFields } from "./settings";

type YearsDRow = {
  key: YearsSettingsFields | null;
  title: string;
  measure: string | null;
  [year: number]: number;
};

const titlesMap: Record<YearsSettingsFields, string> = {
  ebLimits: "Лимит инвестиций на ЭБ",
  reconstructionLimits: "Лимит инвестиций на реконструкции",
  krsTeams: "Бригады КРС",
  zbsTeams: "Бригады ЗБС",
  ebTeams: "Бригады ЭБ",
};

const TABLE = [
  {
    title: "Экономические лимиты",
    measure: "млн руб",
    settings: ["ebLimits", "reconstructionLimits"] as YearsSettingsFields[],
  },
  {
    title: "Лимиты по количеству бригад",
    measure: "шт",
    settings: ["krsTeams", "zbsTeams", "ebTeams"] as YearsSettingsFields[],
  },
];

class RowNode extends TableNode<YearsDRow> {
  public asDRow = (): YearsDRow => ({
    key: this.setting,
    title: titlesMap[this.setting],
    measure: this.parent.measure,
    ...Object.fromEntries(zip([...this.yearsRange], this.yearsSettings[this.setting])),
  });

  constructor(
    private readonly parent: SettingNode,
    public readonly yearsSettings: YearsSettingsData,
    public readonly setting: YearsSettingsFields
  ) {
    super(parent, { selectable: false, mutable: true });
    runInAction(() => (this.childrenStore = null));
  }

  public get yearsRange() {
    return this.parent.yearsRange;
  }

  updateValue?(key: any, newValue: any): [prevValue: any, currValue: any] {
    if (!this.yearsSettings[this.setting]) {
      return [undefined, undefined];
    }

    const yearIdx = this.yearsRange.id(key);
    const prevValue = this.yearsSettings[this.setting][yearIdx];
    const currValue = (this.yearsSettings[this.setting][yearIdx] = newValue);

    return [prevValue, currValue];
  }
}

class SettingNode extends TableNode<YearsDRow, any> {
  public asDRow(): YearsDRow {
    return {
      key: null,
      title: this.title,
      measure: null,
    };
  }
  constructor(
    public readonly parent: YearsSettings,
    public readonly yearsSettings: YearsSettingsData,
    public readonly title: string,
    public readonly measure: string,
    public readonly settings: YearsSettingsFields[]
  ) {
    super(parent, { isExpandedChildren: true, selectable: false, mutable: true });
    runInAction(
      () =>
        (this.childrenStore = new ChildrenStoreArray(
          this,
          settings.map((setting) => new RowNode(this, yearsSettings, setting))
        ))
    );
  }

  public get yearsRange() {
    return this.parent.yearsRange;
  }

  get absoluteIndex(): number {
    return super.absoluteIndex;
  }
}

class YearsSettings extends TableNode<YearsDRow, SettingNode> {
  public readonly yearsRange: Range;

  constructor(yearSettings: YearsSettingsData, yearsRange: Range) {
    super(null, { selectable: false, mutable: true });

    this.yearsRange = yearsRange;

    this.childrenStore = new ChildrenStoreArray(
      this,
      TABLE.map(({ title, measure, settings }) => new SettingNode(this, yearSettings, title, measure, settings))
    );

    makeObservable(this, {});
  }

  get isUpdated(): boolean {
    return super.isUpdated;
  }
}

export { YearsSettings };
export type { YearsDRow };
