import { computed, makeAutoObservable, reaction } from "mobx";

import { CalcEffectTableNode, TableRow } from "../calcEffectTableModel";
import { CalcModel } from "../calcModel";

class CalcModelPhysic {
  public gtmAmount: TableRow;
  public relativeGTMAmount: TableRow;
  public decreaseCoefficients: TableRow;
  public relativeMining: TableRow;
  public additionalMining: TableRow;
  public transferringMining: TableRow;

  public rawTransferringMining: (number | null)[][] = [];

  constructor(public node: CalcEffectTableNode, public calcModel: CalcModel) {
    makeAutoObservable(this, {
      decreaseCoefficientsForTransfering: computed,
    });

    this.gtmAmount = Array.from(node.children!).find((child) => child.rowInfo.title === "Количество ФХ МУН")!;
    this.relativeGTMAmount = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Удельное количество ГТМ"
    )!;
    this.decreaseCoefficients = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени"
    )!;
    this.relativeMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Удельная добыча от ФХ МУН"
    )!;
    this.additionalMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Доп. добыча за год проведения ФХ МУН"
    )!;
    this.transferringMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Переходящая доп. добыча от ФХ МУН"
    )!;

    this.calcRawTransferring();

    // Переходящая доп. добыча

    reaction(
      () => [
        this.decreaseCoefficientsForTransfering,
        ...Object.values(this.relativeMining.data),
        ...Object.values(this.gtmAmount.data),
        this.calcModel.getFirstNElements(this.calcModel.miningWellsModel.serviceCoefficient, 1),
      ],
      () => {
        this.calcRawTransferring();

        for (let i = 0; i < this.calcModel.range.length; ++i) {
          let result = null;

          for (let j = 0; j < this.calcModel.range.length; ++j) {
            if (this.rawTransferringMining[j][i]) {
              if (result === null) {
                result = this.rawTransferringMining[j][i];
              } else {
                result += this.rawTransferringMining[j][i]!;
              }
            }
          }
          if (this.rawTransferringMining[i][i] !== null && result !== null) {
            result -= this.rawTransferringMining[i][i]!;
          }
          this.transferringMining.mutationsManager?.updateWrapper(this.calcModel.range.from + i, result);
        }
      }
    );

    //Количество ФХ МУН

    reaction(
      () => [
        ...Object.values(this.calcModel.injectionWellsModel.wellsAmount.data),
        ...Object.values(this.relativeGTMAmount.data),
      ],
      (values) => {
        Object.keys(this.gtmAmount.data).forEach((year, index) => {
          if (
            index > 2 &&
            this.calcModel.injectionWellsModel.wellsAmount.data[+year] !== null &&
            this.relativeGTMAmount.data[+year] !== null
          ) {
            this.gtmAmount.mutationsManager?.updateWrapper(
              +year,
              this.calcModel.injectionWellsModel.wellsAmount.data[+year]! * this.relativeGTMAmount.data[+year]!
            );
          }
        });
      }
    );

    // Доп. добыча за год проведения

    reaction(
      () => [
        ...Object.values(this.relativeMining.data),
        ...Object.values(this.gtmAmount.data),
        ...this.calcModel.getFirstNElements(this.additionalMining, 3),
      ],
      () => {
        Object.keys(this.additionalMining.data).forEach((year, index) => {
          if (index > 2) {
            if (this.gtmAmount.data[+year] !== null && this.relativeMining.data[+year] !== null) {
              this.additionalMining.mutationsManager?.updateWrapper(
                +year,
                this.gtmAmount.data[+year]! * this.relativeMining.data[+year]!
              );
            }
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.getFirstNElements(this.gtmAmount, 3),
        Object.values(this.calcModel.injectionWellsModel.wellsAmount.data),
      ],
      () => {
        Object.keys(this.relativeGTMAmount.data).forEach((year, index) => {
          if (index < 3) {
            if (this.gtmAmount.data[+year] !== null && this.calcModel.injectionWellsModel.wellsAmount.data[+year]) {
              this.relativeGTMAmount.mutationsManager?.updateWrapper(
                +year,
                this.gtmAmount.data[+year]! / this.calcModel.injectionWellsModel.wellsAmount.data[+year]!
              );
            }
          } else {
            const meanItems = [
              this.relativeGTMAmount.data[this.calcModel.range.from],
              this.relativeGTMAmount.data[this.calcModel.range.from + 1],
              this.relativeGTMAmount.data[this.calcModel.range.from + 2],
            ].filter((item) => item !== null);
            const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

            this.relativeGTMAmount.mutationsManager?.updateWrapper(+year, mean);
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.getFirstNElements(this.gtmAmount, 3),
        Object.values(this.calcModel.injectionWellsModel.wellsAmount.data),
      ],
      () => {
        Object.keys(this.relativeGTMAmount.data).forEach((year, index) => {
          if (index < 3) {
            if (this.calcModel.injectionWellsModel.wellsAmount.data[+year] !== 0) {
              this.relativeGTMAmount.mutationsManager?.updateWrapper(
                +year,
                this.gtmAmount.data[+year]! / this.calcModel.injectionWellsModel.wellsAmount.data[+year]!
              );
            }
          } else {
            const meanItems = [
              this.relativeGTMAmount.data[this.calcModel.range.from],
              this.relativeGTMAmount.data[this.calcModel.range.from + 1],
              this.relativeGTMAmount.data[this.calcModel.range.from + 2],
            ].filter((item) => item !== null);
            const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

            this.relativeGTMAmount.mutationsManager?.updateWrapper(+year, mean);
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.getFirstNElements(this.relativeMining, 3),
        ...Object.values(this.decreaseCoefficients.data),
        ...Object.values(this.additionalMining.data),
      ],
      () => {
        Object.keys(this.relativeMining.data).forEach((year, index) => {
          if (index < 3) {
            if (this.additionalMining.data[+year] !== null && this.gtmAmount.data[+year] !== null) {
              this.relativeMining.mutationsManager?.updateWrapper(
                +year,
                this.additionalMining.data[+year]! / this.gtmAmount.data[+year]!
              );
            }
          } else {
            if (this.decreaseCoefficients.data[+year] !== null) {
              const meanItems = [
                this.relativeMining.data[this.calcModel.range.from],
                this.relativeMining.data[this.calcModel.range.from + 1],
                this.relativeMining.data[this.calcModel.range.from + 2],
              ].filter((item) => item !== null);
              const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

              this.relativeMining.mutationsManager?.updateWrapper(+year, mean * this.decreaseCoefficients.data[+year]!);
            }
          }
        });
      }
    );

    reaction(
      () => Object.values(this.decreaseCoefficients.data),
      () => {
        const lastKey = this.decreaseCoefficients.lastUpdatedKey;
        Object.keys(this.decreaseCoefficients.data).forEach((year, index) => {
          if (lastKey !== null && isFinite(lastKey) && this.decreaseCoefficients.data[lastKey] !== null) {
            if (+year > lastKey) {
              this.decreaseCoefficients.mutationsManager?.updateWrapper(+year, this.decreaseCoefficients.data[lastKey]);
            }
          }
        });
      }
    );
  }

  get decreaseCoefficientsForTransfering() {
    return Array.from(
      this.calcModel.forecast.decreaseCoefficients.coefficients
        .find((coeff) => coeff.key === "physic")
        ?.data?.values() ?? []
    );
  }

  calcRawTransferring = () => {
    const coefficients = Array.from(
      this.calcModel.forecast.decreaseCoefficients.coefficients
        .find((coeff) => coeff.key === "physic")
        ?.data?.values() ?? []
    );
    const debetIncrease: (number | null)[][] = new Array(this.calcModel.range.length)
      .fill(null)
      .map((value, index) => new Array(coefficients.length).fill(null));

    for (let i = 0; i < debetIncrease.length; ++i) {
      debetIncrease[i][0] = this.relativeMining.data[this.calcModel.range.from + i];
      for (let j = 1; j < debetIncrease[i].length; ++j) {
        if (debetIncrease[i][j - 1] !== null && coefficients[i] !== null) {
          debetIncrease[i][j] = debetIncrease[i][j - 1]! * coefficients[i]!;
        }
      }
    }

    this.rawTransferringMining = new Array(this.calcModel.range.length)
      .fill(null)
      .map((value, index) => new Array(this.calcModel.range.length + 9).fill(null));

    for (let i = 0; i < this.calcModel.range.length; ++i) {
      if (debetIncrease[i][0] && this.gtmAmount.data[+this.calcModel.range.from + i]) {
        this.rawTransferringMining[i][i] = debetIncrease[i][0]! * this.gtmAmount.data[+this.calcModel.range.from + i]!;

        for (let j = 1; j < debetIncrease[i].length; ++j) {
          if (debetIncrease[i][j] && this.gtmAmount.data[+this.calcModel.range.from + i])
            this.rawTransferringMining[i][i + j] =
              debetIncrease[i][j]! * this.gtmAmount.data[+this.calcModel.range.from + i]!;
        }
      }
    }
  };
}
export { CalcModelPhysic };
