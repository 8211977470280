import { FC, ReactNode, useMemo } from "react";
import { Menu as AntdMenu, type MenuProps } from "antd";
import { SubMenuType } from "antd/es/menu/hooks/useItems";
import classNames from "classnames";

import { Icon } from "elements/icon/icon";

import { Title } from "./title/title";

import cn from "./menuByItSelf.module.less";

const substr = (str: string, substr: string | undefined): boolean => (substr === undefined ? true : str.toLowerCase().includes(substr.toLowerCase()));

const expandIcon = ({ isOpen }: any) => {
  return <Icon wrapClassName={classNames(cn.folder, { [cn.open]: isOpen })} viewBox="0 0 16 16" content={<path d="M4 6H12L8 10L4 6Z" />} />;
};

const allKeys = (items: MenuProps["items"]): string[] =>
  items?.map((item): string[] => [`${item?.key}`, ...allKeys((item as SubMenuType)?.children ?? null)]).flat() ?? [];

type MenuByItSelfProps = {
  onAdd?: (() => void) | null;
  onCopy?: (() => void) | null;
  title: ReactNode;
  flex: number;
  items: MenuProps["items"] | null;
  noData?: ReactNode;
  addTooltip?: string;
  onNavigate: MenuProps["onClick"];
  onOpenChange?: MenuProps["onOpenChange"];
  alwaysOpen?: true;
  selectedKey: string;
  children?: ReactNode;
  search: string | undefined;
  onSearchChange: React.Dispatch<React.SetStateAction<string | undefined>>;
  className?: string;
};

const searchItems = (items: MenuProps["items"] | undefined | null, value: string | undefined): MenuProps["items"] => {
  if (value === undefined || value === "" || !Array.isArray(items)) {
    return items ?? [];
  }
  return items
    .map((node) => {
      if (node === null) {
        return null;
      }
      if ("label" in node) {
        const result = {
          ...node,
        };
        if ("children" in result) {
          result.children = searchItems(result.children, value);
        }
        if (typeof node.label !== "string" || ("children" in result && (result.children?.length ?? 0) > 0) || substr(node.label, value)) {
          return result;
        }
        return null;
      } else {
        return node;
      }
    })
    .filter((v) => v !== null);
};

const MenuByItSelf: FC<MenuByItSelfProps> = ({
  selectedKey,
  onAdd,
  items,
  flex,
  title,
  noData,
  addTooltip,
  onNavigate,
  onOpenChange,
  children,
  search,
  onSearchChange,
  alwaysOpen,
  onCopy,
  className,
}) => {
  const selectedKeys = useMemo(() => [selectedKey], [selectedKey]);

  const isMenuReady: boolean = items !== null && items !== undefined;

  const filteredItems = searchItems(items, search);

  const isAllFiltered = filteredItems?.length === 0 && items !== null && items !== undefined;

  return (
    <div className={classNames(cn.wrap, className)}>
      <Title
        isWrongSearch={isAllFiltered}
        addTooltip={addTooltip}
        onCopy={onCopy}
        onAdd={onAdd}
        title={title}
        search={search}
        onSearchChange={onSearchChange}
      >
        {children}
      </Title>
      {isMenuReady && !isAllFiltered ? (
        <AntdMenu
          onClick={onNavigate}
          onOpenChange={onOpenChange}
          style={{ flex }}
          expandIcon={expandIcon}
          mode="inline"
          className={cn.menu}
          selectedKeys={selectedKeys}
          {...{ [alwaysOpen ? "openKeys" : "defaultOpenKeys"]: allKeys(filteredItems) }}
          items={filteredItems}
          inlineIndent={16}
        />
      ) : (
        <div style={{ flex }} className={cn["no-data"]}>
          <div>{isAllFiltered ? "Нет пунктов для отображения, проверьте условия поиска" : noData}</div>
        </div>
      )}
    </div>
  );
};

export { MenuByItSelf };
