import { FC, useState } from "react";
import { CheckOutlined, CloseOutlined, CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Tooltip } from "antd";

import cn from "./editableLabel.module.less";

type EditedLabelProps = {
  onSave: (value: string) => void;
  onDelete?: () => Promise<void>;
  label: string;
  onCopy?: () => void;
};

const EditableLabel: FC<EditedLabelProps> = ({ label, onSave, onDelete, onCopy }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState<string>(label);
  const [loadingState, setLoading] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    await onSave(value);
    setLoading(false);
    setEdit(false);
  };

  return (
    <div className={cn["editable-label"]}>
      <div className={cn["text-container"]}>
        {edit ? (
          <Input
            variant="borderless"
            autoFocus
            defaultValue={label}
            onBlur={() => setEdit(false)}
            onPressEnter={handleSave}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        ) : label === "" ? (
          <span />
        ) : (
          label
        )}
      </div>
      <div className={cn["button-container"]}>
        {edit ? (
          <>
            <Button
              size="small"
              type="text"
              icon={<CheckOutlined />}
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleSave}
              loading={loadingState}
            />
            <Button
              size="small"
              type="text"
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                setEdit(false);
              }}
              icon={<CloseOutlined />}
            />
          </>
        ) : (
          <Tooltip title="Переименовать">
            <Button
              size="small"
              type="text"
              className={cn["edit-button"]}
              onClick={(e) => {
                e.stopPropagation();
                setEdit(true);
              }}
              icon={<EditOutlined />}
            />
          </Tooltip>
        )}
        {onCopy ? (
          <Tooltip title="Копировать">
            <Button className={cn["edit-button"]} onClick={onCopy} icon={<CopyOutlined />} size="small" type="text" />
          </Tooltip>
        ) : null}
        {onDelete ? (
          <Tooltip title="Удалить">
            <Popconfirm
              title="Удалить сценарий?"
              cancelText="Отмена"
              okText="Удалить"
              onConfirm={async (e) => {
                e?.stopPropagation();
                setDeleting(true);
                await onDelete();
                setDeleting(false);
              }}
            >
              <Button loading={isDeleting} danger className={cn["edit-button"]} icon={<DeleteOutlined />} size="small" type="text" />
            </Popconfirm>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export { EditableLabel };
