import { Axis } from "@okopok/axes_context";
import { computed, makeObservable, observable, ObservableSet, runInAction, transaction } from "mobx";

import { Analogs } from "features/techForecast/models/well/analogs";
import { axisInference, META } from "features/techForecast/models/well/wellTechChartChannelsMeta";
import { AnalogsResult, Fitting, getAnalogs, itemKey, RATE_FITTED } from "services/back/techForecast/request";
import { Production } from "services/back/techForecast/techForecast";
import { randomColor } from "utils/random";

import { LineDataModel } from "../../results/chart/elements/lineDataModel";

import cn from "./analogsModal.module.less";

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

class Chart {
  readonly axes: Axis[];
  lines: Record<string, LineDataModel>;
  current: string = "";
  hidden: ObservableSet<string>;
  updated: Pick<AnalogsResult, "liquidRateM3Fitted" | "oilRateTFitted"> | null = null;
  updatedHidden: string;

  constructor(private readonly analogs: Analogs) {
    const currentAnalogs = analogs.currentAnalogs!;
    const curveKey = ({ liquid: "liquidRateM3", oil: "oilRateT" } as const)[analogs.mode];
    this.hidden = observable.set([...analogs.hidden.values()]);
    this.updatedHidden = JSON.stringify([...analogs.hidden.values()].sort());
    this.axes = [
      new Axis(
        "y",
        "left",
        { min: 0, max: 1 },
        [META[curveKey].title, axisInference(curveKey)].filter(Boolean).join(", ")
      ),
      new Axis("x", "bottom", { min: 0, max: currentAnalogs.items[0].factProduction[curveKey].length }, "Месяц"),
    ];
    this.lines = Object.fromEntries(
      currentAnalogs.items.map((item) => {
        const key = itemKey(item);
        return [
          key,
          new LineDataModel({
            y: item.factProduction[`normalized${capitalizeFirstLetter(curveKey)}` as keyof Production] as number[],
            x: this.axes[1].domain,
            axisKey: "y",
            key,
            color: randomColor(),
            className: cn.analog,
          }),
        ] as const;
      })
    );
    this.lines.fitting = new LineDataModel({
      y: currentAnalogs[`${curveKey}Fitted`].y,
      x: this.axes[1].domain,
      axisKey: "y",
      key: "fitting",
      color: "green",
      className: cn.fitting,
    });

    this.lines.rawFitting = new LineDataModel({
      y: currentAnalogs[`${curveKey}Fitted`].yRaw!,
      x: this.axes[1].domain,
      axisKey: "y",
      key: "rawFitting",
      color: "green",
      className: cn.rawFitting,
    });

    makeObservable(this, {
      current: observable,
      updatedHidden: observable,
      updated: observable,
      currentLine: computed,
      fitting: computed,
    });
  }

  updateFittings = async () => {
    const result = await getAnalogs(
      {
        ...this.analogs.analogsRequest,
        wellIds:
          this.analogs.currentAnalogs?.items.filter((v) => !this.hidden.has(itemKey(v))).map((v) => v.wellId) ?? null,
      },
      true
    );

    this.updated = {
      liquidRateM3Fitted: result.liquidRateM3Fitted,
      oilRateTFitted: result.oilRateTFitted,
    };
    runInAction(() =>
      transaction(() => {
        this.updatedHidden = JSON.stringify([...this.hidden.values()].sort());
        this.lines.fitting.y = result[RATE_FITTED[this.analogs.mode]].y;
        this.lines.rawFitting.y = result[RATE_FITTED[this.analogs.mode]].yRaw!;
      })
    );
  };

  get isUpdated(): boolean {
    return this.updatedHidden === JSON.stringify([...this.hidden.values()].sort());
  }

  readonly toggleHidden = (key: string) => () => {
    runInAction(() => {
      this.hidden[this.hidden.has(key) ? "delete" : "add"](key);
    });
  };

  get selectedAmount(): number | null {
    let counter = 0;
    const { currentAnalogs } = this.analogs;
    if (currentAnalogs === undefined) {
      return null;
    }
    const { hidden } = this;
    if (hidden.size === 0) {
      return currentAnalogs.items.length;
    }
    for (const item of currentAnalogs.items)
      if (hidden.has(itemKey(item))) {
        counter += 1;
      }
    return currentAnalogs.items.length - counter;
  }

  get amount(): number | null {
    return this.analogs.amount;
  }

  get currentLine(): LineDataModel | undefined {
    return this.lines[this.current];
  }

  get fitting(): Fitting | null {
    if (this.updated === null) {
      return null;
    }
    return this.updated[RATE_FITTED[this.analogs.mode]];
  }

  currentHolder(key: string) {
    return () => runInAction(() => (this.current = key));
  }
}

export { Chart };
