import { type FC, type ReactNode, useEffect, useRef } from "react";
import * as d3 from "d3";

import { UOM } from "elements/uom";
import { colorCarousel } from "services/colorCarousel";

import Arc from "./Arc";

import styles from "./DonutChart.module.less";
type DonutChartProps = {
  data: { title: ReactNode; value: number | null }[];
  innerRadius: number;
  outerRadius: number;
  children?: ReactNode;
  UOM: UOM;
};

const DonutChart: FC<DonutChartProps> = ({ data: rawData, innerRadius, outerRadius, children, UOM }) => {
  const svgRef = useRef(null);
  const centerElementRef = useRef(null);
  const data = rawData.filter(({ value }) => value !== null) as { title: string; value: number }[];
  const padding = 6;
  const centerElementHeight = innerRadius + 10;
  const centerElementWidth = 2 * (innerRadius * innerRadius - (centerElementHeight * centerElementHeight) / 4) ** 0.5;
  const pie = d3.pie();
  const data_ready = pie(data.map(({ value }) => value));

  useEffect(() => {
    d3.select(centerElementRef.current)
      .attr("width", centerElementWidth)
      .attr("height", centerElementHeight)
      .attr("transform", `translate(${padding + (outerRadius - centerElementWidth / 2)}, ${padding + (outerRadius - centerElementHeight / 2)})`)
      .attr("class", styles.middleText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerElementRef.current]);

  return (
    <div>
      <svg ref={svgRef} width={2 * (outerRadius + padding)} height={2 * (outerRadius + padding)}>
        <foreignObject ref={centerElementRef}>{children}</foreignObject>
        <defs>
          <filter id={"dropShadow"}>
            <feDropShadow stdDeviation={"4 4"} in="SourceGraphic" dx={0} dy={0} className={styles.filter}></feDropShadow>
          </filter>
        </defs>
        <g className={styles.container} transform={`translate(${outerRadius + padding}, ${outerRadius + padding})`}>
          {data_ready.map((arc, index) => (
            <Arc
              arcInfo={arc}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              color={colorCarousel(index)}
              filterId={styles.filter}
              title={data[index].title}
              UOM={UOM}
              key={index}
            ></Arc>
          ))}
        </g>
      </svg>
    </div>
  );
};

export { DonutChart, type DonutChartProps };
