import { FC } from "react";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Ranking as SequentialPage } from "features/ranking/ranking";

const TABS: TabItem[] = [
  {
    key: "drilling",
    label: "Эксплуатационное бурение",
    children: <SequentialPage type="sequential" />,
    default: true,
  },
  {
    key: "reconstruction",
    label: "Реконструкция скважин",
    children: <SequentialPage type="sequential" />,
  },
];

const Sequential: FC = () => (
  <ForecastSelector>
    <PageFrame title="Очередность бурения" tabs={TABS} />
  </ForecastSelector>
);

export { Sequential };
