import { createContext, FC, PropsWithChildren, useContext, useMemo } from "react";

import { useDebetContext } from "pages/project/tech/debet/context";

import { TechForecastModel } from "./models/techForecastModel";

const TechForecastModelContext = createContext<TechForecastModel | null>(null);

const TechForecastContext: FC<PropsWithChildren> = ({ children }) => {
  const debet = useDebetContext();
  const model = useMemo(() => new TechForecastModel(debet), [debet]);
  return <TechForecastModelContext.Provider value={model}>{children}</TechForecastModelContext.Provider>;
};

const useTechForecastModel = (): TechForecastModel => {
  const model = useContext(TechForecastModelContext);
  console.assert(model !== null, "Использование модели прогноза технологических показателей вне контекста");
  return model!;
};

export { TechForecastContext, type TechForecastModel, useTechForecastModel };
