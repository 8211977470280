import { reqCamel as req } from "utils/request";

type WellPadBackend = {
  id: number;
  title: string;
  fieldId: number;
  code: string | null;
};

type WellPadRaw = Omit<WellPadBackend, "code">;

type WellPadUnsaved = Omit<WellPadRaw, "id">;

async function getWellPads(): Promise<WellPadRaw[]> {
  const response = await req.get<WellPadBackend[]>("mines/");
  return response.map(({ id, title, code, fieldId }) => ({
    id,
    title: code ?? title,
    fieldId,
  }));
}

async function saveWellPad(data: WellPadUnsaved): Promise<WellPadBackend> {
  return req.post<WellPadBackend>("mines/", { ...data, code: data.title });
}

export type { WellPadRaw };
export { getWellPads, saveWellPad };
