import { UploadFile } from "antd";
import { objectToCamel } from "ts-case-convert";
import type { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { req } from "utils/request";

import { reqLogged } from "./logger";

type InterventionBackend = {
  id: number;
  well_id: number;
  scenario_id: number;
  gtm_type_id: number | null;
  stratum_id: number | null;
  date: string | null;
  oil_rate: number | null;
  liquid_rate: number | null;
  water_cut: number | null;
  recoverable_resources: number | null;
  link_scenario_well_id: number | null;
  is_ranking_result_date: boolean;
};

type InterventionParsedBackend = Omit<InterventionBackend, "well_id"> & {
  well_id: number | null;
  well_title: string | null;
  stratum_title: string | null;
  gtm_type_title: string | null;
  producing_object_id: number | null;
  producing_object_title: string | null;
};

type InterventionBackendUnsaved = Omit<InterventionBackend, "id" | "link_scenario_well_id">;

type InterventionRaw = ObjectToCamel<Omit<InterventionBackend, "link_scenario_well_id">>;

type InterventionUnsaved = ObjectToCamel<Omit<InterventionBackendUnsaved, "scenario_id">> & {
  id: number | undefined;
};

type InterventionParsedRaw = ObjectToCamel<Omit<InterventionParsedBackend, "id" | "link_scenario_well_id">>;

const toRawInterventions = ({ link_scenario_well_id, ...d }: InterventionBackend): InterventionRaw => objectToCamel(d);
const toParsedInterventions = ({ link_scenario_well_id, id, ...d }: InterventionParsedBackend): InterventionParsedRaw =>
  objectToCamel(d);

const getInterventions = async (forecastId: number): Promise<InterventionRaw[]> => {
  const response = await req.get<InterventionBackend[]>(`gtms/?${req.args({ scenario_id: forecastId })}`);
  return response.map(toRawInterventions);
};

type InterventionsUnsaved = {
  updatedInterventions?: InterventionUnsaved[];
  createdInterventions?: InterventionUnsaved[];
  deletedIds?: number[];
};

const setInterventions = async (
  scenarioId: number,
  { createdInterventions, updatedInterventions, deletedIds }: InterventionsUnsaved
): Promise<InterventionRaw[]> => {
  const addIntervention = (intervention: InterventionUnsaved) =>
    reqLogged.post<InterventionBackend>("gtms/", { ...intervention, scenarioId });

  const deleteIntervention = (interventionId: number) =>
    reqLogged.delete<InterventionBackend>(`gtms/${interventionId}`);

  deletedIds && (await Promise.all(deletedIds.map(deleteIntervention)));
  createdInterventions && (await Promise.all(createdInterventions.map(addIntervention)));
  updatedInterventions &&
    (await Promise.all(
      updatedInterventions
        .filter(({ id }) => id !== undefined)
        .map((intervention) => deleteIntervention(intervention.id!).then(() => addIntervention(intervention)))
    ));

  return getInterventions(scenarioId);
};

const parseExcelFile = async (file: UploadFile, forecastId: number): Promise<InterventionParsedRaw[]> => {
  const formData = new FormData();
  formData.append("file", file as unknown as Blob);

  try {
    const response = await req.post<InterventionParsedBackend[]>(
      `gtms/parse_excel/?scenario_id=${forecastId}`,
      formData
    );
    return response.map(toParsedInterventions);
  } catch (err) {
    throw new Error("invalid file format");
  }
};

const deleteIntervention = async (id: number): Promise<{ status: number; response?: InterventionRaw; err?: any }> => {
  try {
    const response = await req.delete<InterventionRaw>(`gtms/${id}`);
    return Promise.resolve({ status: 200, response });
  } catch (err) {
    return Promise.reject({ status: 422, err });
  }
};

const deleteInterventions = async (
  forecastId: number
): Promise<{ status: number; response?: InterventionRaw[]; err?: any }> => {
  try {
    const response = await req.delete<InterventionRaw[]>(`gtms/?${req.args({ scenario_id: forecastId })}`);
    return Promise.resolve({ status: 200, response });
  } catch (err) {
    return Promise.reject({ status: 422, err });
  }
};

type InterventionsTypesResponse = {
  title: string;
  id: number;
};

const getInterventionTypes = (): Promise<InterventionsTypesResponse[]> =>
  req.get<InterventionsTypesResponse[]>("gtms/types/");

export {
  deleteIntervention,
  deleteInterventions,
  getInterventions,
  getInterventionTypes,
  parseExcelFile,
  setInterventions,
};

export type {
  InterventionParsedRaw,
  InterventionRaw,
  InterventionsTypesResponse,
  InterventionsUnsaved,
  InterventionUnsaved,
};
