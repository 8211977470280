import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import { ReactComponent as LogoIcon } from "./logo.svg";

import cn from "./logo.module.less";

const Logo: FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div className={classnames(className, cn.class)} onClick={() => navigate("/")}>
      <LogoIcon />
      ИПРМ
    </div>
  );
};

export { Logo };
