import { ReactNode, useMemo, useRef } from "react";
import { Axis } from "@okopok/axes_context";
import { LineDataModel } from "@okopok/axes_context";
import { NumberValue } from "d3";

import { boundDomain, Domain, joinDomains, valuesDomain } from "utils/boundDomain";

const isNotEqualPredicate = (origin: Axis[]) => (a: Axis, id: number) => a.isEqual(origin[id]) === false;

const isEqual = (a: Axis[], b: Axis[] | null): boolean =>
  a.length === b?.length && a.find(isNotEqualPredicate(b)) === undefined;

const useScaleFromLines = (
  lines: LineDataModel[] | undefined,
  xDomainPadding: NumberValue,
  titles?: Map<string, ReactNode>,
  rightAxis?: Set<string>,
  yearsOnly?: Set<string>,
  isXAxisDate?: boolean,
  yDomains?: Map<string, Domain>
): Axis[] => {
  const result = useMemo(() => {
    if (lines === undefined || lines.length === 0 || lines.filter((line) => line.y.length > 0).length === 0) {
      return [
        new Axis(
          "x",
          "bottom",
          { min: 0, max: 1 },
          titles?.get("x"),
          undefined,
          yearsOnly?.has("x"),
          isXAxisDate ?? true
        ),
      ];
    }
    const domains = new Map<string, Domain>();
    let xDomain = boundDomain(lines.filter((line) => line.y.length > 0)[0]!.x);
    for (const line of lines.filter(
      (line) => line.y.length > 0 && line.y.find((value) => isFinite(+value)) !== undefined
    )) {
      const known = domains.get(line!.axisKey);
      domains.set(line!.axisKey, joinDomains(line!.domain ?? valuesDomain(line!.y), known));
      if ((Array.isArray(line!.x) && line!.x.length > 0) || (!Array.isArray(line!.x) && line!.x.min !== line!.x.max))
        xDomain = joinDomains(xDomain, boundDomain(line!.x));
    }
    xDomain.min = +xDomain.min - +xDomainPadding;
    xDomain.max = +xDomain.max + +xDomainPadding;
    const axes: Axis[] = [
      new Axis("x", "bottom", xDomain, titles?.get("x"), undefined, yearsOnly?.has("x"), isXAxisDate ?? true),
    ];

    for (const [key, domain] of domains) {
      if (+domain!.min === +domain!.max) {
        domain!.max = +domain!.min + 1;
      }
      if (yDomains?.has(key)) {
        axes.push(
          new Axis(
            key,
            rightAxis?.has(key) ? "right" : "left",
            yDomains.get(key)!,
            titles?.get(key),
            undefined,
            yearsOnly?.has(key),
            undefined
          )
        );
      } else {
        axes.push(
          new Axis(
            key,
            rightAxis?.has(key) ? "right" : "left",
            domain,
            titles?.get(key),
            undefined,
            yearsOnly?.has(key),
            undefined
          )
        );
      }
    }

    return axes;
  }, [lines, xDomainPadding, titles, yearsOnly, isXAxisDate, yDomains, rightAxis]);

  const prevStore = useRef<Axis[] | null>(null);

  const current = prevStore.current;

  // если в действительности в массиве ничего не изменилось то возвращаем старый объект массива
  if (isEqual(result, current)) {
    return current!;
  }
  prevStore.current = result;
  return result;
};

export { useScaleFromLines };
