import { Metric } from "services/finance/utils";
import { Range } from "utils/range";
import { req } from "utils/request";

import { formatSchema, ParamsResponse } from "./utils";

const getDepositsResolver = async (range: Range): Promise<{ schema_operation: Metric[] }> => {
  const { schema_operation } = await req.get<ParamsResponse<"schema_operation">>("demo/schemas/basic");
  return { schema_operation: formatSchema(schema_operation.schema, range) };
};

export { getDepositsResolver };
