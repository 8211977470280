import { useRef } from "react";
import { Spin } from "antd";
import classNames from "classnames";
import * as d3 from "d3";
import { useResultModel } from "routing/outlines/result/resultPage";

import { Card } from "elements/card/card";
import D3SVG from "elements/charts/lineChart/D3SVG/D3SVG";
import { SummaryContext } from "features/summary/summaryPage";
import { SummaryModel } from "models/summary";
import { colorCarousel } from "services/colorCarousel";
import { useContainerShape } from "utils/useContainerShape";

import styles from "./reportChart.module.less";
const InvestReportChart = () => {
  const result = useResultModel();
  const model = new SummaryModel(result);
  const cardRef = useRef(null);
  const cardRefShape = useContainerShape(cardRef);
  return (
    <SummaryContext.Provider value={model}>
      <Card title="Динамика денежного потока" className={classNames(styles.card)}>
        <div className={styles["cardBody"]} ref={cardRef}>
          {Number.isNaN(cardRefShape.width) ? (
            <Spin />
          ) : (
            <D3SVG
              curves={model.DynamicInvestCashFlow.map((item, index) => {
                return {
                  points: item,
                  fragments: [
                    {
                      from: new Date(model.ranges[0].from, 0, 1),
                      to: new Date(model.ranges[1].from, 0, 1),
                      lineStyles: { lineStyle: "solid" },
                      color: colorCarousel(2 + index),
                      shadow: false,
                      fragmentBorder: false,
                    },
                    {
                      from: new Date(model.ranges[1].from, 0, 1),
                      to: new Date(model.ranges[1].to, 0, 1),
                      lineStyles: { lineStyle: "dashed" },
                      color: colorCarousel(2 + index),
                      shadow: false,
                      fragmentBorder: false,
                    },
                  ],
                  yId: 1 + index,
                  zIndex: -1,
                  curveMethod: d3.curveLinear,
                  title: 1 + index === 1 ? "ДП от инвестиционной деятельности, млн руб" : "ДП от инвестиций в оборотный капитал, млн руб",
                };
              })}
              width={cardRefShape.width - 7}
              height={300 - 7}
              xAxis={{
                domain: { min: new Date(model.ranges[0].from, 0, 1), max: new Date(model.ranges[0].to, 0, 1) },
              }}
              yAxesLeft={[]}
              yAxesRight={[
                {
                  uom: "ДП от инвестиционной деятельности, млн руб",
                  id: 1,
                  color: colorCarousel(2),
                },
                {
                  uom: "ДП от инвестиций в оборотный капитал, млн руб",
                  id: 2,
                  color: colorCarousel(3),
                },
              ]}
            ></D3SVG>
          )}
        </div>
      </Card>
    </SummaryContext.Provider>
  );
};

export default InvestReportChart;
