import { RowBar } from "../rowBar/rowBar";

import { BarsDataModel } from "./barDataModel";
import { StackedBar } from "./stackedBar";

type BarsProps = {
  bars: BarsDataModel;
};

const StackedBars = ({ bars }: BarsProps) => {
  const axisKey = bars.axisKey;
  const preparedBars = bars.preparedBars;
  return (
    <>
      {(bars.inRow === undefined || bars.inRow === false) &&
        Array.from(preparedBars).map(([year, barInfo], index) => <StackedBar key={index} axisKey={axisKey} x={+year} y={barInfo} />)}
      {bars.inRow === true &&
        Array.from(preparedBars).map(([year, barInfo], index) => <RowBar key={index} axisKey={axisKey} x={+year} y={barInfo} />)}
    </>
  );
};

export default StackedBars;
