/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import classNames from "classnames";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";

import Line from "./Line";

import styles from "./Curve.module.less";

type CurveProps = {
  i: number;
};

const Curve = observer(({ i }: CurveProps) => {
  const model = useD3Context();

  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    model.curves[i].fragments.forEach((fragment, j) => {
      model.curves[i].fragments.forEach((fragment) => {
        if (fragment.fragmentBorderLeft === true)
          d3.select(`.fragment-border-visible-${i}-${j}${model.svgClassName}`)
            .attr("x", xScale(fragment.from))
            .attr("y", model.clipPaths[i][j].y)
            .attr("height", model.clipPaths[i][j].height);
        if (fragment.fragmentBorderRight === true)
          d3.select(`.fragment-border-visible-${i}-${j}${model.svgClassName}`)
            .attr("x", xScale(fragment.to))
            .attr("y", model.clipPaths[i][j].y)
            .attr("height", model.clipPaths[i][j].height);
      });
      d3.select(`.clipPath-${i}-${j}-container-` + model.svgClassName)
        .append("clipPath")
        .attr("id", `clipPath-${i}-${j}` + model.svgClassName)
        .append("rect")
        .attr("class", `clipPath-${i}-${j}-rect` + model.svgClassName)
        .attr(
          "x",
          xScale(fragment.from < xScale.domain()[0] ? xScale.domain()[0] : fragment.from < xScale.domain()[1] ? fragment.from : xScale.domain()[1])
        )
        .attr("y", model.padding.top)
        .attr(
          "width",
          xScale(fragment.to < xScale.domain()[0] ? xScale.domain()[0] : fragment.to < xScale.domain()[1] ? fragment.to : xScale.domain()[1]) -
            xScale(fragment.from < xScale.domain()[0] ? xScale.domain()[0] : fragment.from < xScale.domain()[1] ? fragment.from : xScale.domain()[1])
        )
        .attr("height", model.height - model.padding.bottom - model.padding.top);
    });
    return () => {
      model.curves[i].fragments.forEach((fragment, j) => {
        d3.select(`#clipPath-${i}-${j}` + model.svgClassName).remove();
      });
    };
  }, [
    i,
    model.YDomains[model.curves[i].yId].min,
    model.YDomains[model.curves[i].yId].max,
    model.currentZoomState,
    model.height,
    model.width,
    model.curves[i].points.map((point) => point.y),
  ]);
  return (
    <>
      {model.curves[i].fragments.map((fragment, j) => (
        <g key={j} className={`clipPath-${i}-${j}` + model.svgClassName} style={{ opacity: model.curves[i].opacity, transition: "0.2s" }}>
          <g className={`clipPath-${i}-${j}-container-` + model.svgClassName}></g>

          {(fragment.fragmentBorderLeft ?? false) && (
            <rect
              className={classNames(`fragment-border-visible-${i}-${j}${model.svgClassName}`, styles.coloredLine)}
              width={2}
              clipPath={`url(#clipPath-${model.svgClassName})`}
            ></rect>
          )}
          {(fragment.fragmentBorderRight ?? false) && (
            <rect
              className={classNames(`fragment-border-visible-${i}-${j}${model.svgClassName}`, styles.coloredLine)}
              width={2}
              clipPath={`url(#clipPath-${model.svgClassName})`}
            ></rect>
          )}
          <Line i={i} j={j} fragment={fragment}></Line>
        </g>
      ))}
    </>
  );
});

export default Curve;
