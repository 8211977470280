import { FC } from "react";
import { Button, Tooltip } from "antd";
import { observer } from "mobx-react";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Icon } from "elements/icon/icon";

import { NodeType, PipeType, useInfrastructureMapContext } from "../../InfrastructureMapManager/InfrastructureMapManager";
import { NodeInspector } from "../nodeInspector/nodeInspector";
import { PipeInspector } from "../pipeInspector/pipeInspector";
import { Container } from "../ui/container";

import { ReactComponent as NodeInfoIcon } from "./nodeInfo.svg";

import cn from "./multiInspector.module.less";

const SelectedItemsList: FC<{ list: (NodeType | PipeType)[]; onClick: (item: NodeType | PipeType) => void }> = ({ list, onClick }) => (
  <>
    {list.map((el) => (
      <div key={el.uuid} onClick={() => onClick(el)}>
        {el.title}
        <Icon width="14px" height="14px" viewBox="0 0 14 14" content={<NodeInfoIcon />} />
      </div>
    ))}
  </>
);

const MultiInspector: FC = observer(() => {
  const { inspector, removeSelectedItems } = useInfrastructureMapContext();

  const isDisabled = (inspector.selectedNodes || []).some((el) => el.type === "mine");

  if (inspector.currentNode) {
    return <NodeInspector node={inspector.currentNode} />;
  }

  if (inspector.currentPipe) {
    return <PipeInspector pipe={inspector.currentPipe} />;
  }

  return (
    <Container title="Инспектор">
      <div className={cn.wrapper}>
        <div className={cn.title}>Выбрано</div>
        <div className={cn.list}>
          {inspector.selectedNodes && <SelectedItemsList list={inspector.selectedNodes} onClick={inspector.setCurrentItem} />}
          {inspector.selectedPipes && <SelectedItemsList list={inspector.selectedPipes} onClick={inspector.setCurrentItem} />}
        </div>
        <Tooltip title={isDisabled ? "Кустовые площадки нельзя удалить" : ""}>
          <DeleteButton title={`Вы точно хотите удалить выбранные элементы?`} onClick={() => removeSelectedItems()}>
            <Button disabled={isDisabled} danger>
              Удалить ({[...(inspector.selectedNodes || []), ...(inspector.selectedPipes || [])].length})
            </Button>
          </DeleteButton>
        </Tooltip>
      </div>
    </Container>
  );
});

export { MultiInspector };
