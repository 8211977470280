import { FC } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import classNames from "classnames";

import { global } from "models/global";

import cn from "./userCard.module.less";

type UserCardProps = {
  id: number | null;
  className?: string;
  large?: boolean;
};

const UserCard: FC<UserCardProps> = ({ id, className, large }) => {
  const { users } = global;
  const user = users.at(id ?? 0);

  return (
    <div className={classNames(cn.card, className)}>
      <div className={large ? cn["large-avatar"] : cn.avatar}>
        <UserOutlined />
      </div>
      <div>
        <Typography.Text>{user ? user.fullName : "неизвестно"}</Typography.Text>
      </div>
    </div>
  );
};

export { UserCard };
