import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable } from "mobx";

import { UOM } from "elements/uom";
import { LicenseRegion } from "models/project/licenseRegion/licenseRegion";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { zip } from "utils/itertools";

import { DRow, ParamKeys, ParamsStore, ReservesDepletion } from "./tableModel";

type Param<Key extends ParamKeys> = {
  key: Key;
  title: string;
  measure: UOM;
};

function sumNullableArrays(arr1: (number | null)[], arr2: (number | null)[]): (number | null)[] {
  console.assert(arr1.length === arr2.length);
  return arr1.map((v, i) => (arr2[i] || v) && (arr2[i] ?? 0) + (v ?? 0));
}

class ParamsNode<Key extends ParamKeys> extends TableNode<DRow> {
  asDRow = (): DRow => ({
    name: this.name,
    measure: this.param.measure.unit,
    ...Object.fromEntries(zip([...this.paramsRange], this.data)),

    isEditable: this.licenseRegion !== null && this.parent.producingObject !== null,
  });

  constructor(public parent: LicenseRegionsGeneric<Key>, public licenseRegion: LicenseRegion | null) {
    super(parent, { isExpandedChildren: true });
    this.childrenStore = null;
    makeObservable<ParamsNode<Key>, "data">(this, {
      summary: computed,
      data: computed,
      updateValue: action,
    });
  }

  private get name(): string {
    const prefix = this.parent.producingObject !== null ? "объекта " : "";
    const suffix = this.licenseRegion !== null ? `на участке ${this.licenseRegion.title}` : "в масштабе месторождения";
    return `${this.param.title} ${prefix}${suffix}`;
  }

  private get param(): Param<Key> {
    return this.parent.parent.param;
  }

  public get data(): (number | null)[] {
    if (this.licenseRegion) {
      return this.parent.dataSlice[this.licenseRegion.id][this.param.key];
    }
    return this.summary;
  }

  public get summary(): (number | null)[] {
    let summary = this.paramsRange.array.fill(null);
    for (const lrParams of Object.values(this.parent.dataSlice)) {
      summary = sumNullableArrays(summary, lrParams[this.param.key]);
    }
    return summary;
  }

  private get paramsRange() {
    return this.parent.parent.root.params.paramsRange;
  }

  updateValue(year: number, newValue: number | null): [prevValue: any, currValue: any] {
    const idx = this.paramsRange.id(year);
    const prev = this.data[idx];
    return [prev, (this.data[idx] = newValue)];
  }
}

class LicenseRegionsGeneric<Key extends ParamKeys> extends TableNode<DRow, ParamsNode<Key>> {
  asDRow = (): DRow => ({
    name: `${this.parent.param.title} ${this.producingObject?.title ?? "месторождения"}`,
    measure: null,
  });

  constructor(public parent: ProdObjectsGeneric<Key>, public producingObject: ProducingObject | null) {
    super(parent, { isExpandedChildren: true });
    makeObservable(this, {
      summary: computed,
      dataSlice: computed,
    });

    this.childrenStore = new ChildrenStoreArray(this, [
      new ParamsNode(this, null),
      ...parent.root.licenseRegions!.map((lr) => new ParamsNode(this, lr)),
    ]);
  }

  public get dataSlice(): ParamsStore<Key>[number] {
    if (this.producingObject === null) {
      return this.summary;
    }
    return this.parent.root.data![this.producingObject.id];
  }

  public get summary(): ParamsStore<Key>[number] {
    const summary: ParamsStore<Key>[number] = {};
    const paramKey = this.parent.param.key;
    for (const lr of this.parent.root.licenseRegions!) {
      summary[lr.id] = {
        [paramKey]: this.parent.root.params.paramsRange.array.fill(null),
      } as Record<Key, (number | null)[]>;
    }
    for (const prodObjParams of Object.values(this.parent.root.data!)) {
      for (const [lrId, lrParams] of Object.entries(prodObjParams)) {
        const id = Number(lrId);
        summary[id][paramKey] = sumNullableArrays(summary[id][paramKey], lrParams[paramKey]);
      }
    }
    return summary;
  }
}

class ProdObjectsGeneric<Key extends ParamKeys> extends TableNode<DRow, LicenseRegionsGeneric<Key>> {
  asDRow = (): DRow => ({
    name: this.param.title,
    measure: null,
  });

  constructor(public root: ReservesDepletion, public param: Param<Key>) {
    super(root, { isExpandedChildren: true });
    this.childrenStore = new ChildrenStoreArray(this, [
      new LicenseRegionsGeneric(this, null),
      ...root.producingObjects!.map((prodObj) => new LicenseRegionsGeneric(this, prodObj)),
    ]);
  }
}

export { ProdObjectsGeneric };
