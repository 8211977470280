import { action, computed, makeObservable, observable } from "mobx";

import { Lazy } from "models/lazy";
import { calcMock } from "services/back/calculate/calcMock";
import {
  CachedValues,
  CalculationExecNormalResult,
  loadCalculation,
  loadCalculationMeta,
} from "services/back/calculate/calculate";

import { Forecast } from "./forecast";
import { ForecastResult } from "./forecastResult";

type ForecastResultPack = {
  total: ForecastResult;
  [licenseRegionId: number]: ForecastResult;
};

function constructResultModels(
  forecast: Forecast,
  calcResult: CalculationExecNormalResult
): Map<number, ForecastResultPack> {
  const resultModels = new Map<number, ForecastResultPack>();
  const { calculated, ...rest } = calcResult;
  for (const [uscId, { total, ...byLZ }] of calculated.entries()) {
    const resultPack: ForecastResultPack = {
      total: new ForecastResult(forecast, { ...rest, calculated: total }, "total"),
    };
    for (const [lzId, lzResult] of Object.entries(byLZ)) {
      const licenseRegionId = lzId !== undefined ? parseInt(lzId) : forecast.licenseRegions.ids[0];
      const licenseRegionTitle = forecast.licenseRegions.data.find((region) => region.id === licenseRegionId)?.title;
      resultPack[licenseRegionId] = new ForecastResult(forecast, { ...rest, calculated: lzResult }, licenseRegionTitle);
    }

    resultModels.set(uscId, resultPack);
  }
  return resultModels;
}

class LazyKeeper<T> {
  #value = observable.box<T | undefined>(undefined);

  constructor(v: () => Promise<T>) {
    v().then((result) => this.#value.set(result));
  }

  get value(): T | undefined {
    return this.#value.get();
  }
}

class ForecastResultOperator {
  private savedValue: null | Lazy<LazyKeeper<Map<number, ForecastResultPack>>> | undefined;
  public cached: CachedValues | null | undefined;
  public savedTS: undefined | null | Date;

  public calculated: null | undefined | Map<number, ForecastResultPack> = (() => {
    const mock = calcMock();
    if (mock === null) {
      return null;
    }
    // если подложить в папку services/back/calculate файл с моком то он будет использоваться сразу в конструкторе расчета
    return constructResultModels(this.fc, mock);
  })();

  private async initSaved() {
    const savedMeta = await loadCalculationMeta(this.fc);
    if (savedMeta === null || savedMeta.forecastId !== this.fc.id) {
      this.savedValue = null;
      this.savedTS = null;
      return;
    }
    this.savedTS = savedMeta.ts;
    this.cached = savedMeta.cached;
    this.savedValue = new Lazy(
      () => new LazyKeeper(async () => constructResultModels(this.fc, (await loadCalculation(this.fc))!))
    );
  }

  get saved(): undefined | null | Map<number, ForecastResultPack> {
    if (this.savedValue === null || this.savedValue === undefined) {
      return this.savedValue;
    }
    return this.savedValue.value.value;
  }

  public drop() {
    this.savedValue = null;
    this.savedTS = null;
  }

  constructor(private fc: Forecast) {
    this.initSaved();

    makeObservable<ForecastResultOperator, "initSaved" | "savedValue">(this, {
      initSaved: action,
      savedValue: observable,
      saved: computed,
      savedTS: observable,
      cached: observable,
      calculated: observable,
    });
  }
}

export { constructResultModels, ForecastResultOperator, type ForecastResultPack };
