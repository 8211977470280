import { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Menu, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Icon } from "elements/icon/icon";
import { global } from "models/global";

import { COMPARE, GENERAL, MANAGEMENT, Page, ProjectFolder, REPORTS, SCENARIO } from "../../assets/routing";

import cn from "./modeSwitch.module.less";

type MenuItem = Required<MenuProps>["items"][number];

const useCurrentPath = (): [ProjectFolder, Page | undefined] => {
  const { scenario } = useMainRouterParams();
  const { pathname } = useLocation();
  let expectedKey = pathname.split("/")[2 + (scenario ? 1 : 0)];

  const folder =
    GROUPS.flat().find(
      (item) =>
        // если ключ группы совпадает
        item.key === expectedKey ||
        // или если среди детей есть соответствующий ключ
        ("children" in item && item.children.find(({ key }) => key === expectedKey))
    ) ??
    // иначе берём дефолтную
    GENERAL;

  const child = "children" in folder ? folder.children.find(({ key }) => key === expectedKey) : undefined;

  return [folder, child];
};

const MenuNarrowButton: FC = observer(() => {
  const [folder] = useCurrentPath();

  return (
    <div className={cn.menuToggler}>
      <Tooltip placement="right" title="Свернуть/показать подменю">
        <Button
          disabled={!("children" in folder) && folder.key !== "infrastructure"}
          onClick={global.toggleNarrowMenu}
          type="text"
          size="large"
          icon={global.isNarrowMenu ? <RightCircleOutlined /> : <LeftCircleOutlined />}
          className={cn.btn}
        />
      </Tooltip>
    </div>
  );
});

MenuNarrowButton.displayName = "narrowMenu";

const GROUPS = [[GENERAL, MANAGEMENT], SCENARIO, [COMPARE, REPORTS]];

const isEndOfGroup = (key: string): boolean => GROUPS.find((group) => group.at(-1)!.key === key) !== undefined;

const MenuIcon: FC<{ index: string; title: React.ReactNode; icon: React.ReactNode; showTooltip: boolean; onNavigate: () => void }> = ({
  title,
  icon,
  index,
  showTooltip,
  onNavigate,
}) => {
  return (
    <Tooltip className={classNames(isEndOfGroup(index) && cn.lastOfGroup)} placement="right" title={showTooltip ? title : ""}>
      <div onClick={onNavigate}>
        <Icon content={icon} style={{ fontSize: "calc(24px / 1.1428)" }} />
      </div>
    </Tooltip>
  );
};

const useOnNavigate = () => {
  const navigate = useNavigate();
  const { project, scenario } = useMainRouterParams();

  return useCallback(
    (key: string) => {
      navigate(["", project, scenario, key].filter((v) => v !== undefined).join("/"));
    },
    [navigate, project, scenario]
  );
};

const ModeSwitch: FC<{ className?: string }> = observer(({ className }) => {
  const [folder, child] = useCurrentPath();
  const onNavigate = useOnNavigate();

  const initialKey = child?.key ?? folder.key;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([initialKey]);

  useEffect(() => {
    setSelectedKeys([initialKey]);
  }, [initialKey]);
  const TabsWithSubMenu: MenuItem[] = GROUPS.flat().map((group, ...content) => {
    return {
      key: "children" in group && group.children && global.isNarrowMenu && group.children.length > 0 ? `p-${group.key}` : group.key,
      label: (
        <MenuIcon
          onNavigate={() => onNavigate(group.key)}
          index={group.key}
          title={group.title}
          icon={group.icon}
          showTooltip={!("children" in group) || !global.isNarrowMenu}
        />
      ),

      ...("children" in group && group.children && global.isNarrowMenu && group.children.length > 0
        ? {
            children: group.children.map((child) => ({
              key: child.key,
              label: child.title,
            })),
            popupClassName: cn.popup,
          }
        : {}),
      disabled: "element" in content && content.element === null,
    };
  });

  return (
    <>
      <MenuNarrowButton />
      <Menu
        activeKey={folder.key}
        selectedKeys={selectedKeys}
        className={classNames(cn.tabs, className)}
        onClick={(e) => {
          onNavigate(e.key);
        }}
        items={TabsWithSubMenu}
        mode="vertical"
      />
    </>
  );
});

export { ModeSwitch, useCurrentPath };
