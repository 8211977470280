import fieldsData from "./fields.json";

type NamedEntity = {
  title: string;
  id: number;
};

type FieldInfo = {
  company: string;
  ngdo: string;
  tpp: string;
  field: string;
};

type FieldEntity = NamedEntity & { tppId: number };

type TppEntity = NamedEntity & { ngdoId: number };

type Ngdo = NamedEntity;

type Tpp = NamedEntity & { ngdo: Ngdo };

type Field = NamedEntity & { tpp: Tpp };

type FieldsList = {
  fields: FieldEntity[];
  tppList: TppEntity[];
  ngdoList: Ngdo[];
};

const parseData = (fieldsInfo: FieldInfo[]): FieldsList => {
  const fieldsList: FieldsList = {
    fields: [],
    tppList: [],
    ngdoList: [],
  };
  fieldsInfo.forEach(({ tpp, ngdo, field }) => {
    if (!fieldsList.ngdoList.find(({ title }) => title === ngdo)) {
      fieldsList.ngdoList.push({
        id: fieldsList.ngdoList.length + 1,
        title: ngdo,
      });
    }
    if (!fieldsList.tppList.find(({ title }) => title === tpp)) {
      const ngdoId = fieldsList.ngdoList.find(({ title }) => title === ngdo)?.id;
      if (ngdoId === undefined) {
        console.assert(`ngdoId not found for tpp ${tpp}`);
      } else {
        fieldsList.tppList.push({
          id: fieldsList.tppList.length + 1,
          title: tpp,
          ngdoId,
        });
      }
    }
    if (!fieldsList.fields.find(({ title }) => title === field)) {
      const tppId = fieldsList.tppList.find(({ title }) => title === tpp)?.id;
      if (!tppId) {
        console.assert(`tppId not found for field ${field}`);
      } else {
        fieldsList.fields.push({
          id: fieldsList.fields.length + 1,
          title: field,
          tppId,
        });
      }
    }
  });

  return fieldsList;
};

const FIELDS_LIST = parseData(fieldsData as FieldInfo[]);
const { fields, tppList, ngdoList } = FIELDS_LIST;

const tpp = tppList.map(({ ngdoId, ...rest }) => ({ ...rest, ngdo: ngdoList[ngdoId - 1] }));
const fieldsList = fields.map(({ tppId, ...rest }) => ({ ...rest, tpp: tpp[tppId - 1] }));

const getFieldsList = (): Promise<Field[]> => Promise.resolve(fieldsList);

const getTpp = (): Promise<Tpp[]> => Promise.resolve(tpp);

export { getFieldsList, getTpp };
export type { FieldEntity, Tpp };
