/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from "react";
import { Button, Form, InputNumber, Select, Tabs, TabsProps } from "antd";
import { observer } from "mobx-react";

import { Card } from "elements/card/card";
import { Format } from "elements/format/format";
import { Loader } from "elements/loader";
import { useFact } from "models/project/fact/fact";
import { HTR_TYPES, HTRType } from "services/wells";

import cn from "./stratumParameters.module.less";

const HTR_TYPES_OPTIONS = HTR_TYPES.map((item) => {
  return { value: item };
});

const StratumParameters = observer(() => {
  const state = useFact()!;
  const [isUpdated, setUpdated] = useState(false);
  const [isValid, setValid] = useState(true);
  const isCanSubmit = isUpdated && isValid;
  const [isLoading] = useState(false);

  const { stratums } = state;

  const checkValid = useCallback(
    (_: any, changed: Record<string, string>) => {
      const dataset = {
        ...stratums.formInitialValues,
        ...changed,
      };
      setUpdated(stratums.isUpdated(dataset));
      setValid(stratums.isValid(dataset));
    },
    [state?.stratums]
  );

  const onFinish = useCallback(async (data: Record<string, string | HTRType>) => {
    // setLoading(true);
    // await stratums.update({
    //   ...stratums.formInitialValues,
    //   ...data,
    // });
    // setLoading(false);
    // checkValid(null, data);
    console.error("stratums update not implemented");
  }, []);

  const items: TabsProps["items"] = useMemo(
    () =>
      stratums?.items?.map(
        ({ id, title }) =>
          ({
            key: `${id}`,
            label: <Format>{title}</Format>,
            children: (
              <div className={cn.tab}>
                <Form.Item label="Категория ТРИЗ" name={`htrType${id}`}>
                  <Select className={cn.select} options={HTR_TYPES_OPTIONS} />
                </Form.Item>
                <Form.Item label="Выработанность на 2012 год" name={`depletion${id}`}>
                  <InputNumber variant="borderless" addonAfter="%" min={0} max={100} className={cn.nameInput} />
                </Form.Item>
              </div>
            ),
          } ?? [])
      ),
    [(stratums?.items?.map(({ title }) => title) ?? []).join(""), stratums.length]
  );

  if (state.stratums === undefined || state.stratums.isLoading) {
    return <Loader />;
  }

  return (
    <Card title="Параметры налогового режима">
      <Form className={cn.form} disabled onFinish={onFinish} initialValues={state?.stratums.formInitialValues} onValuesChange={checkValid}>
        <Tabs items={items} className={cn.tabs}></Tabs>
        <Button disabled={!isCanSubmit} loading={isLoading} type="primary" htmlType="submit">
          {!isUpdated ? "Данные не изменены" : isValid ? "Сохранить" : "Название пустое или одинаковое для нескольких залежей"}
        </Button>
      </Form>
    </Card>
  );
});

export { StratumParameters };
