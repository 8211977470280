import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { debounce } from "throttle-debounce";

import { Loader } from "elements/loader";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { TreeRoot } from "models/tree/tree";

import { Chart } from "./chart";
import { YearDatum } from "./constants";
import { Table } from "./table";
import { aggregate } from "./utils";

const View: FC<{ tree: TreeRoot<Well>; className?: string }> = observer(({ tree, className }) => {
  const forecast = useForecast()!;
  const years = useMemo(() => [...forecast.wholeRange].map(year => dayjs(`${year}-01-01`)), [forecast]);
  const [data, setData] = useState<YearDatum[] | undefined>(undefined);

  const immediateCall = useRef<() => Promise<void> | undefined>();
  const call = useCallback(debounce(100, () => immediateCall.current?.(), { atBegin: false }), []); // eslint-disable-line react-hooks/exhaustive-deps

  immediateCall.current = () =>
      aggregate(
        forecast.wholeRange,
        tree.selectedItems.map((item) => item.item!),
        forecast.production
      ).then(setData);

  useEffect(() => {
    setData(undefined);
    call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecast, tree.selectedItems]);

  return (
    <div className={className}>
      {data?.length ? (
        <>
          <Chart years={years} data={data ?? []} />
          <Table years={years} data={data ?? []} />
        </>
      ) : <Loader />}
    </div>
  );
});

export { View };
