import { type RefObject, useMemo } from "react";
import * as d3 from "d3";

class MapManager {
  #zoom?: d3.ZoomBehavior<SVGSVGElement, unknown>;
  #svgRef?: RefObject<SVGSVGElement>;

  updateClosure(zoom: d3.ZoomBehavior<SVGSVGElement, unknown>, svgRef: RefObject<SVGSVGElement>) {
    this.#zoom = zoom;
    this.#svgRef = svgRef;
  }

  setInitialScale = () => {
    if (this.#zoom) {
      this.#zoom.transform(d3.select(this.#svgRef!.current!), d3.zoomIdentity);
    }
  };
}

const useMapManager = (zoom: d3.ZoomBehavior<SVGSVGElement, unknown>, svgRef: RefObject<SVGSVGElement>): MapManager => {
  const manager = useMemo(() => new MapManager(), []);
  manager.updateClosure(zoom, svgRef);

  return manager;
};

export { type MapManager, useMapManager };
