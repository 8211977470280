import { FC } from "react";
import { ChartContext } from "@okopok/axes_context";
import { Lines } from "@okopok/axes_context";
import { Empty, Typography } from "antd";
import { observer } from "mobx-react";

import { CornerLabels } from "elements/charts/additionalLabels/cornerLabels/cornerLabels";
import { YearLabels } from "elements/charts/additionalLabels/yearLabels/yearLabels";
import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";

import { DecreaseEffectChartModel } from "./decreaseEffectChartModel";

import cn from "./decreaseEffectChart.module.less";
type DecreaseEffectChartProps = {
  chartModel: DecreaseEffectChartModel;
};
const YEARS_ONLY = new Set(["x"]);
const TITLES = new Map<string, string>([["y", "Среднесуточная добыча, т/сут"]]);

const DecreaseEffectChart: FC<DecreaseEffectChartProps> = observer(({ chartModel }) => {
  const axes = useScaleFromLines(chartModel.lines, 0, TITLES, undefined, YEARS_ONLY);
  return (
    <div className={cn.layout}>
      {chartModel.isValid && (
        <>
          <div className={cn.title}>
            {chartModel.lines
              .slice()
              .reverse()
              .map((line, index) => (
                <div key={index} className={cn.legendItem}>
                  {line.color !== null && <div className={cn.circle} style={{ backgroundColor: line.color }} />}
                  <div className={cn.value}>{line.title}</div>
                </div>
              ))}
          </div>
          <ChartContext axes={axes} className={cn.chart}>
            <TooltipContext.Provider value={chartModel.tooltipManager}>
              <Lines data={chartModel.lines} />
              <PointerLine />
            </TooltipContext.Provider>
            <CornerLabels
              topLeft="Общее изменение добычи"
              topRight="Общее изменение добычи"
              bottomLeft="Изменение базисной добычи"
              bottomRight="Изменение базисной добычи"
            />
            <YearLabels topLabels={chartModel.yearLabelsTop} bottomLabels={chartModel.yearLabelsBottom} />
          </ChartContext>
          <TooltipDiv manager={chartModel.tooltipManager} />
        </>
      )}
      {!chartModel.isValid && (
        <div className={cn.empty}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Typography.Text>Таблица заполнена не полностью</Typography.Text>} />
        </div>
      )}
    </div>
  );
});

export { DecreaseEffectChart };
