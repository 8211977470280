import { observer } from "mobx-react";

import { FullScreenEmpty, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Comparison as ComparisonFeature } from "features/comparison/comparison";
import { useFact } from "models/project/fact/fact";

const Comparison = observer(() => {
  const fact = useFact();

  if (fact === undefined || fact === null || fact.forecasts.isLoading) {
    return <FullScreenLoader />;
  }

  if (fact.forecasts.length! <= 1) {
    return <FullScreenEmpty>Для сравнения нужно создать более одного прогноза по месторождению</FullScreenEmpty>;
  }

  return <ComparisonFeature fact={fact} />;
});

export { Comparison };
