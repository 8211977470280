import { req } from "utils/request";

import { backendStorage } from "./backendStorage";

const PROD_OBJ_LR_PARAMS_KEYS = ["reserves"] as const;

type ProducingObjectLicenseRegionsParams = Record<(typeof PROD_OBJ_LR_PARAMS_KEYS)[number], number[]>;

type ProducingObjectsLicenseRegionsParams = {
  [producingObjectId: number]: {
    [licenseRegionId: number]: ProducingObjectLicenseRegionsParams;
  };
};

const LR_PARAMS_STORAGE_KEY = "producing_object_license_region_params" as const;
function getProducingObjectsLicenseRegionsParams(
  projectId: number,
  scenarioId: number | undefined
): Promise<ProducingObjectsLicenseRegionsParams | null> {
  return backendStorage.getItem<ProducingObjectsLicenseRegionsParams>(LR_PARAMS_STORAGE_KEY, projectId, scenarioId);
}
function setProducingObjectsLicenseRegionsParams(
  projectId: number,
  scenarioId: number | undefined,
  params: ProducingObjectsLicenseRegionsParams
): Promise<ProducingObjectsLicenseRegionsParams | null> {
  return backendStorage.setItem<ProducingObjectsLicenseRegionsParams>(
    params,
    LR_PARAMS_STORAGE_KEY,
    projectId,
    scenarioId
  );
}

type ProducingObjectsHTRTypesIds = {
  [producingObjectId: number]: (number | null)[];
};

const HTR_TYPES_STORAGE_KEY = "producing_object_htr_types" as const;
function getProducingObjectsHTRTypesIds(
  projectId: number,
  scenarioId: number | undefined
): Promise<ProducingObjectsHTRTypesIds | null> {
  return backendStorage.getItem<ProducingObjectsHTRTypesIds>(HTR_TYPES_STORAGE_KEY, projectId, scenarioId);
}
function setProducingObjectsHTRTypesIds(
  projectId: number,
  scenarioId: number | undefined,
  ids: ProducingObjectsHTRTypesIds
): Promise<ProducingObjectsHTRTypesIds | null> {
  return backendStorage.setItem<ProducingObjectsHTRTypesIds>(ids, HTR_TYPES_STORAGE_KEY, projectId, scenarioId);
}

type HTRType = {
  id: number;
  title: string;
};
function getHTRTypes(): Promise<HTRType[]> {
  return req.get<HTRType[]>("htr/types/");
}

export { PROD_OBJ_LR_PARAMS_KEYS };
export type { HTRType, ProducingObjectsHTRTypesIds, ProducingObjectsLicenseRegionsParams };
export {
  getHTRTypes,
  getProducingObjectsHTRTypesIds,
  getProducingObjectsLicenseRegionsParams,
  setProducingObjectsHTRTypesIds,
  setProducingObjectsLicenseRegionsParams,
};
