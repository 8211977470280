import { reqCamel } from "utils/request";

import { ProjectParticipantInfo } from "./projectParticipants";

const getUsers = async (): Promise<(ProjectParticipantInfo & { title: string })[]> =>
  (await reqCamel.get<(ProjectParticipantInfo & { title: string })[]>("users")).map(({ fullName, ...rest }) => ({
    ...rest,
    fullName,
    title: fullName,
  }));

const addUser = async (email: string) =>
  await reqCamel.post<ProjectParticipantInfo & { title: string }>("users/create", { email });

const deleteUser = async (email: string) => await reqCamel.delete<{ id: number }>("users", { email });

export { addUser, deleteUser, getUsers };
