import { createContext, FC, RefObject, useContext } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";

const CONTEXT = createContext<{
  toolboxRef: RefObject<HTMLElement>;
} | null>(null);

const ResultPageToolboxPortalContextProvider = CONTEXT.Provider;

type ResultPageTitlePortalProps = {
  children?: React.ReactNode;
};

const ResultPageTitlePortal: FC<ResultPageTitlePortalProps> = observer(({ children }) => {
  const { toolboxRef } = useContext(CONTEXT)!;
  if (toolboxRef.current === null) {
    return null;
  }

  return createPortal(children, toolboxRef.current);
});

export { ResultPageTitlePortal, ResultPageToolboxPortalContextProvider };
