import { FC } from "react";
import { Scale } from "@okopok/axes_context";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { WrapTooltip } from "elements/tooltips/wrapTooltip/wrapTooltip";

import { type GridMapDataManager } from "../GridMapDataManager";
import { useHoverContext } from "../hoverManager";
import { PadTooltip } from "../tooltip/padTooltip";

import Cell from "./Cell";
import { WellPadType } from "./WellPad";

type CellsProps = {
  dataManager: GridMapDataManager;
  scale: Scale;
  zoom: number;
  active?: boolean;
  type: WellPadType;
};

const Cells: FC<CellsProps> = observer(({ dataManager, scale, zoom, active, type }) => {
  const colorName = type === "mining" ? "rgb(219, 68, 50)" : "rgb(100, 200, 50)";
  const color = d3.color(colorName)!;
  console.assert(color !== null, "Ошибка задания цвета");
  const colorBorder = color?.toString();
  color.opacity = 0.3;
  const colorHover = active ? color?.toString() : "rgba(0, 0, 0, 0)";
  const hoverManager = useHoverContext();
  const cells = dataManager.wells!.map((point) => {
    const cell = (
      <Cell
        onMouseEnter={() => hoverManager?.findIntersectId(point.id)}
        onMouseLeave={() => hoverManager?.reset()}
        key={point.id}
        id={point.id}
        scale={scale}
        cell={point.polygon}
        color={colorBorder}
        colorHover={colorHover}
      />
    );
    if (!active) {
      return cell;
    }
    return (
      <WrapTooltip id={point.id} key={point.id} title={<PadTooltip wellPad={point} />}>
        {cell}
      </WrapTooltip>
    );
  });

  return <>{cells}</>;
});

export { Cells };
