import { useCallback } from "react";
import { DatePicker, type DatePickerProps } from "antd";
import type { Dayjs } from "dayjs";

type MonthPickerProps = Omit<DatePickerProps, "picker" | "disabledDate"> & {
  start?: Dayjs;
  end?: Dayjs;
};

const MonthPicker = ({ start, end, ...props }: MonthPickerProps) => {
  const disabledDate = useCallback(
    (date: Dayjs) => {
      return !date || (end !== undefined && date.isAfter(end.add(1, "month"))) || (start !== undefined && date.isBefore(start));
    },
    [start, end]
  );

  return (
    <DatePicker
      format="MM.YYYY"
      changeOnBlur
      placeholder={props.placeholder ?? "Выбрать дату"}
      {...props}
      picker="month"
      disabledDate={disabledDate}
    />
  );
};

export { MonthPicker };
