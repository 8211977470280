import { PageFrame } from "routing/pageFrame/pageFrame";

import { LicenseRegions as LicenseRegionsFeature } from "features/fieldParameters/licenseRegions";
import { Preloader } from "features/preloader/preloader";
import { useFact } from "models/project/fact/fact";

const useLicenseRegions = () => useFact()?.licenseRegions;

const LicenseRegions = () => (
  <Preloader hooks={[useLicenseRegions]}>
    <PageFrame title="Лицензионные участки">
      <LicenseRegionsFeature />
    </PageFrame>
  </Preloader>
);

export { LicenseRegions };
