import { ReactNode, useMemo } from "react";
import { Axis } from "@okopok/axes_context";
import { LineDataModel } from "@okopok/axes_context";

import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { useScaleFromBars } from "elements/charts/stackedBars/useScaleFromBars";
import { Domain, joinDomains } from "utils/boundDomain";

const YEAR = 31536000000 / 2;
type Padding = {
  axisKey: string;
  top?: number;
  bottom?: number;
};
const useLinesBarsComposition = (
  lines?: LineDataModel[] | undefined,
  bars?: BarsDataModel | null,
  paddings?: Padding[],
  titles?: Map<string, ReactNode>,
  rightAxis?: Set<string>,
  yearsOnly?: Set<string>
): Axis[] => {
  const lineAxes = useScaleFromLines(lines, YEAR, titles, rightAxis, yearsOnly);
  const barAxes = useScaleFromBars(bars, titles, bars?.axisKey ? rightAxis?.has(bars?.axisKey) : undefined, yearsOnly);
  const result = useMemo(() => {
    if (lineAxes.length === 0 && barAxes.length < 2) return [];
    let xDomain: Domain | undefined;
    if (lineAxes.length > 1) {
      for (let i = 0; i < lineAxes.length; ++i) {
        const padding = paddings?.find((padding) => padding.axisKey === lineAxes[i].key);
        if (padding) {
          console.assert(1 - (padding.top ?? 0) - (padding.bottom ?? 0) > 0, "Отступы слишком большие");
          const newLength =
            (+lineAxes[i].domain.max - +lineAxes[i].domain.min) / (1 - (padding.top ?? 0) - (padding.bottom ?? 0));

          if (padding.top) {
            lineAxes[i].domain.max = +lineAxes[i].domain.max + newLength * padding.top;
          }
          if (padding.bottom) {
            lineAxes[i].domain.min = +lineAxes[i].domain.min - newLength * padding.bottom;
          }
        }
        if (lineAxes[i].key === "x") {
          xDomain = joinDomains(lineAxes[i].domain, xDomain);
        }
      }
    }

    if (barAxes.length > 1) {
      const padding = paddings?.find((padding) => padding.axisKey === barAxes[1].key);
      if (padding !== undefined) {
        console.assert(1 - (padding.top ?? 0) - (padding.bottom ?? 0) > 0, "Отступы слишком большие");
        const newLength =
          (+barAxes[1].domain.max - +barAxes[1].domain.min) / (1 - (padding.top ?? 0) - (padding.bottom ?? 0));
        if (padding.top) {
          barAxes[1].domain.max = +barAxes[1].domain.max + newLength * padding.top;
        }
        if (padding.bottom) {
          barAxes[1].domain.min = +barAxes[1].domain.min - newLength * padding.bottom;
        }
      }

      xDomain = joinDomains(barAxes[0].domain, xDomain);
    }
    return [
      ...lineAxes.filter((axis) => axis.key !== "x"),
      ...(barAxes.length > 1 ? [barAxes[1]] : []),
      new Axis(
        "x",
        "bottom",
        xDomain!,
        titles?.get("x") ? `${titles?.get("x")}` : undefined,
        undefined,
        yearsOnly?.has("x"),
        true
      ),
    ];
  }, [barAxes, lineAxes, paddings, titles, yearsOnly]);

  return result;
};
export { useLinesBarsComposition };
export type { Padding };
