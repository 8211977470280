import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { constructionKeys, reconstructionKeys } from "features/infrastructure/utils";
import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { InfrastructureCatalog } from "models/project/fact/infrastructure/infrastructureCatalog";
import { SegmentType } from "services/back/infrastructure/catalog";
import { getRandomUid } from "utils/random";

type CustomKeysType = {
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;
  reconstructionDsw: number;
  reconstructionCiw: number;
  reconstructionEquipment: number;
  reconstructionOther: number;
  period: number;
};

type DRow = CustomKeysType & {
  title: string;
  steel: string | null;
  diameter?: number;
  diameterOuter?: number;
  thickness?: number;
  workPressure: number | null;
  roughness?: number;
  limitingVelocity?: number;
  limitingPressureGradient?: number;
  total?: number;
  totalReconstruction?: number;
  isBlocked?: boolean;
  construction?: SegmentType["construction"];
  reconstruction?: SegmentType["reconstruction"];
  remove?: () => void;
};

class Pipe extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    total: this.total,
    diameter: this.diameter,
    totalReconstruction: this.totalReconstruction,
    ...(!this.data.isBlocked && { remove: this.remove }),
  });
  public data: CustomKeysType & SegmentType;
  constructor(private parent: Pipes, data: SegmentType, private catalog: InfrastructureCatalog) {
    super(parent);

    makeObservable(this, {
      data: observable,
      total: computed,
      diameter: computed,
      totalReconstruction: computed,
      updateValue: action,
    });

    this.data = Pipe.formatSegmentData(data);
  }

  static formatSegmentData = (data: SegmentType) => {
    const { dsw, ciw, equipment, other } = data.construction!;
    const {
      dsw: reconstructionDsw,
      ciw: reconstructionCiw,
      equipment: reconstructionEquipment,
      other: reconstructionOther,
      period,
    } = data.reconstruction!;

    return {
      ...data,
      dsw,
      ciw,
      equipment,
      other,
      reconstructionDsw,
      reconstructionCiw,
      reconstructionEquipment,
      reconstructionOther,
      period,
    };
  };

  get diameter() {
    return this.data.diameterOuter - this.data.thickness;
  }

  get total() {
    const { dsw, ciw, equipment, other } = this.data!;
    return dsw + ciw + equipment + other;
  }
  get totalReconstruction() {
    const { reconstructionDsw, reconstructionCiw, reconstructionEquipment, reconstructionOther } = this.data!;
    return reconstructionDsw + reconstructionCiw + reconstructionEquipment + reconstructionOther;
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const { construction, reconstruction } = this.data;
    const [k, value] = [key as keyof SegmentType, newValue as never];
    const prev = this.data[k];
    this.data[k] = value;

    if (constructionKeys[key as keyof SegmentType["construction"]]) {
      const newConstruction = { ...construction!, [key]: value, totalCostPerUnit: this.total };
      this.data.construction = newConstruction;
      this.catalog.update({ uuid: this.data.uuid, construction: newConstruction }, "pipe");
    } else if ((reconstructionKeys as any)[key]) {
      const k = key as keyof typeof reconstructionKeys;
      const newReconstruction = {
        ...reconstruction!,
        [reconstructionKeys[k]]: value,
        totalCostPerUnit: this.totalReconstruction,
      };
      this.data.reconstruction = newReconstruction;
      this.catalog.update({ uuid: this.data.uuid, reconstruction: newReconstruction }, "pipe");
    } else if (k === "diameterOuter") {
      const diameter = value - this.data.thickness;
      this.catalog.update({ uuid: this.data.uuid, [k]: value, diameter }, "pipe");
    } else {
      this.catalog.update({ uuid: this.data.uuid, [k]: value }, "pipe");
    }

    return [prev, value];
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.catalog.remove(this.data.uuid, "pipe");
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

class Pipes extends TableNode<DRow, Pipe> {
  constructor(public infrastructure: Infrastructure, public mode: "prod" | "inj") {
    super();
    this.init();
  }

  public init = () => {
    const pipes = this.infrastructure.catalog.pipes(this.mode);
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        pipes.map((el) => new Pipe(this, el, this.infrastructure.catalog)) ?? []
      );
    });
  };

  public push = () => {
    const newPipe: SegmentType = {
      id: Math.floor(Math.random() * 1000),
      uuid: getRandomUid(),
      title: "Новый " + (this.mode === "prod" ? "нефтепровод" : "водовод"),
      steel: null,
      diameter: 0,
      diameterOuter: 0,
      thickness: 0,
      roughness: 0,
      workPressure: 0,
      limitingVelocity: 0,
      limitingPressureGradient: 0,
      frictionCorrectionFactor: 1,
      segmentType: this.mode,
      construction: {
        dsw: 0,
        ciw: 0,
        other: 0,
        equipment: 0,
        totalCostPerUnit: 0,
      },
      reconstruction: {
        dsw: 0,
        ciw: 0,
        other: 0,
        equipment: 0,
        totalCostPerUnit: 0,
        isActive: false,
        period: 0,
      },
    };
    this.infrastructure.catalog.push(newPipe, "pipe");
    this.childrenStore?.push(new Pipe(this, newPipe, this.infrastructure.catalog));
  };
}

export { type DRow as DRowPipes, Pipes };
