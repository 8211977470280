import { FC, useCallback, useState } from "react";
import { Button, Form, Input } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { Logo } from "elements/logo/logo";
import { global } from "models/global";
import { LoginInfo } from "services/auth";

import { ReactComponent as LoginIcon } from "./login.svg";
import { ReactComponent as PasswordIcon } from "./passwd.svg";

import cn from "./login.module.less";

const FORM_VALUE: LoginInfo = { username: "admin", password: "" };

const Login: FC = observer(() => {
  const [isLoading, setLoading] = useState(false);
  const clickHolder = useCallback((credentials: LoginInfo) => {
    setLoading(true);
    global.login(credentials).finally(() => {
      setLoading(false);
    });
  }, []);
  return (
    <Form className={cn.wrap} layout="vertical" initialValues={FORM_VALUE} onFinish={clickHolder}>
      <Logo className={cn.logo} />
      <div className={cn.form}>
        <p>Добро пожаловать</p>
        <h1>Вход в систему</h1>
        <Form.Item required name="username">
          <Input className={cn.input} prefix={<Icon className={cn.prefix} content={<LoginIcon />} />} placeholder="Логин"></Input>
        </Form.Item>
        <Form.Item required name="password">
          <Input type="password" className={cn.input} prefix={<Icon className={cn.prefix} content={<PasswordIcon />} />} placeholder="Пароль"></Input>
        </Form.Item>
        <Button loading={isLoading} htmlType="submit" className={cn.submit} type="primary">
          Войти
        </Button>
      </div>
    </Form>
  );
});

export { Login };
