import { createContext, FC, useContext, useMemo } from "react";

import { TechForecastContext } from "features/techForecast/useTechForecastModel";
import { useProjectContext } from "models/project/context/projectContext";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Debet as DebetStore } from "models/project/fact/forecast/techPrediction/techPrediction";
import { TreeContextProvider } from "models/tree/context";

const DebetContext = createContext<DebetStore | null>(null);

const useDebetContext = () => {
  const debetModel = useContext(DebetContext);
  console.assert(debetModel !== null, "Использование модели дебита скважин вне контекста");
  return debetModel!;
};

const DebetContextProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const forecast = useForecast()!;

  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;

  // useEffect(() => { // TODO: ADD FILTRATION
  //   const targetItems = new Set(
  //     tree.items
  //       .map((well) => [well, forecast.interventions.getInterventionsByWellId(well.id)] as const)
  //       .filter(([well, interventions]) => well.data.stratumId !== null || interventions.length > 0)
  //       .map(([well]) => well.id)
  //   );

  //   tree.applyGlobalFilter((well) => targetItems.has(well.id));
  //   return () => {
  //     tree.dropGlobalFilter();
  //   };
  // }, [forecast.interventions, tree]);

  const store = useMemo(() => new DebetStore(forecast, tree), [forecast, tree]);
  return (
    <TreeContextProvider value={tree}>
      <DebetContext.Provider value={store}>
        <TechForecastContext>{children}</TechForecastContext>
      </DebetContext.Provider>
    </TreeContextProvider>
  );
};

export { DebetContextProvider, useDebetContext };
