import { useResultDrainSourcesTableStore } from "./useResultDrainSourcesTableStore";
import { useResultMinesTableStore } from "./useResultMinesTableStore";
import { useResultPipesTableStore } from "./useResultPipesTableStore";

const useResultsTableByMode = (mode: string) => {
  const { columns: minesColumns, store: minesStore } = useResultMinesTableStore();
  const { columns: oilColumns, store: oilStore } = useResultPipesTableStore("prod");
  const { columns: waterColumns, store: waterStore } = useResultPipesTableStore("inj");
  const { columns: drainsColumns, store: drainsStore } = useResultDrainSourcesTableStore("drain");
  const { columns: sourcesColumns, store: sourcesStore } = useResultDrainSourcesTableStore("source");

  if (mode === "prod") {
    return { columns: oilColumns, store: oilStore };
  }
  if (mode === "mines") {
    return { columns: minesColumns, store: minesStore };
  }
  if (mode === "drain") {
    return { columns: drainsColumns, store: drainsStore };
  }
  if (mode === "source") {
    return { columns: sourcesColumns, store: sourcesStore };
  }
  return { columns: waterColumns, store: waterStore };
};

export { useResultsTableByMode };
