import { FC } from "react";
import { Widget } from "@okopok/components/Table";
import { Empty } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { PermissionButton } from "elements/permissionButton/permissionButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import { useMaterialBalanceContext } from "./materialBalanceProvider";

import cn from "./calculationResult.module.less";

const useColumns = (producingObject: ProducingObject[]): Column[] => [
  {
    title: "Параметр",
    width: { min: 300, max: 330, competitiveness: 1 },
    dataKey: "title",
    type: "string",
    isSticky: true,
  },
  {
    title: "Ед. измерения",
    width: { min: 120, max: 120, competitiveness: 1 },
    dataKey: "measure",
    type: "string",
    isSticky: true,
  },
  //@ts-ignore
  ...producingObject.map(({ title, id }) => ({
    title,
    width: { min: 72, max: 144, competitiveness: 1 },
    dataKey: id,
    type: "number",
    editable: true,
  })),
];
const InputData: FC = observer(() => {
  const { producingObjects } = useProject()!;
  const columns = useColumns(Array.from(producingObjects.values ?? []));
  const materialBalance = useMaterialBalanceContext();

  const project = useProject()!;
  const forecast = useForecast();
  const edit = getUserPermission(project, forecast)["tech"];

  if (materialBalance.isLoadingSettings) {
    return <FullScreenLoader />;
  }

  return edit.value ? (
    <>
      <PageFrameTitlePortal onSave={materialBalance.saveSettings} permissionSection="tech">
        <PermissionButton section="tech" type="primary" onClick={materialBalance.calculate} loading={materialBalance.isCalculating}>
          Расчитать
        </PermissionButton>
      </PageFrameTitlePortal>
      <SimpleTableContext
        data={materialBalance.store}
        columns={columns}
        exportFileName="material-balance-settings"
        tableOptions={{
          onRow: ({ expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : cn.tableRowSecondary,
          }),
        }}
        theme={{
          headerHeight: 54,
          rowHeight: 33,
          borderColor: "#e5e7eb",
        }}
      >
        <Widget />
      </SimpleTableContext>
    </>
  ) : (
    <Empty className={cn.empty} description={edit.customTooltip || "Для выполнения действия недостаточно полномочий вашей роли в проекте"} />
  );
});

export { InputData };
