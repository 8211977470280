import { runInAction } from "mobx";

import { OptionedParams } from "models/params/optionedParams/optionedParams";
import { Params } from "models/params/params";
import { costsSource } from "services/back/costs";
import { operatingUnitCostsSource } from "services/finance/operating/operating";

import { Forecast } from "../forecast/forecast";

class OperatingUnitCosts extends Params {
  private setCosts;

  constructor(forecast: Forecast) {
    const services = operatingUnitCostsSource(forecast.storageKey);
    super("Параметры операционной деятельности", forecast.range, services);
    this.optionedScalars = undefined;

    const [getCosts, setCosts] = costsSource(forecast.storageKey);
    this.setCosts = setCosts;
    getCosts().then((data) => {
      runInAction(() => {
        this.optionedScalars = new OptionedParams(data, forecast);
      });
    });
  }

  async postOptionedScalars(changed: OptionedParams) {
    return this.setCosts(changed.dump);
  }

  public async cloneOperatingInstance(forecast: Forecast): Promise<OperatingUnitCosts> {
    const clonedScalars = this.optionedScalars!.clone(forecast);
    const setCosts = costsSource(forecast.storageKey)[1];
    const [clone] = await Promise.all([
      super.cloneInstance(operatingUnitCostsSource(forecast.storageKey)) as Promise<OperatingUnitCosts>,
      setCosts(clonedScalars.dump),
    ] as const);
    clone.setCosts = setCosts;
    clone.optionedScalars = clonedScalars;
    return clone;
  }
}

export { OperatingUnitCosts };
