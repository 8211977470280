import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { TreeRoot } from "./tree";

const TreeContext = createContext<TreeRoot | null>(null);

const TreeContextProvider = ({ value, children }: PropsWithChildren<{ value: TreeRoot }>) => {
  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>;
};

const useTreeContext = (): TreeRoot => {
  console.assert(useContext<TreeRoot | null>(TreeContext) !== null, "useTreeContext out of context");
  return useContext<TreeRoot | null>(TreeContext)!;
};

const useSecureTreeContext = (): TreeRoot | null => useContext<TreeRoot | null>(TreeContext);

export { TreeContextProvider, useSecureTreeContext, useTreeContext };
