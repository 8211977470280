import { observer } from "mobx-react-lite";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";

import styles from "./YearLabels.module.less";
type CornerLabelsType = {
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
};
const CornerLabels = observer(({ topLeft, topRight, bottomLeft, bottomRight }: CornerLabelsType) => {
  const model = useD3Context();

  return (
    <g className={styles.level2}>
      <text x={model.padding.left + model.leftAxisCount * axisWidth + 20} y={model.padding.top + 25}>
        {topLeft}
      </text>
      <text x={model.width - model.padding.right - model.rightAxisCount * axisWidth - 20} y={model.padding.top + 25} style={{ textAnchor: "end" }}>
        {topRight}
      </text>
      <text x={model.padding.left + model.leftAxisCount * axisWidth + 20} y={model.height - model.padding.top - 27}>
        {bottomLeft}
      </text>
      <text
        x={model.width - model.padding.right - model.rightAxisCount * axisWidth - 20}
        y={model.height - model.padding.top - 27}
        style={{ textAnchor: "end" }}
      >
        {bottomRight}
      </text>
    </g>
  );
});

export default CornerLabels;
export type { CornerLabelsType };
