import { useState } from "react";
import { observer } from "mobx-react";

import { Card } from "elements/card/card";
import { CalculationPreloader } from "features/calculationPreloader/calculationPreloader";

const CalcParamsCheck = observer(() => {
  const [loading, setLoading] = useState(true);
  return (
    <Card margin title={loading ? "Прогноз готовится к выполнению расчета" : "Прогноз готов к выполнению расчета"}>
      <CalculationPreloader onFinishLoading={setLoading} />
    </Card>
  );
});

export { CalcParamsCheck };
