import { FC } from "react";
import { observer } from "mobx-react";

import { useTechForecastModel } from "../useTechForecastModel";

import { Method } from "./methods/method";
import { SettingsFrame } from "./settingsFrame";
import { StopCriterion } from "./stopCriterion";

import cn from "./wellTechForecastSettings.module.less";

const WellTechForecastSettings: FC = observer(() => {
  const model = useTechForecastModel();
  const fc = model.currentForecast;
  const { settings } = fc;
  return (
    <div className={cn.layout}>
      {settings.metricForCompute !== "liquid" && (
        <SettingsFrame mode="liquid">
          <Method mode="liquid" />
        </SettingsFrame>
      )}
      {settings.metricForCompute !== "waterCut" && (
        <SettingsFrame mode="waterCut">
          <Method mode="waterCut" />
        </SettingsFrame>
      )}
      {settings.metricForCompute !== "oil" && (
        <SettingsFrame mode="oil">
          <Method mode="oil" />
        </SettingsFrame>
      )}
      <SettingsFrame mode="stopCriterion">
        <StopCriterion />
      </SettingsFrame>
    </div>
  );
});

export { WellTechForecastSettings };
