import type { FC } from "react";
import { useChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { ForecastMode } from "services/back/techForecast/request";

import { LineBounds } from "./lineBounds";

type BoundsProps = {
  metric: ForecastMode;
};

const Bounds: FC<BoundsProps> = observer(({ metric }) => {
  const axes = useChartContext();

  const model = useTechForecastModel();
  const { chart, settings } = model.currentForecast;

  const linesInfo = chart.visibleMetricLines(metric);

  if (!settings[metric].isNeedBounds) {
    return null;
  }

  if (linesInfo.length === 0) {
    return null;
  }

  if (axes.isNotReady) {
    return null;
  }
  const xScale = axes.axisX.scale;

  return (
    <>
      {linesInfo.map((lineInfo, infoIndex) => {
        const yScale = axes.at(lineInfo.axisKey)?.scale;
        if (yScale === undefined) {
          return null;
        }
        return (
          <LineBounds key={infoIndex} accessor={chart.factDomainAccessor!} xScale={xScale} yScale={yScale} lineInfo={lineInfo} metric={metric} />
        );
      })}
    </>
  );
});

export { Bounds };
