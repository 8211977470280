import { useResultModel } from "routing/outlines/result/resultPage";

import { Results } from "./results";

const CashflowResults = () => {
  const result = useResultModel();

  return <Results model={result.cashflow} exportFileName="Денежный поток" />;
};

export { CashflowResults };
