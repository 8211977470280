import dayjs, { Dayjs } from "dayjs";

class DateRange {
  public readonly monthDuration: number;
  public readonly yearsCount: number;

  constructor(public readonly from: Dayjs, public readonly to: Dayjs) {
    console.assert(!to.isBefore(from), `wrong range value ${from} >= ${to}`);
    this.monthDuration = to.diff(from, "month") + 1;
    this.yearsCount = to.year() - from.year() + 1;
  }

  get arrayYears() {
    return new Array(this.yearsCount);
  }

  get arrayMonths() {
    return new Array(this.monthDuration);
  }

  public index(year: number, month: number): number | undefined {
    const date = dayjs(`${year}-${month + 1}-01`);
    return this.indexDate(date);
  }

  public *months(): Generator<[month: number, year: number]> {
    let month = this.from.month();
    let year = this.from.year();

    for (let i = 0; i < this.monthDuration; ++i) {
      yield [month, year];
      month = (month + 1) % 12;
      year = year + +(month === 0);
    }
  }

  public *years(): Generator<number> {
    const yearFrom = this.from.year();
    const yearTo = this.to.year();
    for (let i = yearFrom; i <= yearTo; ++i) {
      yield i;
    }
  }

  public indexDate(date: Dayjs): number | undefined {
    const diff = date.diff(this.from, "month");
    if (diff < 0 || diff > this.monthDuration) {
      return undefined;
    }
    return diff;
  }

  public *monthDates(): Generator<Dayjs> {
    for (const [month, year] of this.months()) {
      const r = dayjs(`${year}-${month + 1}-01`);
      if (r.isBefore(this.from)) {
        console.warn(r.format("YYYY-MM-DD"), this.from.format("YYYY-MM-DD"));
      }
      yield r;
    }
  }

  public *yearDates(): Generator<Dayjs> {
    for (const year of this.years()) {
      yield dayjs(`${year}-01-01`);
    }
  }
}

export { DateRange };
