import { FC, ReactNode } from "react";
import { Scale } from "@okopok/axes_context";
import { observer } from "mobx-react";
import styled from "styled-components";

import { useHoverContext } from "../hoverManager";

type CellProps = {
  scale: Scale;
  cell: d3.Delaunay.Polygon;
  color: string;
  id: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  colorHover: string;
  children?: ReactNode;
};

const Region = styled.polygon<{ $color: string; $strokeColor: string; $colorHover?: string }>`
  fill: ${(props) => props.$color};
  stroke-width: 0.3px;
  stroke: ${(props) => props.$strokeColor};
  &:hover {
    fill: ${(props) => props.$colorHover};
  }
`;
const Cell: FC<CellProps> = observer(({ scale, cell, color, id, onMouseEnter, onMouseLeave, colorHover, children }) => {
  const hoverArray = useHoverContext()?.hoverArray ?? [];
  return (
    <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Region
        $color={hoverArray.includes(id) ? colorHover : "rgba(0, 0, 0, 0)"}
        $strokeColor={color}
        $colorHover={colorHover}
        points={cell.map(([x, y]) => `${scale.x(x)}, ${scale.y(y)}`).join(",")}
      />
      {children}
    </g>
  );
});

export default Cell;
