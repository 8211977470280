import { FC } from "react";
import { ColumnRaw } from "@okopok/components/Table";
import { DatePicker, Input } from "antd";
import dayjs from "dayjs";

import { InputNumber } from "elements/inputs/inputNumber/inputNumber";

import type { CustomColumnType, RowKey } from "./customColumns";

type CustomInput<V> = FC<{ value: V | null | undefined; onChange: (newValue: V | null | undefined) => void }>;

const CustomInputString: CustomInput<string> = ({ value, onChange }) => {
  return <Input value={value ?? undefined} onChange={(e) => onChange(e.target.value)} />;
};

const CustomInputNumber: CustomInput<number> = ({ value, onChange }) => {
  return <InputNumber bordered value={value} onUpdate={onChange} />;
};

const CustomInputDate: CustomInput<string> = ({ value, onChange }) => {
  return (
    <DatePicker
      style={{ width: "100%" }}
      changeOnBlur
      placeholder=""
      value={value ? dayjs(value) : undefined}
      onChange={(_, ds) => onChange(ds as string)}
    />
  );
};

const INPUT_MAP: Record<CustomColumnType, CustomInput<any>> = {
  string: CustomInputString,
  number: CustomInputNumber,
  date: CustomInputDate,
};

function columnRenderers(
  columnTitle: string,
  columnType: CustomColumnType,
  update: (rowKey: RowKey, columnTitle: string, newValue: any) => void
): Pick<ColumnRaw<{ rowKey?: RowKey }>, "render" | "renderToString"> {
  return {
    render: (v, { value }) =>
      value?.rowKey === undefined ? null : INPUT_MAP[columnType]({ value: v, onChange: (newValue) => update(value.rowKey!, columnTitle, newValue) }),
    renderToString: (value) => value,
  };
}

export { columnRenderers, INPUT_MAP };
