type Factor = {
  value: number;
  label: string;
  key: string;
  disabled?: boolean;
};

const FACTORS: Factor[] = [
  {
    value: 1,
    key: "Добыча нефти",
    label: "Добыча",
  },
  {
    value: 2,
    key: "prod_wells",
    label: "Общий фонд скважин",
  },
  {
    value: 3,
    key: "Операционные расходы (с инфляцией)",
    label: "Удельные операционные затраты",
  },
  {
    value: 4,
    key: "Денежный поток от инвестиционной деятельности",
    label: "Удельные инвестиционные затраты",
  },
  {
    value: 5,
    key: "Строительство, всего",
    label: "Инфраструктурное обустройство",
  },
];

export { FACTORS };
