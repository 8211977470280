import type { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET: FC<{ modified: boolean }> = ({ modified }) => (
  <g fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1053 19L13.4487 17.4263V13.3022L18.6915 7.98519C18.8891 7.78597 19 7.51253 19 7.22619V6.06228C19 5.47508 18.5392 5 17.9707 5H6.02934C5.46078 5 5 5.47508 5 6.06228V7.25281C5 7.52302 5.09919 7.78274 5.27803 7.97955L10.1053 13.3022V19Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {modified && <circle cx="18" cy="5" r="3" fill="#DB4432" stroke="white" strokeWidth="2" />}
  </g>
);

const Filter: FC<SharedIconProps & { modified?: boolean }> = ({ modified = false, ...props }) => (
  <Icon {...props} content={<ASSET modified={modified} />} />
);

export { Filter };
