import { FC, useState } from "react";
import { observer } from "mobx-react";

import { useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";

import { Node } from "./node";

const Nodes: FC = observer(() => {
  const [selectedId, setSelectedId] = useState<string | null>();

  const { isDrawing, nodes } = useInfrastructureMapContext();

  return (
    <g>
      {nodes.map((node) => (
        <Node node={node} key={node.uuid} onClick={setSelectedId} isActive={node.isSelected || (isDrawing && selectedId === node.uuid)} />
      ))}
    </g>
  );
});

export { Nodes };
