import { FC, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { ChartContext } from "@okopok/axes_context";
import { Button, ButtonProps, Checkbox, Typography } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { ModelContentComponentType } from "elements/modal/modal";
import { type Analogs } from "features/techForecast/models/well/analogs";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Wells } from "models/project/fact/well/wells";
import { AnalogsResult, Fitting, itemKey } from "services/back/techForecast/request";

import { Line } from "../../results/chart/elements/line";
import { LineDataModel } from "../../results/chart/elements/lineDataModel";

import { Chart } from "./analogsChartModel";
import { useAnalogPointsCloud } from "./useAnalogPointsCloud";

import cn from "./analogsModal.module.less";

const Item: FC<{
  wells: Wells;
  data: AnalogsResult["items"][number];
  chart: Chart;
}> = observer(({ data, wells, chart }) => {
  const key = itemKey(data);
  return (
    <Checkbox
      className={classNames(cn.checkbox, chart.hidden.has(key) && cn.hidden)}
      onMouseEnter={chart.currentHolder(key)}
      onChange={chart.toggleHidden(key)}
      checked={!chart.hidden.has(key)}
    >
      {wells.at(data.wellId)?.title}
    </Checkbox>
  );
});

const ITEM_CONTENT = (_: number, data: AnalogsResult["items"][number], { wells, chart }: { wells: Wells; chart: Chart }) => {
  return <Item data={data} wells={wells} chart={chart} />;
};

const AwaitButton: FC<ButtonProps> = ({ onClick, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const finalOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const clc = onClick?.(e) as any;
      if (clc instanceof Promise) {
        setLoading(true);
        clc.finally(() => setLoading(false));
      }
    },
    [onClick, setLoading]
  );
  return <Button loading={isLoading} onClick={finalOnClick} {...props} />;
};

const MetricsDisplay: FC<Fitting> = ({ r2, mse, a }) => {
  return (
    <div className={cn.metrics}>
      <span className={cn.label}>R²</span>
      <span className={cn.value}>
        <Format>{r2}</Format>
      </span>
      <span className={cn.label}>MSE</span>
      <span className={cn.value}>
        <Format>{mse}</Format>
      </span>
      <span className={cn.label}>a</span>
      <span className={cn.value}>
        <Format>{a}</Format>
      </span>
    </div>
  );
};

const List: FC<{ chart: Chart; items: any; fitting?: Fitting | null }> = observer(({ items, chart, fitting: initialFitting }) => {
  const wells = useForecast()!.wells;

  const fitting = chart.fitting ?? initialFitting;
  return (
    <div className={cn.list}>
      <Typography.Title level={4}>
        Скважины аналоги
        <div className={cn.counters}>
          <Format>{chart.selectedAmount}</Format>/<Format>{chart.amount}</Format>
        </div>
      </Typography.Title>
      <div className={cn.items}>
        <Virtuoso itemContent={ITEM_CONTENT} data={items} context={{ wells, chart }} />
      </div>
      <div className={cn.update}>
        <AwaitButton disabled={chart.isUpdated} onClick={chart.updateFittings}>
          Перестроить тренд
        </AwaitButton>
        {fitting && <MetricsDisplay {...fitting} />}
      </div>
    </div>
  );
});

const HidableLine: FC<{ chart: Chart; lineInfo: LineDataModel; className?: string }> = observer(({ chart, lineInfo, className }) => {
  if (chart.hidden.has(lineInfo.key)) {
    return null;
  }
  return <Line lineInfo={lineInfo} className={className} />;
});

const ChartWidget: FC<{ chart: Chart }> = observer(({ chart }) => (
  <ChartContext className={cn.chart} axes={chart.axes}>
    <HidableLine lineInfo={chart.lines.fitting} chart={chart} />;
    <HidableLine lineInfo={chart.lines.rawFitting} chart={chart} />;
    {chart.currentLine && <HidableLine chart={chart} lineInfo={chart.currentLine} className={cn.current} />}
  </ChartContext>
));

const WellsAnalogsModal: ModelContentComponentType<void, Analogs> = observer(({ dataRef, setLoading, setOnAccept }) => {
  const analogs = dataRef.current;
  const items = analogs.currentAnalogs?.items ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chart = useMemo(() => new Chart(analogs), []);
  const { canvasRef, layoutRef } = useAnalogPointsCloud(chart);

  setOnAccept(async () => {
    if (!chart.isUpdated) {
      setLoading(true);
      await chart.updateFittings();
      setLoading(false);
    }
    if (chart.updated !== null) {
      analogs.applyModal(chart.hidden, chart.updated);
    }
    return { result: true };
  });

  return (
    <div className={cn.layout} ref={layoutRef}>
      <canvas ref={canvasRef} className={cn.points} />
      <ChartWidget chart={chart} />
      <List chart={chart} items={items} fitting={analogs.fitting} />
    </div>
  );
});

export { WellsAnalogsModal };
