import React from "react";

import { AdditionalLabelsType } from "../D3SVG/D3SVG";

import CornerLabels from "./CornerLabel";
import YearLabels from "./YearLabels";
import YearLines from "./YearLines";

const AdditionalLabels = ({ yearLines, yearLabels, cornerLabels }: AdditionalLabelsType) => {
  return (
    <g>
      {yearLines && <YearLines></YearLines>}
      {yearLabels !== undefined && <YearLabels yearLabels={yearLabels}></YearLabels>}
      {cornerLabels !== undefined && (
        <CornerLabels
          topLeft={cornerLabels.topLeft}
          topRight={cornerLabels.topRight}
          bottomLeft={cornerLabels.bottomLeft}
          bottomRight={cornerLabels.bottomRight}
        ></CornerLabels>
      )}
    </g>
  );
};

export default AdditionalLabels;
