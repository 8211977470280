import { FC } from "react";
import { observer } from "mobx-react";

import { FORECAST_MODES } from "features/techForecast/models/well/wellTechChartChannelsMeta";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { Bounds } from "./bounds/bounds";

const BoundsGroup: FC = observer(() => {
  const model = useTechForecastModel();
  const { settings } = model.currentForecast;

  return (
    <>
      {FORECAST_MODES.filter((metric) => metric !== settings.metricForCompute).map((metric) => (
        <Bounds key={metric} metric={metric} />
      ))}
    </>
  );
});

export { BoundsGroup };
