import { useState } from "react";
import { Select, SelectProps } from "antd";
import { observer } from "mobx-react";

import type { Storable } from "models/loadableStore/loadableStore";

type StoreType<T extends Storable = Storable> = {
  isLoading: boolean;
  selector: { value: number; label: string | undefined }[] | undefined;
  at(id: number): T | null | undefined;
};

type SelectStorableProps<T extends Storable> = Omit<
  SelectProps,
  "value" | "searchValue" | "options" | "onSelect" | "onSearch" | "bordered" | "showSearch" | "options" | "onClear" | "filterOption"
> & {
  values?: [id: number | null, searchValue: string | undefined];
  setValues?: (id: number | null, searchValue?: string) => void;
  store: StoreType<T>;
  caseSensitive?: boolean;
};

const SelectStorable = observer(
  <T extends Storable>({ values, setValues, store, style, caseSensitive = false, allowClear = true, ...props }: SelectStorableProps<T>) => {
    const [isDropDownOpen, setDropDownOpen] = useState<boolean>(false);
    return (
      <Select<number, { value: number; label: string | undefined }>
        {...props}
        value={values?.[0]}
        searchValue={values?.[1]}
        showSearch
        allowClear={allowClear}
        loading={store.isLoading}
        variant={props.variant ?? "borderless"}
        options={store.selector}
        onSelect={(id) => setValues?.(id)}
        onClear={() => setValues?.(null, "")}
        onDropdownVisibleChange={setDropDownOpen}
        onSearch={(input) => {
          // match checking should not happen in filterOption due to setting state conflict
          if (!isDropDownOpen) return;
          const option = store.selector?.find(({ label }) => label?.toLowerCase() === input.toLowerCase());
          if (option !== undefined) {
            setValues?.(option.value, option.label);
          } else {
            setValues?.(null, input);
          }
        }}
        filterOption={(input, option) => {
          let label = option?.label;
          if (!caseSensitive) {
            input = input.toLowerCase();
            label = label?.toLowerCase();
          }
          return (label ?? "").includes(input);
        }}
        style={{ width: "100%", ...style }}
      />
    );
  }
);

export { SelectStorable, type StoreType as SelectStoreType };
