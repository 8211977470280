import type { FC, MouseEventHandler, ReactNode } from "react";
import classNames from "classnames";

import cn from "./style.module.less";

type IconProps = {
  content: ReactNode;
  onClick?: MouseEventHandler<SVGElement>;
  reflex?: boolean;
  className?: string;
  wrapClassName?: string;
  viewBox?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
};

const Icon: FC<IconProps> = ({ content, onClick, reflex, className, wrapClassName, viewBox = "0 0 24 24", style, width = "1em", height = "1em" }) => {
  return (
    <span style={style} role="img" className={classNames(wrapClassName, "anticon")}>
      <svg
        viewBox={viewBox}
        width={width}
        height={height}
        className={classNames(className, cn.generic, { [cn.reflex]: reflex })}
        onClick={onClick}
        children={content}
      />
    </span>
  );
};

export { Icon, type IconProps };
