import * as d3 from "d3";
import { action, autorun, makeObservable, observable } from "mobx";

import { axisWidth } from "theme/global";

import { BarType, clipPathInfo, CurveType, D3SVGProps, Domain, XAxisType, YAxisType } from "./D3SVG/D3SVG";
import { PointType } from "./Points/Point/Point";
import { TooltipProps } from "./Tooltip/Tooltip";
import { LegendModel } from "./LegendModel";
type RectInfo = {
  y: number;
  color: string | number;
  yId: number;
};

class D3SVGModel {
  curves: Array<CurveType & { opacity: number }>;
  bars: { [index: number]: RectInfo[] };
  width: number;
  height: number;
  xAxis: XAxisType;
  yAxesLeft: Array<YAxisType & { domain: Domain }>;
  yAxesRight: Array<YAxisType & { domain: Domain }>;
  activeAxis: number | null;
  padding = { top: 20, left: 20, right: 10, bottom: 25 };
  clipPaths: Array<clipPathInfo[]>;
  svgClassName: string;
  leftAxisCount: number;
  rightAxisCount: number;
  YDomains: Domain[];
  grid:
    | {
        x?: { show: boolean; color?: string; opacity?: number };
        y?: { show: boolean; yId: number; color?: string; opacity?: number };
      }
    | undefined;
  zoom: any;
  currentZoomState: d3.ZoomTransform | null;
  tooltipState: TooltipProps | null;
  legendModel: LegendModel | null;

  constructor(svgProps: D3SVGProps, svgClassName: string, legend: LegendModel | null) {
    makeObservable(this, {
      curves: observable,
      YDomains: observable,
      yAxesLeft: observable,
      yAxesRight: observable,
      currentZoomState: observable,
      tooltipState: observable,
      activeAxis: observable,
      updatePoint: action,
      updateXDomain: action,
      updateYDomain: action,
      findDomain: action,
      buildCurveFall: action,
      setCurrentZoomState: action,
      setTooltipState: action,
      setCurveOpacity: action,
      setActiveAxis: action,
    });
    this.width = svgProps.width;
    this.height = svgProps.height;
    this.xAxis = svgProps.xAxis;
    this.curves = this.zIndexProcess(this.dataPointsProcess(svgProps.curves)).map((curve) =>
      Object.assign(curve, { opacity: 1 })
    );
    this.bars = this.barsProcess(svgProps.bars);
    this.activeAxis = null;
    this.legendModel = legend;
    this.legendModel?.setLegend([
      ...(svgProps.bars
        ? svgProps.bars.map((bar) => {
            return { color: bar.color, title: bar.title, id: 0, opacity: 1 };
          })
        : []),
      ...(svgProps.curves
        ? this.curves.map((curve, index) => {
            return { color: curve.fragments[0].color, title: curve.title, id: index, opacity: 1 };
          })
        : []),
    ]);

    this.yAxesLeft = svgProps.yAxesLeft.map((axis) =>
      Object.assign(axis, {
        domain: this.findDomain(
          [
            ...svgProps.curves.filter((curve) => curve.yId === axis.id).map((item) => item.points),

            Object.keys(this.bars).map((year, index) => {
              return {
                y: this.bars![parseInt(year)].reduce((accum, value) => accum + value.y, 0),
                x: new Date(parseInt(year), 0, 1),
              };
            }),
          ],
          axis.paddingTop,
          axis.paddingBottom,
          axis.startValue
        ),
        opacity: 1,
      })
    );
    this.currentZoomState = null;
    this.grid = svgProps.grid;
    this.yAxesRight = svgProps.yAxesRight.map((axis) =>
      Object.assign(axis, {
        domain: this.findDomain(
          this.curves.filter((curve) => curve.yId === axis.id).map((item) => item.points),
          axis.paddingTop,
          axis.paddingBottom,
          axis.startValue
        ),
        opacity: 1,
      })
    );
    this.YDomains = [];
    this.yAxesLeft.forEach((axis) => {
      this.YDomains[axis.id] = axis.domain;
    });

    this.yAxesRight.forEach((axis) => {
      this.YDomains[axis.id] = axis.domain;
    });

    this.svgClassName = svgClassName;
    this.leftAxisCount = svgProps.yAxesLeft.filter((axis) => axis.showAxis === undefined || axis.showAxis).length ?? 0;
    this.rightAxisCount =
      svgProps.yAxesRight.filter((axis) => axis.showAxis === undefined || axis.showAxis).length ?? 0;

    this.clipPaths = Array<clipPathInfo[]>();
    svgProps.curves.forEach((curve, index) => {
      this.clipPaths.push([]);
      const xScale = d3
        .scaleTime()
        .domain([this.xAxis.domain.min, this.xAxis.domain.max])
        .range([
          this.padding.left + axisWidth * this.leftAxisCount,
          this.width - this.padding.right - axisWidth * this.rightAxisCount,
        ]);
      for (let fragment of curve.fragments) {
        this.clipPaths[index].push({
          x1: xScale(
            fragment.from < this.xAxis.domain.min
              ? this.xAxis.domain.min
              : fragment.from < this.xAxis.domain.max
              ? fragment.from
              : this.xAxis.domain.max
          ),
          y: this.padding.top,
          x2: xScale(
            fragment.to < this.xAxis.domain.min
              ? this.xAxis.domain.min
              : fragment.to < this.xAxis.domain.max
              ? fragment.to
              : this.xAxis.domain.max
          ),
          height: svgProps.height - this.padding.bottom - this.padding.top,
        });
      }
    });
    this.zoom = d3
      .zoom()
      .scaleExtent([0.5, 5])
      .on("zoom", (event: any) => {
        const zoomState = event.transform;
        this.setCurrentZoomState(zoomState);
      });

    this.tooltipState = null;

    autorun(() => {
      if (legend) {
        if (legend.activeLabel !== null) {
          for (let i = 0; i < this.curves.length; ++i) {
            this.setCurveOpacity(i, 0.3);
          }
          this.setActiveAxis(this.curves[legend.activeLabel].yId);

          this.setCurveOpacity(legend.activeLabel, 1);
        } else {
          for (let i = 0; i < this.curves.length; ++i) {
            this.setCurveOpacity(i, 1);
            this.setActiveAxis(null);
          }
        }
      }
    });
  }

  public setActiveAxis = (yId: number | null) => {
    this.activeAxis = yId;
  };

  public setCurveOpacity = (i: number, opacity: number) => {
    if (this.curves[i]) {
      this.curves[i].opacity = opacity;
    }
  };

  public barsProcess = (bars: BarType[] | undefined) => {
    const result: { [index: number]: RectInfo[] } = {};

    if (bars === undefined) {
      return result;
    } else {
      bars.forEach((bar, index) => {
        bar.points.forEach((point) => {
          if (typeof result[point.x.getFullYear()] === "undefined") {
            result[point.x.getFullYear()] = [];
            result[point.x.getFullYear()].push({ y: point.y, color: bar.color, yId: bar.yId });
          } else {
            result[point.x.getFullYear()].push({ y: point.y, color: bar.color, yId: bar.yId });
          }
        });
      });
      return result;
    }
  };

  public zIndexProcess = (curves: CurveType[]) => {
    return curves.sort((a: CurveType, b: CurveType) => (a.zIndex ?? 0) - (b.zIndex ?? 0));
  };

  public updateXDomain = (min: Date, max: Date) => {
    this.xAxis.domain = { min: min, max: max };
  };

  public setCurrentZoomState = (zoomState: any) => {
    this.currentZoomState = zoomState;
  };

  public updateYDomain = (yId: number) => {
    const axis = this.yAxesLeft.find((axis) => axis.id === yId) ?? this.yAxesRight.find((axis) => axis.id === yId);
    const domain = this.findDomain(
      this.Curves.filter((curve) => curve.yId === yId).map((item) => item.points),
      axis?.paddingTop,
      axis?.paddingBottom
    );
    this.YDomains[yId] = { min: domain.min, max: domain.max };
  };

  public findDomainOnArray = (array: PointType[] | undefined, paddingTop?: number, paddingBottom?: number) => {
    if (array === undefined) {
      return { min: -10, max: 10 };
    }

    let min = -10,
      max = 10;

    array.forEach((item) => {
      if (item !== null) {
        min = item.y;
        max = item.y;
      }
    });

    array.forEach((item) => {
      if (item !== null) {
        if (min > item.y) {
          min = item.y;
        }
        if (max < item.y) {
          max = item.y;
        }
      }
    });

    min = min - (max - min) * (paddingBottom ?? 1 / 10);

    max = max + (max - min) * (paddingTop ?? 1 / 4);
    if (max - min <= 1) {
      max += 1;
      min -= 1;
    }
    return { min, max };
  };

  public findDomain = (
    arrays: PointType[][] | undefined,
    paddingTop?: number,
    paddingBottom?: number,
    startValue?: number
  ) => {
    let min = -10,
      max = 10;
    if (arrays === undefined) {
      return { min: -10, max: 10 };
    }
    arrays = arrays.filter((array) => array.length > 0);
    if (arrays.length > 0) {
      const domain = this.findDomainOnArray(arrays[0], paddingTop, paddingBottom);
      min = domain.min;
      max = domain.max;
      arrays.forEach((array) => {
        const domain = this.findDomainOnArray(array, paddingTop, paddingBottom);
        min = Math.min(min, domain.min);
        max = Math.max(max, domain.max);
      });
    }
    return { min: startValue ?? min, max: max };
  };

  get Curves() {
    return this.curves;
  }

  public updatePoint = (curveIndex: number, pointIndex: number, value: number) => {
    this.curves[curveIndex].points[pointIndex].y = value;
  };

  public points = (curveIndex: number) => {
    return this.curves[curveIndex].points;
  };

  private dataPointsProcess = (curves: CurveType[]) => {
    const updatedCurves: CurveType[] = [];
    curves.forEach((curve) => {
      const segments: PointType[][] = [[]];
      curve.points.forEach((point) => {
        if (
          point.x >= this.xAxis.domain.min &&
          point.x <= this.xAxis.domain.max &&
          curve.fragments.find((fragment) => fragment.from <= point.x && fragment.to >= point.x)
        ) {
          if (point.y === null) {
            if (curve.replaceNull !== undefined) {
              segments[segments.length - 1].push({ x: point.x, y: curve.replaceNull });
            } else {
              segments.push([]);
            }
          } else {
            segments[segments.length - 1].push(point);
          }
        }
      });
      segments.forEach((item) => {
        if (item.length > 0) {
          const clone: any = {};
          Object.assign(clone, curve);
          clone.points = item;
          updatedCurves.push(clone);
        }
      });
    });
    return updatedCurves;
  };

  public buildCurveFall = (i: number, k: number) => {
    const step = this.curves[i].points[k].y / (this.curves[i].points.length - k - 1);
    for (let j = k + 1; j < this.curves[i].points.length; ++j) {
      this.curves[i].points[j].y = this.curves[i].points[j - 1].y - step;
    }
  };

  public setTooltipState = (state: TooltipProps | null) => {
    this.tooltipState = state;
  };
}

export { D3SVGModel };
