import type { FC } from "react";
import { Typography } from "antd";

import { EllipsisTitle } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";

import cn from "./resultTitle.module.less";

type ResultTitleProps = {
  children: string | undefined;
  date?: Date | null | undefined;
};

const ResultTitle: FC<ResultTitleProps> = ({ children, date }) => {
  if (children === undefined) {
    return null;
  }
  return (
    <div className={cn.box}>
      <EllipsisTitle>{children}</EllipsisTitle>
      {date && (
        <Typography.Text className={cn.date}>
          <Format dateFormat="accurate">{date}</Format>
        </Typography.Text>
      )}
    </div>
  );
};

export { ResultTitle };
