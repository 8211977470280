import type { ElementType, FC, PropsWithChildren, ReactNode } from "react";
import { Typography } from "antd";
import classNames from "classnames";

import cn from "./param.module.less";

type ParamProps = PropsWithChildren<{
  title?: ReactNode;
  tag?: ElementType;
  className?: string;
}>;

const Param: FC<ParamProps> = ({ title, className, children, tag: Tag = "li" }) => {
  return (
    <Tag className={classNames(className, cn.param)}>
      {title && <Typography.Title level={4}>{title}</Typography.Title>}
      <div className={cn.input}>{children}</div>
    </Tag>
  );
};

const Params: FC<PropsWithChildren> = ({ children }) => <ul className={cn.params}>{children}</ul>;

export { Param, Params };
