import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { OilStatePlan as OilStatePlanFeature } from "features/report/oilStatePlan/oilStatePlan";
import { useFact } from "models/project/fact/fact";

const OilStatePlan: FC = observer(() => {
  return (
    <Preloader hooks={[useFact]}>
      <PageFrame title="ГОСПЛАН-нефть">
        <OilStatePlanFeature />
      </PageFrame>
    </Preloader>
  );
});

export { OilStatePlan };
