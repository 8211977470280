const addViewportMargin = (x: number, y: number, dx: number, dy: number, margin: number) =>
  [x - dx * (margin / 2), y - dy * (margin / 2), dx * (margin + 1), dy * (margin + 1)] as [
    number,
    number,
    number,
    number
  ];

const viewport = (vertices: { x: number; y: number }[], margin: number = 0.1): [number, number, number, number] => {
  if (vertices.length === 0) {
    return [-1, -1, 2, 2];
  }
  let xMin, xMax, yMin, yMax;
  xMin = xMax = vertices[0].x;
  yMin = yMax = vertices[0].y;
  for (const { x, y } of vertices) {
    xMin = Math.min(xMin, x);
    xMax = Math.max(xMax, x);
    yMin = Math.min(yMin, y);
    yMax = Math.max(yMax, y);
  }
  return addViewportMargin(xMin, yMin, xMax - xMin, yMax - yMin, margin);
};

export { viewport };
