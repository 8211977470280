import { CalculationExecNormalResult, wrapBackendUSCResult } from "./calculate";
import { Output, USCModel } from "./types";

const calcMock = (): CalculationExecNormalResult | null => {
  let calculated: Output | null = null;
  let uscModelsRaw: USCModel[] | null = null;
  for (let i = 0; i < 10; ++i) {
    try {
      uscModelsRaw = require(`./calcMock-uscModels${Math.random() > 0.5 ? ".json" : ".json"}`);
      calculated = require(`./calcMock${i !== 0 ? i : ""}.json`);
    } catch {}
  }
  if (calculated === null) {
    return null;
  }

  const uscModels = new Map(uscModelsRaw?.map(({ uuid, schema_usc }) => [uuid, { schema_usc }]));
  const resultByUSC = new Map(
    calculated.map((uscOutput) => [uscOutput.usc_uuid, wrapBackendUSCResult(uscModels, uscOutput)])
  );

  const delay = 0;
  return {
    unchanged: [],
    changed: [],
    delay,
    calculated: resultByUSC,
  };
};

export { calcMock };
