import { Domain } from "@okopok/axes_context";
import type { NumberValue } from "d3";
import * as d3 from "d3";
import { action, computed, makeObservable, observable } from "mobx";

type NullableNumberValue = NumberValue | null;

type LineDataModelProps = {
  y: NullableNumberValue[];
  x: NumberValue[] | Domain;
  axisKey: string;
  key: string;
  color?: string;
  className?: string;
  title?: string;
  showPoints?: boolean;
  onChange?: (newArr: NumberValue[]) => void;
  domain?: Domain;
  limitMin?: number;
  limitMax?: number;
  shadow?: boolean;
  curve?: d3.CurveFactory;
  strokeDasharray?: string;
};
class LineDataModel {
  public y: NullableNumberValue[];
  public x: NumberValue[] | Domain;
  public axisKey: string;
  public key: string;
  public color?: string;
  public className?: string;
  public title?: string;
  public onChange?: (newArr: NumberValue[]) => void;
  public domain?: Domain;
  public limitMin: number;
  public limitMax: number;
  public shadow: boolean;
  public showPoints: boolean;
  public curve: d3.CurveFactory;
  public strokeDasharray?: string;
  constructor(lineInfo: LineDataModelProps) {
    makeObservable(this, {
      y: observable,
      showPoints: observable,
      color: observable,
      interpolate: action,
      onChangeShowPoints: action,
      setPoints: action,
      setMaxLimit: action,
      setMinLimit: action,
      firstX: computed,
      lastX: computed,
      setFirstX: action,
      setLastX: action,
    });
    this.showPoints = lineInfo.showPoints ?? false;
    this.y = lineInfo.y;
    this.x = lineInfo.x;
    this.axisKey = lineInfo.axisKey;
    this.key = lineInfo.key;
    this.color = lineInfo.color;
    this.className = lineInfo.className;
    this.title = lineInfo.title;
    this.onChange = lineInfo.onChange;
    this.domain = lineInfo.domain;
    this.limitMin = lineInfo.limitMin ?? Number.MIN_SAFE_INTEGER;
    this.limitMax = lineInfo.limitMax ?? Number.MAX_SAFE_INTEGER;
    this.shadow = lineInfo.shadow ?? false;
    this.curve = lineInfo.curve ?? d3.curveLinear;
    this.strokeDasharray = lineInfo.strokeDasharray;
  }

  public setPoints = (newArray: NullableNumberValue[]) => {
    if (this.onChange) {
      this.onChange(newArray.map((value) => this.checkValueInLimits(value)));
    } else {
      this.y = newArray.map((value) => this.checkValueInLimits(value));
    }
  };

  public setMinLimit = (min: number) => {
    this.limitMin = min;
  };

  public setMaxLimit = (max: number) => {
    this.limitMax = max;
  };

  public onChangeShowPoints = (state: boolean) => {
    this.showPoints = state;
  };

  get firstX() {
    if (Array.isArray(this.x)) {
      return this.x[0];
    } else {
      return this.x.min;
    }
  }

  get lastX() {
    if (Array.isArray(this.x)) {
      return this.x.at(-1)!;
    } else {
      return this.x.max;
    }
  }

  public setFirstX = (newValue: NumberValue) => {
    if (Array.isArray(this.x)) {
      this.x.splice(0, 0, newValue);
    } else {
      this.x.min = newValue;
    }
  };

  public setLastX = (newValue: NumberValue) => {
    if (Array.isArray(this.x)) {
      this.x.push(newValue);
    } else {
      this.x.max = newValue;
    }
  };

  public checkValueInLimits = (value: NullableNumberValue) => {
    // поддерживается по факту только для общности. с графика null приходить не должен
    if (value == null) {
      return this.limitMin / 2 + this.limitMax / 2;
    }
    if (+value < this.limitMin) {
      return this.limitMin;
    }
    if (+value > this.limitMax) {
      return this.limitMax;
    }
    return value;
  };

  public interpolate = (lineArray: NullableNumberValue[]) => {
    const result: NumberValue[] = new Array(this.y.length);
    const spaces: {
      left: number;
      right: number;
    }[] = [];
    let start = -1;
    let end = -1;

    this.y.forEach((value, indx) => {
      if (!Number.isFinite(value)) {
        if (start === -1) {
          start = indx;
        }
        end = indx;
      } else {
        result[indx] = value!;
        if (start !== -1) {
          spaces.push({
            left: start,
            right: end,
          });
          start = -1;
          end = -1;
        }
      }
    });

    spaces.forEach((space) => {
      if (space.left !== 0 && space.right !== result.length - 1) {
        const y1 = +result[space.left - 1];
        const y2 = +result[space.right + 1];
        const x1 = space.left - 1;
        const x2 = space.right + 1;
        new Array(space.right - space.left + 1)
          .fill(null)
          .map((_, index) => space.left + index)
          .forEach((indx) => (result[indx] = ((y2 - y1) * (indx - x1)) / (x2 - x1) + y1));
      }
    });
    this.y = result;
  };
}

export { LineDataModel, type NullableNumberValue };
