import { action, computed, makeObservable, observable } from "mobx";

import type { Forecast } from "models/project/fact/forecast/forecast";
import type { CostDump } from "services/back/costs";
import { zip } from "utils/itertools";

import { OptionedParam } from "./optionedParam";

class OptionedParams {
  public readonly data = observable.array<OptionedParam>();

  constructor(data: CostDump[], public readonly forecast: Forecast, private origin: OptionedParams | null = null) {
    makeObservable(this, {
      grouped: computed,
      isUpdated: computed,
      isCompleted: computed,
      setParams: action,
    });

    this.setParams(data);
  }

  public setParams(data: CostDump[]) {
    this.data.replace(data.map((v) => new OptionedParam(v, this.forecast)));
  }

  // то же самое что и [...new Set(this.data.map(({ group }) => group))] но с сохранением порядка
  get groups(): Array<string | null> {
    const used = new Set<string | null>();
    const iter = function* (this: OptionedParams) {
      for (const { group } of this.data) {
        if (!used.has(group)) {
          used.add(group);
          yield group;
        }
      }
    };
    return [...iter.call(this)];
  }

  get isCompleted() {
    for (const param of this.data) {
      if (!param.isCompleted) {
        return false;
      }
    }
    return true;
  }

  autofill() {
    return Promise.all(
      this.data.filter(({ isCompleted }) => !isCompleted).map(async (param) => param.prepareAutoCalc())
    );
  }

  get isUpdated() {
    if (this.origin === null) {
      return false;
    }
    for (const [a, b] of zip(this.data, this.origin.data)) {
      if (JSON.stringify(a.valuesDump) !== JSON.stringify(b.valuesDump)) {
        return true;
      }
    }
    return false;
  }

  clone(forecast?: Forecast): OptionedParams {
    return new OptionedParams(
      this.data.map((v) => v.dump),
      forecast ?? this.forecast,
      this
    );
  }

  get dump(): CostDump[] {
    return this.data.map((v) => v.dump);
  }

  get grouped(): Array<{ title: string; children: OptionedParam[] } | OptionedParam> {
    const result: Array<{ title: string; children: OptionedParam[] } | OptionedParam> = [];
    for (const param of this.data) {
      if (param.group === null) {
        result.push(param);
      } else {
        let groupItem = result.find(({ title }) => title === param.group) as
          | { title: string; children: OptionedParam[] }
          | undefined;
        if (groupItem === undefined) {
          groupItem = {
            title: param.group,
            children: [] as OptionedParam[],
          };
          result.push(groupItem);
        }
        groupItem.children.push(param);
      }
    }
    return result;
  }
}

export { OptionedParams };
