import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { Logs } from "features/project/logs/logs";
import { global } from "models/global";
import { useProject } from "models/project/project";

const LogsPage = () => (
  <Preloader hooks={[useProject, () => global.logger]}>
    <PageFrame title="Логирование">
      <Logs />
    </PageFrame>
  </Preloader>
);

export { LogsPage };
