import { ECYStore } from "models/project/fact/ecyStore/ecyStore";
import { useFact } from "models/project/fact/fact";

const useECYStore = (): ECYStore | undefined => {
  const state = useFact()!;

  console.assert(state !== undefined, "Попытка вызвать хук метрик до завершения загрузки модели состояния");

  return state.ecyStore;
};

export { useECYStore };
