import { FC, PropsWithChildren } from "react";

import { Preloader } from "features/preloader/preloader";
import { useForecast } from "models/project/fact/forecast/forecast";

const SettingsPreloader: FC<PropsWithChildren> = ({ children }) => {
  const { investCosts } = useForecast()!;
  return <Preloader hooks={[() => investCosts]}>{children}</Preloader>;
};

export { SettingsPreloader };
