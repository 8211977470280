import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react";

import { debugColumnsForSimpleTable } from "elements/debugColumn/debugColumn";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { VoronoiGrid } from "services/back/injectionPrediction/voronoi";

import { ProductionSlice } from "./productionSliceModel";

import cn from "./sliceTable.module.less";

const ProductionSliceTable: FC<{ voronoi: VoronoiGrid }> = observer(({ voronoi }) => {
  const forecast = useForecast()!;
  const store = useMemo(() => new ProductionSlice(forecast, voronoi), [forecast, voronoi]);

  const columns = useMemo<Column[]>(
    () => [
      ...debugColumnsForSimpleTable([
        {
          dataKey: "id",
          title: "ID",
          width: 80,
          hideFilter: true,
        },
      ]),
      {
        dataKey: "prodWellTitle",
        title: "Добывающая скважина",
        type: "string",
        isSticky: true,
        hideFilter: true,
        width: { min: 120, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "prodMineTitle",
        title: "Куст добывающей скважины",
        type: "number",
        hideFilter: true,
        width: { min: 150, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "prodProducingObjectTitle",
        title: "Объект разработки",
        type: "string",
        hideFilter: true,
        width: { min: 100, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "liquidRate",
        title: "Дебит жидкости (Qж м³/сут)",
        type: "number",
        hideFilter: true,
        width: { min: 135, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "oilRate",
        title: "Дебит нефти (Qн т/сут)",
        type: "number",
        hideFilter: true,
        width: { min: 110, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "injWellTitle",
        title: "Нагнетательная скважина",
        type: "string",
        hideFilter: true,
        width: { min: 130, max: 600, competitiveness: 2 },
      },
      {
        dataKey: "injMineTitle",
        title: "Куст нагнетательной скважины",
        type: "number",
        hideFilter: true,
        width: { min: 165, max: 600, competitiveness: 1 },
      },
      {
        dataKey: "oilCoeff",
        title: "Коэф-т закачки",
        type: "number",
        hideFilter: true,
        width: { min: 130, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "injCapacity",
        title: "Приемистость (Qз м³/сут)",
        type: "number",
        hideFilter: true,
        width: { min: 120, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "injCapacityWithOilCoeff",
        title: "Приемистость (с учетом коэф-та закачки), м³/сут",
        type: "number",
        hideFilter: true,
        width: { min: 200, max: 400, competitiveness: 1 },
      },
      {
        dataKey: "compensation",
        title: "Компенсация, %",
        type: "number",
        hideFilter: true,
        width: { min: 150, max: 400, competitiveness: 1 },
      },
    ],
    []
  );

  return (
    <SimpleTableContext
      columns={columns}
      data={store}
      exportFileName="Прогноз закачки. Срез по добывающим скважинам"
      tableOptions={{
        onRow: ({ indexPath }) => ({
          className: classNames({ [cn.tableRowPrimary]: indexPath.length === 1 }),
        }),
      }}
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
    >
      <Widget headerClassName={cn.tableHeader} className={cn.table} />
    </SimpleTableContext>
  );
});

export { ProductionSliceTable };
