import { useCallback, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Widget } from "@okopok/components/Table";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { debugColumnsForSimpleTable } from "elements/debugColumn/debugColumn";
import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { InputParams } from "models/project/fact/forecast/injPrediction/inputParams";
import { useProject } from "models/project/project";
import { getUserPermission } from "services/back/roles";

import { ReactComponent as AddManyPeriodsIcon } from "./icons/addManyPeriods.svg";
import { ReactComponent as AddPeriodIcon } from "./icons/addPeriod.svg";
import { ReactComponent as CompenstaionCoefIcon } from "./icons/compensationCoeff.svg";
import { ReactComponent as ExtendBaseInjectionIcon } from "./icons/extendBaseInjection.svg";
import { useMultiPeriodModal } from "./multiPeriodModal/multiPeriodModal";
import { usePeriodModal } from "./periodModal/periodModal";
import { useProducingObjectModal } from "./producingObjectForm/producingObjectModal";
import { ToggleIntersectedButton } from "./toggleInstersectedButton/toggleIntersectedButton";

import cn from "./inputParams.module.less";

const PERIOD_STATUS_NAME = {
  prod: "Добывающая",
  inj: "Нагнетательная",
  idle: "Бездействие",
} as const;

const useColumns = () => {
  return useMemo<Column[]>(() => {
    return [
      ...debugColumnsForSimpleTable([
        {
          dataKey: "wellId",
          title: "ID скважины",
          width: 80,
          hideFilter: true,
        },
        {
          dataKey: "gtmId",
          title: "ID ГТМ",
          width: 80,
          hideFilter: true,
        },
      ]),
      {
        dataKey: "wellTitle",
        title: "Скважина",
        type: "string",
        isSticky: true,
        hideFilter: true,
        width: { min: 100, max: 400, competitiveness: 2 },
      },
      {
        dataKey: "mineTitle",
        title: "Куст",
        type: "number",
        width: { min: 100, max: 200, competitiveness: 1 },
        hideFilter: true,
      },
      {
        dataKey: "eventTitle",
        title: "Мероприятие",
        type: "string",
        width: { min: 190, max: 270, competitiveness: 1 },
        hideFilter: true,
      },
      {
        dataKey: "licenseRegionTitle",
        title: "ЛУ",
        type: "string",
        width: { min: 110, max: 400, competitiveness: 2 },
        hideFilter: true,
      },
      {
        dataKey: "start",
        key: "start",
        title: "Дата начала",
        width: 130,
        type: "date",
        renderFormat: "month",
        hideFilter: true,
        editable: true,
        onCell: ({ value }) => ({
          className: classNames(cn.dateColumn, value?.isIntersected === true && value.periodStatus === "inj" && cn.invalidCell),
        }),
      },
      {
        dataKey: "end",
        key: "end",
        title: "Дата окончания",
        width: 130,
        type: "date",
        renderFormat: "month",
        hideFilter: true,
        editable: true,
        onCell: ({ value }) => ({
          className: classNames(cn.dateColumn, value?.isIntersected === true && value.periodStatus === "inj" && cn.invalidCell),
        }),
      },
      {
        dataKey: "periodStatus",
        key: "periodStatus",
        title: "Назначение",
        type: "string",
        width: 160,
        hideFilter: true,
      },
      {
        dataKey: "producingObjectId",
        key: "producingObjectId",
        title: "Объект разработки",
        type: "string",
        width: 180,
        hideFilter: true,
      },
      {
        title: "Залежь",
        dataKey: "stratumId",
        key: "stratumId",
        type: "string",
        width: 180,
        hideFilter: true,
      },
      {
        title: "Коэф-т компенсации",
        dataKey: "compensationCoefficient",
        key: "compensationCoefficient",
        type: "number",
        width: 160,
        editable: true,
        hideFilter: true,
      },
      {
        title: <div />,
        key: "sticky",
        type: "string",
        width: 38,
        hideFilter: true,
        onCell: () => ({ style: { position: "sticky", right: 0 } }),
      },
    ];
  }, []);
};

const InjectionPredictionInputParams = observer(() => {
  const forecast = useForecast()!;
  const projectContext = useProjectContext();
  const fact = useFact()!;

  const tree = projectContext.wellsTree;

  const columns = useColumns();
  const store = useMemo(() => new InputParams(forecast, tree), [forecast, tree]);

  const producingObjectModal = useProducingObjectModal();
  const onProducingObjectModal = useCallback(() => {
    return producingObjectModal().then((data) => store.propagateCompensationCoefficients(data));
  }, [producingObjectModal, store]);

  const periodModal = usePeriodModal(store.disabledDate, store.producingObjectIds);
  const onPeriodModal = useCallback(() => {
    return periodModal(null).then(({ well, producingObject, range }) => {
      const [from, to] = range;
      store.addPeriod(well, producingObject, from.date(1), to.date(1));
    });
  }, [periodModal, store]);

  const multiPeriodModal = useMultiPeriodModal();
  const onMultiPeriodModal = useCallback(() => {
    return multiPeriodModal().then((settings) => store.addManyPeriods(settings));
  }, [multiPeriodModal, store]);

  const project = useProject()!;
  const edit = getUserPermission(project, forecast)["tech"];
  const editTitle = (title?: string) => (edit ? title : "Для выполнения действия недостаточно полномочий вашей роли в проекте");

  return (
    <>
      <PageFrameTitlePortal
        model={store}
        submitting={store.isSaving}
        onSave={store.save}
        submitCustomTooltip={{ onNotValid: "Данные содержат пересекающиеся периоды. Сохранение невозможно" }}
        permissionSection="tech"
      >
        <ToggleIntersectedButton hasIntersected={!store.isValid} onlyIntersected={() => store.onlyIntersected()} reset={() => store.resetFilters()} />
        <ToolbarButton
          tooltip={{
            title: editTitle("Добавить период нагнетания"),
          }}
          icon={<AddPeriodIcon />}
          onClick={onPeriodModal}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{
            title: editTitle("Задать периоды нагнетания"),
          }}
          icon={<AddManyPeriodsIcon />}
          onClick={onMultiPeriodModal}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{
            title: editTitle("Продлить режим нагнетания БФ"),
          }}
          icon={<ExtendBaseInjectionIcon />}
          onClick={store.expandBaseInjection}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{
            title: editTitle("Задать коэффициент компенсации"),
          }}
          icon={<CompenstaionCoefIcon />}
          onClick={onProducingObjectModal}
          disabled={!edit.value}
        />
        <ToolbarButton
          tooltip={{ title: editTitle("Удалить периоды нагнетания") }}
          popconfirm={{
            title: "Удаление периодов нагнетания",
            description: "Удалить все периоды нагнетания для выбранных скважин?",
            onConfirm: store.removeSelectedWellsInjection,
            onCancel: () => "",
            okText: "Да",
            cancelText: "Нет",
          }}
          icon={<DeleteOutlined />}
          disabled={!edit.value}
          danger
        />
        <ToolbarButton
          tooltip={{
            title: editTitle(!store.isValid ? "Данные содержат пересекающиеся периоды. Запуск расчета невозможен" : undefined),
          }}
          type="primary"
          disabled={!store.isValid || forecast.compensationCoefficients.isLoading || !edit.value}
          loading={forecast.injectionResults.isCalculating}
          onClick={() => store.saveCompensation().then(() => forecast.injectionResults.calculate())}
        >
          Запустить расчет
        </ToolbarButton>
      </PageFrameTitlePortal>
      <div className={cn.root}>
        <SimpleTableContext
          columns={columns}
          data={store}
          exportFileName="Прогноз закачки. Входные параметры"
          tableOptions={{
            onRow: ({ value, indexPath }) => ({
              className: classNames(
                {
                  [cn.tableRowPrimary]: indexPath.length === 1,
                  [cn.invalidRow]: value?.isIntersected,
                },
                value?.periodStatus && cn[`${value.periodStatus}Row`]
              ),
            }),
          }}
          theme={{
            headerHeight: 39,
            rowHeight: 33,
            borderColor: "#f0f0f0",
          }}
          customRenders={{
            sticky: (value, row) => {
              if (row.expand !== undefined || row.value.periodStatus !== "inj") {
                return null;
              }
              return <DeleteButton onClick={value?.remove} />;
            },
            periodStatus: (value: "prod" | "inj" | "idle", row) => {
              if (!value) return null;

              return PERIOD_STATUS_NAME[value];
            },
            producingObjectId: (value, row) => {
              return row.value?.periodStatus !== "idle" && row.value?.producingObjectsResolver !== undefined ? (
                <SelectStorable
                  values={[value ?? null, undefined]}
                  setValues={(id) => row.update?.("producingObjectId", id)}
                  store={row.value.producingObjectsResolver}
                  disabled={row.value?.periodStatus !== "inj"}
                />
              ) : null;
            },
            stratumId: (value, row) => {
              if (row.value?.periodStatus === "idle" || value === null || value === undefined) {
                return null;
              }
              const stratum = fact.stratums.at(value);
              return <Format>{stratum?.title}</Format>;
            },
          }}
        >
          <Widget headerClassName={cn.tableHeader} className={cn.table} />
        </SimpleTableContext>
      </div>
    </>
  );
});

export { InjectionPredictionInputParams };
