import { useMatches } from "react-router-dom";

import { useForecast } from "models/project/fact/forecast/forecast";
import { TableType } from "services/back/ranking";

const useTab = (): "drilling" | "reconstruction" => {
  let tab = useMatches().at(-1)!.pathname.split("/")[4];
  if (tab !== "drilling" && tab !== "reconstruction") {
    return "drilling";
  }
  return tab;
};

const useRankingStore = (type: TableType) => {
  const forecast = useForecast()!;
  return useTab() === "drilling" ? forecast.rankingDrilling(type) : forecast.rankingReconstruction(type);
};

export { useRankingStore, useTab };
