import { FC } from "react";
import { Button, DatePicker, Divider, InputProps } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

import { Icon } from "elements/icon/icon";
import { ValidationInput } from "elements/inputs/validationInput/validationInput";
import { CalculateStore } from "models/project/fact/infrastructure/calculateStore";

import "dayjs/locale/ru";

import { ReactComponent as ResetIcon } from "./reset.svg";

import cn from "./infrastructureResultsTable.module.less";

const { RangePicker } = DatePicker;

type InputCustomType = {
  title?: string;
  value?: number | null;
  max?: number;
  min?: number;
  onUpdate: (value: number | null) => void;
} & Omit<InputProps, "value" | "min" | "max">;

const InputCustom: FC<InputCustomType> = ({ title, value, max = 100000, min = 0, onUpdate, ...props }) => (
  <div className={cn.inputCustomWrapper}>
    {!!title && <div>{title}</div>}
    <ValidationInput {...props} value={value} onSave={onUpdate} min={min} max={max} forceValueUpdate />
  </div>
);

const SubFrameTitle: FC<{ store: CalculateStore }> = observer(({ store }) => {
  if (store.hydraulicDataCollection === null) {
    return <></>;
  }
  return (
    <div className={cn.subFrameTitle}>
      <RangePicker
        className={cn.date}
        locale={locale}
        placeholder={["Начало", "Конец"]}
        picker="month"
        value={store.userFilterParams?.date}
        onChange={([start, end]) => store.setUserFilterParams({ date: [dayjs(start), dayjs(end)] })}
      />
      <Button icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />} onClick={store.resetUserFiterDate} />
      <Divider type="vertical" />
      <InputCustom
        title="Макс. градиент давления"
        className={cn.inputMax}
        placeholder="500 МПа/км"
        type="number"
        value={store.userFilterParams?.pressureGradient ?? undefined}
        onUpdate={(value) => store.setUserFilterParams({ pressureGradient: value })}
      />
      <InputCustom
        title="Макс. скорость потока"
        className={cn.inputMax}
        placeholder="1 м/сек"
        value={store.userFilterParams?.velocity ?? undefined}
        onUpdate={(value) => store.setUserFilterParams({ velocity: value })}
      />
      <Button icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />} onClick={store.resetUserFiterInputs} />
    </div>
  );
});

export { SubFrameTitle };
