import { useMemo } from "react";
import classNames from "classnames";

import { Column } from "features/tableDebug/simpleTable";

import { useInfrastructure } from "../useInfrastructure";

import { StationsModel } from "./facilitiesManager/stations";

import cn from "./infrastructureFacilities.module.less";

const stationsTypeOptions = [
  { label: "НС", value: "НС" },
  { label: "УКПГ", value: "УКПГ" },
  { label: "УПН", value: "УПН" },
  { label: "УПСВ", value: "УПСВ" },
];

const columns: Column[] = [
  {
    title: "Название вида",
    width: { min: 200, max: 300, competitiveness: 1 },
    isSticky: true,
    dataKey: "title",
    type: "string",
    editable: true,
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Тип",
    width: { min: 100, max: 120, competitiveness: 1 },
    dataKey: "stationType",
    type: "select",
    options: stationsTypeOptions,
    editable: true,
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Мощность, тыс м3/сут",
    width: { min: 100, max: 120, competitiveness: 1 },
    dataKey: "power",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Перепад давления, МПа",
    width: { min: 120, max: 150, competitiveness: 1 },
    dataKey: "deltaPressure",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: (station) => ({
      className: classNames(cn.tableCellAlignRight, station.expand?.status === undefined && !station.value?.deltaPressure && cn.empty),
    }),
  },
  {
    title: "Итого (Строительство), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "total",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "ПИР (Строительство), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "dsw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Оборудование (Строительство), тыс руб / (тыс м3/сут)",
    width: { min: 270, max: 290, competitiveness: 1 },
    dataKey: "equipment",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "СМР (Строительство), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "ciw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Прочее (Строительство), тыс руб / (тыс м3/сут)",
    width: { min: 220, max: 240, competitiveness: 1 },
    dataKey: "other",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Итого (Реконструкция), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "totalReconstruction",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "ПИР (Реконструкция), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "reconstructionDsw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Оборудование (Реконструкция), тыс руб / (тыс м3/сут)",
    width: { min: 270, max: 290, competitiveness: 1 },
    dataKey: "reconstructionEquipment",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "СМР (Реконструкция), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "reconstructionCiw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Прочее (Реконструкция), тыс руб / (тыс м3/сут)",
    width: { min: 200, max: 220, competitiveness: 1 },
    dataKey: "reconstructionOther",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Период реконструкции, года",
    width: { min: 230, max: 250, competitiveness: 1 },
    dataKey: "period",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
];

const useFacilitiesStationsTableModel = () => {
  const infrastructure = useInfrastructure();
  const store = useMemo(() => new StationsModel(infrastructure), [infrastructure]);
  return { columns, store };
};

export { useFacilitiesStationsTableModel };
