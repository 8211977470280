import { LineDataModel } from "@okopok/axes_context";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import dayjs from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";
import { colorCarousel } from "services/colorCarousel";

import { DEFAULT_CHART_SETTINGS } from "./settingsChart/settingsChart";

class ExpensesChartModel {
  selectedParams: CheckboxValueType[] = DEFAULT_CHART_SETTINGS;
  constructor(public summaryModel: SummaryModel) {
    makeObservable(this, {
      selectedParams: observable,
      tooltipManager: computed,
      lines: computed,
      capexStickerValue: computed,
      setSelectedParams: action,
    });
  }

  public setSelectedParams = (newParams: CheckboxValueType[]) => {
    this.selectedParams = newParams;
  };

  get tooltipManager() {
    return new TooltipDataManager(this.lines);
  }

  get capexStickerValue() {
    return this.summaryModel.CAPEX.data
      .filter(
        (value) =>
          value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
          value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
      )
      .reduce((prev, curr) => prev + curr.y, 0);
  }

  get opexStickerValue() {
    return this.summaryModel.OPEX.data
      .filter(
        (value) =>
          value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
          value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
      )
      .reduce((prev, curr) => prev + curr.y, 0);
  }

  get lines(): LineDataModel[] {
    return this.selectedParams
      .map((param) => {
        if (param === "1") {
          return new LineDataModel({
            y: this.summaryModel.CAPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.CAPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "capex",
            color: colorCarousel(6),
            title: `CAPEX, млн ₽`,
          });
        }
        if (param === "2") {
          return new LineDataModel({
            y: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "opex",
            color: colorCarousel(7),
            title: `OPEX, млн ₽`,
          });
        }
        if (param === "3") {
          return new LineDataModel({
            y: this.summaryModel
              .getDataLines("Налоги")
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "3",
            color: colorCarousel(8),
            title: `Налоги, млн ₽`,
          });
        }
        if (param === "4") {
          return new LineDataModel({
            y: this.summaryModel
              .getDataLines("НДПИ нефть")
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "4",
            color: colorCarousel(9),
            title: `НДПИ нефть, млн ₽`,
          });
        }
        if (param === "5") {
          return new LineDataModel({
            y: this.summaryModel
              .getDataLines("Налог на имущество")
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "5",
            color: colorCarousel(10),
            title: `Налог на имущество, млн ₽`,
          });
        }
        if (param === "6") {
          return new LineDataModel({
            y: this.summaryModel
              .getDataLines("Налог на прибыль")
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "6",
            color: colorCarousel(11),
            title: `Налог на прибыль, млн ₽`,
          });
        }
        if (param === "7") {
          return new LineDataModel({
            y: this.summaryModel
              .getDataLines("НДД")
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "7",
            color: colorCarousel(12),
            title: `НДД, млн ₽`,
          });
        }
        if (param === "8") {
          return new LineDataModel({
            y: this.summaryModel
              .getDataLines("Выручка всего (без налогов и транспорта)")
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.y),
            x: this.summaryModel.OPEX.data
              .filter(
                (value) =>
                  value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
                  value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
              )
              .map((value) => value.x),
            axisKey: "other",
            key: "8",
            color: colorCarousel(12),
            title: `Выручка всего (без налогов и транспорта), млн ₽`,
          });
        }
        return null;
      })
      .filter((item) => item !== null)
      .map((item) => item!);
  }
}

export { ExpensesChartModel };
