import { FC, ReactNode } from "react";
import classNames from "classnames";

import cn from "../inspectorMenu.module.less";

const Parameter: FC<{ icon?: ReactNode; title?: string; children: ReactNode }> = ({ icon, title, children }) => (
  <div className={classNames(cn.param, title && cn.tooltipParam)}>
    <div>{title ? title : icon}</div>
    <div>{children}</div>
  </div>
);

export { Parameter };
