import type { Forecast } from "models/project/fact/forecast/forecast";

import { CALCULATE_PREDEFINE } from "./calculatePredefine";
import { linkParent, type ParentedMetric, sophisticatedSearch } from "./utils";

const CODE_TITLES = {
  "Прочие расходы": {
    "Удельные расходы по добыче нефти и газа, сбору газа попутного": "Удельные расходы Прочие расходы",
    "Удельные расходы по искусственному воздействию на пласт": "Удельные расходы Прочие расходы (возд.)",
    "Удельные расходы по транспортировке нефтесодержащей жидкости и газа":
      "Удельные расходы Прочие расходы (транспорт.)",
    "Расходы по технологической подготовке нефти": "Удельные расходы Прочие расходы (подготов.)",
  },
  Энергия: {
    "Удельные расходы по искусственному воздействию на пласт": "Удельные расходы Энергия",
    "Удельные расходы по транспортировке нефтесодержащей жидкости и газа": "Удельные расходы Энергия (транспорт.)",
    "Расходы по технологической подготовке нефти": "Удельные расходы Энергия (подготов.)",
  },
};

function constantsSchema(forecast: Forecast) {
  const predef = CALCULATE_PREDEFINE.constants.schema;
  const schema: any[] = forecast.fact.stratums.items!.map(({ title, depletion }, id) => ({
    code_title: `Выработанность по ${title} на 2012 год`,
    id: predef.at(-1)!.id + id,
    parent_id: 0,
    title: `Выработанность по ${title} на 2012 год`,
    unit: {
      measure: 2,
      quantity: 3,
    },
    values: [depletion],
    visible: {},
  }));
  const regionKey = forecast.licenseRegions.ids[0];

  const investCosts = forecast.investCosts.scalar!.flatten(false);
  const operatingCosts = forecast.operatingCosts.scalar!.flatten(false);
  const operatingRevenue = forecast.operatingRevenue.get(regionKey).scalar!.flatten(false);
  const taxWealth = forecast.wealthTax.get(regionKey).scalar!.flatten(false);
  const taxNdd = forecast.fact.nddTax.scalar!.flatten(false);
  const depletion = forecast.fact.stratums.depletion;
  const extractionCoefficient = [
    {
      ...operatingRevenue.find(({ title }) => title === "По направлению"),
      title: "Коэффициент расчета товарной нефти",
    },
    {
      ...operatingRevenue.find(({ title }) => title === "Нефтяной газ"),
      title: "Коэффициент расчета товарного нефтяного газа",
    },
    {
      ...taxNdd.find(({ title }) => title === "Индекс 1"),
      title: "Индекс для переносов 1",
    },
    {
      ...taxNdd.find(({ title }) => title === "Индекс 2"),
      title: "Индекс для переносов 2",
    },
    {
      title: "Год начала промышленной добычи",
      values: [forecast.fact.licenseRegions.at(regionKey)?.ndd?.transitionYear ?? 0],
    },
  ];
  const severanceTax: any[] = forecast.fact.severanceTax.scalar?.flatten(false) ?? [];

  const constantsPack: ParentedMetric[] = [
    investCosts,
    operatingCosts,
    operatingRevenue,
    taxWealth,
    taxNdd,
    depletion,
    extractionCoefficient,
    severanceTax,
  ]
    .map(linkParent)
    .flat();

  const used: Set<ParentedMetric> = new Set();

  for (const metric of predef) {
    if (metric.unit.quantity === 0) {
      schema.push(metric);
    } else {
      const item = constantsPack.find(sophisticatedSearch(CODE_TITLES, metric));
      if (item === undefined) {
        console.error(`Constant ${metric.title} (${metric.code_title}) not found`);
      } else {
        console.assert(
          !used.has(item),
          `Constant taken multiple times ("${item.title}": ${item.values?.length})`,
          item
        );
        used.add(item);
        schema.push({ ...metric, values: item.values! });
      }
    }
  }

  return { schema };
}

export { constantsSchema };
