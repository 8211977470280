import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class OperatingRevenue extends Params {
  constructor(years: Range, services: ParamsServices) {
    super("Выручка", years, services);
  }
}

export { OperatingRevenue };
