import { makeAutoObservable } from "mobx";

import { global } from "models/global";

class TaxDiscount {
  constructor(public id: number) {
    makeAutoObservable(this);
  }

  public setId(newId: number) {
    this.id = newId;
  }

  get title(): string | undefined {
    return this.discount;
  }

  get discount() {
    if (global.taxDiscounts.isLoading) {
      return undefined;
    }
    console.assert(global.taxDiscounts.has(this.id) === true, "Попытка получения объекта скидки по не верному айди");
    return global.taxDiscounts.at(this.id)!.title;
  }
}

export { TaxDiscount };
