import type { LicenseRegionBackend, NDDSystem, TaxSystem } from "services/back/licenseRegions";

import type { LicenseRegions } from "./licenseRegions";

class LicenseRegion {
  constructor(private data: LicenseRegionBackend, private readonly container: LicenseRegions | null) {}

  static fromRaw(container: LicenseRegions) {
    return (data: LicenseRegionBackend): LicenseRegion => new LicenseRegion(data, container);
  }

  public get id(): number {
    return this.data.id;
  }

  public get title(): string {
    return this.data.title;
  }

  public get ndd(): NDDSystem | null {
    return this.data.taxSystem.nddSystem;
  }

  get isNdd() {
    return this.ndd !== null;
  }

  async update(data: TaxSystem): Promise<void> {
    console.assert(this.container !== null, "Обновление не редактируемых ЛУ (глобальная область видимости)");
    if (this.container === null) {
      return;
    }
    this.data = await this.container.update(this, { ...this.data, taxSystem: data });
  }

  get dump(): LicenseRegionBackend {
    return this.data;
  }
}

export { LicenseRegion };
