import type { DispatchWithoutAction, FC } from "react";
import { useHref, useLinkClickHandler } from "react-router-dom";
import type { ButtonProps } from "antd";
import { Button } from "antd";

type LinkButtonProps = Omit<ButtonProps, "onClick" | "href" | "target"> & { to: Parameters<typeof useLinkClickHandler>[0] } & Parameters<
    typeof useLinkClickHandler
  >[1];

const LinkButton: FC<LinkButtonProps> = ({ to, target, replace, state, preventScrollReset, relative, ...props }) => {
  const href = useHref(to);
  const onNavigate = useLinkClickHandler(to, {
    target,
    replace,
    state,
    preventScrollReset,
    relative,
  });

  return <Button {...props} onClick={onNavigate as DispatchWithoutAction} href={href} />;
};

export { LinkButton };
export type { LinkButtonProps };
