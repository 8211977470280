import type { ColumnType } from "antd/es/table";
import { computed, makeObservable, override } from "mobx";

type Column<T = any> = ColumnType<T>;

class Table<T = any> {
  constructor(readonly title: string) {
    makeObservable(this, {
      tableItems: computed,
      isUpdated: computed,
      isLoading: computed,
      isCompleted: computed,
      isValid: computed,
    });
  }

  static inherited = {
    tableItems: override,
    isUpdated: override,
    isLoading: override,
    isCompleted: override,
    isValid: override,
  };

  export(): (string | null)[][] {
    console.warn("Default export");
    return [];
  }

  get isUpdated(): boolean {
    console.warn("Default isUpdated");
    return false;
  }

  get isLoading(): boolean {
    console.warn("Default isLoading");
    return false;
  }

  get isCompleted(): boolean {
    console.warn("Default isCompleted");
    return true;
  }

  get tableItems(): T[] {
    console.warn("Default tableItems");
    return [];
  }

  get isValid(): boolean {
    return true;
  }
}

export { type Column, Table };
