import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayJS from "dayjs";
import { makeAutoObservable, reaction } from "mobx";

import { User } from "models/user";
import { backendStorage, BackendStorageMock } from "services/back/backendStorage";

import { Project } from "./project/project";
import { Projects } from "./project/projects";
import { global } from "./global";

type LogNote = {
  date: string;
  user: User;
  title: string;
  scenarioId?: number | null;
  projectName?: string;
  scenarioTitle?: string | null;
};

const ACTION_MAP: Record<string, string> = {
  "post:productions/forecast": "Сохранение прогноза",
  "post:gtms": "Создание ГТМ",
  "delete:gtms": "Удаление ГТМ",
  "post:wells/": "Создание скважины",
  "get:ranking_invest:storage": "Получение ранжирования",
  "post:ranking_invest:storage": "Сохранение критерия ранжирования",
  "post:ranking_settings:storage": "Сохранение настроек ранжирования",
  "post:ranking_sequential:storage": "Сохранение ручного ранжирования",
  "post:scenarios": "Создание сценария",
  "post:scenarios/copy": "Копирования сценария",
  "post:pipe_boundary_condition": "Сохранение инфраструктуры",
  "post:calculation/infrastructure": "Расчет инфраструктуры",
  "demo/schemas/new_calculation?scenario_id=": "Экономический расчет",
};

const trNote = (title: string) => {
  if (title in ACTION_MAP) {
    return ACTION_MAP[title];
  }
  for (const [key, value] of Object.entries(ACTION_MAP)) {
    if (title.startsWith(key)) {
      return `${value} ${title.slice(key.length)}`;
    }
  }
  return title;
};

const createDayjs = (date: string) => {
  if (dayJS(date).day()) {
    return dayJS(date);
  }
  return dayJS(date, "DD/MM/YYYY hh:mm");
};

const sortNodes = (node1: LogNode, node2: LogNode) => {
  const date1 = createDayjs(node1.logNote.date);
  const date2 = createDayjs(node2.logNote.date);
  if (date1.isSame(date2)) {
    return 0;
  }
  return date1.isBefore(date2, "s") ? -1 : 1;
};

class Logger {
  private logger?: BackendStorageMock<void, LogNote[]>;
  private logs: LogNote[] = [];
  private globalLogs: Record<number, LogNote[]> = {};
  private project?: Project;
  private scenarioId?: number;
  public isLoadingLog = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.project,
      async (newProject) => {
        this.logger = new BackendStorageMock<void, LogNote[]>("log", newProject?.id!, undefined, false);
        if (this.globalLogs[newProject?.id!]) {
          this.logs = this.globalLogs[newProject?.id!];
        } else {
          this.logs = (await this.logger.getItem()) ?? [];
          this.isLoadingLog = false;
        }
      }
    );
  }

  addNote = async (key: string) => {
    if (this.logger && global.user) {
      const logs = (await this.logger.getItem()) ?? [];
      const newLogs = await this.logger.setItem([
        ...logs,
        { date: new Date().toString(), title: key, user: global.user, scenarioId: this.scenarioId ?? null },
      ]);
      this.logs = newLogs ?? [];
    } else {
      console.assert("Логирование может быть произведено только в рамках проекта");
    }
  };

  setProject = (newProject: Project) => {
    this.project = newProject;
    if (!this.globalLogs[newProject?.id]) {
      this.isLoadingLog = true;
    }
  };

  setScenarioId = (newScenarioId: number) => {
    this.scenarioId = newScenarioId;
  };

  projectLog = (id: number): LogNote[] => {
    return this.globalLogs[id];
  };

  loadAllLogs = (projectIds: number[]) => {
    this.isLoadingLog = true;
    Promise.all(
      projectIds.map((pId) =>
        backendStorage.getItem<LogNote[]>("log", pId, undefined).then((data) => (this.globalLogs[pId] = data ?? []))
      )
    ).then(() => (this.isLoadingLog = false));
  };

  get currentProjectLog(): LogNote[] {
    return this.logs;
  }

  get isLoading(): boolean {
    return this.isLoadingLog;
  }

  get logsNumber(): number {
    return Object.keys(this.globalLogs).length;
  }
}

class LoggerStore extends TableNode<LogNote | { user: string }, LogNode> {
  constructor(private logger: Logger, projects: Projects, pId?: number) {
    super();
    if (pId) {
      const project = projects.at(pId)!;
      this.childrenStore = new ChildrenStoreArray(
        this,
        logger.currentProjectLog.map((log) => new LogNode(this, log, project))
      );
    } else {
      const logNodes: LogNode[] = [];

      for (const project of Array.from(projects.values ?? [])) {
        const logs = logger.projectLog(project.id) ?? [];
        logNodes.push(...logs.map((log) => new LogNode(this, log, project)));
      }
      logNodes.sort(sortNodes);

      this.childrenStore = new ChildrenStoreArray(this, logNodes);
    }
  }

  public get getLogs() {
    return this.logger.currentProjectLog;
  }
}

class LogNode extends TableNode<LogNote | { user: string }> {
  public asDRow(): LogNote | { user: string } {
    return {
      title: trNote(this.logNote.title),
      user: this.logNote.user.name,
      date: this.logNote.date,
      projectName: this.project.title,
      scenarioTitle: this.project?.scenarios.find(({ id }) => id === this.logNote.scenarioId)?.title,
    };
  }
  constructor(parentNode: LoggerStore, public readonly logNote: LogNote, private project: Project) {
    super(parentNode);
  }
}

export { Logger, LoggerStore };
export type { LogNote };
