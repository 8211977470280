import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { useCreateForecastModal } from "features/forecast/createForecastModal";
import { useFact } from "models/project/fact/fact";
import { useProject } from "models/project/project";
import { conditionallyArr } from "utils/conditionally";

import { ScenarioInfo } from "./scenarioInfo";
import { ScenariosList } from "./scenariosList";
import { ScenariosListModel } from "./scenariosListModel";

import cn from "./scenarios.module.less";

const Scenarios: FC = observer(() => {
  const fact = useFact()!;
  const project = useProject();
  const params = useMainRouterParams();
  const navigate = useNavigate();
  const createForecastModal = useCreateForecastModal();

  const forecasts = fact.forecasts;

  const onNavigate: (key: string, postfix: string) => void = useCallback(
    (key, postfix) => {
      const path = ["", params.project, ...conditionallyArr(key !== "fact", key), postfix].join("/");
      navigate(path);
    },
    [navigate, params.project]
  );

  const onAdd = async () => {
    const forecast = await createForecastModal();
    if (forecast!) {
      project?.fact.forecasts.add(forecast);
      navigate(["", params.project, forecast.id, "scenario"].join("/"));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const model = useMemo(() => (forecasts && new ScenariosListModel(forecasts, onNavigate)) ?? undefined, [forecasts, forecasts.length, onNavigate]);

  const modelRows = model.childrenStore?.children && [...model.childrenStore?.children];
  const currentScenario = modelRows?.find((child) => child.row.id === params.scenario);
  const firstScenario = modelRows?.find((child) => child.index === 0);

  useEffect(() => {
    if (model && currentScenario) {
      model.currentIndex = currentScenario.index!;
      model.selectScenario(currentScenario.row.id);
    } else if (firstScenario) {
      model.selectScenario(firstScenario.row.id);
    }
  }, [model, currentScenario, firstScenario]);

  if (forecasts.length === 0) {
    return (
      <FullScreenEmpty>
        <div className={cn.emptyContent}>
          По этому проекту нет сценариев
          <Button onClick={onAdd} type="primary">
            Создать сценарий
          </Button>
        </div>
      </FullScreenEmpty>
    );
  }

  return (
    <div className={cn.container}>
      <ScenariosList data={model} onNavigate={onNavigate} />
      <ScenarioInfo scenario={model.selectedScenario} onNavigate={onNavigate} />
    </div>
  );
});

export { Scenarios };
