import { useMemo } from "react";

import { useProject } from "models/project/project";

import { Deposits } from "./deposits/deposits";
import { LicenseRegion } from "./licenseRegion";
import { MainProjectParams } from "./mainProjectParams";
import { VersionFem } from "./versionFem";

const DataProject = () => {
  const project = useProject()!;

  const licenseRegions = useMemo(() => project?.licenseRegions.titles, [project?.licenseRegions]);
  const stratums = useMemo(() => project?.stratums.items?.map(({ title }) => title), [project?.stratums]);

  return (
    <>
      <MainProjectParams project={project} />
      <VersionFem project={project} />
      <LicenseRegion licenseRegions={licenseRegions} />
      <Deposits stratums={stratums} />
    </>
  );
};

export { DataProject };
