import { FC, memo } from "react";
import { useDrag } from "@okopok/axes_context";
import classNames from "classnames";

import cn from "./point.module.less";

type PointProps = {
  x: number;
  y: number;
  index: number;
  // для оповещения тултипа о начале перетаскивания пропс заложен но реально тултип в библиотеку пока не перенесён, параметр не используется
  curveKey: string;
  onChange?: (value: number, index: number) => void;
  onClick?: React.MouseEventHandler<SVGGElement>;
  className?: string;
  color?: string;
};

const Point: FC<PointProps> = memo(({ x, y, onChange, index, onClick, className, color }) => {
  const onDrag = (_diffX: number, _diffY: number, _dx: number, dy: number) => {
    onChange?.(dy + y, index);
  };
  const [drag] = useDrag(onDrag);
  return (
    <g style={{ color }} onClick={onClick} onMouseDownCapture={onChange ? drag : undefined}>
      <circle cx={x} cy={y} r={5} className={classNames(cn.point, className)} />
      <circle cx={x} cy={y} r={8} className={classNames(cn.transparentPoint, onChange && cn.draggable)} />
    </g>
  );
});

export { Point };
