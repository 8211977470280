import { FC, memo } from "react";

import { CalcEffectTable } from "../calcEffectTable/calcEffectTable";
import { DecreaseCoeffsTable } from "../decreaseCoeffsTable/decreaseCoeffsTable";
import { DecreaseEffectChartExample } from "../decreaseEffectChart/decreaseEffectChartExample";
import { MiningValueChartExample } from "../miningValueChart/miningValueChartExample";
import { WellInterventionsAmountChartFeature } from "../wellInterventionsAmountChart/wellInterventionsAmountChartFeature";

import cn from "./main.module.less";

type MainProps = {
  currentTab: string;
};

const Main: FC<MainProps> = memo(({ currentTab }) => {
  if (currentTab === "calcEffect") {
    return (
      <div className={cn.content}>
        <CalcEffectTable />
      </div>
    );
  }
  if (currentTab === "decreaseCoeffs") {
    return (
      <div className={cn.content}>
        <DecreaseCoeffsTable />
      </div>
    );
  }
  if (currentTab === "miningValue") {
    return (
      <div className={cn.content}>
        <MiningValueChartExample />
      </div>
    );
  }
  if (currentTab === "gtmAmount") {
    return (
      <div className={cn.content}>
        <WellInterventionsAmountChartFeature />
      </div>
    );
  }

  if (currentTab === "decreaseEffect") {
    return (
      <div className={cn.content}>
        <DecreaseEffectChartExample />
      </div>
    );
  }
  return <>{currentTab}</>;
});

export { Main };
