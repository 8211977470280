import { createContext, useContext } from "react";
import { makeAutoObservable } from "mobx";

import { User } from "models/user";

import { Projects } from "./project/projects";

class Session {
  public projects: Projects = new Projects();

  constructor(public user: User) {
    makeAutoObservable(this);
  }
}

const SessionContext = createContext<Session | null>(null);

const useSession = (): Session => {
  const session = useContext(SessionContext);
  console.assert(session != null, "запрошена сессия вне контекста AuthorizedRouter");
  return session!;
};

const SessionContextProvider = SessionContext.Provider;

export { Session, SessionContextProvider, useSession };
