import { useCallback } from "react";
import { Radio, type RadioChangeEvent } from "antd";
import { observer } from "mobx-react";

import { useRankingStore } from "./useRankingStore";

const RADIO = {
  pi: "PI",
  npv: "NPV",
  irr: "IRR",
  pvCapexProd: "PV CAPEX на добычу",
  pbp: "PBP",
  dpbp: "dPBP",
};

const OrderSelector = observer(() => {
  const model = useRankingStore("ranking");

  const changeHolder = useCallback(
    (v: RadioChangeEvent) => {
      model.setOrder(v.target.value);
    },
    [model]
  );

  return (
    <Radio.Group onChange={changeHolder} value={model.order}>
      {Object.entries(RADIO).map(([value, title]) => (
        <Radio value={value} key={value}>
          {title}
        </Radio>
      ))}
    </Radio.Group>
  );
});

export { OrderSelector };
