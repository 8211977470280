import React from "react";

import { Icon } from "elements/icon/icon";

import { PadIcon } from "../icons/padIcon";

import cn from "./legendMap.module.less";

const LegendMap = () => {
  return (
    <div className={cn.legendMap}>
      <div className={cn.legendFlex}>
        <Icon className={cn.icon} content={<PadIcon fond="Base" mode="injection" />} viewBox="-12 -12 28 28" height="14px" width="14px" />
        Нагнетательная скважина (Базовый фонд)
      </div>
      <div className={cn.legendFlex}>
        <Icon className={cn.icon} content={<PadIcon fond="New" mode="injection" />} viewBox="-12 -12 28 28" height="14px" width="14px" />
        Нагнетательная скважина (Новый фонд)
      </div>
      <div className={cn.legendFlex}>
        <Icon viewBox="-12 -12 28 28" height="14px" width="14px" content={<PadIcon fond="Base" mode="mining" />} />
        Добывающая скважина (Базовый фонд)
      </div>
      <div className={cn.legendFlex}>
        <Icon viewBox="-12 -12 28 28" height="14px" width="14px" content={<PadIcon fond="New" mode="mining" />} />
        Добывающая скважина (Новый фонд)
      </div>
    </div>
  );
};

export { LegendMap };
