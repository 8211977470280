import { makeAutoObservable, reaction } from "mobx";

import { CalcEffectTableNode, TableRow } from "../calcEffectTableModel";
import { CalcModel } from "../calcModel";

class CalcModelInjectionWells {
  public wellsAmount: TableRow;
  public repairAmount: TableRow;
  public gtmAmount: TableRow;
  public additionalMining: TableRow;
  public transferringMining: TableRow;
  constructor(public node: CalcEffectTableNode, public calcModel: CalcModel) {
    makeAutoObservable(this);
    this.wellsAmount = Array.from(node.children!).find((child) => child.rowInfo.title === "Количество скважин")!;
    this.repairAmount = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Количество ремонтов нагнетательных скважин"
    )!;
    this.gtmAmount = Array.from(node.children!).find((child) => child.rowInfo.title === "Количество ФХ МУН")!;
    this.additionalMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Доп. добыча за год проведения ФХ МУН"
    )!;
    this.transferringMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Переходящая доп. добыча от ФХ МУН"
    )!;

    reaction(
      () => Object.values(calcModel.repairModel.gtmAmount!.data),
      () => {
        Object.keys(this.repairAmount.data).forEach((year) => {
          if (calcModel.repairModel.gtmAmount?.data[+year] !== null) {
            this.repairAmount.mutationsManager?.updateWrapper(+year, calcModel.repairModel.gtmAmount?.data[+year] ?? 0);
          }
        });
      }
    );

    reaction(
      () => Object.values(calcModel.physicModel.gtmAmount.data),
      () => {
        Object.keys(this.gtmAmount.data).forEach((year) => {
          if (calcModel.physicModel.gtmAmount?.data[+year] !== null) {
            this.gtmAmount.mutationsManager?.updateWrapper(+year, calcModel.physicModel.gtmAmount?.data[+year] ?? 0);
          }
        });
      }
    );

    reaction(
      () => Object.values(calcModel.physicModel.additionalMining.data),
      () => {
        Object.keys(this.additionalMining.data).forEach((year) => {
          if (calcModel.physicModel.additionalMining?.data[+year] !== null) {
            this.additionalMining.mutationsManager?.updateWrapper(
              +year,
              calcModel.physicModel.additionalMining?.data[+year]
            );
          }
        });
      }
    );

    reaction(
      () => Object.values(calcModel.physicModel.transferringMining.data),
      () => {
        Object.keys(this.transferringMining.data).forEach((year) => {
          if (calcModel.physicModel.transferringMining?.data[+year] !== null) {
            this.transferringMining.mutationsManager?.updateWrapper(
              +year,
              calcModel.physicModel.transferringMining?.data[+year]
            );
          }
        });
      }
    );
  }
}

export { CalcModelInjectionWells };
