import { delayedResolve } from "utils/delayedResolve";
import { LocalStorageMock } from "utils/localStorageMock";

import { type DRow as DRowInterventions } from "../models/project/fact/forecast/wellInterventions//wellIntervention";

const HTR_TYPES = [
  "Залежи с проницаемостью не более 2 мД и эффективной толщиной не более 10 м",
  "Баженовские, абалакские, хадумские и доманиковские продуктивные отложения",
] as const;

type HTRType = (typeof HTR_TYPES)[number];
type Deposit = {
  name: string;
  htrType: HTRType;
  key: number;
  depletion: number;
};

const { decorators } = new LocalStorageMock<void, Deposit[], Deposit[]>("deposits1", false);
const depositsStorageOperator = decorators;

const getDeposits = depositsStorageOperator[0](
  (): Promise<Deposit[]> =>
    delayedResolve(
      [
        { name: "Пласт Ачимовской свиты (Ач2)", key: 1, htrType: HTR_TYPES[0], depletion: 0 },
        { name: "Пласт Баженовкой свиты (ЮВ0)", key: 2, htrType: HTR_TYPES[0], depletion: 0 },
        { name: "Пласт Васюганской свиты (ЮВ1)", key: 3, htrType: HTR_TYPES[1], depletion: 0 },
      ],
      100
    )
);

const setDeposits: (d: Deposit[]) => Promise<Deposit[]> = depositsStorageOperator[1](
  (v: Deposit[]): Promise<Deposit[]> => delayedResolve(v, 100)
);

type TechRawDatum = {
  miningDays: Array<number | null>;
  oilDebet: Array<number | null>;
  waterDebet: Array<number | null>;
  gasInj: Array<number | null>;
  frac?: Array<number | null>;
};

type TechRawData = {
  tech?: Array<TechRawDatum & { key: number }>;
  inject?: Array<{ key: number; data: boolean[] }>;
};

const saveForecastTech = (data: TechRawData): Promise<TechRawData> => delayedResolve(data);

const saveStateTech = (data: TechRawData): Promise<TechRawData> => delayedResolve(data);

const getForecastTech =
  (duration: number): (() => Promise<TechRawData>) =>
  () =>
    delayedResolve({
      tech: [],
      inject: [],
    });

const getStateTech = (): Promise<TechRawData> =>
  delayedResolve({
    tech: [],
    inject: [],
  });

const stateTechDec = new LocalStorageMock<never, TechRawData, TechRawData>("tech", true).decorators;
const saveStateTechDec = stateTechDec[1](saveStateTech);
const getStateTechDec = stateTechDec[0](getStateTech);

const stateForecastDec = (key: string, duration: number) => {
  const dec = new LocalStorageMock<never, TechRawData, TechRawData>(`tech-forecast-${key}`, true).decorators;
  return [dec[0](getForecastTech(duration)), dec[1](saveForecastTech)] as const;
};

function getWellInterventionsDS(forecastKey: string) {
  const [get, set] = new LocalStorageMock<void, DRowInterventions[], DRowInterventions[]>(
    `well-interventions-${forecastKey}`,
    false
  ).decorators;
  const result = [
    get((): Promise<DRowInterventions[]> => delayedResolve([])),
    set((data: DRowInterventions[]): Promise<DRowInterventions[]> => Promise.resolve(data)),
  ] as const;
  return result;
}

export type { HTRType };
export type { TechRawData, TechRawDatum };
export type { Deposit };

export { HTR_TYPES };
export {
  getDeposits,
  getForecastTech,
  getStateTechDec as getStateTech,
  getWellInterventionsDS,
  saveStateTechDec as saveStateTech,
  setDeposits,
  stateForecastDec,
};
