import { getConstantsResolver } from "services/back/constants";
import { getOperatingResolver } from "services/back/operating";
import type { Range } from "utils/range";
import { ofTree } from "utils/tree";

import { MetricTree, riseMetrics } from "../utils";

const params = {
  cashFlowOperating: "Денежный поток от операционной деятельности",
  productionCommercialHC: "Добыча товарных УВ",
  totalRevenue: "Выручка всего (без налогов и транспорта), млн руб.",
  totalRevenueForOil: "Выручка всего (без налогов и транспорта) за нефть, включая льготы по ЭП",
  totalRevenueForOilGas: "Выручка всего (без налогов и транспорта) за нефтяной газ",
  oilSalesDirection3: "Реализация нефти по направлению 3",
} as const;

const includeParentParams: string[] = [params.cashFlowOperating, params.productionCommercialHC];
const includeCashFlowOperatingParams: string[] = [
  params.totalRevenueForOil,
  params.totalRevenueForOilGas,
  params.oilSalesDirection3,
];

const getRevenueFact = async (range: Range) => {
  const { schema_operation } = await getOperatingResolver(range);
  const metricsSchema = riseMetrics(schema_operation);

  let [cashFlow, productionCommercial] = metricsSchema.filter((el) => includeParentParams.includes(el.code_title!));

  cashFlow = cashFlow.children.find((el) => el.code_title === params.totalRevenue)!;
  cashFlow.children = cashFlow.children.filter((el) => !includeCashFlowOperatingParams.includes(el.code_title!));
  productionCommercial = productionCommercial.children[0].children[0];

  const result = [...cashFlow.children, productionCommercial].sort((a, b) => a.title[0].localeCompare(b.title[0]));
  return { table: result };
};

function wrapScalars(metric: MetricTree): MetricTree[] {
  metric.title = "Коэффициенты расчета товарной продукции";
  metric.values = null;
  return [
    {
      title: "",
      key: 0,
      unit: { quantity: 0, measure: 0 },
      values: null,
      children: [metric],
    },
  ];
}

const getRevenueForecast = async (range: Range) => {
  const { constants } = await getConstantsResolver();
  const { table } = await getRevenueFact(range);
  const constantsTree = riseMetrics(constants);

  let scalar: MetricTree[] | null = null;
  for (const metric of ofTree(constantsTree)) {
    if (metric.title === "Для расчета выручки") {
      scalar = wrapScalars(metric);
      break;
    }
  }

  return { table, scalar };
};

export { getRevenueFact, getRevenueForecast };
