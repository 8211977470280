import { FC } from "react";
import { Typography } from "antd";
import { Dayjs } from "dayjs";

import { Format } from "elements/format/format";
import type { Well } from "models/project/fact/well/well";

import cn from "./wellTooltip.module.less";

const WELL_PROPS_LIST = [
  {
    title: "Актив",
    getter: (w: Well): string => w.title,
  },
  {
    title: "ЛУ",
    getter: (w: Well): string => w.licenseRegion?.title ?? "Не задан",
  },
  {
    title: "Куст",
    getter: (w: Well): string => w.mine?.title ?? "Не задан",
  },
  {
    title: "Тип скважины",
    getter: (w: Well): string => w.type ?? "Не задан",
  },
  {
    title: "Дата ввода",
    getter: (w: Well): Dayjs => w.date,
  },
] as const;

const WellTooltip: FC<{ well: Well }> = ({ well }) => {
  return (
    <ul className={cn.container}>
      {WELL_PROPS_LIST.map(({ title, getter }) => (
        <li className={cn.note} key={title}>
          <Typography.Text className={cn.label}>{title}</Typography.Text>
          <Typography.Text className={cn.value}>
            <Format dateFormat="monthYear">{getter(well)}</Format>
          </Typography.Text>
        </li>
      ))}
    </ul>
  );
};

export { WellTooltip };
