import { FC } from "react";

import { Format } from "elements/format/format";

import { PipeColorMode } from "./InfrastructureMapManager/InfrastructureMapManager";
import { LEGEND_MAP_COLOR } from "./utils";

import cn from "./InfrastructureMap.module.less";

const LegendMap: FC<{ mode: PipeColorMode; range: [number, number] }> = ({ mode, range }) => {
  const [minVelocity, maxVelocity] = [range[0], range[1]];
  return (
    <div className={cn.legendMap}>
      <div className={cn.legendFlex}>
        <div className={cn.horizontalLine}></div>
        Построенный трубопровод
      </div>
      <div className={cn.legendFlex}>
        <div className={cn.dottedLine}></div>
        Проектируемый трубопровод
      </div>
      <div>
        <div className={cn.scaleLabels}>
          <Format>{minVelocity}</Format>
          <span>{mode === "pressure" ? "Давление, бар" : "Скорость потока, м/с"}</span>
          <Format>{maxVelocity}</Format>
        </div>
        <div className={cn.colorBlock} style={{ background: `linear-gradient(to right, ${LEGEND_MAP_COLOR.join(", ")})` }}></div>
      </div>
    </div>
  );
};

export { LegendMap };
