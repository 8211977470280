import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, reaction, runInAction, when } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { DecreaseCoefficient } from "models/project/fact/forecast/operatingGTM/decreaseCoefficients";
type DRow = {
  title: string;
  dataKey: string;
  [yearIdx: number]: number | null;
};
class DecreaseCoeffsTableModel extends TableNode<DRow, DecreaseCoeffsTableNode> {
  constructor(public forecast: Forecast) {
    super();
    makeObservable(this, {
      submit: action,
      reset: action,
      clear: action,
      decreaseCoefficients: computed,
    });
    when(
      () => this.decreaseCoefficients.length > 0,
      () => this.init()
    );
  }

  private init() {
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.decreaseCoefficients.map((coeff) => new DecreaseCoeffsTableNode(this, coeff))
      );
    });
  }

  public submit = async () => {
    if (this.childrenStore) {
      for (let child of this.childrenStore.children) {
        child.mutatedChildren?.forEach((value) => {
          const mutatedKeys = Object.keys(value.data);
          mutatedKeys.forEach((year) => {
            if (Number.isFinite(+year))
              this.forecast.decreaseCoefficients.setData(value.parent.dataKey, value.dataKey, +year, value.data[+year]);
          });
        });
      }
    }

    this.reset();
    await this.forecast.decreaseCoefficients.save();
    return;
  };

  public reset() {
    runInAction(() => {
      this.mutationsManager?.dropMutations();
    });
  }
  public async clear() {
    await this.forecast.decreaseCoefficients.clear();
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.decreaseCoefficients.map((coeff) => new DecreaseCoeffsTableNode(this, coeff))
      );
    });
  }

  get decreaseCoefficients() {
    return this.forecast.decreaseCoefficients.coefficients;
  }
}

class DecreaseCoeffsTableNode extends TableNode<DRow, TableRow> {
  public dataKey: string;
  public asDRow = (): DRow => ({
    title: this.coeff.title,
    dataKey: this.dataKey,
  });
  constructor(public readonly parent: DecreaseCoeffsTableModel, public readonly coeff: DecreaseCoefficient) {
    super(parent, { isExpandedChildren: true });
    this.dataKey = coeff.key;
    runInAction(
      () =>
        (this.childrenStore =
          coeff.data !== null
            ? new ChildrenStoreArray(this, [
                new TableRow(this, {
                  title: "Переходящий коэффициент снижения эффекта ГТМ",
                  dataKey: coeff.key,
                  ...Array.from(coeff.data.entries()).reduce((acc, [year, value]) => ({ ...acc, [year]: value }), {}),
                }),
              ])
            : null)
    );
  }
}

class TableRow extends TableNode<DRow> {
  public data: Omit<DRow, "title" | "dataKey">;
  public dataKey: string;
  public lastUpdatedKey: number | null = null;

  public asDRow = (): DRow => ({
    title: this.rowInfo.title,
    dataKey: this.dataKey,
    ...this.data,
  });

  constructor(public readonly parent: DecreaseCoeffsTableNode, public rowInfo: DRow) {
    super(parent, { isExpandedChildren: true });
    makeObservable(this, {
      data: observable,
      updateValue: action,
    });
    this.dataKey = rowInfo.dataKey;
    this.data = Object.fromEntries(
      Object.keys(rowInfo)
        .filter((key) => key !== "title" && key !== "dataKey")
        .map((key) => [key, rowInfo[+key]])
    );
    runInAction(() => (this.childrenStore = null));

    reaction(
      () => Object.values(this.data),
      () => {
        Object.keys(this.data).forEach((year, index) => {
          if (
            this.lastUpdatedKey !== null &&
            isFinite(this.lastUpdatedKey) &&
            this.data[this.lastUpdatedKey] !== null
          ) {
            if (+year > this.lastUpdatedKey) {
              this.mutationsManager?.updateWrapper(+year, this.data[this.lastUpdatedKey]);
            }
          }
        });
      }
    );
  }

  public updateValue(k: any, newValue: any): [prevValue: any, currValue: any] {
    const key = k as keyof DRow;
    if (key === "title" || key === "dataKey") {
      console.error("attempt to update not editable field");
      return [undefined, undefined];
    }
    const prev = this.data[key];
    runInAction(() => {
      this.data[key] = newValue;
    });
    this.lastUpdatedKey = key;
    return [prev, this.data[key]];
  }
}
export { DecreaseCoeffsTableModel };
export type { DRow };
