const findMatch = <T>(obj1: Partial<T>, array: T[]) => {
  const fn = (obj1: Partial<T>, obj2: Partial<T>): boolean => {
    return Object.keys(obj1).every((key) => {
      const k = key as keyof T;
      if (obj1[k] === undefined || obj2[k] === undefined) {
        return true;
      }
      if (typeof obj1[k] === "object" && obj1[k] !== null && obj2[k] !== null) {
        return fn(obj1[k]!, obj2[k]!);
      }
      return obj1[k] === obj2[k];
    });
  };
  return array.find((item) => fn(obj1, item));
};

const compareValues = (limit: number, value: number, type: "more" | "less" = "more") =>
  type === "more" ? limit < Number(value.toPrecision(5)) : limit > Number(value.toPrecision(5));

const constructionKeys = { dsw: "dsw", ciw: "ciw", equipment: "equipment", other: "other" };
const reconstructionKeys = {
  reconstructionDsw: "dsw",
  reconstructionCiw: "ciw",
  reconstructionEquipment: "equipment",
  reconstructionOther: "other",
  period: "period",
} as const;

export { compareValues, constructionKeys, findMatch, reconstructionKeys };
