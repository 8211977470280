import { backendStorage } from "../backendStorage";

type TypeForecastSetting = {
  uuid: string;
  title: string | null;
  wellType: string | null;
  producingObject: string | null;
  forecastParam: string | null;
  method: string | null;
  a: number | null;
  k: number | null;
};

const saveTypeSettings = async (
  data: TypeForecastSetting[],
  projectId: number
): Promise<TypeForecastSetting[] | null> =>
  await backendStorage.setItem<TypeForecastSetting[]>(data, "type_forecast_settings", projectId, undefined, true);

const getTypeSettings = (projectId: number): Promise<TypeForecastSetting[] | null> =>
  backendStorage.getItem<TypeForecastSetting[]>("type_forecast_settings", projectId);

export { getTypeSettings, saveTypeSettings, type TypeForecastSetting };
