import { FC, PropsWithChildren, ReactNode } from "react";
import { Typography } from "antd";
import classNames from "classnames";

import cn from "./projectInfoCard.module.less";

type ProjectInfoSectionProps = {
  title: string;
  icon?: ReactNode;
  className?: string;
  iconFirst?: boolean;
};

const ProjectInfoCard: FC<ProjectInfoSectionProps & PropsWithChildren> = ({ title, children, icon, className, iconFirst }) => {
  return (
    <div className={classNames(cn.card, className)}>
      <div className={cn.header}>
        <Typography.Text type="secondary" className={cn.title}>
          {iconFirst && <div className={cn.icon}>{icon}</div>}
          {title}
        </Typography.Text>
        {!iconFirst && icon}
      </div>
      {children}
    </div>
  );
};

export { ProjectInfoCard };
