import { createContext, useContext, useMemo, useRef } from "react";

import { ContainerShape } from "utils/useContainerShape";

import { Rect, Scale } from "./types";

/*
Основная задача контекста карты - поставлять дочерним компонентам информацию, достаточную для того, чтобы
преобразовать координаты сцены в координаты экрана и вовремя среагировать пересчетом координат на изменения
настроек сцены (зум, перетаскивание). Кроме того передаётся подсказка, показывающая что видно пользователю
*/
type Context = {
  // Не меняется почти никогда. Только при изменении размера сцены и вьюпорта. В основном нужен только он
  scale: Scale;
  // меняется при значительном изменении зума: Math.round(Math.log(k)), позволяет поделить систему рендеринга на "слои"
  zoom: number;
  /*
  Основной инструмент "виртуализации". Идея в том, чтобы побить сцену на прямоугольники, вычислить какие из них видимы
  и рисовать только то, что попадает в эти прямоугольники. Меняются при перетаскивании и зуме
   */
  tiles: Rect;
  tileStep: number;
  domain: Rect;
};

const MapContext = createContext<Context | null>(null);

/*
Ссылочный контекст передаёт часто меняющиеся объекты, на изменение которых не должно реагировать дум-дерево.
Прежде всего это скейл, который учитывает зум. Для обычного рисования он не нужен так как сцена трансформируется
при перетаскивании и зуме без перерисовки дочерних компонентов. Тем не менее при обработке событий информация нужна
*/
type RefsContext = {
  zoomedScale: React.MutableRefObject<Scale>;
  tooltipRef: React.RefObject<HTMLDivElement>;
  noTransformRef: React.RefObject<SVGGElement>;
  shapeRef: React.RefObject<ContainerShape>;
};

const MapRefsContext = createContext<RefsContext | null>(null);

const useMapContext = (): Context => {
  const context = useContext(MapContext);
  console.assert(context, "Использование контекста карты вне виджета карты");
  return context!;
};

const useContextMemo = (
  scale: Scale,
  zoomedScale: Scale,
  domain: Rect,
  transform: d3.ZoomTransform | null
): Context => {
  const zoom = Math.pow(2, Math.round(Math.log2(transform?.k ?? 1)));

  const [xMin, xMax] = zoomedScale.x.domain().map((v) => v - domain.x1);
  const [yMax, yMin] = zoomedScale.y.domain().map((v) => v - domain.y2);
  const tileStep = Math.max(...[domain.x1 - domain.x2, domain.y1 - domain.y2].map(Math.abs)) / 10 / zoom;
  const x1 = Math.floor(xMin / tileStep);
  const x2 = Math.ceil(xMax / tileStep);
  const y1 = Math.floor(yMin / tileStep);
  const y2 = Math.ceil(yMax / tileStep);

  return useMemo(
    (): Context => ({
      scale,
      zoom,
      tiles: { x1, x2, y1, y2 },
      tileStep,
      domain,
    }),
    [scale, zoom, y1, y2, x1, x2, tileStep, domain]
  );
};

const useRefsContextMemo = (
  zoomedScale: Scale,
  tooltipRef: React.RefObject<HTMLDivElement>,
  noTransformRef: React.RefObject<SVGGElement>,
  shape: ContainerShape
): RefsContext => {
  const zoomedScaleRef = useRef(zoomedScale);
  zoomedScaleRef.current = zoomedScale;
  const shapeRef = useRef<ContainerShape>(shape);
  shapeRef.current = shape;

  return useMemo(
    () => ({
      zoomedScale: zoomedScaleRef,
      tooltipRef,
      noTransformRef,
      shapeRef,
    }),
    [noTransformRef, tooltipRef]
  );
};

const useMapRefsContext = (): RefsContext => {
  const context = useContext(MapRefsContext);
  console.assert(context, "Использование контекста карты вне виджета карты");
  return context!;
};

export {
  type Context,
  MapContext,
  MapRefsContext,
  useContextMemo,
  useMapContext,
  useMapRefsContext,
  useRefsContextMemo,
};
