import { useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenEmpty, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";

import { CalculateButton } from "../calculateButton/calculateButton";
import { useInfrastructure } from "../useInfrastructure";

import { AggregationTableStore } from "./tableModel";

import cn from "./infrastructureAggregation.module.less";

const InfrastructureAggregation = observer(() => {
  const forecast = useForecast()!;
  const { aggregation, calculateStore } = useInfrastructure();

  const store = useMemo(
    () => (aggregation && new AggregationTableStore(aggregation.years, aggregation.tableItems)) ?? undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aggregation, calculateStore.isCalculation]
  );

  const columns = useMemo<Column[]>(
    () => [
      {
        title: "Параметр",
        dataKey: "param",
        type: "string",
        width: 350,
        showEditRowBtn: true,
        isSticky: true,
      },
      {
        title: "Единица измерения",
        dataKey: "measure",
        type: "string",
        width: 140,
        isSticky: true,
      },
      {
        title: "Всего за пер.",
        dataKey: "total",
        type: "number",
        width: 140,
        isSticky: true,
      },
      ...[...(aggregation.years ?? [])].map(
        (year): Column => ({
          title: `${year}`,
          dataKey: `${year}`,
          type: "number",
          width: 140,
          renderToString: (v) => v,
        })
      ),
    ],
    [aggregation]
  );

  if (aggregation.isLoading || calculateStore.isCalculation) {
    return <FullScreenLoader />;
  }

  return (
    <SimpleTableContext
      exportFileName="aggregation"
      columns={columns}
      data={store}
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
    >
      <PageFrameTitlePortal model={store}>
        <div className={cn.actionButtons}>{forecast && <CalculateButton fullWidth />}</div>
      </PageFrameTitlePortal>
      {store.childrenStore && store.childrenStore.size === 0 ? (
        <FullScreenEmpty>Нет данных для отображения</FullScreenEmpty>
      ) : (
        <div style={{ height: "100%" }}>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </div>
      )}
    </SimpleTableContext>
  );
});

export { InfrastructureAggregation };
