import { FC } from "react";
import dayjs, { Dayjs } from "dayjs";

import { TechSummaryChart } from "features/techSummaryChart/techSummaryChart";
import { useForecast } from "models/project/fact/forecast/forecast";

import { YearDatum } from "./constants";
import { yearDatumsToAxes } from "./utils";

import cn from "./summary.module.less";

type ChartProps = {
  years: Dayjs[];
  data: YearDatum[];
};

const Chart: FC<ChartProps> = ({ years, data }) => {
  const { from } = useForecast()!.range;
  return (
    <div className={cn.chart}>
      <TechSummaryChart {...yearDatumsToAxes(data)} title="График" x={years} threshold={dayjs(`${from}`)} />
    </div>
  );
};

export { Chart };
