import { FC } from "react";

import { Icon } from "elements/icon/icon";

import { SharedIconProps } from "./sharedIconProps";

const ASSET = (
  <g stroke="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86797 5.04467C3.95661 5.19824 4.10279 5.31017 4.27416 5.3557L6.32148 5.89965C6.67732 5.99419 7.04244 5.78237 7.13698 5.42653C7.23152 5.07068 7.0197 4.70557 6.66385 4.61102L5.26368 4.23901L5.64175 2.84085C5.73785 2.48542 5.52764 2.11939 5.17221 2.02328C4.81678 1.92717 4.45075 2.13739 4.35464 2.49282L3.80179 4.53737C3.75551 4.70854 3.77932 4.8911 3.86797 5.04467Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.67325 3.87793C8.61076 3.78559 9.55128 4.02118 10.3346 4.54455C11.1178 5.06792 11.6954 5.84669 11.9689 6.74817C12.2423 7.64965 12.1948 8.61806 11.8343 9.48839C11.4737 10.3587 10.8226 11.0771 9.99181 11.5212C9.16101 11.9653 8.20192 12.1076 7.27798 11.9238C6.35404 11.74 5.52241 11.2415 4.92478 10.5133C4.32716 9.78511 4.00051 8.87221 4.00051 7.93016C4.00051 7.56198 3.70204 7.2635 3.33385 7.2635C2.96566 7.2635 2.66718 7.56198 2.66718 7.93016C2.66718 9.18068 3.10078 10.3925 3.8941 11.3592C4.68742 12.3258 5.79137 12.9875 7.01786 13.2315C8.24435 13.4754 9.51749 13.2866 10.6203 12.6971C11.7232 12.1076 12.5875 11.154 13.0661 9.99864C13.5446 8.84331 13.6078 7.55779 13.2448 6.36112C12.8818 5.16445 12.1151 4.13067 11.0753 3.43592C10.0355 2.74117 8.78705 2.42844 7.54256 2.55101C6.29806 2.67359 5.13457 3.22388 4.25032 4.10813C3.98997 4.36848 3.98997 4.79059 4.25032 5.05093C4.51067 5.31128 4.93278 5.31128 5.19313 5.05093C5.85926 4.38481 6.73574 3.97026 7.67325 3.87793Z"
      fill="currentColor"
    />
  </g>
);

const Reset: FC<SharedIconProps> = (props) => <Icon {...props} viewBox="0 0 16 16" content={ASSET} />;

export { Reset };
