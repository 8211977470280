import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { ResultPage } from "routing/outlines/result/resultPage";

import { SummaryPage } from "features/summary/summaryPage";

const SummaryForecast: FC = observer(() => {
  return (
    <ResultPage title="Сводка по прогнозу">
      <SummaryPage />
    </ResultPage>
  );
});

export { SummaryForecast };
