/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { createDomainAccessor } from "@okopok/axes_context/utils/boundDomain";

import { useContainerShape } from "utils/useContainerShape";

import { Chart } from "./analogsChartModel";

const useAnalogPointsCloud = (chart: Chart) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const [layoutHeight, setLayoutHeight] = useState(0);

  const shape = useContainerShape(layoutRef);

  useEffect(() => {
    if (layoutRef.current) {
      setLayoutWidth(layoutRef?.current?.clientWidth!);
      setLayoutHeight(layoutRef?.current?.clientHeight);
    }
  }, [layoutRef.current]);

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvasRef.current.getContext("2d");

    const drawPoints = (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      Object.values(chart.lines)
        .filter(({ key }) => !chart.hidden.has(key) && "rawFitting" !== key && "fitting" !== key)
        .forEach((lineInfo) => {
          const accessor = createDomainAccessor(chart.axes[1].domain, lineInfo.y.length);
          const yScale = chart.axes[0].scale;
          const xScale = chart.axes[1].scale;
          ctx.fillStyle = lineInfo.color!;
          ctx.save();
          ctx.translate(-40, 0);
          ctx.beginPath();
          lineInfo.y.forEach((y, index) => {
            if (y !== null) {
              ctx.fillRect(xScale(accessor(index)), yScale(y), 2, 2);
            }
          });
          ctx.closePath();
          ctx.restore();
        });
    };

    if (ctx) {
      drawPoints(ctx);
      requestAnimationFrame(drawCanvas);
    }
  };

  const resizeCanvas = useCallback(
    (canvas: HTMLCanvasElement) => {
      if (!canvas || !layoutRef.current) return;
      const listWidth = 250;
      const axisY = 40;
      const axisX = 50;

      const chartWidth = layoutWidth - (listWidth + axisY);
      const chartHeight = layoutHeight - axisX;

      canvas.width = isFinite(shape.width - listWidth - axisY) ? shape.width - listWidth - axisY : chartWidth;
      canvas.height = isFinite(shape.height - axisX) ? shape.height - axisX : chartHeight;
    },
    [layoutHeight, layoutWidth, shape]
  );

  useEffect(() => {
    resizeCanvas(canvasRef.current!);
    drawCanvas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, resizeCanvas]);

  return { canvasRef, layoutRef };
};

export { useAnalogPointsCloud };
