import { getConstantsResolver } from "services/back/constants";
import { getMetCoefsResolver } from "services/back/metCoefs";
import { getTaxResolver } from "services/back/tax";
import { Range } from "utils/range";

import { MetricTree, riseMetrics } from "../utils";

const includeTableParams = ["НДПИ", "Добыча товарных УВ"];

const reorganizedScalarChild = (scalar: MetricTree[]) => {
  const parent = scalar.find(({ code_title }) => code_title === "Для расчета НДПИ");
  const child = parent?.children.filter(({ code_title }) => code_title === "Коэффициент Кндпи");
  return [
    {
      title: "Постоянные значения",
      values: null,
      unit: { quantity: 0, measure: 0 },
      key: 0,
      children: [
        {
          children: child,
          title: "Коэффициент Кндпи",
          values: null,
          unit: { quantity: 0, measure: 0 },
          key: 1,
        },
      ],
    },
  ] as unknown as MetricTree[];
};

const reorganizedTableChild = (table: MetricTree[], isForecast = false) => {
  const [firstParent, lastParent] = table.filter(({ code_title }) => includeTableParams.includes(code_title as any));
  if (firstParent) {
    firstParent.title = "Коэффициенты";
  }
  const lastParentChild = lastParent.children.find(({ code_title }) => code_title === "Товарная нефть");
  if (lastParentChild) {
    lastParentChild.title = "Добыча нефти";
    lastParentChild.children = lastParentChild.children.filter(
      ({ code_title }) => code_title === "Добыча нефти на кот. распростр. спец. льготы по НДПИ"
    );
  }
  if (isForecast) {
    firstParent.children = firstParent.children.filter(
      ({ code_title }) => code_title !== "Коэффициент выработанности (Кв)"
    );
  }
  return [firstParent, lastParentChild] as MetricTree[];
};

const getSeveranceFact = async (range: Range) => {
  const { constants } = await getConstantsResolver();
  const { schema_operation } = await getTaxResolver(range);
  const { met_coefs } = await getMetCoefsResolver(range);
  const [scalarData, tableData] = [
    riseMetrics(constants),
    [...riseMetrics(met_coefs), ...riseMetrics(schema_operation)],
  ];
  return { scalar: reorganizedScalarChild(scalarData), table: reorganizedTableChild(tableData) };
};

const getSeveranceForecast = async (range: Range) => {
  const { met_coefs } = await getMetCoefsResolver(range);
  const { schema_operation } = await getTaxResolver(range);
  const tableData = [...riseMetrics(met_coefs), ...riseMetrics(schema_operation)];
  return { table: reorganizedTableChild(tableData, true) };
};
export { getSeveranceFact, getSeveranceForecast };
