import { ChildrenStoreArray, ITableNode, TableNode } from "@okopok/components/Table";
import { UploadFile } from "antd";
import { action, computed, makeObservable, observable, reaction, transaction } from "mobx";

import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { Well } from "models/project/fact/well/well";
import { TreeRoot } from "models/tree/tree";
import { parseExcelWellRecoverableResources, RecoverableResourcesExcelType, WellUnsaved } from "services/back/wells";
import { plural } from "utils/plural";
import { getRandomUid } from "utils/random";

import { Fact } from "../../fact";
import { Forecast } from "../../forecast/forecast";

import { WellNode, type WellRawOptional } from "./wellNode";
import { WellNodeFactual } from "./wellNodeFactual";

type DRow = {
  title?: string;
  wellId?: number | null;
  isRankingResultDate?: boolean | null;
  date?: string | null;

  type?: [id: number | null, title?: string];
  stratum?: [id: number | null, title?: string];
  mine?: [id: number | null, title?: string];
  licenseZone?: [id: number | null, title?: string];
  producingObject?: [id: number | null, title?: string];

  topX?: number | null;
  botX?: number | null;
  topY?: number | null;
  botY?: number | null;

  md?: number | null;
  oilRate?: number | null;
  liquidRate?: number | null;
  waterCut?: number | null;
  recoverableResources?: number | null;

  isComplete: boolean;
  isDuplicatedWell: boolean;

  copy?: () => void;
  remove?: () => void;
};

class SummaryRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    ...this.sumProd,
    title: `${this.wellsCount} ${plural(this.wellsCount, ["скважина", "скважин", "скважины"])}`,
    waterCut: null,
    isRankingResultDate: null,
    isComplete: false,
    isDuplicatedWell: false,
  });

  constructor(private parent: WellsList) {
    super(parent);
    makeObservable(this, {
      wellsCount: computed,
      sumProd: computed,
    });
    this.childrenStore = null;
  }

  get wellsCount(): number {
    return this.parent.childrenStore?.size ?? 0;
  }

  get sumProd(): Pick<DRow, "oilRate" | "liquidRate" | "recoverableResources" | "md"> {
    const result: Pick<DRow, "oilRate" | "liquidRate" | "recoverableResources" | "md"> = {
      oilRate: null,
      liquidRate: null,
      recoverableResources: null,
      md: null,
    };
    for (const well of this.parent.children ?? []) {
      const md = well.asDRow().md;
      result.md = (result.md || md) && (result.md ?? 0) + (md ?? 0);
      const nodes = [well, ...(well.children ?? [])];
      for (const node of nodes) {
        for (const key of ["oilRate", "liquidRate", "recoverableResources"] as const) {
          const a = result[key];
          const b = node.asDRow()[key] ?? null;
          result[key] = (a || b) && (a ?? 0) + (b ?? 0); // null if both null, sum otherwise (null = 0)
        }
      }
    }
    return result;
  }
}

class WellsList extends TableNode<DRow, WellNode | WellNodeFactual> {
  public summaryRow?: SummaryRow;

  constructor(
    public readonly fact: Fact,
    public readonly forecast: Forecast | null = null,
    public readonly modeModel: ModeSelectorModel,
    private readonly tree?: TreeRoot<Well>
  ) {
    super();
    makeObservable<WellsList, "reset">(this, {
      summaryRow: observable.ref,
      reset: action,
      propagateDuplicates: action,
      isValid: computed,
    });
    this.reset();

    if (tree) {
      reaction(
        () => this.tree?.selectedItems,
        (selected) => this.filterSelected(new Set(selected?.map((node) => node.item!.id))),
        { fireImmediately: true }
      );
    }
  }

  private filterSelected(selectedWellsIds: Set<number>) {
    const filteredChildren =
      this.childrenStore?.filter((node) => {
        return selectedWellsIds.has(node.data.well_id ?? 0);
      }) || [];

    return filteredChildren;
  }

  public get isValid(): boolean {
    if (this.forecast === null) {
      return true;
    }
    for (const well of this.childrenStore?.children ?? []) {
      if (!well.isCompleted || well.isDuplicatedWell) {
        return false;
      }
    }
    return true;
  }

  private reset() {
    this.mutationsManager?.dropMutations();
    this.selectManager?.propagateDeselected();
    if (this.forecast === null) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.fact.wells.wells.map((well) => new WellNodeFactual(this, this.fact, well))
      );
    } else {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.forecast.wells.wells.map((well) => new WellNode(this, well))
      );
    }
    this.summaryRow = new SummaryRow(this);
    this.propagateDuplicates();
  }

  public propagateDuplicates() {
    const existedTitles = new Set<string>();

    for (const well of this.fact.wells.wells) {
      if (well.data.title) {
        existedTitles.add(well.data.title);
      }
    }

    for (const well of this.childrenStore?.children ?? []) {
      if (!well.data.title) {
        continue;
      }
      if (existedTitles.has(well.data.title)) {
        well.isDuplicatedWell = true;
      } else {
        well.isDuplicatedWell = false;
      }
      existedTitles.add(well.data.title);
    }
  }

  at(index: number): ITableNode<DRow> | undefined {
    if (index === 0) {
      return this.summaryRow;
    }
    return super.at(index - 1);
  }

  get length(): number {
    return super.length + 1;
  }

  public addEmptyWell() {
    const wellNode = new WellNode(this);
    this.childrenStore?.push(wellNode);
  }

  public importWells(wells: WellRawOptional[], replace: boolean = false) {
    if (!this.childrenStore) {
      console.error("Импорт в неинициализированную таблицу");
      return;
    }
    const newWells = wells.map((data) => WellNode.fromRaw(this, data));
    if (replace) {
      this.childrenStore.splice(0, this.childrenStore.size, ...newWells);
    } else {
      this.childrenStore.push(...newWells);
    }
  }

  public parseExcelRecoverableResources = async (file?: UploadFile) => {
    if (!file || !this.childrenStore) return;

    const result = [];
    try {
      const errors: { error: string; uuid: string; type: string }[] = [];
      const importedData = await parseExcelWellRecoverableResources(file);

      const baseWellTitles = new Set(
        Array.isArray(this.childrenStore.children) ? this.childrenStore.children.map((well) => well.data.title) : []
      );

      const importWellTitles = new Set<string>();
      for (const importWell of importedData) {
        if (importWell.recoverableResources === null) {
          errors.push({
            error: `Для скважины ${importWell.wellTitle} -  ОИЗ не заданы`,
            uuid: getRandomUid(),
            type: "notValidFormatValue",
          });
          continue;
        }
        if (importWell.wellTitle === "nan") {
          errors.push({
            error: "Не все поля заполнены корректно",
            uuid: getRandomUid(),
            type: "notValidFormatValue",
          });
          continue;
        }

        importWellTitles.add(importWell.wellTitle);
        if (!baseWellTitles.has(importWell.wellTitle)) {
          errors.push({
            error: `Скважина "${importWell.wellTitle}" отсутствует в базовом фонде`,
            uuid: getRandomUid(),
            type: "notInBase",
          });
          continue;
        }

        if (typeof importWell.recoverableResources !== "number") {
          errors.push({
            error: `Для скважины "${importWell.wellTitle}" указан некорректный формат ОИЗ`,
            uuid: getRandomUid(),
            type: "notValidFormatValue",
          });
          continue;
        }

        result.push(importWell);
      }

      if (Array.isArray(this.childrenStore.children)) {
        for (const baseWell of this.childrenStore.children) {
          if (!importWellTitles.has(baseWell.data.title)) {
            errors.push({
              error: `Скважина "${baseWell.data.title}" из базового фонда отсутствует в файле`,
              uuid: getRandomUid(),
              type: "notInFile",
            });
          }
        }
      }

      return { data: result, errors };
    } catch (error) {
      throw error;
    }
  };

  public importRecoverableResources = async (data?: RecoverableResourcesExcelType[] | null) => {
    if (!data || !this.childrenStore) return;
    const baseWellsMap = new Map<string, WellNode>();
    if (Array.isArray(this.childrenStore.children)) {
      for (const well of this.childrenStore.children) {
        baseWellsMap.set(well.data.title, well);
      }
    }

    transaction(() => {
      for (const well of data) {
        const baseWell = baseWellsMap.get(well.wellTitle);
        if (baseWell instanceof WellNodeFactual) {
          baseWell.mutationsManager?.updateWrapper("recoverableResources", well.recoverableResources);
        }
      }
    });
  };

  public submit = async () => {
    await this.forecast?.wellPads.save();

    const updatedWells: WellUnsaved[] = [];
    const createdWells: WellUnsaved[] = [];

    const hasId = (wellId: number | null): wellId is number => {
      const hasId = wellId !== null;
      console.assert(hasId, "скважина без id среди удалённых");
      return hasId;
    };
    const deletedIds: number[] = [...this.childrenStore!.deletedChildren].map((w) => w.data.well_id).filter(hasId);

    this.mutatedChildren!.forEach((wellNode) => {
      const raw = wellNode.toWellUnsaved;
      if (raw?.id !== undefined) {
        updatedWells.push(raw);
      }
      if (this.forecast === null && wellNode.mutatedChildren?.size) {
        // factual wells with several stratum ids
        wellNode.mutatedChildren.forEach((subNode) => {
          const raw = subNode.toWellUnsaved;
          if (raw?.id !== undefined) {
            updatedWells.push(raw);
          }
        });
      }
    });
    this.addedChildren!.forEach((wellNode) => {
      const raw = wellNode.toWellUnsaved;
      if (raw !== undefined) {
        createdWells.push(raw);
      } else {
        console.error("attempt to submit not completed well");
      }
    });

    await this.forecast?.wellPads.reset();

    return (
      this.forecast !== null
        ? this.forecast.wells.update({ createdWells, updatedWells, deletedIds })
        : this.fact.wells.update({ createdWells, updatedWells, deletedIds })
    ).then(() => this.reset());
  };
}

export type { DRow };
export { WellsList };
