import { makeAutoObservable, reaction } from "mobx";

import { CalcEffectTableNode, TableRow } from "../calcEffectTableModel";
import { CalcModel } from "../calcModel";

class CalcModelRepair {
  public gtmAmount: TableRow;
  public relativeGTMAmount: TableRow;
  constructor(public node: CalcEffectTableNode, public calcModel: CalcModel) {
    makeAutoObservable(this);
    this.gtmAmount = Array.from(node.children!).find((child) => child.rowInfo.title === "Количество ГТМ")!;
    this.relativeGTMAmount = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Удельное количество ГТМ"
    )!;

    reaction(
      () => [
        ...Object.values(this.calcModel.injectionWellsModel.wellsAmount.data),
        ...Object.values(this.relativeGTMAmount.data),
        ...this.calcModel.getFirstNElements(this.gtmAmount),
      ],
      () => {
        Object.keys(this.gtmAmount!.data).forEach((year, index) => {
          if (index > 2) {
            if (
              this.calcModel.injectionWellsModel.wellsAmount.data[+year] !== null &&
              this.relativeGTMAmount?.data[+year] !== null
            ) {
              this.gtmAmount?.mutationsManager?.updateWrapper(
                +year,
                this.calcModel.injectionWellsModel.wellsAmount.data[+year]! * this.relativeGTMAmount.data[+year]!
              );
            }
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.getFirstNElements(this.calcModel.injectionWellsModel.wellsAmount),
        ...this.calcModel.getFirstNElements(this.gtmAmount),
      ],
      () => {
        Object.keys(this.relativeGTMAmount!.data).forEach((year, index) => {
          if (index < 3) {
            if (this.gtmAmount?.data[+year] !== null && this.calcModel.injectionWellsModel.wellsAmount.data[+year]) {
              const newValue =
                this.gtmAmount.data[+year]! / this.calcModel.injectionWellsModel.wellsAmount.data[+year]!;

              this.relativeGTMAmount!.mutationsManager?.updateWrapper(+year, newValue);
            }
          } else {
            if (
              this.relativeGTMAmount?.data[this.calcModel.range.from] !== null ||
              this.relativeGTMAmount?.data[this.calcModel.range.from + 1] !== null ||
              this.relativeGTMAmount?.data[this.calcModel.range.from + 2] !== null
            ) {
              const meanItems = [
                this.relativeGTMAmount!.data[this.calcModel.range.from],
                this.relativeGTMAmount!.data[this.calcModel.range.from + 1],
                this.relativeGTMAmount!.data[this.calcModel.range.from + 2],
              ].filter((item) => item !== null);
              const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

              this.relativeGTMAmount!.mutationsManager?.updateWrapper(+year, mean);
            }
          }
        });
      }
    );
  }
}

export { CalcModelRepair };
