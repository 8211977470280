import { colorPrimary } from "theme/global";

const Theme = {
  components: {
    Radio: {
      buttonSolidCheckedBg: "rgba(0, 0, 0, 0.04)",
      buttonBg: colorPrimary,
      buttonColor: "#fff",
      buttonSolidCheckedColor: "rgba(0, 0, 0, 0.88)",
      buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.04)",
    },
  },
  token: {
    colorBorder: colorPrimary,
    colorPrimary: "#fff",
  },
};

export { Theme };
