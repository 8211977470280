const setupAccuracy = (num: number, direction: "round" | "floor" | "ceil" = "round", base = 3): number => {
  if (num === 0) {
    return num;
  }
  if (num < 0) {
    return -setupAccuracy(-num, direction, base);
  }
  const p = Math.pow(10, Math.ceil(Math.log10(num)) - base);
  if (p < 1) {
    const d = Math.round(1 / p);
    return Math[direction](num * d) / d;
  } else {
    const d = Math.round(p);
    return Math[direction](num / d) * d;
  }
};

export { setupAccuracy };
