function* ofTree<T>(items: T[]): Iterable<T> {
  for (const item of items) {
    yield item;
    if (item !== null && typeof item === "object" && "children" in item && Array.isArray(item.children)) {
      yield* ofTree(item.children);
    }
  }
}

function findInTree<T>(predicate: (v: T) => boolean, tree: T[]): T | undefined {
  for (const item of ofTree(tree)) {
    if (predicate(item)) {
      return item;
    }
  }
}

function findByKeyInTree<T extends { key: string | number }>(key: T["key"], tree: T[]): T | undefined {
  return findInTree((i) => i.key === key, tree);
}

function numerateTree<T>(items: T[]): void {
  let current = 0;
  for (const node of ofTree(items)) {
    (node as { row: number }).row = ++current;
  }
}

function keysForTree<T>(items: T[]): void {
  let current = 0;
  for (const node of ofTree(items)) {
    (node as { key: number }).key = ++current;
  }
}

function cloneTree<T extends { children?: T[] }>(items: T[]): T[] {
  const result = [];
  for (const itm of items) {
    result.push({
      ...itm,
    });
    if (itm.children !== undefined) {
      result[result.length - 1].children = cloneTree(itm.children);
    }
  }
  return result;
}

export { cloneTree, findByKeyInTree, findInTree, keysForTree, numerateTree, ofTree };
