import { FC, PropsWithChildren, useState } from "react";
import { Button, message, Upload } from "antd";

import { Icon } from "elements/icon/icon";
import { req } from "utils/request";

import { ReactComponent as DownloadIcon } from "../../icons/download.svg";

import cn from "./uploadChart.module.less";

type UploadChartProps<T> = PropsWithChildren<{
  onSave: (chart: T) => void;
  path: string;
}>;

const UploadChart: FC<UploadChartProps<any>> = ({ onSave, children, path }) => {
  const [loading, setLoading] = useState<"noFile" | "uploading" | "uploaded">("noFile");
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <Upload
      name="file"
      className={cn.container}
      customRequest={async ({ file }) => {
        const formData = new FormData();
        formData.append("file", file);
        setLoading("uploading");
        try {
          const res = await req.post<any>(path, formData);
          onSave("data" in res ? res.data : res);
          setLoading("uploaded");
        } catch (err) {
          if (err instanceof Response) {
            const explain = await err.json();
            messageApi.warning(`Не удалось загрузить excel файл: ${explain.detail}`);
          } else {
            messageApi.warning(`Ошибка при загрузке excel файла`);
          }
          setLoading("noFile");
          return;
        }
      }}
      showUploadList={false}
      maxCount={1}
    >
      {contextHolder}
      <Button size="small" icon={<Icon content={<DownloadIcon />} width="16" height="16" viewBox="0 0 16 16" />} loading={loading === "uploading"}>
        {children}
        {loading === "uploaded" && " (загружено)"}
      </Button>
    </Upload>
  );
};

export { UploadChart };
