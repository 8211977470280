import { PageFrame } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { OperatingGTM as OperatingGTMFeature } from "features/techPrediction/operatingGTM/operatingGTM";

import { OPERATING_PRELOADER } from "./preloads";

const OperatingGTM = () => (
  <ForecastSelector>
    <Preloader hooks={OPERATING_PRELOADER}>
      <PageFrame title="Операционные ГТМ">
        <OperatingGTMFeature />
      </PageFrame>
    </Preloader>
  </ForecastSelector>
);

export { OperatingGTM };
