import { FC } from "react";
import { Button } from "antd";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Card } from "elements/card/card";
import { Plus } from "elements/icons/plus";

import cn from "./versionFem.module.less";

const data = {
  version: 13.0,
  description: "",
  workDate: "31.12.2023",
  date: "01.02.2023",
  versionCategory: "",
  creationReason: "",
};

const VersionFemData: FC = () => {
  return (
    <>
      <PageFrameTitlePortal>
        <Button disabled icon={<Plus />}>
          Добавить свою версию алгоритма
        </Button>
      </PageFrameTitlePortal>
      <Card className={cn.card} bodyClassName={cn.bodyCard} title="Данные">
        <div className={cn.field}>
          <div className={cn.fieldTitle}>Номер версии алгоритма</div>
          <div className={cn.fieldValue}>{data.version || "-"}</div>
        </div>
        <div className={cn.field}>
          <div className={cn.fieldTitle}>Описание версии алгоритма</div>
          <div className={cn.fieldValue}>{data.description || "-"}</div>
        </div>
        <div className={cn.field}>
          <div className={cn.fieldTitle}>Дата внедрения версии в ИС</div>
          <div className={cn.fieldValue}>{data.workDate || "-"}</div>
        </div>
        <div className={cn.field}>
          <div className={cn.fieldTitle}>Дата вступления в силу</div>
          <div className={cn.fieldValue}>{data.date || "-"}</div>
        </div>
        <div className={cn.field}>
          <div className={cn.fieldTitle}>Категория версии алгоритма</div>
          <div className={cn.fieldValue}>{data.versionCategory || "-"}</div>
        </div>
        <div className={cn.field}>
          <div className={cn.fieldTitle}>Основание создания версии алгоритма</div>
          <div className={cn.fieldValue}>{data.creationReason || "-"}</div>
        </div>
      </Card>
    </>
  );
};

export { VersionFemData };
