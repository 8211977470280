import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

import cn from "./label.module.less";

interface LabelProps {
  title: string;
  labelWidth?: string;
  className?: string;
  inputClassName?: string;
}

const Label: FC<LabelProps & PropsWithChildren> = ({ title, children, labelWidth, className, inputClassName }) => {
  return (
    <div className={classNames(cn["label-block"], className)}>
      <div style={{ width: labelWidth }} className={cn.title}>
        {title}
      </div>
      <div className={classNames(cn["input-container"], inputClassName)}>{children}</div>
    </div>
  );
};

export { Label };
