import { FC, useState } from "react";
import { Select } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreen, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { MonthPicker } from "elements/inputs/monthPicker";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { InjectionResults as InjectionResultsModel } from "models/project/fact/forecast/injPrediction/injectionResults";
import { useProject } from "models/project/project";

import { GridMap } from "./gridMap/GridMap";
import { InjectionSliceTable } from "./resultsTableView/injectionSliceTable";
import { ProductionSliceTable } from "./resultsTableView/productionSliceTable";

import cn from "./injectionResults.module.less";

type ViewMode = "inj" | "prod" | "map";

const VIEW_MODE_OPTIONS: { value: ViewMode; label: string; disabled?: boolean }[] = [
  {
    value: "inj",
    label: "Срез по нагнетательным",
  },
  {
    value: "prod",
    label: "Срез по добывающим",
  },
  {
    value: "map",
    label: "Сетка Вороного",
  },
];

const Switch: FC<{ viewMode: ViewMode; injectionResults: InjectionResultsModel }> = observer(({ viewMode, injectionResults }) => {
  if (injectionResults.voronoi === undefined) {
    return <FullScreenLoader />;
  }
  if (injectionResults.voronoi === null) {
    return <FullScreen>Нет данных за текущий период</FullScreen>;
  }
  if (viewMode === "inj") {
    return <InjectionSliceTable voronoi={injectionResults.voronoi} />;
  }
  if (viewMode === "prod") {
    return <ProductionSliceTable voronoi={injectionResults.voronoi} />;
  }
  if (viewMode === "map") {
    return <GridMap data={injectionResults.voronoi} />;
  }
  return <>{viewMode}</>;
});

const InjectionResults = observer(() => {
  const producingObjects = useProject()!.producingObjects;
  const forecast = useForecast()!;
  const injectionResults = forecast.injectionResults;
  const [viewMode, setViewMode] = useState<ViewMode>("inj");

  if (injectionResults.isCalculating) {
    return <FullScreenLoader>Выполняется расчет</FullScreenLoader>;
  }

  return (
    <>
      <PageFrameTitlePortal>
        <div className={cn.selectorsSection}>
          <Select
            value={viewMode}
            options={VIEW_MODE_OPTIONS}
            onSelect={(mode) => runInAction(() => setViewMode(mode))}
            className={cn.viewModeSelector}
          />
          <MonthPicker
            value={injectionResults.currentDate}
            onChange={(date) => runInAction(() => (injectionResults.currentDate = date))}
            allowClear={false}
            className={cn.monthPicker}
          />
          <SelectStorable
            store={producingObjects}
            values={[injectionResults.currentProducingObjectId, undefined]}
            setValues={(id: number | null) => runInAction(() => (injectionResults.currentProducingObjectId = id))}
            variant="outlined"
            allowClear={false}
          />
        </div>
      </PageFrameTitlePortal>
      <Switch viewMode={viewMode} injectionResults={injectionResults} />
    </>
  );
});

export { InjectionResults };
