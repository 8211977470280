import { FC } from "react";
import { observer } from "mobx-react";

import { WellsTree } from "features/wellsTree/wellsTree";

import cn from "./treeMenu.module.less";

type TreeMenuMenuProps = {};

const TreeMenu: FC<TreeMenuMenuProps> = observer(() => {
  return (
    <div className={cn.wrap}>
      <WellsTree title="Скважины" />
    </div>
  );
});

export { TreeMenu };
