import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { ProducingObjects as ProducingObjectsFeature } from "features/project/producingObjects/producingObjects";
import { useProject } from "models/project/project";

const useProducingObjects = () => useProject()?.producingObjects;
const useStratums = () => useProject()?.stratums;

const ProducingObjects = () => (
  <Preloader hooks={[useProducingObjects, useStratums]}>
    <PageFrame title="Объекты разработки">
      <ProducingObjectsFeature />
    </PageFrame>
  </Preloader>
);

export { ProducingObjects };
