import { useMemo } from "react";
import { TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { Popconfirm, Select, Tag } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { global } from "models/global";
import { useProject } from "models/project/project";
import { ExtendedParticipantInfo, ProjectParticipantsStore } from "models/projectParticipants";
import { useTableSettings } from "models/tableSettings";
import { ProjectRole } from "services/back/roles";

import { ReactComponent as AddIcon } from "../projects/icons/add.svg";

import cn from "./participantsTable.module.less";

const useColumns = (canUserEdit: boolean): ColumnRaw<ExtendedParticipantInfo>[] => {
  return useMemo(
    (): ColumnRaw<ExtendedParticipantInfo>[] => [
      {
        dataKey: "fullName",
        title: "Сотрудник",
        width: { min: 10, max: 1000, competitiveness: 10 },
        render: (value) => <Format>{value}</Format>,
      },
      {
        key: "department",
        title: "Отдел",
        width: { min: 10, max: 1000, competitiveness: 10 },
        render: () => <Format>{null}</Format>,
      },
      {
        key: "organization",
        title: "Организация",
        width: { min: 10, max: 1000, competitiveness: 10 },
        render: () => <Format>{null}</Format>,
      },
      {
        dataKey: "email",
        title: "Электронный адрес",
        width: { min: 10, max: 1000, competitiveness: 10 },
        render: (value) => <Format>{value}</Format>,
      },
      {
        key: "position",
        title: "Должность",
        width: { min: 10, max: 1000, competitiveness: 10 },
        render: () => <Format>{null}</Format>,
      },
      {
        key: "roles",
        title: "Роль",
        width: { min: 100, max: 1000, competitiveness: 10 },
        render: (value: ProjectRole[], { value: participant }) => {
          const selected = value.map(({ id }) => id);
          return (
            <Select
              mode="multiple"
              disabled={!canUserEdit}
              allowClear={false}
              value={selected}
              defaultValue={value.map(({ id }) => id)}
              options={global.roles.selector!.map((option) => ({ ...option, closable: false }))}
              variant="borderless"
              className={cn["role-select"]}
              tagRender={({ label, value, onClose }) => {
                const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                  event.preventDefault();
                  event.stopPropagation();
                };
                return (
                  <Tag closable={selected.length !== 1 && value !== 1} onClose={onClose} onMouseDown={onPreventMouseDown}>
                    {label}
                  </Tag>
                );
              }}
              onSelect={async (rId) => {
                if (participant) {
                  await participant.addRole!(rId);
                  participant.refresh!();
                }
              }}
              onDeselect={async (rId) => {
                if (participant) {
                  await participant.deleteRole!(rId);
                  participant.refresh!();
                }
              }}
            />
          );
        },
      },
      {
        key: "actions",
        title: "Действия",
        width: { min: 10, max: 10000, competitiveness: 4 },
        render: () => <Format>{null}</Format>,
      },
      ...(canUserEdit
        ? ([
            {
              title: "",
              key: "sticky",
              width: 55,
              render: (_, { value }) => (
                <Popconfirm
                  title="Удалить участника проекта?"
                  cancelText="Отмена"
                  okText="Удалить"
                  onConfirm={async () => {
                    if (value?.deleteParticipant) {
                      await value.deleteParticipant();
                      value.refresh!();
                    }
                  }}
                >
                  <DeleteButton />
                </Popconfirm>
              ),
              onCell: () => ({ className: cn["delete-cell"] }),
            },
          ] as ColumnRaw<ExtendedParticipantInfo>[])
        : []),
    ],
    [canUserEdit]
  );
};

const ParticipantsTable = observer(() => {
  const project = useProject()!;
  const { participants } = project;

  const columns = useColumns(participants.canUserEdit);

  const store = useMemo(() => new ProjectParticipantsStore(project), [project]);
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: () => ({ className: cn.row }),
        },
        {
          headerHeight: 39,
          borderColor: "#f0f0f0",
        }
      ),
    [store, columns]
  );

  useTableSettings(model, "users");

  const options: DefaultOptionType[] = Array.from(global.users.values!)
    .filter(({ id }) => !participants.tableData.find(({ id: pId }) => id === pId))
    .map(({ id, fullName }) => ({ label: fullName, value: id }));

  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal>
        {participants.canUserEdit ? (
          <Select
            placeholder="Добавить участника..."
            suffixIcon={<Icon viewBox="0 0 15 15" content={<AddIcon />} />}
            options={options}
            className={cn.select}
            onSelect={async (id) => {
              await project.participants.addParticipant(id);
              store.refresh();
            }}
          />
        ) : null}
      </PageFrameTitlePortal>
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { ParticipantsTable };
