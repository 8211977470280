import { type FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { WellInterventions as WellInterventionFeature } from "features/interventions/wellInterventions";
import { Preloader } from "features/preloader/preloader";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

const useProducingObjects = () => useProject()?.producingObjects;
const useFactWells = () => useFact()?.wells;
const useForecastWells = () => useForecast()?.wells;
const useForecastInterventions = () => useForecast()?.interventions;

const WellInterventions: FC = () => (
  <ForecastSelector>
    <Preloader hooks={[useProducingObjects, useFactWells, useForecastWells, useForecastInterventions]}>
      <PageFrame title="Программа ГТМ">
        <WellInterventionFeature />
      </PageFrame>
    </Preloader>
  </ForecastSelector>
);

export { WellInterventions };
