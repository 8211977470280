import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";
import { PointInfo } from "../Tooltip/Tooltip";

const TooltipLine = observer(({ svgRef }: { svgRef: React.MutableRefObject<null> }) => {
  const model = useD3Context();
  const refLine = useRef<SVGLineElement | null>(null);
  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
    d3.select(svgRef.current)
      .on("mouseover", () => {
        d3.select(refLine.current).style("opacity", 1).style("stroke-dasharray", "5,5");
      })
      .on("mousemove", (event: MouseEvent) => {
        const [x, y] = d3.pointer(event);
        d3.select(refLine.current)
          .attr("x1", x)
          .attr("x2", x)
          .attr("y1", model.padding.top)
          .attr("y2", model.height - model.padding.top);
        const xCursor = xScale.invert(x);
        const tooltipStates: PointInfo[] = [];
        const confidenceDist = Math.abs(xScale.invert(x).getTime() - xScale.invert(x + 10).getTime());
        model.Curves.forEach((curve) => {
          let closetPoint = curve.points[0];
          let dist = Math.abs(curve.points[0].x.getTime() - xCursor.getTime());

          curve.points.forEach((point) => {
            if (Math.abs(point.x.getTime() - xCursor.getTime()) < dist) {
              closetPoint = point;
              dist = Math.abs(point.x.getTime() - xCursor.getTime());
            }
          });

          if (dist <= confidenceDist) {
            const axisTitle =
              model.yAxesLeft.find((axis) => axis.id === curve.yId)?.uom ?? model.yAxesRight.find((axis) => axis.id === curve.yId)?.uom ?? ",";
            const color = curve.fragments.find((fragment) => closetPoint.x <= fragment.to && closetPoint.x >= fragment.from)?.color ?? "blue";
            tooltipStates.push({
              header: axisTitle.split(",")[0],
              color: color,
              value: closetPoint.y,
              uom: axisTitle.split(",")[1],
              year: closetPoint.x.getFullYear(),
            });
          }
        });
        if (tooltipStates.length === 0) {
          model.setTooltipState(null);
        } else {
          if (model.height - y < 100) {
            model.setTooltipState({ left: x + 20, top: y - (100 - model.height + y), pointsInfo: tooltipStates });
          } else {
            model.setTooltipState({ left: x + 20, top: y, pointsInfo: tooltipStates });
          }
        }
      })
      .on("mouseout", () => {
        d3.select(refLine.current).style("opacity", 0);
        model.setTooltipState(null);
      });
  }, [model, model.height, model.padding.top, model.xAxis.domain.max, model.xAxis.domain.min, model.currentZoomState, svgRef]);
  return (
    <g>
      <line ref={refLine} width={2} stroke="blue" clipPath={`url(#clipPath-${model.svgClassName})`} style={{ pointerEvents: "none" }}></line>
    </g>
  );
});

export default TooltipLine;
