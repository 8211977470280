import { useCallback, useRef } from "react";

import { useModal } from "elements/modal/modal";

import { type PeriodData, PeriodForm, type PeriodInput } from "./periodForm";

const usePeriodModal = (disabledDate: PeriodInput["disabledDate"], producingObjectIds: PeriodInput["producingObjectIds"]) => {
  const modal = useModal<PeriodData, PeriodInput>();

  const dataRef = useRef<PeriodInput>({ data: null, disabledDate, producingObjectIds });

  return useCallback(
    (data: PeriodInput["data"]) =>
      modal(
        PeriodForm,
        (() => {
          dataRef.current.data = data;
          return dataRef;
        })(),
        data ? "Редактирование периода" : "Добавление нового периода",
        "Применить",
        "Отменить",
        "540px"
      ),
    [modal]
  );
};

export { usePeriodModal };
