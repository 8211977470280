import { ResultValidationMessage } from "routing/outlines/result/resultInformer";

import type { Forecast } from "models/project/fact/forecast/forecast";
import type { LicenseRegionsParamsMap } from "models/project/fact/licenseRegionParamsMap";
import { zip } from "utils/itertools";
import type { Range } from "utils/range";
import { ofTree } from "utils/tree";

import { Params } from "./params";
import type { ParamsNode } from "./paramsNode";
import type { ParamsTable } from "./paramsTable";

type ValidationContext = "fact" | "forecast";

class ParamsValidator {
  private start: number;
  private end: number;
  private plan: number;
  private nddEntry: number;

  constructor(private readonly forecast: Forecast) {
    this.start = forecast.fact.investYear;
    this.plan = forecast.range.from;
    this.end = forecast.range.to - 1;
    this.nddEntry = forecast.fact.NDDYear ?? 2021;
  }

  private getLicenseRegionTitle(licenseRegionId: number): string | undefined {
    return this.forecast.licenseRegions.at(licenseRegionId)?.title;
  }

  private get toValidateFact(): (Params | LicenseRegionsParamsMap<Params>)[] {
    const fact = this.forecast.fact;
    return [
      fact.investParams,
      fact.operatingParams,
      fact.operatingRevenue,
      fact.severanceTax,
      fact.nddTax,
      fact.wealthTax,
      fact.referenceParams,
    ];
  }

  private get toValidateForecast(): (Params | LicenseRegionsParamsMap<Params>)[] {
    return [
      this.forecast.investCosts,
      this.forecast.investParams,
      this.forecast.operatingCosts,
      this.forecast.operatingRevenue,
      this.forecast.severanceTax,
      this.forecast.wealthTax,
      this.forecast.nddTax,
      this.forecast.referenceParams,
    ];
  }

  public *validate(): Generator<ResultValidationMessage, void, undefined> {
    for (const paramsEntry of this.toValidateFact) {
      yield* this.validateParamsEntry(paramsEntry, "fact");
    }
    for (const paramsEntry of this.toValidateForecast) {
      yield* this.validateParamsEntry(paramsEntry, "forecast");
    }
  }

  private validateParamsEntry(
    entry: Params | LicenseRegionsParamsMap<Params>,
    ctx: ValidationContext
  ): ResultValidationMessage[] {
    if (entry.isLoading === true) {
      return [];
    }

    const result: ResultValidationMessage[] = [];
    if (entry instanceof Params) {
      result.push(...this.validateParams(entry, ctx));
    } else {
      for (const [lrId, params] of entry.items!) {
        result.push(...this.validateParams(params, ctx, lrId));
      }
    }
    return result;
  }

  private validateParams(params: Params, ctx: ValidationContext, licenseRegionId?: number): ResultValidationMessage[] {
    let prefix = "";
    if (ctx === "fact") {
      prefix += "Фактические данные. ";
    } else {
      prefix += "Прогнозные данные. ";
    }
    prefix += `${params.title}. `;

    if (licenseRegionId !== undefined) {
      const lrTitle = this.getLicenseRegionTitle(licenseRegionId);
      if (lrTitle === undefined) {
        return [
          new ResultValidationMessage(
            "debug",
            `${prefix} Неизвестный id ЛУ: ${licenseRegionId}. Валидация не проведена.`
          ),
        ];
      }
      prefix += `Данные по ЛУ ${lrTitle}.`;
    }
    prefix += "\n";
    const result = [];

    if (params.scalar) {
      if (!params.isCompletedScalars) {
        result.push(new ResultValidationMessage("error", prefix + `Константы заполнены не полностью.`));
      }
    }
    if (params.table) {
      try {
        const notCompletedRows = this.validateParamsTable(params.table);
        const entries = [...Object.entries(notCompletedRows)];
        if (entries.length > 0) {
          const uniqueYears = new Set<number>();
          for (const years of Object.values(notCompletedRows)) {
            for (const year of years) {
              uniqueYears.add(year);
            }
          }
          const uniqueYearsArray = [...uniqueYears].sort((a, b) => a - b);
          result.push(
            new ResultValidationMessage(
              "debug",
              prefix +
                `Табличные данные заполнены не полностью:\n${entries
                  .map(([key, years]) => `${key}: ${years.join(", ")}`)
                  .join("\n")}`
            ),
            new ResultValidationMessage(
              "error",
              prefix +
                `Табличные данные заполнены не полностью. Отсутствуют данные за ${uniqueYearsArray.join(
                  ", "
                )}:\n${entries.map(([key]) => key).join(", ")}`
            )
          );
        }
      } catch (err) {
        result.push(new ResultValidationMessage("debug", prefix + err));
      }
    }

    return result;
  }

  private validateParamsTable(table: ParamsTable): Record<string, number[]> {
    const valuesRange = table.years;
    const notValidParams: Record<string, number[]> = {};

    if (table.metrics === undefined) {
      throw new Error(`undefined table metrics. ${table}`);
    }

    for (const node of ofTree(table.metrics)) {
      const emptyYears = this.validateParamsNode(node, valuesRange);
      if (emptyYears.length > 0) {
        notValidParams[node.title] = emptyYears;
      }
    }

    return notValidParams;
  }

  private validateParamsNode(node: ParamsNode, valuesRange: Range): number[] {
    if (!node.validators) {
      return [];
    }
    const { visible, editable } = node.validators;
    if (!visible && !editable) {
      return [];
    }
    if (editable?.optional === true || editable?.not_editable === true) {
      return [];
    }

    const emptyYears: number[] = [];
    const { start, end, plan, nddEntry } = this;
    const validatorRange = new RangeResolver(start, end, plan, nddEntry);

    if (visible?.row?.only_ndd === true) {
      validatorRange.only_since_ndd();
    }
    if (visible?.column) {
      for (const option of ParamsValidator.disposibleOptions) {
        if (visible.column[option]) {
          validatorRange[option](editable?.dispose);
        }
      }
    }
    if (editable) {
      for (const option of ParamsValidator.disposibleOptions) {
        if (editable[option]) {
          validatorRange[option](editable.dispose);
        }
      }
      for (const option of ParamsValidator.nddOptions) {
        if (editable[option]) {
          validatorRange[option]();
        }
      }
      if (editable.start_from !== null) {
        validatorRange.start_from(editable.start_from);
      }
    }

    if (node.personalValues === null || node.personalValues.length !== valuesRange.length) {
      throw new Error(
        `ParamsNode values array wrong size. ${node.title}, ${node.personalValues}. expected range: ${valuesRange}`
      );
    }

    const [from, to] = validatorRange.range;
    for (const [year, value] of zip([...valuesRange], node.personalValues)) {
      if (from <= year && year <= to && value === null) {
        emptyYears.push(year);
      }
    }

    return emptyYears;
  }

  private static disposibleOptions = ["only_fact", "only_forecast"] as const;
  private static nddOptions = ["only_before_ndd", "only_since_ndd"] as const;
}

class RangeResolver {
  #range: [number, number];
  get range() {
    return this.#range;
  }
  set rangeStart(newVal: number) {
    this.#range[0] = Math.max(this.range[0], newVal);
  }
  set rangeEnd(newVal: number) {
    this.#range[1] = Math.min(this.range[1], newVal);
  }

  constructor(start: number, end: number, private plan: number, private nddEntry: number) {
    this.#range = [start, end];
  }

  public start_from(start: number): RangeResolver {
    this.rangeStart = start;
    return this;
  }

  public only_fact(dispose: number | null = null): RangeResolver {
    dispose ??= 0;
    this.rangeEnd = this.plan - 1 + dispose;
    return this;
  }

  public only_forecast(dispose: number | null = null): RangeResolver {
    dispose ??= 0;
    this.rangeStart = this.plan - dispose;
    return this;
  }

  public only_since_ndd(): RangeResolver {
    this.rangeStart = this.nddEntry;
    return this;
  }

  public only_before_ndd(): RangeResolver {
    this.rangeEnd = this.nddEntry - 1;
    return this;
  }
}

export { ParamsValidator };
