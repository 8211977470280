import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";
import { action, makeObservable, reaction } from "mobx";

import { Well } from "models/project/fact/well/well";

import { Forecast } from "../../forecast";

import { GTMInfo } from "./utils/extractInfo";

type DRow = {
  wellTitle: string;
  mineCode: string;
  eventType: string;
  crewType: "ЭБ" | "ЗБС" | "КРС";

  oilProdYear?: number;
  oilProdPeriod?: number;
  liquidProdYear?: number;
  liquidProdPeriod?: number;

  rank: number | undefined;

  price: number | undefined; // млн руб
  start: Dayjs | undefined;
  end: Dayjs | undefined;
};

class EventNode extends TableNode<DRow> {
  asDRow(): DRow {
    return {
      wellTitle: this.well?.title ?? "скважина не найдена",
      mineCode: this.well?.pad?.title ?? "куст не найден",
      eventType: this.gtm.gtmType ?? "Эксплуатационное бурение",
      crewType: this.gtm.crewType,
      rank: this.gtm.rank,
      start: this.gtm.period && dayjs(this.gtm.period.start),
      end: this.gtm.period && dayjs(this.gtm.period.end),
      price: this.gtm.price,
    };
  }

  constructor(private readonly parent: Events, public readonly gtm: GTMInfo) {
    super(parent);
  }

  public get well(): Well | null {
    return this.parent.forecast.wells.at(this.gtm.wellId);
  }
}

class Events extends TableNode<DRow, EventNode> {
  constructor(public readonly forecast: Forecast, gtmsList: "included" | "notIncluded") {
    super();

    makeObservable<Events, "init">(this, {
      init: action,
    });

    reaction(
      () => this.forecast.rankingSettings.result,
      (res) => res && this.init(gtmsList === "included" ? res.gtms : res.gtmsNotIncluded),
      { fireImmediately: true }
    );
  }

  private init(gtms: GTMInfo[]) {
    this.childrenStore = new ChildrenStoreArray(
      this,
      gtms.map((gtm) => new EventNode(this, gtm))
    );
  }
}

export type { DRow };
export { Events };
