import { ReactNode } from "react";
import { Button, Typography, Upload, UploadFile } from "antd";

import { FullScreen } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";

import { ReactComponent as UploadIcon } from "./uploadIcon.svg";

import es from "./emptyScreen.module.less";

const { Dragger } = Upload;
const { Title } = Typography;

type Props = {
  text: string;
  icon?: ReactNode;
  onUpload?: (file: UploadFile) => void;
};

const EmptyScreen = ({ text, icon, onUpload }: Props) => {
  return (
    <FullScreen className={es.emptyScreen}>
      <Dragger onChange={({ file }) => onUpload?.(file)} accept=".xlsx" className={es.dragger} beforeUpload={() => false}>
        {icon}
        <Title>{text}</Title>
        <Button icon={<Icon className={es.uploadIcon} content={<UploadIcon />} viewBox="0 0 16 16" />} type="primary">
          Загрузить данные
        </Button>
        <div className={es.notDataText}>или перенесите файл в эту область</div>
      </Dragger>
    </FullScreen>
  );
};

export { EmptyScreen };
