import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeObservable, observable } from "mobx";

import type { ParamsRow as FinancialParamsRow } from "features/useMetrics/paramsRow";
import { ResultsTable } from "models/project/fact/forecast/forecastResult";
import { zip } from "utils/itertools";
import type { Range } from "utils/range";

type DRow = {
  title: string;
  measure: string;
  [year: number]: number | null;
};

class FinancialResultStore extends TableNode<DRow, Datum> {
  public readonly root = this;
  public rows: FinancialParamsRow[];
  public range: Range;

  constructor(private readonly result: ResultsTable) {
    super();
    this.rows = result.tableItems;
    this.range = result.years;

    makeObservable<FinancialResultStore, "rows">(this, {
      rows: observable,
    });
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.rows.map((row) => new Datum(this, row))
    );
  }
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): FinancialResultStore {
    return this.parent.root;
  }

  constructor(private readonly parent: Datum | FinancialResultStore, private readonly row: FinancialParamsRow) {
    super(parent, { isExpandedChildren: true, mutable: false });
    if (row.children) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        row.children?.map((childRow) => {
          if (childRow.setBooleanValue) {
            childRow.values = childRow.values?.map((value) => Boolean(value));
          }

          return new Datum(this, childRow);
        })
      );
    } else {
      this.childrenStore = null;
    }
    if (this.row.children !== undefined) {
      this.updateValue = undefined;
    }
    makeObservable<Datum, "row">(this, {
      row: observable,
    });
  }

  public get range(): Range {
    return this.root.range;
  }

  asDRow = (): DRow => ({
    title: this.row.title,
    measure: this.row.measure ?? "",
    ...Object.fromEntries(zip([...this.range], this.row.values ?? [])),
  });
}

export { FinancialResultStore };
