/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { axisWidth } from "theme/global";

import { useD3Context } from "../../D3SVG/D3SVG";

type PointType = {
  x: Date;
  y: number;
};
type PointProps = {
  i: number;
  k: number;
  yId: number;
  color: string | number;
  axisTitle: string;
  editable?: boolean;
};
type CoordsType = {
  x: number;
  y: number;
};

const calcRadius = (leftNeighbor: CoordsType | null, self: CoordsType, rightNeighbor: CoordsType | null): number => {
  let leftRadius = 21;
  let rightRadius = 21;
  if (leftNeighbor !== null) {
    leftRadius = Math.sqrt((self.x - leftNeighbor.x) * (self.x - leftNeighbor.x) + (self.y - leftNeighbor.y) * (self.y - leftNeighbor.y));
  }
  if (rightNeighbor !== null) {
    rightRadius = Math.sqrt((self.x - rightNeighbor.x) * (self.x - rightNeighbor.x) + (self.y - rightNeighbor.y) * (self.y - rightNeighbor.y));
  }
  return Math.min(Math.min(10, leftRadius / 2), Math.min(10, rightRadius / 2));
};

const Point = observer(({ i, k, yId, color, axisTitle, editable }: PointProps) => {
  const model = useD3Context();
  const [edit, setEdit] = useState(false);
  const refVisible = useRef<SVGCircleElement | null>(null);
  const refInvisible = useRef<SVGCircleElement | null>(null);

  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
    const yScale = d3
      .scaleLinear()
      .domain([model.YDomains[yId].min, model.YDomains[yId].max])
      .range([model.height - model.padding.bottom, model.padding.top])
      .nice();
    d3.select(refInvisible.current)
      .on("mouseover", function (event: MouseEvent) {
        d3.select(refVisible.current).style("opacity", 1);
      })
      .on("mouseout", function (event) {
        if (editable === undefined || editable === false) {
          d3.select(refVisible.current).style("opacity", 0);
        }
        model.setTooltipState(null);
      })
      .on("click", function (event) {
        if (editable === true) {
          setEdit(true);
        }
      });

    const dragHandler: any = d3
      .drag()
      .on("drag", (event) => {
        model.updatePoint(i, k, yScale.invert(yScale(model.curves[i].points[k].y) + event.dy));
        d3.select(refVisible.current).attr("cy", yScale(model.curves[i].points[k].y));
        d3.select(refInvisible.current).attr("cy", yScale(model.curves[i].points[k].y));
      })
      .on("end", () => {
        model.updateYDomain(yId);
      });

    if (editable) {
      d3.select(refInvisible.current).style("cursor", "ns-resize");
      dragHandler(d3.select(refVisible.current));
      dragHandler(d3.select(refInvisible.current));
    }

    d3.select(refVisible.current).attr("cx", xScale(model.curves[i].points[k].x)).attr("cy", yScale(model.curves[i].points[k].y));
    const radius = calcRadius(
      k > 0 ? { x: xScale(model.curves[i].points[k - 1].x), y: yScale(model.curves[i].points[k - 1].y) } : null,
      { x: xScale(model.curves[i].points[k].x), y: yScale(model.curves[i].points[k].y) },
      k + 1 < model.curves[i].points.length ? { x: xScale(model.curves[i].points[k + 1].x), y: yScale(model.curves[i].points[k + 1].y) } : null
    );

    d3.select(refInvisible.current).attr("cx", xScale(model.curves[i].points[k].x)).attr("cy", yScale(model.curves[i].points[k].y)).attr("r", radius);
  }, [editable, model.YDomains[yId].min, model.YDomains[yId].max, model.xAxis.domain.max, model.xAxis.domain.min, model.currentZoomState]);

  return (
    <g>
      {typeof color === "string" ? (
        <circle
          ref={refVisible}
          r={4}
          fill={color}
          stroke="white"
          strokeWidth={2}
          opacity={editable === true ? 1 : 0}
          clipPath={`url(#clipPath-${model.svgClassName})`}
        ></circle>
      ) : (
        <circle
          ref={refVisible}
          r={4}
          fill={`url(#hatch-${color})`}
          stroke="white"
          strokeWidth={2}
          opacity={editable === true ? 1 : 0}
          clipPath={`url(#clipPath-${model.svgClassName})`}
        ></circle>
      )}
      <circle ref={refInvisible} opacity={0} clipPath={`url(#clipPath-${model.svgClassName})`}></circle>
    </g>
  );
});

export default Point;
export type { PointType };
