import { FC } from "react";
import { ChartContext } from "@okopok/axes_context";
import { Lines } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";

import { MiningValueChartModel } from "./miningValueChartModel";

import cn from "./miningValueChart.module.less";

const YEARS_ONLY = new Set(["x"]);
const TITLES = new Map<string, string>([["y", "Объем добычи, тыс т"]]);
type MiningValueChartProps = {
  chartModel: MiningValueChartModel;
};
const MiningValueChart: FC<MiningValueChartProps> = observer(({ chartModel }) => {
  return (
    <div className={cn.layout}>
      <div className={cn.title}>
        {chartModel.lines
          .slice()
          .reverse()
          .map((line, index) => (
            <div key={index} className={cn.legendItem}>
              {line.color !== null && <div className={cn.circle} style={{ backgroundColor: line.color }} />}
              <div className={cn.value}>{line.title}</div>
            </div>
          ))}
      </div>
      <ChartContext
        axes={useScaleFromLines(
          chartModel.lines,
          0,
          TITLES,
          undefined,
          YEARS_ONLY,
          true,
          new Map([
            [
              "y",
              {
                min: 0,
                max: Math.max(
                  ...chartModel.lines.map((line) =>
                    Math.max(
                      ...line.y.map((y) => {
                        if (y) {
                          return +y;
                        } else {
                          return 1;
                        }
                      })
                    )
                  )
                ),
              },
            ],
          ])
        )}
        className={cn.chart}
      >
        <TooltipContext.Provider value={chartModel.tooltipManager}>
          <Lines data={chartModel.lines} />
          <PointerLine />
        </TooltipContext.Provider>
      </ChartContext>
      <TooltipDiv manager={chartModel.tooltipManager} />
    </div>
  );
});

export { MiningValueChart };
