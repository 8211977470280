import React, { FC } from "react";
import { Typography } from "antd";
import { observer } from "mobx-react";
import { useParamsModel } from "routing/outlines/params/paramsPage";

import { CompleteIcon } from "elements/icons/complete/complete";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { ParamsNode } from "models/params/paramsNode";

import cn from "./scalar.module.less";

const Scalar: FC = observer(() => {
  const { scalar } = useParamsModel();
  return (
    <div className={cn.scalar}>
      {scalar?.children!.map(({ title, key, children }) => (
        <React.Fragment key={key}>
          <Typography.Title level={2}>{title}</Typography.Title>
          {children?.map((subset) => (
            <div className={cn.subset} key={subset.key}>
              <Typography.Title level={3}>
                <CompleteIcon completed={ParamsNode.isCompleted(subset)} />
                {subset.title}
              </Typography.Title>
              <div className={cn.values}>
                {subset.children!.map(({ values, title, unit, key, setScalarValue }) => {
                  console.assert(values.length === 1);
                  return (
                    <div key={key} className={cn.value}>
                      {subset.children!.length > 1 && <Typography.Title level={4}>{title}</Typography.Title>}
                      <InputNumber className={cn.input} onUpdate={setScalarValue} bordered addonAfter={unit.unit} value={values[0] ?? undefined} />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
});

export { Scalar };
