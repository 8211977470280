import { observer } from "mobx-react";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";
import XAxis from "../XAxis/XAxis";
import YAxis from "../YAxis/YAxis";

enum AxisPosition {
  Left,
  Right,
}

const selectTicksNumber = (width: number) => {
  if (width < 250) {
    return 3;
  }
  return (width - 250) / 70 + 3;
};

const Axes = observer(() => {
  const model = useD3Context();
  return (
    <>
      <XAxis />
      {model.yAxesLeft
        .filter((axis) => axis.showAxis ?? true)
        .map((yAxis, key) => {
          return <YAxis key={key} translateX={model.padding.left + (key + 1) * axisWidth} axis={yAxis} position={AxisPosition.Left} />;
        })}
      {model.yAxesRight
        .filter((axis) => axis.showAxis ?? true)
        .map((yAxis, key) => {
          return (
            <YAxis key={key} translateX={model.width - (key + 1) * axisWidth - model.padding.right} axis={yAxis} position={AxisPosition.Right} />
          );
        })}
    </>
  );
});

export default Axes;
export { AxisPosition, selectTicksNumber };
