import { TemplateCard } from "elements/importModalContent/templateInfoCards";

export const INTERVENTIONS_TEMPLATE_INFO_CARDS: TemplateCard[] = [
  {
    widthBasis: 320,
    sections: [
      {
        title: "Скважина",
        content:
          "Скважина, на которой будет проводиться ГТМ, уже должна находиться в списке скважин проекта.\nОбязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 640,
    sections: [
      {
        title: "Стартовый дебит нефти, жидкости, извлекаемые запасы",
        content: "Обязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 368,
    sections: [
      {
        title: "Тип ГТМ",
        content: "Может принимать только значения, перечисленные выше.\nОбязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 185,
    sections: [
      {
        title: "Дата ввода",
        content: "Должна входить в интервал планирования проекта.\nОбязательно для заполнения.",
      },
    ],
  },
  {
    widthBasis: 332,
    sections: [
      {
        title: "Объект разработки",
        content: "Должен соответствовать списку объектов разработки проекта.\nОбязательно для заполнения.",
      },
    ],
  },
];
