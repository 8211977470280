import { useCallback, useState } from "react";

export const useBooleanState = (defaultValue = false) => {
  const [value, setValue] = useState(defaultValue);
  return [
    value,
    useCallback(() => {
      setValue(true);
    }, []),
    useCallback(() => {
      setValue(false);
    }, []),
    useCallback(() => setValue((v) => !v), []),
  ] as const;
};
