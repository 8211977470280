import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { ParamsPage } from "routing/outlines/params/paramsPage";

import type { Params as ParamsModel } from "models/params/params";

type ReferenceModel = {
  referenceParams: {
    get: (key: number) => ParamsModel;
  };
};

const tab = [
  {
    key: "index",
    title: "Параметры по годам",
    hideSums: false,
    isNeedLicense: true,
    model: (data: ReferenceModel, key?: number) => data.referenceParams.get(key!),
  },
];

const Reference: FC = observer(() => {
  return <ParamsPage factTabs={tab} forecastTabs={tab} />;
});

export { Reference };
