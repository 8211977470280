import { FC } from "react";
import { useChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react-lite";

import { BarInfo } from "./barDataModel";

type StackedBarProps = {
  y: BarInfo[];
  x: number;
  axisKey: string;
};

const getBarWidth = (yearLength: number) => {
  return Math.min(40, (2 * yearLength) / 3);
};

const StackedBar: FC<StackedBarProps> = observer(({ axisKey, x, y }) => {
  const axes = useChartContext();
  const accumulate: number[] = [0];
  y.forEach((info) => accumulate.push(info.value + accumulate[accumulate.length - 1]), 0);
  if (axes.isNotReady || !axes.isShapeReady) {
    return null;
  }
  const yScale = axes.at(axisKey)?.scale;
  const xScale = axes.axisX.scale;
  const yearWidth = axes.yearWidth;
  if (yScale === undefined) {
    console.error("Не определён масштаб для отображаемой кривой");
    return null;
  }
  return (
    <>
      {y.map((info, index) => (
        <rect
          key={index}
          x={xScale(x) - getBarWidth(yearWidth) / 2}
          y={yScale(info.value + accumulate[index])}
          width={getBarWidth(yearWidth)}
          height={yScale(0) - yScale(info.value)}
          style={{ fill: info.color }}
        />
      ))}
    </>
  );
});

export { getBarWidth, StackedBar };
