import React, { type FC, useEffect, useMemo } from "react";
import { Table } from "@okopok/components/Table/Table";
import { Button, Collapse, Radio, Typography } from "antd";
import { observer } from "mobx-react";
import { useParamsModel } from "routing/outlines/params/paramsPage";

import { Format } from "elements/format/format";
import { CompleteIcon } from "elements/icons/complete/complete";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { Loader } from "elements/loader";
import { UOM } from "elements/uom";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { ParamsRow } from "features/useMetrics/paramsRow";
import { global } from "models/global";
import { OptionedParam } from "models/params/optionedParams/optionedParam";

import { ScalarTableStore } from "./scalarTableModel";

import cn from "./scalar.module.less";

const CostParam: FC<{ value: OptionedParam }> = observer(({ value }) => {
  const { I18N_MAP, UOM_MAP } = useMemo(() => {
    const I18N_MAP: Record<string, string> = {
      operations: value.isNet ? "Задать значение" : "Число операций",
      net: "Без инфляции",
      base_index: "Базисный индекс инфляции",
      real: "С учетом инфляции",
    };

    const UOM_MAP: Record<string, UOM | undefined> = {
      operations: value.isNet ? new UOM(2, 3, global.uomResolver) : undefined,
      net: new UOM(2, 3, global.uomResolver),
      base_index: new UOM(3, 1, global.uomResolver),
      real: new UOM(2, 3, global.uomResolver),
    };

    return { I18N_MAP, UOM_MAP };
  }, [value.isNet]);

  useEffect(() => {
    value.prepareAutoCalc();
  }, [value]);

  const INPUT_METHOD_OPTIONS = [
    { label: "Значение на скважину", value: "primary" },
    { label: value.isNet ? "Абсолютное значение" : "На скважино-операцию", value: "secondary" },
  ];

  const isEditable = value.isNet || value.isOperations;

  const columns = useMemo<Column[]>(
    () =>
      value
        ? [
            {
              title: "Параметр",
              dataKey: "param",
              type: "string",
              width: 350,
              showEditRowBtn: true,
            },
            {
              title: "Единица измерения",
              dataKey: "measure",
              type: "string",
              width: 100,
            },
            ...[...value.range].map(
              (year): Column => ({
                title: `${year}`,
                dataKey: `${year}`,
                type: "number",
                width: 140,
                editable: isEditable,
                renderToString: (v) => v,
              })
            ),
          ]
        : [],
    [isEditable, value]
  );

  const dataItems = useMemo(() => {
    return (["operations", "net", "base_index", "real"] as const)
      .map((key, index) => {
        if (key === "operations" && !value.isNet && !value.isOperations) {
          return null;
        }

        const val = (() => {
          if (key === "operations") {
            return value.vector;
          }
          if (value.table) {
            return value.table[key];
          }

          return value.table;
        })();

        const rowData: ParamsRow = {
          key: index,
          row: index,
          title: I18N_MAP[key],
          measure: UOM_MAP[key]?.unit || null,
          level: 0,
          values: val,
        };

        return rowData;
      })
      .filter((row): row is ParamsRow => row !== null);
  }, [I18N_MAP, UOM_MAP, value.isNet, value.isOperations, value.table, value.vector]);

  const getTotalRows = (items: ParamsRow[]) => {
    return items.reduce((count: number, item: ParamsRow) => {
      let total = 1;
      if (item.children && item.children.length > 0) {
        total += getTotalRows(item.children);
      }

      return count + total;
    }, 0);
  };

  const lineCount = dataItems ? getTotalRows(dataItems) : 0;

  const data = useMemo(() => (value && new ScalarTableStore(value.range, dataItems)) ?? undefined, [dataItems, value]);

  const isLoading = dataItems && !value.table;

  return (
    <div className={cn.conditionally}>
      <div className={cn.inputs}>
        {(value.isNet || value.secondaryUOM) && (
          <Radio.Group
            className={cn.input}
            options={INPUT_METHOD_OPTIONS}
            onChange={value.setInputMethod}
            value={value.inputMethod}
            optionType="button"
          />
        )}
        <InputNumber
          value={value.primary ?? undefined}
          onUpdate={value.setPrimary}
          className={cn.input}
          addonAfter={
            <>
              {value.primaryUOM.unit}
              <Button
                type="text"
                className={cn.autoCalc}
                disabled={value.autoCalc === undefined || value.autoCalc === value.primary}
                onClick={value.toAutoCalc}
              >
                На основе данных последнего фактического года &nbsp;
                {value.autoCalc !== undefined ? (
                  <>
                    (<Format>{value.autoCalc}</Format> {value.primaryUOM.unit})
                  </>
                ) : (
                  <Format>{undefined}</Format>
                )}
              </Button>
            </>
          }
          bordered
        />
        {value.secondaryUOM && (
          <InputNumber
            placeholder="Для расчета операций по годам"
            value={value.secondary ?? undefined}
            onUpdate={value.setSecondary}
            className={cn.input}
            addonAfter={value.secondaryUOM.unit}
            bordered
          />
        )}
        {value.isNet && <Button onClick={value.fillNetVector}>Заполнить погодовые значения на основе текущего удельного</Button>}
      </div>
      <Collapse
        className={cn.collapse}
        size="small"
        items={[
          {
            key: 1,
            label: "По годам",
            children: isLoading ? (
              <div className={cn.loader}>
                <Loader />
              </div>
            ) : (
              <SimpleTableContext
                exportFileName={value.title}
                columns={columns}
                hideExpandColumn
                data={data}
                tableOptions={{
                  isRowEditable: ({ absoluteIndex }) => {
                    return absoluteIndex === 1;
                  },
                }}
              >
                <div className={cn.simpleTable} style={{ "--line-count": `${lineCount}` } as React.CSSProperties}>
                  <Table headerClassName={cn.tableHeader} className={cn.table} />
                </div>
              </SimpleTableContext>
            ),
          },
        ]}
      />
    </div>
  );
});

const OptionedScalar: FC = observer(() => {
  const optionedScalars = useParamsModel().optionedScalars!;
  if (useParamsModel().optionedScalars === undefined) {
    return <Loader />;
  }
  return (
    <div className={cn.scalar}>
      {optionedScalars.grouped.map((group) => {
        const paramMapper = (param: OptionedParam) => (
          <div key={param.id} className={cn.subset}>
            <Typography.Title level={3}>
              <CompleteIcon completed={param.isCompleted} />
              {param.title}
            </Typography.Title>
            <CostParam value={param} />
          </div>
        );
        if ("children" in group) {
          const { title, children } = group;
          return (
            <React.Fragment key={title}>
              <Typography.Title level={2}>{title}</Typography.Title>
              {children?.map(paramMapper)}
            </React.Fragment>
          );
        }
        return paramMapper(group);
      })}
    </div>
  );
});

export { OptionedScalar };
