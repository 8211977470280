import { FC } from "react";
import { observer } from "mobx-react";

import { useTechForecastModel } from "features/techForecast/useTechForecastModel";
import { AllApproximations, ForecastMode, isVectorApproximation, NEED_K } from "services/back/techForecast/request";

import { UploadChart } from "../legacyInputs/uploadChart";

import { WellsAnalog } from "./analog/analog";
import { DropCurveCoefficientInput } from "./dropCurveCoefficientInput";
import { Typical } from "./typical";

type MethodParamsProps = {
  method: AllApproximations | "displacementCharacteristics" | "wellsAnalog" | "typical" | "excel";
  mode: ForecastMode;
};

const MethodParams: FC<MethodParamsProps> = observer(({ method, mode }) => {
  const model = useTechForecastModel();
  if (method === "wellsAnalog" && mode !== "waterCut") {
    return <WellsAnalog mode={mode} />;
  }

  if (method === "typical" && mode !== "waterCut") {
    return <Typical mode={mode} />;
  }

  if (method === "excel") {
    return (
      <>
        <UploadChart onSave={model.currentForecast.settings[mode].excelHolder} path="/decline-new/parse-excel-forecast">
          Загрузить файл
        </UploadChart>
        <a style={{ textAlign: "right" }} href="/static/xy.xlsx" download="Ручное задание кривой падения.xlsx">
          Скачать шаблон кривой
        </a>
      </>
    );
  }

  if (isVectorApproximation(method)) {
    if (model.currentGroup === "base") {
      return null;
    }
    return (
      <>
        <UploadChart onSave={model.currentForecast.settings[mode].vectorHolder} path="/decline/parse_excel_dc/">
          Загрузить ХВ
        </UploadChart>
        <a
          style={{ textAlign: "right" }}
          href={`/static/${
            {
              dc_water_cut_vol: "dc_water_cut_vol",
              dc_ln_vnf_vol: "dc_ln_vnf_vol",
            }[method]
          }}.xlsx`}
          download="XB.xlsx"
        >
          Скачать шаблон файла с ХВ
        </a>
      </>
    );
  }
  return (
    <>
      {<DropCurveCoefficientInput param="a" mode={mode} />}
      {mode !== "waterCut" && NEED_K.has(method) && <DropCurveCoefficientInput param="k" mode={mode} />}
    </>
  );
});

export { MethodParams };
