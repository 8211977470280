import { Input } from "./types";

const CALCULATE_PREDEFINE: Input = {
  constants: {
    schema: [
      {
        code_title: "Для расчета НДПИ",
        id: 0,
        title: "Для расчета НДПИ",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Выработанность по Ач2 на 2012 год",
        id: 1,
        parent_id: 0,
        title: "Выработанность по Ач2 на 2012 год",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [0],
        visible: {},
      },
      {
        code_title: "Выработанность по ЮВ1/1 на 2012 год",
        id: 2,
        parent_id: 0,
        title: "Выработанность по ЮВ1/1 на 2012 год",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [0],
        visible: {},
      },
      {
        code_title: "Выработанность по БВ6 на 2012 год",
        id: 3,
        parent_id: 0,
        title: "Выработанность по БВ6 на 2012 год",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [0],
        visible: {},
      },
      {
        code_title: "Коэффициент Кндпи",
        id: 4,
        parent_id: 0,
        title: "Коэффициент Кндпи",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [559],
        visible: {},
      },
      {
        code_title: "Коэффициент дисконтирования",
        id: 5,
        title: "Коэффициент дисконтирования",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [15],
        visible: {},
      },
      {
        code_title: "Для расчета выручки",
        id: 6,
        title: "Для расчета выручки",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Коэффициент расчета товарного нефтяного газа",
        id: 8,
        parent_id: 6,
        title: "Коэффициент расчета товарного нефтяного газа",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [0.349441],
        visible: {},
      },
      {
        code_title: "Коэффициент расчета товарной нефти",
        id: 9,
        parent_id: 6,
        title: "Коэффициент расчета товарной нефти",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [0.999991],
        visible: {},
      },
      {
        code_title: "Газовый фактор",
        id: 10,
        parent_id: 6,
        title: "Газовый фактор",
        unit: {
          measure: 1,
          quantity: 10,
        },
        values: [96],
        visible: {},
      },
      {
        code_title: "Удельные расходы. Операционная деятельность",
        id: 11,
        title: "Удельные расходы. Операционная деятельность",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы по добыче нефти и газа, сбору газа попутного",
        id: 12,
        parent_id: 11,
        title: "Удельные расходы по добыче нефти и газа, сбору газа попутного",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы Электроэнергия по извлечению нефти",
        id: 13,
        parent_id: 12,
        title: "Электроэнергия по извлечению нефти",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [641.785],
        visible: {},
      },
      {
        code_title: "Удельные расходы Оплата труда и ЕСН",
        id: 14,
        parent_id: 12,
        title: "Оплата труда и ЕСН",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [20],
        visible: {},
      },
      {
        code_title: "Удельные расходы Подземный ремонт скважин",
        id: 15,
        parent_id: 37,
        title: "Подземный ремонт скважин",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Удельные расходы Капитальный ремонт скважин",
        id: 16,
        parent_id: 37,
        title: "Капитальный ремонт скважин",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Удельные расходы Прочие расходы",
        id: 17,
        parent_id: 12,
        title: "Прочие расходы",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [23],
        visible: {},
      },
      {
        code_title: "Удельные расходы по искусственному воздействию на пласт",
        id: 18,
        parent_id: 11,
        title: "Удельные расходы по искусственному воздействию на пласт",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы Энергия",
        id: 19,
        parent_id: 18,
        title: "Энергия",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [922.634],
        visible: {},
      },
      {
        code_title: "Затраты по повышению нефтеотдачи пластов",
        id: 20,
        parent_id: 18,
        title: "Затраты по повышению нефтеотдачи пластов",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Удельные расходы ГРП",
        id: 21,
        parent_id: 20,
        title: "ГРП",
        unit: {
          measure: 6,
          quantity: 11,
        },
        values: [2500],
        visible: {},
      },
      {
        code_title: "Удельные расходы Прочие методы",
        id: 22,
        parent_id: 20,
        title: "Прочие методы",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [23],
        visible: {},
      },
      {
        code_title: "Удельные расходы Прочие расходы (возд.)",
        id: 23,
        parent_id: 18,
        title: "Прочие расходы",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [23],
        visible: {},
      },
      {
        code_title: "Удельные расходы по транспортировке нефтесодержащей жидкости и газа",
        id: 24,
        parent_id: 11,
        title: "Удельные расходы по транспортировке нефтесодержащей жидкости и газа",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Удельные расходы Энергия (транспорт.)",
        id: 25,
        parent_id: 24,
        title: "Энергия",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [231.215],
        visible: {},
      },
      {
        code_title: "Удельные расходы Прочие расходы (транспорт.)",
        id: 26,
        parent_id: 24,
        title: "Прочие расходы",
        unit: {
          measure: 6,
          quantity: 11,
        },
        values: [23],
        visible: {},
      },
      {
        code_title: "Расходы по технологической подготовке нефти",
        id: 27,
        parent_id: 11,
        title: "Расходы по технологической подготовке нефти",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Удельные расходы Энергия (подготов.)",
        id: 28,
        parent_id: 27,
        title: "Энергия",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [247.789],
        visible: {},
      },
      {
        code_title: "Удельные расходы Вспомогательные материалы",
        id: 29,
        parent_id: 27,
        title: "Вспомогательные материалы",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [175.854],
        visible: {},
      },
      {
        code_title: "Удельные расходы Прочие расходы (подготов.)",
        id: 30,
        parent_id: 27,
        title: "Прочие расходы",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Остальные удельные расходы",
        id: 31,
        parent_id: 11,
        title: "Остальные удельные расходы",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [2],
        visible: {},
      },
      {
        code_title: "Удельные расходы на подготовку и освоение производства (без списания)",
        id: 32,
        parent_id: 31,
        title: "Подготовка и освоение производства (без списания)",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [23],
        visible: {},
      },
      {
        code_title: "Удельные расходы Расходы по транспортировке нефти и газа",
        id: 33,
        parent_id: 31,
        title: "Транспортировка нефти и газа",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [591.324],
        visible: {},
      },
      {
        code_title: "Удельные расходы Общепроизводственные расходы",
        id: 34,
        parent_id: 31,
        title: "Общепроизводственные расходы",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [287.21],
        visible: {},
      },
      {
        code_title: "Удельные расходы Прочие производственные расходы",
        id: 35,
        parent_id: 31,
        title: "Прочие производственные расходы",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [210],
        visible: {},
      },
      {
        code_title: "Удельные расходы Внепроизводственные расходы",
        id: 36,
        parent_id: 31,
        title: "Внепроизводственные расходы",
        unit: {
          measure: 1,
          quantity: 11,
        },
        values: [1819.745],
        visible: {},
      },
      {
        code_title: "Ремонт скважин",
        id: 37,
        parent_id: 12,
        title: "Ремонт скважин",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы. Инвестиционная деятельность",
        id: 38,
        title: "Удельные расходы. Инвестиционная деятельность",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Бурение",
        id: 39,
        parent_id: 38,
        title: "Бурение",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Эксплуатационное бурение",
        id: 40,
        parent_id: 39,
        title: "Эксплуатационное бурение",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы Бурение наклонно-направленных скважин",
        id: 41,
        parent_id: 40,
        title: "Наклонно-направленные скважины",
        unit: {
          measure: 8,
          quantity: 11,
        },
        values: [28.477],
        visible: {},
      },
      {
        code_title: "Удельные расходы Бурение горизонтальных скважин",
        id: 42,
        parent_id: 40,
        title: "Горизонтальные скважины",
        unit: {
          measure: 8,
          quantity: 11,
        },
        values: [37.41],
        visible: {},
      },
      {
        code_title: "Удельные расходы Бурение многоствольных и многозабойных скважин",
        id: 43,
        parent_id: 40,
        title: "Многоствольные и многозабойные скважины",
        unit: {
          measure: 8,
          quantity: 11,
        },
        values: [45.87],
        visible: {},
      },
      {
        code_title: "Удельные расходы Бурение нагнетательных скважин",
        id: 44,
        parent_id: 40,
        title: "Нагнетательные скважины",
        unit: {
          measure: 8,
          quantity: 11,
        },
        values: [27.993],
        visible: {},
      },
      {
        code_title: "Удельные расходы Бурение прочих скважин",
        id: 45,
        parent_id: 40,
        title: "Прочие скважины",
        unit: {
          measure: 8,
          quantity: 11,
        },
        values: [26.939],
        visible: {},
      },
      {
        code_title: "Реконструкция скважин",
        id: 46,
        parent_id: 39,
        title: "Реконструкция скважин",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы Перевод на другой горизонт",
        id: 47,
        parent_id: 46,
        title: "Перевод на другой горизонт",
        unit: {
          measure: 6,
          quantity: 11,
        },
        values: [521],
        visible: {},
      },
      {
        code_title: "Удельные расходы Углубление забоя",
        id: 48,
        parent_id: 46,
        title: "Углубление забоя",
        unit: {
          measure: 6,
          quantity: 11,
        },
        values: [564],
        visible: {},
      },
      {
        code_title: "Удельные расходы Радиальное бурение",
        id: 49,
        parent_id: 46,
        title: "Радиальное бурение",
        unit: {
          measure: 6,
          quantity: 11,
        },
        values: [0],
        visible: {},
      },
      {
        code_title: "Удельные расходы Зарезка боковых (вторых) стволов",
        id: 50,
        parent_id: 46,
        title: "Зарезка боковых (вторых) стволов",
        unit: {
          measure: 6,
          quantity: 11,
        },
        values: [47.469],
        visible: {},
      },
      {
        code_title: "Оборудование, не входящее в сметы строек",
        id: 51,
        parent_id: 38,
        title: "Оборудование, не входящее в сметы строек",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Удельные расходы ОНСС на новый фонд",
        id: 52,
        parent_id: 51,
        title: "ОНСС на новый фонд",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [4.276],
        visible: {},
      },
      {
        code_title: "Удельные расходы ОНСС на поддержку существующего фонда",
        id: 53,
        parent_id: 51,
        title: "ОНСС на поддержку существующего фонда",
        unit: {
          measure: 4,
          quantity: 11,
        },
        values: [0.843],
        visible: {},
      },
      {
        code_title: "Для расчета налога на имущество",
        id: 54,
        title: "Для расчета налога на имущество",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {},
      },
      {
        code_title: "Норма амортизационных отчислений переходящих ОФ",
        id: 55,
        parent_id: 54,
        title: "Норма амортизационных отчислений переходящих ОФ",
        unit: {
          measure: 2,
          quantity: 4,
        },
        values: [7],
        visible: {},
      },
      {
        code_title: "Норма амортизационных отчислений новых ОФ",
        id: 56,
        parent_id: 54,
        title: "Норма амортизационных отчислений новых ОФ",
        unit: {
          measure: 2,
          quantity: 4,
        },
        values: [7],
        visible: {},
      },
      {
        code_title: "Процент незавершенного строительства",
        id: 57,
        parent_id: 54,
        title: "Процент незавершенного строительства",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [50],
        visible: {},
      },
      {
        code_title: "Для расчета НДД",
        id: 58,
        title: "Для расчета НДД",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [null],
        visible: {
          row: {
            only_ndd: true,
          },
        },
      },
      {
        code_title: "Индекс для переносов 1",
        id: 59,
        parent_id: 58,
        title: "Индекс для переносов 1",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [16.3],
        visible: {
          row: {
            only_ndd: true,
          },
        },
      },
      {
        code_title: "Индекс для переносов 2",
        id: 60,
        parent_id: 58,
        title: "Индекс для переносов 2",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [10],
        visible: {
          row: {
            group_ndd: [1, 2, 3, 4],
            only_ndd: true,
          },
        },
      },
      {
        code_title: "Процент затрат, включаемые в состав расходов",
        id: 61,
        parent_id: 58,
        title: "Процент затрат, включаемые в состав расходов",
        unit: {
          measure: 2,
          quantity: 3,
        },
        values: [15],
        visible: {
          row: {
            only_ndd: true,
          },
        },
      },
      {
        code_title: "Год начала промышленной добычи (для 1, 2, 4 групп НДД)",
        id: 62,
        parent_id: 58,
        title: "Год начала промышленной добычи",
        unit: {
          measure: 2,
          quantity: 4,
        },
        values: [0],
        visible: {
          row: {
            ndd_group: [1, 2, 4],
            only_ndd: true,
          },
        },
      },
    ],
  },
  deposit: [
    {
      htr_types: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0,
      ],
      lzs: [
        {
          init_reserves: [
            18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181,
            18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181,
            18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181, 18181,
          ],
          license_zone_id: 3,
          license_zone_title: "",
        },
        {
          init_reserves: [
            365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365,
            365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365, 365,
            365,
          ],
          license_zone_id: 4,
          license_zone_title: "",
        },
      ],
      producing_object_id: 25,
      producing_object_title: "БВ7",
      years: [
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
        2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
        2048, 2049, 2050, 2051, 2052, 2053, 2054,
      ],
    },
    {
      htr_types: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1,
      ],
      lzs: [
        {
          init_reserves: [
            325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325,
            325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325, 325,
            325,
          ],
          license_zone_id: 3,
          license_zone_title: "",
        },
        {
          init_reserves: [
            285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285,
            285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285, 285,
            285,
          ],
          license_zone_id: 4,
          license_zone_title: "",
        },
      ],
      producing_object_id: 35,
      producing_object_title: "Ач2",
      years: [
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
        2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
        2048, 2049, 2050, 2051, 2052, 2053, 2054,
      ],
    },
    {
      htr_types: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      lzs: [
        {
          init_reserves: [
            485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485,
            485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485, 485,
            485,
          ],
          license_zone_id: 3,
          license_zone_title: "",
        },
        {
          init_reserves: [
            785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7,
            785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7,
            785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7, 785.7,
          ],
          license_zone_id: 4,
          license_zone_title: "",
        },
      ],
      producing_object_id: 36,
      producing_object_title: "ЮВ1",
      years: [
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
        2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
        2048, 2049, 2050, 2051, 2052, 2053, 2054,
      ],
    },
    {
      htr_types: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      lzs: [
        {
          init_reserves: [
            452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452,
            452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452, 452,
            452,
          ],
          license_zone_id: 3,
          license_zone_title: "",
        },
        {
          init_reserves: [
            247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247,
            247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247, 247,
            247,
          ],
          license_zone_id: 4,
          license_zone_title: "",
        },
      ],
      producing_object_id: 34,
      producing_object_title: "БВ6",
      years: [
        2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
        2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
        2048, 2049, 2050, 2051, 2052, 2053, 2054,
      ],
    },
  ],
  lz: [
    {
      id: 0,
      name: "ХМН-00498-НЭ",
      schema_invest: {
        schema: [
          {
            code_title: "Год",
            editable: {
              not_editable: true,
            },
            id: 0,
            title: "Год",
            unit: {
              measure: 2,
              quantity: 4,
            },
            values: [
              2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
              2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
              2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {},
          },
          {
            code_title: "Капитальные вложения",
            editable: {
              not_editable: true,
            },
            id: 1,
            parent_id: 44,
            title: "Капитальные вложения",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Бурение скважин",
            editable: {
              not_editable: true,
            },
            id: 2,
            parent_id: 1,
            title: "Бурение скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Поисково-разведочное бурение",
            editable: {},
            id: 3,
            parent_id: 2,
            title: "Поисково-разведочное бурение",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              21.1, 0, 0, 0, 0.025, 62.151, 0, 24.036, 5.833, 394.093, 387.87, 242.027, 52.322, 677.808, 177.753,
              86.874, 338.196, 156.143, 156.143, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Эксплуатационное бурение",
            editable: {
              not_editable: true,
            },
            id: 4,
            parent_id: 2,
            title: "Эксплуатационное бурение",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Добывающие скважины",
            editable: {
              not_editable: true,
            },
            id: 5,
            parent_id: 4,
            title: "Добывающие скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Бурение наклонно-направленных скважин",
            editable: {
              only_fact: true,
            },
            id: 6,
            parent_id: 5,
            title: "Наклонно-направленные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              40.079,
              305.468,
              1540.532,
              1467.935,
              1123.252,
              1165.49,
              740.597,
              963.403,
              1778.151,
              661.559,
              237.173,
              721.082,
              1597.581,
              245.394,
              1016.608,
              1094.118,
              686.699,
              686.699,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Бурение горизонтальных скважин",
            editable: {
              only_fact: true,
            },
            id: 7,
            parent_id: 5,
            title: "Горизонтальные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              70.083,
              0,
              0,
              0,
              223.562,
              1116.134,
              898.355,
              847.362,
              28.341,
              0,
              143.589,
              1718.954,
              57.595,
              603.047,
              1639.423,
              321.972,
              321.972,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Бурение многоствольных и многозабойных скважин",
            editable: {
              only_fact: true,
            },
            id: 8,
            parent_id: 5,
            title: "Многоствольные и многозабойные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              161.545,
              11.534,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Вспомогательные и специальные скважины",
            editable: {
              not_editable: true,
            },
            id: 9,
            parent_id: 4,
            title: "Вспомогательные и специальные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Бурение нагнетательных скважин",
            editable: {
              only_fact: true,
            },
            id: 10,
            parent_id: 9,
            title: "Нагнетательные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              24.017,
              0,
              0,
              0,
              99.801,
              149.793,
              91.201,
              357.946,
              268.84,
              0.14,
              136.072,
              473.148,
              89.037,
              46.817,
              454.471,
              127.467,
              127.467,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Бурение прочих скважин",
            editable: {
              only_fact: true,
            },
            id: 11,
            parent_id: 9,
            title: "Прочие скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              19.726,
              3.042,
              24.781,
              20.62,
              18.64,
              2.23,
              83.146,
              66.325,
              4.636,
              27.284,
              61.465,
              1.226,
              1.226,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Реконструкция скважин",
            editable: {
              not_editable: true,
            },
            id: 12,
            parent_id: 2,
            title: "Реконструкция скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Перевод на другой горизонт",
            editable: {
              only_fact: true,
            },
            id: 13,
            parent_id: 12,
            title: "Перевод на другой горизонт",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              19.044,
              22.908,
              22.439,
              21.84,
              0,
              126.628,
              81.051,
              67.057,
              29.077,
              81.35,
              30.709,
              65.525,
              90.631,
              79.916,
              130.157,
              130.157,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Углубление забоя",
            editable: {
              only_fact: true,
            },
            id: 14,
            parent_id: 12,
            title: "Углубление забоя",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              30.331,
              82.246,
              18.502,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Радиальное бурение",
            editable: {
              only_fact: true,
            },
            id: 15,
            parent_id: 12,
            title: "Радиальное бурение",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Зарезка боковых (вторых) стволов",
            editable: {
              only_fact: true,
            },
            id: 16,
            parent_id: 12,
            title: "Зарезка боковых (вторых) стволов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0.255,
              0.622,
              0.128,
              221.967,
              592.7,
              611.838,
              1040.863,
              1927.667,
              1920.775,
              1911.554,
              567.916,
              454.602,
              993.458,
              1031.14,
              692.645,
              1425.024,
              1231.232,
              1231.232,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Оборудование, не входящее в сметы строек",
            editable: {
              not_editable: true,
            },
            id: 17,
            parent_id: 1,
            title: "Оборудование, не входящее в сметы строек",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "ОНСС на новый фонд",
            editable: {
              only_fact: true,
            },
            id: 18,
            parent_id: 17,
            title: "ОНСС на новый фонд",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              96.573,
              283.907,
              574.534,
              649.257,
              22.196,
              84.708,
              55.011,
              103.97,
              111.446,
              180.481,
              86.613,
              36.794,
              62.353,
              212.24,
              50.784,
              59.632,
              247.848,
              44.539,
              44.539,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "ОНСС на поддержку существующего фонда",
            editable: {
              only_fact: true,
            },
            id: 19,
            parent_id: 17,
            title: "ОНСС на поддержку существующего фонда",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              543.646,
              519.854,
              577.401,
              644.839,
              600.792,
              765.061,
              977.892,
              1214.561,
              1013.628,
              1323.814,
              1259.381,
              1194.939,
              977.225,
              977.225,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "прочее (ОНСС)",
            editable: {},
            id: 20,
            parent_id: 17,
            title: "ОНСС (прочее)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Строительство, всего",
            editable: {
              not_editable: true,
            },
            id: 21,
            parent_id: 1,
            title: "Строительство, всего",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "ПИР",
            editable: {},
            id: 22,
            parent_id: 21,
            title: "ПИР",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.372, 2.464, 5.334, 21.573, 20.934, 17.957, 81.041, 97.745, 94.069, 92.817, 73.51, 102.962, 132.538,
              116.58, 77.725, 55.879, 83.767, 83.648, 83.648, 82.055, 150.451, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "оборудование",
            editable: {},
            id: 23,
            parent_id: 21,
            title: "Оборудование",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 8.38, 62.804, 120.407, 22.334, 64.184, 43.279, 130.601, 145.463, 206.251, 56.72, 23.068, 32.199,
              252.275, 323.607, 479.061, 311.623, 33.472, 33.472, 92.537, 81.399, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "СМР",
            editable: {},
            id: 24,
            parent_id: 21,
            title: "СМР",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 4.1, 104.022, 891.534, 620.836, 913.827, 576.12, 893.429, 1346.336, 742.098, 1166.373, 296.809,
              965.755, 1027.24, 642.199, 1415.92, 1212.717, 593.108, 593.108, 1434.625, 830.988, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "прочие расходы (строительство)",
            editable: {},
            id: 25,
            parent_id: 21,
            title: "Прочие расходы (строительство)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.017, 0.025, 3.176, 16.762, 13.285, 18.614, 38.012, 38.458, 40.893, 11.979, 19.965, 11.112, 22.15,
              30.609, 32.901, 95.244, 64.321, 33.756, 33.756, 78.49, 61.724, 2461.55, 1512.649, 3573.503, 1317.673,
              1493.652, 1210.102, 1414.256, 1475.579, 1422.096, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0,
            ],
            visible: {},
          },
          {
            code_title: "Затраты на геолого-разведочные работы (без развед. бурения)",
            editable: {
              not_editable: true,
            },
            id: 26,
            parent_id: 44,
            title: "Затраты на геолого-разведочные работы (без развед. бурения)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "сейсморазведка 2Д",
            editable: {},
            id: 27,
            parent_id: 26,
            title: "Сейсморазведка 2Д",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "сейсморазведка 3Д",
            editable: {},
            id: 28,
            parent_id: 26,
            title: "Сейсморазведка 3Д",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 78.36, 109.944, 199.946, 109.086, 159.982, 4.955, 0, 0, 0, 0, 0, 13.82, 426.353, 24.406, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 13.82, 426.353, 24.406, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "НТР в ГРР",
            editable: {},
            id: 29,
            parent_id: 26,
            title: "НТР в ГРР",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              13.277, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "другие виды работ",
            editable: {},
            id: 30,
            parent_id: 26,
            title: "Другие виды работ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.465, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Затраты на приобретение лицензий и геолог информации",
            editable: {},
            id: 31,
            parent_id: 44,
            title: "Затраты на приобретение лицензий и геолог. информации",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Прочие инвестиционные расходы",
            editable: {
              not_editable: true,
            },
            id: 32,
            parent_id: 44,
            title: "Прочие инвестиционные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НТР в области разработки месторождения",
            editable: {},
            id: 33,
            parent_id: 32,
            title: "НТР в области разработки месторождения",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Крупные капитальные ремонты",
            editable: {},
            id: 34,
            parent_id: 32,
            title: "Крупные капитальные ремонты",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Горно-подготовительные работы",
            editable: {
              not_editable: true,
            },
            id: 35,
            parent_id: 32,
            title: "Горно-подготовительные работы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "подземное бурение скважин",
            editable: {},
            id: 36,
            parent_id: 35,
            title: "Подземное бурение скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "проходка горных выработок",
            editable: {},
            id: 37,
            parent_id: 35,
            title: "Проходка горных выработок",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "прочие расходы (прочие)",
            editable: {},
            id: 38,
            parent_id: 32,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 6.9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Данные по реконструкции скважин",
            editable: {
              not_editable: true,
            },
            id: 39,
            title: "Данные по реконструкции скважин",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Перевод на другой горизонт, скв.-оп.",
            editable: {},
            id: 40,
            parent_id: 39,
            title: "Перевод на другой горизонт",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2037, 2037, 2038, 2039, 2041, 2041, 2042, 2043, 2044, 2046, 2046, 2047,
              2048, 2049, 2051, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Углубление забоя, скв.-оп.",
            editable: {},
            id: 41,
            parent_id: 39,
            title: "Углубление забоя",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2023, 2024, 2025, 2026, 2027, 2029, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2037, 2037, 2039, 2039, 2040, 2041, 2043, 2043, 2044, 2045, 2046, 2048,
              2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Радиальное бурение, скв.-оп.",
            editable: {},
            id: 42,
            parent_id: 39,
            title: "Радиальное бурение",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
              2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Зарезка боковых (вторых) стволов, скв.-оп.",
            editable: {},
            id: 43,
            parent_id: 39,
            title: "Зарезка боковых (вторых) стволов",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2024, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2041, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
              2049, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Инвестиционные затраты (без НДС), с учетом инфляции",
            editable: {
              not_editable: true,
            },
            id: 44,
            parent_id: 46,
            title: "Инвестиционные затраты (без НДС), с учетом инфляции",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Корректировка инвестиций согласно модели ЕСУ",
            editable: {},
            id: 45,
            parent_id: 44,
            title: "Корректировка инвестиций согласно модели ЕСУ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Денежный поток от инвестиционной деятельности",
            editable: {
              not_editable: true,
            },
            id: 46,
            title: "Денежный поток от инвестиционной деятельности",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Денежный поток от инвестиций в оборотный капитал",
            editable: {
              not_editable: true,
            },
            id: 47,
            title: "Денежный поток от инвестиций в оборотный капитал",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Денежный поток от измен.оборотного капитала по инвест.деят.",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 48,
            parent_id: 47,
            title: "Денежный поток от изменения ОК по инвест. деятельности.",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Авансы по инвест.деят. на конец года без НДС с инфляцией",
            editable: {
              optional: true,
            },
            id: 49,
            parent_id: 47,
            title: "Авансы по инвест. деятельности на конец года без НДС с инфляцией",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
            ],
            visible: {},
          },
          {
            code_title: "Кредиторская задолженность на конец года без НДС с инфляцией",
            editable: {
              dispose: 2,
              only_forecast: true,
            },
            id: 50,
            parent_id: 47,
            title: "Кредиторская задолженность на конец года без НДС с инфляцией",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
            ],
            visible: {},
          },
          {
            code_title: "Прочие изменения оборотного капитала",
            editable: {
              dispose: 1,
              only_forecast: true,
            },
            id: 51,
            parent_id: 47,
            title: "Прочие изменения оборотного капитала",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
            ],
            visible: {},
          },
        ],
      },
      schema_operation: {
        schema: [
          {
            code_title: "Год",
            editable: {
              not_editable: true,
            },
            id: 0,
            title: "Год",
            unit: {
              measure: 2,
              quantity: 4,
            },
            values: [
              2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
              2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
              2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {},
          },
          {
            code_title: "Операционные расходы (с инфляцией)",
            editable: {
              not_editable: true,
            },
            id: 1,
            parent_id: 79,
            title: "Операционные расходы (с инфляцией)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Расходы на подготовку и освоение производства (без списания)",
            editable: {
              only_fact: true,
            },
            id: 2,
            parent_id: 1,
            title: "Расходы на подготовку и освоение производства (без списания)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              15.795,
              41.401,
              45.388,
              65.685,
              79.867,
              67.615,
              88.444,
              101.402,
              89.139,
              133.409,
              151.764,
              65.819,
              63.87,
              71.471,
              53.728,
              74.47,
              60.495,
              20.768,
              20.768,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по добыче нефти и газа, сбор газа попутного",
            editable: {
              not_editable: true,
            },
            id: 3,
            parent_id: 1,
            title: "Расходы по добыче нефти и газа, сбор газа попутного",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Электроэнергия по извлечению нефти",
            editable: {
              only_fact: true,
            },
            id: 4,
            parent_id: 3,
            title: "Электроэнергия по извлечению нефти",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              203.933,
              251.165,
              316.592,
              420.655,
              472.663,
              807.535,
              1045.362,
              1277.778,
              1319.105,
              1182.236,
              1140.782,
              871.323,
              1024.788,
              1048.553,
              1074.04,
              1068.146,
              1085.593,
              1288.077,
              1288.077,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Оплата труда и ЕСН",
            editable: {
              only_fact: true,
            },
            id: 5,
            parent_id: 3,
            title: "Оплата труда и ЕСН",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              90.525,
              103.934,
              107.653,
              146.893,
              145.098,
              158.683,
              167.038,
              192.948,
              204.382,
              178.995,
              196.804,
              233.191,
              257.576,
              285.405,
              304.68,
              294.162,
              311.193,
              318.609,
              318.609,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Ремонт скважин",
            editable: {
              not_editable: true,
            },
            id: 6,
            parent_id: 3,
            title: "Ремонт скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Подземный ремонт скважин",
            editable: {
              only_fact: true,
            },
            id: 7,
            parent_id: 6,
            title: "Подземный ремонт скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              344.264,
              450.281,
              532.678,
              580.451,
              578.552,
              587.816,
              565.827,
              471.53,
              500.713,
              495.323,
              537.634,
              519.646,
              519.435,
              456.539,
              421.483,
              347.183,
              323.404,
              317.548,
              317.548,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Капитальный ремонт скважин",
            editable: {
              only_fact: true,
            },
            id: 8,
            parent_id: 6,
            title: "Капитальный ремонт скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              167.088,
              192.307,
              285.024,
              94.835,
              127.715,
              162.43,
              924.729,
              995.272,
              1003,
              1172.476,
              956.269,
              1298.696,
              1198.076,
              963.837,
              932.627,
              858.714,
              536.793,
              515.597,
              515.597,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы",
            editable: {
              only_fact: true,
            },
            id: 9,
            parent_id: 3,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              820.89,
              941.61,
              1080.345,
              733.069,
              642.346,
              564.062,
              682.988,
              769.896,
              867.534,
              1028.111,
              1166.969,
              1247.723,
              1208.694,
              1237.286,
              1176.519,
              1103.104,
              989.523,
              1451.792,
              1451.792,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по искусственному воздействию на пласт",
            editable: {
              not_editable: true,
            },
            id: 10,
            parent_id: 1,
            title: "Расходы по искусственному воздействию на пласт",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Энергия",
            editable: {
              only_fact: true,
            },
            id: 11,
            parent_id: 10,
            title: "Энергия",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              104.274,
              118.999,
              136.927,
              9.31,
              249.846,
              386.902,
              435.523,
              500.307,
              511.548,
              533.996,
              556.526,
              530.243,
              593.78,
              591.236,
              576.503,
              596.763,
              619.339,
              651.755,
              651.755,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Затраты по повышению нефтеотдачи пластов",
            editable: {
              not_editable: true,
            },
            id: 12,
            parent_id: 10,
            title: "Затраты по повышению нефтеотдачи пластов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "ГРП",
            editable: {
              only_fact: true,
            },
            id: 13,
            parent_id: 12,
            title: "ГРП",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              1285.522,
              1314.388,
              1285.522,
              422.659,
              471.075,
              693.326,
              581.347,
              598.135,
              674.507,
              515.301,
              402.697,
              501.653,
              339.993,
              191.582,
              233.057,
              233.057,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие методы",
            editable: {
              only_fact: true,
            },
            id: 14,
            parent_id: 12,
            title: "Прочие методы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              484.067,
              882.1,
              1522.021,
              65.575,
              270.879,
              77.055,
              116.178,
              106.988,
              140.486,
              280.197,
              233.286,
              372.362,
              527.614,
              695.319,
              634.654,
              655.203,
              550.938,
              560.996,
              560.996,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы (возд.)",
            editable: {
              only_fact: true,
            },
            id: 15,
            parent_id: 10,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              201.461,
              319.816,
              376.11,
              733.358,
              427.366,
              465.43562323,
              816.45405323,
              530.24441813,
              606.73593985,
              746.09207288,
              723.81333959,
              688.79159281,
              771.41013394,
              888.39980701,
              1020.87114713,
              995.79202283,
              919.1370967,
              1050.65437568883,
              1050.65437568883,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по транспортировке нефтесодержащей жидкости и газа",
            editable: {
              not_editable: true,
            },
            id: 16,
            parent_id: 1,
            title: "Расходы по транспортировке нефтесодержащей жидкости и газа",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Энергия (транспорт.)",
            editable: {
              only_fact: true,
            },
            id: 17,
            parent_id: 16,
            title: "Энергия",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              13.174,
              14.329,
              22.073,
              85.687,
              96.378,
              149.27638834,
              163.06399701,
              170.97172348,
              190.00243655,
              292.28820355,
              395.98666312,
              434.64982277,
              325.47556361,
              400.42802437,
              347.73079021,
              353.33322985,
              317.81046016,
              249.80978665,
              249.80978665,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы (транспорт.)",
            editable: {
              only_fact: true,
            },
            id: 18,
            parent_id: 16,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              286.646,
              230.89,
              220.209,
              206.898,
              276.747,
              306.32867201,
              307.68079293,
              332.24919965,
              342.17608948,
              344.38662439,
              363.96275958,
              402.47101009,
              380.01002642,
              429.65416261,
              453.83945388,
              474.81138484,
              441.75310205,
              455.403770197894,
              455.403770197894,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по технологической подготовке нефти",
            editable: {
              not_editable: true,
            },
            id: 19,
            parent_id: 1,
            title: "Расходы по технологической подготовке нефти",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Энергия (подготов.)",
            editable: {
              only_fact: true,
            },
            id: 20,
            parent_id: 19,
            title: "Энергия",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              10.068,
              10.97,
              11.88,
              45.766,
              66.504,
              47.57075233,
              24.03219284,
              24.99813882,
              24.4970258,
              42.24591471,
              63.89950308,
              115.94821388,
              91.03120225,
              52.93804825,
              51.01049101,
              56.25180209,
              57.68235083,
              54.89353758,
              54.89353758,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Вспомогательные материалы",
            editable: {
              only_fact: true,
            },
            id: 21,
            parent_id: 19,
            title: "Вспомогательные материалы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              56.075,
              86.057,
              106.563,
              15.572,
              15.814,
              13.693,
              11.222,
              6.61,
              8.808,
              9.616,
              9.143,
              9.315,
              8.403,
              8.248,
              7.733,
              7.346,
              9.982,
              9.533,
              9.533,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы (подготов.)",
            editable: {
              only_fact: true,
            },
            id: 22,
            parent_id: 19,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              42.755,
              63.326,
              65.548,
              186.366,
              184.406,
              250.02163132,
              167.04821229,
              200.27717814,
              196.42640717,
              168.98063132,
              189.10933967,
              208.78837104,
              226.6525198,
              274.65738705,
              301.75462341,
              325.78329083,
              324.20638188,
              333.504716842458,
              333.504716842458,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по транспортировке нефти и газа",
            editable: {
              only_fact: true,
            },
            id: 23,
            parent_id: 1,
            title: "Расходы по транспортировке нефти и газа",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Общепроизводственные расходы",
            editable: {
              only_fact: true,
            },
            id: 24,
            parent_id: 1,
            title: "Общепроизводственные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              841.101,
              888.601,
              995.533,
              908.071,
              1256.611,
              1224.875,
              1234.247,
              1220.173,
              1253.232,
              1609.416,
              1527.76,
              1633.035,
              1704.868,
              1830.358,
              1818.455,
              1814.557,
              1808.046,
              2066.665,
              2066.665,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие производственные расходы",
            editable: {
              only_fact: true,
            },
            id: 25,
            parent_id: 1,
            title: "Прочие производственные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              16.517,
              17.704,
              19.684,
              32.242,
              71.779,
              87.952,
              76.592,
              57.48,
              62.727,
              62.487,
              64.857,
              63.114,
              67.552,
              65.015,
              69.724,
              71.227,
              77.711,
              81.294,
              81.294,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Внепроизводственные расходы",
            editable: {
              only_fact: true,
            },
            id: 26,
            parent_id: 1,
            title: "Внепроизводственные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              29.641,
              34.945,
              31.352,
              47.901,
              55.731,
              50.164,
              83.719,
              95.167,
              96.006,
              56.816,
              55.167,
              55.216,
              55.689,
              57.756,
              58.259,
              57.046,
              58.156,
              57.674,
              57.674,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Налоги",
            editable: {
              not_editable: true,
            },
            id: 27,
            parent_id: 79,
            title: "Налоги",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НДПИ нефть ТРИЗ",
            editable: {
              only_before_ndd: true,
              only_fact: true,
              optional: true,
              start_from: 2013,
            },
            id: 28,
            parent_id: 27,
            title: "НДПИ нефть ТРИЗ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "НДПИ нефть всего",
            editable: {
              only_fact: true,
              optional: true,
            },
            id: 29,
            parent_id: 27,
            title: "НДПИ нефть",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              5921.809,
              10925.449,
              13806.289,
              15105.63,
              19264.003,
              12440.034,
              16320.751,
              23959.387,
              26984.414,
              28557.609,
              30658.831,
              30560.996,
              24705.518,
              31761.817,
              45385.681,
              42806.804,
              26638.868,
              29178.772,
              29178.772,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "НДД",
            editable: {
              only_fact: true,
              only_since_ndd: true,
              optional: true,
            },
            id: 30,
            parent_id: 27,
            title: "НДД",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              20256.906,
              20256.906,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Реальные ставки налога",
            editable: {
              not_editable: true,
            },
            id: 31,
            title: "Реальные ставки налога",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Налог на прибыль (реальная), %",
            editable: {
              dispose: -1,
              only_fact: true,
              optional: true,
            },
            id: 32,
            parent_id: 31,
            title: "Налог на прибыль (реальная ставка)",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Налог на имущество (реальная), %",
            editable: {
              dispose: -1,
              only_fact: true,
              optional: true,
            },
            id: 33,
            parent_id: 31,
            title: "Налог на имущество (реальная ставка)",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Стоимость имущества на месторождении",
            editable: {
              not_editable: true,
            },
            id: 34,
            title: "Стоимость имущества на месторождении",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Ввод основных фондов",
            editable: {
              dispose: 1,
              only_fact: true,
            },
            id: 35,
            parent_id: 34,
            title: "Ввод основных фондов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              96.201,
              12.314,
              233.533,
              1578.191,
              2355.648,
              3793.33,
              3366.413,
              3550.191,
              5269.089,
              6932.948,
              6197.964,
              3019.827,
              3289.079,
              7804.791,
              4918.03,
              5535.91,
              6765.041,
              3846.634,
              3846.634,
              4987.779,
              8304.944,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Вывод/списание основных фондов",
            editable: {
              dispose: 1,
              only_fact: true,
            },
            id: 36,
            parent_id: 34,
            title: "Вывод/списание основных фондов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              59.741,
              279.254,
              5.076,
              93.99,
              76.07,
              252.938,
              106.43,
              97.176,
              89.141,
              67.111,
              85.539,
              105.858,
              34.47,
              83.769,
              89.463,
              90.917,
              0,
              313.089,
              313.089,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Балансовая стоимость имущества",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 37,
            parent_id: 34,
            title: "Балансовая стоимость имущества",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              5159.823,
              6503.048,
              6479.431,
              8159.897,
              10425.608,
              13273.527,
              16067.352,
              21926.096,
              28254.991,
              36790.3,
              36239.929,
              39663.683,
              30141.019,
              43649.32,
              50101.455,
              53927.03,
              6765.041,
              65793.274,
              65793.274,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Незавершенное строительство",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 38,
            parent_id: 34,
            title: "Незавершенное строительство",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              24.798,
              129.442,
              495.807,
              1494.319,
              1667.072,
              1425.734,
              853.701,
              1541.101,
              1914.801,
              2320.17,
              1536.04,
              1052.654,
              1781.858,
              2616.166,
              1524.345,
              1939.69,
              1958.029,
              1771.868,
              1771.868,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Амортизационные отчисления на добычу нефти и газа",
            editable: {
              dispose: 1,
              only_fact: true,
            },
            id: 39,
            parent_id: 34,
            title: "Амортизационные отчисления на добычу нефти и газа",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              217.631,
              328.959,
              294.096,
              400.79,
              721.78,
              780.362,
              1245.034,
              1916.152,
              2478.763,
              3295.039,
              3338.522,
              4040.504,
              2427.3,
              3748.72,
              4472.613,
              4543.923,
              5646.187,
              5361.735,
              5361.735,
              5911.254,
              6623.794,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Остаточная стоимость имущества",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 40,
            parent_id: 34,
            title: "Остаточная стоимость имущества",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              1264.236,
              1672.473,
              1767.226,
              3286.198,
              5366.645,
              9477.406,
              11669.488,
              13427.39,
              15581.47,
              20968.341,
              19984.549,
              19201.149,
              12818.81,
              20507.689,
              22480.248,
              21679.151,
              6765.041,
              21717.967,
              21717.967,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Корректировка налога на имущество",
            editable: {
              not_editable: true,
            },
            id: 41,
            title: "Корректировка налога на имущество",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Снижение остаточной стоимости (кор-ка налога на имущество)",
            editable: {
              optional: true,
              start_from: 2018,
            },
            id: 42,
            parent_id: 41,
            title: "Снижение остаточной стоимости",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {},
          },
          {
            code_title: "Снижение НЗС (кор-ка налога на имущество)",
            editable: {
              optional: true,
            },
            id: 43,
            parent_id: 41,
            title: "Снижение НЗС (корректировка налога на имущество)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Снижение кап. вложений (кор-ка налога на имущество)",
            editable: {
              dispose: -1,
              only_forecast: true,
              optional: true,
            },
            id: 44,
            parent_id: 41,
            title: "Снижение капитальных вложений",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.2,
              0.2,
              0.2,
              0.2,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Расчет. Налог на прибыль",
            editable: {},
            id: 45,
            title: "Расчет. Налог на прибыль",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Списание стоимости лицензии (нал.уч)",
            editable: {},
            id: 46,
            parent_id: 45,
            title: "Списание стоимости лицензии",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6.356, 6.433, 6.805, 6.907, 6.543, 6.128, 5.64, 0, 0, 6.712, 4.726, 4.514,
              4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379,
              4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379,
            ],
            visible: {},
          },
          {
            code_title: "Списание затрат на ГРР (без разведочного бурения) (нал.уч)",
            editable: {},
            id: 47,
            parent_id: 45,
            title: "Списание затрат на ГРР (без разведочного бурения)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26.243, 22.166, 23.007, 18.389, 30.632, 34.768, 32.481, 29.705, 29.705, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Снижение НЗС (кор-ка налога на прибыль)",
            editable: {
              optional: true,
            },
            id: 48,
            parent_id: 45,
            title: "Снижение НЗС (корректировка налога на прибыль)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Списание прочих затрат",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 49,
            parent_id: 45,
            title: "Списание прочих затрат",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Списание затрат на зарезку боковых стволов (нал.уч)",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 50,
            parent_id: 45,
            title: "Списание затрат на зарезку боковых стволов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              1425.024,
              1231.232,
              1231.232,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Налоговое окружение НДД Актива",
            editable: {
              not_editable: true,
            },
            id: 51,
            title: "Налоговое окружение НДД Актива",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Отрицательный дополнительный доход до списания убытков",
            editable: {},
            id: 52,
            parent_id: 51,
            title: "Отрицательный дополнительный доход до списания убытков",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Списание убытков",
            editable: {},
            id: 53,
            parent_id: 51,
            title: "Списание убытков",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Применение налогового вычета НДПИ (НДД) III гр. по ВЗ",
            editable: {
              only_since_ndd: true,
            },
            id: 54,
            parent_id: 51,
            title: "Применение налогового вычета НДПИ (НДД) по ВЗ",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
              row: {
                ndd_group: [3],
                only_ndd: true,
              },
            },
          },
          {
            code_title: "НДД по минимальной налоговой базе",
            editable: {
              only_since_ndd: true,
            },
            id: 55,
            parent_id: 51,
            title: "НДД по минимальной налоговой базе",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Коэффициент удельных расходов НДД, руб./т",
            editable: {
              only_since_ndd: true,
              optional: true,
            },
            id: 56,
            parent_id: 51,
            title: "Коэффициент удельных расходов НДД",
            unit: {
              measure: 1,
              quantity: 11,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              7140,
              7568,
              8675,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Индикативный тариф на трансп. нефти (НДД), долл./т",
            editable: {
              only_since_ndd: true,
            },
            id: 57,
            parent_id: 51,
            title: "Индикативный тариф на транспортировку нефти",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              39.005,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Инвестиции (внутригрупповой оборот)",
            editable: {
              only_since_ndd: true,
            },
            id: 58,
            parent_id: 51,
            title: "Инвестиции (внутригрупповой оборот)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              1,
              1,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Закачка попутного газа в пласт, млн.н.м3",
            editable: {
              only_since_ndd: true,
            },
            id: 59,
            parent_id: 51,
            title: "Закачка попутного газа в пласт",
            unit: {
              measure: 4,
              quantity: 7,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Прогноз расходов на инфр. объекты для учета в НБ НДД",
            editable: {
              dispose: 1,
              only_forecast: true,
              only_since_ndd: true,
            },
            id: 60,
            parent_id: 51,
            title: "Прогноз расходов на инфраструктурные объекты для учета в НБ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Начальные извлекаемые запасы",
            editable: {
              not_editable: true,
            },
            id: 61,
            title: "Начальные извлекаемые запасы",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1+B2 Ач2 (на нач. г.)",
            editable: {
              only_before_ndd: true,
              start_from: 2013,
            },
            id: 62,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1+B2 Ач2 на начало года",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 888, 888, 888, 888, 888, 888, 888, 888, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1+B2 ЮВ1/1 (на нач. г.)",
            editable: {
              only_before_ndd: true,
              start_from: 2013,
            },
            id: 63,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1+B2 ЮВ1/1 на начало года",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 18161, 18161, 18161, 18161, 18161, 18161, 18161, 18161, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1+B2 БВ6 (на нач. г.)",
            editable: {
              only_before_ndd: true,
              start_from: 2013,
            },
            id: 64,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1+B2 БВ6 на начало года",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 4581, 4581, 4581, 4581, 4581, 4581, 4581, 4581, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1/С1 (на нач. г.)",
            editable: {
              only_since_ndd: true,
            },
            id: 65,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1/С1 на начало года",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Извлекаемые запасы нефти B2/C2 (на нач. г.)",
            editable: {
              only_since_ndd: true,
            },
            id: 66,
            parent_id: 61,
            title: "Извлекаемые запасы нефти B2/C2 на начало года",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Остаточные извлекаемые запасы второго месторождения на ЛУ",
            editable: {},
            id: 96,
            parent_id: 61,
            title: "Остаточные извлекаемые запасы второго месторождения на ЛУ",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Остаточные извлекаемые запасы проектного месторождения на ЛУ",
            editable: {},
            id: 97,
            parent_id: 61,
            title: "Остаточные извлекаемые запасы проектного месторождения на ЛУ",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
              1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            ],
            visible: {},
          },
          {
            code_title: "Выручка всего (без налогов и транспорта), млн руб.",
            editable: {
              not_editable: true,
            },
            id: 67,
            parent_id: 79,
            title: "Выручка",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Затраты на внешний транспорт (нефть)",
            editable: {},
            id: 68,
            parent_id: 67,
            title: "Затраты на внешний транспорт (нефть)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
              0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
              0.5, 0.5, 0.5, 0.5, 0.5,
            ],
            visible: {},
          },
          {
            code_title: "Реализация нефти по направлению 2",
            editable: {},
            id: 69,
            parent_id: 67,
            title: "Реализация нефти по направлению 2",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
              100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
              100, 100, 100, 100, 100, 100, 100, 100,
            ],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Реализация нефти по направлению 3",
            editable: {},
            id: 70,
            parent_id: 67,
            title: "Реализация нефти по направлению 3",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                direction_group: [1, 2, 3],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть, на которую распространяется льгота по ЭП",
            editable: {
              only_before_ndd: true,
            },
            id: 71,
            parent_id: 84,
            title: "Товарная нефть, на которую распространяется льгота по ЭП",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              200,
              200,
              200,
              200,
              200,
              200,
              200,
              200,
              200,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочая выручка",
            editable: {},
            id: 72,
            parent_id: 67,
            title: "Прочая выручка",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2,
              0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2,
              0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2,
            ],
            visible: {},
          },
          {
            code_title: "Количество ГРП",
            editable: {},
            id: 78,
            title: "Количество ГРП",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 8, 8, 10, 8, 9, 8, 6, 6, 6, 6, 6, 6, 6, 6, 6,
              5, 5, 5, 5, 5, 4, 4, 4, 4, 3, 3, 1, 1, 1, 1,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Денежный поток от операционной деятельности",
            editable: {
              not_editable: true,
            },
            id: 79,
            title: "Денежный поток от операционной деятельности",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Выручка всего (без налогов и транспорта) за нефть, включая льготы по ЭП",
            editable: {
              not_editable: true,
            },
            id: 80,
            parent_id: 67,
            title: "Нефть, включая льготы по ЭП",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Льгота от ЭП",
            editable: {
              not_editable: true,
            },
            id: 81,
            parent_id: 80,
            title: "Льготы от ЭП",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Выручка всего (без налогов и транспорта) за нефтяной газ",
            editable: {
              not_editable: true,
            },
            id: 82,
            parent_id: 67,
            title: "Нефтяной газ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Добыча товарных УВ",
            editable: {
              not_editable: true,
            },
            id: 83,
            title: "Добыча товарных УВ",
            unit: {
              measure: 6,
              quantity: 5,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Товарная нефть",
            editable: {
              not_editable: true,
            },
            id: 84,
            parent_id: 83,
            title: "Товарная нефть",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "По направлениям",
            editable: {
              not_editable: true,
            },
            id: 85,
            parent_id: 84,
            title: "По направлениям",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть направление 1",
            editable: {
              not_editable: true,
            },
            id: 86,
            parent_id: 85,
            title: "Направление 1",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть направление 2",
            editable: {
              not_editable: true,
            },
            id: 87,
            parent_id: 85,
            title: "Направление 2",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть направление 3",
            editable: {
              not_editable: true,
            },
            id: 88,
            parent_id: 85,
            title: "Направление 3",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2, 3],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарный нефтяной газ",
            editable: {
              not_editable: true,
            },
            id: 89,
            parent_id: 83,
            title: "Товарный нефтяной газ",
            unit: {
              measure: 5,
              quantity: 7,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Налог на прибыль",
            editable: {
              not_editable: true,
            },
            id: 90,
            parent_id: 27,
            title: "Налог на прибыль",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НДПИ (НДД)",
            editable: {
              not_editable: true,
            },
            id: 91,
            parent_id: 27,
            title: "НДПИ (НДД)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "НДПИ (нефть) для специальных льгот",
            editable: {
              not_editable: true,
            },
            id: 92,
            parent_id: 29,
            title: "НДПИ нефть для специальных льгот",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Добыча нефти на кот. распростр. спец. льготы по НДПИ",
            editable: {
              only_before_ndd: true,
              start_from: 2014,
            },
            id: 93,
            parent_id: 84,
            title: "Добыча нефти, на которую распространяются спец. льготы по НДПИ",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Уровень использования нефтяного газа",
            editable: {},
            id: 95,
            parent_id: 67,
            title: "Уровень использования нефтяного газа",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96,
              96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96,
            ],
            visible: {},
          },
        ],
      },
    },
    {
      id: 1,
      name: "ХМН-00497-НЭ",
      props: {
        ndd_group: 3,
        ndd_year: 2021,
      },
      schema_invest: {
        schema: [
          {
            code_title: "Год",
            editable: {
              not_editable: true,
            },
            id: 0,
            title: "Год",
            unit: {
              measure: 2,
              quantity: 4,
            },
            values: [
              2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
              2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
              2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {},
          },
          {
            code_title: "Капитальные вложения",
            editable: {
              not_editable: true,
            },
            id: 1,
            parent_id: 44,
            title: "Капитальные вложения",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Бурение скважин",
            editable: {
              not_editable: true,
            },
            id: 2,
            parent_id: 1,
            title: "Бурение скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Поисково-разведочное бурение",
            editable: {},
            id: 3,
            parent_id: 2,
            title: "Поисково-разведочное бурение",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              21.1, 0, 0, 0, 0.025, 62.151, 0, 24.036, 5.833, 394.093, 387.87, 242.027, 52.322, 677.808, 177.753,
              86.874, 338.196, 156.143, 156.143, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Эксплуатационное бурение",
            editable: {
              not_editable: true,
            },
            id: 4,
            parent_id: 2,
            title: "Эксплуатационное бурение",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Добывающие скважины",
            editable: {
              not_editable: true,
            },
            id: 5,
            parent_id: 4,
            title: "Добывающие скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Бурение наклонно-направленных скважин",
            editable: {
              only_fact: true,
            },
            id: 6,
            parent_id: 5,
            title: "Наклонно-направленные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              40.079,
              305.468,
              1540.532,
              1467.935,
              1123.252,
              1165.49,
              740.597,
              963.403,
              1778.151,
              661.559,
              237.173,
              721.082,
              1597.581,
              245.394,
              1016.608,
              1094.118,
              686.699,
              686.699,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Бурение горизонтальных скважин",
            editable: {
              only_fact: true,
            },
            id: 7,
            parent_id: 5,
            title: "Горизонтальные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              70.083,
              0,
              0,
              0,
              223.562,
              1116.134,
              898.355,
              847.362,
              28.341,
              0,
              143.589,
              1718.954,
              57.595,
              603.047,
              1639.423,
              321.972,
              321.972,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Бурение многоствольных и многозабойных скважин",
            editable: {
              only_fact: true,
            },
            id: 8,
            parent_id: 5,
            title: "Многоствольные и многозабойные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              161.545,
              11.534,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Вспомогательные и специальные скважины",
            editable: {
              not_editable: true,
            },
            id: 9,
            parent_id: 4,
            title: "Вспомогательные и специальные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Бурение нагнетательных скважин",
            editable: {
              only_fact: true,
            },
            id: 10,
            parent_id: 9,
            title: "Нагнетательные скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              24.017,
              0,
              0,
              0,
              99.801,
              149.793,
              91.201,
              357.946,
              268.84,
              0.14,
              136.072,
              473.148,
              89.037,
              46.817,
              454.471,
              127.467,
              127.467,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Бурение прочих скважин",
            editable: {
              only_fact: true,
            },
            id: 11,
            parent_id: 9,
            title: "Прочие скважины",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              19.726,
              3.042,
              24.781,
              20.62,
              18.64,
              2.23,
              83.146,
              66.325,
              4.636,
              27.284,
              61.465,
              1.226,
              1.226,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Реконструкция скважин",
            editable: {
              not_editable: true,
            },
            id: 12,
            parent_id: 2,
            title: "Реконструкция скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Перевод на другой горизонт",
            editable: {
              only_fact: true,
            },
            id: 13,
            parent_id: 12,
            title: "Перевод на другой горизонт",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              19.044,
              22.908,
              22.439,
              21.84,
              0,
              126.628,
              81.051,
              67.057,
              29.077,
              81.35,
              30.709,
              65.525,
              90.631,
              79.916,
              130.157,
              130.157,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Углубление забоя",
            editable: {
              only_fact: true,
            },
            id: 14,
            parent_id: 12,
            title: "Углубление забоя",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              30.331,
              82.246,
              18.502,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Радиальное бурение",
            editable: {
              only_fact: true,
            },
            id: 15,
            parent_id: 12,
            title: "Радиальное бурение",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Зарезка боковых (вторых) стволов",
            editable: {
              only_fact: true,
            },
            id: 16,
            parent_id: 12,
            title: "Зарезка боковых (вторых) стволов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0.255,
              0.622,
              0.128,
              221.967,
              592.7,
              611.838,
              1040.863,
              1927.667,
              1920.775,
              1911.554,
              567.916,
              454.602,
              993.458,
              1031.14,
              692.645,
              1425.024,
              1231.232,
              1231.232,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Оборудование, не входящее в сметы строек",
            editable: {
              not_editable: true,
            },
            id: 17,
            parent_id: 1,
            title: "Оборудование, не входящее в сметы строек",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "ОНСС на новый фонд",
            editable: {
              only_fact: true,
            },
            id: 18,
            parent_id: 17,
            title: "ОНСС на новый фонд",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              96.573,
              283.907,
              574.534,
              649.257,
              22.196,
              84.708,
              55.011,
              103.97,
              111.446,
              180.481,
              86.613,
              36.794,
              62.353,
              212.24,
              50.784,
              59.632,
              247.848,
              44.539,
              44.539,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "ОНСС на поддержку существующего фонда",
            editable: {
              only_fact: true,
            },
            id: 19,
            parent_id: 17,
            title: "ОНСС на поддержку существующего фонда",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              543.646,
              519.854,
              577.401,
              644.839,
              600.792,
              765.061,
              977.892,
              1214.561,
              1013.628,
              1323.814,
              1259.381,
              1194.939,
              977.225,
              977.225,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "прочее (ОНСС)",
            editable: {},
            id: 20,
            parent_id: 17,
            title: "ОНСС (прочее)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Строительство, всего",
            editable: {
              not_editable: true,
            },
            id: 21,
            parent_id: 1,
            title: "Строительство, всего",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "ПИР",
            editable: {},
            id: 22,
            parent_id: 21,
            title: "ПИР",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.372, 2.464, 5.334, 21.573, 20.934, 17.957, 81.041, 97.745, 94.069, 92.817, 73.51, 102.962, 132.538,
              116.58, 77.725, 55.879, 83.767, 83.648, 83.648, 82.055, 150.451, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "оборудование",
            editable: {},
            id: 23,
            parent_id: 21,
            title: "Оборудование",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 8.38, 62.804, 120.407, 22.334, 64.184, 43.279, 130.601, 145.463, 206.251, 56.72, 23.068, 32.199,
              252.275, 323.607, 479.061, 311.623, 33.472, 33.472, 92.537, 81.399, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "СМР",
            editable: {},
            id: 24,
            parent_id: 21,
            title: "СМР",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 4.1, 104.022, 891.534, 620.836, 913.827, 576.12, 893.429, 1346.336, 742.098, 1166.373, 296.809,
              965.755, 1027.24, 642.199, 1415.92, 1212.717, 593.108, 593.108, 1434.625, 830.988, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "прочие расходы (строительство)",
            editable: {},
            id: 25,
            parent_id: 21,
            title: "Прочие расходы (строительство)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.017, 0.025, 3.176, 16.762, 13.285, 18.614, 38.012, 38.458, 40.893, 11.979, 19.965, 11.112, 22.15,
              30.609, 32.901, 95.244, 64.321, 33.756, 33.756, 78.49, 61.724, 2461.55, 1512.649, 3573.503, 1317.673,
              1493.652, 1210.102, 1414.256, 1475.579, 1422.096, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0,
            ],
            visible: {},
          },
          {
            code_title: "Затраты на геолого-разведочные работы (без развед. бурения)",
            editable: {
              not_editable: true,
            },
            id: 26,
            parent_id: 44,
            title: "Затраты на геолого-разведочные работы (без развед. бурения)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "сейсморазведка 2Д",
            editable: {},
            id: 27,
            parent_id: 26,
            title: "Сейсморазведка 2Д",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "сейсморазведка 3Д",
            editable: {},
            id: 28,
            parent_id: 26,
            title: "Сейсморазведка 3Д",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 78.36, 109.944, 199.946, 109.086, 159.982, 4.955, 0, 0, 0, 0, 0, 13.82, 426.353, 24.406, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 13.82, 426.353, 24.406, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "НТР в ГРР",
            editable: {},
            id: 29,
            parent_id: 26,
            title: "НТР в ГРР",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              13.277, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "другие виды работ",
            editable: {},
            id: 30,
            parent_id: 26,
            title: "Другие виды работ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.465, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Затраты на приобретение лицензий и геолог информации",
            editable: {},
            id: 31,
            parent_id: 44,
            title: "Затраты на приобретение лицензий и геолог. информации",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Прочие инвестиционные расходы",
            editable: {
              not_editable: true,
            },
            id: 32,
            parent_id: 44,
            title: "Прочие инвестиционные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НТР в области разработки месторождения",
            editable: {},
            id: 33,
            parent_id: 32,
            title: "НТР в области разработки месторождения",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Крупные капитальные ремонты",
            editable: {},
            id: 34,
            parent_id: 32,
            title: "Крупные капитальные ремонты",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Горно-подготовительные работы",
            editable: {
              not_editable: true,
            },
            id: 35,
            parent_id: 32,
            title: "Горно-подготовительные работы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "подземное бурение скважин",
            editable: {},
            id: 36,
            parent_id: 35,
            title: "Подземное бурение скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "проходка горных выработок",
            editable: {},
            id: 37,
            parent_id: 35,
            title: "Проходка горных выработок",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "прочие расходы (прочие)",
            editable: {},
            id: 38,
            parent_id: 32,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 6.9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Данные по реконструкции скважин",
            editable: {
              not_editable: true,
            },
            id: 39,
            title: "Данные по реконструкции скважин",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Перевод на другой горизонт, скв.-оп.",
            editable: {},
            id: 40,
            parent_id: 39,
            title: "Перевод на другой горизонт",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2037, 2037, 2038, 2039, 2041, 2041, 2042, 2043, 2044, 2046, 2046, 2047,
              2048, 2049, 2051, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Углубление забоя, скв.-оп.",
            editable: {},
            id: 41,
            parent_id: 39,
            title: "Углубление забоя",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2023, 2024, 2025, 2026, 2027, 2029, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2037, 2037, 2039, 2039, 2040, 2041, 2043, 2043, 2044, 2045, 2046, 2048,
              2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Радиальное бурение, скв.-оп.",
            editable: {},
            id: 42,
            parent_id: 39,
            title: "Радиальное бурение",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
              2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Зарезка боковых (вторых) стволов, скв.-оп.",
            editable: {},
            id: 43,
            parent_id: 39,
            title: "Зарезка боковых (вторых) стволов",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2024, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
              2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2041, 2041, 2042, 2043, 2044, 2045, 2046, 2047,
              2049, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Инвестиционные затраты (без НДС), с учетом инфляции",
            editable: {
              not_editable: true,
            },
            id: 44,
            parent_id: 46,
            title: "Инвестиционные затраты (без НДС), с учетом инфляции",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Корректировка инвестиций согласно модели ЕСУ",
            editable: {},
            id: 45,
            parent_id: 44,
            title: "Корректировка инвестиций согласно модели ЕСУ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Денежный поток от инвестиционной деятельности",
            editable: {
              not_editable: true,
            },
            id: 46,
            title: "Денежный поток от инвестиционной деятельности",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Денежный поток от инвестиций в оборотный капитал",
            editable: {
              not_editable: true,
            },
            id: 47,
            title: "Денежный поток от инвестиций в оборотный капитал",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Денежный поток от измен.оборотного капитала по инвест.деят.",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 48,
            parent_id: 47,
            title: "Денежный поток от изменения ОК по инвест. деятельности.",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              2,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Авансы по инвест.деят. на конец года без НДС с инфляцией",
            editable: {
              optional: true,
            },
            id: 49,
            parent_id: 47,
            title: "Авансы по инвест. деятельности на конец года без НДС с инфляцией",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
            ],
            visible: {},
          },
          {
            code_title: "Кредиторская задолженность на конец года без НДС с инфляцией",
            editable: {
              dispose: 2,
              only_forecast: true,
            },
            id: 50,
            parent_id: 47,
            title: "Кредиторская задолженность на конец года без НДС с инфляцией",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
              0.2,
            ],
            visible: {},
          },
          {
            code_title: "Прочие изменения оборотного капитала",
            editable: {
              dispose: 1,
              only_forecast: true,
            },
            id: 51,
            parent_id: 47,
            title: "Прочие изменения оборотного капитала",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
              0.1,
            ],
            visible: {},
          },
        ],
      },
      schema_operation: {
        schema: [
          {
            code_title: "Год",
            editable: {
              not_editable: true,
            },
            id: 0,
            title: "Год",
            unit: {
              measure: 2,
              quantity: 4,
            },
            values: [
              2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
              2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
              2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {},
          },
          {
            code_title: "Операционные расходы (с инфляцией)",
            editable: {
              not_editable: true,
            },
            id: 1,
            parent_id: 79,
            title: "Операционные расходы (с инфляцией)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Расходы на подготовку и освоение производства (без списания)",
            editable: {
              only_fact: true,
            },
            id: 2,
            parent_id: 1,
            title: "Расходы на подготовку и освоение производства (без списания)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              15.795,
              41.401,
              45.388,
              65.685,
              79.867,
              67.615,
              88.444,
              101.402,
              89.139,
              133.409,
              151.764,
              65.819,
              63.87,
              71.471,
              53.728,
              74.47,
              60.495,
              20.768,
              20.768,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по добыче нефти и газа, сбор газа ���опутного",
            editable: {
              not_editable: true,
            },
            id: 3,
            parent_id: 1,
            title: "Расходы по добыче нефти и газа, сбор газа попутного",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Электроэнергия по извлечению нефти",
            editable: {
              only_fact: true,
            },
            id: 4,
            parent_id: 3,
            title: "Электроэнергия по извлечению нефти",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              203.933,
              251.165,
              316.592,
              420.655,
              472.663,
              807.535,
              1045.362,
              1277.778,
              1319.105,
              1182.236,
              1140.782,
              871.323,
              1024.788,
              1048.553,
              1074.04,
              1068.146,
              1085.593,
              1288.077,
              1288.077,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Оплата труда и ЕСН",
            editable: {
              only_fact: true,
            },
            id: 5,
            parent_id: 3,
            title: "Оплата труда и ЕСН",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              90.525,
              103.934,
              107.653,
              146.893,
              145.098,
              158.683,
              167.038,
              192.948,
              204.382,
              178.995,
              196.804,
              233.191,
              257.576,
              285.405,
              304.68,
              294.162,
              311.193,
              318.609,
              318.609,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Ремонт скважин",
            editable: {
              not_editable: true,
            },
            id: 6,
            parent_id: 3,
            title: "Ремонт скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Подземный ремонт скважин",
            editable: {
              only_fact: true,
            },
            id: 7,
            parent_id: 6,
            title: "Подземный ремонт скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              344.264,
              450.281,
              532.678,
              580.451,
              578.552,
              587.816,
              565.827,
              471.53,
              500.713,
              495.323,
              537.634,
              519.646,
              519.435,
              456.539,
              421.483,
              347.183,
              323.404,
              317.548,
              317.548,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Капитальный ремонт скважин",
            editable: {
              only_fact: true,
            },
            id: 8,
            parent_id: 6,
            title: "Капитальный ремонт скважин",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              167.088,
              192.307,
              285.024,
              94.835,
              127.715,
              162.43,
              924.729,
              995.272,
              1003,
              1172.476,
              956.269,
              1298.696,
              1198.076,
              963.837,
              932.627,
              858.714,
              536.793,
              515.597,
              515.597,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы",
            editable: {
              only_fact: true,
            },
            id: 9,
            parent_id: 3,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              820.89,
              941.61,
              1080.345,
              733.069,
              642.346,
              564.062,
              682.988,
              769.896,
              867.534,
              1028.111,
              1166.969,
              1247.723,
              1208.694,
              1237.286,
              1176.519,
              1103.104,
              989.523,
              1451.792,
              1451.792,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по искусственному воздействию на пласт",
            editable: {
              not_editable: true,
            },
            id: 10,
            parent_id: 1,
            title: "Расходы по искусственному воздействию на пласт",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Энергия",
            editable: {
              only_fact: true,
            },
            id: 11,
            parent_id: 10,
            title: "Энергия",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              104.274,
              118.999,
              136.927,
              9.31,
              249.846,
              386.902,
              435.523,
              500.307,
              511.548,
              533.996,
              556.526,
              530.243,
              593.78,
              591.236,
              576.503,
              596.763,
              619.339,
              651.755,
              651.755,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Затраты по повышению нефтеотдачи пластов",
            editable: {
              not_editable: true,
            },
            id: 12,
            parent_id: 10,
            title: "Затраты по повышению нефтеотдачи пластов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "ГРП",
            editable: {
              only_fact: true,
            },
            id: 13,
            parent_id: 12,
            title: "ГРП",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              1285.522,
              1314.388,
              1285.522,
              422.659,
              471.075,
              693.326,
              581.347,
              598.135,
              674.507,
              515.301,
              402.697,
              501.653,
              339.993,
              191.582,
              233.057,
              233.057,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие методы",
            editable: {
              only_fact: true,
            },
            id: 14,
            parent_id: 12,
            title: "Прочие методы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              484.067,
              882.1,
              1522.021,
              65.575,
              270.879,
              77.055,
              116.178,
              106.988,
              140.486,
              280.197,
              233.286,
              372.362,
              527.614,
              695.319,
              634.654,
              655.203,
              550.938,
              560.996,
              560.996,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы (возд.)",
            editable: {
              only_fact: true,
            },
            id: 15,
            parent_id: 10,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              201.461,
              319.816,
              376.11,
              733.358,
              427.366,
              465.43562323,
              816.45405323,
              530.24441813,
              606.73593985,
              746.09207288,
              723.81333959,
              688.79159281,
              771.41013394,
              888.39980701,
              1020.87114713,
              995.79202283,
              919.1370967,
              1050.65437568883,
              1050.65437568883,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по транспортировке нефтесодержащей жидкости и газа",
            editable: {
              not_editable: true,
            },
            id: 16,
            parent_id: 1,
            title: "Расходы по транспортировке нефтесодержащей жидкости и газа",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Энергия (транспорт.)",
            editable: {
              only_fact: true,
            },
            id: 17,
            parent_id: 16,
            title: "Энергия",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              13.174,
              14.329,
              22.073,
              85.687,
              96.378,
              149.27638834,
              163.06399701,
              170.97172348,
              190.00243655,
              292.28820355,
              395.98666312,
              434.64982277,
              325.47556361,
              400.42802437,
              347.73079021,
              353.33322985,
              317.81046016,
              249.80978665,
              249.80978665,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы (транспорт.)",
            editable: {
              only_fact: true,
            },
            id: 18,
            parent_id: 16,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              286.646,
              230.89,
              220.209,
              206.898,
              276.747,
              306.32867201,
              307.68079293,
              332.24919965,
              342.17608948,
              344.38662439,
              363.96275958,
              402.47101009,
              380.01002642,
              429.65416261,
              453.83945388,
              474.81138484,
              441.75310205,
              455.403770197894,
              455.403770197894,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по технологической подготовке нефти",
            editable: {
              not_editable: true,
            },
            id: 19,
            parent_id: 1,
            title: "Расходы по технологической подготовке нефти",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Энергия (подготов.)",
            editable: {
              only_fact: true,
            },
            id: 20,
            parent_id: 19,
            title: "Энергия",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              10.068,
              10.97,
              11.88,
              45.766,
              66.504,
              47.57075233,
              24.03219284,
              24.99813882,
              24.4970258,
              42.24591471,
              63.89950308,
              115.94821388,
              91.03120225,
              52.93804825,
              51.01049101,
              56.25180209,
              57.68235083,
              54.89353758,
              54.89353758,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Вспомогательные материалы",
            editable: {
              only_fact: true,
            },
            id: 21,
            parent_id: 19,
            title: "Вспомогательные материалы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              56.075,
              86.057,
              106.563,
              15.572,
              15.814,
              13.693,
              11.222,
              6.61,
              8.808,
              9.616,
              9.143,
              9.315,
              8.403,
              8.248,
              7.733,
              7.346,
              9.982,
              9.533,
              9.533,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие расходы (подготов.)",
            editable: {
              only_fact: true,
            },
            id: 22,
            parent_id: 19,
            title: "Прочие расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              42.755,
              63.326,
              65.548,
              186.366,
              184.406,
              250.02163132,
              167.04821229,
              200.27717814,
              196.42640717,
              168.98063132,
              189.10933967,
              208.78837104,
              226.6525198,
              274.65738705,
              301.75462341,
              325.78329083,
              324.20638188,
              333.504716842458,
              333.504716842458,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Расходы по транспортировке нефти и газа",
            editable: {
              only_fact: true,
            },
            id: 23,
            parent_id: 1,
            title: "Расходы по транспортировке нефти и газа",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Общепроизводственные расходы",
            editable: {
              only_fact: true,
            },
            id: 24,
            parent_id: 1,
            title: "Общепроизводственные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              841.101,
              888.601,
              995.533,
              908.071,
              1256.611,
              1224.875,
              1234.247,
              1220.173,
              1253.232,
              1609.416,
              1527.76,
              1633.035,
              1704.868,
              1830.358,
              1818.455,
              1814.557,
              1808.046,
              2066.665,
              2066.665,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочие производственные расходы",
            editable: {
              only_fact: true,
            },
            id: 25,
            parent_id: 1,
            title: "Прочие производственные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              16.517,
              17.704,
              19.684,
              32.242,
              71.779,
              87.952,
              76.592,
              57.48,
              62.727,
              62.487,
              64.857,
              63.114,
              67.552,
              65.015,
              69.724,
              71.227,
              77.711,
              81.294,
              81.294,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Внепроизводственные расходы",
            editable: {
              only_fact: true,
            },
            id: 26,
            parent_id: 1,
            title: "Внепроизводственные расходы",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              29.641,
              34.945,
              31.352,
              47.901,
              55.731,
              50.164,
              83.719,
              95.167,
              96.006,
              56.816,
              55.167,
              55.216,
              55.689,
              57.756,
              58.259,
              57.046,
              58.156,
              57.674,
              57.674,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Налоги",
            editable: {
              not_editable: true,
            },
            id: 27,
            parent_id: 79,
            title: "Налоги",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НДПИ нефть ТРИЗ",
            editable: {
              only_before_ndd: true,
              only_fact: true,
              optional: true,
              start_from: 2013,
            },
            id: 28,
            parent_id: 27,
            title: "НДПИ нефть ТРИЗ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "НДПИ нефть всего",
            editable: {
              only_fact: true,
              optional: true,
            },
            id: 29,
            parent_id: 27,
            title: "НДПИ нефть",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              5921.809,
              10925.449,
              13806.289,
              15105.63,
              19264.003,
              12440.034,
              16320.751,
              23959.387,
              26984.414,
              28557.609,
              30658.831,
              30560.996,
              24705.518,
              31761.817,
              45385.681,
              42806.804,
              26638.868,
              29178.772,
              29178.772,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "НДД",
            editable: {
              only_fact: true,
              only_since_ndd: true,
              optional: true,
            },
            id: 30,
            parent_id: 27,
            title: "НДД",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              20256.906,
              20256.906,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Реальные ставки налога",
            editable: {
              not_editable: true,
            },
            id: 31,
            title: "Реальные ставки налога",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Налог на прибыль (реальная), %",
            editable: {
              dispose: -1,
              only_fact: true,
              optional: true,
            },
            id: 32,
            parent_id: 31,
            title: "Налог на прибыль (реальная ставка)",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Налог на имущество (реальная), %",
            editable: {
              dispose: -1,
              only_fact: true,
              optional: true,
            },
            id: 33,
            parent_id: 31,
            title: "Налог на имущество (реальная ставка)",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Стоимость имущества на месторождении",
            editable: {
              not_editable: true,
            },
            id: 34,
            title: "Стоимость имущества на месторождении",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Ввод основных фондов",
            editable: {
              dispose: 1,
              only_fact: true,
            },
            id: 35,
            parent_id: 34,
            title: "Ввод основных фондов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              96.201,
              12.314,
              233.533,
              1578.191,
              2355.648,
              3793.33,
              3366.413,
              3550.191,
              5269.089,
              6932.948,
              6197.964,
              3019.827,
              3289.079,
              7804.791,
              4918.03,
              5535.91,
              6765.041,
              3846.634,
              3846.634,
              4987.779,
              8304.944,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Вывод/списание основных фондов",
            editable: {
              dispose: 1,
              only_fact: true,
            },
            id: 36,
            parent_id: 34,
            title: "Вывод/списание основных фондов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              59.741,
              279.254,
              5.076,
              93.99,
              76.07,
              252.938,
              106.43,
              97.176,
              89.141,
              67.111,
              85.539,
              105.858,
              34.47,
              83.769,
              89.463,
              90.917,
              0,
              313.089,
              313.089,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Балансовая стоимость имущества",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 37,
            parent_id: 34,
            title: "Балансовая стоимость имущества",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              5159.823,
              6503.048,
              6479.431,
              8159.897,
              10425.608,
              13273.527,
              16067.352,
              21926.096,
              28254.991,
              36790.3,
              36239.929,
              39663.683,
              30141.019,
              43649.32,
              50101.455,
              53927.03,
              6765.041,
              65793.274,
              65793.274,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Незавершенное строительство",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 38,
            parent_id: 34,
            title: "Незавершенное строительство",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              24.798,
              129.442,
              495.807,
              1494.319,
              1667.072,
              1425.734,
              853.701,
              1541.101,
              1914.801,
              2320.17,
              1536.04,
              1052.654,
              1781.858,
              2616.166,
              1524.345,
              1939.69,
              1958.029,
              1771.868,
              1771.868,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Амортизационные отчисления на добычу нефти и газа",
            editable: {
              dispose: 1,
              only_fact: true,
            },
            id: 39,
            parent_id: 34,
            title: "Амортизационные отчисления на добычу нефти и газа",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              217.631,
              328.959,
              294.096,
              400.79,
              721.78,
              780.362,
              1245.034,
              1916.152,
              2478.763,
              3295.039,
              3338.522,
              4040.504,
              2427.3,
              3748.72,
              4472.613,
              4543.923,
              5646.187,
              5361.735,
              5361.735,
              5911.254,
              6623.794,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Остаточная стоимость имущества",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 40,
            parent_id: 34,
            title: "Остаточная стоимость имущества",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              1264.236,
              1672.473,
              1767.226,
              3286.198,
              5366.645,
              9477.406,
              11669.488,
              13427.39,
              15581.47,
              20968.341,
              19984.549,
              19201.149,
              12818.81,
              20507.689,
              22480.248,
              21679.151,
              6765.041,
              21717.967,
              21717.967,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Корректировка налога на имущество",
            editable: {
              not_editable: true,
            },
            id: 41,
            title: "Корректировка налога на имущество",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Снижение остаточной стоимости (кор-ка налога на имущество)",
            editable: {
              optional: true,
              start_from: 2018,
            },
            id: 42,
            parent_id: 41,
            title: "Снижение остаточной стоимости",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0.5,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {},
          },
          {
            code_title: "Снижение НЗС (кор-ка налога на имущество)",
            editable: {
              optional: true,
            },
            id: 43,
            parent_id: 41,
            title: "Снижение НЗС (корректировка налога на имущество)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Снижение кап. вложений (кор-ка налога на имущество)",
            editable: {
              dispose: -1,
              only_forecast: true,
              optional: true,
            },
            id: 44,
            parent_id: 41,
            title: "Снижение капитальных вложений",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0.2,
              0.2,
              0.2,
              0.2,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Расчет. Налог на прибыль",
            editable: {},
            id: 45,
            title: "Расчет. Налог на прибыль",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Списание стоимости лицензии (нал.уч)",
            editable: {},
            id: 46,
            parent_id: 45,
            title: "Списание стоимости лицензии",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6.356, 6.433, 6.805, 6.907, 6.543, 6.128, 5.64, 0, 0, 6.712, 4.726, 4.514,
              4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379,
              4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379, 4.379,
            ],
            visible: {},
          },
          {
            code_title: "Списание затрат на ГРР (без разведочного бурения) (нал.уч)",
            editable: {},
            id: 47,
            parent_id: 45,
            title: "Списание затрат на ГРР (без разведочного бурения)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26.243, 22.166, 23.007, 18.389, 30.632, 34.768, 32.481, 29.705, 29.705, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Снижение НЗС (кор-ка налога на прибыль)",
            editable: {
              optional: true,
            },
            id: 48,
            parent_id: 45,
            title: "Снижение НЗС (корректировка налога на прибыль)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Списание прочих затрат",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 49,
            parent_id: 45,
            title: "Списание прочих затрат",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Списание затрат на зарезку боковых стволов (нал.уч)",
            editable: {
              dispose: -2,
              only_fact: true,
            },
            id: 50,
            parent_id: 45,
            title: "Списание затрат на зарезку боковых стволов",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              1425.024,
              1231.232,
              1231.232,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              column: {
                only_fact: true,
              },
            },
          },
          {
            code_title: "Налоговое окружение НДД Актива",
            editable: {
              not_editable: true,
            },
            id: 51,
            title: "Налоговое окружение НДД Актива",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Отрицательный дополнительный доход до списания убытков",
            editable: {},
            id: 52,
            parent_id: 51,
            title: "Отрицательный дополнительный доход до списания убытков",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Списание убытков",
            editable: {},
            id: 53,
            parent_id: 51,
            title: "Списание убытков",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Применение налогового вычета НДПИ (НДД) III гр. по ВЗ",
            editable: {
              only_since_ndd: true,
            },
            id: 54,
            parent_id: 51,
            title: "Применение налогового вычета НДПИ (НДД) по ВЗ",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
              row: {
                ndd_group: [3],
                only_ndd: true,
              },
            },
          },
          {
            code_title: "НДД по минимальной налоговой базе",
            editable: {
              only_since_ndd: true,
            },
            id: 55,
            parent_id: 51,
            title: "НДД по минимальной налоговой базе",
            unit: {
              measure: 1,
              quantity: 13,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              true,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Коэффициент удельных расходов НДД, руб./т",
            editable: {
              only_since_ndd: true,
              optional: true,
            },
            id: 56,
            parent_id: 51,
            title: "Коэффициент удельных расходов НДД",
            unit: {
              measure: 1,
              quantity: 11,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              7140,
              7568,
              8675,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Индикативный тариф на трансп. нефти (НДД), долл./т",
            editable: {
              only_since_ndd: true,
            },
            id: 57,
            parent_id: 51,
            title: "Индикативный тариф на транспортировку нефти",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              39.005,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
              39.139,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Инвестиции (внутригрупповой оборот)",
            editable: {
              only_since_ndd: true,
            },
            id: 58,
            parent_id: 51,
            title: "Инвестиции (внутригрупповой оборот)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              1,
              1,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
              0.8,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Закачка попутного газа в пласт, млн.н.м3",
            editable: {
              only_since_ndd: true,
            },
            id: 59,
            parent_id: 51,
            title: "Закачка попутного газа в пласт",
            unit: {
              measure: 4,
              quantity: 7,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Прогноз расходов на инфр. объекты для учета в НБ НДД",
            editable: {
              dispose: 1,
              only_forecast: true,
              only_since_ndd: true,
            },
            id: 60,
            parent_id: 51,
            title: "Прогноз расходов на инфраструктурные объекты для учета в НБ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
              1,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Начальные извлекаемые запасы",
            editable: {
              not_editable: true,
            },
            id: 61,
            title: "Начальные извлекаемые запасы",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1+B2 Ач2 (на нач. г.)",
            editable: {
              only_before_ndd: true,
              start_from: 2013,
            },
            id: 62,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1+B2 Ач2 на начало года",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 888, 888, 888, 888, 888, 888, 888, 888, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1+B2 ЮВ1/1 (на нач. г.)",
            editable: {
              only_before_ndd: true,
              start_from: 2013,
            },
            id: 63,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1+B2 ЮВ1/1 на начало года",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 18161, 18161, 18161, 18161, 18161, 18161, 18161, 18161, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1+B2 БВ6 (на нач. г.)",
            editable: {
              only_before_ndd: true,
              start_from: 2013,
            },
            id: 64,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1+B2 БВ6 на начало года",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 4581, 4581, 4581, 4581, 4581, 4581, 4581, 4581, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Начальные извлекаемые запасы нефти АВ1/С1 (на нач. г.)",
            editable: {
              only_since_ndd: true,
            },
            id: 65,
            parent_id: 61,
            title: "Начальные извлекаемые запасы нефти АВ1/С1 на начало года",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
              301.646,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Извлекаемые запасы нефти B2/C2 (на нач. г.)",
            editable: {
              only_since_ndd: true,
            },
            id: 66,
            parent_id: 61,
            title: "Извлекаемые запасы нефти B2/C2 на начало года",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
              6.5,
            ],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Остаточные извлекаемые запасы второго месторождения на ЛУ",
            editable: {},
            id: 96,
            parent_id: 61,
            title: "Остаточные извлекаемые запасы второго месторождения на ЛУ",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Остаточные извлекаемые запасы проектного месторождения на ЛУ",
            editable: {},
            id: 97,
            parent_id: 61,
            title: "Остаточные извлекаемые запасы проектного месторождения на ЛУ",
            unit: {
              measure: 5,
              quantity: 5,
            },
            values: [
              1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
              1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            ],
            visible: {},
          },
          {
            code_title: "Выручка всего (без налогов и транспорта), млн руб.",
            editable: {
              not_editable: true,
            },
            id: 67,
            parent_id: 79,
            title: "Выручка",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Затраты на внешний транспорт (нефть)",
            editable: {},
            id: 68,
            parent_id: 67,
            title: "Затраты на внешний транспорт (нефть)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
              0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
              0.5, 0.5, 0.5, 0.5, 0.5,
            ],
            visible: {},
          },
          {
            code_title: "Реализация нефти по направлению 2",
            editable: {},
            id: 69,
            parent_id: 67,
            title: "Реализация нефти по направлению 2",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
              100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
              100, 100, 100, 100, 100, 100, 100, 100,
            ],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Реализация нефти по направлению 3",
            editable: {},
            id: 70,
            parent_id: 67,
            title: "Реализация нефти по направлению 3",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                direction_group: [1, 2, 3],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть, на которую распространяется льгота по ЭП",
            editable: {
              only_before_ndd: true,
            },
            id: 71,
            parent_id: 84,
            title: "Товарная нефть, на которую распространяется льгота по ЭП",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              200,
              200,
              200,
              200,
              200,
              200,
              200,
              200,
              200,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Прочая выручка",
            editable: {},
            id: 72,
            parent_id: 67,
            title: "Прочая выручка",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [
              0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2,
              0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2,
              0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2,
            ],
            visible: {},
          },
          {
            code_title: "Количество ГРП",
            editable: {},
            id: 78,
            title: "Количество ГРП",
            unit: {
              measure: 2,
              quantity: 12,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 8, 8, 10, 8, 9, 8, 6, 6, 6, 6, 6, 6, 6, 6, 6,
              5, 5, 5, 5, 5, 4, 4, 4, 4, 3, 3, 1, 1, 1, 1,
            ],
            visible: {
              column: {
                only_forecast: true,
              },
            },
          },
          {
            code_title: "Денежный поток от операционной деятельности",
            editable: {
              not_editable: true,
            },
            id: 79,
            title: "Денежный поток от операционной деятельности",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Выручка всего (без налогов и транспорта) за нефть, включая льготы по ЭП",
            editable: {
              not_editable: true,
            },
            id: 80,
            parent_id: 67,
            title: "Нефть, включая льготы по ЭП",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Льгота от ЭП",
            editable: {
              not_editable: true,
            },
            id: 81,
            parent_id: 80,
            title: "Льготы от ЭП",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Выручка всего (без налогов и транспорта) за нефтяной газ",
            editable: {
              not_editable: true,
            },
            id: 82,
            parent_id: 67,
            title: "Нефтяной газ",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Добыча товарных УВ",
            editable: {
              not_editable: true,
            },
            id: 83,
            title: "Добыча товарных УВ",
            unit: {
              measure: 6,
              quantity: 5,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Товарная нефть",
            editable: {
              not_editable: true,
            },
            id: 84,
            parent_id: 83,
            title: "Товарная нефть",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "По направлениям",
            editable: {
              not_editable: true,
            },
            id: 85,
            parent_id: 84,
            title: "По направлениям",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть направление 1",
            editable: {
              not_editable: true,
            },
            id: 86,
            parent_id: 85,
            title: "Направление 1",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть направление 2",
            editable: {
              not_editable: true,
            },
            id: 87,
            parent_id: 85,
            title: "Направление 2",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарная нефть направление 3",
            editable: {
              not_editable: true,
            },
            id: 88,
            parent_id: 85,
            title: "Направление 3",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [],
            visible: {
              row: {
                direction_group: [1, 2, 3],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Товарный нефтяной газ",
            editable: {
              not_editable: true,
            },
            id: 89,
            parent_id: 83,
            title: "Товарный нефтяной газ",
            unit: {
              measure: 5,
              quantity: 7,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Налог на прибыль",
            editable: {
              not_editable: true,
            },
            id: 90,
            parent_id: 27,
            title: "Налог на прибыль",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НДПИ (НДД)",
            editable: {
              not_editable: true,
            },
            id: 91,
            parent_id: 27,
            title: "НДПИ (НДД)",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {
              row: {
                only_ndd: true,
              },
            },
          },
          {
            code_title: "НДПИ (нефть) для специальных льгот",
            editable: {
              not_editable: true,
            },
            id: 92,
            parent_id: 29,
            title: "НДПИ нефть для специальных льгот",
            unit: {
              measure: 3,
              quantity: 2,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Добыча нефти на кот. распростр. спец. льготы по НДПИ",
            editable: {
              only_before_ndd: true,
              start_from: 2014,
            },
            id: 93,
            parent_id: 84,
            title: "Добыча нефти, на которую распространяются спец. льготы по НДПИ",
            unit: {
              measure: 4,
              quantity: 5,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {},
          },
          {
            code_title: "Уровень использования нефтяного газа",
            editable: {},
            id: 95,
            parent_id: 67,
            title: "Уровень использования нефтяного газа",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96,
              96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96,
            ],
            visible: {},
          },
        ],
      },
    },
  ],
  met_coefs: {
    schema: [
      {
        code_title: "НДПИ",
        editable: {
          not_editable: true,
        },
        id: 73,
        title: "Коэффициенты НДПИ",
        unit: {
          measure: 0,
          quantity: 0,
        },
        values: [],
        visible: {},
      },
      {
        code_title: "Коэффициент выработанности (Кв)",
        editable: {
          only_before_ndd: true,
          start_from: 2016,
        },
        id: 74,
        parent_id: 73,
        title: "Коэффициент выработанности (Кв)",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          0.92,
          0.92,
          0.92,
          0.92,
          0.92,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        visible: {},
      },
      {
        code_title: "Коэффициент величины запасов (Кз)",
        editable: {
          only_before_ndd: true,
        },
        id: 75,
        parent_id: 73,
        title: "Коэффициент величины запасов (Кз)",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        ],
        visible: {},
      },
      {
        code_title: "Коэффициент региона добычи и свойств нефти (Ккан)",
        editable: {
          only_before_ndd: true,
        },
        id: 76,
        parent_id: 73,
        title: "Коэффициент региона добычи и свойств нефти (Ккан)",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        ],
        visible: {},
      },
      {
        code_title: "Коэффициент ЗНМ (Корз)",
        editable: {
          only_before_ndd: true,
          start_from: 2019,
        },
        id: 77,
        parent_id: 73,
        title: "Коэффициент ЗНМ (Корз)",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        ],
        visible: {},
      },
      {
        code_title: "Поправочный коэффициент для ставки НДПИ",
        editable: {
          only_before_ndd: true,
          start_from: 2014,
        },
        id: 94,
        parent_id: 73,
        title: "Поправочный коэффициент для ставки НДПИ",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        visible: {},
      },
    ],
  },
  properties: {
    infrastructural: false,
    losses_transfer: true,
    ndd_asset_algorithm: true,
    plan_year: 2024,
    preferences: "Льгот нет",
    start: 2004,
    stop: 2053,
  },
  schema_drilling: [
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["8"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["ГС"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [3.5],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2023],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["9"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["ННС"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [2.8],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2023],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["10"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["МС/МЗС"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [4.2],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2024],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["11"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["Нагнетательная"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [2.8],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2024],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["12"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["Прочей категории"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [2.7],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2024],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["13"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["ГС"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [4.2],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2026],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["14"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["ННС"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [3.7],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2026],
        },
      ],
    },
    {
      schema: [
        {
          code_title: "name",
          id: 0,
          title: "Скважина/участок",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["15"],
        },
        {
          code_title: "type",
          id: 1,
          title: "Вид скважины",
          unit: {
            measure: 0,
            quantity: 0,
          },
          values: ["МС/МЗС"],
        },
        {
          code_title: "Проходка, тыс. м",
          id: 2,
          title: "Проходка",
          unit: {
            measure: 2,
            quantity: 1,
          },
          values: [4.5],
          visible: {
            column: {
              only_forecast: true,
            },
          },
        },
        {
          code_title: "Год проведения ГТМ",
          id: 3,
          title: "Год ввода",
          unit: {
            measure: 2,
            quantity: 4,
          },
          values: [2028],
        },
      ],
    },
  ],
  schema_reconstruction: [],
  unit_costs: [
    {
      id: 0,
      primary: 23,
    },
    {
      id: 1,
      primary: 641.785,
    },
    {
      id: 2,
      primary: 20,
    },
    {
      id: 3,
      primary: 20,
    },
    {
      id: 4,
      primary: 20,
    },
    {
      id: 5,
      primary: 922.634,
    },
    {
      id: 6,
      primary: 23,
    },
    {
      id: 7,
      secondary: 2500,
    },
    {
      id: 8,
      primary: 23,
    },
    {
      id: 9,
      primary: 247.789,
    },
    {
      id: 10,
      primary: 175.854,
    },
    {
      id: 11,
      primary: 591.324,
    },
    {
      id: 12,
      primary: 1819.745,
    },
    {
      id: 13,
      primary: 23,
    },
    {
      id: 14,
      primary: 231.215,
    },
    {
      id: 15,
      primary: 23,
    },
  ],
  usc_model: [
    {
      discount_coef: 15,
      schema_usc: {
        schema: [
          {
            code_title: "Год",
            editable: {
              not_editable: true,
            },
            id: 0,
            title: "Год",
            unit: {
              measure: 2,
              quantity: 4,
            },
            values: [
              2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
              2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
              2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053,
            ],
            visible: {},
          },
          {
            code_title: "Цены и курсы валют",
            editable: {
              not_editable: true,
            },
            id: 1,
            title: "Цены и курсы валют",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "BFO DTD, $/брр.",
            editable: {},
            id: 2,
            parent_id: 1,
            title: "BFO DTD",
            unit: {
              measure: 9,
              quantity: 11,
            },
            values: [
              38.3, 54.5, 65.1, 72.4, 97.3, 61.7, 79.5, 111.3, 111.7, 108.7, 98.9, 52.4, 43.7, 54.3, 71.3, 64.2, 41.8,
              70.9, 100, 75, 65, 60, 60.6, 61.9, 63.1, 64.4, 65.6, 67, 68.3, 68.3, 68.3, 68.3, 68.3, 68.3, 69.666,
              71.05932, 72.4805064, 73.930116528, 75.40871885856, 76.9168932357312, 78.4552311004458, 80.0243357224548,
              81.6248224369038, 83.2573188856419, 84.9224652633548, 86.6209145686219, 88.3533328599943,
              90.1203995171942, 91.9228075075381, 93.7612636576888,
            ],
            visible: {},
          },
          {
            code_title: "Urals MOM, $/брр.",
            editable: {},
            id: 3,
            parent_id: 1,
            title: "Urals MOM",
            unit: {
              measure: 9,
              quantity: 11,
            },
            values: [
              34.3, 50.6, 61.3, 69.3, 94.8, 61.2, 78.3, 109.1, 110.4, 107.7, 97.6, 51.4, 42.1, 53.1, 69.8, 63.8, 41.4,
              69.1, 71, 54, 53, 52, 54.6, 55.7, 56.8, 57.9, 59.1, 60.3, 61.5, 61.5, 61.5, 61.5, 61.5, 61.5, 62.73,
              63.9846, 65.264292, 66.56957784, 67.9009693968, 69.258988784736, 70.6441685604307, 72.0570519316394,
              73.4981929702721, 74.9681568296776, 76.4675199662711, 77.9968703655966, 79.5568077729085,
              81.1479439283667, 82.770902806934, 84.4263208630727,
            ],
            visible: {},
          },
          {
            code_title: "Инфляция по $, к предыдущему году",
            editable: {},
            id: 4,
            parent_id: 1,
            title: "Инфляция по $, к предыдущему году",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              102.5, 103.1, 103.3, 103, 103.3, 99.6, 111.6, 108.4, 102.1, 101.5, 101.5, 100.1, 101.2, 102.2, 102.4,
              101.8, 101.3, 104.7, 106, 103, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
              102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
            ],
            visible: {},
          },
          {
            code_title: "Номинальный курс национальной валюты, руб./$",
            editable: {},
            id: 5,
            parent_id: 1,
            title: "Номинальный курс национальной валюты",
            unit: {
              measure: 10,
              quantity: 11,
            },
            values: [
              28.8, 28.3, 27.2, 25.6, 24.9, 31.7, 30.4, 29.4, 31.1, 31.8, 38.4, 61.3, 67, 58.3, 62.7, 64.7, 72.1, 73.7,
              75, 77, 79, 81, 82.6, 84.2, 85.9, 87.5, 89.3, 91, 91, 91, 91, 91, 91, 91, 91, 91, 91, 91, 91, 91, 91, 91,
              91, 91, 91, 91, 91, 91, 91, 91,
            ],
            visible: {},
          },
          {
            code_title: "Курс USD/EURO",
            editable: {},
            id: 6,
            parent_id: 1,
            title: "Курс USD/EURO",
            unit: {
              measure: 14,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 1.45, 1.25, 1.4, 1.3, 1.3, 1.1, 1.07, 1.1, 1.18, 1.12, 1.14, 1.18, 1.08, 1.05, 1.1,
              1.15, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
              1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
            ],
            visible: {},
          },
          {
            code_title: "Индексы цен",
            editable: {
              not_editable: true,
            },
            id: 7,
            title: "Индексы цен",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Индекс потребительских цен",
            editable: {},
            id: 8,
            parent_id: 7,
            title: "Индекс потребительских цен",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              111, 112.5, 109.8, 109.1, 114.1, 111.8, 106.8, 108.4, 105.1, 106.8, 107.8, 115.5, 107, 103.7, 102.9,
              104.5, 103.4, 106.7, 116.5, 109, 104.6, 104, 104, 104, 104, 104, 104, 104, 102, 102, 102, 102, 102, 102,
              102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
            ],
            visible: {},
          },
          {
            code_title: "Индекс цен промышленности",
            editable: {},
            id: 9,
            parent_id: 7,
            title: "Индекс цен промышленности",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              123.4, 120.7, 112.5, 114.3, 121.4, 92.8, 112.3, 117.7, 106.7, 103.3, 106.1, 112.2, 104.2, 105.2, 112.1,
              102.2, 99.6, 124.4, 104, 107, 102.6, 102, 104, 104, 104, 104, 104, 104, 102, 102, 102, 102, 102, 102, 102,
              102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
            ],
            visible: {},
          },
          {
            code_title: "Индекс тарифа на электроэнергию и теплоэнергию",
            editable: {},
            id: 10,
            parent_id: 7,
            title: "Индекс тарифа на электроэнергию и теплоэнергию",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              112.2, 109.7, 108.9, 114.6, 119, 115.5, 112.1, 106.9, 97.6, 108.8, 105.4, 102.7, 106.62, 109.93, 105.25,
              105.98, 104.21, 104.6, 106.1, 106.7, 105.7, 104.3, 103.6, 103.6, 103.6, 103.6, 103.6, 103.7, 102, 102,
              102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
            ],
            visible: {},
          },
          {
            code_title: "Индекс цен в капитальном строительстве",
            editable: {},
            id: 11,
            parent_id: 7,
            title: "Индекс цен в капитальном строительстве",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              112.6, 114.3, 111.4, 115.1, 119.2, 105.1, 105.4, 109.2, 107, 105.8, 104.8, 110.4, 105.2, 103, 104.9,
              107.4, 105.4, 106.9, 104, 107, 102.6, 102, 104, 104, 104, 104, 104, 104, 102, 102, 102, 102, 102, 102,
              102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
            ],
            visible: {},
          },
          {
            code_title: "Индекс роста зарплаты",
            editable: {},
            id: 12,
            parent_id: 7,
            title: "Индекс роста зарплаты",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              122.6, 126.9, 124.3, 127.8, 127.2, 107.9, 111.3, 112.2, 106, 112.3, 108.3, 108.9, 105, 108.3, 104.3,
              107.1, 103.2, 104, 110, 116.5, 109, 104.6, 104, 104, 104, 104, 104, 104, 102, 102, 102, 102, 102, 102,
              102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102,
            ],
            visible: {},
          },
          {
            code_title: "Налоги",
            editable: {
              not_editable: true,
            },
            id: 13,
            title: "Налоги",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "НДПИ нефть с Кк, руб/т",
            editable: {},
            id: 14,
            parent_id: 13,
            title: "НДПИ нефть с Кк",
            unit: {
              measure: 1,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11568, 7014, 14435, 15217, 11002, 10998, 10981, 11945, 12494,
              13071, 13645, 14294, 14943, 15327, 16327, 17327, 18327, 19327, 20327, 20733.54, 21148.2108, 21571.175016,
              22002.59851632, 22442.6504866464, 22891.5034963793, 23349.3335663069, 23816.3202376331, 24292.6466423857,
              24778.4995752334, 25274.0695667381, 25779.5509580729, 26295.1419772343, 26821.044816779, 27357.4657131146,
              27904.6150273769,
            ],
            visible: {},
          },
          {
            code_title: "НДПИ нефть с Кк и Кабдт, руб/т",
            editable: {},
            id: 15,
            parent_id: 13,
            title: "НДПИ нефть с Кк и Кабдт",
            unit: {
              measure: 1,
              quantity: 11,
            },
            values: [
              1054, 1876, 2266, 2473, 3329, 2303, 3076, 4457, 5066, 5330, 5827, 6312, 5777, 8134, 12468, 11821, 7087,
              14919, 16314, 11679, 11606, 11670, 11945, 12494, 13071, 13645, 14294, 14943, 15327, 16327, 17327, 18327,
              19327, 20327, 20733.54, 21148.2108, 21571.175016, 22002.59851632, 22442.6504866464, 22891.5034963793,
              23349.3335663069, 23816.3202376331, 24292.6466423857, 24778.4995752334, 25274.0695667381,
              25779.5509580729, 26295.1419772343, 26821.044816779, 27357.4657131146, 27904.6150273769,
            ],
            visible: {},
          },
          {
            code_title: "Налог на имущество, %",
            editable: {},
            id: 16,
            parent_id: 13,
            title: "Налог на имущество",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2,
              2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2,
              2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2,
            ],
            visible: {},
          },
          {
            code_title: "Налог на прибыль, %",
            editable: {},
            id: 17,
            parent_id: 13,
            title: "Налог на прибыль",
            unit: {
              measure: 2,
              quantity: 3,
            },
            values: [
              24, 24, 24, 24, 24, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
              20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
            ],
            visible: {},
          },
          {
            code_title: "Экспортная пошлина, нефть, $/т",
            editable: {},
            id: 18,
            parent_id: 13,
            title: "Экспортная пошлина, нефть",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 207.6, 409, 404, 392, 366.5, 120.3, 75.7, 86.7, 128.52, 93.8, 45.9, 59.1, 43.2, 15.4, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Экспортная пошлина (льготная ставка), нефть, $/т",
            editable: {},
            id: 19,
            parent_id: 13,
            title: "Экспортная пошлина (льготная ставка), нефть",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 186, 199, 190, 174.7, 1.01, 0, 0, 0, 0, 0, 59.1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Экспортная пошлина (10%), нефть, $/т",
            editable: {},
            id: 20,
            parent_id: 13,
            title: "Экспортная пошлина (10%), нефть",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 40.4, 39, 36.6, 15.2, 9, 13.4, 21.1, 9.2, 4.6, 59.1, 43.2, 15.4, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Экспортная пошлина (0%), нефть, $/т",
            editable: {},
            id: 21,
            parent_id: 13,
            title: "Экспортная пошлина (0%), нефть",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {},
          },
          {
            code_title: "Надбавка Кабдт в НДПИ для 1-4 групп НДД, руб./т",
            editable: {},
            id: 22,
            parent_id: 13,
            title: "Надбавка Кабдт в НДПИ для 1-4 групп НДД",
            unit: {
              measure: 1,
              quantity: 11,
            },
            values: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 484, 1098, 677, 608, 690, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
            visible: {
              row: {
                ndd_group: [1, 2, 3, 4],
                only_ndd: true,
              },
            },
          },
          {
            code_title: "Цены ЕСУ по Провинции",
            editable: {
              not_editable: true,
            },
            id: 23,
            title: "Цены ЕСУ по Провинции",
            unit: {
              measure: 0,
              quantity: 0,
            },
            values: [],
            visible: {},
          },
          {
            code_title: "Нетто-цена нефти на базисе ФСА (направление 1)",
            editable: {},
            id: 24,
            parent_id: 23,
            title: "Нетто-цена нефти на базисе ФСА (направление 1)",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              161, 204, 209, 255, 273, 215, 235, 318, 329, 322, 315, 225, 206, 270, 352, 343, 222, 417, 460, 357, 353,
              338, 353, 360, 367, 375, 382, 390, 397, 397, 397, 397, 397, 397, 404.94, 413.0388, 421.299576,
              429.72556752, 438.3200788704, 447.086480447808, 456.028210056764, 465.1487742579, 474.451749743058,
              483.940784737919, 493.619600432677, 503.491992441331, 513.561832290157, 523.83306893596, 534.30973031468,
              544.995924920973,
            ],
            visible: {},
          },
          {
            code_title: "Нетто-цена нефти на базисе ФСА (направление 2)",
            editable: {},
            id: 25,
            parent_id: 23,
            title: "Нетто-цена нефти на базисе ФСА (направление 2)",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              161, 204, 209, 255, 273, 215, 235, 318, 329, 322, 278, 205, 183, 249, 325, 316, 206, 393, 397, 314, 323,
              317, 335, 341, 348, 355, 362, 370, 377, 377, 377, 377, 377, 377, 384.54, 392.2308, 400.075416,
              408.07692432, 416.2384628064, 424.563232062528, 433.054496703779, 441.715586637854, 450.549898370611,
              459.560896338024, 468.752114264784, 478.12715655008, 487.689699681081, 497.443493674703, 507.392363548197,
              517.540210819161,
            ],
            visible: {
              row: {
                direction_group: [1, 2],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Нетто-цена нефти на базисе ФСА (направление 3)",
            editable: {},
            id: 26,
            parent_id: 23,
            title: "Нетто-цена нефти на базисе ФСА (направление 3)",
            unit: {
              measure: 13,
              quantity: 11,
            },
            values: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            visible: {
              row: {
                direction_group: [1, 2, 3],
                only_direction: true,
              },
            },
          },
          {
            code_title: "Нетто-цена на нефтяной газ в РФ",
            editable: {},
            id: 27,
            parent_id: 23,
            title: "Нетто-цена на нефтяной газ в РФ",
            unit: {
              measure: 15,
              quantity: 11,
            },
            values: [
              8, 10, 8, 13, 23, 10, 26, 42, 42, 51, 55, 36, 34, 40, 38, 32, 31, 31, 32, 33, 34, 34, 34, 35, 35, 36, 36,
              36, 37, 37, 37, 37, 37, 37, 37.74, 38.4948, 39.264696, 40.04998992, 40.8509897184, 41.668009512768,
              42.5013697030234, 43.3513970970838, 44.2184250390255, 45.102793539806, 46.0048494106021, 46.9249463988142,
              47.8634453267905, 48.8207142333263, 49.7971285179928, 50.7930710883527,
            ],
            visible: {},
          },
        ],
      },
      uuid: 0,
    },
  ],
};

export { CALCULATE_PREDEFINE };
