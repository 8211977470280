import { useMemo } from "react";

import { type Fact, useFact } from "models/project/fact/fact";
import { type Forecast, useForecast } from "models/project/fact/forecast/forecast";

import type { ParamsPageProps } from "./paramsPage";
import type { Tab } from "./paramsPage";

type UseTargetType = (
  factTabs: ParamsPageProps["factTabs"],
  forecastTabs: ParamsPageProps["forecastTabs"]
) => [undefined, undefined] | [Forecast, Tab<Forecast>[]] | [Fact, Tab<Fact>[]];

const useTarget: UseTargetType = (factTabs, forecastTabs) => {
  const state = useFact();
  const forecast = useForecast();
  return useMemo(() => {
    if (state === undefined || forecast === undefined) {
      return [undefined, undefined];
    }
    console.assert(state !== null, "Использование страницы параметров проекта вне контекста проекта");
    if (forecast === null) {
      return [state!, factTabs];
    }
    return [forecast, forecastTabs];
  }, [state, forecast, factTabs, forecastTabs]);
};

export { useTarget };
