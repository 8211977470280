import { FC } from "react";
import { ChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { useTechForecastModel } from "features/techForecast/useTechForecastModel";

import { Line } from "./elements/line";
import { BoundsGroup } from "./boundsGroup";

const ChartScene: FC<{ className?: string }> = observer(({ className }) => {
  const model = useTechForecastModel();
  const chart = model.currentForecast.chart;

  return (
    <>
      <ChartContext className={className} axes={chart.axes}>
        <TooltipContext.Provider value={model.currentForecast.chart.tooltipManager}>
          {chart.lines.map((line) => (
            <Line key={line.key} lineInfo={line} />
          ))}
          <BoundsGroup />
          <PointerLine />
        </TooltipContext.Provider>
      </ChartContext>
      <TooltipDiv manager={model.currentForecast.chart.tooltipManager} />
    </>
  );
});

export { ChartScene };
