// https://www.newline.co/@bespoyasov/how-to-use-fetch-with-typescript--a81ac257

import { makeAutoObservable, reaction } from "mobx";
import { getPersistedStore, makePersistable } from "mobx-persist-store";
import { objectToCamel, objectToSnake } from "ts-case-convert";

class TokenHolder {
  token: string | null = null;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "global_token",
      properties: ["token"],
      storage: window.localStorage,
    });
  }

  public onTokenChange(callback: () => void) {
    getPersistedStore(this).then(() => {
      reaction(() => this.token, callback, { fireImmediately: true });
    });
  }

  get header(): {} | { Authorization: string } {
    if (this.token === null) {
      return {};
    }
    return {
      Authorization: `Bearer ${this.token}`,
    };
  }
}

const TOKEN_HOLDER = new TokenHolder();

/**
 * Обёртка выполняет несколько функций
 * 1. Добавляет авторизационный токен
 * 2. Преобразовывает ответ в JSON и кастует его к типу (слепо, без проверки, просто инициализирует TypeScript на точке входа)
 * 3. Специфичная задача, связанная с возможным отсутствием/присутствием слеша в последнем символе пути. Если бек отвечает
 *    редиректом то обёртка пытается добавить/убрать слеш чтобы обработать редирект.
 * 4. Если запрос вернулся но без статуса Ok то запрос преобразовывается в reject
 * @param path путь к ручке
 * @param args дополнительные аргументы
 * @returns результат запроса в виде объекта типа T
 */
const request = async <T>(path: string, args: RequestInit): Promise<T> => {
  const url = `/${process.env.REACT_APP_PREFIX}/${path}`;
  const tokened = { ...args, headers: { ...args.headers, ...TOKEN_HOLDER.header } };

  return fetch(url, process.env.NODE_ENV === "development" ? { ...tokened, redirect: "follow" } : tokened).then(
    (response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    }
  );
};

type Args = Omit<RequestInit, "body" | "method">;

const getMethod = <T = unknown>(path: string, arg?: Args) => request<T>(path, { ...arg, method: "GET" });

let SPECIAL_CACHE: Promise<any> | null = null;
const getMethodWithSpecialCache = async <T = unknown>(path: string, arg?: Args): Promise<T> => {
  if (path === "demo/schemas/basic") {
    if (SPECIAL_CACHE === null) {
      SPECIAL_CACHE = getMethod<T>(path, arg);
    }
    return SPECIAL_CACHE;
  }
  return getMethod(path, arg);
};

const methodParams = <T = unknown>(
  path: string,
  body: any,
  method: "POST" | "PUT" | "PATCH" | "DELETE",
  arg?: Args
) => {
  const isFormData = body instanceof FormData;
  return request<T>(path, {
    ...arg,
    method: method,
    body: isFormData ? body : JSON.stringify(body),
    headers: {
      ...(isFormData ? {} : { "Content-Type": "application/json" }),
      ...arg?.headers,
    },
  });
};

const postMethod = <T = unknown>(path: string, body: any, arg?: Args) => {
  return methodParams<T>(path, body, "POST", arg);
};

const patchMethod = <T = unknown>(path: string, body: any, arg?: Args) => {
  return methodParams<T>(path, body, "PATCH", arg);
};

const putMethod = <T = unknown>(path: string, body: any, arg?: Args) => {
  return methodParams<T>(path, body, "PUT", arg);
};

const deleteMethod = <T = unknown>(path: string, body?: any, arg?: Args) => {
  return methodParams<T>(path, body, "DELETE", arg);
};

const args = (args: Object) => {
  const r = [];
  for (const [key, valData] of Object.entries(args)) {
    if (valData === undefined) {
      continue;
    }
    let val = valData;
    if (Array.isArray(valData)) {
      val = valData.join(",");
    }
    r.push(`${key}=${val}`);
  }
  return r.join("&");
};

const req = {
  get: getMethodWithSpecialCache,
  post: postMethod,
  delete: deleteMethod,
  put: putMethod,
  patch: patchMethod,
  args,
};

const reqCamel = {
  get: <T extends object>(path: string, arg?: Args) => req.get<T>(path, arg).then(objectToCamel),
  post: <T extends object, B extends object = any>(path: string, body: B, arg?: Args) =>
    req.post<T>(path, objectToSnake(body), arg).then(objectToCamel),
  delete: <T extends object>(path: string, body?: any, arg?: Args) =>
    req.delete<T>(path, objectToSnake(body), arg).then(objectToCamel),
  put: <T extends object>(path: string, body: any, arg?: Args) =>
    req.put<T>(path, objectToSnake(body), arg).then(objectToCamel),
  patch: <T extends object>(path: string, body: any, arg?: Args) =>
    req.patch<T>(path, objectToSnake(body), arg).then(objectToCamel),
  args: (a: Object) => args(objectToSnake(a) as any),
};

export { req, reqCamel, TOKEN_HOLDER };
export type { Args };
