import { type FC, useCallback, useMemo } from "react";
import { Tabs } from "antd";

import { Format } from "elements/format/format";
import { useProject } from "models/project/project";

import cn from "./licenseTabs.module.less";

const LicenseTabs: FC<{ value: number; onChange: (id: number) => void }> = ({ value, onChange }) => {
  const { licenseRegions } = useProject()!;

  const items = useMemo(
    () =>
      licenseRegions.selector.map(({ value, label }) => ({
        key: value.toString(),
        label: <Format>{label}</Format>,
      })),
    [licenseRegions.selector]
  );

  const onChangeWrap = useCallback(
    (key: string) => {
      onChange(parseInt(key, 10));
    },
    [onChange]
  );

  return (
    <div className={cn.licenseTabsWrap}>
      <Tabs activeKey={`${value}`} onChange={onChangeWrap} items={items} className={cn.tabsWrapper} />
    </div>
  );
};

export { LicenseTabs };
