import { FC } from "react";
import { ChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import StackedBars from "elements/charts/stackedBars/stackedBars";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { useLinesBarsComposition } from "features/plot/useLinesBarsComposition";

import { PlanningChartModel } from "./planningChartModel";

import cn from "./planningChart.module.less";

type PlanningChartProps = {
  chartModel: PlanningChartModel;
};

const YEARS_ONLY = new Set(["x"]);

const PlanningChart: FC<PlanningChartProps> = observer(({ chartModel }) => {
  const axes = useLinesBarsComposition(undefined, chartModel.bars, undefined, chartModel.axesTitles, undefined, YEARS_ONLY);

  return (
    <div className={cn.layout}>
      <div className={cn.title}>
        {chartModel.title}
        <div className={cn.legend}>
          {chartModel.bars.bars.map((bar, index) => (
            <div key={index} className={cn.legendItem}>
              {bar.color !== null && <div className={cn.circle} style={{ backgroundColor: bar.color }} />}
              <div className={cn.value}>{bar.title}</div>
            </div>
          ))}
        </div>
      </div>
      <ChartContext axes={axes} className={cn.chart}>
        <TooltipContext.Provider value={chartModel.tooltipManager}>
          <StackedBars bars={chartModel.bars} />
          <PointerLine />
        </TooltipContext.Provider>
      </ChartContext>
      <TooltipDiv manager={chartModel.tooltipManager} />
    </div>
  );
});

export { PlanningChart };
