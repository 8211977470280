import { action, computed, makeObservable, observable } from "mobx";

import { LoadableStore } from "models/loadableStore/loadableStore";
import {
  CatalogType,
  getInfrastructureCatalog,
  MineType,
  saveCatalog,
  SegmentType,
  StationType,
} from "services/back/infrastructure/catalog";

const pipesFilter = (type: "prod" | "inj", pipes?: SegmentType[]) => {
  return (pipes ?? []).filter(({ segmentType }) => segmentType === type);
};

class InfrastructureCatalog {
  public data?: CatalogType;
  public oilPipesSelector: LoadableStore<SegmentType> = new LoadableStore(() => Promise.resolve([]));
  public waterPipesSelector: LoadableStore<SegmentType> = new LoadableStore(() => Promise.resolve([]));
  public minesSelector: LoadableStore<MineType> = new LoadableStore(() => Promise.resolve([]));
  public stationsSelector: LoadableStore<StationType> = new LoadableStore(() => Promise.resolve([]));

  constructor() {
    makeObservable(this, {
      data: observable,
      oilPipesSelector: observable,
      waterPipesSelector: observable,
      minesSelector: observable,
      stationsSelector: observable,
      oilPipes: computed,
      waterPipes: computed,
      mines: computed,
      stations: computed,
      isLoading: computed,
      initChildren: action,
      update: action,
      push: action,
      remove: action,
    });

    this.initChildren();
  }

  async initChildren() {
    const response = await getInfrastructureCatalog();
    this.data = response;
    this.oilPipesSelector = new LoadableStore(() => Promise.resolve(pipesFilter("prod", response.segments)));
    this.waterPipesSelector = new LoadableStore(() => Promise.resolve(pipesFilter("inj", response.segments)));
    this.minesSelector = new LoadableStore(() => Promise.resolve(response.mines ?? []));
    this.stationsSelector = new LoadableStore(() => Promise.resolve(response.stations ?? []));
  }

  get oilPipes() {
    return this.data?.segments.filter(({ segmentType }) => segmentType === "prod") || [];
  }
  get waterPipes() {
    return this.data?.segments.filter(({ segmentType }) => segmentType === "inj") || [];
  }
  get mines() {
    return this.data?.mines || [];
  }
  get stations() {
    if (!this.data) {
      return [];
    }
    return this.data.stations || [];
  }
  get isLoading(): boolean {
    return this.data === undefined;
  }

  public update = (item: Partial<SegmentType | MineType | StationType>, type: "pipe" | "mine" | "station") => {
    const array = type === "pipe" ? "segments" : type === "mine" ? "mines" : "stations";
    const index = this.data![array].findIndex(({ uuid }) => uuid === item.uuid);
    this.data![array][index] = { ...this.data![array][index], ...item };
  };

  public push = (item: SegmentType | MineType | StationType, type: "pipe" | "mine" | "station") => {
    const array = type === "pipe" ? "segments" : type === "mine" ? "mines" : "stations";
    this.data![array].push(item as any);
  };

  pipes = (type: "prod" | "inj") => (type === "prod" ? this.oilPipes : this.waterPipes);
  stationsAt = (type: string) => this.stations.filter((el) => el.stationType === type);

  remove = (uuid: string, type: "pipe" | "mine" | "station") => {
    if (!this.data) {
      return;
    }
    const array = type === "pipe" ? "segments" : type === "mine" ? "mines" : "stations";
    const index = this.data[array].findIndex((el) => el.uuid === uuid);
    this.data[array].splice(index, 1);
  };

  save = async () => {
    await saveCatalog(this.data!);
  };
}

export { InfrastructureCatalog };
