import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnRaw } from "@okopok/components/Table";
import { Tooltip } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";

import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format, formatNumber } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { Forecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as StarIcon } from "../../star.svg";

import { DRow } from "./tableStore";

import cn from "../infrastructure.module.less";

const COMPARE_TABLE_BASE_COLUMNS: ColumnRaw<DRow>[] = [
  {
    title: "№",
    width: 54,
    key: "index",
    isSticky: true,
    render: (_, mine) => mine.absoluteIndex,
  },
  {
    title: "Участок трубопровода",
    width: { min: 400, max: 400, competitiveness: 1 },
    dataKey: "param",
    isSticky: true,
    onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
    render: (value) => (
      <Ellipsis limit={45} position="after">
        {value}
      </Ellipsis>
    ),
  },
];

const lastCalc = (ts: Date | null | undefined) => {
  if (ts === undefined) {
    return `Расчет загружается`;
  }
  if (ts === null) {
    return `Расчет не проводился`;
  }
  return `Расчет: ${dayjs(ts).format("HH:MM DD-MM-YYYY")}`;
};

function generateColumns(forecasts: Forecast[], favoriteScenario: number | null, onFavoriteSelect: (id: number) => void): ColumnRaw<DRow>[] {
  return [
    ...COMPARE_TABLE_BASE_COLUMNS,
    ...forecasts
      .sort(({ title: a }, { title: b }) => a.localeCompare(b))
      .map(
        ({ id, title, infrastructure }): ColumnRaw<DRow> => ({
          key: `fc_${id}`,
          dataKey: id,
          title: (
            <div className={cn.tooltip}>
              <div className={cn.favHeader}>
                {title} {favoriteScenario === id && <Icon content={<StarIcon />} width={"25px"} height={"25px"} viewBox="0 0 13 13" />}
              </div>
              <Tooltip title={`${lastCalc(infrastructure.calculateStore.lastTS)}`}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          ),
          hidden: true,
          width: { min: 250, max: 300, competitiveness: 1 },
          onHeaderCell: () => ({
            className: classNames(cn.tableCellAlignRight, favoriteScenario === id && cn.favorite, cn.headerCell),
            onClick: () => {
              onFavoriteSelect(id);
            },
          }),
          onCell: () => ({
            className: cn.cellWrap,
          }),
          render: (_, { value }) => (
            <div className={cn.cell}>
              <div>
                <Format>{value?.[id].value}</Format>
              </div>
              {value?.[id].delta !== null && value?.[id].delta !== 0 && value?.[id].delta !== undefined && (
                <div className={value?.[id].delta! > 0 ? cn.profit : cn.nonprofit}>
                  {formatNumber(value?.[id].delta!) !== "0" && formatNumber(value?.[id].delta!) !== "-0" ? (
                    <Format sign>{value?.[id].delta}</Format>
                  ) : (
                    <Format sign unit={"real_5"}>
                      {value?.[id].delta}
                    </Format>
                  )}
                </div>
              )}
            </div>
          ),
        })
      ),
  ];
}

export { generateColumns };
