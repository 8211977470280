import { Component, ReactNode } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Modal } from "antd";
import { observer } from "mobx-react";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Loader } from "elements/loader";
import { global } from "models/global";
import { Session, SessionContextProvider } from "models/session";
import { User } from "models/user";
import { Login } from "pages/login";

import { AuthorizedRouter } from "./authorizedRouter";

type SessionContextHolderProps = { children: ReactNode; user: User };

class SessionContextHolder extends Component<SessionContextHolderProps> {
  session: Session;

  constructor(props: SessionContextHolderProps) {
    super(props);
    this.session = new Session(props.user);
  }

  render() {
    console.assert(this.session.user === this.props.user);
    return <SessionContextProvider value={this.session}>{this.props.children}</SessionContextProvider>;
  }
}

const FOOTER = [] as const;
const MODAL_STYLE = { body: { margin: "20px", height: "20px" } };

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Login />

        <Modal
          centered
          open={global.isPseudoLogoutState && !global.isAuthorized}
          footer={FOOTER}
          closeIcon={null}
          closable={false}
          styles={MODAL_STYLE}
        >
          <Loader tip="Разбор новых данных" size="large">
            <div className="content" />
          </Loader>
        </Modal>
      </>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

const RootRouter = observer(() => {
  if (global.user === undefined) {
    return <FullScreenLoader>Загрузка информации о пользователе</FullScreenLoader>;
  }
  if (global.user) {
    return (
      <SessionContextHolder user={global.user}>
        <AuthorizedRouter />
      </SessionContextHolder>
    );
  }
  return <RouterProvider router={router} />;
});

export { RootRouter };
