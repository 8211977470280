import { FC, type ReactNode } from "react";
import { observer } from "mobx-react";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";

type PreloaderProps = {
  hooks: Array<() => undefined | unknown>;
  children: ReactNode;
  whileLoading?: ReactNode;
};

const EXPLAINS = new Map<PreloaderProps["hooks"][number], ReactNode>([]);

const kindInference = (value: ReturnType<PreloaderProps["hooks"][number]>): boolean => {
  if (value === undefined) {
    return true;
  }
  if (value === null) {
    return false;
  }
  if (typeof value === "object" && "isLoading" in value) {
    return value.isLoading === true;
  }
  return false;
};

const Preloader: FC<PreloaderProps> = observer(({ hooks, children, whileLoading = null }) => {
  for (const [hook, val] of hooks.map((hook) => [hook, hook()] as const)) {
    const isLoading = kindInference(val);

    if (isLoading) {
      return whileLoading ? <>{whileLoading}</> : <FullScreenLoader>{EXPLAINS.get(hook)}</FullScreenLoader>;
    }
  }
  return <>{children}</>;
});

export { kindInference, Preloader };
