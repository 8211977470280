import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class ReferenceParams extends Params {
  constructor(years: Range, services: ParamsServices) {
    super("Справочные параметры", years, services);
  }
}

export { ReferenceParams };
