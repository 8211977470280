import { EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";

import { WELL_GROUPS_LIST } from "./techForecastModel";

type ForecastGroup = (typeof WELL_GROUPS_LIST)[number];

class GroupedEvents {
  new: EventNode[] = [];
  base: EventNode[] = [];
  gtm: EventNode[] = [];

  at(index: number): EventNode[] {
    return this[WELL_GROUPS_LIST[index]];
  }

  groupAt(index: number): number {
    let cur = 0;
    while (cur <= index && cur < WELL_GROUPS_LIST.length) {
      if (this.at(cur).length === 0) {
        index++;
      }
      cur++;
    }
    // Из-за гонки между перерисовкой и изменением числа групп может приходить некорректный индекс на вход
    return Math.min(index, WELL_GROUPS_LIST.length - 1);
  }

  atIndex(index: number): EventNode {
    let group = 0;
    while (group < WELL_GROUPS_LIST.length && this.at(group).length <= index) {
      index -= this.at(group).length;
      ++group;
    }
    console.assert(group < WELL_GROUPS_LIST.length, "Ошибка резолвинга абсолютного индекса");
    return this.at(group)[index];
  }

  indexAt(event: EventNode): number {
    let result = 0;
    for (let index = 0; index < WELL_GROUPS_LIST.length; ++index) {
      const founded = this.at(index).findIndex((e) => e === event);
      if (founded >= 0) {
        return result + founded;
      }
      result += this.at(index).length;
    }
    return 0;
  }

  atEvent(index: number, groupIndex: number): EventNode {
    for (let i = 0; i !== groupIndex; ++i) {
      index -= this.at(i)?.length ?? 0;
    }
    return this.at(groupIndex)[index];
  }

  constructor(all: EventNode[]) {
    for (const selected of all) {
      this[GroupedEvents.wellMode(selected)].push(selected);
    }
  }

  static wellMode(selected: EventNode): ForecastGroup {
    return selected.intervention ? "gtm" : selected.well.forecast !== null ? "new" : "base";
  }
}

export { type ForecastGroup, GroupedEvents };
