import { BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";

abstract class PlanningChartModel {
  abstract tooltipManager: TooltipDataManager | null;
  abstract bars: BarsDataModel;

  constructor(public title: string, public axesTitles: Map<string, string>) {}
}
export { PlanningChartModel };
