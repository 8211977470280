import { type FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, CheckboxOptionType, Popover, Typography } from "antd";

import { Filter } from "elements/icons/filter";
import { Reset } from "elements/icons/reset";

import cn from "./filterPicker.module.less";

type Items = {
  key: string;
  label: string;
}[];

type FilterPickerProps = {
  groups: Array<{
    title: string;
    items: Items;
  }>;
  onChange?: (changed: string[][]) => void;
  value?: string[][];
};

const asValue = (groups: FilterPickerProps["groups"]) => groups.map(({ items }) => items.map(({ key }) => key));

const appendAll = (value: string[], length: number) => {
  if (value.length !== length) {
    return value;
  }
  const result = ["all", ...value];
  return result;
};

const takeKeys = ({ key }: Items[0]) => key;

const isModified = ({ groups, value }: FilterPickerProps): boolean => {
  if (value === undefined) {
    return false;
  }
  for (let i = 0; i < groups.length; ++i) {
    if (groups[i].items.length !== value[i].length) {
      return true;
    }
  }
  return false;
};

const CheckboxGroup: FC<{ items: Items; onChange: (changed: string[][]) => void; value: string[][]; groupId: number }> = ({
  items,
  onChange,
  value,
  groupId,
}) => {
  const options = useMemo(() => {
    // если осталась выбрана всего одна галочка то её снять нельзя, только прожать резет
    const result: CheckboxOptionType<string>[] = items.map(({ key, label }) => {
      const disabled = value[groupId]?.length === 1 && value[groupId][0] === key;
      const title = disabled ? "Последний элемент группы отключить нельзя" : undefined;
      return { value: key, label, disabled, title };
    });
    if (result.length > 3) {
      result.splice(0, 0, {
        label: "Все",
        value: "all",
        disabled: value[groupId]?.length === items.length,
        onChange: () => {
          const result = [...value];
          result[groupId] = items.map(takeKeys);
          onChange(result);
        },
      });
    }
    return result;
  }, [groupId, items, onChange, value]);
  const onGroupChange = useCallback(
    (changedInitial: string[]) => {
      const result = [...value];
      result[groupId] = [...changedInitial.filter((v) => v !== "all")];
      if (JSON.stringify(result[groupId]) !== JSON.stringify(value[groupId])) {
        onChange(result);
      }
    },
    [onChange, value, groupId]
  );
  return <Checkbox.Group className={cn.group} options={options} value={appendAll(value[groupId], items.length)} onChange={onGroupChange} />;
};

const FilterPickerContent: FC<FilterPickerProps> = (props) => {
  const { groups, onChange, value } = props;
  console.assert(value === undefined || value.length === groups.length, "Размерность векторов значений и групп не должны отличаться");
  const [inferredValue, setInferredValue] = useState(value ?? asValue(groups));
  useEffect(() => setInferredValue(value ?? asValue(groups)), [value, groups]);
  const reset = useCallback(() => {
    setInferredValue(groups.map(({ items }) => items.map(takeKeys)));
  }, [groups, setInferredValue]);
  useEffect(() => {
    if (inferredValue.map(({ length }) => length).find((l) => l !== 0) === undefined) {
      reset();
    } else if (JSON.stringify(inferredValue) !== JSON.stringify(value)) {
      onChange?.(inferredValue);
    }
  }, [onChange, inferredValue, value, reset]);

  return (
    <div className={cn.filter}>
      <Button className={cn.reset} onClick={reset} disabled={!isModified(props)} icon={<Reset />} />
      {groups
        .filter(({ items }) => items.length !== 0)
        .map(({ title, items }, groupId) => (
          <div key={title}>
            <Typography.Title level={3}>{title}</Typography.Title>
            <CheckboxGroup onChange={setInferredValue} value={inferredValue} items={items} groupId={groupId} />
          </div>
        ))}
    </div>
  );
};

const FilterPicker: FC<FilterPickerProps> = (props) => (
  <Popover placement="bottomLeft" title="Фильтр" content={<FilterPickerContent {...props} />}>
    <Button className={cn.button} type="text" icon={<Filter modified={isModified(props)} />} />
  </Popover>
);

export type { FilterPickerProps };
export { FilterPicker };
