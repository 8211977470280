import { FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { DataProject } from "features/project/dataProject";
import { useProject } from "models/project/project";

import cn from "./project.module.less";

const Project: FC = () => (
  <Preloader hooks={[useProject]}>
    <PageFrame className={cn.wrap} bodyClassName={cn.content} title="Основные параметры проекта">
      <DataProject />
    </PageFrame>
  </Preloader>
);

export { Project };
