import { global } from "models/global";

function conditionally<T>(is: boolean, v: Record<string, T> | (() => Record<string, T>)): {} | Record<string, T> {
  if (!is) {
    return {};
  }

  return v instanceof Function ? v() : v;
}
function conditionallyArr<T>(is: boolean, v: T | (() => T)): [T] | [] {
  if (!is) {
    return [];
  }
  return v instanceof Function ? [v()] : [v];
}

function ifDebugZoneArr<T>(v: T | (() => T)) {
  return conditionallyArr(global.IS_DEBUG_ZONE, v);
}

function ifDebugZone<T>(v: Record<string, T> | (() => Record<string, T>)) {
  return conditionally(global.IS_DEBUG_ZONE, v);
}

export { conditionally, conditionallyArr, ifDebugZone, ifDebugZoneArr };
