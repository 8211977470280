import type { FC } from "react";
import ReactJson from "react-json-view";
import { Typography } from "antd";
import { observer } from "mobx-react";

import { useTechForecastModel } from "../../useTechForecastModel";

import cn from "./resultsDebug.module.less";

const ResultsDebug: FC = observer(() => {
  const model = useTechForecastModel();
  const result = model.currentForecast.currentResult;

  let resultJSX;
  if (result === undefined) {
    resultJSX = <Typography.Text>debounce await</Typography.Text>;
  } else if (result === null) {
    resultJSX = <Typography.Text>request failed</Typography.Text>;
  } else if (result.data === undefined) {
    resultJSX = <Typography.Text>request await</Typography.Text>;
  } else if (result.data === null) {
    resultJSX = <Typography.Text>request error</Typography.Text>;
  } else {
    resultJSX = <ReactJson name="Тело ответа" quotesOnKeys={false} displayDataTypes={false} src={result?.data}></ReactJson>;
  }
  return (
    <div className={cn.layout}>
      <ReactJson name="Тело запроса" quotesOnKeys={false} displayDataTypes={false} src={model.currentForecast.settings.dump}></ReactJson>
      {resultJSX}
    </div>
  );
});

export { ResultsDebug };
