import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { TableContextProvider, Widget } from "@okopok/components/Table";
import { Button, Empty } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react";

import { useProjectsContext } from "./projectsContextProvider";

import cn from "./projectsTable.module.less";

const ProjectsTable: FC = observer(() => {
  const fields = useProjectsContext();

  const navigate = useNavigate();
  fields.onNavigateProject = (id) => {
    navigate(id.toString());
  };

  return fields.tableModel === null ? (
    <div className={cn.empty}>
      <Empty description="У выбранного месторождения нет ни одного проекта">
        <Button
          onClick={() => {
            runInAction(() => (fields.currentFieldId = 34));
          }}
        >
          К месторождению им.Некрасова
        </Button>
      </Empty>
    </div>
  ) : (
    <TableContextProvider value={fields.tableModel}>
      <div className={cn.table}>
        <Widget headerClassName={cn.tableHeader} />
      </div>
    </TableContextProvider>
  );
});

export { ProjectsTable };
