import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeObservable, observable } from "mobx";

import { ParamsRow } from "features/useMetrics/paramsRow";
import { zip } from "utils/itertools";
import type { Range } from "utils/range";

type TableDataRow = {
  param: string;
  measure: string;
  [year: number]: number | null;
};

class ScalarTableStore extends TableNode<TableDataRow, Datum> {
  public readonly root = this;

  constructor(public readonly range: Range, readonly rows: ParamsRow[]) {
    super();
    makeObservable<ScalarTableStore, "rows">(this, {
      rows: observable,
    });
    this.childrenStore = new ChildrenStoreArray(
      this,
      rows.map((row) => new Datum(this, row))
    );
  }

  get isUpdated(): boolean {
    return super.isUpdated;
  }
}

class Datum extends TableNode<TableDataRow> {
  public get root(): ScalarTableStore {
    return this.parent.root;
  }

  constructor(private readonly parent: Datum | ScalarTableStore, private readonly row: ParamsRow) {
    super(parent, { isExpandedChildren: true });
    this.childrenStore = null;
  }

  public get range(): Range {
    return this.root.range;
  }

  asDRow = (): TableDataRow => ({
    param: this.row.title,
    measure: this.row.measure ?? "",
    ...Object.fromEntries(zip([...this.range], this.row.values ?? [])),
  });

  updateValue?(key: any, newValue: number | null): [prev: any, curr: any] {
    const operationsTitle = ["Задать значение", "Число операций"];

    if (!this.row.values || !operationsTitle.includes(this.row.title)) {
      return [undefined, undefined];
    }

    const yearIdx = this.range.id(key);
    const prev = this.row.values[yearIdx];
    const curr = (this.row.values[yearIdx] = newValue);
    return [prev, curr];
  }
}

export { ScalarTableStore };
