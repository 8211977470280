import { FC } from "react";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { observer } from "mobx-react";

import { Label } from "elements/inputs/label/label";

import { useCreateProjectsContext } from "./createProjectProvider";
import { RolesSettings } from "./roles";

import cn from "./settings.module.less";

const Settings: FC = observer(() => {
  const createdProject = useCreateProjectsContext();

  return (
    <div className={cn.container}>
      <Label title="Название проекта" inputClassName={cn.input}>
        <Input value={createdProject.title} onChange={createdProject.setTitle} />
      </Label>
      <Label title="Описание" inputClassName={cn.input} className={cn.description}>
        <TextArea value={createdProject.description} onChange={createdProject.setDescription} />
      </Label>
      <RolesSettings />
    </div>
  );
});

export { Settings };
