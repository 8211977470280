import { ChangeEventHandler, useCallback, useRef, useState } from "react";
import { Input, Typography } from "antd";

import { ModelContentComponentType, useModal } from "elements/modal/modal";

type Row = {
  value: Array<null | number>;
  update: (key: number, value: null | number) => void;
};

const split = (s: string): Array<string> => {
  // если так то в качестве десятичного разделителя используется точка а значит запятая это разделитель чисел
  if (s.includes(".")) {
    return s.split(/[\t,]/);
  }
  // точек нет а табуляция есть. Значит запятая считается десятичным разделителем а табуляция разделителем чисел
  if (s.includes("\t")) {
    return s.replaceAll(",", ".").split("\t");
  }
  // нет ни точек не табуляций, значит это целые числа через запятую
  return s.split(",");
};

const getFilteredKeys = (data: Array<number | null>): Array<number> => {
  return Object.keys(data)
    .map(Number)
    .filter((key) => !isNaN(key));
};

const EditRow: ModelContentComponentType<Array<number | null>, Row> = ({ dataRef, setOnAccept, setValid }) => {
  const filteredKeys = getFilteredKeys(dataRef.current.value);

  const [value, setValue] = useState<string>(filteredKeys.map((key) => dataRef.current.value[key]).join(", "));

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      setValue(target.value);
      setValid(split(target.value).length === Object.values(dataRef.current.value).length - 2);
    },
    [setValid, dataRef]
  );

  setOnAccept(() =>
    Promise.resolve({
      result: true,
      data: split(value)
        .map((v) => parseFloat(v))
        .map((v) => (isFinite(v) ? v : null)),
    })
  );

  return (
    <>
      <Typography.Text>{`${split(value).length} (${
        split(value)
          .map((v) => parseFloat(v))
          .filter((v) => isFinite(v)).length
      }) / ${dataRef.current.value.length}`}</Typography.Text>
      <Input value={value} onChange={onChange} />
    </>
  );
};

const useEditRow = (row: Row) => {
  const showModal = useModal<Row["value"], Row>();
  const rowRef = useRef<Row>(row);
  rowRef.current = row;

  return useCallback(async () => {
    let values = null;
    try {
      values = await showModal(EditRow, rowRef, "Ввод строки целиком");
    } catch {
      return;
    }

    const filteredKeys = getFilteredKeys(rowRef.current.value);

    values.forEach((value, index) => {
      const key = filteredKeys[index];
      row.update(key, value);
    });
  }, [showModal, row]);
};

export { useEditRow };
