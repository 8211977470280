import { FC, useEffect, useRef } from "react";
import { MapTooltip } from "@okopok/axes_context";
import { Button } from "antd";
import * as d3 from "d3";

import { getZoomParameters } from "../utils";

import cn from "./selection.module.less";

type Props = {
  x: number;
  y: number;
  updatePosition: (event: any) => void;
  onFinish?: VoidFunction;
  onDelete?: VoidFunction;
  onHover?: (hover: boolean) => void;
  zoom: number;
};

const SelectionPoint: FC<Props> = ({ x, y, zoom, updatePosition, onFinish, onDelete, onHover }) => {
  const pointRef = useRef(null);

  const { zoomScale } = getZoomParameters(zoom);

  useEffect(() => {
    const dragHandler = d3
      .drag()
      .on("start", updatePosition)
      .on("drag", (drag) => {
        updatePosition(drag);
        onHover?.(false);
      })
      .on("end", updatePosition);
    d3.select(pointRef.current).call(dragHandler as any);
  }, [updatePosition, onHover]);

  return (
    <MapTooltip
      title={
        <div>
          <Button className={cn.tooltipBtn} type="text" onClick={onDelete}>
            Удалить точку
          </Button>
          <Button className={cn.tooltipBtn} type="text" onClick={onFinish}>
            Завершить
          </Button>
        </div>
      }
      trigger="click"
    >
      <g transform={`translate(${x}, ${y}), scale(${zoomScale})`}>
        <circle r={10} ref={pointRef} className={cn.selectionPoint} onMouseMove={() => onHover?.(false)} onMouseLeave={() => onHover?.(true)} />
      </g>
    </MapTooltip>
  );
};

export default SelectionPoint;
