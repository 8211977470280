import { makeAutoObservable } from "mobx";

import { ParamsRow } from "features/useMetrics/paramsRow";
import { ParamsNode } from "models/params/paramsNode";
import { MetricTree, riseMetrics } from "services/finance/utils";
import { Range } from "utils/range";
import { numerateTree } from "utils/tree";

class Aggregation {
  public metrics?: ParamsNode[];

  constructor(public readonly years: Range, rawData?: MetricTree[]) {
    makeAutoObservable(this);
  }

  public fromRaw(raw: MetricTree[]): void {
    this.metrics = raw.map(ParamsNode.fromRaw);
    this.metrics.forEach((v) => v.propagateParent());
  }

  public get asRaw(): MetricTree[] {
    const data = this.metrics!.flatMap((node) => node.flatten());
    return riseMetrics(data);
  }

  public get isLoading() {
    return this.metrics === undefined;
  }

  public get tableItems(): ParamsRow[] {
    console.assert(this.metrics !== undefined, "Попытка отобразить таблицу до завершения её загрузки");
    const result = (this.metrics ?? []).map((v) => v.tableItems);
    numerateTree(result);
    return result;
  }

  public clone() {
    return new Aggregation(this.years, structuredClone(this.asRaw));
  }
}

export { Aggregation };
