import { useRef } from "react";
import { useChartContext } from "@okopok/axes_context";
import { useSceneContext } from "@okopok/axes_context";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { useTooltipContext } from "features/plot/Tooltip/useTooltipDataManager";

const PointerLine = observer(() => {
  const axes = useChartContext();
  const sceneContext = useSceneContext();
  const refLine = useRef<SVGLineElement | null>(null);
  const tooltipManager = useTooltipContext();
  if (sceneContext === null) {
    return <>now it's out of context axis</>;
  }
  const { svgRef } = sceneContext;
  if (axes.isNotReady || !axes.isShapeReady) {
    return null;
  }
  const xScale = axes.axisX.scale;
  d3.select(svgRef.current)
    .on("mouseover", () => {
      d3.select(refLine.current).style("opacity", 1).style("stroke-dasharray", "5,5");
    })
    .on("mousemove", (event: MouseEvent) => {
      const [x] = d3.pointer(event);
      const xCursor = Math.round(xScale.invert(x));
      const scene = svgRef.current?.getBoundingClientRect();
      const lineX = tooltipManager?.getPointerLinePosition(xCursor);
      d3.select(refLine.current).attr("x1", xScale(lineX!)).attr("x2", xScale(lineX!));
      tooltipManager?.setTooltipInfo({
        currentTimestamp: xCursor,
        left: xScale(lineX ?? x),
        top: scene!.height - 20,
        sceneTop: scene!.top,
        sceneLeft: scene!.left,
      });
      if (Math.abs(x - xScale(lineX ?? x)) > 20) {
        tooltipManager?.setTooltipInfo(null);
      }
    })
    .on("mouseout", () => {
      d3.select(refLine.current).style("opacity", 0);
      tooltipManager?.setTooltipInfo(null);
    });

  return <line ref={refLine} width={2} stroke="blue" x1={-1} x2={-1} y1={0} y2={axes.height} style={{ pointerEvents: "none" }} />;
});

export { PointerLine };
