import { FC, useMemo } from "react";
import { Widget } from "@okopok/components/Table";
import { Empty, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreen } from "elements/fullScreen/fullScreen";
import { LinkButton } from "elements/linkButton/linkButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { Events as EventsModel } from "models/project/fact/forecast/ranking/results/eventsTable";
import { useProject } from "models/project/project";
import { useRankingResults } from "pages/ranking/rankingResultsContext";

import { getRangColor } from "../ranking";

import cn from "./results.module.less";

const COLUMNS_SHARED: Column[] = [
  {
    dataKey: "wellTitle",
    title: "Скважина",
    type: "number",
    isSticky: true,
    width: { min: 200, max: 600, competitiveness: 2 },
  },
  {
    dataKey: "mineCode",
    title: "Куст",
    type: "number",
    width: { min: 60, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "eventType",
    title: "Мероприятие",
    type: "string",
    width: { min: 250, max: 600, competitiveness: 2 },
  },
  {
    dataKey: "crewType",
    title: "Тип бригады",
    type: "string",
    width: { min: 120, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "rank",
    key: "rank",
    title: "Ранг",
    type: "number",
    width: { min: 52, max: 52, competitiveness: 1 },
  },
  {
    dataKey: "oilProdPeriod",
    title: "Накопл. добыча нефти за период, тыс т",
    width: { min: 180, max: 600, competitiveness: 0 },
    type: "number",
  },
  {
    dataKey: "liquidProdPeriod",
    title: "Накопл. добыча жид-ти за период, тыс м³",
    type: "number",
    width: { min: 190, max: 600, competitiveness: 0 },
  },
];

const COLUMNS_INCLUDED_GTMS: Column[] = [
  {
    dataKey: "oilProdYear",
    title: "Накопл. добыча нефти за год, тыс т",
    type: "number",
    width: { min: 180, max: 600, competitiveness: 0 },
  },
  {
    dataKey: "liquidProdYear",
    title: "Накопл. добыча жид-ти за год, тыс м³",
    type: "number",
    width: { min: 190, max: 600, competitiveness: 0 },
  },
  {
    dataKey: "start",
    title: "Начало",
    type: "date",
    width: 140,
    renderFormat: "date",
  },
  {
    dataKey: "end",
    title: "Окончание",
    width: 140,
    type: "date",
    renderFormat: "date",
  },
  {
    dataKey: "price",
    title: "Затраты на проведение, млн руб",
    width: 260,
    type: "number",
    onCell: () => ({ style: { justifyContent: "end" } }),
  },
];

const Events: FC<{ gtmsList: "included" | "notIncluded" }> = observer(({ gtmsList }) => {
  const project = useProject();
  const forecast = useForecast()!;
  const results = useRankingResults();
  const store = useMemo(() => new EventsModel(forecast, gtmsList), [forecast, gtmsList]);
  const columns = useMemo(() => {
    return gtmsList === "notIncluded" ? COLUMNS_SHARED : [...COLUMNS_SHARED, ...COLUMNS_INCLUDED_GTMS];
  }, [gtmsList]);

  if (store.isLoading) {
    return (
      <FullScreen>
        <Empty description={<>Необходимо провести планирование</>}>
          <LinkButton to={`/${project?.id}/${forecast.id}/settings`}>Настройки планирования</LinkButton>
        </Empty>
      </FullScreen>
    );
  }
  return (
    <SimpleTableContext
      exportFileName={`Результаты планирования: ${gtmsList === "included" ? "Мероприятия" : "Невошедшие ГТМ"}`}
      columns={columns}
      data={store}
      hideExpandColumn
      theme={{
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
      customRenders={{
        rank: (value, row) => {
          return (
            <Tag className={cn.rank} style={{ borderRadius: "100px" }} color={getRangColor(Number(value))}>
              {Math.round(value)}
            </Tag>
          );
        },
      }}
      tableSettingsId={`planning-sumary-events-${gtmsList}`}
    >
      <PageFrameTitlePortal model={results} submitting={results.isSaving} onSave={results.save} permissionSection="ranking" />
      <Widget headerClassName={cn.tableHeader} />
    </SimpleTableContext>
  );
});

export { Events };
