import { message } from "antd";
import dayJS, { Dayjs } from "dayjs";
import { action, computed, IObservableArray, makeObservable, observable, runInAction, transaction } from "mobx";
import { makePersistable } from "mobx-persist-store";

import type { UOMResolver } from "elements/uom";
import { LicenseRegion } from "models/project/licenseRegion/licenseRegion";
import { User } from "models/user";
import { authLoginService, getUserInfo, LoginInfo } from "services/auth";
import { getFieldsList, getTpp } from "services/back/fields";
import { getAllFields } from "services/back/fieldsTmp";
import { getInterventionTypes } from "services/back/interventions";
import { getLicenseRegions } from "services/back/licenseRegions";
import { getHTRTypes, type HTRType } from "services/back/producingObjectsParams";
import { getMaxFactDate } from "services/back/project";
import { getRoles } from "services/back/roles";
import type { StratumRaw } from "services/back/stratums";
import { getStratums } from "services/back/stratums";
import { getUOMResolver } from "services/back/uom";
import { getUsers } from "services/back/users";
import { getAllWells, getWellTypes, WellType } from "services/back/wells";
import { getTaxDiscounts } from "services/finance/tax/tax";
import { getProvincesList, Province } from "services/provinces";
import { TOKEN_HOLDER } from "utils/request";

import { LoadableStore } from "./loadableStore/loadableStore";
import { Stratum } from "./project/stratum/stratum";
import { Logger } from "./logger";

class Global {
  public readonly IS_DEBUG_ZONE =
    new Set(["localhost:3000", "localhost:3001"]).has(window.location.host) ||
    window.location.host.endsWith("iprm.online");

  public user: User | null | undefined;
  public lastFactDate: Dayjs | undefined;

  public readonly uomResolver: IObservableArray<UOMResolver> = (() => {
    getUOMResolver().then((resolver) => this.uomResolver.replace(resolver));
    return observable.array();
  })();

  public readonly provinces = new LoadableStore<Province>(getProvincesList);

  public readonly htrTypes = new LoadableStore<HTRType>(getHTRTypes);
  public readonly wellTypes = new LoadableStore<WellType>(getWellTypes);

  public readonly taxDiscounts = new LoadableStore(getTaxDiscounts);

  public readonly fields = new LoadableStore(getFieldsList);
  public readonly fieldsTrue = new LoadableStore(getAllFields);

  public readonly tpp = new LoadableStore(getTpp);

  public readonly users = new LoadableStore(getUsers);

  public readonly roles = new LoadableStore(getRoles);

  public readonly wells = new LoadableStore(getAllWells);

  public readonly interventionsTypes = new LoadableStore(getInterventionTypes);

  public readonly licenseRegions = new LoadableStore<LicenseRegion>(() =>
    getLicenseRegions().then((data) => data.map((regionRaw) => new LicenseRegion(regionRaw, null)))
  );

  public readonly stratums = new LoadableStore<Stratum, StratumRaw>(() =>
    getStratums().then((data) => data.map((stratumRaw) => new Stratum(stratumRaw)))
  );

  public readonly logger = new Logger();

  public isNarrowMenu: boolean = false;

  public isPickingFactYears: boolean = localStorage.getItem("isPickingFactYears") !== "false";

  constructor() {
    getMaxFactDate().then((data) => {
      this.lastFactDate = dayJS(data);
    });

    makeObservable<Global>(this, {
      user: observable,
      miningWellTypes: computed,
      isNarrowMenu: observable,
      isPickingFactYears: observable,
      togglePickingFactYears: action,
      toggleNarrowMenu: action,
      login: action,
      logout: action,
      isAuthorized: computed,
    });
    makePersistable(this, {
      name: "global",
      properties: ["isNarrowMenu", "isPickingFactYears"],
      storage: window.localStorage,
    });

    TOKEN_HOLDER.onTokenChange(this.#updateUser);
  }

  readonly #updateUser = () => {
    if (TOKEN_HOLDER.token === null) {
      runInAction(() => {
        this.user = null;
      });
      return;
    }
    runInAction(() => {
      this.user = undefined;
    });
    getUserInfo()
      .then((user) => {
        runInAction(() => {
          this.user = User.fromService(user);
        });
      })
      .catch((error) => {
        console.assert(
          error?.status === 401,
          "Обрыв получения информации о пользователя не по причине инвалидации токена",
          error
        );
        message.warning(
          "Токен авторизации, хранимый в браузере не распознан. Данные об авторизации очищены, приложение переведено в режим авторизации"
        );
        this.logout();
      });
  };

  public async runInPseudoLogoutMode(func: () => Promise<unknown>): Promise<void> {
    const user = this.user;
    this.user = null;
    await func();
    this.user = user;
  }

  public readonly togglePickingFactYears = () => {
    this.isPickingFactYears = !this.isPickingFactYears;
  };

  public readonly toggleNarrowMenu = () => (this.isNarrowMenu = !this.isNarrowMenu);

  get isPseudoLogoutState(): boolean {
    return this.user === null && TOKEN_HOLDER.token !== null;
  }

  get isAuthorized(): boolean {
    return this.user !== null && this.user !== undefined;
  }

  public readonly login = (credentials: LoginInfo) =>
    authLoginService(credentials).then((token) =>
      runInAction(() => {
        TOKEN_HOLDER.token = token;
      })
    );

  public get miningWellTypes(): Set<string> | undefined {
    if (this.wellTypes.isLoading) {
      return undefined;
    }
    const types = [...this.wellTypes.values!].map(({ title }) => title);
    return new Set(types.filter((type) => type !== "Нагнетательная"));
  }

  public readonly logout = () => {
    transaction(() => {
      TOKEN_HOLDER.token = null;
      this.user = null;
    });
  };
}

const global = new Global();

export { global };
