import { Dayjs } from "dayjs";
import { ObjectToCamel, objectToCamel, ObjectToSnake, objectToSnake } from "ts-case-convert/lib/caseConvert";

import { global } from "models/global";
import { Metric } from "services/finance/utils";
import { convertPressure } from "utils/convertePressure";
import { Range } from "utils/range";
import { req, reqCamel } from "utils/request";

import { reqLogged } from "../logger";
import { formatSchema, Schema } from "../utils";

import { LinkMineNode, NodeType, PipeBoundaryCondition, Segment, Station } from "./types";

type CalculateMultipleInfrastructureType = { date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationOutput;

type FilterCalculateType = PromiseFulfilledResult<CalculateMultipleInfrastructureType>;

type Construction = {
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;
  total: number;
};

const { barToMPa } = convertPressure;

const formatCalculationData = (simulation: PipeSystemSimulationOutput | null): PipeSystemSimulationOutput | null => {
  if (simulation === null) {
    return null;
  }
  return {
    nodes: simulation.nodes.map((node) => ({ ...node, pressure: barToMPa(node.pressure) ?? 0 })),
    segments: simulation.segments.map((segment) => ({
      ...segment,
      limitingPressureGradient: barToMPa(segment.limitingPressureGradient),
      pressureDelta: barToMPa(segment.pressureDelta) ?? 0,
      pressureGradient: barToMPa(segment.pressureGradient) ?? 0,
    })),
  };
};

type SimulationInput = {
  density_oil?: number;
  density_water?: number;
  density_gas?: number;
  mu_water?: number;

  pvdg?: Array<[number, number, number]>;
  pvto?: Array<[number, number, number, number]>;

  nodes: NodeType[];
  segments: Segment[];

  links_mine_node: Array<
    LinkMineNode & {
      boundary_condition: {
        oil_rate: number;
        water_rate: number;
      };
      regime: "inj" | "prod";
    }
  >;

  stations: Array<Station>;

  drain_sources: PipeBoundaryCondition[];
};

type Mine = LinkMineNode & {
  fluidRateT: number;
  oilRateT: number;
  waterRateT: number;
  injRateT: number;
  mineTitle: string;
};

type SegmentType = Segment & { velocity: number; fluidRateM3: number; pressureDelta: number; pressureGradient: number };

type PipeSystemSimulationOutput = {
  nodes: Array<NodeType & { pressure: number }>;
  segments: SegmentType[];
};

type SimulationOutput = {
  prod: PipeSystemSimulationOutput | null;
  inj: PipeSystemSimulationOutput | null;
  mines: Mine[];
};

type SimulationInputRaw = ObjectToCamel<SimulationInput>;

async function calculateInfrastructure(simulationInput: SimulationInputRaw): Promise<SimulationOutput> {
  const response = await reqLogged.post<SimulationOutput>("calculation/infrastructure", objectToSnake(simulationInput));
  return { ...response, inj: formatCalculationData(response.inj), prod: formatCalculationData(response.prod) };
}

async function calculateMultipleInfrastructures(
  simulationInputs: ({ date: Dayjs } & SimulationInputRaw)[]
): Promise<CalculateMultipleInfrastructureType[]> {
  const path = "calculation/infrastructure";

  const promises = simulationInputs.map(async (item) => {
    const response = await reqCamel.post<SimulationOutput>(path, objectToSnake(item));
    return {
      ...response,
      inj: formatCalculationData(response.inj),
      prod: formatCalculationData(response.prod),
      date: item.date,
      drainSources: item.drainSources,
    };
  });
  const results = await Promise.allSettled(promises);
  const filteredResponses = results.filter((result): result is FilterCalculateType => result.status === "fulfilled");
  global.logger.addNote(`post:${path}`);
  return filteredResponses.map((result) => result.value);
}

type ResPipeCost<T> = {
  res: Array<
    {
      constrNominal: Construction;
      reconstrNominal: Construction;
      constrCost: number;
      costs: number[];
      reconstCost: number;
      years: number[];
    } & T
  >;
};

type ResponsePipeCost = {
  mines: Omit<ResPipeCost<Mine>, "reconstCost">;
  segments: ResPipeCost<Segment>;
  stations: ResPipeCost<Station>;
  schema_: {
    schema: Schema[];
  };
};

async function calculatePipeCost(
  simulationInput: { scenarioId: number; constructionPriceIndex?: (number | null)[] } & SimulationInputRaw,
  range: Range
): Promise<{ schema: Metric[] } & Omit<ResponsePipeCost, "schema_">> {
  const response = await req.post<ObjectToSnake<ResponsePipeCost>>("pipe/cost", objectToSnake(simulationInput));
  return {
    ...objectToCamel(response),
    schema: formatSchema(response.schema_.schema, range, range.from),
  };
}

export type {
  Mine,
  ResPipeCost,
  ResponsePipeCost,
  SegmentType,
  SimulationInputRaw as SimulationInput,
  SimulationOutput,
};
export { calculateInfrastructure, calculateMultipleInfrastructures, calculatePipeCost };
