import { Metric } from "services/finance/utils";
import { req } from "utils/request";

import { formatSchema, ParamsResponse } from "./utils";

const getConstantsResolver = async (): Promise<{ constants: Metric[] }> => {
  const { constants } = await req.get<ParamsResponse<"constants">>("demo/schemas/basic");
  return {
    constants: formatSchema(constants.schema),
  };
};

export { getConstantsResolver };
