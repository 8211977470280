import { backendStorage } from "services/back/backendStorage";
import { StorageKey } from "services/finance/utils";

import type { ColumnKey, CustomColumnType, RowKey } from "./customColumns";

const META_PREFIX = "custom-columns-meta-";
const DATA_PREFIX = "custom-columns-";

type CustomColumnsMeta = Array<{
  columnKey: ColumnKey;
  columnType: CustomColumnType;
}>;

async function getMeta(storageKey: StorageKey, tableKey: string) {
  const { fact, forecast } = storageKey;
  const response = await backendStorage.getItem<CustomColumnsMeta>(META_PREFIX + tableKey, fact, forecast);
  return response;
}

async function saveMeta(storageKey: StorageKey, tableKey: string, meta: CustomColumnsMeta) {
  const { fact, forecast } = storageKey;
  const response = await backendStorage.setItem<CustomColumnsMeta>(meta, META_PREFIX + tableKey, fact, forecast);
  return response;
}

type CustomColumnsData<T> = Record<RowKey, T>;

async function getData<T>(storageKey: StorageKey, tableKey: string) {
  const { fact, forecast } = storageKey;
  const response = await backendStorage.getItem<CustomColumnsData<T>>(DATA_PREFIX + tableKey, fact, forecast);
  return response;
}

async function saveData<T>(storageKey: StorageKey, tableKey: string, data: CustomColumnsData<T>) {
  const { fact, forecast } = storageKey;
  const response = await backendStorage.setItem<CustomColumnsData<T>>(data, DATA_PREFIX + tableKey, fact, forecast);
  return response;
}

export { getData, getMeta, saveData, saveMeta };
