import { FC, Fragment, memo, ReactNode, useEffect, useMemo } from "react";
import { Typography } from "antd";
import { when } from "mobx";
import { observer } from "mobx-react";
import { ResultValidator, useResultValidatorManager, validationMessageTypeLevel } from "routing/outlines/result/resultInformer";

import { CompleteIcon } from "elements/icons/complete/complete";
import { Loader } from "elements/loader";
import {
  getEconomicParamsValidator,
  getGtmTechValidator,
  getWellsBaseTechValidator,
  getWellsForecastTechValidator,
} from "features/results/resultValidators";
import { global } from "models/global";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useBooleanState } from "utils/useBooleanState";

import cn from "./calculationPreloader.module.less";

type Model = {
  isLoading: boolean;
};

const LoadIndicator: FC<{ promise: Promise<unknown>; ready: ReactNode; loading: ReactNode; resultValidatorKeys?: string[] }> = memo(
  ({ promise, ready, loading, resultValidatorKeys }) => {
    const validatorManager = useResultValidatorManager();

    const [isWrong, isWarning] = (() => {
      if (resultValidatorKeys) {
        const validationResult = validatorManager?.validationResultByKeys(resultValidatorKeys);
        if (validationResult?.hasMessages && validationResult.level) {
          return [validationResult.level >= validationMessageTypeLevel.error, validationResult.level >= validationMessageTypeLevel.warning];
        }
        return [false, false];
      } else {
        return [false, false];
      }
    })();

    const [isLoaded, setLoaded] = useBooleanState();
    useEffect(() => {
      promise.then(setLoaded);
    }, [promise, setLoaded]);

    return (
      <>
        {isLoaded ? (
          <CompleteIcon size={18} completed={isWrong || isWarning ? false : true} warning={isWarning} wrong={isWrong} />
        ) : (
          <Loader className={cn.loader} />
        )}
        <Typography.Text>{isLoaded ? ready : loading}</Typography.Text>
      </>
    );
  }
);

const LoadListIndicator = observer(
  ({
    model,
    ready,
    loading,
    resultValidatorKeys,
  }: {
    model: Model | Model[];
    ready: ReactNode;
    loading: ReactNode;
    resultValidatorKeys?: string[];
  }) => {
    const promise = useMemo(
      () => when(() => (Array.isArray(model) ? model.find(({ isLoading }) => isLoading) !== undefined : model.isLoading) === false),
      [model]
    );
    return (
      <li>
        <LoadIndicator promise={promise} ready={ready} loading={loading} resultValidatorKeys={resultValidatorKeys} />
      </li>
    );
  }
);

const CalculationPreloader = observer(({ onFinishLoading }: { onFinishLoading?: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const forecast = useForecast()!;
  const models = useMemo(
    () => [
      global.licenseRegions,
      forecast.investParams,
      forecast.fact.investParams,
      forecast.ecyStore,
      forecast.wealthTax,
      forecast.fact.wealthTax,

      forecast.operatingRevenue,
      forecast.fact.operatingRevenue,
      forecast.fact.operatingParams,

      forecast.nddTax,
      forecast.fact.nddTax,

      forecast.severanceTax,
      forecast.fact.severanceTax,

      forecast.depositParams,
      forecast.fact.depositParams,

      forecast.referenceParams,
      forecast.fact.referenceParams,

      forecast.investCosts,
      forecast.operatingCosts,
      forecast.production,
      forecast.fact.production,
    ],
    [forecast]
  );
  const promise = useMemo(() => when(() => models.find((model) => model.isLoading === true) === undefined), [models]);

  const resultValidator = useMemo(() => {
    return new ResultValidator([
      getEconomicParamsValidator(forecast),
      getWellsBaseTechValidator(forecast),
      getWellsForecastTechValidator(forecast),
      getGtmTechValidator(forecast),
    ]);
  }, [forecast]);

  useResultValidatorManager(resultValidator);
  useEffect(() => {
    promise.then(() => onFinishLoading?.(false));
  }, [onFinishLoading, promise]);

  return (
    <ul className={cn.checklist}>
      <LoadListIndicator
        model={global.licenseRegions}
        ready="Справочник лицензионных участков загружен"
        loading="Загрузка справочника лицензионных участков"
      />
      {forecast.licenseRegions.ids.map((id) => (
        <Fragment key={id}>
          <LoadListIndicator
            model={forecast.investParams.get(id)}
            ready={`Параметры инвестиционной деятельности для лицензионного участка ${id} загружена`}
            loading={`Загрузка параметров инвестиционной деятельности для лицензионного участка ${id}`}
          />
          <LoadListIndicator
            model={forecast.fact.investParams.get(id)}
            ready={`Информация об осуществлённой инвестиционной деятельности для лицензионного участка ${id} загружена`}
            loading={`Загрузка информации об осуществлённой инвестиционной деятельности для лицензионного участка ${id}`}
          />
          <LoadListIndicator
            model={forecast.wealthTax.get(id)}
            ready={`Налоговое окружение для лицензионного участка ${id} загружено`}
            loading={`Загрузка налогового окружения для лицензионного участка ${id}`}
          />
          <LoadListIndicator
            model={forecast.fact.wealthTax.get(id)}
            ready={`Исторические данные налогового окружения в для лицензионного участка ${id} загружены`}
            loading={`Загрузка исторических данных налогового окружения для лицензионного участка ${id}`}
          />
          <LoadListIndicator
            model={[forecast.referenceParams.get(id), forecast.fact.referenceParams.get(id)]}
            ready={`Справочные параметры для лицензионного участка ${id} загружены`}
            loading={`Загрузка справочных параметров для лицензионного участка ${id}`}
          />
          <LoadListIndicator
            model={[forecast.operatingRevenue.get(id), forecast.fact.operatingRevenue.get(id), forecast.fact.operatingParams.get(id)]}
            ready={`Параметры операционной деятельности для лицензионного участка ${id} загружены`}
            loading={`Загрузка параметров операционной деятельности для лицензионного участка ${id}`}
          />
        </Fragment>
      ))}
      <LoadListIndicator model={forecast.ecyStore} ready={`Единые сценарные условия загружены`} loading={`Загрузка единых сценарных условий`} />
      <LoadListIndicator model={[forecast.nddTax, forecast.fact.nddTax]} loading={`Загрузка параметров НДД`} ready={`Параметры НДД загружены`} />
      <LoadListIndicator
        model={[forecast.severanceTax, forecast.fact.severanceTax]}
        loading={`Загрузка параметров налогового окружения`}
        ready={`Параметры налогового окружения загружены`}
      />
      <LoadListIndicator
        model={[forecast.depositParams, forecast.fact.depositParams]}
        loading={`Загрузка информации о запасах`}
        ready={`Информация о запасах загружена`}
      />
      <LoadListIndicator
        model={forecast.investCosts}
        loading={`Загрузка параметров инвестиционной деятельности`}
        ready={`Параметры инвестиционной деятельности" загружены`}
      />
      <LoadListIndicator
        model={forecast.operatingCosts}
        loading={`Загрузка параметров операционной деятельности`}
        ready={`Параметры операционной деятельности загружены`}
      />
      <LoadListIndicator
        model={forecast.production}
        ready="Результат технологического прогноза загружен"
        loading="Загрузка результатов технологического прогноза"
        resultValidatorKeys={["techForecast"]}
      />
      <LoadListIndicator model={forecast.fact.production} ready="История добычи загружена" loading="Загрузка истории добычи" />
    </ul>
  );
});

export { CalculationPreloader, LoadIndicator };
