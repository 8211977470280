function* byPairs<T>(items: T[]): Iterable<[T, T]> {
  for (let i = 1; i < items.length; ++i) {
    yield [items[i - 1], items[i]];
  }
}

//можно пересесть на либу но пока написать своё проще и понятнее https://github.com/nvie/itertools/blob/main/src/itertools.ts#L218
function* zip<T1, T2>(xs: Array<T1>, ys: Array<T2>): Iterable<[T1, T2]> {
  const len = Math.min(xs.length, ys.length);
  for (let i = 0; i < len; ++i) {
    yield [xs[i], ys[i]];
  }
}

function* zipLongest<T1, T2>(xs: Array<T1>, ys: Array<T2>): Iterable<[T1 | undefined, T2 | undefined]> {
  const len = Math.max(xs.length, ys.length);
  for (let i = 0; i < len; ++i) {
    yield [xs[i], ys[i]];
  }
}

function* zipN<T extends any[]>(...iterables: { [K in keyof T]: Iterable<T[K]> }): Generator<T> {
  const iterators = iterables.map((iterable) => iterable[Symbol.iterator]());

  while (true) {
    const items = iterators.map((iterator) => iterator.next());

    if (items.some((item) => item.done)) {
      break;
    }
    yield items.map((item) => item.value) as T;
  }
}

function* cartesianProduct<T extends any[]>(...arrays: { [K in keyof T]: Array<T[K]> }): Generator<T> {
  const cumulativeLengths = arrays
    .reduceRight((acc, cur) => {
      const length = cur.length;
      acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + length);
      return acc;
    }, [])
    .reverse();
  const [fullLength] = cumulativeLengths;

  function currentValue<G>(array: G[], iteration: number, position: number): G {
    if (position === cumulativeLengths.length - 1) {
      return array[iteration % array.length];
    }
    const nom = iteration % cumulativeLengths[position];
    const denom = cumulativeLengths[position + 1];
    return array[Math.floor(nom / denom)];
  }

  for (let i = 0; i < fullLength; ++i) {
    yield arrays.map((arr, j) => currentValue(arr, i, j)) as T;
  }
}

function transpose<T>(matrix: Array<Array<T>>): Array<Array<T>> {
  if (matrix.length === 0) {
    return [];
  }
  const height = matrix[0].length;
  const result = new Array(height).fill(null).map(() => new Array(matrix.length));

  for (let i = 0; i < matrix.length; ++i) {
    console.assert(matrix[i].length === height, "Попытка транспонирования не прямоугольной матрицы");
    for (let j = 0; j < height; ++j) {
      result[j][i] = matrix[i][j];
    }
  }
  return result;
}

function transposeArrayObject<T extends Record<string, any>>(
  elements: Array<T | null>,
  defaultValue: T
): { [K in keyof T]: Array<T[K]> } | null {
  if (elements.length === 0) {
    return null;
  }
  const keys = [...Object.keys(defaultValue)];
  const result = new Map<keyof T, Array<number | null>>(keys.map((k) => [k, []]));
  for (const element of elements) {
    for (const key of keys) {
      result.get(key)!.push(element?.[key] ?? null);
    }
  }
  return Object.fromEntries(result) as any;
}

export { byPairs, cartesianProduct, transpose, transposeArrayObject, zip, zipLongest, zipN };
