import ReactDOM from "react-dom/client";
import { TimeAgoProvider } from "react-timeago-i18n";
import { ConfigProvider } from "antd";

import { Theme } from "theme/customAntdTheme";

import { RootRouter } from "./routing/rootRouter";
import reportWebVitals from "./utils/reportWebVitals";

import "@okopok/axes_context/style.css";
import "./theme/global.less";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ConfigProvider theme={Theme}>
    <TimeAgoProvider locale="RU-ru" hideSeconds>
      <RootRouter />
    </TimeAgoProvider>
  </ConfigProvider>
);

reportWebVitals();
