import { Dayjs } from "dayjs";

const DATE_FORMAT = "YYYY-MM-DD" as const;

class DateMinHeap {
  private uniqueElements = new Set<string>();
  private store: Array<Dayjs> = [];

  public get size(): number {
    return this.store.length;
  }

  public push(date: Dayjs) {
    if (this.uniqueElements.has(date.format(DATE_FORMAT))) {
      return;
    } else {
      this.uniqueElements.add(date.format(DATE_FORMAT));
    }
    this.store.push(date);
    let childIdx = this.store.length - 1;
    let parentIdx = DateMinHeap.parentIdx(childIdx);
    while (childIdx > 0 && this.store[childIdx].isBefore(this.store[parentIdx])) {
      [this.store[childIdx], this.store[parentIdx]] = [this.store[parentIdx], this.store[childIdx]];
      childIdx = parentIdx;
      parentIdx = DateMinHeap.parentIdx(childIdx);
    }
  }

  public peek(): Dayjs | undefined {
    return this.store[0];
  }

  public popMin(): Dayjs | undefined {
    if (this.store.length === 0) {
      return undefined;
    }
    const result = this.store[0];
    this.store[0] = this.store.at(-1)!;
    let parentIdx = 0;
    // while (have 2 childs)
    while (DateMinHeap.rightChildIdx(parentIdx) < this.store.length) {
      const leftChildIdx = DateMinHeap.leftChildIdx(parentIdx);
      const rightChildIdx = DateMinHeap.rightChildIdx(parentIdx);
      const minSonIdx = this.store[rightChildIdx].isBefore(this.store[leftChildIdx]) ? rightChildIdx : leftChildIdx;
      if (this.store[parentIdx].isAfter(this.store[minSonIdx])) {
        [this.store[parentIdx], this.store[minSonIdx]] = [this.store[minSonIdx], this.store[parentIdx]];
        parentIdx = minSonIdx;
      } else {
        break;
      }
    }
    this.store.pop();
    this.uniqueElements.delete(result.format(DATE_FORMAT));
    return result;
  }

  static parentIdx(childIdx: number): number {
    return Math.floor((childIdx - 1) / 2);
  }

  static leftChildIdx(parentIdx: number): number {
    return parentIdx * 2 + 1;
  }

  static rightChildIdx(parentIdx: number): number {
    return parentIdx * 2 + 2;
  }
}

export { DateMinHeap };
