import { ResultPage } from "routing/outlines/result/resultPage";

import { CashflowResults } from "features/results/cashflow";

import styles from "./investReport.module.less";

const Cashflow = () => {
  return (
    <ResultPage scroll={false} title="Результаты расчета: денежные потоки">
      <div key="any key" className={styles.wrap}>
        <CashflowResults />
      </div>
    </ResultPage>
  );
};

export { Cashflow };
