import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { TechForecast } from "features/techForecast/techForecast";
import { TypeForecastSettings } from "features/techForecast/typeForecastSettings/typeForecastSettings";
import { Debet as DebetFeature } from "features/techPrediction/debet";

import { DEBIT_PRELOADER } from "../preloads";

import { DebetContextProvider } from "./context";

const TABS: TabItem[] = [
  {
    key: "results",
    label: "Скважины",
    default: true,
    children: <DebetFeature />,
  },
  {
    key: "forecast",
    label: "Прогноз",
    children: <TechForecast />,
  },
  {
    key: "settings",
    label: "Типовые кривые",
    children: <TypeForecastSettings />,
  },
];

const Debet = () => (
  <ForecastSelector>
    <Preloader hooks={DEBIT_PRELOADER}>
      <DebetContextProvider>
        <PageFrame tabs={TABS} title="Прогноз технологических показателей" />
      </DebetContextProvider>
    </Preloader>
  </ForecastSelector>
);

export { Debet };
