import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, makeObservable, runInAction } from "mobx";

import { ProjectParticipantInfo } from "services/back/projectParticipants";
import { ProjectRole } from "services/back/roles";

import { Project } from "./project/project";

type ExtendedParticipantInfo = ProjectParticipantInfo & {
  roles: ProjectRole[];
  projectId: number;
  refresh?: () => void;
  addParticipant?: (uId: number) => Promise<void>;
  deleteParticipant?: () => Promise<void>;
  addRole?: (rId: number) => Promise<void>;
  deleteRole?: (rId: number) => Promise<void>;
};

class ProjectParticipantsStore extends TableNode<ExtendedParticipantInfo, ParticipantNode> {
  constructor(private project: Project) {
    super();

    const { participants } = project;

    this.childrenStore = new ChildrenStoreArray(
      this,
      participants.tableData.map((info) => new ParticipantNode(this, info))
    );

    makeObservable(this, {
      refresh: action,
    });
  }

  refresh = async () => {
    const { participants } = this.project;

    this.childrenStore = new ChildrenStoreArray(
      this,
      participants.tableData.map((info) => new ParticipantNode(this, info))
    );
  };
}

class ParticipantNode extends TableNode<ExtendedParticipantInfo> {
  public asDRow(): ExtendedParticipantInfo {
    return {
      ...this.personInfo,
      refresh: this.refresh,
    };
  }
  refresh: () => void;
  constructor(parentNode: ProjectParticipantsStore, public readonly personInfo: ExtendedParticipantInfo) {
    super(parentNode, { isExpandedChildren: true });
    this.refresh = parentNode.refresh;
    runInAction(() => (this.childrenStore = null));
  }
}

export { ProjectParticipantsStore };
export type { ExtendedParticipantInfo };
