import { FC, HTMLAttributes, PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";

import { CustomLeaf, Tree } from "elements/tree/tree";
import { useProjectContext } from "models/project/context/projectContext";
import { TreeContextProvider } from "models/tree/context";
import { TreeNode } from "models/tree/tree";

import { useWellTreePreloading } from "./useWellTreePreloading";

type WellsTreeProps = {
  title: string;
  className?: string;
  customLeaf?: CustomLeaf;
  onRow?: (node?: TreeNode<any>) => Omit<HTMLAttributes<HTMLDivElement>, "style">;
};

const WellsTree: FC<WellsTreeProps & PropsWithChildren> = observer(({ title, className, customLeaf, onRow, children }) => {
  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;
  return (
    <TreeContextProvider value={tree}>
      <Tree title={title} className={className} customLeaf={customLeaf} onRow={onRow} preloadHooks={[useWellTreePreloading(tree)]}>
        {children}
      </Tree>
    </TreeContextProvider>
  );
});

export { WellsTree };
