import { observer } from "mobx-react-lite";
import { ParamsPage } from "routing/outlines/params/paramsPage";

import type { Params as ParamsModel } from "models/params/params";

type OperatingModels = {
  operatingRevenue: {
    get: (key: number) => ParamsModel;
  };
  operatingParams: {
    get: (key: number) => ParamsModel;
  };
};

type OperatingModelsForecast = {
  operatingCosts: ParamsModel;
  operatingRevenue: {
    get: (key: number) => ParamsModel;
  };
};

const forecast = [
  {
    key: "costs",
    title: "Удельные затраты",
    model: (data: OperatingModelsForecast) => data.operatingCosts,
  },
  {
    key: "revenue",
    title: "Выручка",
    isNeedLicense: true,
    model: (data: OperatingModelsForecast, licenseKey?: number) => data.operatingRevenue.get(licenseKey!),
  },
];

const fact = [
  {
    key: "revenue",
    title: "Выручка",
    isNeedLicense: true,
    model: (data: OperatingModels, licenseKey?: number) => data.operatingRevenue.get(licenseKey!),
  },
  {
    key: "years",
    title: "Параметры по годам",
    model: (data: OperatingModels, licenseKey?: number) => data.operatingParams.get(licenseKey!),
    hideSums: false,
    isNeedLicense: true,
  },
];

const OperatingParameters = observer(() => {
  return <ParamsPage factTabs={fact} forecastTabs={forecast} />;
});

export { OperatingParameters };
