import { ReactNode, useEffect, useState } from "react";
import { TableNode, Widget } from "@okopok/components/Table";
import { Button, Card, Upload, UploadFile } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { Reset } from "elements/icons/reset";
import { Loader } from "elements/loader";
import { ModelContentComponentType } from "elements/modal/modal";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { GenericTableRow } from "services/back/genericTable/genegicTableService";

import { ReactComponent as AttachIcon } from "./icons/attachIcon.svg";
import { ReactComponent as WarningIcon } from "./icons/warningIcon.svg";
import { TemplateCard } from "./templateInfoCards";
import { TemplateTable } from "./templateTable";

import styles from "./importModalContent.module.less";

type TableNodeBase = {
  isComplete: boolean;
  isValid: boolean;
  newRows: any[];
};

type TableState<TNode extends TableNode<any, any> & TableNodeBase> = {
  columns: Column[];
  customRenders?: Record<string, (value: any, row: GenericTableRow) => ReactNode>;
  file?: UploadFile | null;
  parseExcel: (file: UploadFile) => Promise<TNode>;
  template: {
    templateLink: ReactNode;
    infoCards?: TemplateCard[];
    data: any[];
    columns: ColumnsType<any>;
  };
};

type ImportModalResolve = {
  file: UploadFile | null;
  newRows: any[];
  replace: boolean;
};

const ImportModalContent: ModelContentComponentType<ImportModalResolve, TableState<any>> = observer((props) => {
  const { dataRef: ref, reject, setOnAccept, accept } = props;
  const { template, columns, customRenders, parseExcel } = ref.current;

  const [file, setFile] = useState<UploadFile | null>(ref.current?.file || null);

  const [preview, setPreview] = useState<(TableNode<any> & TableNodeBase) | null>(null);
  const [isError, setIsError] = useState(false);

  const isDisabled = preview !== null ? !preview.isValid : false;
  const isComplete = preview !== null ? preview.isComplete : false;

  useEffect(() => {
    if (!file) {
      return;
    }

    setIsError(false);
    const parseExcelData = async () => {
      try {
        const response = await parseExcel(file);
        setPreview(response);
      } catch (err) {
        setIsError(true);
      }
    };
    parseExcelData();
  }, [file, parseExcel]);

  const resetPreviews = () => {
    setFile(null);
    setPreview(null);
  };

  const onFinish = async (isReplace = false) => {
    if (!preview?.children) {
      return;
    }
    return {
      result: true,
      data: {
        file: isReplace ? file : null,
        newRows: preview.newRows,
        replace: isReplace,
      },
    };
  };

  const onSubmit = (isReplace: boolean = false) => {
    setOnAccept(() => onFinish(isReplace));
    accept();
  };

  return (
    <div>
      {file === null || isError ? (
        <TemplateTable {...template} fileName={file?.name || ""} isError={isError} onFile={setFile} />
      ) : (
        <>
          {preview === null && <Loader className={styles.loader} />}
          {preview !== null && (
            <>
              <div className={styles.titleBlock}>
                <div>
                  Импортируемый файл: <b>{file?.name}</b>
                </div>
                <div className={styles.uploadButtons}>
                  <Upload onChange={({ file }) => setFile(file)} accept=".xlsx, .xls" beforeUpload={() => false} showUploadList={false}>
                    <Button icon={<Icon content={<AttachIcon />} className={styles.attachIcon} viewBox="0 0 16 16" />}>Выбрать другой файл</Button>
                  </Upload>
                  <Button icon={<Reset className={styles.attachIcon} />} onClick={resetPreviews}>
                    Сбросить
                  </Button>
                </div>
              </div>
              <div className={styles.previewText}>Предпросмотр</div>
              <SimpleTableContext
                tableOptions={{
                  onRow: ({ value }) => ({ className: classNames(!value?.isComplete && styles.isIncomplete) }),
                }}
                hideExpandColumn
                exportFileName="Программа ГТМ"
                data={preview}
                customRenders={customRenders}
                columns={columns}
              >
                <div style={{ height: "100%" }}>
                  <Widget className={styles.widget} />
                </div>
              </SimpleTableContext>
            </>
          )}
        </>
      )}
      <div className={styles.footer}>
        {file && !isComplete && (
          <Card className={styles.warningBlock}>
            <Icon content={<WarningIcon />} className={styles.attachIcon} viewBox="0 0 16 16" />
            {isDisabled ? "Данные некорректны и не могут быть импортированы." : "Часть данных не может быть импортирована."}
          </Card>
        )}
        <Button onClick={reject}>Отмена</Button>
        <Button onClick={() => onSubmit()} disabled={isDisabled || file === null} type="primary">
          Добавить
        </Button>
        <Button onClick={() => onSubmit(true)} disabled={isDisabled || file === null} type="primary">
          Импортировать с заменой
        </Button>
      </div>
    </div>
  );
});

export { ImportModalContent, type ImportModalResolve, type TableState };
