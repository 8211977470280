import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, override, reaction, runInAction, transaction, when } from "mobx";

import { PeriodSettings } from "features/injPrediction/multiPeriodModal/multiPeriodForm";
import { LoadableStoreResolver } from "models/loadableStore/loadableStoreResolver";
import { Intervention } from "models/project/fact/forecast/interventions/intervention";
import { Interventions } from "models/project/fact/forecast/interventions/interventions";
import { Production } from "models/project/fact/production/production";
import { StratumData } from "models/project/fact/production/stratumData";
import { Well } from "models/project/fact/well/well";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { ProducingObjects } from "models/project/producingObject/producingObjects";
import { Stratum } from "models/project/stratum/stratum";
import { TreeRoot } from "models/tree/tree";
import { CompensationCoefficientsRaw } from "services/back/injectionPrediction/compensationCoefficients";
import { type InjectionInterval, savePeriods } from "services/back/injectionPrediction/savePeriods";
import { conditionallyArr } from "utils/conditionally";
import { DateRange } from "utils/dateRange";
import { byPairs } from "utils/itertools";

import { ProductionDataPack } from "../../production/productionData";
import { Forecast } from "../forecast";

type Period = Pick<StratumData, "range" | "stratumId" | "status">;

type DRow = {
  wellId?: number; // debug column
  gtmId?: number; // debug column
  wellTitle: string;
  mineTitle?: string;
  eventTitle?: string;
  licenseRegionTitle?: string;
  start?: Dayjs;
  end?: Dayjs;
  periodStatus?: "prod" | "inj" | "idle";
  producingObjectId?: number | null;
  stratumId?: number | null;
  compensationCoefficient?: number | null;

  producingObjectsResolver?: LoadableStoreResolver<ProducingObject>;
  isIntersected?: boolean;
  notEditable?: boolean;
  remove?: () => void;
};

function isIntersected(a: [Dayjs, Dayjs], b: [Dayjs, Dayjs]): boolean {
  const [s1, e1] = a;
  const [s2, e2] = b;
  return !(s1 > e2 || s2 > e1);
}

function sortByStart(a: PeriodNode, b: PeriodNode): number {
  return a.start.diff(b.start, "month") || a.end.diff(b.end, "month");
}

function getLastMonthProductions(forecast: Forecast, wellId: number) {
  const factLastDate = forecast.fact.project.actualStateDate.date(1);
  const byStratums = forecast.fact.production.wellData(wellId)?.dataByStratums;
  const lastDatums = [...(byStratums?.values() ?? [])].map((datums) => datums[datums.length - 1]);
  return lastDatums
    .map((d) => [d.stratumId, d.status, d.getForMonth(factLastDate.year(), factLastDate.month())] as const)
    .filter(([_, status, datum]) => status === "inj" && datum !== undefined);
}

class PeriodNode extends TableNode<DRow> {
  asDRow = (): DRow => ({
    wellId: this.well.id,
    gtmId: this.gtm?.id,
    wellTitle: this.well.title,
    mineTitle: this.well.pad?.title,
    eventTitle: this.eventTitle,
    licenseRegionTitle: this.well.licenseRegion?.title,
    start: this.start,
    end: this.end,
    periodStatus: this.period.status,
    producingObjectId: this.producingObject?.id ?? null,
    stratumId: this.stratumId,
    compensationCoefficient: this.compensationCoefficient,

    producingObjectsResolver: this.parent.producingObjects,
    isIntersected: this.isIntersected,
    notEditable: this.notEditable,
    remove: this.remove,
  });

  public producingObject: ProducingObject | null;
  public start: Dayjs;
  public end: Dayjs;
  public isIntersected: boolean = false;

  constructor(
    private parent: WellNode,
    public readonly period: Period,
    public readonly gtm: Intervention | null = null
  ) {
    super(parent);
    this.childrenStore = null;
    makeObservable(this, {
      compensationCoefficient: computed,
      start: observable.ref,
      end: observable.ref,
      producingObject: observable.ref,
      isIntersected: observable,
      isValid: computed,
      updateValue: action,
    });
    this.producingObject = this.initProducingObject();
    this.start = dayjs(this.period.range.from);
    this.end = dayjs(this.period.range.to);
    this.initCompensationCoefficient();
  }

  public get stratumId(): number | null {
    return this.producingObject?.data.mainStratumId ?? null;
  }

  public get compensationCoefficient(): number | null {
    if (this.period.status !== "prod" || !this.producingObject) {
      return null;
    }
    return this.parent.compensationCoefficients[this.producingObject.id];
  }

  private initCompensationCoefficient() {
    if (this.period.status !== "prod" || !this.producingObject) {
      return;
    }
    const coeffs = this.parent.compensationCoefficients;
    if (coeffs === undefined) {
      console.error("Коэффициенты компенсации не заданы до загрузки таблицы");
      return;
    }
    if (coeffs[this.producingObject.id] === undefined) {
      coeffs[this.producingObject.id] = 115;
    }
  }

  public get isValid(): boolean {
    if (this.isIntersected) {
      return false;
    }
    return this.producingObject?.id !== undefined;
  }

  public get eventTitle(): string | undefined {
    if (this.period.status !== "prod") {
      return "";
    }
    if (this.gtm !== null) {
      return this.gtm.typeName ?? undefined;
    }
    if (this.well.fond === "New") {
      return "Эксплуатационное бурение";
    } else {
      return "Базовая добыча";
    }
  }

  private initProducingObject(): ProducingObject | null {
    const stratums = this.parent.parent.forecast.fact.stratums;
    const stratum = stratums.at(this.period.stratumId);
    if (!stratum) {
      return null;
    }
    return stratums.getProducingObject(stratum) ?? null;
  }

  private get well(): Well {
    return this.parent.well;
  }

  public get notEditable(): boolean {
    return this.period.status !== "inj";
  }

  private remove = () => {
    if (this.index === undefined) {
      console.error("попытка удаления периода без индекса");
      return;
    }
    this.parent.removePeriod(this.index);
  };

  updateValue(key: keyof DRow, newValue: any): [prevValue: any, currValue: any] {
    if (!PeriodNode.isEditableField(key)) {
      console.error("редактирование немутабельного поля");
      return [undefined, undefined];
    }
    if (key === "producingObjectId") {
      const prev = this.producingObject?.id;
      this.producingObject = this.parent.parent.producingObjects.at(newValue) ?? null;
      this.parent.onUpdate();
      return [prev, this.producingObject?.id];
    }
    if (key === "start") {
      const prev = this.start.unix();
      const next = (newValue as Dayjs).unix();
      this.start = newValue;
      if (this.start.isAfter(this.end)) {
        this.mutationsManager?.updateWrapper("end", newValue);
      }
      this.parent.onUpdate();
      return [prev, next];
    }
    if (key === "end") {
      const prev = this.end.unix();
      const next = (newValue as Dayjs).unix();
      this.end = newValue;
      if (this.start.isAfter(this.end)) {
        this.mutationsManager?.updateWrapper("start", newValue);
      }
      this.parent.onUpdate();
      return [prev, next];
    }
    if (
      key === "compensationCoefficient" &&
      this.parent.compensationCoefficients !== undefined &&
      this.producingObject !== null
    ) {
      this.parent.compensationCoefficients[this.producingObject.id] = newValue;
      return [undefined, undefined]; // stop isMutated propagation
    }
    // unreachable
    return [undefined, undefined];
  }

  static isEditableField(key: keyof DRow): key is "start" | "end" | "producingObjectId" | "compensationCoefficient" {
    return ["start", "end", "producingObjectId", "compensationCoefficient"].includes(key);
  }
}

class WellNode extends TableNode<DRow, PeriodNode> {
  asDRow = (): DRow => ({
    wellTitle: this.well.title,
  });

  private initialEncodedInjPeriodsState: string;

  public readonly interventions: Intervention[];
  public readonly producingObjects: LoadableStoreResolver<ProducingObject>;
  public readonly compensationCoefficients: CompensationCoefficientsRaw[number];

  constructor(public readonly parent: InputParams, public readonly well: Well) {
    super(parent, { isExpandedChildren: true });

    this.compensationCoefficients = this.initCompensationCoefficients();
    this.interventions = this.parent.interventions.getInterventionsByWellId(this.well.id);

    const stratums = new Set(
      this.periods
        .map((p) => parent.forecast.stratums.at(p[1].stratumId))
        .filter((s): s is Stratum => s !== null && s !== undefined)
    );
    if (well.fond === "Base") {
      const baseStratumsIds = [...(parent.forecast.fact.production.wellData(well.id)?.dataByStratums.keys() ?? [])];
      baseStratumsIds.forEach((stratumId) => {
        const stratum = parent.forecast.stratums.at(stratumId);
        console.assert(stratum);
        stratums.add(stratum!);
      });
    }

    const availableProducingObjects = new Set(
      [...stratums]
        .map((s) => parent.forecast.stratums.getProducingObject(s)?.id ?? null)
        .filter((id): id is number => id !== null)
    );

    this.producingObjects = new LoadableStoreResolver(this.parent.producingObjects, [
      ...new Set<number>(availableProducingObjects),
    ]);

    this.childrenStore = new ChildrenStoreArray(
      this,
      this.periods.map(([gtm, period]) => new PeriodNode(this, period, gtm))
    );

    makeObservable<WellNode, "recalculateIdle" | "propagateIntersected">(this, {
      isUpdated: override,
      encodedInjPeriodsState: computed,
      injectionPeriods: computed,
      idlePeriods: computed,
      recalculateIdle: action,
      propagateIntersected: action,
    });

    this.initialEncodedInjPeriodsState = this.encodedInjPeriodsState;
    this.onUpdate();
  }

  public onUpdate = () => {
    this.recalculateIdle();
    this.propagateIntersected();
    this.sortByStart();
    this.mutationsManager?.propagateMutation();
  };

  public get encodedInjPeriodsState(): string {
    const periods = this.injectionPeriods.sort(sortByStart).map((p) => ({
      from: p.start.unix(),
      to: p.end.unix(),
      stratumId: p.stratumId,
    }));
    return JSON.stringify(periods);
  }

  public get isUpdated(): boolean {
    return this.initialEncodedInjPeriodsState !== this.encodedInjPeriodsState;
  }

  public initCompensationCoefficients(): CompensationCoefficientsRaw[number] {
    const coeffs = this.parent.compensationCoefficients;
    if (coeffs === undefined) {
      console.error("Коэффициенты компенсации не заданы до загрузки таблицы");
      return {};
    }
    if (coeffs[this.well.id] === undefined) {
      coeffs[this.well.id] = {};
    }
    return coeffs[this.well.id];
  }

  public get injectionPeriods(): PeriodNode[] {
    return [...(this.childrenStore?.children ?? [])].filter((ch) => ch.period.status === "inj");
  }

  public get idlePeriods(): PeriodNode[] {
    return [...(this.childrenStore?.children ?? [])].filter((ch) => ch.period.status === "idle");
  }

  public addInjectionPeriod(stratumId: number, start: Dayjs, end: Dayjs) {
    this.childrenStore?.push(
      new PeriodNode(this, {
        stratumId,
        status: "inj",
        range: new DateRange(start, end),
      })
    );
    this.mutationsManager?.propagateMutation();
    this.onUpdate();
  }

  public removePeriod(childIdx: number) {
    this.childrenStore?.splice(childIdx, 1);
    this.propagateIntersected();
    this.onUpdate();
  }

  private get periods(): Array<[gtm: Intervention | null, period: Period]> {
    const productionDataToPeriod = (
      gtm: Intervention | null,
      data: ProductionDataPack
    ): Array<[gtm: Intervention | null, period: Period]> => {
      const periods: [Intervention | null, Period][] = [];
      data.all.forEach(({ status, stratumId, range }) => {
        let resultStatus = status;
        if (status === "idle") {
          resultStatus = "prod";
        }
        periods.push([gtm, { status: resultStatus, stratumId, range }]);
      });
      return periods;
    };
    const result: Array<[gtm: Intervention | null, period: Period]> = [];
    const wellData = this.parent.production.wellData(this.well.id);
    if (wellData !== undefined) {
      result.push(...productionDataToPeriod(null, wellData));
    }
    for (const intervention of this.interventions) {
      const interventionData = this.parent.production.interventionData(intervention.id);
      if (interventionData !== undefined) {
        result.push(...productionDataToPeriod(intervention, interventionData));
      }
    }
    return result;
  }

  private propagateIntersected() {
    const prodPeriods: PeriodNode[] = [];
    const injPeriods: PeriodNode[] = [];

    for (const child of this.childrenStore?.children ?? []) {
      child.isIntersected = false;
      if (child.period.status === "prod") {
        prodPeriods.push(child);
      } else if (child.period.status === "inj") {
        injPeriods.push(child);
      }
    }

    let someIntersects = false;

    for (const prod of prodPeriods) {
      const s1 = prod.start;
      const e1 = prod.end;
      for (const inj of injPeriods) {
        const s2 = inj.start;
        const e2 = inj.end;
        const intersects = isIntersected([s1, e1], [s2, e2]);
        someIntersects ||= intersects;
        runInAction(() => {
          prod.isIntersected = prod.isIntersected || intersects;
          inj.isIntersected = inj.isIntersected || intersects;
        });
      }
    }

    const n = injPeriods.length;
    for (let i = 0; i < n; ++i) {
      const inj1 = injPeriods[i];
      const s1 = inj1.start;
      const e1 = inj1.end;
      for (let j = 0; j < n; ++j) {
        const inj2 = injPeriods[j];
        if (i === j || inj1.producingObject?.id !== inj2.producingObject?.id) {
          continue;
        }
        const s2 = inj2.start;
        const e2 = inj2.end;
        const intersects = isIntersected([s1, e1], [s2, e2]);
        someIntersects ||= intersects;
        runInAction(() => {
          inj1.isIntersected = inj1.isIntersected || intersects;
          inj2.isIntersected = inj2.isIntersected || intersects;
        });
      }
    }
    if (someIntersects) {
      this.parent.invalidWells.add(this);
    } else {
      this.parent.invalidWells.delete(this);
    }
  }

  public removeAllInjection() {
    if (!this.childrenStore) {
      return;
    }
    const newChilds = [...this.childrenStore.children].filter((child) => child.period.status !== "inj");

    const prevSize = this.childrenStore.size;
    this.childrenStore = new ChildrenStoreArray(this, newChilds);
    const newSize = this.childrenStore.size;
    const delta = newSize - prevSize;
    if (this.index !== undefined) {
      this.isExpandedChildren && this.propagateExpand(delta);
      this.propagateSplice(delta);
    }
    this.mutationsManager?.propagateMutation();

    this.onUpdate();
  }

  private recalculateIdle() {
    if (!this.childrenStore) {
      return;
    }
    const notIdleChilds = [...this.childrenStore.children].filter((child) => child.period.status !== "idle");

    let { from, to } = this.parent.forecast.fact.forecastDateRange;
    from = from.date(1); // set day to 01 (month start)
    to = to.date(1); // set day to 01 (month start)
    const factualStratumId = this.parent.forecast.fact.production.wellData(this.well.id)?.mostValuableStratum;
    const periods: (Omit<Period, "stratumId"> & { stratumId: number | null })[] = [
      ...conditionallyArr(factualStratumId !== undefined, {
        range: new DateRange(from, from),
        status: "idle",
        stratumId: factualStratumId!,
      } as const), // dummy period
      ...notIdleChilds
        .sort(sortByStart)
        .map(
          (child): Period => ({
            range: new DateRange(child.start.subtract(1, "month"), child.end.add(1, "month")),
            stratumId: child.period.stratumId,
            status: child.period.status,
          })
        )
        .filter((period, i, periods) => {
          if (i === 0) {
            return true;
          }
          return period.range.to.isAfter(periods[i - 1].range.to);
        }),
      { range: new DateRange(to, to), status: "idle", stratumId: null }, // dummy period
    ];

    const idles: Period[] = [];
    for (const [prev, curr] of byPairs(periods)) {
      if (!curr.range.from.isBefore(prev.range.to)) {
        console.assert(prev.stratumId !== null);
        idles.push({
          range: new DateRange(prev.range.to, curr.range.from),
          status: "idle",
          stratumId: prev.stratumId!,
        });
      }
    }
    const newChilds = [...notIdleChilds, ...idles.map((period) => new PeriodNode(this, period))];

    const prevSize = this.childrenStore.size;
    this.childrenStore = new ChildrenStoreArray(this, newChilds);
    const newSize = this.childrenStore.size;
    const delta = newSize - prevSize;
    if (this.index !== undefined) {
      this.isExpandedChildren && this.propagateExpand(delta);
      this.propagateSplice(delta);
    }
  }

  public sortByStart() {
    this.childrenStore?.sort(sortByStart);
  }

  public onlyIntersected() {
    this.childrenStore?.filter((ch) => ch.isIntersected);
  }

  public reset() {
    this.childrenStore?.reset();
    this.sortByStart();
  }
}

class InputParams extends TableNode<DRow, WellNode> {
  public readonly invalidWells = observable.set<WellNode>();
  public compensationCoefficients?: CompensationCoefficientsRaw;
  public isSaving: boolean = false;

  constructor(public readonly forecast: Forecast, private readonly tree: TreeRoot<Well>) {
    super();

    makeObservable(this, {
      compensationCoefficients: observable,
      isSaving: observable,
      isUpdated: override,
      isValid: computed,
      propagateCompensationCoefficients: action,
      save: action,
    });

    when(
      () => this.forecast.compensationCoefficients.isLoading === false,
      () => this.init()
    );
  }

  private init() {
    this.compensationCoefficients = this.forecast.compensationCoefficients.coeffs ?? {};
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.wells.map((well) => new WellNode(this, well))
    );

    reaction(
      () => this.tree.selectedItems,
      (selected) => this.filterSelected(new Set(selected.map((node) => node.item!.id))),
      { fireImmediately: true }
    );
  }

  public get isUpdated(): boolean {
    if (super.isUpdated) {
      return true;
    }
    if (!this.compensationCoefficients) {
      return false;
    }
    return !this.forecast.compensationCoefficients.isEqual(this.compensationCoefficients);
  }

  public get isValid(): boolean {
    return this.invalidWells.size === 0;
  }

  public addPeriod(wellId: number, producingObjectId: number, start: Dayjs, end: Dayjs): void {
    const wellNode = [...(this.childrenStore?.children ?? [])].find((node) => node.well.id === wellId);
    const prodObj = this.forecast.fact.producingObjects.at(producingObjectId);
    if (!wellNode || !prodObj) {
      return;
    }
    wellNode.addInjectionPeriod(prodObj.data.mainStratumId, start, end);
  }

  public addManyPeriods(settings: PeriodSettings): void {
    const { from, to } = settings.monthDuration;
    transaction(() => {
      for (const wellNode of this.childrenStore?.children ?? []) {
        if (!settings.fonds.includes(wellNode.well.fond)) {
          continue;
        }
        if (!settings.idleWells) {
          const hasForecastProduction = this.forecast.production.wellData(wellNode.well.id) !== undefined;
          const lastMonthProductions = getLastMonthProductions(this.forecast, wellNode.well.id);
          if (!hasForecastProduction && !(lastMonthProductions && lastMonthProductions.length > 0)) {
            continue;
          }
        }
        const idlePeriods = wellNode.idlePeriods;
        for (const period of idlePeriods) {
          if (period.producingObject === null || !settings.producingObjectsIds.includes(period.producingObject.id)) {
            continue;
          }
          const monthDuration = period.period.range.monthDuration;
          const enoughTime = from === undefined || from <= monthDuration;
          if (!enoughTime) {
            continue;
          }

          let periodEnd = period.period.range.to;
          if (to !== undefined && monthDuration > to) {
            periodEnd = period.period.range.from.add(to, "month");
          }

          wellNode.addInjectionPeriod(period.producingObject.data.mainStratumId, period.period.range.from, periodEnd);
        }
      }
    });
  }

  public removeSelectedWellsInjection = (): void => {
    transaction(() => {
      for (const wellNode of this.children ?? []) {
        wellNode.removeAllInjection();
      }
    });
  };

  public expandBaseInjection = (): void => {
    for (const wellNode of this.children ?? []) {
      if (wellNode.well.fond !== "Base") {
        continue;
      }

      const lastMonthProductions = getLastMonthProductions(this.forecast, wellNode.well.id);
      if (!lastMonthProductions || lastMonthProductions.length === 0) {
        continue;
      }

      let { from, to } = this.forecast.fact.forecastDateRange;
      from = from.date(1); // set day to 01 (month start)
      to = to.date(1); // set day to 01 (month start)
      const startDateByStratum = new Map<number, Dayjs>();
      for (const { period, start } of wellNode.children ?? []) {
        if (period.status === "idle") {
          continue;
        }
        if (period.status === "prod") {
          if (start.isBefore(to)) {
            to = start.subtract(1, "month");
          }
          continue;
        }
        const minDate = startDateByStratum.get(period.stratumId);
        if (minDate !== undefined) {
          startDateByStratum.set(period.stratumId, (start.isBefore(minDate) ? start : minDate).subtract(1, "month"));
        } else {
          startDateByStratum.set(period.stratumId, start.subtract(1, "month"));
        }
      }

      for (const [stratumId] of lastMonthProductions) {
        const startByStratum = startDateByStratum.get(stratumId);
        const endDate = startByStratum?.isBefore(to) ? startByStratum : to;

        if (!from.isAfter(endDate)) {
          wellNode.addInjectionPeriod(stratumId, from, endDate);
        }
      }
    }
  };

  public propagateCompensationCoefficients(coeffsByProdObj: { [producingObjectId: number]: number }) {
    if (!this.compensationCoefficients) {
      return;
    }
    transaction(() => {
      for (const wellCoeffs of Object.values(this.compensationCoefficients!)) {
        for (const prodObjId of Object.keys(wellCoeffs)) {
          wellCoeffs[prodObjId as any] = coeffsByProdObj[prodObjId as any];
        }
      }
    });
  }

  public saveCompensation = async (): Promise<void> => {
    if (this.compensationCoefficients !== undefined) {
      return await this.forecast.compensationCoefficients.update(this.compensationCoefficients);
    } else {
      console.error("Коэффициенты компенсации не заданы на момент сохранения");
    }
  };

  public save = async (): Promise<void> => {
    this.isSaving = true;
    await this.saveCompensation();
    const updatedWells: WellNode[] = [];
    const newPeriods: InjectionInterval[] = [...(this.mutatedChildren ?? [])].flatMap((wellNode) => {
      updatedWells.push(wellNode);
      const injectionPeriods = wellNode.injectionPeriods;
      if (injectionPeriods.length === 0) {
        return {
          wellId: wellNode.well.id,
          producingObjectId: wellNode.well.producingObject!.id,
          firstMonth: 0,
          firstYear: 0,
          monthsDuration: 0,
        };
      }

      return injectionPeriods
        .filter((periodNode) => {
          console.assert(periodNode.isValid);
          return periodNode.isValid;
        })
        .map((periodNode): InjectionInterval => {
          const { start, end } = periodNode;
          const firstMonth = start.month() + 1;
          const firstYear = start.year();
          const monthsDuration = Math.max(end.diff(start, "month") + 1, 0);

          return {
            wellId: wellNode.well.id,
            producingObjectId: periodNode.producingObject!.id, // garanted by isValid
            firstMonth,
            firstYear,
            monthsDuration,
          };
        });
    });

    const isSaved = await savePeriods(this.forecast.id, newPeriods);
    this.isSaving = false;
    if (isSaved) {
      const ids = updatedWells.map(({ well, mutationsManager }) => {
        mutationsManager?.dropMutations();
        mutationsManager?.propagateMutation();
        return { wellId: well.id };
      });
      this.forecast.production.update(ids);
    }
  };

  private filterSelected(selectedWellsIds: Set<number>) {
    this.childrenStore?.filter((node) => selectedWellsIds.has(node.well.id));
  }

  public get production(): Production {
    return this.forecast.production;
  }

  public get interventions(): Interventions {
    return this.forecast.interventions;
  }

  public get producingObjects(): ProducingObjects {
    return this.forecast.fact.producingObjects;
  }

  private get wells(): Well[] {
    return this.forecast.wells.allWells;
  }

  public disabledDate = (wellId: number, prodObjectId?: number) => {
    if (prodObjectId === undefined) {
      return () => true;
    }
    let wellNode: WellNode | undefined = undefined;
    for (const node of this.childrenStore?.children ?? []) {
      if (node.well.id === wellId) {
        wellNode = node;
        break;
      }
    }
    if (wellNode === undefined) {
      return () => true;
    }
    const periods = [...(wellNode.childrenStore?.children ?? [])].filter(
      ({ period, producingObject }) =>
        period.status === "prod" || (period.status === "inj" && producingObject?.id === prodObjectId)
    );
    return (date: Dayjs) =>
      !periods.every(({ start, end }) => date.isBefore(start, "month") || date.isAfter(end, "month"));
  };

  public producingObjectIds = (wellId: number): number[] => {
    let wellNode: WellNode | undefined = undefined;
    for (const node of this.childrenStore?.children ?? []) {
      if (node.well.id === wellId) {
        wellNode = node;
        break;
      }
    }
    if (wellNode === undefined) {
      return [];
    }
    return wellNode.producingObjects.ids;
  };

  public onlyIntersected() {
    this.invalidWells.forEach((w) => {
      w.onlyIntersected();
    });
    const selected = new Set(this.tree.selectedItems.map((node) => node.item!.id));
    this.childrenStore?.filter((ch) => selected.has(ch.well.id) && this.invalidWells.has(ch));
  }

  public resetFilters() {
    for (const child of this.childrenStore?.children ?? []) {
      child.childrenStore?.filter(() => true);
    }
    this.childrenStore?.filter(() => true);
    const selected = new Set(this.tree.selectedItems.map((node) => node.item!.id));
    this.filterSelected(selected);
  }
}

export type { DRow };
export { InputParams };
