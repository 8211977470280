import { useEffect, useRef, useState } from "react";
import { LineChartOutlined } from "@ant-design/icons";
import { Input, InputRef } from "antd";
import { observer } from "mobx-react-lite";

import { ModelContentComponentType } from "elements/modal/modal";
import { TypeForecastSettings } from "features/techForecast/models/typeForecastSettings";
import { Methods } from "features/techForecast/models/well/methods";
import { ForecastMode } from "services/back/techForecast/request";
import { TypeForecastSetting } from "services/back/techForecast/typeSettings";
import { getRandomUid } from "utils/random";

import cn from "./saveTypicalModal.module.less";

const SaveTypicalModal: ModelContentComponentType<void, { settings: TypeForecastSettings; modeSettings: Methods; mode: ForecastMode }> = observer(
  ({ dataRef, setValid, setOnAccept }) => {
    const modeSettings = dataRef.current.modeSettings;
    const typeForecastSettings = dataRef.current.settings;
    const mode = dataRef.current.mode;
    const typicalSettings = modeSettings.asTypical;

    const [value, setValue] = useState("");
    const inputRef = useRef<InputRef>(null);

    const list = typeForecastSettings.data;
    const haveTitle = list && list.some((item) => item.title === value);
    const errorMessage = "Это название уже используется";
    const isValid = !haveTitle && value !== "";

    useEffect(() => {
      setValid(isValid);
    }, [isValid, setValid]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [isValid]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    const createSetting = (): TypeForecastSetting => {
      return {
        uuid: getRandomUid(),
        title: value,
        wellType: typicalSettings?.wellType ?? null,
        producingObject: typicalSettings?.producingObject ?? null,
        forecastParam: mode,
        method: typicalSettings?.method ?? null,
        a: typicalSettings?.a ?? null,
        k: typicalSettings?.k ?? null,
      };
    };

    setOnAccept(async () => {
      if (isValid) {
        const newSetting = createSetting();
        typeForecastSettings.addSetting(newSetting);
        typeForecastSettings.save();

        setValue("");

        return { result: true };
      }
      return { result: false };
    });

    return (
      <div className={cn.modal}>
        <Input ref={inputRef} value={value} onChange={handleChange} placeholder="Введите Название" prefix={isValid ? "" : <LineChartOutlined />} />
        {haveTitle && (
          <div className={cn.errorMessage}>
            <span>*</span>
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export { SaveTypicalModal };
