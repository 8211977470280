import { FC, memo, ReactNode } from "react";
import FullScreen from "react-fullscreen-crossbrowser";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { ChartContext } from "@okopok/axes_context";
import { LineData } from "@okopok/axes_context";
import { LineDataModel } from "@okopok/axes_context";
import { Lines } from "@okopok/axes_context";
import { Button, Typography } from "antd";
import { NumberValue } from "d3";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { BarDataModelProps, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import StackedBars from "elements/charts/stackedBars/stackedBars";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext, useTooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Padding, useLinesBarsComposition } from "features/plot/useLinesBarsComposition";
import { colorCarousel } from "services/colorCarousel";
import { createDomainAccessor } from "utils/boundDomain";
import { useBooleanState } from "utils/useBooleanState";

import cn from "./techSummaryChart.module.less";

type DataSeries = {
  y: (number | null)[];
  title?: string;
};

type TechSummaryAxisData = {
  dataSet: DataSeries[];
  unit?: string;
  side?: "left" | "right";
};

type TechSummaryForecastProps = {
  title: string;
  lines: TechSummaryAxisData[];
  bars?: TechSummaryAxisData;
  x: NumberValue[];
  threshold?: NumberValue;
};

const TechSummaryChart: FC<TechSummaryForecastProps> = memo(({ title, lines, bars, x, threshold }) => {
  const preparedLines: LineDataModel[] = [];
  let currentColor = 0;
  lines.forEach((axis, id) => {
    axis.dataSet.forEach((line, index) => {
      const accessor = createDomainAccessor<LineData["x"]>(x, line.y.length);
      if (line.y.filter((value, index) => (threshold ? accessor(index) <= +threshold : true)).length > 0) {
        preparedLines.push(
          new LineDataModel({
            y: line.y.filter((value, index) => (threshold ? accessor(index) <= +threshold : true)),
            x: x.filter((value) => (threshold ? value <= threshold : true)),
            axisKey: `axis${id}`,
            key: `axis${id}-${index}-0`,
            color: colorCarousel(currentColor),
            title: line.title,
          })
        );
      }
      if (line.y.filter((value, index) => (threshold ? accessor(index) >= +threshold : false)).length > 0) {
        preparedLines.push(
          new LineDataModel({
            y: line.y.filter((value, index) => (threshold ? accessor(index) >= +threshold : false)),
            x: x.filter((value) => (threshold ? value >= threshold : false)),
            axisKey: `axis${id}`,
            key: `axis${id}-${index}-1`,
            color: colorCarousel(currentColor),
            className: cn.dashed,
            title: line.title,
          })
        );
      }
      currentColor++;
    });
  });

  const rawBars: BarDataModelProps[] = bars
    ? bars.dataSet.map((bar, index) => ({
        y: bar.y.map((value) => ({ value: value })),
        x: x,
        color: colorCarousel(currentColor + index + 1),
        key: `bar${index}`,
        title: bar.title,
      }))
    : [];

  const barsModel = new BarsDataModel(rawBars, "bars", true);
  const TITLES = new Map<string, ReactNode>(lines.map((line, index) => [`axis${index}`, line.unit]));
  TITLES.set("bars", bars?.unit);
  const RIGHT = new Set<string>(["bars"]);
  lines.forEach((line, index) => {
    if (line.side === "right") {
      RIGHT.add(`axis${index}`);
    }
  });
  const PADDINGS: Padding[] = [...lines.map((line, index) => ({ axisKey: `axis${index}`, bottom: 0 })), { axisKey: "bars", top: 0.5 }];
  const tooltipManager = useTooltipDataManager(preparedLines, barsModel);
  const [isFullscreen, openFullscreen, closeFullscreen, switchFullscreen] = useBooleanState();
  return (
    <div className={cn.layout}>
      <FullScreen enabled={isFullscreen} onClose={closeFullscreen} onOpen={openFullscreen}>
        <div className={cn.titleContainer}>
          <div className={cn.header}>
            <Typography.Title level={4} className={cn.title}>
              {title}
            </Typography.Title>
          </div>
          <Button onClick={switchFullscreen} icon={isFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />} />
        </div>
        <div className={cn.wrap}>
          <ChartContext axes={useLinesBarsComposition(preparedLines, barsModel, PADDINGS, TITLES, RIGHT, undefined)} className={cn.chart}>
            <TooltipContext.Provider value={tooltipManager}>
              <StackedBars bars={barsModel} />
              <Lines data={preparedLines} />
              <PointerLine />
            </TooltipContext.Provider>
          </ChartContext>
          <TooltipDiv manager={tooltipManager} />
        </div>
      </FullScreen>
    </div>
  );
});

export type { TechSummaryAxisData };
export { TechSummaryChart };
