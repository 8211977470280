import { ReactNode } from "react";
import { Input, InputProps } from "antd";
import classNames from "classnames";

import { useBooleanState } from "utils/useBooleanState";

import cn from "./lazyInput.module.less";

type LazyInputProps = Omit<InputProps, "onBlur" | "onChange" | "autoFocus" | "onPressEnter"> & {
  value?: string | null;
  addonAfter?: ReactNode;
  className?: string;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
  bordered?: boolean;
  status?: "" | "warning" | "error";
  placeholder?: string;
};

const LazyInput = ({ value, className, onChange, ...props }: LazyInputProps) => {
  const [editing, makeEditable, makePlain] = useBooleanState(false);

  if (editing) {
    return (
      <Input
        {...props}
        value={value}
        autoFocus
        className={classNames(cn.input, className)}
        onChange={(e) => onChange?.(e.target.value ?? null)}
        onBlur={makePlain}
        onPressEnter={makePlain}
      />
    );
  }

  return (
    <div tabIndex={0} className={cn.placeholder} onClick={makeEditable} onFocus={makeEditable}>
      {value?.toString()}
    </div>
  );
};

export { LazyInput, type LazyInputProps };
