import { GTMWork, RCPOutput } from "services/back/rcp/calculate";

import { KRS_GTM_TYPES, WELL_TYPES, ZBS_GTM_TYPE } from "../constants";

type YearDatumGeneric<T extends number | number[]> = {
  count: {
    gtm: {
      [gtmType: string]: T;
    };
    eb: {
      [wellType: string]: T;
    };
    crews: {
      [gtmGroup in "ЭБ" | "КРС" | "ЗБС"]: T;
    };
  };
  length: {
    [wellType: string]: T;
  };
  invests: {
    eb: T; // млн руб
    reconstruction: T; // млн руб
  };
};

type YearDatum = YearDatumGeneric<number>;
type YearsData = YearDatumGeneric<number[]>;

function initEmpty(): YearsData {
  return {
    count: {
      gtm: Object.fromEntries([ZBS_GTM_TYPE, ...KRS_GTM_TYPES].map((type) => [type, []])),
      eb: Object.fromEntries(WELL_TYPES.map((type) => [type, []])),
      crews: {
        ЭБ: [],
        КРС: [],
        ЗБС: [],
      },
    },
    length: Object.fromEntries(WELL_TYPES.map((type) => [type, []])),
    invests: {
      eb: [],
      reconstruction: [],
    },
  };
}

function aggregateGTMWorks(datums: GTMWork[]): YearDatum {
  const gtm = Object.fromEntries([ZBS_GTM_TYPE, ...KRS_GTM_TYPES].map((type) => [type, 0]));
  const eb = Object.fromEntries(WELL_TYPES.map((type) => [type, 0]));
  const length = Object.fromEntries(WELL_TYPES.map((type) => [type, 0]));
  const crews = {
    ЭБ: new Set<number>(),
    КРС: new Set<number>(),
    ЗБС: new Set<number>(),
  };
  const invests = {
    eb: 0,
    reconstruction: 0,
  };

  for (const datum of datums) {
    if (datum.gtm === null) {
      console.assert(datum.crew.gtmGroup === "ЭБ");
      eb[datum.well.wellType]++;
      crews[datum.crew.gtmGroup].add(datum.crew.id);
      invests.eb += (datum.well.prodDrillingPrice * datum.well.length) / 1000;
      length[datum.well.wellType] += datum.well.length;
    } else {
      console.assert(datum.crew.gtmGroup !== "ЭБ");
      gtm[datum.gtm.gtmType]++;
      crews[datum.crew.gtmGroup].add(datum.crew.id);
      invests.reconstruction += datum.gtm.gtmPrice / 1000;
    }
  }

  return {
    count: {
      gtm,
      eb,
      crews: {
        ЭБ: crews["ЭБ"].size,
        КРС: crews["КРС"].size,
        ЗБС: crews["ЗБС"].size,
      },
    },
    length,
    invests,
  };
}

function aggregate(gtms: RCPOutput): YearsData {
  const res = initEmpty();
  for (const gtm of gtms) {
    const aggregated = aggregateGTMWorks(gtm.gtmWorks);
    [ZBS_GTM_TYPE, ...KRS_GTM_TYPES].forEach((gtmType) => {
      res.count.gtm[gtmType].push(aggregated.count.gtm[gtmType]);
    });
    WELL_TYPES.forEach((wellType) => {
      res.count.eb[wellType].push(aggregated.count.eb[wellType]);
      res.length[wellType].push(aggregated.length[wellType]);
    });
    (["ЭБ", "КРС", "ЗБС"] as const).forEach((crewType) => {
      res.count.crews[crewType].push(aggregated.count.crews[crewType]);
    });
    res.invests.eb.push(aggregated.invests.eb);
    res.invests.reconstruction.push(aggregated.invests.reconstruction);
  }
  return res;
}

export type { YearsData };
export { aggregate, KRS_GTM_TYPES, WELL_TYPES, ZBS_GTM_TYPE };
