import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { Scenarios as ScenariosFeature } from "features/scenariosList/scenarios";
import { useProject } from "models/project/project";

const useForecasts = () => useProject()?.fact?.forecasts;

const Scenarios = () => (
  <Preloader hooks={[useForecasts]}>
    <PageFrame title="Перечень сценариев">
      <ScenariosFeature />
    </PageFrame>
  </Preloader>
);

export { Scenarios };
