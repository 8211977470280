import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

import cn from "./settingsModule.module.less";

type SettingsModuleProps = {
  title: string;
  className?: string;
};

const SettingsModule: FC<SettingsModuleProps & PropsWithChildren> = ({ title, children, className }) => {
  return (
    <div className={classNames(cn.container, className)}>
      <div className={cn.title}>
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};

const SettingsSection: FC<SettingsModuleProps & PropsWithChildren> = ({ title, children, className }) => {
  return (
    <div className={classNames(cn.container, className)}>
      <div className={cn["section-title"]}>
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};

export { SettingsModule, SettingsSection };
