import { Metric } from "services/finance/utils";
import { Range } from "utils/range";
import { req } from "utils/request";

import { formatSchema, ParamsResponse } from "./utils";

const getEcyResolver = async (range: Range): Promise<{ schema_usc: Metric[] }> => {
  const { schema_usc } = await req.get<ParamsResponse<"schema_usc">>("demo/schemas/basic");
  return {
    schema_usc: formatSchema(schema_usc.schema, range),
  };
};

export { getEcyResolver };
