import { Button } from "antd";
import { observer } from "mobx-react";

import { ReactComponent as CloseIcon } from "elements/deleteButton/close.svg";
import { formatData } from "elements/format/formatData";
import { Icon } from "elements/icon/icon";
import { useTooltipContext } from "elements/tooltips/abstractTooltip";
import { WrapTooltipModel } from "elements/tooltips/wrapTooltip/wrapTooltipModel";
import { setupAccuracy } from "utils/round";

import { WellPadPoint } from "../GridMapDataManager";

import { InjectionTable } from "./injectionTable/injectionTable";
import { MiningTable } from "./miningTable/miningTable";

import cn from "./padTooltip.module.less";

const PadTooltip = observer(({ wellPad }: { wellPad: WellPadPoint }) => {
  const wrapTooltip = useTooltipContext();
  if (wellPad.type === "mining") {
    return (
      <div className={cn.wrapMining}>
        <div className={cn.header}>
          <Button
            onClick={() => {
              (wrapTooltip as WrapTooltipModel)?.unlockUpdates();
            }}
            icon={<Icon content={<CloseIcon />} viewBox="0 0 16 16" />}
          />
        </div>
        <div>
          Добывающая скважина: <span className={cn.value}>{wellPad.well?.title}</span>
        </div>
        <div>
          Дата: <span className={cn.value}>{formatData(wellPad.date, { dateFormat: "monthYearNumeric" })}</span>
        </div>
        <div>
          Объект разработки: <span className={cn.value}>{wellPad.well?.producingObject?.title}</span>
        </div>
        <div>
          Дебит жидкости:{" "}
          <span className={cn.value}>{wellPad.liquidRate !== null ? setupAccuracy(wellPad.liquidRate, "round") : wellPad.liquidRate}</span> м³/сут
        </div>
        <div>
          Дебит нефти: <span className={cn.value}>{wellPad.oilRate !== null ? setupAccuracy(wellPad.oilRate, "round") : wellPad.oilRate}</span> т/сут
        </div>
        <div className={cn.table}>
          Нагнетательные скважины:
          <InjectionTable wellPads={wellPad.injectionWells}></InjectionTable>
        </div>
      </div>
    );
  } else
    return (
      <div className={cn.wrapInj}>
        <div className={cn.header}>
          <Button
            onClick={() => (wrapTooltip as WrapTooltipModel)?.unlockUpdates()}
            className={cn.button}
            icon={<Icon content={<CloseIcon />} viewBox="0 0 16 16" />}
          />
        </div>
        <div>
          Нагнетательная скважина: <span className={cn.value}>{wellPad.well?.title}</span>
        </div>
        <div>
          Дата: <span className={cn.value}>{formatData(wellPad.date, { dateFormat: "monthYearNumeric" })}</span>{" "}
        </div>
        <div>
          Объект разработки: <span className={cn.value}>{wellPad.well?.producingObject?.title}</span>
        </div>
        <div>
          Приемистость: <span className={cn.value}>{wellPad.injCapacity ? setupAccuracy(wellPad.injCapacity, "round") : wellPad.injCapacity}</span>{" "}
          м³/сут
        </div>
        <div className={cn.table}>
          Добывающие скважины:
          <MiningTable wellPads={wellPad.miningWells}></MiningTable>
        </div>
      </div>
    );
});

export { PadTooltip };
