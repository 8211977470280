import { Range } from "./range";

function expandArr<T>(arr: T[], arrBaseIdx: number, range: Range): (T | null)[] {
  const offset = arrBaseIdx - range.from;

  const intersectionStart = Math.max(0, -offset);
  const intersectionEnd = range.to - arrBaseIdx;
  const intersection = arr.slice(intersectionStart, intersectionEnd);
  while (intersection.length < range.length) {
    intersection.push(intersection[intersection.length - 1]);
  }

  const result = range.array.fill(null);
  const insertionPoint = Math.max(offset, 0);

  result.splice(insertionPoint, intersection.length, ...intersection);

  const DEFINED_YEAR = 2022;
  const GAP = 5;
  if (range.to > DEFINED_YEAR && range.to <= DEFINED_YEAR + GAP && result[DEFINED_YEAR - arrBaseIdx] !== null) {
    for (let i = DEFINED_YEAR; i !== range.to; ++i) {
      result[i - arrBaseIdx] = result[DEFINED_YEAR - arrBaseIdx];
    }
  }

  return result;
}

export { expandArr };
