import { useCallback } from "react";
import { DatePicker, type DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";

type YearPickerProps = Omit<DatePickerProps, "picker" | "value" | "disabledDate"> & {
  value?: number | Dayjs | null;
  onChange?: (year: number | null, dateString: string | string[]) => void;
  min?: number;
  max?: number;
};

const YearPicker = ({ value, onChange, min, max, ...props }: YearPickerProps) => {
  if (typeof value === "number") {
    value = dayjs().year(value);
  }

  const onChangeDecorated = useCallback(
    (date: Dayjs | null, dateString: string | string[]) => {
      onChange?.(date && (date.year() as any), dateString);
    },
    [onChange]
  );

  const disabledDate = (date: Dayjs) => {
    return !date || (max !== undefined && date.isAfter(dayjs().year(max))) || (min !== undefined && date.isBefore(dayjs().year(min)));
  };

  return <DatePicker changeOnBlur placeholder="" {...props} value={value} onChange={onChangeDecorated} picker="year" disabledDate={disabledDate} />;
};

export { YearPicker };
