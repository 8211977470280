import { useResultModel } from "routing/outlines/result/resultPage";

import { Results } from "./results";

const InvestResults = () => {
  const result = useResultModel();
  const licenseRegion = result.selectedLicenseRegion === "total" ? "Суммарно по всем ЛУ" : result.selectedLicenseRegion;

  return <Results model={result.invest} exportFileName={`Инвестиционная деятельность ${licenseRegion}`} />;
};

export { InvestResults };
