import { ChannelsMetaInfo } from "elements/charts/channelsManager";
import { colorCarousel } from "services/colorCarousel";

const SUMMARY_FIELDS = [
  "prodDays",
  "injDays",
  "oilProd",
  "oilRate",
  "accumOilProd",
  "liquidProd",
  "liquidRate",
  "accumLiquidProd",
  "waterCut",
  "waterInj",
  "accumWaterInj",
  "injectCompensation",
  "accumInjectCompensation",
  "injectionCapacity",
  "miningFond",
  "injectionFond",
] as const;

type YearDatumInternal<T extends number | null> = Record<(typeof SUMMARY_FIELDS)[number], T>;
type YearDatum = YearDatumInternal<number | null>;

function defaultYearDatum<T extends number | null>(
  fillValue: T
): YearDatumInternal<T extends null ? number | null : number> {
  return Object.fromEntries(SUMMARY_FIELDS.map((k) => [k, fillValue])) as unknown as YearDatumInternal<
    T extends null ? number | null : number
  >;
}

const PARAMS: ChannelsMetaInfo = {
  oilProd: {
    title: "Добыча нефти",
    axis: "тыс т",
    color: colorCarousel(1),
  },
  oilRate: {
    title: "Среднесуточный дебит нефти",
    axis: "т/сут",
    color: colorCarousel(2),
  },
  accumOilProd: {
    title: "Накопленная добыча нефти",
    axis: "тыс т",
    color: colorCarousel(3),
  },
  liquidProd: {
    title: "Добыча жидкости",
    axis: "тыс. м³",
    color: colorCarousel(4),
  },
  liquidRate: {
    title: "Среднесуточный дебит жидкости",
    axis: "м³/сут",
    color: colorCarousel(5),
  },
  accumLiquidProd: {
    title: "Накопленная добыча жидкости",
    axis: "тыс. м³",
    color: colorCarousel(6),
  },
  waterCut: {
    title: "Обводненность",
    axis: "%",
    color: colorCarousel(7),
  },
  waterInj: {
    title: "Закачка воды",
    axis: "тыс. м³",
    color: colorCarousel(8),
  },
  injectionCapacity: {
    title: "Среднесуточная приемистость",
    axis: "м³/сут",
    color: colorCarousel(9),
  },
  accumWaterInj: {
    title: "Накопленная закачка",
    axis: "тыс. м³",
    color: colorCarousel(10),
  },
  injectCompensation: {
    title: "Компенсация отбора закачкой",
    axis: "д.ед",
    color: colorCarousel(11),
  },
  accumInjectCompensation: {
    title: "Накопленная компенсация отбора закачкой",
    axis: "д.ед",
    color: colorCarousel(12),
  },
  miningFond: {
    title: "Фонд действующих добывающих скважин",
    axis: "скв",
    color: colorCarousel(13),
  },
  injectionFond: {
    title: "Фонд нагнетательных скважин",
    axis: "скв",
    color: colorCarousel(14),
  },
};

export type { YearDatum };
export { defaultYearDatum, PARAMS, SUMMARY_FIELDS };
