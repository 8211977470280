/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { observer } from "mobx-react-lite";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";
type BarProps = {
  x: Date;
  y: number;
  yPrev: number;
  yId: number;
  color: string | number;
};
const Bar = observer(({ x, y, yPrev, yId, color }: BarProps) => {
  const model = useD3Context();
  const rectRef = useRef(null);
  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain([model.xAxis.domain.min, model.xAxis.domain.max])
      .range([model.padding.left + axisWidth * model.leftAxisCount, model.width - model.padding.right - axisWidth * model.rightAxisCount]);
    if (model.currentZoomState) {
      const newXScale = model.currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }
    const yScale = d3
      .scaleLinear()
      .domain([model.YDomains[yId].min, model.YDomains[yId].max])
      .range([model.height - model.padding.bottom, model.padding.top])
      .nice();
    const yearLength = xScale(new Date(2024, 0, 1)) - xScale(new Date(2023, 0, 1));
    const barWidth = Math.min(40, (2 * yearLength) / 3);
    d3.select(rectRef.current)
      .attr("x", xScale(x) - barWidth / 2)
      .attr("height", yScale(0) - yScale(y))
      .attr("width", barWidth)
      .attr("y", yScale(yPrev) - (yScale(0) - yScale(y)));
    if (typeof color === "string") {
      d3.select(rectRef.current).style("fill", color);
    } else {
      d3.select(rectRef.current).style("fill", `url(#hatch-${color})`);
    }
  }, [model.currentZoomState, model.height, model.width]);
  return <rect ref={rectRef} clipPath={`url(#clipPath-${model.svgClassName})`}></rect>;
});

export default Bar;
