import type { FC } from "react";
import { GroupedVirtuoso } from "react-virtuoso";
import { Typography } from "antd";
import { observer } from "mobx-react";

import { useTechForecastModel } from "../useTechForecastModel";

import { WellItem } from "./wellItem";
import { WellsGroup } from "./wellsGroup";

import cn from "./wellsList.module.less";

type CurrentId = {
  wellId: number;
  gtmId: number | null;
};

const GROUP_CONTENT = (index: number) => {
  return <WellsGroup index={index} />;
};

const ITEM_CONTENT = (index: number, groupIndex: number) => <WellItem index={index} groupIndex={groupIndex} />;

const WellsList: FC = observer(() => {
  const model = useTechForecastModel();
  return (
    <div className={cn.container}>
      <Typography.Title className={cn.title}>Скважины/ГТМ</Typography.Title>
      <div className={cn.scroller}>
        <GroupedVirtuoso groupCounts={model.wellGroupsCount} groupContent={GROUP_CONTENT} itemContent={ITEM_CONTENT} />
      </div>
    </div>
  );
});

export { type CurrentId, WellsList };
