import React from "react";
import type { DefaultOptionType } from "antd/es/select";
import { action, computed, makeObservable, observable } from "mobx";

import { TypeForecastSetting } from "services/back/techForecast/typeSettings";

class TypeForecastSettings {
  data?: TypeForecastSetting[];
  initialData?: TypeForecastSetting[];
  isSaved: boolean = false;

  constructor(
    private projectId: number,
    private getData: (projectId: number) => Promise<TypeForecastSetting[] | null>,
    private saveData: (data: TypeForecastSetting[], projectId: number) => void
  ) {
    makeObservable(this, {
      data: observable,
      initialData: observable,
      update: action,
      addSetting: action,
      remove: action,
      isLoading: computed,
      isSaved: observable,
    });

    this.init();
  }

  get isUpdated(): boolean {
    return !this.isSaved && JSON.stringify(this.data) !== JSON.stringify(this.initialData);
  }

  get isLoading() {
    return this.data === undefined;
  }

  private init = async () => {
    const response = await this.getData(this.projectId);
    this.initialData = response ?? [];
    this.data = response ?? [];
  };

  public update = (item: Partial<TypeForecastSetting>) => {
    if (!this.data) return;
    this.isSaved = false;

    const itemIndex = this.data.findIndex((el) => el.uuid === item.uuid);
    if (itemIndex !== -1) {
      this.data[itemIndex] = { ...this.data[itemIndex], ...item };
    }
  };

  public addSetting = (setting: TypeForecastSetting) => {
    if (!this.data) {
      this.data = [setting];
    } else {
      this.data.push(setting);
    }
  };

  at(uid: string | null | undefined): TypeForecastSetting | null | undefined {
    if (this.data === undefined) {
      return undefined;
    }
    return this.data.find(({ uuid }) => uuid === uid) ?? null;
  }

  selector(mode: "oil" | "liquid"): DefaultOptionType[] | undefined {
    return this.data
      ?.filter(({ forecastParam }) => forecastParam === mode)
      .map(({ title, uuid }) => ({
        value: uuid,
        label: title as React.ReactNode,
      }));
  }

  public save = () => {
    this.saveData(this.data ?? [], this.projectId);
    this.isSaved = true;
  };

  public remove = (uuid: string) => {
    this.data = this.data?.filter((el) => el.uuid !== uuid);
    this.isSaved = false;
  };
}

export { TypeForecastSettings };
