import { FilterCondition, FilterType } from "./types";

type Option<T extends FilterType> = {
  value: FilterCondition<T>;
  label: string;
};

const orderingOptions: Option<"ordering">[] = [
  { value: "eq", label: "=" },
  { value: "gt", label: ">" },
  { value: "ge", label: "≥" },
  { value: "lt", label: "<" },
  { value: "le", label: "≤" },
  { value: "ne", label: "≠" },
];

const stringerOptions: Option<"stringer">[] = [
  { value: "contains", label: "Содержит" },
  { value: "notcontains", label: "Не содержит" },
];

const equalOptions: Option<"equal">[] = [
  { value: "eq", label: "Равно" },
  { value: "ne", label: "Не равно" },
];

const conditionOptionsMap: Record<FilterType, Option<FilterType>[]> = {
  ordering: orderingOptions,
  stringer: stringerOptions,
  equal: equalOptions,
} as const;

export { conditionOptionsMap };
