import { PageFrame } from "routing/pageFrame/pageFrame";

import { UsersTable } from "features/usersTable/usersTable";

const Users = () => {
  return (
    <PageFrame title="Пользователи системы">
      <UsersTable />
    </PageFrame>
  );
};

export { Users };
