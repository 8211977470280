function deltaEncoding(arr: number[]) {
  if (arr.length === 0) return [];

  let deltas = [arr[0]]; // первое число оставляем как есть
  for (let i = 1; i < arr.length; i++) {
    deltas.push(arr[i] - arr[i - 1]);
  }
  return deltas;
}

function rleCompress(deltas: number[]) {
  let result = [];
  let count = 1;

  for (let i = 1; i <= deltas.length; i++) {
    if (deltas[i] === deltas[i - 1]) {
      count++;
    } else {
      if (count > 1) {
        result.push(`${deltas[i - 1]}x${count}`);
      } else {
        result.push(`${deltas[i - 1]}`);
      }
      count = 1;
    }
  }

  return result.join("_");
}

function compressIds(arr: number[]) {
  let deltas = deltaEncoding(arr);
  return rleCompress(deltas);
}

function decompressIds(queryString: string) {
  let deltas: number[] = [];

  // Разбиваем строку по запятым и обрабатываем каждый элемент
  queryString.split("_").forEach((part) => {
    if (part.includes("x")) {
      // Если элемент содержит 'x', это формат "значение x количество"
      let [value, count] = part.split("x").map(Number);
      deltas.push(...Array(count).fill(value)); // Добавляем "count" раз "value"
    } else {
      // Обычное значение
      deltas.push(Number(part));
    }
  });

  // Теперь восстанавливаем оригинальный список через обратное Delta Encoding
  let arr = [deltas[0]]; // Первое число остается как есть

  for (let i = 1; i < deltas.length; i++) {
    arr.push(arr[arr.length - 1] + deltas[i]);
  }

  return arr;
}

export { compressIds, decompressIds };
