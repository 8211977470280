import { delayedResolve } from "utils/delayedResolve";
import { LocalStorageMock } from "utils/localStorageMock";

import { CREATION_MOMENT } from "./field";

type ForecastRawData = {
  name: string;
  key: string;
  prior: boolean;
  infrastructural: boolean;
  planLastYear: number;
  startYear: number;
};

type ForecastRawDataUnsaved = Omit<ForecastRawData, "key">;

const postForecast = (data: ForecastRawDataUnsaved): Promise<ForecastRawData> =>
  delayedResolve({
    ...data,
    key: data.name
      .toLowerCase()
      .split("")
      .map((c: string) => (c === "." ? "-" : c))
      .filter((c: string) => c !== " ")
      .join(""),
  });

const getForecasts = (stateId: string): Promise<ForecastRawData[]> =>
  Promise.resolve([
    {
      name: "Тестовый прогноз",
      key: "test_forecast",
      prior: false,
      infrastructural: false,
      planLastYear: 2054,
      startYear: CREATION_MOMENT,
    },
  ]);

const storageName = "forecasts" as const;
const { decorators, mockKey } = new LocalStorageMock<string, ForecastRawData[]>(storageName, false);
const [getAll] = decorators;

const newForecast = (forecasts: ForecastRawData[]) => {
  const empty = {} as ForecastRawData;
  forecasts.push(empty);
  return empty;
};
const [, setSpecific] = mockKey(newForecast);

const getForecastsDec = getAll(getForecasts);
const postForecastDec = setSpecific(postForecast);

const [getTax, setTax] = mockKey<
  Array<ForecastRawData & { taxDiscount: number; discount: number }>,
  { taxDiscount: number; discount: number }
>(
  (forecasts, forecastKey: string) => {
    const fc = forecasts.find((f) => f.key === forecastKey);
    if (fc === undefined || fc.taxDiscount === undefined || fc.discount === undefined) {
      return undefined as any;
    }
    return { taxDiscount: fc.taxDiscount, discount: fc.discount };
  },
  (forecasts, forecastKey: string) => (newValue: { taxDiscount: number; discount: number }) => {
    const fc = forecasts.find((f) => f.key === forecastKey) ?? { taxDiscount: 0, discount: 15 };
    fc.taxDiscount = newValue.taxDiscount;
    fc.discount = newValue.discount;
    return fc;
  }
);
const fetchTax = getTax((_forecastKey: string) => Promise.resolve({ taxDiscount: 0, discount: 15 }));
const saveTax = setTax((_forecastKey: string, newValue: { taxDiscount: number; discount: number }) => {
  return Promise.resolve(newValue);
});

export { fetchTax, getForecastsDec as getForecasts, postForecastDec as postForecast, saveTax };
export type { ForecastRawData, ForecastRawDataUnsaved };
