import { FC } from "react";
import { Widget } from "@okopok/components/Table";
import { Empty, Select, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { PermissionButton } from "elements/permissionButton/permissionButton";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { BalanceResult } from "models/project/stratum/materialBalance";

import { BalanceChart } from "./balanceChart";
import { useMaterialBalanceContext } from "./materialBalanceProvider";

import cn from "./calculationResult.module.less";

const useColumns = (result: BalanceResult): Column[] => [
  {
    title: "Параметр",
    width: { min: 300, max: 330, competitiveness: 1 },
    dataKey: "title",
    type: "string",
    isSticky: true,
  },
  {
    title: "Ед. измерения",
    width: { min: 120, max: 120, competitiveness: 1 },
    dataKey: "measure",
    type: "string",
    isSticky: true,
  },
  //@ts-ignore
  ...Object.keys(result)
    .filter((key) => !["key", "title", "measure"].includes(key))
    .map((year) => ({
      title: year,
      width: { min: 72, max: 144, competitiveness: 1 },
      dataKey: year,
      type: "number",
    })),
];

const CalculationResult: FC = observer(() => {
  const materialBalance = useMaterialBalanceContext();

  if (materialBalance.isLoadingResults) {
    return <FullScreenLoader />;
  }

  if (Object.values(materialBalance.results).length === 0) {
    return (
      <Empty className={cn.empty} description="Отсутствует расчет">
        Для отображения результатов выполните расчет
      </Empty>
    );
  }

  const column = useColumns(Object.values(materialBalance.results)[0][0]);

  const options: DefaultOptionType[] = materialBalance.producingObjects.map(({ id, title }) => ({
    value: id,
    label: title,
  }));

  return (
    <div className={cn.container}>
      <PageFrameTitlePortal onSave={materialBalance.saveResults} permissionSection="tech">
        <Typography.Text>Объекты разработки</Typography.Text>
        <Select className={cn.select} options={options} defaultValue={Number(options[0].value)} onChange={materialBalance.setProdId} />
        <PermissionButton section="tech" type="primary" onClick={materialBalance.calculate} loading={materialBalance.isCalculating}>
          Расчитать
        </PermissionButton>
      </PageFrameTitlePortal>
      <BalanceChart data={materialBalance.result} />
      <div className={cn.table}>
        <SimpleTableContext
          data={materialBalance.resultsStore}
          columns={column}
          exportFileName="material-balance-results"
          theme={{
            headerHeight: 54,
            rowHeight: 33,
            borderColor: "#e5e7eb",
          }}
        >
          <Widget />
        </SimpleTableContext>
      </div>
    </div>
  );
});

export { CalculationResult };
