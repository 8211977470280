import type { Dispatch, MutableRefObject, RefObject, SetStateAction } from "react";
import { useMemo, useState } from "react";

const useInteractiveRef = <T = Element>(): RefObject<T> => {
  const [value, setter] = useState<T | null>(null);
  const callback = useMemo((): RefObject<T> => {
    const result = (v: T) => {
      setter(v);
    };
    result.current = value;
    return result;
  }, [value]);
  return callback;
};

class Ref<T> {
  constructor(private value: T, private readonly setter: Dispatch<SetStateAction<T>>) {}

  set current(v: T) {
    this.value = v;
    this.setter(v);
  }

  get current(): T {
    return this.value;
  }
}

const useStatedRef = <T = Element>(initializer: T): MutableRefObject<T> => {
  const [, setter] = useState<T>(initializer);
  const callback = useMemo((): MutableRefObject<T> => {
    const result = new Ref<T>(initializer, setter);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setter]);
  return callback;
};

export { useInteractiveRef, useStatedRef };
