import { FC, PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { Icon } from "elements/icon/icon";
import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { InfrastructureAggregation } from "features/infrastructure/infrastructureAggregation/infrastructureAggregation";
import { InfrastructureFacilities } from "features/infrastructure/infrastructureFacilities/infrastructureFacilities";
import { InfrastructureMap } from "features/infrastructure/infrastructureMap/InfrastructureMap";
import { InfrastructureTable } from "features/infrastructure/infrastructureTable/infrastructureTable";
import { Preloader } from "features/preloader/preloader";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as AggregationIcon } from "./aggregation.svg";
import { ReactComponent as AssetsDataIcon } from "./assetsData.svg";
import { ReactComponent as FacilitiesIcon } from "./facilities.svg";
import { ReactComponent as MapIcon } from "./map.svg";

import cn from "./infrastructure.module.less";

const useScenario = () => {
  const fact = useFact()!;
  const forecast = useForecast();
  return forecast ?? fact;
};

const PreloaderWrap: FC<PropsWithChildren> = ({ children }) => {
  const scenario = useScenario();
  const hooks = [() => scenario.infrastructure, () => scenario.wells, () => scenario.wellPads, () => scenario.ecyStore];
  return <Preloader hooks={hooks}>{children}</Preloader>;
};

const TABS: TabItem[] = [
  {
    key: "facilities",
    label: <Icon width="20px" height="20px" viewBox="0 0 20 20" content={<FacilitiesIcon />} />,
    children: (
      <PreloaderWrap>
        <InfrastructureFacilities />
      </PreloaderWrap>
    ),
  },
  {
    key: "map",
    label: <Icon width="20px" height="20px" viewBox="0 0 20 20" content={<MapIcon />} />,
    default: true,
    children: (
      <PreloaderWrap>
        <InfrastructureMap />
      </PreloaderWrap>
    ),
  },
  {
    key: "table",
    label: <Icon width="20px" height="20px" viewBox="0 0 20 20" content={<AssetsDataIcon />} />,
    children: (
      <PreloaderWrap>
        <InfrastructureTable />
      </PreloaderWrap>
    ),
  },
  {
    key: "aggregation",
    label: <Icon width="20px" height="20px" viewBox="0 0 20 20" content={<AggregationIcon />} />,
    children: (
      <PreloaderWrap>
        <ForecastSelector>
          <InfrastructureAggregation />
        </ForecastSelector>
      </PreloaderWrap>
    ),
  },
];

const Infrastructure: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (path === "infrastructure") {
      navigate(`${location.pathname}/map`, { replace: true });
    }
  }, [location, navigate]);

  return <PageFrame className={cn.wrap} tabsClassName={cn.tabs} bodyClassName={cn.content} title="Наземная инфраструктура" tabs={TABS} />;
};

export { Infrastructure };
