import React from "react";
import classNames from "classnames";

import { Card, Stick } from "elements/card/card";
import { DonutChart } from "elements/charts/donutChart/DonutChart";
import { Format } from "elements/format/format";
import { UOM } from "elements/uom";
import { global } from "models/global";
import { yearWordChoose } from "services/yearWordChoose";

import { useSummaryContext } from "./summaryPage";

import styles from "./summaryPage.module.less";
const PERCENT_UOM = new UOM(3, 2, global.uomResolver);

const Donut = () => {
  const innerRadius = 70;
  const outerRadius = 90;
  const model = useSummaryContext();
  return (
    <Card title="Окупаемость" className={classNames(styles.payBack, styles.card)}>
      <div className={styles["cardBody"]}>
        <DonutChart UOM={PERCENT_UOM} data={model.payback.data} innerRadius={innerRadius} outerRadius={outerRadius}>
          <div className={styles.pieCenter}>
            <span>
              {" "}
              IRR <br></br>
              <span className={styles.big}>
                <Format>{model.payback.irr}</Format>
                {PERCENT_UOM.unit}
              </span>
            </span>
          </div>
        </DonutChart>
        <div className={styles.section}>
          <Stick
            title="IRR"
            children={
              <span className={styles.big}>
                <Format>{model.payback.irr}</Format>%
              </span>
            }
            className={styles.stick}
          ></Stick>
          <Stick
            className={styles.stick}
            title="PI"
            children={
              <span>
                <span className={styles.big}>
                  <Format>{model.payback.pi}</Format>
                </span>{" "}
                д.ед.
              </span>
            }
          ></Stick>
          <Stick
            className={styles.stick}
            title="dPBP"
            children={
              <span>
                <span className={styles.big}>{model.payback.dpbp}</span> {yearWordChoose(model.payback.dpbp)}
              </span>
            }
          ></Stick>
          <Stick
            className={styles.stick}
            title="PBP"
            children={
              <span>
                <span className={styles.big}>{model.payback.pbp}</span> {yearWordChoose(model.payback.pbp)}
              </span>
            }
          ></Stick>
        </div>
      </div>
    </Card>
  );
};

export { Donut };
