import { Axis, Domain } from "@okopok/axes_context";
import { createDomainAccessor, joinDomains, valuesDomain } from "@okopok/axes_context/utils/boundDomain";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, ObservableMap, reaction, runInAction, when } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { ChannelsManager } from "elements/charts/channelsManager";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { LineDataModel } from "features/techForecast/wellTechForecast/results/chart/elements/lineDataModel";
import { Fitting, ForecastMode } from "services/back/techForecast/request";
import { Production } from "services/back/techForecast/techForecast";

import { AXIS_KEYS, axisInference, channelKeys, HIDDEN, KNOWN_GROUPS, META } from "./wellTechChartChannelsMeta";
import type { WellTechForecast } from "./wellTechForecast";
import { WellTechForecastResult } from "./wellTechForecastResult";

const UNUSUAL_CHART_LINES_COLOR = "green";

type LinesPair = {
  fact: LineDataModel | null | undefined;
  forecast: LineDataModel | null | undefined;
  fitting: LineDataModel | null | undefined;
};
/*
Выдаёт массив линий с нужными параметрами для загруженных факта и прогноза
Если факта нет корректно выдаёт только прогноз, если прогноза нет то выдаёт только факт
если и того и другого нет выдаёт известные группы в полном составе, оси и пустой массив линий

Написано под музыку https://www.youtube.com/watch?v=AawLM81gIHo
 */
class WellTechChart extends ChannelsManager {
  private axesValue: Axis[] = [];
  private linesCache: ObservableMap<string, LineDataModel> = observable.map();
  public tooltipManager: TooltipDataManager = new TooltipDataManager();

  constructor(private readonly fc: WellTechForecast) {
    super();
    makeObservable<WellTechChart>(this, {
      tooltipManager: observable,
      factDomainAccessor: computed,
      lines: computed,
      lnVNFDomains: computed,
      recoveryDomains: computed,
      lnVNFLines: computed,
      recoveryLines: computed,
      visibleMetricLines: action,
      axes: computed,
      kindDomain: action,
      knownGroups: computed,
    });

    makePersistable<WellTechChart, "hidden" | "colors" | "hideUngrouped">(this, {
      name: `tech-forecast-chart-channels`,
      properties: [
        {
          key: "hidden",
          serialize: (value) => JSON.stringify([...value.values()]),
          deserialize: (value) => observable.set<string>(JSON.parse(value)),
        },
        {
          key: "colors",
          serialize: (value) => JSON.stringify([...value.entries()]),
          deserialize: (value) => observable.map<string, string>(JSON.parse(value)),
        },
        "hideUngrouped",
      ],
      storage: window.localStorage,
    });

    when(
      () => this.fc.channels !== undefined,
      () => {
        this.setupAxes(this.findDomains());
      }
    );

    this.setupAxes();

    reaction(
      () => [this.lines, this.fc.manager.resultsDisplayMode, this.lnVNFLines],
      () => {
        if (this.fc.manager.resultsDisplayMode === "chart") {
          this.tooltipManager.updateLines(this.lines);
          this.tooltipManager.updateFooterFormat();
        }
        if (this.fc.manager.resultsDisplayMode === "lnvnf") {
          this.tooltipManager.updateLines(
            Object.values(this.lnVNFLines)
              .filter((line) => line !== undefined && line !== null)
              .map((line) => line!)
          );
          this.tooltipManager.updateFooterFormat((value: number) =>
            JSON.parse(
              JSON.stringify({
                header: "Накопленная добыча нефти, т",
                value: value,
                color: null,
              })
            )
          );
        }
        if (this.fc.manager.resultsDisplayMode === "recovery") {
          this.tooltipManager.updateLines(
            Object.values(this.recoveryLines)
              .filter((line) => line !== undefined && line !== null)
              .map((line) => line!)
          );
          this.tooltipManager.updateFooterFormat((value: number) =>
            JSON.parse(
              JSON.stringify({
                header: "Отбор от НИЗ, %",
                value: value,
                color: null,
              })
            )
          );
        }
      }
    );
  }

  get channelsMetaInfo() {
    return META;
  }

  get axes(): Axis[] {
    const visibleAxis = new Set<string>();
    for (let channelKey of this.channelKeys) {
      if (!this.hidden.has(channelKey)) {
        visibleAxis.add(axisInference(channelKey));
      }
    }
    return this.axesValue.filter(({ key }, id) => visibleAxis.has(key) || this.axesValue.length === id + 1);
  }

  private setupAxes(domains: Map<string, Domain> = new Map()) {
    this.axesValue = [
      ...[...AXIS_KEYS.values()].map(
        (key) =>
          new Axis(
            key,
            "left",
            domains.get(key) ?? { min: 0, max: 100 },
            [META[key]?.title ?? key, axisInference(key)].filter(Boolean).join(", ")
          )
      ),
      new Axis("x", "bottom", this.xDomain, "Месяц", false, false, true),
    ];
  }

  kindDomain(kind: "fact" | "forecast"): Domain | null | undefined {
    const channels = kind === "forecast" ? this.fc.channels : this.fc.fact.data;
    if (channels === null || channels === undefined) {
      return channels;
    }

    const steps = channels[`${kind as "fact"}Production`]?.steps;

    if (!Array.isArray(steps) || steps.length < 1) {
      return null;
    }

    return WellTechChart.stepsDomainToDayJSDomain({
      min: steps.at(0)!,
      max: steps.at(-1)! + 1, // Домены без правого края
    });
  }

  private get forecastDomain(): Domain | null | undefined {
    return this.kindDomain("forecast");
  }

  private get factDomain(): Domain | null | undefined {
    return this.kindDomain("fact");
  }

  private line(channel: string, kind: "fact" | "forecast", unusual: boolean = false): LineDataModel | null | undefined {
    const { channels, requestKeeper } = this.fc;
    const key = `${
      kind !== "fact" && requestKeeper instanceof WellTechForecastResult ? requestKeeper.uuid : ""
    }_${channel}_${kind}_${unusual ? "unusual" : ""}`;
    if (this.linesCache.has(key)) {
      return this.linesCache.get(key);
    }
    if (channels === null || channels === undefined) {
      return channels;
    }
    const ds = channels[`${kind}Production`];
    if (ds === undefined || ds === null) {
      return null;
    }
    console.assert(
      channel in ds && Array.isArray(ds[channel as keyof typeof ds]),
      `запрошен не корректный ключ ${channel} в ${kind} среди данны`,
      ds
    );
    const xDomain = this[`${kind}Domain`];
    if (xDomain === null || xDomain === undefined) {
      return xDomain;
    }
    const axisKey = axisInference(channel);
    const y = ds[channel as keyof typeof ds]! as number[];
    const line = new LineDataModel({
      y: axisKey === "%" ? y.map((v) => v * 100) : y,
      x: WellTechChart.xMetricResolver(ds, channel, unusual),
      axisKey,
      key,
      title: `${META[channel]?.title ?? channel}, ${META[channel]?.axis}`,
      color: unusual ? UNUSUAL_CHART_LINES_COLOR : this.channelColor(channel),
      className: kind,
    });
    this.linesCache.set(key, line);
    return line;
  }

  private fittingLines(unusual: boolean = false): LineDataModel[] {
    const { channels, requestKeeper } = this.fc;
    if (channels === null || channels === undefined) {
      return [];
    }
    return (
      Object.entries(channels).filter(([key, fitting]) => key.endsWith("Fitted") && fitting !== null) as [
        string,
        Fitting
      ][]
    )
      .map(([fittingName, fitting]) => {
        const channel = fittingName.slice(0, -6);
        // если прогноз по обводнённости, фитинг рисуется только для нестандартных координат
        if (
          this.fc.settings.waterCut.method.startsWith("dc_") &&
          fittingName === "waterCutVolFitted" &&
          unusual === false
        ) {
          return null;
        }
        if (this.hidden.has(channel)) {
          return null;
        }
        console.assert(requestKeeper instanceof WellTechForecastResult, "обнаружен фитинг в фактических данных");
        const key = `${(requestKeeper as WellTechForecastResult).uuid}_${channel}_${unusual ? "unusual" : ""}_fitting`;
        if (this.linesCache.has(key)) {
          return this.linesCache.get(key);
        }
        const xDomain = this.factDomain;
        if (xDomain === null || xDomain === undefined) {
          return xDomain;
        }
        const axisKey =
          this.fc.settings.waterCut.method === "dc_ln_vnf_vol" ? META.lnVnfVol.axis : axisInference(channel);
        const line = new LineDataModel({
          y: axisKey === "%" ? fitting.y.map((v) => v * 100) : fitting.y,
          x: unusual ? fitting.x : fitting.x.map(WellTechChart.stepToDayJS),
          axisKey,
          key,
          title: `${META[channel]?.title ?? channel} (аппроксимация), ${META[channel]?.axis ?? axisInference(channel)}`,
          color: unusual ? UNUSUAL_CHART_LINES_COLOR : this.channelColor(channel),
          className: "fitting",
        });
        this.linesCache.set(key, line);
        return line;
      })
      .filter(Boolean) as LineDataModel[];
  }

  private get channelKeys(): string[] {
    const { channels } = this.fc;
    if (channels === undefined || channels === null) {
      return Object.keys(META).filter((key) => !HIDDEN.has(key));
    }

    return channelKeys(channels);
  }

  get factDomainAccessor() {
    const channels = this.fc.fact.data;
    const { factDomain } = this;
    if (channels === null || channels === undefined) {
      return channels;
    }
    if (factDomain === null || factDomain === undefined) {
      return createDomainAccessor({ min: 0, max: 1 }, channels.factProduction.steps.length);
    }
    return createDomainAccessor(factDomain, channels.factProduction.steps.length);
  }

  get lines(): LineDataModel[] {
    let fitting = this.fittingLines();
    return [
      ...(this.channelKeys
        .filter((key) => !this.hidden.has(key))
        .map((key) => [this.line(key, "fact"), this.line(key, "forecast")].filter(Boolean))
        .flat()
        .filter(Boolean) as LineDataModel[]),
      ...fitting,
    ];
  }

  get lnVNFLines(): LinesPair {
    const result = Object.fromEntries(
      (["fact", "forecast"] as const).map((mode) => [mode, this.line("lnVnfVol", mode, true)])
    ) as LinesPair;
    if (this.fc.settings.waterCut.method === "dc_ln_vnf_vol") {
      result.fitting = this.fittingLines(true).find(({ key }) => key.endsWith("_waterCutVol_unusual_fitting"));
    }
    return result;
  }

  get recoveryLines(): LinesPair {
    const result = Object.fromEntries(
      (["fact", "forecast"] as const).map((mode) => [mode, this.line("waterCutVol", mode, true)])
    ) as LinesPair;
    if (this.fc.settings.waterCut.method === "dc_water_cut_vol") {
      result.fitting = this.fittingLines(true).find(({ key }) => key.endsWith("_waterCutVol_fitting"));
    }
    return result;
  }

  visibleMetricLines(metric: ForecastMode): LineDataModel[] {
    return [...KNOWN_GROUPS[metric].items.values()]
      .filter((channel) => !this.hidden.has(channel))
      .map((channel) => this.line(channel, "fact"))
      .filter(Boolean) as LineDataModel[];
  }

  private get xDomain(): Domain {
    const { channels } = this.fc;
    if (channels === undefined || channels === null) {
      return { min: 0, max: 1 };
    }

    const fact = channels.factProduction?.steps;
    const forecast = channels.forecastProduction?.steps;

    return WellTechChart.stepsDomainToDayJSDomain({
      min: fact?.at(0) ?? forecast?.at(0)!,
      max: forecast?.at(-1) ?? fact?.at(-1)!,
    });
  }

  private static stepsDomainToDayJSDomain(steps: Domain): Domain {
    return {
      min: WellTechChart.stepToDayJS(+steps.min),
      max: WellTechChart.stepToDayJS(+steps.max),
    };
  }
  public static stepToDayJS(step: number): Dayjs {
    return dayjs(0)
      .subtract(1970, "year")
      .add(step - 1, "month");
  }

  private static xMetricResolver(ds: Production, yKey: string, unusual: boolean) {
    if (yKey === "lnVnfVol" && unusual) {
      return ds.accumOilProdT;
    }
    if (yKey === "waterCutVol" && unusual) {
      return ds.recoveryRate.map((v) => v * 100);
    }
    return ds.steps.map(WellTechChart.stepToDayJS);
  }

  static channelGroup(key: string): string | null {
    return Object.entries(KNOWN_GROUPS).find(([_, { items }]) => items.has(key))?.[0] ?? null;
  }

  get ungroupedChannelsKey(): string[] {
    return this.channelKeys.filter((key) => WellTechChart.channelGroup(key) === null);
  }

  findDomains(): Map<string, Domain> {
    const { channels } = this.fc;
    console.assert(
      channels !== null && channels !== undefined,
      `Запрос доменов до завершения загрузки каналов ${channels}`
    );
    const { factProduction, forecastProduction } = channels!;
    const result = new Map<string, Domain>();
    for (const key of this.channelKeys) {
      const channel: number[] = [
        ...(factProduction?.[key as keyof typeof channels] ?? []),
        ...(forecastProduction?.[key as keyof typeof channels] ?? []),
      ];
      const axisKey = axisInference(key);
      result.set(
        axisKey,
        joinDomains(valuesDomain(channel.map((v: number) => v || null) as number[]), result.get(axisKey))
      );
    }
    for (const domain of result.values()) {
      if (domain.min === domain.max) {
        domain.max = +domain.max + 1;
        domain.min = +domain.min - 1;
      }
    }
    if (result.has("%")) {
      const d = result.get("%")!;
      result.set("%", { min: +d.min * 100, max: +d.max * 100 });
    }
    return result;
  }

  get lnVNFDomains(): { x: Domain; y: Domain } {
    const { channels } = this.fc;
    if (channels === null || channels === undefined) {
      return { x: { min: 0, max: 1 }, y: { min: 0, max: 1 } };
    }
    const { factProduction, forecastProduction } = channels;
    return {
      y: joinDomains(valuesDomain(factProduction?.lnVnfVol ?? []), valuesDomain(forecastProduction?.lnVnfVol ?? [])),
      x: {
        min: Math.min(
          ...([factProduction?.accumOilProdT.at(0), forecastProduction?.accumOilProdT.at(0)].filter(
            (v) => typeof v === "number"
          ) as number[])
        ),
        max: Math.max(
          ...([factProduction?.accumOilProdT.at(-1), forecastProduction?.accumOilProdT.at(-1)].filter(
            (v) => typeof v === "number"
          ) as number[])
        ),
      },
    };
  }

  get recoveryDomains(): { x: Domain; y: Domain } {
    return {
      y: {
        min: 0,
        max: 100,
      },
      x: {
        min: 0,
        max: 100,
      },
    };
  }

  updateLineColor(key: string) {
    runInAction(() => {
      for (const kind of ["fact", "forecast"] as const) {
        const line = this.line(key, kind);
        if (line) {
          line.color = this.colors.get(key);
        }
      }
    });
  }

  get knownGroups(): Record<
    string,
    {
      title: string;
      items: Set<string>;
    }
  > {
    return {
      ...KNOWN_GROUPS,
      other: {
        title: "Другие показатели",
        items: new Set(this.ungroupedChannelsKey),
      },
    };
  }
}

export { WellTechChart };
