import { FC } from "react";

import { FullScreen } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";

import { ReactComponent as EconomicGraphsIcon } from "../icons/economicGraphs.svg";
import { ReactComponent as HydraulicGraphsIcon } from "../icons/hydraulicGraphs.svg";

import cn from "../inspectorMenu.module.less";

const CalculationEmpty: FC<{ type: "hydraulic" | "economic"; calculate?: () => void }> = ({ type, calculate }) => (
  <FullScreen className={cn.emptyBlock}>
    {type === "hydraulic" && (
      <>
        <Icon content={<HydraulicGraphsIcon />} width="48px" height="48px" viewBox="0 0 48 48" />
        Для получения динамических данных проведите гидравлический расчет
      </>
    )}
    {type === "economic" && (
      <>
        <Icon content={<EconomicGraphsIcon />} width="48px" height="48px" viewBox="0 0 48 48" />
        Для получения данных проведите экономический расчет
      </>
    )}
  </FullScreen>
);

export { CalculationEmpty };
