import { FC, useCallback, useMemo } from "react";
import { ImportOutlined } from "@ant-design/icons";
import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Table } from "@okopok/components/Table/Table";
import { Button, Input, message, Tooltip, Upload } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { RcFile } from "antd/es/upload";
import { makeAutoObservable, runInAction, transaction } from "mobx";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Loader } from "elements/loader";
import { SearchParamsStorage, useSearchParamsStorage } from "elements/useSearchParamsStorage";
import {
  genericTableDataPostprocess,
  type GenericTableRow,
  getGenericTableData,
  TableViewData,
} from "services/back/genericTable/genegicTableService";
import { conditionally } from "utils/conditionally";
import { useBooleanState } from "utils/useBooleanState";

import { type Column, SimpleTableContext } from "./simpleTable";

class Datum extends TableNode<GenericTableRow, Datum> {
  constructor(private readonly datum: GenericTableRow, parent: Datum | null = null) {
    super(parent);

    this.childrenStore = datum?.children
      ? new ChildrenStoreArray(
          this,
          datum.children.filter((child) => child !== undefined).map((child) => new Datum(child, this))
        )
      : null;
  }

  asDRow(): GenericTableRow {
    return this.datum;
  }
}

class GenericTableModel {
  uploadedFileName: string | undefined;
  loaded: string | undefined;
  store: Datum | undefined;
  requestError: string | null = null;
  columns: Column[] = [];

  constructor(private readonly message: MessageInstance, private readonly storage: SearchParamsStorage) {
    makeAutoObservable(this);
    this.path = this.path ?? "table-views/examples/1";
  }

  get path(): string {
    return this.storage.getItem("path")!;
  }

  get isLoading() {
    return this.path !== this.loaded && this.uploadedFileName === undefined;
  }

  private takeData(description: ReturnType<typeof genericTableDataPostprocess>) {
    if (description === null) {
      this.requestError = "Данные получены но структура данных не соответствует ожидаемой";
      return;
    }
    const { data, columns } = description;
    this.store = new Datum({ children: data });
    this.columns = columns;
  }

  set path(path: string) {
    this.storage.setItem("path", path);
    getGenericTableData(path, this.message)
      .then((description) =>
        runInAction(() =>
          transaction(() => {
            this.requestError = null;
            this.loaded = path;
            this.uploadedFileName = undefined;
            this.takeData(description as any);
          })
        )
      )
      .catch((result) =>
        runInAction(() => {
          this.requestError = `Не удалось получить данные ${result.status} ${result.url}`;
        })
      );
  }

  get inputStatus(): {} | { status: "warning" } {
    return conditionally(this.requestError !== null, { status: "warning" });
  }

  uploadFile(data: TableViewData, filename: string) {
    transaction(() => {
      this.loaded = undefined;
      this.requestError = null;
      this.uploadedFileName = filename;
      this.takeData(genericTableDataPostprocess(data, this.message));
    });
  }
}

const TableDebug: FC = observer(() => {
  const [messageApi, contextHolder] = message.useMessage();

  const storage = useSearchParamsStorage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const model = useMemo(() => new GenericTableModel(messageApi, storage), []);

  const [isInputFocused, setInputFocused, setInputBlur] = useBooleanState();

  const takeFile = useCallback(
    async (file: RcFile) => {
      const text = await file.text();
      try {
        model.uploadFile(JSON.parse(text), file.name);
      } catch (parseError) {
        if (parseError instanceof SyntaxError) {
          messageApi.error(`Ошибка при разборе json: ${parseError.message}`);
        } else {
          throw parseError;
        }
      }
      return "";
    },
    [messageApi, model]
  );

  return (
    <SimpleTableContext exportFileName={model.path} columns={model.columns} data={model.store}>
      <PageFrameTitlePortal>
        <Tooltip placement="bottom" title="Загрузить файлом">
          <Upload itemRender={() => null} action={takeFile} accept="*.json">
            <Button icon={<ImportOutlined />}>{model.uploadedFileName}</Button>
          </Upload>
        </Tooltip>
        <Tooltip placement="bottom" title={model.requestError} open={model.requestError !== null && isInputFocused}>
          <Input
            prefix={`${window.location.protocol}//${window.location.host}/${process.env.REACT_APP_PREFIX}/`}
            value={model.path}
            onChange={(e) => {
              model.path = e.target.value;
            }}
            onFocus={setInputFocused}
            onBlur={setInputBlur}
            {...model.inputStatus}
          />
        </Tooltip>
      </PageFrameTitlePortal>
      {contextHolder}
      <div style={{ height: "100%" }}>{model.isLoading ? <Loader /> : <Table />}</div>
    </SimpleTableContext>
  );
});

export { TableDebug };
