import { FC } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { ProjectInfo } from "./projectInfo";
import { ProjectsContextProvider } from "./projectsContextProvider";
import { ProjectsTable } from "./table";
import { TppList } from "./tppList";
import { useCreateProject } from "./useCreateProject";

import cn from "./projects.module.less";

const Projects: FC = observer(() => {
  const createProject = useCreateProject();

  return (
    <ProjectsContextProvider>
      <PageFrameTitlePortal>
        <Button onClick={createProject}>Создать проект</Button>
      </PageFrameTitlePortal>
      <div className={cn.container}>
        <TppList />
        <ProjectsTable />
        <ProjectInfo />
      </div>
    </ProjectsContextProvider>
  );
});

export { Projects };
