import { useMemo } from "react";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Column } from "features/tableDebug/simpleTable";

import { WellPadsModel } from "../models/mines";

import cn from "../infrastructureResultsTable.module.less";

const columns: Column[] = [
  {
    title: "Куст",
    width: { min: 250, max: 300, competitiveness: 1 },
    isSticky: true,
    dataKey: "title",
    type: "string",
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Дата",
    width: { min: 250, max: 300, competitiveness: 1 },
    isSticky: true,
    dataKey: "date",
    type: "string",
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Дебит жидкости, т/сут",
    width: { min: 250, max: 300, competitiveness: 1 },
    dataKey: "fluidRateT",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
    type: "number",
  },
  {
    title: "Дебит нефти, т/сут",
    width: { min: 250, max: 300, competitiveness: 1 },
    dataKey: "oilRateT",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
    type: "number",
  },
  {
    title: "Дебит воды, т/сут",
    width: { min: 250, max: 300, competitiveness: 1 },
    dataKey: "waterRateT",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
    type: "number",
  },
  {
    title: "Приемистость, т/сут",
    width: { min: 250, max: 300, competitiveness: 1 },
    dataKey: "injRateT",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
    type: "number",
  },
];

const useResultMinesTableStore = () => {
  const infrastructure = useInfrastructure()!;
  const { isCalculation } = infrastructure.calculateStore;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new WellPadsModel(infrastructure), [infrastructure, isCalculation]);
  return { columns, store };
};

export { useResultMinesTableStore };
