import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class InvestUnitCosts extends Params {
  constructor(years: Range, services: ParamsServices) {
    super("Параметры инвестиционной деятельности", years, services);
  }
}

export { InvestUnitCosts };
