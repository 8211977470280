import hash from "object-hash";

type IsTuple<T> = T extends [any, ...infer Rest] ? (Rest extends any[] ? true : false) : false;

type Fn<V, R> = (
  ...args: V extends undefined ? [] : V extends any[] ? (IsTuple<V> extends true ? V : [V]) : [V]
) => Promise<R>;

type Dec<V, R> = (fn: Fn<V, R>) => Fn<V, R>;

class LocalStorageMock<GetArgs = unknown, SetArgs = GetArgs, Response = SetArgs> {
  private hash?: string | null;

  constructor(private storageName: string, useHash = true) {
    if (!useHash) {
      this.hash = null;
    }
  }

  static new(storageName: string, useHash = true) {
    const mock = new LocalStorageMock(storageName, useHash);
    return [...mock.decorators, mock.mockKey];
  }

  private get key(): string {
    if (this.hash === undefined) {
      console.error("key access before hashing. returning raw storageName");
      return this.storageName;
    }
    if (this.hash === null) {
      return this.storageName;
    }
    return `${this.storageName}_${this.hash}`;
  }

  public get decorators(): [Dec<GetArgs, Response>, Dec<SetArgs, Response>] {
    const getter: Dec<GetArgs, Response> =
      (fn) =>
      async (...args) => {
        const result = await fn(...args);
        if (this.hash !== null) {
          this.hash = hash(result as hash.NotUndefined);
        }

        const stored = localStorage.getItem(this.key);
        if (stored !== null && stored !== "undefined") {
          return JSON.parse(stored);
        }
        localStorage.setItem(this.key, JSON.stringify(result));
        return result;
      };

    const setter: Dec<SetArgs, Response> =
      (fn) =>
      async (...args) => {
        const updatedData = await fn(...args);
        localStorage.setItem(this.key, JSON.stringify(updatedData));
        return updatedData;
      };

    return [getter, setter];
  }

  public mockKey = <Stored extends {}, Item extends {} | null>(
    getItem: (obj: Stored, ...args: any[]) => Item,
    setItem?: (obj: Stored, ...args: any[]) => (newValue: Item) => Item
  ) => {
    type Fn = (...args: any[]) => Promise<Item>;

    const keyGetter =
      (fn: Fn) =>
      async (...args: any[]) => {
        const stored = localStorage.getItem(this.key);
        if (stored === null) {
          console.warn("Intial object is not stored. Returning value from hardcoded mock for now");
          return fn(...args);
        }
        const obj: Stored = JSON.parse(stored);
        const item = getItem(obj, ...args);
        if (item === undefined) {
          const result = await fn(...args);
          if (setItem === undefined) {
            console.warn("Field not found in stored object. Returning value from hardcoded mock for now");
            return result;
          }
          const obj: Stored = JSON.parse(localStorage.getItem(this.key)!);
          setItem(obj, ...args)(result);
          localStorage.setItem(this.key, JSON.stringify(obj));
          return result;
        }
        return item;
      };

    const keySetter =
      (fn: Fn) =>
      async (...args: any[]) => {
        const newValue = await fn(...args);
        const stored = localStorage.getItem(this.key);
        if (stored === null) {
          console.error("Cant set key if initial object is not stored");
          return newValue;
        }
        const obj: Stored = JSON.parse(stored);
        if (setItem !== undefined) {
          setItem(obj, ...args)(newValue);
        } else {
          const item = getItem(obj, ...args);
          if (item !== null && newValue !== null) {
            // else item was deleted from array in getItem function
            Object.assign(item, newValue);
          }
        }
        localStorage.setItem(this.key, JSON.stringify(obj));
        return newValue;
      };

    return [keyGetter, keySetter];
  };
}

export { type Dec, LocalStorageMock };
