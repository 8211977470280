class Lazy<T> {
  #value: T | null = null;

  constructor(private readonly create: () => T) {}

  static resolved<T>(value: T): Lazy<T> {
    const result = new Lazy<T>(() => value);
    result.#value = value;
    return result;
  }

  public get value() {
    if (this.#value === null) {
      this.#value = this.create();
    }
    return this.#value;
  }
}

export { Lazy };
