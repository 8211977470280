import type { FC, ReactElement } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { Typography } from "antd";
import { observer } from "mobx-react";

import { Card } from "elements/card/card";
import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

import { useToForecast } from "./useToForecast";

import cn from "./forecastSelector.module.less";

// Предоставляет гарантию, что children будет отображен в контексте какого-то прогноза
const ForecastSelector: FC<{ children: ReactElement }> = observer(({ children }) => {
  const toForecast = useToForecast();
  const forecast = useForecast();
  const fact = useFact();
  if (forecast === undefined || fact === undefined || fact === null || fact.forecasts.isLoading) {
    return <FullScreenLoader>Загрузка информации о месторождении</FullScreenLoader>;
  }
  if (forecast === null) {
    if (fact.forecasts.length === 1) {
      return <Navigate replace to={toForecast(fact.forecasts.first!)} />;
    }
    return (
      <Card margin bodyClassName={cn.body} title="Выбор расчета">
        <Typography.Text>
          Слева внизу, в меню данных, выбран режим фактических данных. Для выбранной страницы нужно выбрать один из прогнозов
        </Typography.Text>
        <ul className={cn.ul}>
          {fact.forecasts.map((fc) => (
            <li key={fc.id}>
              <Link to={toForecast(fc)}>{fc.title}</Link>
            </li>
          ))}
        </ul>
      </Card>
    );
  }
  return children;
});

export { ForecastSelector };
