import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { MaterialBalance } from "models/project/stratum/materialBalance";

const BalanceContext = createContext<MaterialBalance | null>(null);

const MaterialBalanceContextProvider = ({ children }: PropsWithChildren) => {
  const { producingObjects, id } = useProject()!;
  const forecast = useForecast()!;
  const navigate = useNavigate();

  const materialBalance = useMemo(() => {
    return new MaterialBalance(Array.from(producingObjects.values ?? []), id, forecast?.id, navigate);
  }, [forecast?.id, id, navigate, producingObjects]);
  return <BalanceContext.Provider value={materialBalance}>{children}</BalanceContext.Provider>;
};

const useMaterialBalanceContext = (): MaterialBalance => {
  console.assert(useContext<MaterialBalance | null>(BalanceContext) !== null, "useMaterialBalanceContext out of context");
  return useContext<MaterialBalance | null>(BalanceContext)!;
};

export { MaterialBalanceContextProvider, useMaterialBalanceContext };
