import { computed, makeObservable, observable, runInAction, when } from "mobx";

import { getTechFact, WellFactProduction } from "services/back/techForecast/techForecast";

import type { WellTechForecast } from "./wellTechForecast";

class WellTechFact {
  #data = observable.box<undefined | WellFactProduction | null>();

  constructor(private fc: WellTechForecast) {
    makeObservable(this, {
      isLoading: computed,
      isStoppedWell: computed,
    });

    if (fc.group === "base") {
      when(() => fc.wasTouched, this.#forceLoad);
    } else {
      this.#data.set(null);
    }
  }

  #forceLoad = async () => {
    if (this.#data.get() !== undefined) {
      return;
    }
    try {
      const [result] = await getTechFact([
        {
          wellId: this.fc.wellId,
          stratumId: this.fc.stratumId!,
          scenarioId: this.fc.scenarioId,
        },
      ]);
      runInAction(() => {
        this.#data.set(result);
      });
    } catch (err) {
      this.#data.set(null);
    }
  };

  get data() {
    return this.#data.get();
  }

  get isLoading() {
    return this.data === undefined;
  }

  get isNull() {
    return this.data === null;
  }

  get wasShown() {
    return true;
  }

  get isStoppedWell(): boolean | undefined {
    const lastProd = this.fc.fact.data?.factProduction?.oilProdT?.at(-1);
    if (lastProd === undefined) {
      return undefined;
    }
    return lastProd === 0;
  }

  setShown = () => {};
}

export { WellTechFact };
