import { FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { VersionFemData } from "features/versionFem/versionFemData";
import { VersionFemMenu } from "features/versionFem/versionFemMenu";

import cn from "./versionFem.module.less";

const VersionFem: FC = () => {
  return (
    <div className={cn.versionFemWrapper}>
      <VersionFemMenu />
      <PageFrame className={cn.container} title="Управление изменениями ФЭМ">
        <VersionFemData />
      </PageFrame>
    </div>
  );
};

export { VersionFem };
