import { PageFrame } from "routing/pageFrame/pageFrame";

import { DashboardTable } from "features/dashboard/dashboard";
import { Preloader } from "features/preloader/preloader";
import { useProjects } from "models/project/projects";

const Dashboard = () => {
  return (
    <PageFrame title="Администрирование системы">
      <Preloader hooks={[useProjects]}>
        <DashboardTable />
      </Preloader>
    </PageFrame>
  );
};

export { Dashboard };
