import { FC } from "react";
import classNames from "classnames";

import { Format } from "elements/format/format";

import { FieldParams } from "./fieldParams";

import cn from "../inspectorMenu.module.less";

type Props = {
  coordinates: { x: number; y: number };
  altitude?: number;
  className?: string;
};

const CoordParams: FC<Props> = ({ coordinates, altitude, className }) => (
  <div className={classNames(cn.coordParamsContainer, className)}>
    <FieldParams title="Координаты">
      X <Format>{coordinates.x.toFixed(2)}</Format> м Y <Format>{coordinates.y.toFixed(2)}</Format> м
    </FieldParams>
    {altitude !== undefined && <FieldParams title="Альтитуда">{altitude} м</FieldParams>}
  </div>
);

export { CoordParams, type Props as CoordParamsType };
