import { useMemo } from "react";
import { ChartContext, Lines } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { Stick } from "elements/card/card";
import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { Format } from "elements/format/format";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";

import { MiningOilModel } from "./miningOilModel";

import cn from "./miningOil.module.less";

const YEAR = 31536000000 / 2;

const MiningOil = observer(({ model }: { model: SummaryModel }) => {
  const chartModel = useMemo(() => new MiningOilModel(model), [model]);

  return (
    <div className={cn.layout}>
      <div className={cn.wrap}>
        <Stick title="Товарная нефть" className={cn.chartStick} styles={{ margin: "0" }}>
          <span className={cn.big}>
            <Format maximumFractionDigits={1}>{chartModel.stickerValue}</Format>
          </span>{" "}
          тыс т
        </Stick>
        <ChartContext axes={useScaleFromLines(chartModel.lines, YEAR)} className={cn.chart}>
          <TooltipContext.Provider value={chartModel.tooltipManager}>
            <Lines data={chartModel.lines} />
            <PointerLine />
          </TooltipContext.Provider>
        </ChartContext>
        <TooltipDiv manager={chartModel.tooltipManager} />
      </div>
    </div>
  );
});

export { MiningOil };
