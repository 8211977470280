import { FC, useCallback, useEffect, useState } from "react";

import { Icon } from "elements/icon/icon";

import { PipeType, useInfrastructureMapContext } from "../../InfrastructureMapManager/InfrastructureMapManager";
import { ReactComponent as GoBackIcon } from "../icons/goBack.svg";
import { Container } from "../ui/container";

import { PipeCatalog } from "./pipeCatalog/pipeCatalog";
import { SelectedItem } from "./selectedItem/selectedItem";

const PipeInspector: FC<{ pipe: PipeType }> = ({ pipe }) => {
  const [showCatalog, setShowCatalog] = useState(false);

  const { inspector } = useInfrastructureMapContext();
  const { setCurrentItem, clearCurrentItem, selectedPipes, isMultipleItems } = inspector;

  useEffect(() => {
    if (isMultipleItems) {
      setCurrentItem(selectedPipes?.find(({ uuid }) => pipe.uuid === uuid)!);
    }
  }, [setCurrentItem, selectedPipes, pipe, isMultipleItems]);

  const goCatalog = useCallback(() => setShowCatalog(true), []);
  const onCloseCatalog = useCallback(() => setShowCatalog(false), []);

  return (
    <>
      {showCatalog ? (
        <Container title="Каталог">
          <PipeCatalog selectedItem={pipe} onClose={onCloseCatalog} />
        </Container>
      ) : (
        <Container
          title={
            <>
              Инспектор
              {isMultipleItems && <Icon onClick={clearCurrentItem} content={<GoBackIcon width="24px" height="25px" viewBox="0 0 24 25" />} />}
            </>
          }
        >
          <SelectedItem selectedItem={pipe} goCatalog={goCatalog} />
        </Container>
      )}
    </>
  );
};

export { PipeInspector };
