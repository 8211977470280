import dayjs, { Dayjs } from "dayjs";
import { computed, makeObservable } from "mobx";

import { global } from "models/global";
import type { WellPad } from "models/project/fact/wellPad/wellPad";
import { ProducingObject } from "models/project/producingObject/producingObject";
import type { Stratum } from "models/project/stratum/stratum";
import type { WellRaw } from "services/back/wells";

import type { LicenseRegion } from "../../licenseRegion/licenseRegion";
import { Fact } from "../fact";
import type { Forecast } from "../forecast/forecast";

type WellData = Omit<WellRaw, "date"> & {
  date: Dayjs;
};

type FondType = "Base" | "New";
type FondTranslateType = "Базовый" | "Новый";

class Well {
  public readonly data: WellData;

  constructor(data: WellRaw, public readonly fact: Fact, public readonly forecast: Forecast | null = null) {
    makeObservable<Well>(this, {
      licenseRegion: computed,
      stratum: computed,
      pad: computed,
      type: computed,
    });

    this.data = { ...data, date: dayjs(data.date) };
  }

  public get id(): number {
    return this.data.id;
  }

  public get title(): string {
    return this.data.title;
  }

  public get date(): Dayjs {
    return this.data.date;
  }

  public get mineId(): number {
    return this.data.mineId;
  }

  public get mine(): WellPad | undefined | null {
    return (this.forecast ?? this.fact)?.wellPads.at(this.data.mineId);
  }

  public get length(): number | null {
    return this.data.md;
  }

  public get licenseRegionId(): number | null {
    return this.data.licenseZoneId;
  }

  public get licenseRegion(): LicenseRegion | null {
    const regionId = this.licenseRegionId;
    if (regionId === null) return regionId;
    return this.fact.licenseRegions.at(regionId);
  }

  public get stratum(): Stratum | null | undefined {
    if (this.data.stratumId === null) {
      return null;
    }
    return this.fact.stratums.at(this.data.stratumId);
  }

  public get producingObject(): ProducingObject | null | undefined {
    const stratum = this.stratum;
    if (!stratum) {
      return stratum;
    }
    return this.fact.stratums.getProducingObject(stratum);
  }

  public get pad(): WellPad | null | undefined {
    return this.fact.wellPads.at(this.data.mineId);
  }

  public get type(): string | null | undefined {
    if (this.data.wellTypeId === null) {
      return null;
    }
    if (global.wellTypes.has(this.data.wellTypeId) === false) {
      return null;
    }
    return global.wellTypes.at(this.data.wellTypeId)?.title;
  }

  public get fond(): FondType {
    return this.forecast !== null ? "New" : "Base";
  }

  public get fondTranslate(): FondTranslateType {
    return this.forecast !== null ? "Новый" : "Базовый";
  }

  public get top(): null | { x: number; y: number } {
    if (this.data.topX === null || this.data.topY === null) {
      return null;
    }
    return {
      x: this.data.topX,
      y: this.data.topY,
    };
  }

  public get bottom(): null | { x: number; y: number } {
    if (this.data.botX === null || this.data.botY === null) {
      return null;
    }
    return {
      x: this.data.botX,
      y: this.data.botY,
    };
  }
}

export { Well };
export type { FondType, WellData };
