import { observer } from "mobx-react-lite";

import { useD3Context } from "../D3SVG/D3SVG";

import Bar from "./Bar";

const Bars = observer(() => {
  const model = useD3Context();
  return (
    <g>
      {model.bars &&
        Object.keys(model.bars!).map((year, index) => {
          let accum = 0;

          return model.bars![parseInt(year)].map((rectInfo, index2) => {
            if (index2 >= 1) {
              accum += model.bars![parseInt(year)][index2 - 1].y;
            }
            return (
              <Bar
                x={new Date(parseInt(year), 0, 1)}
                yPrev={accum}
                y={rectInfo.y}
                yId={rectInfo.yId}
                color={rectInfo.color}
                key={index * 1000 + index2}
              ></Bar>
            );
          });
        })}
    </g>
  );
});

export default Bars;
