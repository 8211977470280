import { FC } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { Button } from "antd";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { global } from "models/global";
import { useProject } from "models/project/project";
import { User } from "models/user";
import { BackendStorageMock } from "services/back/backendStorage";

type ErrorNode = {
  location: Location;
  user?: User | null;
  message: unknown;
  stack?: string;
  time: string;
};

const ErrorBoundary: FC = () => {
  const navigate = useNavigate();
  const project = useProject();
  const errorBack = new BackendStorageMock<void, ErrorNode[]>("error-log", project?.id!, undefined, false);

  const error = useRouteError() as Error;

  errorBack.addItem({
    location: window.location,
    user: global.user,
    message: error.message,
    stack: error.stack,
    time: new Date().toString(),
  });

  const returnToProject = () => {
    navigate(`/${project?.id.toString()}/general`);
    navigate(0);
  };

  return (
    <FullScreenEmpty>
      Произошла критическая ошибка. Информация, необходимая для исправления ошибки передана команде разработки приложения.
      <br />
      <Button onClick={returnToProject}>Вернуться к работе с проектом</Button>
    </FullScreenEmpty>
  );
};

export { ErrorBoundary };
