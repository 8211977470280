import { colorPrimary } from "./global";

const Theme = {
  token: {
    borderRadius: 4,
    colorPrimary,
    fontFamily: "Inter",
    margin: 0,
  },

  components: {
    Typography: {
      fontSize: 12,
      fontSizeHeading1: 20,
      lineHeightHeading1: 1.4,

      fontSizeHeading2: 16,
      lineHeightHeading2: 1.375,

      fontSizeHeading3: 14,
      lineHeightHeading3: 1.42857,

      fontSizeHeading4: 12,
      fontWeightStrong: 400,
    },
  },
};

export { Theme };
