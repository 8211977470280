import { useRef } from "react";
import { Spin } from "antd";
import classNames from "classnames";
import * as d3 from "d3";
import { useResultModel } from "routing/outlines/result/resultPage";

import { Card } from "elements/card/card";
import D3SVG from "elements/charts/lineChart/D3SVG/D3SVG";
import { SummaryModel } from "models/summary";
import { colorCarousel } from "services/colorCarousel";
import { useContainerShape } from "utils/useContainerShape";

import styles from "./reportChart.module.less";
const OperatingReportChart = () => {
  const cardRef = useRef(null);
  const cardRefShape = useContainerShape(cardRef);
  const result = useResultModel();
  const model = new SummaryModel(result);
  return (
    <Card title="Динамика денежного потока" className={classNames(styles.card)}>
      <div className={styles["cardBody"]} ref={cardRef}>
        {Number.isNaN(cardRefShape.width) ? (
          <Spin />
        ) : (
          <D3SVG
            curves={[
              {
                points: model.DynamicOperationCashFlow,
                fragments: [
                  {
                    from: new Date(model.ranges[0].from, 0, 1),
                    to: new Date(model.ranges[1].from, 0, 1),
                    lineStyles: { lineStyle: "solid" },
                    color: colorCarousel(3),
                    shadow: false,
                    opacity: 0.6,
                  },
                  {
                    from: new Date(model.ranges[1].from, 0, 1),
                    to: new Date(model.ranges[1].to, 0, 1),
                    lineStyles: { lineStyle: "dashed" },
                    color: colorCarousel(3),
                    shadow: false,
                    opacity: 0.6,
                  },
                ],
                yId: 1,
                zIndex: -1,
                curveMethod: d3.curveLinear,
                title: "ДП от операционной деятельности, млн. руб",
              },
            ]}
            width={cardRefShape.width - 7}
            height={300 - 7}
            xAxis={{
              domain: { min: new Date(model.ranges[0].from, 0, 1), max: new Date(model.ranges[0].to, 0, 1) },
            }}
            yAxesLeft={[]}
            yAxesRight={[
              {
                uom: "ДП от операционной деятельности, млн. руб",
                id: 1,
                color: colorCarousel(3),
              },
            ]}
          ></D3SVG>
        )}
      </div>
    </Card>
  );
};

export default OperatingReportChart;
