import { useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { Fact, useFact } from "models/project/fact/fact";
import { Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { useTableSettings } from "models/tableSettings";

import { type DRow, ReservesDepletion } from "./tableModel/tableModel";

import cn from "./reservesDepletion.module.less";

const useColumns = (fact: Fact, forecast: Forecast | null) =>
  useMemo<ColumnRaw<DRow>[]>(() => {
    const yearsRange = forecast?.range ?? fact.factRange;
    return [
      {
        dataKey: "name",
        title: "Параметр",
        width: 300,
        isSticky: true,
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        width: 120,
        isSticky: true,
      },
      ...[...yearsRange].map(
        (year): ColumnRaw<DRow> => ({
          dataKey: year,
          title: year,
          width: 100,
          render: (v, { value, update }) =>
            v !== undefined ? (
              value?.isEditable === true ? (
                <LazyInputNumber value={v} onUpdate={(newValue) => update?.(year, newValue)} />
              ) : (
                <div className={cn.computedCell}>
                  <Format>{v}</Format>
                </div>
              )
            ) : null,
        })
      ),
    ];
  }, [fact, forecast]);

const Reserves = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;

  const columns = useColumns(fact, forecast);
  const store = useMemo(() => new ReservesDepletion(fact, forecast), [fact, forecast]);
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ indexPath, expand }) => ({
            className:
              indexPath[0] + 1 === 0
                ? cn.summaryRow
                : expand === undefined
                ? cn.tableRowPlain
                : indexPath.length === 1
                ? cn.tableRowPrimary
                : cn.tableRowSecondary,
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store]
  );
  useTableSettings(model, "producing_objects_params_reserves");

  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal model={model} onSave={store.submit} permissionSection="tech" />
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { Reserves };
