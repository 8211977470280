import type { Dayjs } from "dayjs";

import { DateMinHeap } from "./dateMinHeap";

type DateDataRow<T> = [date: Dayjs, datum: T];
type DateDataSeries<T> = Generator<DateDataRow<T>>;

type AggregateFunction<From, To> = (datums: Array<From>) => To;

const DATE_FORMAT = "YYYY-MM-DD";

function* aggregateByDate<From, To>(
  aggFunction: AggregateFunction<From, To>,
  dataIterators: Array<DateDataSeries<From>>
): DateDataSeries<To> {
  const iters = dataIterators.map((i) => i[Symbol.iterator]());
  const dates = new DateMinHeap();
  const dataByDate = new Map<string, Array<[iter: DateDataSeries<From>, datum: From]>>();

  const processIter = (iter: DateDataSeries<From>) => {
    const result = iter.next();
    if (result.done !== false) {
      return;
    }
    const [date, prod] = result.value;
    dataByDate.get(date.format(DATE_FORMAT))?.push([iter, prod]) ||
      dataByDate.set(date.format(DATE_FORMAT), [[iter, prod]]);
    dates.push(date);
  };

  iters.forEach(processIter);

  while (dates.size > 0) {
    const currentDate = dates.popMin()!;
    const pool = dataByDate.get(currentDate.format(DATE_FORMAT))!;
    const datums = pool.map(([iter, datum]) => {
      processIter(iter);
      return datum;
    });
    yield [currentDate, aggFunction(datums)];
    dataByDate.delete(currentDate.format(DATE_FORMAT));
  }
}

export type { AggregateFunction, DateDataRow, DateDataSeries };
export { aggregateByDate };
