import { LoadableStore } from "models/loadableStore/loadableStore";
import { getProducingObjects } from "services/back/producingObjects";

import { ProducingObject } from "./producingObject";

class ProducingObjects extends LoadableStore<ProducingObject> {
  constructor(projectId: number) {
    super(() => getProducingObjects(projectId).then((objectsRaw) => objectsRaw.map((raw) => new ProducingObject(raw))));
  }
}

export { ProducingObjects };
