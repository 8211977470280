import { ResultPage } from "routing/outlines/result/resultPage";

import { OperatingResults } from "features/results/OperatingResults";

import OperatingReportChart from "./resultCharts/operatingReportChart";

import styles from "./investReport.module.less";

const OperatingReport = () => {
  return (
    <ResultPage scroll={false} title="Результаты расчета: операционная деятельность" needLicense>
      <div key="any key" className={styles.wrap}>
        <OperatingResults />
        <OperatingReportChart />
      </div>
    </ResultPage>
  );
};

export { OperatingReport };
