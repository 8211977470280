import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { reqCamel } from "utils/request";

type InjectionIntervalBack = {
  well_id: number;
  producing_object_id: number;
  first_month: number;
  first_year: number;
  months_duration: number;
};

type InjectionInterval = ObjectToCamel<InjectionIntervalBack>;

async function savePeriods(scenarioId: number, intervals: InjectionInterval[]): Promise<boolean> {
  return reqCamel
    .post(`injection/status/${scenarioId}`, intervals)
    .then(() => true) // unreachable. json parse on empty body
    .catch((err) => {
      return err instanceof SyntaxError && err.message === "Unexpected end of JSON input" ? true : false;
    });
}

export type { InjectionInterval };
export { savePeriods };
