import { Params, ParamsServices } from "models/params/params";
import { Range } from "utils/range";

class SeveranceTax extends Params {
  constructor(years: Range, services: ParamsServices) {
    super("Налоговое окружение", years, services);
  }
}

export { SeveranceTax };
