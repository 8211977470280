import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeObservable, observable } from "mobx";

import type { ParamsRow } from "features/useMetrics/paramsRow";
import { ParamsTable } from "models/params/paramsTable";
import { zip } from "utils/itertools";
import type { Range } from "utils/range";

type DRow = {
  param: string;
  measure: string;
  [year: number]: number | null;
};

class GenericTableStore extends TableNode<DRow, Datum> {
  public readonly root = this;

  constructor(private readonly store: ParamsTable, public readonly range: Range, private readonly rows: ParamsRow[]) {
    super();
    makeObservable<GenericTableStore, "rows">(this, {
      rows: observable,
    });
    this.childrenStore = new ChildrenStoreArray(
      this,
      rows.map((row) => new Datum(this, row))
    );
  }

  get isUpdated(): boolean {
    return super.isUpdated || this.store.isUpdated;
  }
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): GenericTableStore {
    return this.parent.root;
  }

  constructor(private readonly parent: Datum | GenericTableStore, private readonly row: ParamsRow) {
    super(parent, { isExpandedChildren: true });
    if (row.children) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        row.children?.map((childRow) => {
          if (childRow.setBooleanValue) {
            childRow.values = childRow.values?.map((value) => Boolean(value));
          }

          return new Datum(this, childRow);
        })
      );
    } else {
      this.childrenStore = null;
    }
    if (this.row.children !== undefined) {
      this.updateValue = undefined;
    }
    makeObservable<Datum, "row">(this, {
      row: observable,
    });
  }

  public get range(): Range {
    return this.root.range;
  }

  asDRow = (): DRow => ({
    param: this.row.title,
    measure: this.row.measure ?? "",
    ...Object.fromEntries(zip([...this.range], this.row.values ?? [])),
  });

  updateValue?(key: any, newValue: any): [prev: any, curr: any] {
    if (!this.row.values) {
      return [undefined, undefined];
    }
    const yearIdx = this.range.id(key);
    const prev = this.row.values[yearIdx];
    const curr = (this.row.values[yearIdx] = newValue);

    this.row.setBooleanValue?.(newValue, yearIdx);
    this.row.setValue?.(newValue, yearIdx);

    return [prev, curr];
  }
}

export { GenericTableStore };
