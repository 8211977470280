import type { FC } from "react";
import { observer } from "mobx-react";

import { useTechForecastModel } from "../useTechForecastModel";

import { ResultChart } from "./results/chart/resultChart";
import { UnusualChart } from "./results/chart/unusualChart";
import { ResultsDebug } from "./results/resultsDebug";
import { ResultTable } from "./results/resultTable";

const COMPONENT_MAP = {
  chart: () => <ResultChart />,
  table: () => <ResultTable />,
  lnvnf: () => <UnusualChart curve="lnVNF" />,
  recovery: () => <UnusualChart curve="recovery" />,
  debug: ResultsDebug,
};

const WellTechForecastResult: FC<{ className?: string }> = observer(({ className }) => {
  const model = useTechForecastModel();

  const Component = COMPONENT_MAP[model.resultsDisplayMode];

  return (
    <div className={className}>
      <Component />
    </div>
  );
});

export { WellTechForecastResult };
