import { observer } from "mobx-react";

import { axisWidth } from "theme/global";

import { useD3Context } from "../D3SVG/D3SVG";

import Curve from "./Curve";

const Curves = observer(() => {
  const model = useD3Context();
  return (
    <>
      <clipPath id={`clipPath-` + model.svgClassName}>
        <rect
          x={model.padding.left + model.leftAxisCount * axisWidth - 5}
          y={model.padding.top}
          width={model.width - model.padding.left - model.padding.right - model.leftAxisCount * axisWidth - model.rightAxisCount * axisWidth + 10}
          height={model.height - model.padding.top - model.padding.bottom}
        ></rect>
      </clipPath>
      {model.curves.map((_, i) => (
        <Curve i={i} key={i}></Curve>
      ))}
    </>
  );
});

export default Curves;
