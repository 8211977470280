import { TableNode } from "@okopok/components/Table";
import dayjs from "dayjs";

import { FilterCondition, FilterDetails } from "../filters/types";

import { INPUT_MAP } from "./columnRenderers";
import { CustomColumns, CustomColumnType, CustomColumnValue } from "./customColumns";

const FILTER_TYPE_BY_COLUMN_TYPE = {
  string: "stringer",
  number: "ordering",
  date: "ordering",
} as const;

type FilterTypeByColumnType<T extends CustomColumnType> = (typeof FILTER_TYPE_BY_COLUMN_TYPE)[T];

type ValidTableNode = {
  customColumns: CustomColumns;
  rowKey: string;
};
function isValidTableNode(tableNode: unknown): tableNode is ValidTableNode {
  if (!tableNode || typeof tableNode !== "object") {
    return false;
  }
  if (
    !("customColumns" in tableNode) ||
    !(tableNode.customColumns instanceof CustomColumns) ||
    !("rowKey" in tableNode) ||
    !(typeof tableNode.rowKey === "string")
  ) {
    return false;
  }
  return true;
}

function ordering(condition: FilterCondition<"ordering">, actualValue: any, value: any): boolean {
  switch (condition) {
    case "eq":
      return actualValue === value;
    case "ne":
      return actualValue !== value;
    case "ge":
      return actualValue >= value;
    case "gt":
      return actualValue > value;
    case "le":
      return actualValue <= value;
    case "lt":
      return actualValue < value;
  }
}

function predicateFactoryByType<T extends CustomColumnType>(title: string, columnType: T) {
  if (columnType === "string")
    return (condition: FilterCondition<FilterTypeByColumnType<"string">>, value: CustomColumnValue<"string">) =>
      (tableNode: unknown) => {
        if (!isValidTableNode(tableNode)) {
          return false;
        }
        value = value?.toLowerCase() ?? "";
        const { customColumns, rowKey } = tableNode;
        const actualValue: string | null | undefined = customColumns.get(title, rowKey);
        if (actualValue === null || actualValue === undefined) {
          return false;
        }
        const contains = actualValue.toLowerCase().includes(value);
        return condition === "contains" ? contains : !contains;
      };

  if (columnType === "number")
    return (condition: FilterCondition<FilterTypeByColumnType<"number">>, value: CustomColumnValue<"number">) =>
      (tableNode: unknown) => {
        if (!isValidTableNode(tableNode)) {
          return false;
        }
        const { customColumns, rowKey } = tableNode;
        const actualValue: number | null | undefined = customColumns.get(title, rowKey);
        if (actualValue === undefined || actualValue === null || value === null) {
          return false;
        }
        return ordering(condition, actualValue, value);
      };

  if (columnType === "date")
    return (condition: FilterCondition<FilterTypeByColumnType<"date">>, value: CustomColumnValue<"date">) =>
      (tableNode: unknown) => {
        if (!isValidTableNode(tableNode)) {
          return false;
        }
        const { customColumns, rowKey } = tableNode;
        const actualValue: number | null | undefined = customColumns.get(title, rowKey);
        if (actualValue === undefined || actualValue === null || value === null) {
          return false;
        }
        return ordering(condition, dayjs(actualValue), dayjs(value));
      };
}

function getFilterDetails<T extends CustomColumnType>(
  title: string,
  type: T
): FilterDetails<
  FilterTypeByColumnType<T>,
  any,
  TableNode<any, any> & { customColumns?: CustomColumns },
  CustomColumnValue<T>
> {
  return {
    type: FILTER_TYPE_BY_COLUMN_TYPE[type],
    predicateFactory: predicateFactoryByType(title, type) as any,
    InputNode: INPUT_MAP[type],
  };
}

export { getFilterDetails };
