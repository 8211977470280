import { FC, useMemo } from "react";
import { CaretDownOutlined, CaretUpOutlined, RedoOutlined } from "@ant-design/icons";
import { Table } from "@okopok/components/Table/Table";
import { Button, Empty, Popover, Tag, Tooltip, Typography } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreen } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";
import { LinkButton } from "elements/linkButton/linkButton";
import { Loader } from "elements/loader";
import { CalculationPreloader } from "features/calculationPreloader/calculationPreloader";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { TableType } from "services/back/ranking";

import { OrderSelector } from "./orderSelector";
import { ReactComponent as RankDistributionIcon } from "./rankDistribution.svg";
import { ReactComponent as RankingCriteriaIcon } from "./rankingСriteria.svg";
import { useRankingStore, useTab } from "./useRankingStore";

import "@okopok/components/style.css";
import cn from "./ranking.module.less";

const getRangColor = (rank: number) => {
  if (rank <= 50) {
    return "#FC9390";
  } else if (rank > 50 && rank <= 70) {
    return "#FDB022";
  }
  return "#16B364";
};

const SHARED_COLUMNS: Column[] = [
  {
    title: "Накопленная добыча нефти за прогнозный период, тыс т",
    dataKey: "accumOilProd",
    type: "number",
    width: { min: 230, max: 600, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "PV CAPEX, млн долл.",
    dataKey: "pvCapex",
    type: "number",
    width: { min: 140, max: 250, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "PV CAPEX к добыче, млн долл./тыс т",
    key: "pvCapexProd",
    type: "number",
    width: { min: 190, max: 250, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "PI , доли ед",
    dataKey: "pi",
    type: "number",
    width: { min: 140, max: 200, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "NPV, млн долл",
    dataKey: "npv",
    type: "number",
    width: { min: 140, max: 200, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "IRR, %",
    dataKey: "irr",
    type: "number",
    width: { min: 104, max: 104, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "PBP, лет",
    dataKey: "pbp",
    type: "number",
    width: { min: 104, max: 104, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "dPBP, лет",
    dataKey: "dpbp",
    type: "number",
    width: { min: 104, max: 104, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Залежь",
    dataKey: "stratumTitle",
    type: "string",
    width: { min: 240, max: 440, competitiveness: 1 },
    onCell: () => ({ style: { justifyContent: "start" } }),
  },
];

const DRILLING_COLUMNS: Column[] = [
  {
    title: "Куст",
    dataKey: "mineCode",
    type: "number",
    isSticky: true,
    width: { min: 84, max: 84, competitiveness: 1 },
  },
  {
    title: "Скважина",
    dataKey: "wellTitle",
    type: "number",
    isSticky: true,
    width: { min: 104, max: 104, competitiveness: 1 },
  },
];

const RECONSTRUCTION_COLUMNS: Column[] = [
  {
    title: "Скважина",
    dataKey: "wellTitle",
    type: "number",
    isSticky: true,
    width: { min: 104, max: 104, competitiveness: 1 },
  },
  {
    title: "Куст",
    dataKey: "mineCode",
    type: "number",
    isSticky: true,
    width: { min: 84, max: 84, competitiveness: 1 },
  },
  {
    title: "Мероприятие",
    dataKey: "gtm",
    type: "string",
    isSticky: true,
    width: { min: 230, max: 300, competitiveness: 1 },
  },
];

const ORDER_COLUMN: Column[] = [
  {
    title: <div />,
    key: "setorder",
    isSticky: true,
    type: "string",
    width: 30,
    onCell: () => ({ style: { padding: 0 } }),
  },
];

const useColumns = (type: TableType, isDrilling?: boolean): Column[] => {
  return useMemo(
    (): Column[] => [
      ...(type === "sequential" ? ORDER_COLUMN : []),
      ...(isDrilling ? DRILLING_COLUMNS : RECONSTRUCTION_COLUMNS),
      {
        title: "Очередь",
        dataKey: "queue",
        type: "string",
        isSticky: true,
        width: { min: 100, max: 100, competitiveness: 1 },
      },
      {
        title: "Ранг",
        key: "rank",
        dataKey: "rank",
        type: "number",
        isSticky: true,
        width: { min: 80, max: 80, competitiveness: 1 },
      },
      ...SHARED_COLUMNS,
    ],
    [isDrilling, type]
  );
};

type RankingProps = {
  type?: TableType;
};

const Ranking: FC<RankingProps> = observer(({ type = "ranking" }) => {
  const tab = useTab();
  const columns = useColumns(type, tab === "drilling");
  const project = useProject();
  const forecast = useForecast();
  const ranking = forecast![type === "ranking" ? "ranking" : "rankingSequential"];
  const store = useRankingStore(type);

  if (type === "ranking" && store.isLoading) {
    return (
      <div className={cn.body}>
        <CalculationPreloader />
        <div className={cn.loader}>
          <Loader />
          <Typography.Text>Расчет рангов</Typography.Text>
        </div>
      </div>
    );
  }
  if (type === "sequential" && store.childrenStore?.size === 0) {
    return (
      <FullScreen>
        <Empty description={<>Для начала настройки очередности проведения ГТМ необходимо сохранить результаты ранжирования</>}>
          <LinkButton to={`/${project!.id}/${forecast!.id}/ranking`}>Ранжирование ГТМ</LinkButton>
        </Empty>
      </FullScreen>
    );
  }

  return (
    <SimpleTableContext
      exportFileName={`${type === "ranking" ? "Ранжирование ГТМ" : "Очередность бурения"}: ${
        tab === "drilling" ? "Эксплуатационное бурение" : "Реконструкция скважин"
      }`}
      columns={columns}
      data={store}
      hideExpandColumn={tab !== "drilling"}
      theme={{
        headerHeight: 54,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }}
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
      customRenders={{
        setorder: (value, row) => {
          return (
            <div className={cn.arrowButtonsBlock}>
              <Button type="link" onClick={() => row?.value?.move?.("up")} icon={<CaretUpOutlined style={{ fontSize: 12 }} />} />
              <Button type="link" onClick={() => row?.value?.move?.("down")} icon={<CaretDownOutlined style={{ fontSize: 12 }} />} />
            </div>
          );
        },
        rank: (value, row) => {
          return (
            <Tag className={cn.rank} style={{ borderRadius: "100px" }} color={getRangColor(Number(value))}>
              {Math.round(value)}
            </Tag>
          );
        },
      }}
      tableSettingsId={`ranking-${type}-${tab}`}
    >
      <PageFrameTitlePortal
        onSave={ranking.submit}
        model={store}
        submitting={store.isLoading}
        submitCustomTooltip={ranking.customTooltip}
        permissionSection="ranking"
      >
        {type === "ranking" ? (
          <Popover placement="bottomRight" title="Критерии ранжирования" content={<OrderSelector />}>
            <Button type="text" icon={<Icon width="24" height="24" content={<RankingCriteriaIcon />} />} />
          </Popover>
        ) : null}
        <Tooltip title="Распределение рангов ГТМ">
          <Button type="text" icon={<Icon width="24" height="24" content={<RankDistributionIcon />} />} />
        </Tooltip>
        <Tooltip title={type === "sequential" ? "Вернуть" : "Пересчитать"}>
          <Button disabled={ranking.isLoading} onClick={() => ranking.execRanking(type === "ranking")} type="text" icon={<RedoOutlined />} />
        </Tooltip>
      </PageFrameTitlePortal>
      <div style={{ height: "100%" }}>
        <Table headerClassName={cn.tableHeader} className={cn.table} />
      </div>
    </SimpleTableContext>
  );
});

export { getRangColor, Ranking };
