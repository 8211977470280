import { type FC } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { WellsTech as WellsTechFeature } from "features/wellsTech/wellsTech";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

const useFactWells = () => useFact()?.wells;
const useForecastWells = () => useForecast()?.wells ?? null;
const useProductionFact = () => useFact()?.production;
const useProductionForecast = () => useForecast()?.production ?? null;

const Tech: FC = observer(() => (
  <Preloader hooks={[useFactWells, useForecastWells, useProductionFact, useProductionForecast]}>
    <PageFrame title="Данные по скважинам">
      <WellsTechFeature />
    </PageFrame>
  </Preloader>
));

export { Tech };
