import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { constructionKeys } from "features/infrastructure/utils";
import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { InfrastructureCatalog } from "models/project/fact/infrastructure/infrastructureCatalog";
import { MineType } from "services/back/infrastructure/catalog";
import { getRandomUid } from "utils/random";

type DRow = {
  title: string;
  numberOfWells: number;
  total: number;
  construction?: MineType["construction"];
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;

  remove?: () => void;
};

class WellPad extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data!,
    total: this.total,
    remove: this.remove,
  });
  public data?: { dsw: number; ciw: number; equipment: number; other: number } & MineType;
  constructor(private parent: WellPads, data: MineType, private catalog: InfrastructureCatalog) {
    super(parent);

    makeObservable(this, { data: observable, total: computed, init: action, updateValue: action });

    this.init(data);
  }

  init = (data: MineType) => {
    const { dsw, ciw, equipment, other } = data.construction!;
    this.data = { ...data, dsw, ciw, equipment, other };
  };

  get total() {
    const { dsw, ciw, equipment, other } = this.data!;
    return dsw + ciw + equipment + other;
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof MineType, newValue as never];
    const prev = this.data![k];
    this.data![k] = value;

    if (constructionKeys[key as keyof typeof constructionKeys]) {
      const construction = { ...this.data?.construction!, [key]: value, totalCostPerUnit: this.total };
      this.data!.construction = construction;
      this.catalog.update({ uuid: this.data!.uuid, construction }, "mine");
    } else {
      this.catalog.update({ uuid: this.data!.uuid, [k]: value }, "mine");
    }
    return [prev, value];
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.catalog.remove(this.data!.uuid, "mine");
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

class WellPads extends TableNode<DRow, WellPad> {
  constructor(public infrastructure: Infrastructure) {
    super();
    this.init();
  }

  public init = () => {
    const { mines } = this.infrastructure.catalog;
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        mines.map((mine) => new WellPad(this, mine, this.infrastructure.catalog)) ?? []
      );
    });
  };

  public push = () => {
    const wellpad: MineType = {
      id: Math.floor(Math.random() * 1000),
      uuid: getRandomUid(),
      title: "Новый куст",
      numberOfWells: 0,
      construction: {
        dsw: 0,
        ciw: 0,
        other: 0,
        equipment: 0,
        totalCostPerUnit: 0,
      },
    };
    this.infrastructure.catalog.push(wellpad, "mine");
    this.childrenStore?.push(new WellPad(this, wellpad, this.infrastructure.catalog));
  };
}

export { type DRow as DRowMines, WellPads };
