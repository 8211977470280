const START_YEAR = 2004;
const CREATION_MOMENT = 2023;

const mock = [
  {
    name: "Южно - Выинтойское",
    key: "южно-выинтойское",
    isMixedSelling: false,
    startYear: START_YEAR,
  },
];

const getFields = (): Promise<typeof mock> => Promise.resolve(mock);

export { CREATION_MOMENT, getFields, START_YEAR };
