import { makeAutoObservable, reaction } from "mobx";
import { isPersisting, makePersistable } from "mobx-persist-store";

import { Forecast } from "models/project/fact/forecast/forecast";
import { TreeNode, TreeRoot } from "models/tree/tree";

import { Project } from "../project";

const processTreeSelect = (node: TreeNode<any> | TreeRoot, selectedIds: Set<number> | null) => {
  if ("item" in node && node.item instanceof Forecast) {
    if (selectedIds === null) {
      if (node.asTableItem.select?.status === "deselected") node.asTableItem.select?.onSelect();
    } else if (selectedIds.has(node.item.id)) {
      if (node.asTableItem.select?.status === "deselected") node.asTableItem.select?.onSelect();
    } else {
      if (node.asTableItem.select?.status === "selected") node.asTableItem.select?.onDeselect();
    }
  }
  if ("children" in node && node.children) {
    for (const c of node.children) {
      processTreeSelect(c, selectedIds);
    }
    if (selectedIds === null) {
      if (node.asTableItem.select?.status === "deselected") node.asTableItem.select?.onSelect();
    } else if (selectedIds.size === 0) {
      if (node.asTableItem.select?.status !== "deselected") node.asTableItem.select?.onDeselect();
    }
  }
};

class ScenarioComparison {
  public selectedScenarios?: number[];
  private treeState: TreeRoot = new TreeRoot(
    "Сценарии",
    [...this.project.fact.forecasts.values!],
    [],
    [],
    true,
    undefined,
    false
  );

  constructor(private project: Project) {
    this.selectedScenarios = undefined;

    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: `compare_scenario_settings_for_project_${project.id}`,
        properties: ["selectedScenarios"],
        storage: window.localStorage,
      },
      {}
    );

    reaction(
      () => [...this.project.fact.forecasts.values!].map((f) => f.id).join("-"),
      () => {
        this.treeState = new TreeRoot(
          "Сценарии",
          [...this.project.fact.forecasts.values!],
          [],
          [],
          true,
          undefined,
          false
        );
      }
    );

    reaction(
      () => this.treeState.selectedItems,
      () => {
        this.selectedScenarios = this.treeState.selectedItems.map((e) => e.item.id);
      }
    );
    reaction(
      () => this.isPersisting,
      () => {
        if (this.selectedScenarios) {
          processTreeSelect(this.treeState, new Set(this.selectedScenarios));
        }
      }
    );
  }

  get tree() {
    return this.treeState;
  }

  public get isPersisting() {
    return isPersisting(this);
  }
}

export { ScenarioComparison };
