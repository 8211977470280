import { MessageInstance } from "antd/es/message/interface";
import dayjs from "dayjs";

import { Column } from "features/tableDebug/simpleTable";
import { req } from "utils/request";
import { ofTree } from "utils/tree";

type GenericTableRow = Record<string, any> & { children?: GenericTableRow[] };

type TableViewData = {
  data: GenericTableRow[];
  columns: Array<{
    key: string;
    title?: string;
    width?: {
      min: number;
      max: number;
      competitiveness: number;
    };
  }>;
};

const MOCK = (() => {
  try {
    return require("./all_prod_hist_agg_uwi.json");
  } catch {
    return [];
  }
})();

const VALUE: GenericTableRow[] = MOCK.map(
  ({ reservoir_ksss_codes, ...other }: any) =>
    ({
      ...other,
      ...(reservoir_ksss_codes ? { children: reservoir_ksss_codes as GenericTableRow[] } : {}),
    } as GenericTableRow)
);

const getGenericTableData = async (
  path: string,
  message: MessageInstance,
  method: "GET" | "POST" = "GET",
  payload: any = null
): Promise<{
  data: GenericTableRow[];
  columns: Column[];
} | null> => {
  if (path === "mock_path") {
    return Promise.resolve({
      data: VALUE,
      columns: [
        {
          key: "overallMaxDate",
          title: "окончательная дата",
          width: { min: 100, max: 100, competitiveness: 1 },
          type: "string",
        },
        {
          key: "wellbore_uwi",
          width: { min: 100, max: 150, competitiveness: 1 },
          type: "string",
        },
      ],
    });
  }
  if (method === "GET") {
    return genericTableDataPostprocess(await req.get<TableViewData>(path), message);
  } else if (method === "POST") {
    return genericTableDataPostprocess(await req.post<TableViewData>(path, payload ?? {}), message);
  } else {
    console.error(`Not implemented method ${method}`);
    return null;
  }
};

const genericTableDataPostprocess = (result: TableViewData, message: MessageInstance) => {
  if (result.data === undefined) {
    message.error(`Передана структура, не содержащая поле data. Получены поля ${Object.keys(result).join(", ")}`);
    return null;
  }
  if (result.columns === undefined) {
    message.error(`Передана структура, не содержащая поле columns. Получены поля ${Object.keys(result).join(", ")}`);
    return null;
  }
  if (!Array.isArray(result.data)) {
    message.error(`Поле data не является массивом (${typeof result.data})`);
    return null;
  }
  if (!Array.isArray(result.columns)) {
    message.error(`Поле columns не является массивом (${typeof result.columns})`);
    return null;
  }
  for (const column of result.columns) {
    if (column.key === undefined) {
      message.error(`Для одной из колонок не передан ключ (${JSON.stringify(column)})`);
      return null;
    }
    if (column.title === undefined) {
      message.warning(`Для колонки ${column.key} не передан заголовок, будет использован ключ`);
    }
    if (column.width === undefined) {
      message.warning(
        `Для колонки ${column.key} не переданы параметры ширины. пойдёт число либо структура вида {min, max, competitiveness}`
      );
    } else if (typeof column.width !== "number") {
      // @todo проверка формата ширины
    }
  }

  const knownKeys = new Set(result.columns.map(({ key }) => key));

  const types = new Map<string, Set<"string" | "number" | "date">>();
  for (const node of ofTree(result.data)) {
    for (const key in node)
      if (key !== "children") {
        if (!types.has(key)) {
          types.set(key, new Set());
        }
        const nodeType = typeof node[key];
        if (nodeType === "string" || nodeType === "number") {
          if (dayjs(node[key]).isValid()) {
            types.get(key)!.add("date");
          } else {
            types.get(key)!.add(nodeType);
          }
        } else {
          message.warning(`Неподдерживаемый тип - ${nodeType}`);
        }
      }
  }
  for (const key of types.keys())
    if (knownKeys.has(key)) {
      if (!knownKeys.has(key)) {
        message.warning(`Используемый в данных ключ ${key} не упомянут в списке колонок и не будет виден в таблице`);
      }
      if (types.get(key)!.size > 1) {
        message.warning(
          `Для поля ${key} заданы данные разных типов (${[...types.get(key)!.values()].join(
            ", "
          )}), будет трактовано как ${[...types.get(key)!.values()][0]}`
        );
      }
      const [type] = [...types.get(key)!.values()];
      if (type !== "string" && type !== "number") {
        message.warning(`Для поля ${key} задан не стандартный тип. Будет использован string`);
      }
    }

  return {
    data: result.data,
    columns: result.columns.map((column) => {
      const [type] = [...[types.get(column.key) ?? ["string"]].values()][0];
      return {
        ...column,
        type: (["string", "number"].includes(type) ? type : "string") as "string" | "number",
        title: column.title ?? column.key,
      };
    }),
  };
};

export type { GenericTableRow, TableViewData };
export { genericTableDataPostprocess, getGenericTableData };
