import { PageFrame } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { Summary as SummaryFeature } from "features/techPrediction/summary/summary";

import { SUMMARY_PRELOADER } from "./preloads";

const Summary = () => (
  <PageFrame title="Сводка по прогнозу технологических показателей">
    <ForecastSelector>
      <Preloader hooks={SUMMARY_PRELOADER}>
        <SummaryFeature />
      </Preloader>
    </ForecastSelector>
  </PageFrame>
);

export { Summary };
