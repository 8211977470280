import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { PipeBoundaryCondition } from "services/back/infrastructure/types";

type FormatDrainSourceType<T> = { uuid: string; title: string; params: T[] };

type DrainSourceType = { date: Dayjs } & Omit<PipeBoundaryCondition, "date">;

type DRow = {
  title: string;
  date: string;
  pressure: number;
};

class DrainSource extends TableNode<DRow> {
  asDRow = (): DRow => ({
    ...this.data,
    pressure: this.data.boundaryCondition?.pressure ?? 0,
    date: dayjs(this.data.date).format("MM.YYYY"),
  });
  data: DrainSourceType;
  constructor(private parent: DrainSources, data: DrainSourceType) {
    super(parent);

    this.data = data;
  }
}
class DrainSources extends TableNode<DRow, DrainSource> {
  asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(public parent: DrainSourcesModel, private name: string, private params: DrainSourceType[]) {
    super(parent, { isExpandedChildren: true });

    this.initChildren();
  }

  initChildren() {
    const array = this.params.map((el) => new DrainSource(this, el));
    this.childrenStore = new ChildrenStoreArray(this, array);
  }
}
class DrainSourcesModel extends TableNode<DRow, DrainSources> {
  constructor(public infrastructure: Infrastructure, private type: "drain" | "source") {
    super();

    this.initChildren();
  }

  initChildren() {
    const calculations = this.infrastructure.calculateStore.hydraulicDataCollection;
    const arrayMap = new Map<string, FormatDrainSourceType<DrainSourceType>>();
    for (const calculation of calculations || []) {
      if (!calculation.drainSources.length) {
        continue;
      }
      const { drainSources, date } = calculation;
      DrainSourcesModel.formatData(drainSources.filter((el) => el.mode === this.type) || [], arrayMap, date);
    }
    const resultArray = [...arrayMap.values()];
    this.childrenStore = new ChildrenStoreArray(
      this,
      resultArray.map((el) => new DrainSources(this, el.title, el.params))
    );
  }

  static formatData = (
    data: PipeBoundaryCondition[],
    result: Map<string, FormatDrainSourceType<DrainSourceType>>,
    date: Dayjs
  ) => {
    for (const item of data) {
      const newItem = {
        ...item,
        date,
      };
      if (result.has(item.nodeUuid)) {
        result.get(item.nodeUuid)?.params.push(newItem);
      } else {
        result.set(item.nodeUuid, {
          uuid: item.uuid,
          title: item.title,
          params: [newItem],
        });
      }
    }
  };
}

export { DrainSourcesModel, type DRow as DRowDrainSource };
