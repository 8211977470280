import { req } from "utils/request";

type FieldBackend = {
  id: number;
  title: string;
  code: string;
  mines: never[] | null;
};

async function getAllFields(): Promise<Array<FieldBackend>> {
  return req.get<FieldBackend[]>("fields/");
}

export { getAllFields };
