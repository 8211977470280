import { expandArr } from "utils/expandArray";
import { LocalStorageMock } from "utils/localStorageMock";
import { Range } from "utils/range";

import { START_YEAR } from "./field";

type ProvincesType = {
  startYear: number;
  data: { title: string; data: number[]; id: number }[];
};

type Province = {
  title: string;
  id: number;
};

const mock = {
  startYear: START_YEAR,
  data: [
    {
      title: "Западная Сибирь",
      data: [
        161, 204, 209, 255, 273, 215, 235, 318, 329, 322, 278, 205, 183, 249, 325, 316, 206, 393, 397, 314, 323, 317,
        335, 341, 348, 355, 362, 370, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377, 377,
        377, 377, 377, 377, 377, 377,
      ],
    },
    {
      title: "Пермь",
      data: [
        167, 210, 217, 264, 285, 226, 249, 335, 346, 339, 293, 213, 192, 261, 336, 327, 216, 404, 408, 325, 335, 328,
        346, 353, 360, 367, 375, 382, 390,
      ],
    },
    {
      title: "Ярега",
      data: [
        100, 146, 178, 207, 227, 186, 207, 272, 191, 326, 283, 210, 182, 252, 332, 319, 212, 394, 405, 322, 331, 325,
        343, 350, 357, 364, 371, 379, 387,
      ],
    },
    {
      title: "Нижняя Волга",
      data: [
        169, 214, 224, 274, 296, 238, 263, 355, 370, 364, 313, 227, 205, 275, 347, 341, 228, 413, 416, 334, 344, 338,
        356, 363, 370, 378, 385, 393, 401,
      ],
    },
    {
      title: "Каспий",
      data: [
        0, 0, 0, 0, 0, 0, 0, 326, 360, 351, 300, 224, 201, 302, 380, 355, 229, 452, 642, 480, 444, 405, 410, 419, 428,
        438, 447, 458, 467,
      ],
    },
    {
      title: "Коми",
      data: [
        166, 208, 213, 259, 280, 221, 242, 325, 333, 326, 283, 204, 185, 254, 331, 321, 211, 394, 405, 322, 331, 325,
        343, 350, 357, 364, 371, 379, 387,
      ],
    },
    {
      title: "Ненецкий АО",
      data: [
        0, 0, 0, 0, 253, 209, 240, 281, 269, 345, 275, 199, 164, 243, 327, 314, 216, 410, 416, 331, 341, 335, 354, 361,
        369, 377, 385, 393, 401,
      ],
    },
    {
      title: "Калининград (Ладушкинская)",
      data: [
        213, 263, 277, 319, 356, 265, 305, 411, 415, 405, 358, 254, 222, 304, 390, 374, 248, 459, 500, 399, 412, 408,
        427, 435, 443, 451, 459, 468, 477,
      ],
    },
    {
      title: "Татарстан",
      data: [
        167, 210, 217, 264, 285, 226, 249, 335, 341, 334, 301, 218, 188, 259, 339, 326, 222, 403, 413, 331, 340, 334,
        352, 359, 366, 373, 381, 389, 397,
      ],
    },
    {
      title: "Большехетская впадина (малосернистая нефть - Вост. Сибирь)",
      data: [
        0, 0, 0, 0, 0, 0, 0, 398, 383, 347, 323, 235, 170, 239, 319, 308, 203, 426, 525, 397, 402, 372, 375, 383, 391,
        399, 406, 415, 423,
      ],
    },
    {
      title: "Восточная Сибирь",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 296, 0, 0, 230, 209, 281, 370, 356, 239, 432, 531, 404, 409, 379, 382, 390, 398, 406,
        414, 423, 431,
      ],
    },
    {
      title: "Обская губа",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 3070, 291, 199, 168, 209, 290, 276, 197, 394, 385, 300, 309, 303, 320, 326, 333, 339,
        346, 352, 359,
      ],
    },
    {
      title: "Ярега ж/д",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 82, 68, 104, 102, 182, 55, 203, 331, 238, 204, 180, 180, 185, 189, 193, 197,
        202, 205,
      ],
    },
    {
      title: "Самара",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 348, 340, 303, 221, 195, 267, 346, 334, 224, 411, 414, 333, 342, 336, 354, 361, 369,
        376, 383, 391, 399,
      ],
    },
    {
      title: "Ненецкий АО (Альтерн)",
      data: [
        0, 0, 0, 0, 253, 209, 240, 281, 269, 345, 275, 199, 164, 243, 327, 314, 248, 439, 445, 360, 370, 364, 383, 390,
        398, 406, 414, 422, 429,
      ],
    },
    {
      title: "Западная Сибирь (третьи лица)",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      title: "Хатангский залив",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 92, 189, 189, 280, 166, 354, 351, 264, 274, 267, 284, 290, 296, 301, 308,
        314, 320,
      ],
    },
    {
      title: "Западная Сибирь (малосернистая нефть)",
      data: [
        161, 204, 209, 255, 273, 215, 235, 318, 329, 322, 315, 225, 206, 270, 352, 343, 222, 417, 460, 357, 353, 338,
        353, 360, 367, 375, 382, 390, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397, 397,
        397, 397, 397, 397, 397, 397,
      ],
    },
    {
      title: "РИТЭК (малосернистая нефть)",
      data: [
        169, 214, 224, 274, 296, 238, 263, 355, 370, 364, 337, 240, 220, 287, 368, 360, 237, 434, 478, 376, 372, 358,
        372, 380, 387, 395, 403, 411, 419,
      ],
    },
    {
      title: "Калининград (Романово)",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      title: "Оренбургская обл. (КС-1 Бурдыгино)",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      title: "Большехетская впадина (малосернистая нефть - Новороссийск)",
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 411, 454, 350, 346, 331, 345, 353, 360, 367, 374, 382, 389,
      ],
    },
  ].map((v, id) => ({ ...v, id })),
};

const getProvincesList = (): Promise<Province[]> =>
  Promise.resolve(
    mock.data.map(({ title, id }) => ({
      title,
      id,
    }))
  );

const getProvinceData = async (
  provinceId: number,
  range: Range,
  forecastKey?: string
): Promise<Array<number | null>> => {
  const provinceData = mock.data.find(({ id }) => id === provinceId)?.data;
  if (provinceData === undefined) {
    console.error("Запрос несуществующего направления");
    return range.array.fill(null);
  }
  return expandArr(provinceData, mock.startYear, range);
};

const setProvinceData = async (
  provinceId: number,
  data: Array<number | null>,
  forecastKey?: string
): Promise<Array<number | null>> => {
  if (!mock.data.some(({ id }) => id === provinceId)) {
    console.error("Попытка сохранить несуществующее направление");
    return Promise.resolve(new Array(data.length).fill(null));
  }
  return Promise.resolve(data);
};

const getNetto = async (range: Range, forecastKey?: string): Promise<Array<number | null>> => {
  return Promise.resolve(
    expandArr(
      [
        8, 10, 8, 13, 23, 10, 26, 42, 42, 51, 55, 36, 34, 40, 38, 32, 31, 31, 32, 33, 34, 34, 34, 35, 35, 36, 36, 36,
        37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37, 37,
      ],
      mock.startYear,
      range
    )
  );
};

const setNetto = async (data: Array<number | null>, forecastKey?: string): Promise<Array<number | null>> => {
  return Promise.resolve(data);
};

const nettoSource = (forecastKey?: string) => {
  type NettoStore = {
    netto: Array<number | null>;
    [key: `oilWay-${number}`]: Array<number | null>;
  };

  const storageName = `Netto_${forecastKey ?? "Fact"}`;
  if (localStorage.getItem(storageName) === null) {
    localStorage.setItem(storageName, JSON.stringify({}));
  }

  const { mockKey } = new LocalStorageMock(storageName, false);

  const [getNettoDec, setNettoDec] = mockKey<NettoStore, NettoStore["netto"]>(
    (obj) => obj.netto,
    (obj) => (newValue) => (obj.netto = newValue)
  );
  const [getPDataDec, setPDataDec] = mockKey<NettoStore, NettoStore[`oilWay-${number}`]>(
    (obj, provinceKey: number) => obj[`oilWay-${provinceKey}`],
    (obj, provinceKey: number) => (newValue) => (obj[`oilWay-${provinceKey}`] = newValue)
  );
  return {
    netto: {
      get: getNettoDec((range: Range) => getNetto(range, forecastKey)),
      set: setNettoDec((data: Array<number | null>) => setNetto(data, forecastKey)),
    },
    provinceData: {
      get: getPDataDec((provinceKey: number, range: Range) => getProvinceData(provinceKey, range, forecastKey)),
      set: setPDataDec((provinceKey: number, data: Array<number | null>) =>
        setProvinceData(provinceKey, data, forecastKey)
      ),
    },
  };
};

export type { Province, ProvincesType };
export { getProvincesList, nettoSource };
