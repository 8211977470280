import { FC, ReactNode } from "react";
import classNames from "classnames";

import cn from "../inspectorMenu.module.less";

const FieldParams: FC<{ title: ReactNode; className?: string; children?: ReactNode }> = ({ title, className, children }) => (
  <div className={classNames(cn.fieldParams, className)}>
    <div>{title}</div>
    {children && <div>{children}</div>}
  </div>
);

export { FieldParams };
