/* eslint-disable @typescript-eslint/no-unused-vars */
import { type FC, type ReactNode, useMemo, useState } from "react";
import { Typography } from "antd";
import classnames from "classnames";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { useLegendContext } from "elements/charts/lineChart/LegendModel";
import { useInteractiveRef } from "utils/useInteractiveRef";

import Legend from "./Legend/legend";
import { CardToolboxPortalContextProvider } from "./cardTitlePortal";

import cn from "./card.module.less";

type LegendType = {
  style: { color: string; hatchId?: number | undefined };
  title: string;
};

type CardProps = {
  title?: ReactNode;
  children: ReactNode;
  className?: string;
  bodyClassName?: string;
  margin?: boolean;
};

type SectionProps = {
  title?: ReactNode;
  children: ReactNode;
  subtitle: ReactNode;
  className?: string;
};

type StickProps = {
  title?: ReactNode;
  children: ReactNode;
  className?: string;
  transparent?: boolean;
  styles?: Object;
};

const Section: FC<SectionProps> = ({ title, children, subtitle, className }) => (
  <div className={classnames(cn.section, className)}>
    {title && (
      <Typography.Title level={3} className={cn.title}>
        {title}
        {subtitle && (
          <>
            <br />
            <Typography.Text type="secondary">{subtitle}</Typography.Text>
          </>
        )}
      </Typography.Title>
    )}
    <div className={cn.body}>{children}</div>
  </div>
);

const Card: FC<CardProps> & { Section: typeof Section } = ({ margin = false, title, children, className, bodyClassName }: CardProps) => {
  const toolboxRef = useInteractiveRef<HTMLDivElement>();
  const toolboxContext = useMemo(
    () => ({
      toolboxRef: toolboxRef,
    }),
    [toolboxRef]
  );
  return (
    <div className={classnames(cn.card, { [cn.margin]: margin }, className)}>
      {title && (
        <div className={cn.header}>
          <Typography.Title level={2} className={cn.title}>
            {title}
          </Typography.Title>
          <Legend></Legend>
          <div ref={toolboxRef} />
        </div>
      )}
      <CardToolboxPortalContextProvider value={toolboxContext}>
        <div className={classNames(cn.body, bodyClassName)}>{children}</div>
      </CardToolboxPortalContextProvider>
    </div>
  );
};

Card.Section = Section;

const Stick: FC<StickProps> = ({ title, children, className, transparent, styles }: StickProps) => (
  <div className={classnames(cn.stick, className, transparent !== undefined && transparent ? cn.stickTransparent : "")} style={styles}>
    {title && <div className={cn.stickHeader}>{title}</div>}
    <div className={cn.body}>{children}</div>
  </div>
);

export { Card };
export { Stick };
