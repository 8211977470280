import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { type Dayjs } from "dayjs";
import { saveAs } from "file-saver";
import { action, computed, makeObservable, observable } from "mobx";
import { objectToSnake } from "ts-case-convert";

import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { Nodes as NodesModel, NodeType } from "models/project/fact/infrastructure/nodes";
import { Pipes as PipesModel, PipeType } from "models/project/fact/infrastructure/pipes";

import { findExistingNode, tooltipText } from "../utils";

import { Inspector } from "./inspector";
import { Selection } from "./selection";

const addViewportMargin = ([x, y, dx, dy]: [number, number, number, number], margin: number) => [
  x - dx * (margin / 2),
  y - dy * (margin / 2),
  dx * (margin + 1),
  dy * (margin + 1),
];

type Position = { x: number; y: number };
type NodePoint = { uuid: string } & Position;
type InteractionMode = "selection" | "create" | "ruler";
type Ruler = { x: number; y: number };
type PipeMode = "oil" | "water";
type PipeColorMode = "velocity" | "pressure";

class InfrastructureMapManager {
  interactionMode: InteractionMode = "selection";
  pipeColorMode: PipeColorMode = "velocity";
  pipeMode: PipeMode = "oil";

  tooltip: string | null = null;
  cursorPosition: Position | null = null;
  zoomedCursorPosition: Position | null = null;
  lineEnd: ({ id?: number; uuid?: string } & Position) | null = null;
  ruler: Ruler | null = null;
  isDragging: boolean = false;
  newPipe: { start: NodePoint | null; end: NodePoint | null } | null = null;
  selection = new Selection(this);
  inspector = new Inspector(this);
  showMapText: boolean = true;
  showMapTooltip: boolean = true;
  error?: string;

  constructor(public readonly infrastructure: Infrastructure) {
    makeObservable(this, {
      interactionMode: observable,
      tooltip: observable,
      pipeMode: observable,
      pipeColorMode: observable,
      isDragging: observable,
      cursorPosition: observable,
      newPipe: observable,
      zoomedCursorPosition: observable,
      lineEnd: observable,
      ruler: observable,
      inspector: observable,
      selection: observable,
      showMapText: observable,
      showMapTooltip: observable,
      error: observable,
      isSelection: computed,
      isCreate: computed,
      isRuler: computed,
      setRuler: action,
      updateCursorPosition: action,
      updateInteractionMode: action,
      updateDragging: action,
      setTooltip: action,
      removeSelectedItems: action,
      setLineEnd: action,
      setPipeMode: action,
      setPipeColorMode: action,
      setShowMapText: action,
      setShowMapTooltip: action,
      resetCreation: action,
      pushPipe: action,
      setError: action,
    });
  }

  get isSelection() {
    return this.interactionMode === "selection";
  }

  get isCreate() {
    return this.interactionMode === "create";
  }

  get isRuler() {
    return this.interactionMode === "ruler";
  }

  get isDrawing(): boolean {
    return !!(this.newPipe && !this.newPipe.end);
  }

  get viewPort(): string {
    const nodes = this.infrastructure?.nodes;
    if (!nodes) {
      return "-1 -1 2 2";
    }
    if (nodes.length === 0) {
      return "-1 -1 2 2";
    }
    let xMin, xMax, yMin, yMax;
    xMin = xMax = nodes.data![0].x;
    yMin = yMax = nodes.data![0].y;
    for (const { x, y } of nodes.data!) {
      xMin = Math.min(xMin, x);
      xMax = Math.max(xMax, x);
      yMin = Math.min(yMin, y);
      yMax = Math.max(yMax, y);
    }
    return addViewportMargin([xMin, yMin, xMax - xMin, yMax - yMin], 0.1).join(" ");
  }

  get pipes(): PipeType[] {
    return (this.infrastructure?.pipes.data ?? []).map((el) => ({
      ...el,
      isSelected: this.selection.selectedPipeIds.includes(el.uuid),
    }));
  }

  get waterPipes() {
    return this.pipes!.filter(({ segmentType }) => segmentType === "inj");
  }
  get oilPipes() {
    return this.pipes!.filter(({ segmentType }) => segmentType === "prod");
  }

  get calculateStore() {
    return this.infrastructure.calculateStore;
  }

  public get pipeMap() {
    const type = this.pipeMode === "oil" ? "prod" : "inj";
    if (!(this.calculateStore.hydraulicData && this.calculateStore.hydraulicData[type]?.segments)) {
      return undefined;
    }
    const nodeMap = new Map(this.calculateStore.hydraulicData[type]?.nodes?.map((node) => [node.uuid, node.pressure]));
    const pipesMap = new Map<string, { pressure: [number, number]; velocity: number }>();
    for (const { uuid, firstNodeUuid, secondNodeUuid, velocity } of this.calculateStore.hydraulicData[type]?.segments ?? []) {
      const pressure: [number, number] = [nodeMap.get(firstNodeUuid) || 0, nodeMap.get(secondNodeUuid) || 0];
      pipesMap.set(uuid, { pressure, velocity });
    }
    return pipesMap;
  }

  public get nodeMap() {
    const type = this.pipeMode === "oil" ? "prod" : "inj";
    if (!this.calculateStore.hydraulicData) {
      return undefined;
    }
    if (!this.calculateStore.hydraulicData[type]?.nodes) {
    }
    const nodesMap = new Map<string, { pressure: number } & Partial<NodeType>>();
    this.calculateStore.hydraulicData[type]?.nodes.forEach((el) => nodesMap.set(el.uuid, el));
    return nodesMap;
  }

  get nodes(): NodeType[] {
    const mode = this.pipeMode;
    const { waterPipes, oilPipes } = this;
    const disabledNodesIds = new Set();

    const getCheckPipes = (pipes: PipeType[], id: string) => pipes.some(({ from, to }) => from === id || to === id);
    (this.infrastructure?.nodes.data ?? []).forEach(({ uuid }) => {
      const isCheckDeactive = getCheckPipes(mode === "oil" ? waterPipes : oilPipes, uuid);
      const isCheckActive = getCheckPipes(mode === "oil" ? oilPipes : waterPipes, uuid);
      if (isCheckDeactive && !isCheckActive) {
        disabledNodesIds.add(uuid);
      }
    });

    return (this.infrastructure?.nodes.data ?? []).map((el) => ({
      ...el,
      isSelected: this.selection.selectedNodeIds.includes(el.uuid),
      isDisabled: el.isDisabled || disabledNodesIds.has(el.uuid),
      pressure: this.nodeMap?.get(el.uuid)?.pressure || 0,
    }));
  }

  public get range(): [number, number] {
    const mode = this.pipeColorMode;
    const type = this.pipeMode === "oil" ? "prod" : "inj";
    if (this.calculateStore.hydraulicData === null) {
      return [0, 0];
    }
    const velocities = this.calculateStore.hydraulicData?.[type]?.segments.map((el) => Math.abs(el.velocity)) || [];
    const pressures = this.calculateStore.hydraulicData?.[type]?.nodes.map((el) => Math.abs(el.pressure)) || [];
    if (mode === "pressure") {
      if (this.calculateStore.hydraulicData?.[type]?.nodes?.length === 1) {
        return [0, pressures[0]];
      }
      if (pressures.length === 0) {
        return [0, 0];
      }
    } else {
      if (velocities.length === 1) {
        return [0, velocities[0]];
      }
      if (velocities.length === 0) {
        return [0, 0];
      }
    }

    let [minVelocity, maxVelocity] = [Infinity, -Infinity];
    for (const item of mode === "pressure" ? pressures : velocities) {
      if (item < minVelocity) minVelocity = item;
      if (item > maxVelocity) maxVelocity = item;
    }
    return [minVelocity, maxVelocity];
  }

  setError = (error?: string) => {
    if (error) {
      this.error = error;
    } else {
      this.error = undefined;
    }
  };

  public save = async (): Promise<void> => {
    await this.infrastructure?.save();
  };

  public delete = async (): Promise<void> => {
    this.infrastructure?.delete();
  };

  public downloadForSolverJSON = () => {
    saveAs(new Blob([JSON.stringify(objectToSnake(this.infrastructure!.forSolverJSON()), null, 2)]), "infrastructure.json");
  };

  setPipeMode = (mode: PipeMode) => {
    this.pipeMode = mode;
  };

  setPipeColorMode = (mode: PipeColorMode) => {
    this.pipeColorMode = mode;
  };

  setTooltip = (title?: string) => {
    if (title) {
      this.tooltip = title;
    } else {
      this.tooltip = null;
    }
  };

  setCurrentDate = (date: Dayjs | null) => {
    this.infrastructure?.setCurrentDate(date);
  };

  setLineEnd = (position: ({ id?: number } & Position) | null) => {
    this.lineEnd = position;
  };

  setShowMapText = (show: boolean) => (this.showMapText = show);

  setShowMapTooltip = (show: boolean) => (this.showMapTooltip = show);

  setRuler = (position: Ruler | null) => {
    if (this.ruler) {
      this.ruler = null;
      this.setTooltip(tooltipText.rulerStartTooltipText);
    } else {
      this.ruler = position;
    }
  };

  updateCursorPosition = (position: Position, isZoom?: boolean) => {
    if (isZoom) {
      this.zoomedCursorPosition = position;
    } else {
      this.cursorPosition = position;
    }
  };

  updateDragging = (drag: boolean) => {
    this.isDragging = drag;
  };

  updateNode = (id: string, values: Partial<NodeType>) => {
    this.infrastructure?.nodes.update({ ...values, uuid: id });
  };
  updatePipe = (id: string, values: Partial<PipeType>) => {
    this.infrastructure?.pipes.update({ ...values, uuid: id });
  };

  updateNodePosition = (node: Partial<NodeType>) => {
    this.infrastructure?.nodes.update(node);
    this.updateDragging(true);
    this.calculateStore.clearCalculation();
  };

  updateInteractionMode = (mode: InteractionMode) => {
    this.interactionMode = mode;
    if (mode === "create") {
      this.selection.reset();
      this.setRuler(null);
      this.setTooltip();
      this.selection.resetSelectedObects();
    }
    if (mode === "selection") {
      this.setRuler(null);
      this.setTooltip(tooltipText.selectionStartTooltipText);
    }
    if (mode === "ruler") {
      this.selection.reset();
      this.setTooltip(tooltipText.rulerStartTooltipText);
      this.selection.resetSelectedObects();
    }
  };

  pushNode = (position: { x: number; y: number }) => {
    const newNode: NodeType = NodesModel.createNode(position, this.infrastructure!.nodes.length);
    const existingPoint = findExistingNode(this.infrastructure?.nodes.data!, this.lineEnd);
    if (!(existingPoint && this.isDrawing)) {
      this.infrastructure?.nodes.pushNode(newNode);
    }
    if (this.isDrawing) {
      this.pushPipe(existingPoint ? existingPoint : newNode);
      this.setTooltip();
    }
    this.calculateStore.clearCalculation();
  };

  pushPipe = (point: NodeType) => {
    if (this.newPipe?.start?.uuid === point.uuid) {
      this.resetCreation();
      return;
    }
    if (this.newPipe === null) {
      this.newPipe = { start: point, end: null };
    } else {
      this.newPipe = { ...this.newPipe, end: point };
    }
    const { start, end } = this.newPipe;
    if (start && end) {
      const pipe = PipesModel.createPipe(start.uuid, end.uuid, this.infrastructure?.pipes.length! + 1, this.pipeMode, this.infrastructure);
      this.infrastructure?.pipes.pushPipe(pipe);
      this.resetCreation();
      this.setTooltip();
    }
  };

  resetCreation = () => (this.newPipe = null);

  removeNodes = (ids: string[]) => this.infrastructure!.nodes.remove(ids);
  removePipes = (ids: string[]) => this.infrastructure!.pipes.remove(ids);

  removeSelectedItems = () => {
    this.removePipes(this.selection.selectedPipeIds);
    this.removeNodes(this.selection.selectedNodeIds);
  };
}

const InfrastructureMapContext = createContext<{ manager: InfrastructureMapManager } | null>(null);

const useInfrastructureMapContext = (): InfrastructureMapManager => {
  const context = useContext(InfrastructureMapContext);
  console.assert(context, "Использование контекста вне виджета карты");
  return context!.manager;
};

const InfrastructureMapContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const infrastructure = useInfrastructure();
  const manager = useMemo(() => new InfrastructureMapManager(infrastructure), [infrastructure]);
  return <InfrastructureMapContext.Provider value={{ manager }}>{children}</InfrastructureMapContext.Provider>;
};

export { InfrastructureMapContextProvider, useInfrastructureMapContext };
export type { InfrastructureMapManager, NodeType, PipeColorMode, PipeType };
