import { computed, makeObservable } from "mobx";

import { global } from "models/global";
import { LoadableStoreResolver } from "models/loadableStore/loadableStoreResolver";
import { ProducingObjectRaw } from "services/back/producingObjects";

import { Stratum } from "../stratum/stratum";

class ProducingObject {
  #stratums: LoadableStoreResolver<Stratum>;

  constructor(public readonly data: ProducingObjectRaw) {
    this.#stratums = new LoadableStoreResolver(global.stratums, data.stratumIds);

    makeObservable(this, {
      stratums: computed,
    });
  }

  public get id(): number {
    return this.data.id;
  }

  public get title(): string {
    return this.data.title;
  }

  public get stratums() {
    return this.#stratums.items;
  }

  public get oilRelativeDensity(): number {
    return this.data.densityOil / 1000;
  }

  public get waterRelativeDensity(): number {
    return this.data.densityWater / 1000;
  }

  public hasStratumWithId(stratumId: number): boolean {
    return this.#stratums.ids.includes(stratumId);
  }
}

export { ProducingObject };
