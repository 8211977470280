import { FC } from "react";
import { PageFrame } from "routing/pageFrame/pageFrame";

import { ParticipantsTable } from "features/participantsTable/participantsTable";
import { Preloader } from "features/preloader/preloader";
import { useProject } from "models/project/project";

const useParticipants = () => useProject()?.participants;

const ParticipantsPage: FC = () => {
  return (
    <Preloader hooks={[useParticipants]}>
      <PageFrame title="Участники проекта">
        <ParticipantsTable />
      </PageFrame>
    </Preloader>
  );
};

export { ParticipantsPage };
