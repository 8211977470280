import { FC, ReactNode } from "react";
import classNames from "classnames";

import cn from "../inspectorMenu.module.less";

type Props = { title: ReactNode; className?: string; children: ReactNode; isFlex?: boolean; onClick?: () => void };

const Card: FC<Props> = ({ title, children, className, isFlex, onClick }) => (
  <div className={classNames(cn.cardWrapper, className, isFlex && cn.cardWrapperFlex)} onClick={onClick}>
    <div className={cn.cardTitle}>{title}</div>
    <div className={cn.params}>{children}</div>
  </div>
);

export { Card };
