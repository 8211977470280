import { useResultModel } from "routing/outlines/result/resultPage";

import { Results } from "./results";

const OperatingResults = () => {
  const result = useResultModel();
  const licenseRegion = result.selectedLicenseRegion === "total" ? "Суммарно по всем ЛУ" : result.selectedLicenseRegion;

  return <Results model={result.operating} exportFileName={`Операционная деятельность ${licenseRegion}`} />;
};

export { OperatingResults };
