import { FC, useCallback, useState } from "react";
import { useMapContext, useMapRefsContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { formatScale } from "../utils";

import SelectionGrab from "./selectionGrab";
import SelectionLine from "./selectionLine";
import SelectionPoint from "./selectionPoint";

const Selection: FC = observer(() => {
  const [intermediateMovePosition, setPosition] = useState<{ x: number; y: number } | null>(null);
  const [isShowGrab, setIsShowGrab] = useState(false);

  const { zoomedScale } = useMapRefsContext();
  const manager = useInfrastructureMapContext();
  const { scale, zoom } = useMapContext();

  const { points, lines } = manager.selection;

  const updatePosition = useCallback(
    (event: any, id: number, isIntermediate?: boolean) => {
      const { x, y } = formatScale(event, zoomedScale);
      if (!isIntermediate) {
        manager.selection.updateSelectionPointPosition({ x, y, id });
      } else {
        setPosition({ x, y });
        manager.selection.updateSelectionIntermediatePointPosition({ x, y, id });
      }
    },
    [manager.selection, zoomedScale]
  );

  const handleDeletePoint = (id: number) => {
    manager.selection.deleteSelectionPoint(id);
  };

  const onFinishSelection = () => {
    manager.selection.reset();
  };

  const preparedPoints = points.map((point) => ({
    id: point.id,
    x: scale.x(point.x),
    y: scale.y(point.y),
  }));

  const polygonPoints = lines
    .flatMap((line) => [`${scale.x(line.from.x)},${scale.y(line.from.y)}`, `${scale.x(line.to.x)},${scale.y(line.to.y)}`])
    .join(" ");

  return (
    <g>
      {points.length && isShowGrab && manager.selection.grabPoints && (
        <SelectionGrab
          scale={scale}
          strokeWidth={zoom < 2 ? 4 : zoom > 2 ? 1 : 2}
          grabPoints={manager.selection.grabPoints}
          cursorPosition={intermediateMovePosition ? intermediateMovePosition : manager.zoomedCursorPosition!}
        />
      )}
      <polygon points={polygonPoints} fill="rgba(46, 144, 250, 0.1)" />
      {lines.map((line) => (
        <SelectionLine
          key={line.id}
          line={line}
          scale={scale}
          zoom={zoom}
          onHover={setIsShowGrab}
          addIntermediatePoint={(event) => manager.selection.update({ ...formatScale(event, zoomedScale), id: line.intermediatePoint.id })}
          onEnd={() => setPosition(null)}
          onDrag={(event) => {
            updatePosition(event, line.id, true);
            manager.selection.setGrabPoints({ prevPoint: line.from, nextPoint: line.to });
          }}
        />
      ))}
      {preparedPoints.map((point) => (
        <SelectionPoint
          key={point.id}
          x={point.x}
          y={point.y}
          zoom={zoom}
          updatePosition={(event) => updatePosition(event, point.id)}
          onFinish={onFinishSelection}
          onDelete={() => handleDeletePoint(point.id)}
          onHover={setIsShowGrab}
        />
      ))}
    </g>
  );
});

export default Selection;
