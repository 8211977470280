import { BaseReport } from "../baseReport";

export const TepReport = () => {
  return (
    <BaseReport
      options={{ useCalculationData: true, chooseSingleLicenceZone: true, chooseSingleLicenceZoneIsImportant: true, useEcyLists: true }}
      apiPath="reports/tep-report"
      title="ТЭП"
    />
  );
};
