import { getConstantsResolver } from "services/back/constants";
import { getTaxResolver } from "services/back/tax";
import { Range } from "utils/range";

import { MetricTree, riseMetrics } from "../utils";

const params = {
  calcPropTax: "Для расчета налога на имущество",
  calculateAIT: "Для расчета НДД",
  percentConstructionProgress: "Процент незавершенного строительства",
  percentCostsIncludedExpenses: "Процент затрат, включаемые в состав расходов",
  valuePropField: "Стоимость имущества на месторождении",
  inputFixAssets: "Ввод основных фондов",
  outAndWriteOffFixAssets: "Вывод/списание основных фондов",
  depreciationChargesOilAndGasProd: "Амортизационные отчисления на добычу нефти и газа",
} as const;

const includeParamsScale = [params.calcPropTax, params.calculateAIT];
const includeParamsTable = [
  params.inputFixAssets,
  params.outAndWriteOffFixAssets,
  params.depreciationChargesOilAndGasProd,
];

const includeFirstChild = [
  "Норма амортизационных отчислений переходящих ОФ",
  "Норма амортизационных отчислений новых ОФ",
];

const reorganizedChild = (scalar: MetricTree[]) => {
  const [firstParent, lastParent] = scalar;
  return [
    {
      title: "Нормы амортизационных отчислений",
      key: 1,
      unit: { quantity: 0, measure: 0 },
      values: null,
      children: firstParent?.children
        .filter(({ code_title }) => includeFirstChild.includes(code_title as any))
        .reverse(),
    },
    {
      title: "Процент незавершенного строительства",
      key: 4,
      unit: { quantity: 0, measure: 0 },
      values: null,
      children: firstParent.children.filter(({ code_title }) => code_title === params.percentConstructionProgress),
    },
    {
      title: "Процент затрат, включаемые в состав расходов",
      key: 6,
      unit: { quantity: 0, measure: 0 },
      values: null,
      children: lastParent.children.filter(({ code_title }) => code_title === params.percentCostsIncludedExpenses),
    },
  ];
};

const getWealthForecast = async (range: Range) => {
  const { constants } = await getConstantsResolver();
  const { schema_operation } = await getTaxResolver(range);
  const [scalarData, tableData] = [riseMetrics(constants), riseMetrics(schema_operation)];

  const scalarParent = scalarData.filter(({ code_title }) => includeParamsScale.includes(code_title as any));
  const resultScalar = [
    {
      title: null,
      key: 0,
      unit: { quantity: 0, measure: 0 },
      values: null,
      children: reorganizedChild(scalarParent),
    },
  ] as unknown as MetricTree[];

  let tableParent = tableData.find(({ code_title }) => code_title === params.valuePropField);
  const resultTable = tableParent?.children.filter(({ code_title }) => includeParamsTable.includes(code_title as any))!;

  return { scalar: resultScalar, table: resultTable };
};

const getWealthFact = async (range: Range) => {
  const { schema_operation } = await getTaxResolver(range);
  return { table: riseMetrics(schema_operation)[2].children };
};

export { getWealthFact, getWealthForecast };
