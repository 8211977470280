import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { Mine } from "services/back/infrastructure/calculate";
import { NodeType } from "services/back/infrastructure/types";

type FormatMinesType<T> = { uuid: string; title: string; params: T[] };

type MineType = { date: Dayjs } & Mine;

type DRow = {
  date: string;
  title: string;
  fluidRateT: number;
  oilRateT: number;
  waterRateT: number;
  injRateT: number;
};

class WellPad extends TableNode<DRow> {
  asDRow = (): DRow => ({
    ...this.data,
    title: this.data.title,
    date: dayjs(this.data.date).format("MM.YYYY"),
  });
  data: MineType;
  constructor(private parent: WellPads, data: MineType) {
    super(parent);

    this.data = data;
  }
}

class WellPads extends TableNode<DRow, WellPad> {
  asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(private parent: WellPadsModel, private name: string, private params: MineType[]) {
    super(parent, { isExpandedChildren: true });

    this.iniChildren();
  }

  private iniChildren() {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.params.map((mine) => new WellPad(this, { ...mine, title: this.name }))
    );
  }
}

class WellPadsModel extends TableNode<DRow, WellPads> {
  constructor(public infrastructure: Infrastructure) {
    super();

    this.initChildren();
  }

  private initChildren() {
    const { mines } = this.infrastructure.nodes;
    const calculations = this.infrastructure.calculateStore.hydraulicDataCollection || [];
    const minesMap = new Map<string, FormatMinesType<MineType>>();
    for (const calculation of calculations) {
      WellPadsModel.formatPipes(calculation.mines || [], minesMap, calculation.date, mines);
    }

    const calculationMines = [...minesMap.values()];
    this.childrenStore = new ChildrenStoreArray(
      this,
      calculationMines.map((mine) => new WellPads(this, mine.title, mine.params))
    );
  }

  static formatPipes = (data: Mine[], result: Map<string, FormatMinesType<Mine>>, date: Dayjs, mines: NodeType[]) => {
    for (const item of data) {
      const newItem = { ...item, date };
      if (result.has(item.nodeUuid)) {
        result.get(item.nodeUuid)?.params.push(newItem);
      } else {
        result.set(item.nodeUuid, {
          uuid: item.uuid,
          title: mines.find((el) => el.uuid === item.nodeUuid)?.title ?? item.mineTitle,
          params: [newItem],
        });
      }
    }
  };
}

export { type DRow as DRowMines, WellPadsModel };
