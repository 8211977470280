import { req } from "utils/request";

type InjectionDataBack = {
  well_id: number;
  stratum_id: number;
  scenario_id: number;
  gtm_id: number | null;
  forecast: {
    month: number[];
    year: number[];
    oil_rate: number[];
    liquid_rate: number[];
    oil_prod: number[];
    liquid_prod: number[];
    prod_days: number[];
    water_inj_m3_rate: number[];
    water_inj_m3: number[];
    inj_days: number[];
  };
};

type CalculationInput = {
  [producingObjectId: number]: {
    [prodWellId: number]: number;
  };
};

function calculateInjection(scenarioId: number, body: CalculationInput) {
  return req.post<InjectionDataBack[]>(`calculation/injection/${scenarioId}`, body);
}

export type { CalculationInput, InjectionDataBack };
export { calculateInjection };
