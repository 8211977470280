import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeObservable, observable } from "mobx";

import type { ParamsRow } from "features/useMetrics/paramsRow";
import { zip } from "utils/itertools";
import type { Range } from "utils/range";

type DRow = {
  param: string;
  measure: string;
  total: number;
  [year: number]: number | null;
};

class AggregationTableStore extends TableNode<DRow, Datum> {
  public readonly root = this;

  constructor(public readonly range: Range, private readonly rows: ParamsRow[]) {
    super();
    makeObservable<AggregationTableStore, "rows">(this, {
      rows: observable,
    });
    this.childrenStore = new ChildrenStoreArray(
      this,
      rows.slice(1).map((row) => new Datum(this, row))
    );
  }
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): AggregationTableStore {
    return this.parent.root;
  }

  constructor(private readonly parent: Datum | AggregationTableStore, private readonly row: ParamsRow) {
    super(parent, { isExpandedChildren: true });
    if (row.children) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        row.children?.map((row) => new Datum(this, row))
      );
    } else {
      this.childrenStore = null;
    }
    if (this.row.children !== undefined) {
      this.updateValue = undefined;
    }
  }

  public get range(): Range {
    return this.root.range;
  }

  asDRow = (): DRow => ({
    param: this.row.title,
    measure: this.row.measure ?? "",
    total: (this.row.values as number[]).reduce((acc, el) => acc + el, 0),
    ...Object.fromEntries(zip([...this.range], this.row.values ?? [])),
  });

  updateValue?(key: any, newValue: any): [prev: any, curr: any] {
    if (!this.row.values) {
      return [undefined, undefined];
    }
    const yearIdx = this.range.id(key);
    const prev = this.row.values[yearIdx];
    const curr = (this.row.values[yearIdx] = newValue);
    return [prev, curr];
  }
}

export { AggregationTableStore };
