import { FC } from "react";

import cn from "./templateInfoCards.module.less";

type TemplateCard = {
  sections: CardSection[];
  widthBasis?: number;
};

type CardSection = {
  title: string;
  content: string;
};

const InfoCard: FC<TemplateCard> = ({ sections, widthBasis }) => {
  return (
    <div className={cn.card} style={{ flexBasis: widthBasis }}>
      {sections.map(({ title, content }, i) => (
        <div key={i} className={cn.cardSection}>
          <div className={cn.cardSectionTitle}>{title}</div>
          {content.split("\n").map((par, j) => (
            <div key={j}>{par}</div>
          ))}
        </div>
      ))}
    </div>
  );
};

const TemplateInfoCards: FC<{ cards: TemplateCard[] }> = ({ cards }) => {
  return (
    <div className={cn.container}>
      {cards.map((card, i) => (
        <InfoCard key={i} {...card} />
      ))}
    </div>
  );
};

export type { TemplateCard };
export { TemplateInfoCards };
