import { type FC, useCallback, useId, useMemo, useState } from "react";
import { CloseOutlined, PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { Table } from "@okopok/components/Table/Table";
import { Button, Input, Popconfirm, Select, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { useForEdit } from "elements/useForEdit";
import { Column, SimpleTableContext } from "features/tableDebug/simpleTable";
import { useECYStore } from "features/useMetrics/useECYStore";
import { ECYStore } from "models/project/fact/ecyStore/ecyStore";
import { checkSystemECY, ECY_BASE_ID } from "services/finance/ecyPatcher";

import { ECYTableStore } from "./tableModel";
import { useAddECYModal } from "./useAddECYModal";
import { useEcyLists } from "./useEcyLists";

import cn from "./ecyParameters.module.less";

const EcyParameters: FC = observer(() => {
  const [currentECYId, setCurrentECYId] = useState(ECY_BASE_ID);
  const ecyStore = useECYStore() as ECYStore;
  const [ecyStoreEditable, setForEdit] = useForEdit(ecyStore);
  const currentECY = ecyStoreEditable?.at(currentECYId);

  const isCurrentECYSystem = checkSystemECY(currentECY);

  const discountInputId = useId();

  const { listSystemECY, listECY } = useEcyLists(ecyStoreEditable);

  const { showModal } = useAddECYModal({ ecyStore: ecyStoreEditable, currentECYId, setCurrentECYId });

  const onSubmit = useCallback(() => {
    ecyStore.update(ecyStoreEditable as any).then(() => setForEdit(ecyStore.clone));
  }, [ecyStore, ecyStoreEditable, setForEdit]);

  const store = useMemo(
    () => (ecyStoreEditable && currentECY && new ECYTableStore(ecyStoreEditable, currentECY.years, currentECY.tableItems)) ?? undefined,
    [ecyStoreEditable, currentECY]
  );

  const columns = useMemo<Column[]>(
    () =>
      ecyStoreEditable && currentECY
        ? [
            {
              title: "Параметр",
              dataKey: "param",
              type: "string",
              width: 350,
              showEditRowBtn: true,
              isSticky: true,
            },
            {
              title: "Единица измерения",
              dataKey: "measure",
              type: "string",
              width: 100,
              isSticky: true,
            },
            ...[...currentECY.years].map(
              (year): Column => ({
                title: `${year}`,
                dataKey: `${year}`,
                type: "number",
                width: 140,
                editable: !ecyStoreEditable.isSystemECY(currentECY),
                renderToString: (v) => v,
              })
            ),
          ]
        : [],
    [ecyStoreEditable, currentECY]
  );

  const isLoading = !currentECY || currentECY.isLoading || !ecyStoreEditable || ecyStoreEditable.isLoading;

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <SimpleTableContext
      exportFileName="ЕСУ"
      columns={columns}
      data={store}
      hideExpandColumn
      tableOptions={{
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      }}
      tableSettingsId="ecy_parameters"
    >
      <PageFrameTitlePortal model={store ?? undefined} onSave={onSubmit} permissionSection="economic">
        <label htmlFor={discountInputId}>Коэффициент дисконтирования</label>
        <Tooltip title="Свой для каждой модели ЕСУ">
          <QuestionCircleFilled className={cn["discont-help-icon"]} />
        </Tooltip>
        <Input
          disabled={isCurrentECYSystem}
          value={currentECY.discount}
          onChange={(e) => {
            currentECY.setDiscount(Number(e.target.value));
          }}
          style={{ width: 110 }}
          className={cn["discount-input"]}
          type="number"
          addonAfter="%"
          id={discountInputId}
        ></Input>

        <Select
          value={currentECYId}
          onChange={(v) => {
            setCurrentECYId(v);
          }}
          style={{ width: 200 }}
          options={[
            {
              label: <span className={cn["select-group-title"]}>Системные</span>,
              title: "system",
              options: listSystemECY.map((ecy) => {
                return {
                  label: <span className={cn["select-system-title"]}>{ecy.title}</span>,
                  value: ecy.id,
                };
              }),
            },
            ...(listECY.length > 0
              ? [
                  {
                    label: <span className={cn["select-group-title"]}>Пользовательские</span>,
                    title: "engineer",
                    options: listECY.map((ecy) => {
                      return {
                        label: <span>{ecy.title}</span>,
                        value: ecy.id,
                      };
                    }),
                  },
                ]
              : []),
          ]}
        />
        <Button
          onClick={() => {
            showModal();
          }}
          icon={<PlusOutlined />}
        >
          ЕСУ
        </Button>
        <Popconfirm
          title="Удаление модели ЕСУ"
          description="Подтвердите удаление ЕСУ"
          onConfirm={() => {
            if (ecyStoreEditable.deleteEcy(currentECY.id)) {
              setCurrentECYId(ecyStoreEditable!.first!.id);
            }
          }}
          // onCancel={cancel}
          okButtonProps={{ danger: true }}
          okText="Удалить"
          cancelText="Отмена"
        >
          <Button disabled={isCurrentECYSystem} icon={<CloseOutlined className={cn["delete-button-icon"]} />} />
        </Popconfirm>
      </PageFrameTitlePortal>

      <div style={{ height: "100%" }}>
        <Table headerClassName={cn.tableHeader} className={cn.table} />
      </div>
    </SimpleTableContext>
  );
});

export { EcyParameters };
