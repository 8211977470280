import { useMemo } from "react";

import { ECYStore } from "models/project/fact/ecyStore/ecyStore";
import { checkSystemECY } from "services/finance/ecyPatcher";

export const useEcyLists = (ecyStore: ECYStore | null | undefined) => {
  const [listSystemECY, listECY] = useMemo(() => {
    const listSystemECY = [];
    const listECY = [];
    if (!ecyStore || !ecyStore.values) {
      return [[], []];
    }

    for (const ecy of ecyStore.values) {
      if (checkSystemECY(ecy)) {
        listSystemECY.push(ecy);
      } else {
        listECY.push(ecy);
      }
    }
    return [listSystemECY, listECY];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecyStore, ecyStore?.length]); // TODO: Не очень пока понятно как это будет работать с созданием и удалением ЕСУ, но покрайней мере иизменение размера списка может свидетельствовать именно об этом

  return { listSystemECY, listECY };
};
