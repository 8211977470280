import { type FC, useEffect, useRef } from "react";
import { useDrag } from "@okopok/axes_context";

import cn from "./bound.module.less";

type BoundProps = {
  side: "left" | "right";
  x: number;
  y: number;
  onDrag: (x: number) => void;
  color?: string;
  patternKey?: string;
};

const Bound: FC<BoundProps> = ({ side, x, y, onDrag, color, patternKey }) => {
  const dragStartX = useRef<number>(x);

  const [drag, isDragging] = useDrag((dx) => {
    onDrag(dragStartX.current + dx);
  });
  useEffect(() => {
    dragStartX.current = x;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);
  return (
    <g style={{ color }} transform={`translate(${x},${y})`}>
      <defs>
        <pattern
          id={`diagonalHatch-${patternKey}-${side}`}
          width="5"
          height="5"
          patternTransform={side === "left" ? "rotate(45)" : "rotate(-45)"}
          patternUnits="userSpaceOnUse"
        >
          <line x1="0" y1="0" x2="0" y2="5" style={{ stroke: `${color}`, strokeWidth: "5px" }} />
        </pattern>
        <linearGradient id={`linearGradient-${patternKey}-${side}`}>
          {side === "left" ? (
            <>
              <stop offset="0%" stopColor="white" />
              <stop offset="70%" stopColor={color} />
              <stop offset="100%" stopColor={color} />
            </>
          ) : (
            <>
              <stop offset="0%" stopColor={color} />
              <stop offset="30%" stopColor={color} />
              <stop offset="100%" stopColor="white" />
            </>
          )}
        </linearGradient>
        <mask id={`mask-${patternKey}-${side}`}>
          <rect width={25} height={100} fill={`url(#diagonalHatch-${patternKey}-${side})`} y={-50} x={{ left: -25, right: 0 }[side]} />
        </mask>
      </defs>
      <rect
        className={cn.curtain}
        width={25}
        height={100}
        y={-50}
        x={{ left: -25, right: 0 }[side]}
        fill={`url(#linearGradient-${patternKey}-${side})`}
        mask={`url(#mask-${patternKey}-${side})`}
      />
      <rect className={cn.border} width={2} height={100} y={-50} x={-1} />
      <rect className={cn.anchor} width={6} height={100} y={-50} x={-3} onMouseDownCapture={drag} />
    </g>
  );
};

export { Bound };
